import React, { useCallback, useState } from "react";
import {
  Box,
  Step,
  StepLabel,
  Stepper,
  Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useForm, FormProvider } from "react-hook-form";

import PrimaryInfoForm from "./PrimaryInfoForm";
import LendersServicerForm from "./LendersServicerForm";
import HedgeCovenantsForm from "./EdgeCovenantsForm";
import { LoansForm, PostLoansForm, PutLoansForm } from "../model/LoansForm";
import { usePropertyInfo } from "../../util/propertyInfoContext";
import { useCreateLoan, useUpdateLoan } from "../api/postLoan";
import { useQueryClient } from "@tanstack/react-query";
import { validateForEmptyFields } from "../util/propertyViewLoans";
import { steps } from "./LoansFormModal";
import { dateBehind } from "../util/primaryInfoForm";

export interface IFormIncompleteModel {
  tabValue: number;
  errorMessage: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  errorTab: {
    color: "red",
  },
  appBar: {
    boxShadow: "none",
  },
  tabs: {
    backgroundColor: "#fff",
  },
  rootTab: {
    textTransform: "none",
  },

  tabsBorder: {
    borderBottom: "1px solid lightgray",
  },
  error: {
    marginLeft: "8px !important",
    fontWeight: "bold",
    color: "#F44336 !important",
    marginTop: 0,
  },
  stepperLabel: {
    color: "red !important",
  },
}));

interface Props {
  defaultLoansForm: any;
  loanId?: string;
  saveLoan: boolean;
  activeStep: number;
  updateActiveStep: (activeStep: number) => void;
  setSaveLoanFalse: () => void;
  onSwitchTab: () => void;
  onCloseDialog: (isLoanSaved?: boolean) => void;
}

const LoansForms: React.FC<Props> = (props) => {
  const classes = useStyles();
  const propertyInfo = usePropertyInfo();
  const createLoanResponse = useCreateLoan();
  const editLoanMutation = useUpdateLoan();
  const {
    saveLoan,
    activeStep,
    setSaveLoanFalse,
    updateActiveStep,
    onSwitchTab,
  } = props;
  // const [tabValue, setTabValue] = useState(0);
  const [isPrimaryFormValid, setIsPrimaryFormValid] = useState(true);
  const [isLenderFormValid, setIsLenderFormValid] = useState(true);
  const [isHedgeFormValid, setIsHedgeFormValid] = useState(true);
  const [hasSavedClicked, setHasSavedClicked] = useState(false);

  const [saveButton, setSaveButton] = React.useState({
    primaryInfo: true,
    lendersServicer: true,
    edgeCovenants: true,
  });

  const methods = useForm<LoansForm>({
    mode: "onTouched",
    reValidateMode: "onChange",
    shouldUnregister: false,
    shouldFocusError: false,
    defaultValues: {
      ...props.defaultLoansForm,
    },
  });

  const queryClient = useQueryClient();

  /**
   * Saving the loan to the backend.
   */
  const postSaveLoan = methods.handleSubmit((model: LoansForm) => {
    if (props.loanId === undefined) {
      const postLoan: PostLoansForm = {
        ...model,
        accountIdentifier: propertyInfo.accountIdentifier,
        propertyId: propertyInfo.id,
      };
      createLoanResponse.mutate(postLoan, {
        onSuccess: (data) => {
          props.onCloseDialog(true);
        },
      });
    } else {
      const putLoan: PutLoansForm = {
        ...model,
        accountIdentifier: propertyInfo.accountIdentifier,
        propertyId: propertyInfo.id,
        loanId: props.loanId,
        noticeDateTypeId: 0,
      };
      editLoanMutation.mutate(putLoan, {
        onSuccess: (data) => {
          props.onCloseDialog(true);
          if (putLoan.loanId)
            queryClient.invalidateQueries([
              "getLoan",
              putLoan.loanId + putLoan.accountIdentifier + putLoan.propertyId,
            ]);
        },
      });
    }
  });

  React.useEffect(() => {
    if (!saveLoan) return;

    const allValues = methods.getValues();
    const { isPrimaryValid, isLenderValid, isHedgeValid } =
      validateForEmptyFields(allValues);
    if (isPrimaryFormValid !== isPrimaryValid)
      setIsPrimaryFormValid(isPrimaryValid);
    if (isLenderFormValid !== isLenderValid)
      setIsLenderFormValid(isLenderValid);
    if (isHedgeFormValid !== isHedgeValid) setIsHedgeFormValid(isHedgeValid);

    const hasNoDatesError =
      dateBehind(allValues.originationDate, allValues.maturityDate) === true
        ? true
        : false;

    if (!isPrimaryValid || !hasNoDatesError) {
      updateActiveStep(0);
      onSwitchTab();
      setSaveLoanFalse();
      return;
    } else if (!isLenderValid) {
      updateActiveStep(1);
      onSwitchTab();
      setSaveLoanFalse();
      return;
    }
    else if(!isHedgeValid){
      setSaveLoanFalse();
      return;
    }

    postSaveLoan();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isHedgeFormValid,
    isLenderFormValid,
    isPrimaryFormValid,
    methods,
    saveLoan,
    setSaveLoanFalse,
    activeStep,
    updateActiveStep,
    onSwitchTab,
  ]);

  /**
   * Resetting the error tab value to true if the user switches to that particular tab.
   */
  React.useEffect(() => {
    // Primary Info form
    if (activeStep === 0 && isPrimaryFormValid === false)
      setIsPrimaryFormValid(true);
    // Lender Form
    else if (activeStep === 1 && isLenderFormValid === false)
      setIsLenderFormValid(true);
    // Hedge Form
    else if (activeStep === 2 && isHedgeFormValid === false)
      setIsHedgeFormValid(true);
  }, [isHedgeFormValid, isLenderFormValid, isPrimaryFormValid, activeStep]);

  const primaryInfoDisableSave = useCallback(
    (value: boolean) => {
      if (saveButton.primaryInfo !== value)
        setSaveButton({ ...saveButton, primaryInfo: value });
    },
    [saveButton]
  );

  React.useEffect(() => {
    if (saveLoan === true && hasSavedClicked === false)
      setHasSavedClicked(true);
  }, [saveLoan, hasSavedClicked]);

  React.useEffect(() => {
    if (hasSavedClicked) methods.trigger();
  }, [methods, hasSavedClicked, activeStep]);

  const lendersServicerDisableSave = useCallback(
    (value: boolean) => {
      if (saveButton.lendersServicer !== value)
        setSaveButton({ ...saveButton, lendersServicer: value });
    },
    [saveButton]
  );

  const edgeCovenantsDisableSave = useCallback(
    (value: boolean) => {
      if (saveButton.edgeCovenants !== value)
        setSaveButton({ ...saveButton, edgeCovenants: value });
    },
    [saveButton]
  );

  return (
    <FormProvider {...methods}>
      <Box className={classes.root}>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              let isError = false;
              if (
                (index === 0 && !isPrimaryFormValid) ||
                (index === 1 && !isLenderFormValid) ||
                (index === 2 && !isHedgeFormValid)
              )
                isError = true;

              return (
                <Step
                  key={label}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (index < activeStep) {
                      updateActiveStep(index);
                      onSwitchTab();
                    }
                  }}
                >
                  <StepLabel>
                    <Typography
                      variant="h3"
                      component="span"
                      className={`${isError ? classes.stepperLabel : ""}`}
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <React.Fragment>
            <Box mt={2} paddingLeft={1} paddingRight={1}>
              {activeStep === 0 ? (
                <PrimaryInfoForm disableSave={primaryInfoDisableSave} />
              ) : activeStep === 1 ? (
                <LendersServicerForm disableSave={lendersServicerDisableSave} />
              ) : (
                <HedgeCovenantsForm disableSave={edgeCovenantsDisableSave} />
              )}
            </Box>
          </React.Fragment>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default LoansForms;
