export interface TEditUserModel extends IUpdateUser {
  accountIdentifier: string;
  userId: string;
}

export interface IUpdateUser {
  roles: string[];
}

export enum EAccountPermission {
  ADMINISTRATION = "Admin",
  ADMINISTER_USERS = "AddUsers",
  PROPERTY_EDIT = "PropertyEdit",
  CONFIGURE_WIDGETS = "ConfigureWidgets",
  CONFIGURE_PORTFOLIOS = "PortfolioCreateEdit",
  CONFIGURE_LOANS = "LoanCreateEdit",
  DOCUMENTS_UPLOAD_AND_DELETE = "DocumentUploadDelete",
}
