import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import { useForm } from "react-hook-form";
import { FormControl, Typography, Box } from "@mui/material";

interface Props {
  handlePopupClose: () => void;
  handleSubmit(
    name: string,
    description: string,
    isPublic: boolean,
    isDefault: boolean
  ): Promise<void>;
}

interface DashboadCreateModel {
  description: string;
  name: string;
  isPublic: boolean;
  isDefault: boolean;
}

const DashboardCreateModal: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<DashboadCreateModel>();

  const onSubmit = handleSubmit((model: DashboadCreateModel) => {
    props.handleSubmit(model.name, model.description, model.isPublic, model.isDefault);
  });

  return (
    <Dialog
      open={true}
      aria-labelledby="dashboard-create-title"
      aria-describedby="dashboard-create-description"
    >
      <form onSubmit={onSubmit}>
        <DialogTitle
          id="dashboard-create-title"
          //style={{ padding: "16px 16px 0px 24px" }}
        >
          <Typography variant="h2">{"Create Dashboard"}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={"dashboard-message"}>
            <Typography variant="body3">
              Please complete the form below and click the Save button to create
              a new dashboard.
            </Typography>
          </DialogContentText>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">{`Name*`}</Typography>
            <TextField
              //margin="dense"
              size="small"
              variant="outlined"
              //label="Name*"
              type="text"
              fullWidth
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ""}
              {...register("name", {
                required: "Name is required",
                maxLength: {
                  value: 100,
                  message: "Maximum length is 100",
                },
              })}
              className={"form-control-field"}
            />
          </FormControl>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Description*`}</Typography>
            <TextField
              // margin="dense"
              size="small"
              variant="outlined"
              //label="Description*"
              type="text"
              fullWidth
              error={!!errors.description}
              helperText={errors.description ? errors.description.message : ""}
              {...register("description", {
                required: "Description is required",
                maxLength: {
                  value: 500,
                  message: "Maximum length is 500",
                },
              })}
              className={"form-control-field"}
            />
          </FormControl>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Switch {...register("isPublic")} />
            <Box pt={1} pl={1} pr={2}>
              Public Dashboard
            </Box>
            <Switch {...register("isDefault")} />
            <Box pt={1} pl={1}>
              Set as default
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handlePopupClose}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button type={"submit"} color="primary" variant="contained" autoFocus>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DashboardCreateModal;
