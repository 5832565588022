import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useIsFetching } from "@tanstack/react-query";
import {
  Box,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  FormHelperText,
  SelectChangeEvent,
  IconButton,
} from "@mui/material";
import { UilMultiply } from "@iconscout/react-unicons";

import {
  ICreatePortfolioModel,
  IGetPortfolio,
} from "../model/createPortfolioModel";
import { useEditPortfolio } from "../api/portfolioService";
import { useGetPortfolioPropertiesList } from "../api/portfolioProperties";
import {
  maxTextField,
  textErrorMessage,
  requiredErrorMessage,
} from "../../../properties/property details/util/errorTexts";

const SELECT_ALL_OPTION = "all";

interface EditPortfolioProps {
  handlePopupClose: (shouldRefreshData: boolean) => void;
  showInsertPopup: boolean;
  accountIdentifier: string;
  defaultPortfolio: IGetPortfolio;
}

const EditPortfolioModal: React.FC<EditPortfolioProps> = (props) => {
  const isFetching = useIsFetching();

  const propertiesQuery = useGetPortfolioPropertiesList(
    props.accountIdentifier
  );

  const {
    register,
    handleSubmit,
    control,

    formState: { errors },
  } = useForm<ICreatePortfolioModel>({
    defaultValues: {
      name: props.defaultPortfolio.name,
      code: props.defaultPortfolio.code,
      description: props.defaultPortfolio.description,
      properties: props.defaultPortfolio.selectedProperties.map(
        (property) => property.id
      ),
    },
  });
  const editPortfolioMutation = useEditPortfolio();

  /**
   * When submitting the form
   */
  const onSubmit = handleSubmit((model: ICreatePortfolioModel) => {
    editPortfolioMutation.mutate(
      {
        accountId: props.accountIdentifier,
        portfolioId: props.defaultPortfolio.id,
        name: model.name,
        description: model.description,
        properties: model.properties.filter(
          (propertyId) => propertyId !== SELECT_ALL_OPTION
        ),
      },
      {
        onSuccess: (data) => {
          props.handlePopupClose(true);
        },
      }
    );
  });

  const SelectIcon = (props: any) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  return (
    <Dialog
      open={props.showInsertPopup}
      fullWidth
      maxWidth="sm"
      aria-labelledby="edit-portfolio"
      aria-describedby="edit-portfolio-description"
    >
      <DialogTitle id="edit-portfolio">
        <Typography component="span" variant="h2" color="primary.dark">
          Edit Portfolio
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => props.handlePopupClose(false)}
          className="icon-dialog-close"
          size="large"
        >
          <UilMultiply />
        </IconButton>
        <Typography component="div" variant="h3" color="neutral700.light">
          Please edit the form and click ‘Save’ to save your updates. Recommended portfolio size is up to 10 properties.
        </Typography>
      </DialogTitle>
      <DialogContent>
        {isFetching > 0 || propertiesQuery.isLoading ? (
          <Box m="auto">
            <CircularProgress />
          </Box>
        ) : (
          <React.Fragment>
            {propertiesQuery.isSuccess &&
              propertiesQuery.data !== undefined &&
              propertiesQuery.data.length > 0 && (
                <React.Fragment>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className={"form-control"}
                  >
                    <Typography variant="body3" component="label">
                      Name*
                    </Typography>
                    <TextField
                      size="small"
                      id="name"
                      variant="outlined"
                      {...register("name", {
                        required: requiredErrorMessage,
                        maxLength: {
                          value: 100,
                          message: textErrorMessage,
                        },
                      })}
                      error={Boolean(errors?.name)}
                      helperText={
                        Boolean(errors?.name) ? errors?.name?.message : null
                      }
                      className={"form-control-field"}
                    />
                  </FormControl>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className={"form-control"}
                  >
                    <Typography variant="body3" component="label">
                      Description*
                    </Typography>
                    <TextField
                      size="small"
                      id="description"
                      variant="outlined"
                      {...register("description", {
                        required: requiredErrorMessage,
                        maxLength: {
                          value: maxTextField,
                          message: textErrorMessage,
                        },
                      })}
                      error={Boolean(errors?.description)}
                      helperText={
                        Boolean(errors?.description)
                          ? errors?.description?.message
                          : null
                      }
                      className={"form-control-field"}
                    />
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    size="small"
                    error={Boolean(errors.properties)}
                    className={"form-control"}
                  >
                    <Typography variant="body3" component="label">
                      Properties*
                    </Typography>
                    <Controller
                      control={control}
                      name="properties"
                      rules={{
                        validate: {
                          required: (values) => {
                            if (Array.isArray(values)) {
                              if (
                                values.length === 0 ||
                                (values.length === 1 &&
                                  values.includes(SELECT_ALL_OPTION))
                              )
                                return requiredErrorMessage;
                            }

                            return true;
                          },
                        },
                      }}
                      render={({ field }) => {
                        const currentValues = [...field.value];
                        const allPropertiesSelected =
                          (propertiesQuery.data &&
                            propertiesQuery.data.length > 0 &&
                            currentValues.length ===
                              propertiesQuery.data.length) ||
                          currentValues.includes(SELECT_ALL_OPTION);
                        const currentValuesLength = currentValues.includes(
                          SELECT_ALL_OPTION
                        )
                          ? currentValues.length - 1
                          : currentValues.length;
                        return (
                          <Select
                            labelId="portfolio-properties"
                            multiple
                            fullWidth
                            className={"form-control-field"}
                            id="properties"
                            value={currentValues}
                            MenuProps={{ autoFocus: false }}
                            onChange={(
                              e: SelectChangeEvent<string[]>,
                              child: React.ReactNode
                            ) => {
                              const newVals = e.target.value as string[];
                              let uniqueVals: string[] = [];
                              if (
                                Array.isArray(newVals) &&
                                Array.isArray(currentValues)
                              ) {
                                if (newVals.length > currentValues.length) {
                                  uniqueVals = newVals.filter(
                                    (newVal) =>
                                      currentValues.indexOf(newVal) === -1
                                  );
                                } else
                                  uniqueVals = currentValues.filter(
                                    (currentValue) =>
                                      newVals.indexOf(currentValue) === -1
                                  );
                              }

                              // if select all is checked
                              if (
                                newVals.includes(SELECT_ALL_OPTION) &&
                                uniqueVals.includes(SELECT_ALL_OPTION)
                              ) {
                                const setValues = propertiesQuery.data.map(
                                  (property) => property.id
                                );
                                setValues.push(SELECT_ALL_OPTION);
                                field.onChange(setValues);
                              }
                              // if select all is unchecked
                              else if (
                                currentValues.length ===
                                  propertiesQuery.data.length + 1 &&
                                !newVals.includes(SELECT_ALL_OPTION)
                              ) {
                                field.onChange([] as string[]);
                              }
                              // if all the properties are selected, then set "select all" as checked
                              else if (
                                newVals.length ===
                                  propertiesQuery.data.length &&
                                currentValues.length <
                                  propertiesQuery.data.length
                              ) {
                                const setValues = propertiesQuery.data.map(
                                  (property) => property.id
                                );
                                setValues.push(SELECT_ALL_OPTION);
                                field.onChange(setValues);
                              }
                              // if all the properties are unchecked, set "Select all" as unchecked
                              else if (
                                currentValues.length === 2 &&
                                newVals.length === 1 &&
                                newVals.includes(SELECT_ALL_OPTION)
                              ) {
                                field.onChange([] as string[]);
                              }
                              // check/uncheck items in the case it is not an edge case
                              else {
                                if (
                                  newVals.length < currentValues.length &&
                                  Array.isArray(newVals)
                                ) {
                                  field.onChange(
                                    newVals.filter(
                                      (newVal) => newVal !== SELECT_ALL_OPTION
                                    )
                                  );
                                } else {
                                  field.onChange(newVals);
                                }
                              }
                            }}
                            onBlur={field.onBlur}
                            renderValue={(selected: any) => {
                              if (!propertiesQuery.data) return selected;
                              const newSelected = selected.filter(
                                (propertyId: any) =>
                                  propertyId !== SELECT_ALL_OPTION
                              );
                              return newSelected
                                .map((selectedPropertyId: any) => {
                                  const property = propertiesQuery.data.find(
                                    (property) =>
                                      property.id === selectedPropertyId
                                  );
                                  return property?.title;
                                })
                                .join(", ");
                            }}
                            IconComponent={SelectIcon}
                          >
                            <MenuItem value={SELECT_ALL_OPTION}>
                              <Checkbox
                                checked={allPropertiesSelected}
                                indeterminate={
                                  propertiesQuery.data &&
                                  currentValuesLength > 0 &&
                                  currentValuesLength <
                                    propertiesQuery.data.length
                                }
                              />
                              <ListItemText primary="Select All" />
                            </MenuItem>
                            {propertiesQuery.data.map((property) => (
                              <MenuItem key={property.id} value={property.id}>
                                <Checkbox
                                  checked={
                                    currentValues.indexOf(property.id) > -1
                                  }
                                />
                                <ListItemText primary={property.title} />
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                    />
                    {Boolean(errors.properties) && (
                      <FormHelperText>
                        {(errors.properties as any)?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </React.Fragment>
              )}
            {editPortfolioMutation.isLoading && (
              <Box m="auto">
                <CircularProgress />
              </Box>
            )}
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => props.handlePopupClose(false)}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={editPortfolioMutation.isLoading || isFetching > 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPortfolioModal;
