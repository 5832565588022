import React from "react";
import { Box, Checkbox } from "@mui/material";
import { IHeaderParams } from "ag-grid-community";

import { UPAContext } from "./UserPropertyAssignmentContext";

const UserPropAssignCustomHeader: React.FC<IHeaderParams> = (props) => {
  const { allUsersChecked, updateAllUsersChecked } =
    React.useContext(UPAContext);

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
        <Checkbox
          checked={allUsersChecked.checked}
          onChange={(event) => updateAllUsersChecked(event.target.checked)}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          className="grid-checkbox"
        />
      </Box>
      <Box>{props.displayName.toUpperCase()}</Box>
    </Box>
  );
};

export default UserPropAssignCustomHeader;
