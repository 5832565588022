import makeStyles from "@mui/styles/makeStyles";
import { AgChartsReact } from "ag-charts-react";
import { Divider } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { PropertySearchMode } from "../../../../properties/property/model/propertyListSearchFilter";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: "white",
  },
  titleContainer: {
    borderBottom: "solid 1px var(--neutral-grey-150)",
    "& h6": {
      fontWeight: "bold",
    },
    "& h4": {
      fontSize: "18px",
      padding: "16px 20px",
      margin: "0",
    },
  },
  subHeaderText: {
    fontSize: "12px;",
  },
  widgetContainer: {
    height: "70%",
  },
  highlightHeader: {
    backgroundColor: "rgb(240, 245, 252)",
    border: "1px solid rgb(25, 118, 210)",
    fontWeight: "bold",
    color: "#066cfa",
  },
}));

interface DashboardItemProps {
  title: string;
  header?: string;
  subHeader?: string;
  options: any;
  tooltip?: string;
  highlightHeader?: boolean;
}
const DashboardItem: React.FC<DashboardItemProps> = (props) => {
  const classes = useStyles();
  const customTheme = {
    palette: {
      strokes: props.options.legend?.color === "Orange" ? ["#ff7646"] : ["#3294f1", "#ff7646", "#b0bec5", "#003f5c"],
      fills: props.options.legend?.color === "Orange" ? ["#ff7646"] : ["#3294f1", "#ff7646", "#b0bec5", "#003f5c"],
    },
    overrides: {
      common: {
        padding: {
          top: 10,
          bottom: 15,
          left: 15,
          right: 15,
        },
        legend: {
          position: "bottom",
          spacing: 2,
        },
        series: {
          area: {
            fillOpacity: 0.3,
            marker: {
              enabled: true,
              size: 6,
            },
          },
        },
      },
    },
  };
  props.options.theme = customTheme;

  return (
    <div className={classes.root}>
      <div
        className={[
          classes.titleContainer,
        ].join(" ")}
      >
        <h4>
          {props.title}{" "}
          {props.tooltip && (
            <Tooltip title={props.tooltip}>
              <InfoOutlined style={{ fontSize: 15 }} />
            </Tooltip>
          )}
        </h4>
      </div>
      <Divider variant="middle" />
      <div>
        <h6>{props.header}</h6>
      </div>
      <div>
        <span className={classes.subHeaderText}>{props.subHeader}</span>
      </div>
      <div className={classes.widgetContainer}>
        <AgChartsReact options={props.options} />
      </div>
    </div>
  );
};

export default DashboardItem;
