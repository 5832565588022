import React, { useCallback, useContext, useEffect, useState } from "react";
import { UilImport } from "@iconscout/react-unicons";
import { UilPlusCircle } from "@iconscout/react-unicons";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  GridReadyEvent,
  StatusPanelDef,
  ICellRendererParams,
} from "ag-grid-community";
import { Box, Grid, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useQueryClient } from "@tanstack/react-query";

import UploadDocumentsModal from "./property rent roll generations/uploadDocumentsModal";
import DocumentsDownloadModal from "./property rent roll generations/downloaDocumentsModal";
import OptionsModal from "./property rent roll generations/optionsModal";
import {
  currencyFormatter,
  dateFilterParams,
  dateValueFormatter,
  dateValueGetter,
  negativeNumCellStyle,
  numberFormatter,
  numberGetter,
} from "../../util/gridUtils";
import { hasSpecificPermission } from "../../../portfolio/util/accountPermissions";
import PortfolioContext from "../../../portfolio/util/portfolioContext";
import { usePropertyInfo } from "../../util/propertyInfoContext";
import { DocumentUploadSourceEnum } from "../../document/model/documentUploadSource";
import { TabPanel } from "../../../portfolio/portfolioDetails/views/propertiesRenderer";
import { EAccountPermission } from "../../../usermanagement/model/editUserModel";
import RentRollContext from "../context/RentRollContext";
import {
  documentUploadErrorMessage,
  documentUploadSuccessMessage,
} from "../../property details/views/propertyviewOverViewDocuments";

const mainTopPropertyInfoWidth = 400;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainTopTabsContainer: {},
    mainTopTabs: {
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        marginTop: -48,
        width: `calc(100% - ${mainTopPropertyInfoWidth}px)`,
        "& > div > div > a:first-child > span": {
          paddingLeft: 10,
        },
        "& a": {
          fontWeight: "bold",
        },
      },
    },
    mainTopTabPanel: {
      width: "100%",
      height: "100%",
      backgroundColor: "white",
      borderTop: "1px solid #ccc",
      [theme.breakpoints.up("lg")]: {
        width: "100%",
      },
    },
  })
);

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Generations: React.FC<ICellRendererParams> = (props) => {
  const classes = useStyles();

  const rowId = props.node.id + "";
  const gridParent =
    typeof props.data.spaceCode === "object" ? "LeaseView" : "SuiteView";

  // const generationsActive = useRef<boolean>(true);
  useEffect(() => {
    return () => {
      // the detail grid is automatically destroyed as it is a React component
      props.api.removeDetailGridInfo(rowId);
    };
  }, [props.api, rowId]);

  const [statusBar] = React.useState<{ statusPanels: StatusPanelDef[] }>({
    statusPanels: [
      {
        statusPanel: "agAggregationComponent",
        statusPanelParams: {
          aggFuncs: ["count", "sum", "min", "max", "avg"],
        },
      },
    ],
  });

  const colDefs = [
    {
      field: "iterationNum",
      headerName: "Generation",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      minWidth: 50,
      maxWidth: 150,
    },
    {
      field: "executedDate",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params: any) => dateValueGetter(params.data.executedDate),
      valueFormatter: (params: any) =>
        dateValueFormatter(params.data.executedDate),
      minWidth: 50,
      maxWidth: 150,
    },
    {
      field: "startDate",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params: any) => dateValueGetter(params.data.startDate),
      valueFormatter: (params: any) =>
        dateValueFormatter(params.data.startDate),
      minWidth: 50,
      maxWidth: 150,
    },
    {
      field: "endDate",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params: any) => dateValueGetter(params.data.endDate),
      valueFormatter: (params: any) => dateValueFormatter(params.data.endDate),
      minWidth: 50,
      maxWidth: 150,
    },
    {
      field: "rentedArea",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      valueFormatter: (params: any) =>
        numberFormatter(params.data.rentedArea, 0),
      valueGetter: (params: any) => numberGetter(params.data.rentedArea),
      minWidth: 50,
      maxWidth: 150,
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Options",
      field: "optionsIcon",
      cellRenderer: "optionsRenderer",
      minWidth: 50,
      maxWidth: 150,
    },
    {
      headerName: "Documents",
      field: "documentsIcon",
      cellRenderer: "documentsRenderer",
      cellRendererParams: {
        buildingId: props.data.buildingId,
        spaceIdList: props.data.spaceIdList,
        tenantId: props.data.tenantId,
        minWidth: 50,
      },
    },
  ];

  const colDefsCharges = [
    {
      field: "iterationNum",
      headerName: "Generation",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      minWidth: 50,
      maxWidth: 150,
    },
    {
      field: "effectiveDate",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params: any) => dateValueGetter(params.data.effectiveDate),
      valueFormatter: (params: any) =>
        dateValueFormatter(params.data.effectiveDate),
      minWidth: 50,
      maxWidth: 150,
    },
    {
      field: "endDate",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params: any) => dateValueGetter(params.data.endDate),
      valueFormatter: (params: any) => dateValueFormatter(params.data.endDate),
      minWidth: 50,
      maxWidth: 150,
    },
    {
      field: "chargeType",
      filter: true,
      sortable: true,
      minWidth: 50,
      maxWidth: 150,
    },
    {
      field: "monthlyAmount",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.monthlyAmount, "$"),
      valueGetter: (params: any) => numberGetter(params.data.monthlyAmount),
      minWidth: 50,
      maxWidth: 200,
      cellStyle: negativeNumCellStyle,
    },
    { field: "", filter: false, sortable: false, minWidth: 50 },
  ];

  const defaultColDef = {
    // flex: 1,
    minWidth: 100,
    filter: true,
    resizable: false,
    suppressMovable: true,
    suppressRowClickSelection: true,
    suppressCellFocus: true,
    filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
  };

  const autoSizeAll = () => {
    let detailGrid = props.api.getDetailGridInfo(rowId);
    setTimeout(() => detailGrid?.columnApi?.autoSizeAllColumns(), 500);// TODO remove setTimeout when Ag-grid fixes autoSizeColumns on React 18 
  };

  const onGridReady = (params: GridReadyEvent) => {
    const gridInfo = {
      id: props.node.id + "",
      parent: props.node.parent?.data,
      api: params.api,
      columnApi: params.columnApi,
    };

    props.api.addDetailGridInfo(rowId, gridInfo);
  };

  function onFirstDataRendered(params: any) {
    // Get and select active row
    const parentleaseid = props.node.id + "";
    const rowNode = params.api.getRowNode(parentleaseid);
    if (typeof rowNode !== "undefined" && parentleaseid.length > 0) {
      rowNode.setSelected(true);
    }
    // params.api.sizeColumnsToFit();
    autoSizeAll();
  }

  function onFirstDataRenderedCharges(params: any) {
    // Get and select active row
    const parentleaseid = props.node.id + "";
    const rowNode = params.api.getRowNode(parentleaseid);
    if (typeof rowNode !== "undefined" && parentleaseid.length > 0) {
      rowNode.setSelected(true);
    }
    // params.api.sizeColumnsToFit();
    autoSizeAll();
  }

  const onGridReadyCharges = (params: GridReadyEvent) => {};

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.mainTopTabsContainer}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Generations Navigation Tabs"
      >
        <Tab label="Generations" className={"tab-nested-grid"} />
        <Tab label="Charge Schedule" className={"tab-nested-grid"} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <div
          id={`${gridParent}_GenView_${rowId}`}
          style={{
            height: "200px",
            width: "100%",
          }}
          className={
            "ag-theme-alpine ag-theme-generations-table ag-theme-alpine-container-override"
          }
        >
          {/* GENERATIONS */}
          <AgGridReact
            components={{
              optionsRenderer: OptionsRenderer,
              documentsRenderer: DocumentsRenderer,
            }}
            autoGroupColumnDef={{
              minWidth: 100,
            }}
            statusBar={statusBar}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            rowData={props.data.generations}
            onGridReady={onGridReady}
            suppressCellFocus={true}
            onFirstDataRendered={onFirstDataRendered}
            getRowId={function (params) {
              return "detail_" + params.data.leaseId;
            }}
          />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div
          id={`${gridParent}_ChargeView_${rowId}`}
          style={{
            height: "200px",
            width: "100%",
          }}
          className={
            "ag-theme-alpine ag-theme-generations-table ag-theme-alpine-container-override"
          }
        >
          {/* CHARGE SCHEDULE */}
          <AgGridReact
            autoGroupColumnDef={{
              minWidth: 100,
            }}
            statusBar={statusBar}
            enableRangeSelection={true}
            columnDefs={colDefsCharges}
            defaultColDef={defaultColDef}
            rowData={props.data.chargeSchedules}
            onGridReady={onGridReadyCharges}
            onFirstDataRendered={onFirstDataRenderedCharges}
            getRowId={function (params) {
              return "detail_" + params.data.leaseId + "_" + params.data.iterationNum;
            }}
          />
        </div>
      </TabPanel>
    </div>
  );
};

export default Generations;

const OptionsRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const queryClient = useQueryClient();
  const [showOptionsPopup, setShowOptionsPopup] = useState(false);

  const handlePopupClose = useCallback(
    (shouldRefreshData: boolean) => {
      setShowOptionsPopup(false);
      shouldRefreshData && queryClient.invalidateQueries(["getLeaseOptionList"]);
    },
    [queryClient]
  );

  return (
    <React.Fragment>
      <Tooltip
        title="View Options"
        style={{ cursor: "pointer", marginLeft: 8 }}
      >
        <div onClick={() => setShowOptionsPopup(true)}>
          <Typography
            variant="body2"
            color="primary.main"
            sx={{ textDecoration: "underline" }}
          >
            View options
          </Typography>
        </div>
      </Tooltip>
      {showOptionsPopup && (
        <OptionsModal
          handlePopupClose={handlePopupClose}
          showOptionsPopup={showOptionsPopup}
          leaseGenId={props.data.leaseId}
        />
      )}
    </React.Fragment>
  );
};

const DocumentsRenderer: React.FC<ICellRendererParams> = (props: any) => {
  const { updateAlertDetails } = useContext(RentRollContext);
  const queryClient = useQueryClient();
  const propertyInfo = usePropertyInfo();
  const { accountPermissions } = useContext(PortfolioContext);

  const [openDocument, setOpenDocument] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  const hasDeleteUploadAccess = React.useMemo(
    () =>
      hasSpecificPermission(
        accountPermissions,
        propertyInfo.accountIdentifier,
        EAccountPermission.DOCUMENTS_UPLOAD_AND_DELETE
      ),
    [accountPermissions, propertyInfo.accountIdentifier]
  );

  const handlePopupClose = useCallback(
    (shouldRefreshData: boolean) => {
      setOpenDocument(false);
      shouldRefreshData && queryClient.invalidateQueries(["getDocumentList"]);
    },
    [queryClient]
  );

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid
          item
          sx={{
            "& svg": {
              maxHeight: "20px",
              maxWidth: "20px",
              lineHeight: "1",
            },
          }}
        >
          <Tooltip
            title="Download Lease Documents"
            style={{ cursor: "pointer" }}
          >
            <Box
              onClick={() => setOpenDocument(true)}
              className={"icon-documents"}
            >
              <UilImport />
            </Box>
          </Tooltip>
        </Grid>
        {hasDeleteUploadAccess && (
          <Grid
            item
            sx={{
              "& svg": {
                maxHeight: "20px",
                maxWidth: "20px",
                lineHeight: "1",
              },
            }}
          >
            <Tooltip
              title="Upload Lease Documents"
              onClick={() => setOpenUpload(true)}
              style={{ cursor: "pointer" }}
            >
              <Box className={"icon-documents"}>
                <UilPlusCircle />
              </Box>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      {openDocument && (
        <DocumentsDownloadModal
          handlePopupClose={handlePopupClose}
          openDocument={openDocument}
          leaseGenId={props.data.leaseId}
        />
      )}
      {hasDeleteUploadAccess && openUpload && (
        <UploadDocumentsModal
          handleClose={() => setOpenUpload(false)}
          onUploaded={() =>
            updateAlertDetails({
              severityType: "success",
              message: documentUploadSuccessMessage,
            })
          }
          onError={() =>
            updateAlertDetails({
              severityType: "error",
              message: documentUploadErrorMessage,
            })
          }
          open={openUpload}
          uploadSource={DocumentUploadSourceEnum.RentRoll}
          leaseGenId={props.data.leaseId}
          buildingId={props.buildingId}
          tenantId={props.tenantId}
          spaceIdList={props.spaceIdList}
        />
      )}
    </React.Fragment>
  );
};
