import React, { useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

import {
  useGetWidgetConfig,
  useUpdateWidgetsConfig,
} from "../api/widgetConfigService";
import WidgetConfigGrid from "./WidgetConfigGrid";
import { WidgetConfigContext } from "./WidgetConfigContext";
import {
  IPutWidgetConfigRow,
  IPutWidgetConfigurations,
  IWidgetConfigRow,
} from "../model/widgetConfigModel";
import { AllUsersChecked } from "./userPropertyAssignment/models/userPropertyAssignmentContext";
import SimpleAlert, { AlertSeverityType } from "../../UI/view/SimpleAlert";
import { useGetAccounts } from "../../properties/property/api/accountService";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

const WidgetConfiguration: React.FC = () => {
  const { data: accounts, isSuccess } = useGetAccounts();
  const accountId = React.useMemo(() => {
    if (!isSuccess || !accounts || accounts.length === 0) return "";
    return accounts[0].identifier;
  }, [accounts, isSuccess]);
  const widgetConfig = useGetWidgetConfig(accountId);
  const updateWidgetsConfigMutation = useUpdateWidgetsConfig(accountId);
  const queryClient = useQueryClient();

  const [mutationStatus, setMutationStatus] = useState<
    "loading" | "success" | "error" | "idle"
  >("idle");

  const [editedWidgetsConfig, setEditedWidgetsConfig] = useState<
    IWidgetConfigRow[]
  >([]);

  const [allWidgetsChecked, setAllWidgetsChecked] = useState<AllUsersChecked>({
    checked: false,
    touched: false,
  });

  const [renderedRows, setRenderedRows] = useState<number[]>([]);

  React.useEffect(() => {
    const status = updateWidgetsConfigMutation.status;
    if (status === "idle" || status === mutationStatus) return;

    if (status === "loading") setMutationStatus("loading");
    else
      setTimeout(() => {
        setMutationStatus(status);
      }, 1000);
  }, [updateWidgetsConfigMutation.status, mutationStatus]);

  const updateRenderedRows = (newRenderedRows: number[]) => {
    setRenderedRows(newRenderedRows);
  };

  const updateAllWidgetsChecked = (checked: boolean) => {
    setAllWidgetsChecked({
      checked: checked,
      touched: true,
    });
  };

  const updateWidgetsConfig = (widgetConfig: IWidgetConfigRow) => {
    setEditedWidgetsConfig((prevWidgetsConfig) => {
      let updatedEditedWidgetsConfig: IWidgetConfigRow[] = [];

      // creating a new copy
      prevWidgetsConfig.forEach((prevWidgetConfig) => {
        updatedEditedWidgetsConfig.push({ ...prevWidgetConfig });
      });
      const prevUserPropIndex = updatedEditedWidgetsConfig.findIndex(
        (prevWidgetConfig) => prevWidgetConfig.id === widgetConfig.id
      );
      // If the widget config item already exists. In this case, delete it from the existing list.
      if (prevUserPropIndex > -1) {
        updatedEditedWidgetsConfig[prevUserPropIndex].isAvailable =
          widgetConfig.isAvailable;
      }
      // If the widget config item doesn't exist. In this case, add it to the existing list.
      else updatedEditedWidgetsConfig.push(widgetConfig);

      return updatedEditedWidgetsConfig;
    });
  };

  const updateMultipleWidgetsConfig = (widgetsConfig: IWidgetConfigRow[]) => {
    setEditedWidgetsConfig(widgetsConfig);
  };

  const onSaveChanges = () => {
    if (widgetConfig.data === undefined) return;

    const finalUpdatedWidgetsConfig: IWidgetConfigRow[] = [];
    editedWidgetsConfig.forEach((editedWidgetConfig) => {
      const untouchedWidgetConfig = widgetConfig.data.find(
        (widgetCon) => widgetCon.id === editedWidgetConfig.id
      );

      if (untouchedWidgetConfig?.isAvailable !== editedWidgetConfig.isAvailable)
        finalUpdatedWidgetsConfig.push({ ...editedWidgetConfig });
    });
    const putWidgetsConfig: IPutWidgetConfigRow[] = [];
    finalUpdatedWidgetsConfig.forEach((updatedWidgetConfig) => {
      const filteredWidgetIndex = renderedRows.findIndex(
        (renderedRow) => renderedRow === updatedWidgetConfig.id
      );
      if (filteredWidgetIndex > -1)
        putWidgetsConfig.push({
          widgetId: updatedWidgetConfig.id,
          isAvailable: updatedWidgetConfig.isAvailable,
        });
    });

    const widgetsToBeUpdated: IPutWidgetConfigurations = {
      widgetConfigurations: putWidgetsConfig,
    };
    updateWidgetsConfigMutation.mutate(widgetsToBeUpdated, {
      onSuccess: (successObj) =>
        queryClient.invalidateQueries(["usersWidgetConfiguration", accountId]),
    });
  };

  const widgetsToBeUpdated = () => {
    if (
      editedWidgetsConfig.length === 0 ||
      updateWidgetsConfigMutation.isLoading
    )
      return true;

    if (widgetConfig.data === undefined) return true;

    for (const editedWidgetConfig of editedWidgetsConfig) {
      const actualWidgetConfig = widgetConfig.data.find(
        (oldWidgetConfig) => oldWidgetConfig.id === editedWidgetConfig.id
      );

      if (actualWidgetConfig === undefined) continue;

      if (actualWidgetConfig.isAvailable !== editedWidgetConfig.isAvailable)
        return false;
    }
    return true;
  };

  const renderAlert = mutationStatus !== "idle";
  let alertMessage = "";
  let alertType: AlertSeverityType = "info";

  if (mutationStatus === "loading") {
    alertMessage = "Updating Widget Configuration.";
    alertType = "info";
  } else if (mutationStatus === "success") {
    alertMessage = "Successfully updated Widget Configuration.";
    alertType = "success";
  } else if (mutationStatus === "error") {
    alertMessage = "Something went wrong. Please reload and try again.";
    alertType = "error";
  }

  return (
    <WidgetConfigContext.Provider
      value={{
        widgetsConfig: editedWidgetsConfig,
        updateWidgetsConfig: updateWidgetsConfig,
        updateMultipleWidgetsConfig: updateMultipleWidgetsConfig,
        allWidgetsChecked: allWidgetsChecked,
        updateAllWidgetsChecked: updateAllWidgetsChecked,
      }}
    >
      {/* Alert to show loading, error and success messages */}
      {renderAlert && (
        <SimpleAlert
          severityType={alertType}
          message={alertMessage}
          onClose={() => {
            setMutationStatus("idle");
          }}
          alertStyles={{ width: "100%" }}
          styles={{ position: "inherit", width: "100%" }}
        />
      )}
      <Grid container className="property-action-bar">
        <Grid item>
          <Grid container sx={{ width: "auto" }}>
            <Grid item></Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container sx={{ width: "auto" }}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveOutlinedIcon />}
                size="small"
                disabled={widgetsToBeUpdated()}
                onClick={onSaveChanges}
              >
                Save Widget Configuration
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Loader */}
      {widgetConfig.isLoading && (
        <Box m="auto">
          <CircularProgress />
        </Box>
      )}
      {/* Grid */}
      {widgetConfig.isSuccess && (
        <WidgetConfigGrid
          rowData={widgetConfig.data}
          updateRenderedRows={updateRenderedRows}
        />
      )}
    </WidgetConfigContext.Provider>
  );
};

export default WidgetConfiguration;
