import React from "react";

import { UserPropAssignContext } from "./models/userPropertyAssignmentContext";

export const UPAContext = React.createContext<UserPropAssignContext>({
  userProperties: [],
  updateUserProperties: () => {},
  updateMultipleUserProperties: () => {},
  allUsersChecked: {
    checked: false,
    touched: false,
  },
  updateAllUsersChecked: () => {},
});
