import axios from "axios";
import TBuildingsSearchFilter from "../model/buildingListSearchFilter";
import { TBuildingList } from "../model/buildingListModel";
import { TCreateBuildingModel } from "../model/createBuildingModel";
import { TUpdateBuildingModel } from "../model/updateBuildingModel";
import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "../../../../shared/api/authorizedAxios";
import { useQuery, useMutation } from "@tanstack/react-query";

export const useGetBuildingList = (filter?: TBuildingsSearchFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getBuildingList", filter],
    () => {
      const source = axios.CancelToken.source();

      const url: string = `/propertyService/v1/properties/${filter?.PropertyId}/buildings`;

      let promise = authorizedApiAxios().get<TBuildingList>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: filter?.accountIdentifier!,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => response.data,
    }
  );
};

export const useCreateBuilding = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: TCreateBuildingModel) => {
    const source = axios.CancelToken.source();
    const url: string = `propertyService/v1/properties/${item.propertyId}/buildings`;

    let promise = authorizedApiAxios().post<string>(url, item, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: item.accountIdentifier,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useUpdateBuilding = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: TUpdateBuildingModel) => {
    const source = axios.CancelToken.source();
    const url: string = `propertyService/v1/properties/${item.propertyId}/buildings/${item.id}`;

    let promise = authorizedApiAxios().patch<string>(url, item, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: item.accountIdentifier,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};
