import React, { useContext } from "react";
import {
  Box,
  CircularProgress,
  Typography
} from "@mui/material";
import { useGetPropertyList } from "../api/propertyService";
import PropertiesGrid from "./PropertyListGrid";
import PortfolioContext from "../../../portfolio/util/portfolioContext";
import UserOptions from "../../../layout/views/userOptions";

const PropertyList: React.FC = () => {
  const {
    propertiesStartDate,
    propertiesEndDate,
  } = useContext(PortfolioContext);

  const { data: propertyList, isFetching } = useGetPropertyList({
    startYear: propertiesStartDate.getFullYear(),
    endYear: propertiesEndDate.getFullYear(),
    startMonth: propertiesStartDate.getMonth() + 1,
    endMonth: propertiesEndDate.getMonth() + 1,
  });

  return isFetching ? (
    <Box m="auto">
      <CircularProgress />
    </Box>
  ) : (
    <React.Fragment>
      <Box className={"appHeader"}>
        <Box>
          <Typography variant="h1" sx={{ color: "primary.dark", }}>Properties</Typography>
        </Box>
        {/* User Options */}
        <UserOptions />
      </Box>
      {propertyList !== undefined && (
        <div>
          <div
            id="property-list"
            className="tab-pane"
          >
            <PropertiesGrid
              propertyList={propertyList}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PropertyList;
