import React, { Fragment } from "react";
import { Box } from "@mui/material";

import PropertyList from "./propertyList";

const Properties: React.FunctionComponent = () => {
  React.useEffect(() => {
    document.title = "Workspace | Properties";
  }, []);

  return (
    <Fragment>
      <Box className="mainPropertiesContainer">
        <PropertyList />
      </Box>
    </Fragment>
  );
};
export default Properties;
