import {
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  ClickAwayListener,
} from "@mui/material";
import headerStyles from "../headerStyles";
import { Link } from "react-router-dom";

interface AccountsMenuProps {
  setLeftMenuAccountingCollapseClose: () => void;
}

const AccountsMenu: React.FunctionComponent<AccountsMenuProps> = (props) => {
  const classes = headerStyles();
  return (
    <ClickAwayListener
      onClickAway={() => {
        props.setLeftMenuAccountingCollapseClose();
      }}
    >
      <List
        component="div"
        aria-labelledby="leftMenuAccountingCollapseContainer"
        disablePadding
        className={classes.leftMenuList}
        onClick={() => props.setLeftMenuAccountingCollapseClose()}
        subheader={
          <ListSubheader
            component="div"
            id="leftMenuAccountingCollapseContainer"
          ></ListSubheader>
        }
      >
        <ListItem button component={Link} to="/">
          <ListItemText primary="Accounts Payable" />
        </ListItem>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Job Costing" />
        </ListItem>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Payment Batches" />
        </ListItem>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Purchase Orders" />
        </ListItem>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Vendors" />
        </ListItem>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Virtual Payments" />
        </ListItem>
      </List>
    </ClickAwayListener>
  );
};

export default AccountsMenu;