import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface Props {
  isExpanded: boolean;
}

const ToggleChevron: React.FC<Props> = ({ isExpanded }) => {
  return (
    <React.Fragment>
      {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </React.Fragment>
  );
};

export default ToggleChevron;
