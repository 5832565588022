import {
  //responsiveFontSizes
  createTheme,
  //adaptV4Theme,
} from "@mui/material/styles";
//import createBreakpoints from '@mui/material/styles/createBreakpoints'

declare module '@mui/material/styles' {
    interface TypographyVariants {
        body3: React.CSSProperties;
    }
    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        body3?: React.CSSProperties;
    }
}
  
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        body3: true;
    }
}

const theme = createTheme({
  //adaptV4Theme({
    typography: {
      fontFamily: ['"Lato"', "sans-serif"].join(","),
      fontSize: 16,
      htmlFontSize: 16,
      body3: {
        fontSize: 14,
        fontWeight: 600,
      },
      button: {
        textTransform: "none",
      },
    },
    /*
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [lato],
        },
      },
    },
    */
    components: {
        MuiCssBaseline: {
            /*
            styleOverrides: `
                @font-face {
                    font-family: '"Lato", sans-serif';
                }
            `,
            */
           styleOverrides: {
                body: {
                    fontFamily: '"Lato", sans-serif',
                    backgroundColor: "#f5f8fb",
                },
                button: {
                    backgroundColor: "#021a3c",
                    "&:hover": {
                        backgroundColor: "#066cfa",
                    },
                },
           },
        },
    },
    palette: {
      primary: {
        light: "#e0edff",
        main: "#066cfa",
        dark: "#021a3c",
      },
      secondary: {
        main: "#ff7646",
        dark: "#cc7c00",
      },
      error: {
        main: "#f43027",
      },
      warning: {
        main: "#fec22e",
      },
      success: {
        main: "#25a567",
      },
      neutral700: {
        light: "#496680",
        main: "#344f67",
        dark: "#253c52",
      },
      neutral: {
        light: "#bdccdc",
        main: "#a0b4c7",
        dark: "#637e97",
      },
      neutral100: {
        light: "#f5f8fb",
        main: "#ecf1f7",
        dark: "#dfe7f1",
      },
      neutral25: {
        main: "#f9fafc",
      },
      neutralPrimary: {
        main: "#0a1a27",
      },
      neutralSecondary: {
        main: "#ffffff",
      },
    },
  //})
});

theme.typography.body1 = {
  //fontFamily: '"Lato", sans-serif',
  fontSize: 15,
  fontWeight: 400,
  //color: theme.palette.primary.dark,
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
};
theme.typography.body2 = {
  //fontFamily: '"Lato", sans-serif',
  fontSize: 14,
  fontWeight: 500,
  //color: theme.palette.primary.dark,
};
theme.typography.caption = {
  //fontFamily: '"Lato", sans-serif',
  fontSize: 12,
  fontWeight: 400,
  //color: theme.palette.primary.dark,
};
theme.typography.h1 = {
  //fontFamily: '"Lato", sans-serif',
  fontSize: 24,
  fontWeight: 600,
  //color: theme.palette.primary.dark,
};
theme.typography.h2 = {
  //fontFamily: '"Lato", sans-serif',
  fontSize: 20,
  fontWeight: 600,
  //color: theme.palette.primary.dark,
};
theme.typography.h3 = {
  //fontFamily: '"Lato", sans-serif',
  fontSize: 15,
  fontWeight: 500,
  //color: theme.palette.primary.dark,
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
};
theme.typography.h4 = {
  //fontFamily: '"Lato", sans-serif',
  fontSize: 14,
  fontWeight: 700,
  textTransform: "uppercase",
  letterSpacing: 0.28,
  //color: theme.palette.primary.dark,
};
theme.typography.subtitle1 = {
  //fontFamily: '"Lato", sans-serif',
  fontSize: 16,
  fontWeight: 600,
  //color: theme.palette.primary.dark,
};
theme.typography.subtitle2 = {
  //fontFamily: '"Lato", sans-serif',
  fontSize: 14,
  fontWeight: 500,
  //color: theme.palette.primary.dark,
};

//theme = responsiveFontSizes(theme);
export default theme;
