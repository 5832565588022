import React from "react";
import { styled } from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Option } from "../../model/rentRollListModel";
import { TableHead } from "@mui/material";

const CustomizedTable = styled(Table)`
  & thead th {
    border-top: 1px solid var(--neutral-grey-150);
    color: var(--neutral-grey-600);
  }
  & th {
    padding: 12px;
    border-right: 1px solid var(--neutral-grey-150);
    border-bottom: 1px solid var(--neutral-grey-150);
    vertical-align: middle;
    line-height: 1.3;
  }
  & th:first-child {
    padding-right: 34px;
    padding-left: 34px;
  }
  & th:last-child {
    border-right: 0;
  }
  & td {
    padding: 12px;
    border-right: 1px solid var(--neutral-grey-150);
    border-bottom: 1px solid var(--neutral-grey-150);
    vertical-align: middle;
    color: var(--primary-dark-blue);
  }
  & td:first-child {
    padding-right: 34px;
    padding-left: 34px;
  }
  & td:last-child {
    border-right: 0;
  }
  & .MuiTypography-body1 {
    padding: 0 !important;
    font-size: inherit !important;
    color: var(--primary-dark-blue) !important;
  }
`;

interface Props {
  options: Option[];
}
const OptionsTable: React.FC<Props> = (props) => {
  const { options } = props;

  return (
    <TableContainer>
      <CustomizedTable aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" size="medium">
              Option Type
            </TableCell>
            <TableCell align="left" size="medium">
              Description
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {options.map((option: Option, index: number) => (
            <TableRow key={index}>
              <TableCell align="left">{option.name}</TableCell>
              <TableCell align="left">{option.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </CustomizedTable>
    </TableContainer>
  );
};

export default OptionsTable;
