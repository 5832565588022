import {
  IPersonnelService,
  IPropertyDetailsService,
} from "../model/propertyOverviewModel";

export const cleanupPropertyDetails = (model: IPropertyDetailsService) => {
  if (model.roofType?.trim() === "") model.roofType = undefined;
  if (model.roofRepairs?.trim() === "") model.roofRepairs = undefined;
  if (model.floorType?.trim() === "") model.floorType = undefined;
  if (model.buildingStandardPaint?.trim() === "")
    model.buildingStandardPaint = undefined;
  if (model.buildingStandardCarpet?.trim() === "")
    model.buildingStandardCarpet = undefined;
  if (model.commonAreaPaint?.trim() === "") model.commonAreaPaint = undefined;
  if (model.commonAreaCarpet?.trim() === "") model.commonAreaCarpet = undefined;
  if (model.parkingType?.trim() === "") model.parkingType = undefined;
  if (model.parkingRates?.trim() === "") model.parkingRates = undefined;
  if (model.providerTelecom?.trim() === "") model.providerTelecom = undefined;
  if (model.providerFiber?.trim() === "") model.providerFiber = undefined;
  if (model.utilityElectricCompany?.trim() === "")
    model.utilityElectricCompany = undefined;
  if (model.utilityGasCompany?.trim() === "")
    model.utilityGasCompany = undefined;
  if (model.utilityWaterCompany?.trim() === "")
    model.utilityWaterCompany = undefined;
  if (model.buildingHours?.trim() === "") model.buildingHours = undefined;
  if (model.hvacHours?.trim() === "") model.hvacHours = undefined;
  if (model.hvacOvertimeRate?.trim() === "") model.hvacOvertimeRate = undefined;
  if (model.lastRenovation?.trim() === "") model.lastRenovation = undefined;
};

export const cleanupPersonnel = (model: IPersonnelService) => {
  if (model.assetManagementCompany?.trim() === "")
    model.assetManagementCompany = undefined;
  if (model.assetManagerContactEmail?.trim() === "")
    model.assetManagerContactEmail = undefined;
  if (model.assetManagerName?.trim() === "") model.assetManagerName = undefined;
  if (model.propertyManagementCompany?.trim() === "")
    model.propertyManagementCompany = undefined;
  if (model.propertyManagerContactEmail?.trim() === "")
    model.propertyManagerContactEmail = undefined;
  if (model.propertyManagerName?.trim() === "")
    model.propertyManagerName = undefined;
  if (model.leasingManagementCompany?.trim() === "")
    model.leasingManagementCompany = undefined;
  if (model.leasingManagerContactEmail?.trim() === "")
    model.leasingManagerContactEmail = undefined;
  if (model.leasingManagerName?.trim() === "")
    model.leasingManagerName = undefined;
  if (model.engineeringHVACManagerName?.trim() === "")
    model.engineeringHVACManagerName = undefined;
  if (model.engineeringHVACManagementCompany?.trim() === "")
    model.engineeringHVACManagementCompany = undefined;
  if (model.engineeringHVACManagerContactEmail?.trim() === "")
    model.engineeringHVACManagerContactEmail = undefined;
};