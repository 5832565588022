/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useIsFetching } from "@tanstack/react-query";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Tabs,
  Tab,
  Radio,
} from "@mui/material";
import { UilPlusCircle } from "@iconscout/react-unicons";
import { UilCalender } from "@iconscout/react-unicons";
import { UilExclamationTriangle } from "@iconscout/react-unicons";
import { UilCheck } from "@iconscout/react-unicons";
import { UilTrashAlt } from "@iconscout/react-unicons";
import { UilMultiply } from "@iconscout/react-unicons";

import {
  ICapitalPartner,
  IInvestmentDetails,
  InvestmentDetailsService,
  IValuation,
} from "../model/propertyOverviewModel";
import { useSaveInvestmentDetails } from "../api/propertyOverviewService";
import { TabPanel } from "../../../portfolio/portfolioDetails/views/propertiesRenderer";
import { a11yProps } from "../../../usermanagement/views/usermanagement";
import InfoChip, { ChipTheme, dangerStyles } from "./InfoChip";
import {
  computeChipText,
  computeCapitalPartnerErrorText,
  isValidValuationId,
  prefixCapitalPartId,
  trimCapitalPartners,
  prefixValuationId,
  trimValuations,
} from "../util/investmentDetailsUtil";
import {
  maxCapitalPartners,
  maxNumberField,
  maxTextField,
  numberErrorMessage,
  requiredErrorMessage,
  textErrorMessage,
} from "../util/errorTexts";

const invalidDateString = "Please enter a valid date";

type InvalidValuation = {
  id: string;
  isValid: boolean;
};

interface Props {
  handlePopupClose: (shouldRefreshData?: boolean) => void;
  showDialog: boolean;
  accountId: string;
  propertyId: string;
  investmentDetails: IInvestmentDetails;
  valuations?: IValuation[];
  capitalPartners?: ICapitalPartner[];
}

const chipIconSize = "18";
const disableStyles = {
  color: "rgba(0, 0, 0, 0.26)",
  backgroundColor: "rgba(0, 0, 0, 0.12)",
  WebkitBackgroundClip: "text",
};

export type TChipTheme = ChipTheme | undefined;

const InvestmentDetailsEdit: React.FC<Props> = (props) => {
  const [tabValue, setTabValue] = React.useState(0);
  const [isYourFirmId, setIsYourFirmId] = React.useState<string | undefined>(
    props.capitalPartners?.find((cp) => cp.isOwner)?.id
  );
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { valuations, capitalPartners, investmentDetails } = props;
  const isFetching = useIsFetching();
  const acquisitionDate = investmentDetails.acquisitionDate;
  const dispositionDate = investmentDetails.dispositionDate;
  const valuationAtCloseDate = investmentDetails.valuationAtCloseDate;
  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<InvestmentDetailsService>({
    defaultValues: {
      ...investmentDetails,
      acquisitionDate: acquisitionDate === undefined ? null : acquisitionDate,
      dispositionDate: dispositionDate === undefined ? null : dispositionDate,
      valuationAtCloseDate:
        valuationAtCloseDate === undefined ? null : valuationAtCloseDate,
      valuations: valuations,
      capitalPartners: capitalPartners,
    },
  });

  const {
    fields: capitalPartnersFields,
    append: appendCapitalPartner,
    remove: removeCapitalPartner,
  } = useFieldArray({
    keyName: "key",
    name: "capitalPartners",
    control,
  });

  const {
    fields: valuationFields,
    append: appendValuation,
    remove: removeValuation,
  } = useFieldArray({
    keyName: "key",
    name: "valuations",
    control,
  });

  // watching values for capital partners
  const watchCapitalPartners = useWatch({
    control,
    name: "capitalPartners",
  });

  // watching values for capital partners
  const watchValuations = useWatch({
    control,
    name: "valuations",
  });

  const [chipDetails, setChipDetails] = useState<{
    chipTheme: TChipTheme;
    sumOfPercents: number;
    missingName: boolean;
    chipStartIcon: React.ReactNode;
  }>({
    chipTheme: undefined,
    missingName: false,
    sumOfPercents: 0,
    chipStartIcon: undefined,
  });

  const [invalidValuation, setInvalidValuation] = useState<InvalidValuation[]>(
    []
  );

  useEffect(() => {
    if (watchCapitalPartners.length === 0) {
      setChipDetails({
        chipTheme: undefined,
        missingName: false,
        sumOfPercents: 0,
        chipStartIcon: undefined,
      });
      return;
    }
    if (watchCapitalPartners && watchCapitalPartners.length > 0) {
      let newSumOfPercents = 0;
      let missingName = false;
      for (const capitalPartner of watchCapitalPartners) {
        if (!isNaN(capitalPartner.capitalPartnerOwnership)) {
          newSumOfPercents += capitalPartner.capitalPartnerOwnership;
          if (
            !missingName &&
            capitalPartner.capitalPartnerOwnership > 0 &&
            capitalPartner.capitalPartnerName.trim().length === 0
          )
            missingName = true;
        }
      }
      if (newSumOfPercents < 100)
        setChipDetails({
          chipTheme: "warning",
          missingName: missingName,
          sumOfPercents: newSumOfPercents,
          chipStartIcon: undefined,
        });
      else if (newSumOfPercents === 100) {
        setChipDetails({
          chipTheme: "success",
          missingName: missingName,
          sumOfPercents: newSumOfPercents,
          chipStartIcon: <UilCheck size={chipIconSize} />,
        });
      } else if (newSumOfPercents > 0) {
        setChipDetails({
          chipTheme: "danger",
          missingName: missingName,
          sumOfPercents: newSumOfPercents,
          chipStartIcon: <UilExclamationTriangle size={chipIconSize} />,
        });
      }
    }
  }, [watchCapitalPartners]);

  const [hasValuationsError, setHasValuationsError] = useState(false);
  useEffect(() => {
    for (let valuation of watchValuations) {
      // if both the valuation and valuation date are empty, then enable "save" button
      if (Number.isNaN(valuation.valuation) && valuation.valuationDate === null)
        continue;
      // if only one of the fields is filled and the other one is empty (0 for valuation is considered empty), then disable "save" button
      else if (
        (Number.isNaN(valuation.valuation) && valuation.valuationDate !== null) ||
        (!Number.isNaN(valuation.valuation) &&
          (valuation.valuation || 0) > 0 &&
          valuation.valuationDate === null)
      ) {
        setHasValuationsError(true);
        return;
      }
    }
    setHasValuationsError(false);
  }, [watchValuations]);

  const chipTheme = chipDetails.chipTheme;
  const sumOfPercents = chipDetails.sumOfPercents;
  const chipStartIcon = chipDetails.chipStartIcon;

  const patchInvestmentDetails = useSaveInvestmentDetails();

  const [isAcquisitionDate, setIsAcquisitionDate] = useState<any>(null);
  const [isValuationAtCloseDate, setIsValuationAtCloseDate] =
    useState<any>(null);
  const [isDispositionDate, setIsDispositionDate] = useState<any>(null);

  const onSubmit = handleSubmit((model: InvestmentDetailsService) => {
    const valuationAtClose = model.valuationAtClose;
    const valuationAtCloseDate = model.valuationAtCloseDate;

    const isValuationAtCloseEmpty =
      !valuationAtClose || isNaN(valuationAtClose);
    const isValuationAtCloseDateEmpty = !valuationAtCloseDate;
    const isValuationAtCloseOnlyEmpty =
      isValuationAtCloseEmpty && !isValuationAtCloseDateEmpty;
    const isValuationAtCloseDateOnlyEmpty =
      !isValuationAtCloseEmpty && isValuationAtCloseDateEmpty;

    if (isValuationAtCloseOnlyEmpty)
      setError("valuationAtClose", {
        type: "validate",
        message: requiredErrorMessage,
      });

    if (isValuationAtCloseDateOnlyEmpty)
      setError("valuationAtCloseDate", {
        type: "validate",
        message: requiredErrorMessage,
      });

    if (isValuationAtCloseOnlyEmpty || isValuationAtCloseDateOnlyEmpty) return;

    model.accountId = props.accountId;
    model.propertyId = props.propertyId;
    if (model.investmentDetailNotes?.trim().length === 0)
      model.investmentDetailNotes = undefined;

    model.capitalPartners = trimCapitalPartners(
      model.capitalPartners,
      isYourFirmId
    );
    model.valuations = trimValuations(model.valuations);
    patchInvestmentDetails.mutate(model, {
      onSuccess: (data) => {
        props.handlePopupClose(true);
      },
    });
  });

  // There cannot be more than n capital partners
  const capitalPartnersLimitError =
    capitalPartnersFields.length >= maxCapitalPartners;

  let cpOnClick = () =>
    appendCapitalPartner({
      id: prefixCapitalPartId + Date.now().toString(),
      capitalPartnerName: "",
      capitalPartnerOwnership: 0,
      isOwner: false
    });

  if (capitalPartnersLimitError) {
    cpOnClick = () => {};
  }

  const renderWarningText = (chipTheme: TChipTheme) => {
    const warningText = computeCapitalPartnerErrorText(chipTheme);

    if (warningText.trim().length === 0) return <React.Fragment />;
    else {
      return (
        <Box display="flex" sx={{ color: dangerStyles.color }} mt={2}>
          <UilExclamationTriangle size={chipIconSize} />
          <Box ml={1} sx={{ fontWeight: 600 }}>
            {warningText}
          </Box>
        </Box>
      );
    }
  };

  const onError = (
    error: any,
    item: "acquisitionDate" | "dispositionDate" | "valuationAtCloseDate"
  ) => {
    if (item === "acquisitionDate") setIsAcquisitionDate(error);
    else if (item === "dispositionDate") setIsDispositionDate(error);
    else if (item === "valuationAtCloseDate") setIsValuationAtCloseDate(error);
  };

  const isDisable = () => {
    let isInvalidValuationsDate = false;
    for (let val of invalidValuation) {
      if (!val.isValid) {
        isInvalidValuationsDate = true;
        break;
      }
    }
    if (
      patchInvestmentDetails.isLoading ||
      isFetching > 0 ||
      isAcquisitionDate !== null ||
      isDispositionDate !== null ||
      isValuationAtCloseDate !== null ||
      (chipDetails.chipTheme !== "success" &&
        chipDetails.chipTheme !== undefined) ||
      chipDetails.missingName ||
      hasValuationsError ||
      isInvalidValuationsDate
    )
      return true;
    else return false;
  };

  return (
    <Dialog
      open={props.showDialog}
      aria-labelledby="investment-details-edit"
      aria-describedby="investment-details-edit-description"
      fullWidth
    >
      <DialogTitle id="investment-details-edit">
        <Typography component="span" variant="h2" color="primary.dark">
          Edit Investment Details
        </Typography>
        <IconButton
          aria-label="close"
          disabled={patchInvestmentDetails.isLoading}
          onClick={() => props.handlePopupClose(false)}
          className="icon-dialog-close"
          size="large"
        >
          <UilMultiply />
        </IconButton>
        <Typography component="div" variant="h3" color="neutral700.light">
          Please select or enter values for the fields and click the save button
          to update investment details.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <React.Fragment>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Investment Details Tabs"
                indicatorColor="primary"
                sx={{ width: "100%", backgroundColor: "neutralSecondary.main" }}
              >
                <Tab
                  label="General"
                  className={"mainTopTab"}
                  {...a11yProps(0)}
                />
                <Tab
                  label="Ownership"
                  className={"mainTopTab"}
                  {...a11yProps(1)}
                />
                <Tab
                  label="Valuation"
                  className={"mainTopTab"}
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
            {/* General Tab */}
            <TabPanel value={tabValue} index={0}>
              <Grid container spacing={1}>
                {/* Acquisition Date */}
                <Grid item xs={6}>
                  <FormControl className={"form-control"}>
                    <Typography
                      variant="body3"
                      component="label"
                    >{`Acquisition Date`}</Typography>
                    <Controller
                      name="acquisitionDate"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          inputRef={field.ref}
                          views={["year", "month", "day"]}
                          onChange={field.onChange}
                          value={field.value}
                          components={{
                            OpenPickerIcon: UilCalender,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // error={Boolean(errors?.acquisitionDate)}
                              helperText={
                                isAcquisitionDate !== null
                                  ? invalidDateString
                                  : null
                              }
                              className={
                                "form-control-field form-control-datepicker"
                              }
                            />
                          )}
                          onError={(error) => onError(error, "acquisitionDate")}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                {/* Disposition Date */}
                <Grid item xs={6}>
                  <FormControl className={"form-control"}>
                    <Typography
                      variant="body3"
                      component="label"
                    >{`Disposition Date`}</Typography>
                    <Controller
                      name="dispositionDate"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          inputRef={field.ref}
                          views={["year", "month", "day"]}
                          onChange={field.onChange}
                          value={field.value}
                          components={{
                            OpenPickerIcon: UilCalender,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // error={Boolean(errors?.dispositionDate)}
                              helperText={
                                isDispositionDate !== null
                                  ? invalidDateString
                                  : null
                              }
                              className={
                                "form-control-field form-control-datepicker"
                              }
                            />
                          )}
                          onError={(error) => onError(error, "dispositionDate")}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                {/* Invested Equity */}
                <Grid item xs={6}>
                  <FormControl className={"form-control"}>
                    <Typography variant="body3" component="label">
                      Invested Equity ($)
                    </Typography>
                    <TextField
                      size="small"
                      id="invested-equity"
                      variant="outlined"
                      type="number"
                      {...register("investedEquity", {
                        valueAsNumber: true,
                        min: {
                          value: 0,
                          message: numberErrorMessage,
                        },
                        max: {
                          value: maxNumberField,
                          message: numberErrorMessage,
                        },
                      })}
                      error={Boolean(errors?.investedEquity)}
                      helperText={
                        Boolean(errors?.investedEquity)
                          ? errors?.investedEquity?.message
                          : null
                      }
                      className={"form-control-field"}
                    />
                  </FormControl>
                </Grid>
                {/* Acquisition Price */}
                <Grid item xs={6}>
                  <FormControl className={"form-control"}>
                    <Typography variant="body3" component="label">
                      Acquisition Price ($)
                    </Typography>
                    <TextField
                      size="small"
                      id="acquisition-price"
                      variant="outlined"
                      type="number"
                      {...register("acquisitionPrice", {
                        valueAsNumber: true,
                        min: {
                          value: 0,
                          message: numberErrorMessage,
                        },
                        max: {
                          value: maxNumberField,
                          message: numberErrorMessage,
                        },
                      })}
                      error={Boolean(errors?.acquisitionPrice)}
                      helperText={
                        Boolean(errors?.acquisitionPrice)
                          ? errors?.acquisitionPrice?.message
                          : null
                      }
                      className={"form-control-field"}
                    />
                  </FormControl>
                </Grid>
                {/* Valuation at Close */}
                <Grid item xs={6}>
                  <FormControl className={"form-control"}>
                    <Typography variant="body3" component="label">
                      Valuation at Close ($)
                    </Typography>
                    <TextField
                      size="small"
                      id="valuation-at-close"
                      variant="outlined"
                      type="number"
                      {...register("valuationAtClose", {
                        valueAsNumber: true,
                        min: {
                          value: 0,
                          message: numberErrorMessage,
                        },
                        max: {
                          value: maxNumberField,
                          message: numberErrorMessage,
                        },
                      })}
                      error={Boolean(errors?.valuationAtClose)}
                      helperText={
                        Boolean(errors?.valuationAtClose)
                          ? errors?.valuationAtClose?.message
                          : null
                      }
                      className={"form-control-field"}
                    />
                  </FormControl>
                </Grid>
                {/* Valuation at Close Date */}
                <Grid item xs={6}>
                  <FormControl className={"form-control"}>
                    <Typography
                      variant="body3"
                      component="label"
                    >{`Valuation at Close Date`}</Typography>
                    <Controller
                      name="valuationAtCloseDate"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          inputRef={field.ref}
                          views={["year", "month", "day"]}
                          onChange={field.onChange}
                          value={field.value}
                          components={{
                            OpenPickerIcon: UilCalender,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={
                                Boolean(errors?.valuationAtCloseDate) ||
                                isValuationAtCloseDate !== null
                              }
                              helperText={
                                isValuationAtCloseDate !== null
                                  ? invalidDateString
                                  : errors?.valuationAtCloseDate
                                  ? requiredErrorMessage
                                  : null
                              }
                              className={
                                "form-control-field form-control-datepicker"
                              }
                            />
                          )}
                          onError={(error) =>
                            onError(error, "valuationAtCloseDate")
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                {/* Leverage at Acquisition */}
                <Grid item xs={6}>
                  <FormControl className={"form-control"}>
                    <Typography variant="body3" component="label">
                      Leverage at Acquisition (%)
                    </Typography>
                    <TextField
                      size="small"
                      id="leverage"
                      variant="outlined"
                      type="number"
                      {...register("leverageAtAcquisition", {
                        valueAsNumber: true,
                        min: {
                          value: 0,
                          message: numberErrorMessage,
                        },
                        max: {
                          value: 100,
                          message: numberErrorMessage,
                        },
                      })}
                      error={Boolean(errors?.leverageAtAcquisition)}
                      helperText={
                        Boolean(errors?.leverageAtAcquisition)
                          ? errors?.leverageAtAcquisition?.message
                          : null
                      }
                      className={"form-control-field"}
                    />
                  </FormControl>
                </Grid>
                {/* Notes */}
                <Grid item xs={12}>
                  <FormControl className={"form-control"}>
                    <Typography
                      variant="body3"
                      component="label"
                    >{`Notes`}</Typography>
                    <TextField
                      size="small"
                      variant="outlined"
                      multiline
                      rows={4}
                      className={"form-control-field form-control-textarea"}
                      error={Boolean(errors?.investmentDetailNotes)}
                      helperText={
                        Boolean(errors?.investmentDetailNotes)
                          ? errors?.investmentDetailNotes?.message
                          : null
                      }
                      {...register("investmentDetailNotes", {
                        validate: {
                          maxCharacters: (value) => {
                            if (!value) return true;
                            if (value?.trim().length > 250)
                              return "Maximum 250 characters";
                          },
                        },
                      })}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </TabPanel>
            {/* Ownership Tab */}
            <TabPanel value={tabValue} index={1}>
              <Typography mt={2} variant="h3" color="primary.dark">
                Percentage or sum of all percentages needs to be 100%. You can
                add up to 4 capital partners.
              </Typography>
              <Grid container columns={36} spacing={1}>
                {capitalPartnersFields.map((field, index) => {
                  const isFirmChecked = isYourFirmId === field?.id;

                  return (
                    <React.Fragment key={field.id}>
                      {/* Your Firm */}
                      <Grid item xs={5} style={{ marginTop: "auto" }}>
                        <FormControl
                          className={"form-control"}
                          style={{ alignItems: "center", display: "flex" }}
                        >
                          {index === 0 && (
                            <Typography variant="body3" component="label">
                              Your Firm
                            </Typography>
                          )}
                          <Radio
                            checked={isFirmChecked}
                            onClick={() =>
                              setIsYourFirmId(
                                isFirmChecked ? undefined : field?.id
                              )
                            }
                            size="small"
                            disableRipple
                            value={field?.id}
                            name={field.key}
                            inputProps={{ "aria-label": field.key }}
                          />
                        </FormControl>
                      </Grid>
                      {/* Capital Partner */}
                      <Grid item xs={14}>
                        <FormControl className={"form-control"}>
                          <Typography variant="body3" component="label">
                            Capital Partner
                          </Typography>
                          <TextField
                            size="small"
                            id={`capital-partner-name-${field.id}`}
                            variant="outlined"
                            {...register(
                              `capitalPartners.${index}.capitalPartnerName` as const,
                              {
                                maxLength: {
                                  value: maxTextField,
                                  message: textErrorMessage,
                                },
                              }
                            )}
                            error={Boolean(
                              errors?.capitalPartners?.[index]
                                ?.capitalPartnerName
                            )}
                            helperText={
                              Boolean(
                                errors?.capitalPartners?.[index]
                                  ?.capitalPartnerName
                              )
                                ? errors?.capitalPartners?.[index]
                                    ?.capitalPartnerName?.message
                                : null
                            }
                            className={"form-control-field"}
                          />
                        </FormControl>
                      </Grid>
                      {/* Ownership Percentage */}
                      <Grid item xs={14}>
                        <FormControl className={"form-control"}>
                          <Typography variant="body3" component="label">
                            Ownership Percentage
                          </Typography>
                          <TextField
                            size="small"
                            id={`capital-partner-ownership-${field.id}`}
                            variant="outlined"
                            type="number"
                            {...register(
                              `capitalPartners.${index}.capitalPartnerOwnership` as const,
                              {
                                valueAsNumber: true,
                                min: {
                                  value: 0,
                                  message: numberErrorMessage,
                                },
                                max: {
                                  value: 100,
                                  message: numberErrorMessage,
                                },
                              }
                            )}
                            error={Boolean(
                              errors?.capitalPartners?.[index]
                                ?.capitalPartnerOwnership
                            )}
                            helperText={
                              Boolean(
                                errors?.capitalPartners?.[index]
                                  ?.capitalPartnerOwnership
                              )
                                ? errors?.capitalPartners?.[index]
                                    ?.capitalPartnerOwnership?.message
                                : null
                            }
                            className={"form-control-field"}
                          />
                        </FormControl>
                      </Grid>
                      {/* Delete button */}
                      <Grid item xs={3} style={{ marginTop: "auto" }}>
                        <Box
                          sx={{ paddingBottom: "4px", cursor: "pointer" }}
                          onClick={() => removeCapitalPartner(index)}
                        >
                          <UilTrashAlt />
                        </Box>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
              {renderWarningText(chipTheme)}
              <Box display="flex" justifyContent="space-between">
                <Box
                  display="flex"
                  mt={2}
                  color="primary.main"
                  alignItems="center"
                  onClick={cpOnClick}
                  style={
                    capitalPartnersLimitError
                      ? disableStyles
                      : { cursor: "pointer" }
                  }
                  className="icon-add"
                >
                  <UilPlusCircle />
                  <Typography component="span" variant="h3" ml={1}>
                    Add capital partner
                  </Typography>
                </Box>
                {chipTheme && (
                  <InfoChip
                    chipTheme={chipTheme}
                    chipText={computeChipText(chipTheme, sumOfPercents)}
                    startIcon={chipStartIcon}
                  />
                )}
              </Box>
            </TabPanel>
            {/* Valuation */}
            <TabPanel value={tabValue} index={2}>
              <Grid container columns={13} spacing={1}>
                {valuationFields.map((field, index) => {
                  const fieldId = field.id;
                  return (
                    <React.Fragment key={field.id}>
                      {/* Valuation Amount */}
                      <Grid item xs={4}>
                        <FormControl className={"form-control"}>
                          <Typography variant="body3" component="label">
                            Valuation Amount ($)
                          </Typography>
                          <TextField
                            size="small"
                            id={`valuation-amount-${field.id}`}
                            variant="outlined"
                            type="number"
                            {...register(
                              `valuations.${index}.valuation` as const,
                              {
                                valueAsNumber: true,
                                min: {
                                  value: 0,
                                  message: numberErrorMessage,
                                },
                                max: {
                                  value: maxNumberField,
                                  message: numberErrorMessage,
                                },
                              }
                            )}
                            error={Boolean(
                              errors?.valuations?.[index]?.valuation
                            )}
                            helperText={
                              Boolean(errors?.valuations?.[index]?.valuation)
                                ? errors?.valuations?.[index]?.valuation
                                    ?.message
                                : null
                            }
                            className={"form-control-field"}
                          />
                        </FormControl>
                      </Grid>
                      {/* Valuation Date */}
                      <Grid item xs={4}>
                        <FormControl className={"form-control"}>
                          <Typography
                            variant="body3"
                            component="label"
                          >{`Valuation Date`}</Typography>
                          <Controller
                            name={`valuations.${index}.valuationDate` as const}
                            control={control}
                            render={({ field }) => {
                              const invalidValuationObj = invalidValuation.find(
                                (val) => val.id === fieldId
                              );
                              let helperText: string | null = null;
                              if (
                                invalidValuationObj &&
                                !invalidValuationObj.isValid
                              ) {
                                helperText = invalidDateString;
                              }
                              return (
                                <DatePicker
                                  inputRef={field.ref}
                                  views={["year", "month", "day"]}
                                  onChange={field.onChange}
                                  value={field.value}
                                  components={{
                                    OpenPickerIcon: UilCalender,
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      // error={Boolean(
                                      //   errors?.valuations?.[index]?.valuationDate
                                      // )}
                                      helperText={helperText}
                                      className={
                                        "form-control-field form-control-datepicker"
                                      }
                                    />
                                  )}
                                  onError={(error) => {
                                    let isValid = false;
                                    if (error === null) isValid = true;

                                    if (invalidValuationObj === undefined) {
                                      setInvalidValuation((currentVals) => {
                                        const newVals: InvalidValuation[] = [
                                          ...currentVals,
                                          {
                                            id: fieldId?.toString() || "",
                                            isValid: isValid,
                                          },
                                        ];
                                        return newVals;
                                      });
                                    } else if (
                                      invalidValuationObj.isValid !== isValid
                                    ) {
                                      setInvalidValuation((currentVals) => {
                                        return currentVals.map((val) => {
                                          if (val.id !== fieldId) return val;
                                          return {
                                            ...val,
                                            isValid: isValid,
                                          };
                                        });
                                      });
                                    }
                                  }}
                                />
                              );
                            }}
                          />
                        </FormControl>
                      </Grid>
                      {/* Added By */}
                      <Grid item xs={4}>
                        <FormControl className={"form-control"}>
                          <Typography variant="body3" component="label">
                            Added By
                          </Typography>
                          <TextField
                            size="small"
                            id={`added-by-${field.id}`}
                            variant="outlined"
                            disabled
                            {...register(
                              `valuations.${index}.lastModifiedUserFullName` as const
                            )}
                            className={"form-control-field"}
                          />
                        </FormControl>
                      </Grid>
                      {!isValidValuationId(field.id, valuations) && (
                        <Grid item xs={1} style={{ margin: "auto" }}>
                          <Box
                            sx={{ marginTop: "24px", cursor: "pointer" }}
                            onClick={() => removeValuation(index)}
                          >
                            <UilTrashAlt />
                          </Box>
                        </Grid>
                      )}
                    </React.Fragment>
                  );
                })}
              </Grid>
              <Box
                display="flex"
                mt={2}
                color="blue"
                alignItems="center"
                onClick={() =>
                  appendValuation({
                    id: prefixValuationId + Date.now().toString(),
                    valuation: undefined,
                    valuationDate: null,
                    lastModifiedUserFullName: "",
                  })
                }
                style={{ cursor: "pointer", maxWidth: "154px" }}
              >
                <UilPlusCircle />
                <Box ml={1}>Add new valuation</Box>
              </Box>
            </TabPanel>
          </Box>
        </React.Fragment>
        {patchInvestmentDetails.isLoading && (
          <Box m="auto">
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          disabled={patchInvestmentDetails.isLoading}
          onClick={() => props.handlePopupClose(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={isDisable()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvestmentDetailsEdit;
