import React, { useState, useContext, useEffect } from "react";
import {
  GridReadyEvent,
  GridApi,
  ColumnApi,
  ColDef,
  ICellRendererParams,
  FirstDataRenderedEvent,
  ColumnEverythingChangedEvent,
} from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Box, CircularProgress, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { useQueryClient } from "@tanstack/react-query";

import {
  useDeletePortfolio,
  useGetPortfolioDetailsList,
} from "../api/portfolioService";
import { TPortfolioListFilter } from "../model/portfolioListFilter";
import DeletePortfolioRenderer from "./deletePortfolioRenderer";
import ConfirmDialog from "../../../../shared/view/confirmationDialog";
import { TDeletePortfolioModel } from "../model/deletePortfolioModel";
import PortfolioContext from "../../../portfolio/util/portfolioContext";
import { getPortfoliosColumns } from "../utils/portfoliosGrid";
import { hasSpecificPermission } from "../../util/accountPermissions";
import { EAccountPermission } from "../../../usermanagement/model/editUserModel";
import { UilUsdCircle, UilPlusCircle } from "@iconscout/react-unicons";

interface PortfolioListProps {
  filter: TPortfolioListFilter;
  gridApi: GridApi | undefined;
  gridColumnApi: ColumnApi | undefined;
  onGridRender: (
    gridApi: GridApi | undefined,
    gridColumnApi: ColumnApi | undefined
  ) => void;
  handlePopupOpen: () => void;
}

const grayStripStyles = {
  width: "110px",
  backgroundColor: "#A6B0BE",
  borderRadius: "4px",
  height: "12px",
};

const PortfoliosGrid: React.FC<PortfolioListProps> = (props) => {
  const { gridColumnApi, onGridRender } = props;

  const { data: portfolioData, isLoading } = useGetPortfolioDetailsList(
    props.filter
  );

  const rows = portfolioData === undefined ? [] : portfolioData;

  const { accountPermissions } = useContext(PortfolioContext);

  const gridFilter = props.filter;
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [deletePortfolioId, setDeletePortfolioId] = useState<string>();
  const [deletePortfolioName, setDeletePortfolioName] = useState<string>();
  const queryClient = useQueryClient();

  const hasPortfolioAccess = React.useMemo(
    () =>
      hasSpecificPermission(
        accountPermissions,
        props.filter.accountIdentifier,
        EAccountPermission.CONFIGURE_PORTFOLIOS
      ),
    [props.filter.accountIdentifier, accountPermissions]
  );

  const refreshTableData = () => {
    const uniqueIdForQuery =
      gridFilter.accountIdentifier +
      gridFilter.startMonth +
      gridFilter.startYear +
      gridFilter.endMonth +
      gridFilter.endYear;
    queryClient.invalidateQueries([
      "getPortfolioDetailsList",
      uniqueIdForQuery,
    ]);
  };

  const [columnDefs, setColumnDefs] = useState<ColDef[]>(
    getPortfoliosColumns(
      hasPortfolioAccess,
      handleDeletePortfolioClicked,
      refreshTableData
    )
  );

  useEffect(() => {
    setColumnDefs(
      getPortfoliosColumns(
        hasPortfolioAccess,
        handleDeletePortfolioClicked,
        refreshTableData
      )
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPortfolioAccess]);

  // useEffect(() => {
  //   return () => onGridRender(undefined, undefined);
  // }, [onGridRender]);

  useEffect(() => {
    if (gridColumnApi) autoSizeAll(gridColumnApi);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnDefs]);

  useEffect(() => {
    const newColumns = getPortfoliosColumns(
      hasPortfolioAccess,
      handleDeletePortfolioClicked,
      refreshTableData
    );
    setColumnDefs(newColumns);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter.accountIdentifier]);

  const deletePortfolioResponse = useDeletePortfolio();

  function LinkComponent(params: ICellRendererParams) {
    // For the row heading
    if (
      params.node.allLeafChildren !== undefined &&
      params.node.allLeafChildren.length > 0
    ) {
      const node = params.node.allLeafChildren[0].data;
      const portfolioId = node.id;
      const portfolioName = node.name;
      const accountIdentifier = node.accountIdentifier;

      return (
        <Box>
          <Link
            to={`/portfolioView/financials?portfolioId=${portfolioId}&accountIdentifier=${accountIdentifier}&description=${portfolioName}`}
          >
            {params.value}
          </Link>
        </Box>
      );
    }
    // For the rows inside the group "portfolio" - show nothing [purpose: enables filter]
    else {
      return <React.Fragment />;
    }
  }

  function handleDeletePortfolioClicked(
    portfolioId: string,
    portoflioName: string
  ) {
    setDeletePortfolioId(portfolioId);
    setDeletePortfolioName(portoflioName);
    setConfirmOpen(true);
  }

  const deletePortfolio = () => {
    const model: TDeletePortfolioModel = {
      accountIdentifier: props.filter.accountIdentifier,
      portfolioId: deletePortfolioId || "",
    };

    deletePortfolioResponse.mutate(model, {
      onSuccess: () => {
        refreshTableData();
      },
    });
  };

  const autoSizeAll = (gridColumnApi: ColumnApi) => {
    setTimeout(() => gridColumnApi?.autoSizeAllColumns(), 500);// TODO remove setTimeout when Ag-grid fixes autoSizeColumns on React 18 
  };

  const onGridReady = (event: GridReadyEvent) => {
    if (!rows || rows.length === 0) {
      // event.api.hideOverlay();
      // event.api.showNoRowsOverlay();
    }
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    onGridRender(event.api, event.columnApi);
    autoSizeAll(event.columnApi);
  };

  if (isLoading)
    return (
      <Box m="auto">
        <CircularProgress />
      </Box>
    );

  if (!isLoading && hasPortfolioAccess && (!rows || rows.length === 0))
    return (
      <Box sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Box
          sx={{
            width: "270px",
            backgroundColor: "white",
            borderRadius: "4px",
            marginBottom: "20px",
            padding: "12px 8px",
            boxShadow: "0 0 4px lightgray",
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box sx={grayStripStyles} />
            <UilUsdCircle color="#418FFB" size="20" />
          </Box>
          <Box
            sx={{
              ...grayStripStyles,
              width: "150px",
            }}
          />
        </Box>
        <Typography variant="h2" sx={{ textAlign: "center" }}>
          You have no portfolios! Select the ‘Create Portfolio’ button to start creating your portfolios.
        </Typography>
        <Button
          color="inherit"
          sx={{
            width: "160px",
            marginTop: "12px",
            boxShadow: "0 0 4px lightgray",
          }}
          onClick={props.handlePopupOpen}
        >
          <UilPlusCircle />
          Create Portfolio
        </Button>
      </Box>
    );
  else if (!isLoading && !hasPortfolioAccess && (!rows || rows.length === 0))
    return (
      <Box sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Box
          sx={{
            width: "270px",
            backgroundColor: "white",
            borderRadius: "4px",
            marginBottom: "20px",
            padding: "12px 8px",
            boxShadow: "0 0 4px lightgray",
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box sx={grayStripStyles} />
            <UilUsdCircle color="#418FFB" size="20" />
          </Box>
          <Box
            sx={{
              ...grayStripStyles,
              width: "150px",
            }}
          />
        </Box>
        <Typography variant="h2" sx={{ textAlign: "center" }}>
          You have no portfolios!
        </Typography>
      </Box>
    );

  return (
    <div
      id="portfolio-main-content"
      className="example-wrapper"
      style={{ height: "100%", width: "100%" }}
    >
      <div
        id="portfolioGrid"
        className="ag-theme-alpine ag-theme-alpine-container-override"
        style={{ height: "100%", width: "100%" }}
      >
        {confirmOpen && (
          <ConfirmDialog
            title="Delete Portfolio?"
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={deletePortfolio}
          >
            Are you sure you want to delete '{deletePortfolioName}'?
          </ConfirmDialog>
        )}
        <AgGridReact
          onGridReady={onGridReady}
          statusBar={{
            statusPanels: [
              {
                statusPanel: "agAggregationComponent",
                statusPanelParams: {
                  aggFuncs: ["count", "sum", "min", "max", "avg"],
                },
              },
            ],
          }}
          groupDisplayType={"multipleColumns"}
          enableRangeSelection={true}
          onColumnEverythingChanged={(event: ColumnEverythingChangedEvent) => {
            const currentColumnState = event.columnApi.getColumnState();
            if (
              Array.isArray(currentColumnState) &&
              currentColumnState.length > 0
            ) {
              if (currentColumnState[0].colId?.toLowerCase() !== "id") {
                event.columnApi.moveColumn("id", 0);
              }
            }
          }}
          excelStyles={[
            {
              id: "alignRight",
              alignment: {
                horizontal: "Right",
              },
            },
            {
              id: "dateFormat",
              dataType: "DateTime",
              numberFormat: { format: "mm/dd/yyyy;@" },
            },
            {
              id: "currencyFormat",
              numberFormat: { format: "\u0024 #,##0.00" },
            },
          ]}
          onFirstDataRendered={onFirstDataRendered}
          rowData={rows}
          autoGroupColumnDef={{
            minWidth: 100,
            sortable: true,
            field: "name",
            filter: true,
            cellRendererParams: {
              suppressCount: true,
            },
          }}
          components={{
            deletePortfolioRenderer: DeletePortfolioRenderer,
            linkComponent: LinkComponent,
          }}
          sortingOrder={["desc", "asc"]}
          defaultColDef={{
            minWidth: 100,
            filter: true,
            resizable: true,
            filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
          }}
        >
          {columnDefs.map((column) => (
            <AgGridColumn {...column} key={column.field} />
          ))}
        </AgGridReact>
      </div>
    </div>
  );
};

export default PortfoliosGrid;
