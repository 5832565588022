import { ColDef } from "ag-grid-community";
import { getTerm, getTermValue } from "../../../properties/util/formatTerm";

import {
  currencyFormatter,
  dateFilterParams,
  dateValueFormatter,
  dateValueGetterNA,
  negativeNumCellStyle,
  numberGetter,
  numberSort,
  stringSortCaseInsensitive,
} from "../../../properties/util/gridUtils";

export const portfolioRROtherColumns: ColDef[] = [
  {
    headerName: "Property",
    field: "propertyTitle",
    filter: "agTextColumnFilter",
    comparator: stringSortCaseInsensitive,
  },
  {
    headerName: "Lease ID",
    field: "leaseCode",
    filter: "agTextColumnFilter",
    comparator: stringSortCaseInsensitive,
  },
  {
    headerName: "Tenant",
    field: "tenantTitle",
    filter: "agTextColumnFilter",
    comparator: stringSortCaseInsensitive,
  },
  {
    headerName: "Lease Start",
    field: "leaseFrom",
    cellClass: ["alignRight", "dateFormat"],
    filter: "agDateColumnFilter",
    filterParams: dateFilterParams,
    valueFormatter: (params: any) => dateValueFormatter(params.data.leaseFrom),
  },
  {
    headerName: "Lease End",
    field: "leaseTo",
    cellClass: ["alignRight", "dateFormat"],
    filter: "agDateColumnFilter",
    filterParams: dateFilterParams,
    valueFormatter: (params: any) => dateValueFormatter(params.data.leaseTo),
    valueGetter: (params) => dateValueGetterNA(params.data.leaseTo),
  },
  {
    headerName: "Term (mo)",
    field: "leaseTerm",
    cellClass: ["alignRight"],
    filter: "agNumberColumnFilter",
    type: "numericColumn",
    valueFormatter: (params) =>
    getTerm(params.data.leaseTo, params.data.leaseTerm),
    valueGetter: (params) =>
    getTermValue(params.data.leaseTo, params.data.leaseTerm),
    comparator: numberSort,
  },
  {
    headerName: "Move-In",
    field: "moveInDate",
    cellClass: ["alignRight", "dateFormat"],
    filter: "agDateColumnFilter",
    filterParams: dateFilterParams,
    valueFormatter: (params: any) => dateValueFormatter(params.data.moveInDate),
  },
  {
    headerName: "Move-Out",
    field: "moveOutDate",
    cellClass: ["alignRight", "dateFormat"],
    filter: "agDateColumnFilter",
    filterParams: dateFilterParams,
    valueFormatter: (params: any) =>
      dateValueFormatter(params.data.moveOutDate),
  },

  {
    headerName: "Security Deposit",
    field: "securityDeposit",
    cellClass: ["alignRight", "currencyFormat"],
    filter: "agNumberColumnFilter",
    type: "numericColumn",
    valueFormatter: (params: any) =>
      currencyFormatter(params.data.securityDeposit, "$"),
    valueGetter: (params: any) => numberGetter(params.data.securityDeposit),
    cellStyle: negativeNumCellStyle,
  },
  {
    headerName: "Current Monthly Rent",
    field: "monthlyRent",
    cellClass: ["alignRight", "currencyFormat"],
    filter: "agNumberColumnFilter",
    type: "numericColumn",
    valueFormatter: (params: any) =>
      currencyFormatter(params.data.monthlyRent, "$"),
    valueGetter: (params: any) => numberGetter(params.data.monthlyRent),
    cellStyle: negativeNumCellStyle,
  },
];
