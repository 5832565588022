import React, { useState } from "react";

import { LoansContextProps } from "../model/LoansContextModel";
import { LoanKeyValue, UsState } from "../model/LoansModel";

/**
 * Context for storing Loans Form options across Loans page
 */
const LoansContext = React.createContext<LoansContextProps>({
  // Loans Form Options
  hedgeInstrumentTypeSelectList: [],
  hedgeRateTypeSelectList: [],
  interestRateBasisTypeSelectList: [],
  interestRateTypeSelectList: [],
  kpiFinancialMeasureTypeSelectList: [],
  loanTypeSelectList: [],
  noticeDateTypeSelectList: [],
  yesNoSelectList: [],
  activeInactiveSelectList: [],
  usStatesList: [],

  updateHedgeInstrumentTypeSelectList: () => {},
  updateHedgeRateTypeSelectList: () => {},
  updateInterestRateBasisTypeSelectList: () => {},
  updateInterestRateTypeSelectList: () => {},
  updateKpiFinancialMeasureTypeSelectList: () => {},
  updateLoanTypeSelectList: () => {},
  updateNoticeDateTypeSelectList: () => {},
  updateYesNoSelectList: () => {},
  updateActiveInactiveSelectList: () => {},
  updateUsStatesList: () => {},
});

interface LoansProviderProps {
  children: React.ReactNode;
}

export const LoansProvider: React.FunctionComponent<LoansProviderProps> = (
  props
) => {
  // Loans forms options

  // Dropdown values
  const [hedgeInstrumentTypeSelectList, setHedgeInstrumentTypeSelectList] =
    useState<LoanKeyValue[]>([]);
  const [hedgeRateTypeSelectList, setHedgeRateTypeSelectList] = useState<
    LoanKeyValue[]
  >([]);
  const [interestRateBasisTypeSelectList, setInterestRateBasisTypeSelectList] =
    useState<LoanKeyValue[]>([]);
  const [interestRateTypeSelectList, setInterestRateTypeSelectList] = useState<
    LoanKeyValue[]
  >([]);
  const [
    kpiFinancialMeasureTypeSelectList,
    setKpiFinancialMeasureTypeSelectList,
  ] = useState<LoanKeyValue[]>([]);
  const [loanTypeSelectList, setLoanTypeSelectList] = useState<LoanKeyValue[]>(
    []
  );
  const [yesNoSelectList, setYesNoSelectList] = useState<LoanKeyValue[]>([]);
  const [activeInactiveSelectList, setActiveInactiveSelectList] = useState<
    LoanKeyValue[]
  >([]);
  const [noticeDateTypeSelectList, setNoticeDateTypeSelectList] = useState<
    LoanKeyValue[]
  >([]);

  // Us State List
  const [usStatesList, setUsStatesList] = useState<UsState[]>([]);

  // Hedge Instrument Options
  const updateHedgeInstrumentTypeSelectList = (
    hedgeInstrumentOptions: LoanKeyValue[]
  ) => {
    setHedgeInstrumentTypeSelectList(hedgeInstrumentOptions);
  };

  // Hedge Rate options
  const updateHedgeRateTypeSelectList = (hedgeRateOptions: LoanKeyValue[]) => {
    setHedgeRateTypeSelectList(hedgeRateOptions);
  };

  //Interest Rate Basis options
  const updateInterestRateBasisTypeSelectList = (
    interestRateBasisOptions: LoanKeyValue[]
  ) => {
    setInterestRateBasisTypeSelectList(interestRateBasisOptions);
  };

  // Interest Rate options
  const updateInterestRateTypeSelectList = (
    interestRateOptions: LoanKeyValue[]
  ) => {
    setInterestRateTypeSelectList(interestRateOptions);
  };

  // KPI options
  const updateKpiFinancialMeasureTypeSelectList = (
    kpiOptions: LoanKeyValue[]
  ) => {
    setKpiFinancialMeasureTypeSelectList(kpiOptions);
  };

  // Loan type options
  const updateLoanTypeSelectList = (loanTypeOptions: LoanKeyValue[]) => {
    setLoanTypeSelectList(loanTypeOptions);
  };

  // Notice Date type options
  const updateNoticeDateTypeSelectList = (
    noticeDateOptions: LoanKeyValue[]
  ) => {
    setNoticeDateTypeSelectList(noticeDateOptions);
  };

  // YesNo options
  const updateYesNoSelectList = (yesNoOptions: LoanKeyValue[]) => {
    setYesNoSelectList(yesNoOptions);
  };

  // ActiveInactive options
  const updateActiveInactiveSelectList = (
    activeInactiveOptions: LoanKeyValue[]
  ) => {
    setActiveInactiveSelectList(activeInactiveOptions);
  };

  // Us states options
  const updateUsStatesList = (useStatesOptions: UsState[]) => {
    setUsStatesList(useStatesOptions);
  };

  return (
    <LoansContext.Provider
      value={{
        // Loans Form options
        hedgeInstrumentTypeSelectList,
        hedgeRateTypeSelectList,
        interestRateBasisTypeSelectList,
        interestRateTypeSelectList,
        kpiFinancialMeasureTypeSelectList,
        loanTypeSelectList,
        yesNoSelectList,
        activeInactiveSelectList,
        noticeDateTypeSelectList,
        usStatesList,

        updateHedgeInstrumentTypeSelectList,
        updateHedgeRateTypeSelectList,
        updateInterestRateBasisTypeSelectList,
        updateInterestRateTypeSelectList,
        updateKpiFinancialMeasureTypeSelectList,
        updateLoanTypeSelectList,
        updateNoticeDateTypeSelectList,
        updateYesNoSelectList,
        updateActiveInactiveSelectList,
        updateUsStatesList,
      }}
    >
      {props.children}
    </LoansContext.Provider>
  );
};

export default LoansContext;
