import React from "react";
import { Checkbox } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";

import { IUserPropertyAssignment } from "./models/userPropertyAssignment";
import { UPAContext } from "./UserPropertyAssignmentContext";

const UPAAccessRenderer: React.FC<ICellRendererParams> = (props) => {
  const { userProperties, updateUserProperties } = React.useContext(UPAContext);

  let defaultCheckedValue = false;
  const propertyUserIndex = userProperties.findIndex(
    (userProperty) =>
      userProperty.userId === props.data.userId &&
      userProperty.propertyId === props.data.propertyId
  );
  if (propertyUserIndex > -1)
    defaultCheckedValue = userProperties[propertyUserIndex].grantAccess;
  else defaultCheckedValue = props.value;

  const [checked, setChecked] = React.useState(defaultCheckedValue);

  React.useEffect(() => {
    const userPropertyIndex = userProperties.findIndex(
      (updatedUserProperty) =>
        updatedUserProperty.propertyId === props.data.propertyId &&
        updatedUserProperty.userId === props.data.userId
    );

    if (userPropertyIndex > -1) {
      const latestCheckedVal = userProperties[userPropertyIndex].grantAccess;
      if (latestCheckedVal !== checked) setChecked(latestCheckedVal);
    }
  }, [userProperties, checked, props.data]);

  if (props.data === undefined || props.value === undefined)
    return <React.Fragment></React.Fragment>;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setChecked(checked);

    const state = props.data?.state ?? undefined;
    const city = props.data?.city ?? undefined;
    const zip = props.data?.zip ?? undefined;

    const editedUserProperty: IUserPropertyAssignment = {
      userId: props.data.userId,
      userName: props.data.userName,
      email: props.data.email,
      propertyId: props.data.propertyId,
      propertyTitle: props.data.propertyTitle,
      grantAccess: checked,
      state,
      city,
      zip,
    };
    updateUserProperties(editedUserProperty);
  };

  return (
    <div>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        color="primary"
        inputProps={{ "aria-label": "secondary checkbox" }}
        className="grid-checkbox"
      />
    </div>
  );
};

export default UPAAccessRenderer;
