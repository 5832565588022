import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  GridReadyEvent,
  GridApi,
  RowNode,
  StatusPanelDef,
} from "ag-grid-community";
import { Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import queryString from "query-string";

import {
  currencyFormatter,
  negativeNumCellStyle,
  numberFormatter,
  numberGetter,
  pctGetter,
  percentFormatter,
} from "../../../properties/util/gridUtils";

import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useQueryClient } from "@tanstack/react-query";
import { TPortfolioPropertyListModel } from "../model/portfolioPropertyListModel";
import { useRemoveProperty } from "../api/portfolioDetailsService";
import ConfirmDialog from "../../../../shared/view/confirmationDialog";
import { TRemovePropertyModel } from "../model/removePropertyModel";
import { usePortfolioInfo } from "../../util/portfolioInfoContext";
import RemovePropertyRenderer from "./removePropertyRenderer";
import PropertyRenderer from "./propertyRenderer";
import PortfolioContext from "../../util/portfolioContext";
import { useLocation } from "react-router-dom";
import { getPortPropCols } from "../util/portfolioPropertiesGrid";

const mainTopPropertyInfoWidth = 400;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainTopTabsContainer: {},
    mainTopTabs: {
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        marginTop: -48,
        width: `calc(100% - ${mainTopPropertyInfoWidth}px)`,
        "& > div > div > a:first-child > span": {
          paddingLeft: 10,
        },
        "& a": {
          fontWeight: "bold",
        },
      },
    },
    mainTopTabPanel: {
      width: "100%",
      height: "100%",
      backgroundColor: "white",
      borderTop: "1px solid #ccc",
      [theme.breakpoints.up("lg")]: {
        width: "100%",
      },
    },
  })
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

interface DetailProps {
  data: TPortfolioPropertyListModel;
  node: RowNode;
  api: GridApi;
}

const Properties: React.FunctionComponent<DetailProps> = (props) => {
  const classes = useStyles();

  const { adminAccounts } = React.useContext(PortfolioContext);
  const location = useLocation();
  const parsedQueryParams = queryString.parse(location.search);
  const accId: string = parsedQueryParams.accountIdentifier?.toString() || "";
  const hasAdminAccess = adminAccounts.includes(accId);

  const rowId = props.node.id + "";
  const queryClient = useQueryClient();
  const [confirmIncludePropertyOpen, setConfirmIncludePropertyOpen] =
    useState<boolean>(false);
  const [removePropertyId, setRemovePropertyId] = useState<string>();
  const [removePropertyDescription, setRemovePropertyDescription] =
    useState<string>();
  const removePropertyResponse = useRemoveProperty();
  const portfolioInfo = usePortfolioInfo();

  useEffect(() => {
    return () => {
      // the detail grid is automatically destroyed as it is a React component
      props.api.removeDetailGridInfo(rowId);
    };
  }, [props.api, rowId]);

 
  const statusBar: { statusPanels: StatusPanelDef[] } = {
    statusPanels: [
      {
        statusPanel: "agAggregationComponent",
        statusPanelParams: {
          aggFuncs: ["count", "sum", "min", "max", "avg"],
        },
      },
    ],
  };

  const textFilterParams = {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  };

  const colDefs = [
    {
      headerName: "",
      filter: false,
      field: "propertyId",
      cellRenderer: "removePropertyRenderer",
      cellRendererParams: {
        clicked: function (propertyId: string, title: string) {
          handleRemovePropertyClicked(propertyId, title);
        },
      },
      width: 30,
    },
    {
      field: "title",
      headerName: "Property",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      sortable: true,
      minWidth: 50,
      cellRenderer: "propertyRenderer",
    },
    {
      field: "street",
      headerName: "Street",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      sortable: true,
      minWidth: 50,
    },
    {
      field: "city",
      headerName: "City",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      sortable: true,
      minWidth: 50,
    },
    {
      field: "state",
      headerName: "State",
      filter: true,
      filterParams: textFilterParams,
      sortable: true,
      minWidth: 50,
    },
    {
      field: "zip",
      headerName: "Zip",
      filter: true,
      filterParams: textFilterParams,
      sortable: true,
      minWidth: 50,
    },
    {
      field: "propertyType",
      headerName: "Asset Type",
      filter: true,
      sortable: true,
      minWidth: 50,
    },
    {
      headerName: "Asset Class",
      field: "assetClass",
      sortable: true,
      filter: true,
      // valueFormatter: (params: any) => textFormatter(params.data.assetClass),
    },
    // { field: 'status', headerName: 'Status', filter: true, sortable: true, minWidth: 50, },
    {
      field: "rsf",
      headerName: "Rentable Area",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      valueFormatter: (params: any) => numberFormatter(params.data.rsf, 0),
      cellStyle: negativeNumCellStyle,
    },
    {
      field: "units",
      headerName: "Suite/Unit Count",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      valueFormatter: (params: any) => numberFormatter(params.data.units, 0),
      cellStyle: negativeNumCellStyle,
    },
    // { field: 'startDate', headerName: 'Start Date', sortable: true, filter: 'agDateColumnFilter', filterParams: dateFilterParams, valueGetter: (params: any) => dateValueGetter(params.data.startDate), valueFormatter: (params: any) => dateValueFormatter(params.data.startDate), minWidth: 50, maxWidth: 150, },
    // { field: 'endDate', headerName: 'End Date', sortable: true, filter: 'agDateColumnFilter', filterParams: dateFilterParams, valueGetter: (params: any) => dateValueGetter(params.data.endDate), valueFormatter: (params: any) => dateValueFormatter(params.data.endDate), minWidth: 50, maxWidth: 150, },
    {
      field: "leasePercentage",
      headerName: "Occupancy (Area)",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      valueFormatter: (params: any) =>
        percentFormatter(params.data.leasePercentage, "%"),
      valueGetter: (params: any) => pctGetter(params.data.leasePercentage),
      minWidth: 50,
      cellStyle: negativeNumCellStyle,
    },
    // {               headerName: 'Leased %', filter: 'agNumberColumnFilter', sortable: true, type: 'numericColumn', valueFormatter: (params: any) => percentFormatter( getLeasedValue(params), '%'), valueGetter: (params: any) => getLeasedValue(params), minWidth: 50, maxWidth: 150, },
    {
      field: "monthlyRentPerArea",
      headerName: "Monthly Rent Per Area",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.monthlyRentPerArea, "$"),
      valueGetter: (params: any) =>
        numberGetter(params.data.monthlyRentPerArea),
      minWidth: 50,
      cellStyle: negativeNumCellStyle,
    },
    {
      field: "revenue",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.revenue, "$"),
      valueGetter: (params: any) => numberGetter(params.data.revenue),
      minWidth: 50,
      cellStyle: negativeNumCellStyle,
    },
    {
      field: "expenses",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.expenses, "$"),
      valueGetter: (params: any) => numberGetter(params.data.expenses),
      minWidth: 50,
      cellStyle: negativeNumCellStyle,
    },
    {
      field: "netOperatingIncome",
      headerName: "NOI",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.netOperatingIncome, "$"),
      valueGetter: (params: any) =>
        numberGetter(params.data.netOperatingIncome),
      minWidth: 50,
      cellStyle: negativeNumCellStyle,
    },
    {
      field: "debtService",
      headerName: "DSCV",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.debtService, "$"),
      valueGetter: (params: any) => numberGetter(params.data.debtService),
      minWidth: 50,
      cellStyle: negativeNumCellStyle,
    },
    {
      field: "netCashFlow",
      headerName: "NCF",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.netCashFlow, "$"),
      valueGetter: (params: any) => numberGetter(params.data.netCashFlow),
      minWidth: 50,
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Loan Principal",
      field: "loanPrincipal",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      valueFormatter: (params: any) =>
        numberFormatter(params.data.loanPrincipal, 0),
      // valueGetter: (params: any) => pctGetter(params.data.leasedPercent),
      // cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Loan Interest Rate",
      field: "loanInterestRate",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      valueFormatter: (params: any) =>
        percentFormatter(params.data.loanInterestRate, "%"),
      // valueGetter: (params: any) => pctGetter(params.data.leasedPercent),
      // cellStyle: negativeNumCellStyle,
    },
  ];

  const defaultColDef = {
    // flex: 1,
    minWidth: 50,
    //floatingFilter: true,
    resizable: true,
    // suppressMovable: true,
    filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
  };

  function handleRemovePropertyClicked(
    propertyId: string,
    propertyDescription: string
  ) {
    setRemovePropertyId(propertyId);
    setRemovePropertyDescription(propertyDescription);
    setConfirmIncludePropertyOpen(true);
  }

  const removeProperty = () => {
    const model: TRemovePropertyModel = {
      accountIdentifier: portfolioInfo.accountIdentifier,
      portfolioId: portfolioInfo.portfolioId || "",
      propertyId: removePropertyId || "",
    };

    removePropertyResponse.mutate(model, {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["getPortfolioPropertyList"]);
      },
    });
  };

  const onGridReady = (params: GridReadyEvent) => {
    const gridInfo = {
      id: props.node.id + "",
      parent: props.node.parent?.data,
      api: params.api,
      columnApi: params.columnApi,
    };
    if (
      typeof props.data.properties === "undefined" ||
      props.data.properties.length === 0
    ) {
      params.api.hideOverlay();
      params.api.showNoRowsOverlay();
    }

    props.api.addDetailGridInfo(rowId, gridInfo);
  };

  const autoSizeAll = () => {
    let  detailGrid = props.api.getDetailGridInfo(rowId);
    detailGrid?.columnApi?.autoSizeAllColumns();
  };

  function onFirstDataRendered(params: any) {
    autoSizeAll();
  }

  return (
    <div className={classes.mainTopTabsContainer}>
      <div>
        <ConfirmDialog
          title="Remove Property?"
          open={confirmIncludePropertyOpen}
          setOpen={setConfirmIncludePropertyOpen}
          onConfirm={removeProperty}
        >
          Are you sure you want to remove '{removePropertyDescription}'?
        </ConfirmDialog>
      </div>
      <div
        id={`Properties_${rowId}`}
        style={{
          height: "250px",
          width: "100%",
        }}
        className={"ag-theme-alpine, ag-theme-generations-table"}
      >
        {/* PROPERTIES */}
        <AgGridReact
          components={{
            removePropertyRenderer: RemovePropertyRenderer,
            propertyRenderer: PropertyRenderer,
          }}
          autoGroupColumnDef={{
            minWidth: 100,
          }}
          statusBar={statusBar}
          enableRangeSelection={true}
          columnDefs={hasAdminAccess ? colDefs : getPortPropCols()}
          defaultColDef={defaultColDef}
          rowData={props.data.properties}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={function (params) {
            return "detail_" + params.data.leaseId;
          }}
        />
      </div>
    </div>
  );
};

export default Properties;
