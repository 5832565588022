import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  GridReadyEvent,
  GridApi,
  ColumnApi,
  StatusPanelDef,
  ExcelExportParams,
  FirstDataRenderedEvent,
} from "ag-grid-community";
import "ag-grid-enterprise";
import { Box, Button, Grid, Typography } from "@mui/material";
import { UilExport } from "@iconscout/react-unicons";

import { getStatusPanels } from "../../../properties/leasing activity/util/gridTable";
import { getPropertyColumns } from "../util/propertyListGridUtil";
import { useGetGridConfig } from "../../../../shared/api/pageConfigService";
import {
  GridConfigIds,
  PageConfigIds,
} from "../../../../constants/GridPageConfigurationIds";
import { useQueryClient } from "@tanstack/react-query";
import SaveGridStateOptions, {
  GRID_CONFIG_DEFAULT_VIEW,
} from "../../../../shared/view/SaveGridStateOptions";
import PropertyListImageRenderer from "./propertyListImageRenderer";
import { TPropertyModel } from "../model/propertyListModel";
import DatePicker from "react-datepicker";
import { UilCalender } from "@iconscout/react-unicons";
import PortfolioContext from "../../../portfolio/util/portfolioContext";

interface PropertyListGrid {
  propertyList?: TPropertyModel;
}

const pageId = PageConfigIds.PROPERTIES;
const gridId = GridConfigIds.PROPERTY_LIST_GRID;

const PropertiesGrid: React.FC<PropertyListGrid> = (props) => {
  const tableContent = props.propertyList?.propertyAndAssetList;
  const {
    propertiesStartDate,
    propertiesEndDate,
    updatePropertiesStartDate,
    updatePropertiesEndDate,
  } = useContext(PortfolioContext);

  const [gridView, setGridView] = useState(GRID_CONFIG_DEFAULT_VIEW);

  // grid state
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | undefined>(
    undefined
  );
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);

  const columnDefs = React.useMemo(() => getPropertyColumns(), []);

  const statusBar: { statusPanels: StatusPanelDef[] } = getStatusPanels();

  const accountId =
    props.propertyList &&
    props.propertyList?.propertyAndAssetList &&
    props.propertyList?.propertyAndAssetList.length > 0
      ? props.propertyList.propertyAndAssetList[0].accountIdentifier
      : "";
  /**
   * Sets the context that is retrieved from the backend whenever the gridView is changed.
   * gridView is either changed when data first loads (only if a default value exists) or when the user uses the dropdown
   */
  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1 ||
      gridApi === undefined ||
      gridColumnApi === undefined
    )
      return;

    if (gridView === GRID_CONFIG_DEFAULT_VIEW) {
      gridApi.setFilterModel({});
      gridColumnApi.resetColumnState();
      gridApi.redrawRows();
      return;
    }

    const selectedGridConfig = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.id === Number(gridView)
    );

    if (selectedGridConfig === undefined) return;

    gridApi.setFilterModel(JSON.parse(selectedGridConfig.columnFilterState));
    gridColumnApi.applyColumnState({
      state: JSON.parse(selectedGridConfig.columnState),
    });
    gridApi.redrawRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridView, gridApi, gridColumnApi]);

  const propertyList = props.propertyList?.propertyAndAssetList;

  const queryClient = useQueryClient();
  const gridConfigsQuery = useGetGridConfig({
    pageId: pageId,
    gridIdentifier: gridId,
    accountId:
      props.propertyList && propertyList && propertyList.length > 0
        ? props.propertyList.propertyAndAssetList[0].accountIdentifier
        : "",
  });

  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1
    ) {
      if (gridView !== GRID_CONFIG_DEFAULT_VIEW)
        setGridView(GRID_CONFIG_DEFAULT_VIEW);
      return;
    }

    const isSaveAsRender = saveAsView.current.trim().length > 0;

    if (isSaveAsRender) {
      const savedAsView = gridConfigsQuery.data.gridConfigurations.find(
        (gridConfig) =>
          gridConfig.name.toLowerCase().trim() ===
          saveAsView.current.toLowerCase().trim()
      );
      if (savedAsView) setGridView(savedAsView.id.toString());
      saveAsView.current = "";
      return;
    }

    const defaultView = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.isDefault
    );
    if (
      defaultView &&
      gridView !== defaultView.id.toString() &&
      gridView === GRID_CONFIG_DEFAULT_VIEW
    )
      setGridView(defaultView.id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridConfigsQuery.isSuccess, gridConfigsQuery.data]);

  const saveAsView = React.useRef("");

  /**
   * exports the data that is rendered in the AG grid.
   */
  const onExport = () => {
    const params: ExcelExportParams = {
      sheetName: "Properties",
      exportMode: undefined,
      suppressTextAsCDATA: false,
      rowHeight: undefined,
      headerRowHeight: undefined,
      fileName: "Properties",
    };
    gridApi?.exportDataAsExcel(params);
  };

  const autoSizeAll = () => {
    setTimeout(() => gridColumnApi?.autoSizeAllColumns(), 500);// TODO remove setTimeout when Ag-grid fixes autoSizeColumns on React 18 
  };

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
    setGridColumnApi(event.columnApi);
    if (tableContent === undefined || tableContent.length === 0) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }
  };

  const aggFuncs = {
    // this overrides the grids built-in sum function
    list: (values: any) => {
      let listOfItems = "";

      values.forEach((value: any) => (listOfItems += value + ", "));
      listOfItems = listOfItems.replace(/,\s*$/, "");

      return listOfItems;
    },
    distinctcount: (values: any) => {
      const testCnt: string[] = [];
      values.forEach((value: any) => {
        testCnt.push(value);
      });
      let test = new Set(testCnt);
      return test.size;
    },
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    autoSizeAll();
  };

  const LinkComponent = (props: any) => {
    return (
      <span>
        <Link
          to={`/propertyView/propertyViewDetails?propertyId=${props.data.id}&accountIdentifier=${props.data.accountIdentifier}`}
        >
          {props.value}
        </Link>
      </span>
    );
  };

  return (
    <React.Fragment>
      <Grid
        container
        className={"property-action-bar"}
        sx={{ borderTop: "1px solid #ccc", }}
      >
        <Grid item>
          <Grid container sx={{ width: "auto" }}>
            <Grid item>
              <Typography
                  variant="body3"
                  component="label"
                  className={"input-label"}
                >{`Select date range`}
              </Typography>
              <Box className={"datepicker-container daterange-container"}>
                <DatePicker
                  selected={propertiesStartDate}
                  onChange={(newDate: Date) => {
                    updatePropertiesStartDate(newDate);
                  }}
                  dateFormat="MMM yyyy"
                  showMonthYearPicker              
                  popperModifiers={[
                    {
                      name: "preventOverflow",
                      options: {
                        altAxis: true,
                      },
                    },
                    {
                      name: "offset",
                      options: {
                        offset: [-13, 0],
                      },
                    },
                  ]}
                />
                <Box className={"icon-datepicker icon-datepicker-dash"}>
                  &nbsp;-&nbsp;
                </Box>
                <DatePicker
                  selected={propertiesEndDate}
                  onChange={(newDate: Date) => {
                    updatePropertiesEndDate(newDate);
                  }}
                  dateFormat="MMM yyyy"
                  showMonthYearPicker              
                  minDate={propertiesStartDate}
                  maxDate={new Date()}
                  popperModifiers={[
                    {
                      name: "preventOverflow",
                      options: {
                        altAxis: true,
                      },
                    },
                    {
                      name: "offset",
                      options: {
                        offset: [-13, 0],
                      },
                    },
                  ]}
                />
                <Box className={"icon-datepicker"}>
                  <UilCalender />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container sx={{ width: "auto" }}>
            <Grid item>
              <SaveGridStateOptions
                isSuccess={gridConfigsQuery.isSuccess}
                gridView={gridView}
                handleGridView={(newVal: string) => {
                  setGridView(newVal);
                }}
                gridConfigData={gridConfigsQuery.data}
                gridApi={gridApi}
                gridColumnApi={gridColumnApi}
                gridId={gridId}
                pageId={pageId}
                accountId={accountId}
                invalidateGridQuery={() =>
                  queryClient.invalidateQueries([
                    "getGridConfig",
                    pageId + gridId + accountId,
                  ])
                }
                updateSaveAsViewName={(name: string) => {
                  saveAsView.current = name;
                }}
                updateGridView={() => setGridView(GRID_CONFIG_DEFAULT_VIEW)}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                className={"btn-primary"}
                sx={{ marginTop: "21px" }}
                onClick={onExport}
              >
                <UilExport /> Export
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div id="property-main-content" className="tab-content">
        <div className="standard-content">
          <div
            className="table-standard-toggle-container"
            style={{ width: "100%" }}
          >
            <div
              id="property-main-content-container"
              className="example-wrapper"
              style={{ width: "100%" }}
            >
              <div
                id="myGrid"
                className="ag-theme-alpine ag-theme-alpine-container-override"
                style={{ width: "100%" }}
              >
                <AgGridReact
                  excelStyles={[
                    {
                      id: "alignRight",
                      alignment: {
                        horizontal: "Right",
                      },
                    },
                    {
                      id: "currencyFormat",
                      numberFormat: { format: "\u0024 #,##0.00" },
                    },
                    {
                      id: "numberFormat",
                      numberFormat: { format: "#,##0.00" },
                    },
                  ]}
                  onGridReady={onGridReady}
                  detailRowAutoHeight={true}
                  columnDefs={columnDefs}
                  rowData={tableContent}
                  onFirstDataRendered={onFirstDataRendered}
                  suppressAggFuncInHeader
                  aggFuncs={aggFuncs}
                  statusBar={statusBar}
                  enableCharts={true}
                  enableRangeSelection={true}
                  autoGroupColumnDef={{ minWidth: 100 }}
                  sortingOrder={["desc", "asc"]}
                  defaultColDef={{
                    minWidth: 50,
                    resizable: true,
                    filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
                    flex: 1,
                  }}
                  components={{
                    linkComponent: LinkComponent,
                    propertyListImageRenderer: PropertyListImageRenderer
                  }}
                  rowHeight={84}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PropertiesGrid;
