import React, { useContext, useState } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  ColDef,
  FirstDataRenderedEvent,
  GridReadyEvent,
} from "ag-grid-community";

import { IArAlertRule, IFrequencySelectList } from "../../model/alertsModel";
import AlertsActionsRenderer from "./AlertsActionsRenderer";
import ConfirmDialog from "../../../../shared/view/confirmationDialog";
import { useDeleteAlert } from "../../api/alertsService";
import { UserInfoContext } from "../../util/userInfoContext";
import { stringSortCaseInsensitive } from "../../../properties/util/gridUtils";

const deleteAlertText = "Successfully updated alerts.";

const deleteAlertFailText =
  "Your alert could not been deleted. Please try again later.";

type Props = {
  rows: IArAlertRule[];
  accountId: string;
  frequencyList: IFrequencySelectList[];
  handleClose: (shouldRefresh: boolean) => void;
};

const AlertsGrid: React.FC<Props> = (props) => {
  const { updateAlertDetails } = useContext(UserInfoContext);

  const { rows, accountId, frequencyList, handleClose } = props;

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteAlertName, setDeleteAlertName] = useState("");
  const [deleteAlertId, setDeleteAlertId] = useState<string | undefined>(
    undefined
  );

  const deleteAlert = useDeleteAlert();

  const handleDeleteDialog = (alertName?: string, alertId?: string) => {
    setOpenDeleteDialog(true);
    if (alertName !== undefined) setDeleteAlertName(alertName);
    if (alertId !== undefined) setDeleteAlertId(alertId);
  };

  const onDeleteAlert = () => {
    if (deleteAlertId !== undefined)
      deleteAlert.mutate(
        {
          alertRuleId: deleteAlertId,
          accountId,
        },
        {
          onSuccess: (data) => {
            handleClose(true);
            setOpenDeleteDialog(false);
            updateAlertDetails(deleteAlertText, "success");
          },
          onError: (data) => {
            handleClose(false);
            setOpenDeleteDialog(false);
            updateAlertDetails(deleteAlertFailText, "error");
          },
        }
      );
  };

  const columnDefs = React.useMemo(() => {
    const defs: ColDef[] = [
      {
        headerName: "Alert Title",
        field: "alertTitle",
        comparator: stringSortCaseInsensitive,
      },
      {
        headerName: "Status",
        comparator: stringSortCaseInsensitive,
        field: "status",
      },
      {
        headerName: "Dollar Amount",
        field: "totalBalanceThreshold",
        type: "rightAligned",
        valueFormatter: (params) => {
          if (params.data.totalBalanceThreshold !== undefined) {
            const options = {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            };
            const formatted = Number(
              params.data.totalBalanceThreshold
            ).toLocaleString("en", options);
            return `$${formatted}`;
          } else return "-";
        },
      },
      {
        headerName: "Frequency",
        field: "alertRuleFrequencyDescription",
        comparator: stringSortCaseInsensitive,
      },
      {
        headerName: "Send Email",
        valueFormatter: (params: any) => (params.data.sendEmail ? "Yes" : "No"),
        valueGetter: (params: any) => (params.data.sendEmail ? "Yes" : "No"),
        comparator: stringSortCaseInsensitive,
      },
      {
        headerName: "Actions",
        field: "id",
        cellRenderer: "actionsRenderer",
        cellRendererParams: {
          accountId: accountId,
          frequencyList: frequencyList,
          handleClose: handleClose,
          handleOpenDeleteDialog: handleDeleteDialog,
        },
        sortable: false,
        filter: false,
      },
    ];
    return defs;
  }, [accountId, frequencyList, handleClose]);

  const onGridReady = (event: GridReadyEvent) => {
    if (rows === undefined || rows.length < 1) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    // Auto sizing columns to fit the whole screen
    event.api.sizeColumnsToFit();
  };

  return (
    <div id="alerts-main-content" className="grid-container">
      <div
        id="alertsGrid"
        className="ag-theme-alpine ag-theme-alpine-container-override"
      >
        {deleteAlertId && (
          <ConfirmDialog
            title="Delete Alert?"
            open={openDeleteDialog}
            setOpen={(open: boolean) => setOpenDeleteDialog(open)}
            onConfirm={onDeleteAlert}
          >
            Are you sure you want to delete '{deleteAlertName}'?
          </ConfirmDialog>
        )}
        <AgGridReact
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          rowData={rows}
          columnDefs={columnDefs}
          components={{
            actionsRenderer: AlertsActionsRenderer,
          }}
          sortingOrder={["desc", "asc"]}
          defaultColDef={{
            filter: true,
            resizable: true,
            sortable: true,
            filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
          }}
        />
      </div>
    </div>
  );
};

export default AlertsGrid;
