import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { Checkbox } from "@mui/material";
import { NegateActualBudgetContext } from "./NegateActualBudgetContext";
import { INegateActualBudgetRow } from "../../model/negateActualBudgetModel";

const NegateActualBudgetActionsRenderer: React.FC<ICellRendererParams> = (
  props: any
) => {
  const {
    negateActualBudgetRow,
    updatenegateActualBudgetRow,
    lastColumnChecked,
    updateLastColumnChecked,
  } = React.useContext(NegateActualBudgetContext);

  let defaultCheckedValue = false;
  const rowIndex = negateActualBudgetRow.findIndex(
    (negateActualBudgetRow) =>
      negateActualBudgetRow.financialAccountNumberId ===
      props.data.financialAccountNumberId
  );
  if (rowIndex > -1) {
    defaultCheckedValue =
      props.column?.getColId() === "negateBudgetOnImport"
        ? negateActualBudgetRow[rowIndex].negateBudgetOnImport
        : negateActualBudgetRow[rowIndex].negateActualOnImport;
  } else {
    defaultCheckedValue = props.value;
  }

  const [checked, setChecked] = React.useState(defaultCheckedValue);

  /* Update the checkboxes on the context values*/
  React.useEffect(() => {
    if (props.column?.getColId() !== lastColumnChecked) return;

    const negateRowIndex = negateActualBudgetRow.findIndex(
      (updatedRow) =>
        updatedRow.financialAccountNumberId ===
        props.data.financialAccountNumberId
    );

    if (negateRowIndex > -1) {
      const latestCheckedVal =
        props.column?.getColId() === "negateBudgetOnImport"
          ? negateActualBudgetRow[negateRowIndex].negateBudgetOnImport
          : negateActualBudgetRow[negateRowIndex].negateActualOnImport;
      if (latestCheckedVal !== checked) {
        setChecked(latestCheckedVal);
      }
      // console.log(
      //   "update checkboxes from context values: " +
      //     props.column?.getColId() +
      //     ", index: " +
      //     negateRowIndex +
      //     ", value = " +
      //     latestCheckedVal
      // );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    negateActualBudgetRow,
    // checked,
    props.data.financialAccountNumberId,
    props.column,
    // rowIndex,
  ]);

  if (props.data === undefined || props.value === undefined)
    return <React.Fragment></React.Fragment>;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    updateLastColumnChecked(props.column?.getColId());
    setChecked(checked);

    const negateRowIndex = negateActualBudgetRow.findIndex(
      (updatedRow) =>
        updatedRow.financialAccountNumberId ===
        props.data.financialAccountNumberId
    );

    // console.log(
    //   "handlechange: index " +
    //     negateRowIndex +
    //     ", col: " +
    //     props.column?.getColId()
    // );

    // console.log(
    //   "handlechange actual: value = " +
    //     (props.column?.getColId() === "negateActualOnImport")
    //     ? checked
    //     : negateRowIndex === -1
    //     ? props.data.negateActualOnImport
    //     : negateActualBudgetRow[negateRowIndex].negateActualOnImport
    // );

    // console.log(
    //   "handlechange budget: value = " + props.column?.getColId() ===
    //     "negateBudgetOnImport"
    //     ? checked
    //     : negateRowIndex === -1
    //     ? props.data.negateBudgetOnImport
    //     : negateActualBudgetRow[negateRowIndex].negateBudgetOnImport
    // );

    const editedRow: INegateActualBudgetRow = {
      accountingSystemId: props.data.accountingSystemId,
      accountingSystemTitle: props.data.accountingSystemTitle,
      financialAccountNumberDescription:
        props.data.financialAccountNumberDescription,
      financialAccountNumber: props.data.financialAccountNumber,
      financialAccountNumberId: props.data.financialAccountNumberId,
      negateActualOnImport:
        props.column?.getColId() === "negateActualOnImport"
          ? checked
          : negateRowIndex === -1
          ? props.data.negateActualOnImport
          : negateActualBudgetRow[negateRowIndex].negateActualOnImport,
      negateBudgetOnImport:
        props.column?.getColId() === "negateBudgetOnImport"
          ? checked
          : negateRowIndex === -1
          ? props.data.negateBudgetOnImport
          : negateActualBudgetRow[negateRowIndex].negateBudgetOnImport,
    };
    updatenegateActualBudgetRow(editedRow);
  };

  return (
    <React.Fragment>
      <div>
        <Checkbox
          name="budget"
          checked={checked}
          onChange={handleChange}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          className="grid-checkbox"
        />
      </div>
    </React.Fragment>
  );
};

export default NegateActualBudgetActionsRenderer;
