export function formatStackingPercentage(
  input: number,
  maximumFractionDigits = 2
) {
  let formattedInput;

  formattedInput = input.toLocaleString("en-US", {
    maximumFractionDigits: maximumFractionDigits,
  });

  return formattedInput + "%";
}
