import React, { useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  GridReadyEvent,
  GridApi,
  ColumnApi,
  ExcelStyle,
  FirstDataRenderedEvent,
} from "ag-grid-community";
import "ag-grid-enterprise";
import { UseQueryResult } from "@tanstack/react-query";
import { Link } from "@mui/material";

import {
  currencyFormatterNoAbbrev,
  negativeAndPositiveNumCellStyle,
  negativeNumCellStyle,
  stringSortCaseInsensitive,
} from "../../../properties/util/gridUtils";
import { usePortfolioInfo } from "../../util/portfolioInfoContext";
import { getStatusBar } from "../../financials/util/financialsGrid";

interface FinancialProps {
  financialsQuery: UseQueryResult<any>;
  onGridRender: (gridApi: GridApi, gridColumnApi: ColumnApi) => void;
  gridApi?: GridApi;
  gridColumnApi?: ColumnApi;
  budgetView: boolean;
}

const FinancialsGrid: React.FunctionComponent<FinancialProps> = (props) => {
  const portfolioInfo = usePortfolioInfo();
  const { financialsQuery, gridColumnApi, onGridRender } = props;

  const rowData = financialsQuery.data?.financialData;
  const columns: any[] = financialsQuery.data?.columnDefs;

  //Add link to PropertyName
  if (columns != null) {
    columns.forEach((column) => {
      if (column.field === "PropertyName") {
        column["cellRenderer"] = "linkComponent";
        column["minWidth"] = "200";
        column["filter"] = "agTextColumnFilter";
      }
      if ("children" in column)
        if (Array.isArray(column.children))
          column.children.forEach((child: any) => {
            if ("type" in child)
              if (Array.isArray(child.type))
                if (child.type.includes("money"))
                  child.cellClass = ["alignRight", "currencyFormat"];
          });
    });
  }

  const statusBar = getStatusBar();

  const autoSizeAll = () => {
    setTimeout(() => gridColumnApi?.autoSizeAllColumns(), 500); // TODO remove setTimeout when Ag-grid fixes autoSizeColumns on React 18
  };

  const onGridReady = (event: GridReadyEvent) => {
    onGridRender(event.api, event.columnApi);
    if (typeof rowData === "undefined" || rowData.length === 0) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    autoSizeAll();
    event.api?.expandAll();
  };

  const formatCurrency = (params: { value: number | undefined }) => {
    return currencyFormatterNoAbbrev(params.value, "$");
  };

  const LinkComponent = (props: any) => {
    const propertyId = props.data?.PropertyId ?? "";

    useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [financialsQuery]);

    return (
      <Link
        target="_blank"
        href={`/propertyView/financials?propertyId=${propertyId}&accountIdentifier=${portfolioInfo.accountIdentifier}`}
      >
        {props.value ?? ""}
      </Link>
    );
  };

  const excelStyles: ExcelStyle[] = [
    {
      id: "alignRight",
      alignment: {
        horizontal: "Right",
      },
    },
    {
      id: "dateFormat",
      dataType: "DateTime",
      numberFormat: { format: "mm/dd/yyyy;@" },
    },
    {
      id: "currencyFormat",
      numberFormat: { format: "\u0024 #,##0.00" },
    },
  ];

  return (
    <div
      id="portfolio-financials-content"
      className="example-wrapper"
      style={{ height: "100%", width: "100%" }}
    >
      <div
        id="portfolioFinancialsGrid"
        className="ag-theme-alpine"
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          excelStyles={excelStyles}
          onGridReady={onGridReady}
          rowData={rowData}
          onFirstDataRendered={onFirstDataRendered}
          suppressAggFuncInHeader={true}
          enableCharts={true}
          statusBar={statusBar}
          enableRangeSelection={true}
          groupIncludeFooter={true}
          groupIncludeTotalFooter={true}
          sortingOrder={["desc", "asc"]}
          defaultColDef={{
            initialWidth: 100,
            sortable: true,
            resizable: true,
            filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
          }}
          columnTypes={{
            money: {
              width: 100,
              valueFormatter: formatCurrency,
              type: "numericColumn",
              aggFunc: "sum",
              cellStyle: negativeNumCellStyle,
              filter: "agNumberColumnFilter",
              filterParams: {
                filterOptions: [
                  "lessThan",
                  "greaterThan",
                  "inRange",
                  "blank",
                  "notBlank",
                ],
              },
            },
            group: {
              width: 100,
              rowGroup: true,
              hide: true,
            },
          }}
          autoGroupColumnDef={{
            headerName: " Description ",
            field: "Description",
            minWidth: 250,
            cellRendererParams: {
              suppressCount: true,
            },
          }}
          components={{
            linkComponent: LinkComponent,
          }}
        >
          {columns.map((column) => {
            return column.hasOwnProperty("children") ? (
              <AgGridColumn
                headerName={column.headerName}
                marryChildren={true}
                key={column.headerName}
                type={"rightAligned"}
              >
                {column?.children.map((childcol: any) => (
                  <AgGridColumn
                    {...childcol}
                    key={childcol.field}
                    headerTooltip={childcol.headerName}
                    filter={"agNumberColumnFilter"}
                    {...(childcol.headerName === "VARIANCE" && {
                      cellStyle: negativeAndPositiveNumCellStyle,
                    })}
                  />
                ))}
              </AgGridColumn>
            ) : (
              <AgGridColumn
                {...column}
                key={column.field}
                comparator={stringSortCaseInsensitive}
              />
            );
          })}
        </AgGridReact>
      </div>
    </div>
  );
};

export default FinancialsGrid;
