import {
  //withStyles
  Theme,
} from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const drawerWidth = 240;

//export const useStyles = makeStyles((theme) => ({

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: "100%",
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      marginTop: "64px",
    },
    removeWidget: {
      position: "absolute",
      padding: 4,
      right: 8,
      top: 10,
    },
    dragWidget: {
      position: "absolute",
      padding: 4,
      right: 40,
      top: 10,
    },
    removeWidgetIcon: {
      position: "absolute",
      right: "24px",
      top: "0px",
    },
    addWidgetSelect: {
      fontSize: "0.9rem",
      "& svg": {
        top: "calc(50% - 0.65em)",
        "& path": {
          fill: "#637e97",
        },
      },
    },
    addWidgetCloseIcon: {
      marginRight: "0 !important",
      marginBottom: "0 !important",
      paddingRight: 0,
      paddingLeft: 0,
      minWidth: "auto",
    },
    widgetIcon: {
      marginTop: 2,
      fontSize: "1.5rem",
    },
    addWidgetText: {
      fontSize: "0.9rem",
    },
    spaceItem: {
      padding: theme.spacing(1),
    },
    formControl: {
      /*
      width: "100%",
    */
    },
    selectedAll: {
      /*
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    */
    },
    datePicker: {
      width: 75,
    },
    datePickerRoot: {
      flexGrow: 1,
      display: "flex",
      flexFlow: "row wrap",
      "& input": {
        //fontSize: "16px"
      },
    },
    addWidgetList: {
      marginTop: "8px",
      marginBottom: "45px",
      border: "1px solid #ccc",
    },
    addWidgetDrawer: {
      flexShrink: 0,
      width: drawerWidth,
      "& > div": {
        marginTop: 0,
      },
    },
    addWidgetContainer: {
      padding: "1em",
    },
    addWidgetIcon: {
      verticalAlign: "text-bottom",
    },
    splitButtonMenuContainer: {
      zIndex: 100,
    },
    greyButton: {
      height: "25px",
      padding: "5px 8px",
      backgroundColor: "var(--neutral-grey-150)",
      color: "var(--primary-dark-blue)",
      borderRadius: "6px",
      fontSize: "14px",
      WebkitBoxShadow: "0px 0px",
      fontWeight: "600",
      "&:hover": {
        backgroundColor: "var(--neutral-grey-150)",
        color: "var(--primary-dark-blue)",
      },
      "& span" : {
        marginRight: "4px",
        marginLeft: "0px",
        "& svg": {
          fontSize: "16px !important",
        }
      }
    },
    whiteButton: {
      padding: "6px 16px",
      backgroundColor: "white",
      color: "var(--primary-dark-blue)",
      borderRadius: "6px",
      fontSize: "15px",
      WebkitBoxShadow: "0 2px 2px 0 rgba(46, 57, 72, 0.02)",
      fontWeight: "500",
      border: "solid 1px var(--neutral-grey-200)",
      "&:hover": {
        backgroundColor: "white",
        color: "var(--primary-blue)",
      },
      "& span" : {
        marginRight: "4px",
        marginLeft: "0px",
        "& svg": {
          fontSize: "16px !important",
          "&:hover": {
            color: "var(--primary-blue)",
          },
        }
      }
    },
    blueButton: {
      padding: "6px 16px",
      backgroundColor: "var(--primary-blue)",
      color: "white !important",
      borderRadius: "6px",
      fontSize: "15px",
      WebkitBoxShadow: "0 2px 2px 0 rgba(46, 57, 72, 0.02)",
      fontWeight: "500",
      border: "solid 1px var(--primary-blue) !important",
      "&:hover": {
        backgroundColor: "var(--primary-blue)",
        color: "white",
      },
      "& span" : {
        marginRight: "4px",
        marginLeft: "0px",
        "& svg": {
          fontSize: "16px !important",
        }
      }
    },
    dropIconButton: {
      backgroundColor: "var(--primary-blue)",
      color: "white !important",
      borderRadius: "6px",
      border: "solid 0px var(--primary-blue) !important",
      fontSize: "15px",
      fontWeight: "500",
      "&:hover": {
        backgroundColor: "var(--primary-blue)",
        color: "white",
      },
    },
    linkButton: {
      "&:hover": {
        color: "var(--primary-blue)",
        backgroundColor: "white",
      }
    },
    loansDialogPaper: {
      width: "100%",
    },
    loansDialogTitle: {
      padding: "16px 8px !important",
    },
    defaultItem: {
      "& svg": {
        width: "18px",
        height: "18px",
      },
    },
  })
);

export default useStyles;
