export function formatDate(input: string) {
    if (isDate(input)){
        let d = new Date(input);
        return d.toDateString();
    }
    else{
        return "";
    }
  }
  
var isDate = function(date: string) {
    return (new Date(date).toString()  !== "Invalid Date") && !isNaN(Date.parse(date));
}

export function formatDateMMddyyyy(input: string) {
    if (isDate(input)){
        let d = new Date(input);
        let formattedDate = ((d.getMonth() > 8) ? (d.getMonth() + 1) : ('0' + (d.getMonth() + 1))) + '/' + ((d.getDate() > 9) ? d.getDate() : ('0' + d.getDate())) + '/' + d.getFullYear();
        return formattedDate;
    }
    else{
        return "";
    }
  }
