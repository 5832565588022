import React, { forwardRef, useContext, useEffect, useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { UilExport } from "@iconscout/react-unicons";
import { useSearchParams } from "react-router-dom";

import { getInitialAppliedFilterValues } from "../util/propertyAr";
import TenantTable from "./TenantTable";
import ARAssetSummary from "./ARAssetSummary";
import { useGetARData } from "../api/ARService";
import { usePropertyInfo } from "../../util/propertyInfoContext";
import { AppliedFilter, ARMonthYearFilter } from "../model/tenant";
import RentRollContext from "../../rent roll/context/RentRollContext";
import Drawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import { UilAngleLeft } from "@iconscout/react-unicons";
import { UilTimes } from "@iconscout/react-unicons";
import DatePicker from "react-datepicker";
import { UilCalender } from "@iconscout/react-unicons";
import { GridApi, ColumnApi } from "ag-grid-community";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import {
  GridConfigIds,
  PageConfigIds,
} from "../../../../constants/GridPageConfigurationIds";
import { useGetGridConfig } from "../../../../shared/api/pageConfigService";
import "react-datepicker/dist/react-datepicker.css";
import SaveGridStateOptions, {
  GRID_CONFIG_DEFAULT_VIEW,
} from "../../../../shared/view/SaveGridStateOptions";
import { LoadingButton } from "@mui/lab";
import { useExportReport } from "../../../reporting/api/propertyService";
import fileSaver from "file-saver";

const drawerSummaryWidth = 420;
const drawerSummaryWidthMobile = 300;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

interface Props {
  value?: string;
  onClick?: () => void;
}
const PropertyAR: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [agFilterQuery, setAgFilterQuery] = useState<string | null>(null);
  const [agOrderBy, setAgOrderBy] = useState<string | null>(null);

  /**
   * Picks a query param called agFilter
   */
  useEffect(() => {
    if (searchParams.has("tenantTitle") || searchParams.has("orderBy")) {
      const agFilterParam = searchParams.get("tenantTitle");
      if (agFilterParam) {
        setAgFilterQuery(agFilterParam);
      }
      const orderBy = searchParams.get("orderBy");
      if (orderBy) {
        setAgOrderBy(orderBy);
        }
        if (searchParams.has("propertyId")) {
            let tenantPropId = searchParams.get("propertyId");
            setTenantPropertyId(tenantPropId);
        }
    }
  }, [searchParams, setSearchParams]);

  const handleDrawerSummaryOpen = () => {
    setOpen(true);
  };

  const handleDrawerSummaryClose = () => {
    setOpen(false);
  };

  const { arDate, updateArDate } = useContext(RentRollContext);
  const propertyInfo = usePropertyInfo();
  const [tenantPropertyId, setTenantPropertyId] = useState<string | null>(
    propertyInfo.id
  );
  const [appliedFilterData, setAppliedFilterData] = useState<AppliedFilter[]>(
    getInitialAppliedFilterValues()
  );
  const [monthFilter, setMonthFilter] = useState<ARMonthYearFilter>({
    year: arDate?.getFullYear(),
    month: arDate !== undefined ? arDate?.getMonth() + 1 : undefined,
  });

  const updateAppliedFilterData = React.useCallback(
    (appliedFilterValues: AppliedFilter[]) => {
      setAppliedFilterData(appliedFilterValues);
    },
    []
  );

  const getMaxDate = (minDate: Date, maxDate?: Date) => {
    if (maxDate === undefined) return minDate;
    return Date.parse(maxDate.toString()) > Date.parse(new Date().toString())
      ? new Date()
      : new Date(maxDate);
  };

  const [gridApi, setGridApi] = React.useState<GridApi | undefined>(undefined);
  const [gridColumnApi, setGridColumnApi] = React.useState<
    ColumnApi | undefined
  >(undefined);

  // Grid Config state and methods

  const [gridView, setGridView] = React.useState(GRID_CONFIG_DEFAULT_VIEW);

  const gridId = GridConfigIds.PROPERTY_AR_GRID;
  const pageId = PageConfigIds.PROPERTY_AR_GRID;

  const accountId = propertyInfo.accountIdentifier;

  const queryClient = useQueryClient();
  const gridConfigsQuery = useGetGridConfig({
    pageId: pageId,
    gridIdentifier: gridId,
    accountId: accountId,
  });

  /**
   * Sets the context that is retrieved from the backend whenever the gridView is changed.
   * gridView is either changed when data first loads (only if a default value exists) or when the user uses the dropdown
   */
  React.useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1 ||
      gridApi === undefined ||
      gridColumnApi === undefined
    )
      return;

    if (gridView === GRID_CONFIG_DEFAULT_VIEW) {
      gridApi.setFilterModel({});
      gridColumnApi.resetColumnState();
      return;
    }

    const selectedGridConfig = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.id === Number(gridView)
    );

    if (selectedGridConfig === undefined) return;

    gridApi.setFilterModel(JSON.parse(selectedGridConfig.columnFilterState));
    gridColumnApi.applyColumnState({
      state: JSON.parse(selectedGridConfig.columnState),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridView]);

  const saveAsView = React.useRef("");

  React.useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1
    ) {
      if (gridView !== GRID_CONFIG_DEFAULT_VIEW)
        setGridView(GRID_CONFIG_DEFAULT_VIEW);
      return;
    }

    const isSaveAsRender = saveAsView.current.trim().length > 0;

    if (isSaveAsRender) {
      const savedAsView = gridConfigsQuery.data.gridConfigurations.find(
        (gridConfig) =>
          gridConfig.name.toLowerCase().trim() ===
          saveAsView.current.toLowerCase().trim()
      );
      if (savedAsView) setGridView(savedAsView.id.toString());
      saveAsView.current = "";
      return;
    }

    const defaultView = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.isDefault
    );
    if (
      defaultView &&
      gridView !== defaultView.id.toString() &&
      gridView === GRID_CONFIG_DEFAULT_VIEW
    )
      setGridView(defaultView.id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridConfigsQuery.isSuccess, gridConfigsQuery.data]);

  const [propId, setPropId] = useState<string>(propertyInfo.id);
  const ARQueryResult = useGetARData({
    propertyId: propId,
    accountIdentifier: propertyInfo.accountIdentifier,
    monthYearFilter: monthFilter,
  });
  const { isLoading, isSuccess, data: arTenantData } = ARQueryResult;

  useEffect(() => {
    if (
      arTenantData === undefined ||
      !isSuccess ||
      arTenantData.assetSummary !== undefined
    )
      return;

    let newDate = arDate;
    const monthString = searchParams.get("month");
    const yearString = searchParams.get("year");
    if (yearString !== null && monthString !== null) {
      newDate = new Date(Number(yearString), Number(monthString) - 1);
    }

    const maxDate = getMaxDate(
      new Date(arTenantData.minDate),
      new Date(arTenantData.maxDate)
    );
    if (newDate > maxDate) {
      newDate = maxDate;
    }

    updateArDate(newDate);
    setMonthFilter({
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arTenantData, isSuccess, searchParams, updateArDate, arDate]);

  useEffect(() => {
    if (tenantPropertyId !== null) {
      setPropId(tenantPropertyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  type Props = {
    onClick?: () => void;
    value?: string;
  };
  type RefType = number;
  const CustomDatePickerInput = forwardRef<RefType, Props>(
    ({ onClick, value }, ref) => (
      <Box className={"react-datepicker-input"} ref={ref} onClick={onClick}>
        {value}
        <Box className={"react-datepicker-icon"}>
          <UilCalender />
        </Box>
      </Box>
    )
  );

  const exportReportResponse = useExportReport();
  const isMutating = useIsMutating();

  const onExport = async () => {
    const data = await exportReportResponse.mutateAsync({
      accountIdentifier: propertyInfo.accountIdentifier,
      properties: [propertyInfo.id],
      includeArReport: true,
      arReportYear: monthFilter.year,
      arReportMonth: monthFilter.month,
    });

    const fileName = `${propertyInfo.title} - AR.xlsx`;
    fileSaver.saveAs(data.data, fileName);
  };

  return isLoading ? (
    <Box m="auto">
      <CircularProgress />
    </Box>
  ) : (
    <React.Fragment>
      {isSuccess && !!arTenantData && (
        <>
          <div
            id="property-ar"
            className="tab-pane fade show active"
            role="tabpanel"
            aria-labelledby="property-tab-ar"
          >
            <Grid container className="property-action-bar">
              <Grid item>
                <Grid container sx={{ width: "auto" }}>
                  <Grid item>
                    <Box className={"datepicker-outer-container"}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >{`Show tenants for month:`}</Typography>
                      <Box className={"datepicker-container"}>
                        <DatePicker
                          selected={arDate}
                          onChange={(date: Date) => {
                            setMonthFilter({
                              month: date.getMonth() + 1,
                              year: date.getFullYear(),
                            });
                            updateArDate(date);
                          }}
                          dateFormat="MMMM yyyy"
                          showMonthYearPicker
                          minDate={new Date(arTenantData.minDate)}
                          maxDate={getMaxDate(
                            new Date(arTenantData.minDate),
                            new Date(arTenantData.maxDate)
                          )}
                          popperModifiers={[
                            {
                              name: "preventOverflow",
                              options: {
                                altAxis: true,
                              },
                            },
                            {
                              name: "offset",
                              options: {
                                offset: [-13, 0],
                              },
                            },
                          ]}
                          customInput={<CustomDatePickerInput {...props} />}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container sx={{ width: "auto" }}>
                  <Grid item>
                    <SaveGridStateOptions
                      isSuccess={gridConfigsQuery.isSuccess}
                      gridView={gridView}
                      handleGridView={(newVal: string) => {
                        setGridView(newVal);
                      }}
                      gridConfigData={gridConfigsQuery.data}
                      gridApi={gridApi}
                      gridColumnApi={gridColumnApi}
                      gridId={gridId}
                      pageId={pageId}
                      accountId={accountId}
                      invalidateGridQuery={() =>
                        queryClient.invalidateQueries([
                          "getGridConfig",
                          pageId + gridId + accountId,
                        ])
                      }
                      updateSaveAsViewName={(name: string) => {
                        saveAsView.current = name;
                      }}
                      updateGridView={() =>
                        setGridView(GRID_CONFIG_DEFAULT_VIEW)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      loading={isMutating > 0}
                      variant="contained"
                      size="small"
                      className={"btn-primary"}
                      sx={{ marginTop: "21px" }}
                      onClick={async () => await onExport()}
                    >
                      <UilExport /> Export
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div id="property-ar-content" className="tab-content">
              <TenantTable
                tableContent={arTenantData.tenants}
                updateAppliedFilterValues={updateAppliedFilterData}
                propertyTitle={propertyInfo.title}
                onGridRender={(gridApi: GridApi, gridColumnApi: ColumnApi) => {
                  setGridApi(gridApi);
                  setGridColumnApi(gridColumnApi);
                }}
                agFilterParam={
                  agFilterQuery === null ? undefined : agFilterQuery
                }
                agOrderBy={agOrderBy}
              />
            </div>
          </div>
          <DrawerHeader
            sx={{
              position: "fixed",
              right: "10px",
              bottom: "50px",
              zIndex: 1201,
            }}
            className={"summary-drawer-action"}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerSummaryOpen}
              edge="start"
              sx={{
                ...(open && { display: "none" }),
              }}
              size="large"
            >
              <UilAngleLeft />
              <Typography>Summary</Typography>
            </IconButton>
            <IconButton
              onClick={handleDrawerSummaryClose}
              sx={{
                display: "none",
                ...(open && { display: "flex" }),
              }}
              size="large"
            >
              <UilTimes />
              <Typography>Close</Typography>
            </IconButton>
          </DrawerHeader>
          <Drawer
            sx={{
              position: "fixed",
              width: drawerSummaryWidthMobile,
              [theme.breakpoints.up("sm")]: {
                width: drawerSummaryWidth,
              },
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerSummaryWidthMobile,
                [theme.breakpoints.up("sm")]: {
                  width: drawerSummaryWidth,
                },
              },
            }}
            variant="persistent"
            anchor="right"
            open={open}
          >
            <div
              id="summaryContent"
              className="summary-content table-responsive collapse show"
            >
              <ARAssetSummary
                assetSummaryValues={arTenantData.assetSummary}
                appliedFilterValues={appliedFilterData}
                assetSummaryExists={"assetSummary" in arTenantData}
              />
            </div>
          </Drawer>
        </>
      )}
    </React.Fragment>
  );
};

export default PropertyAR;
