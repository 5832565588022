import React, {
  useState,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import {
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  SelectChangeEvent
} from "@mui/material";

import "react-datepicker/dist/react-datepicker.css";
import { ICellRendererParams } from "ag-grid-community";
export const ExportTypeComponent = forwardRef((props: ICellRendererParams, ref) => {
  const [editing, setEditing] = useState(true);

  const [selectedIndex, setSelectedIndex] = useState("Yes");

  useEffect(() => {
    if (!editing) {
      props.api.stopEditing();
    }
    setSelectedIndex(props.value);
  }, [editing, props]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return selectedIndex;
      }
    };
  });

  return (
    <>
      {
        <FormControl variant="outlined" size="small">
          <Select
            labelId="property-rr-perspective"
            displayEmpty
            input={<OutlinedInput />}
            className={"input-field"}
            value={selectedIndex}
            onChange={(event: SelectChangeEvent) => {
              setSelectedIndex(event.target.value);
              setEditing(false);
            }}
          >
            <MenuItem key={0} value="PDF">PDF</MenuItem>
            <MenuItem key={1} value="EXCEL">EXCEL</MenuItem>
          </Select>
        </FormControl>
      }
    </>
  );
});

export default ExportTypeComponent;
