const months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun",
"Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

export function formatMonth(month: string) {
  if (parseInt(month) >= 0 && parseInt(month) < 12) return months[parseInt(month)];
  else return months[0];
}

export function getMonthByIndex(month: string) {
  return months.findIndex(element => element === month);
}

export function getGroupByEnum(groupBy: string) {
  let groupEnum = 0; 
  switch (groupBy)
  { 
    case "Month":  
      groupEnum = 0;
      break;
    case "Quarter":  
      groupEnum = 1;
      break;
    case "Year":  
      groupEnum = 2;
      break;  
    default:
      groupEnum = 0;        
  }
  return groupEnum;
};
