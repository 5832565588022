import { ColDef } from "ag-grid-community";
import { currencyFormatterNoAbbrev, stringSortCaseInsensitive } from "../../../properties/util/gridUtils";

export const getPortfolioARColumns = () => {
  const columnDefs: ColDef[] = [
    {
      headerName: "Property",
      field: "propertyName",
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: "agTextColumnFilter",
      cellRenderer: "linkComponent",
    },
    {
      headerName: "Tenant",
      field: "tenant",
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.data.tenant !== undefined ? params.data.tenant : "-";
      },
    },
    {
      headerName: "Prepaid",
      field: "prepaid",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params) => {
        return `${currencyFormatterNoAbbrev(params.data.prepaid, "$")}`;
      },
    },
    {
      headerName: "0 - 30 Days",
      field: "thirtyDays",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params) => {
        return `${currencyFormatterNoAbbrev(params.data.thirtyDays, "$")}`;
      },
    },
    {
      headerName: "31 - 60 Days",
      field: "sixtyDays",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params) => {
        return `${currencyFormatterNoAbbrev(params.data.sixtyDays, "$")}`;
      },
    },
    {
      headerName: "61 - 90 Days",
      field: "ninetyDays",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params) => {
        return `${currencyFormatterNoAbbrev(params.data.ninetyDays, "$")}`;
      },
    },
    {
      headerName: "90+ Days",
      field: "ninetyPlusDays",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params) => {
        return `${currencyFormatterNoAbbrev(params.data.ninetyPlusDays, "$")}`;
      },
    },
    {
      headerName: "Total Balance",
      field: "totalBalance",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params) => {
        return `${currencyFormatterNoAbbrev(params.data.totalBalance, "$")}`;
      },
    },
  ];

  return columnDefs;
};
