import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  AccountIdentifierHeaderName,
  useAuthorizedApiAxios,
} from "../../../../shared/api/authorizedAxios";
import { TCreatePhotoModel } from "../model/createPhotoModel";
import {
  IAssetOverviewService,
  InvestmentDetailsService,
  IPersonnelService,
  IPropertyDetailsService,
  IPropertyOverviewType,
  ISoftwareAndSystemsService,
  IWorkspaceStatusService,
} from "../model/propertyOverviewModel";

export const useGetPropertyOverview = (
  propertyId: string,
  accountId: string
) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getPropertyOverview", propertyId, accountId],
    () => {
      const source = axios.CancelToken.source();
      const url = `/assetService/v1/Properties/${propertyId}/PropertyOverview`;
      let promise = authorizedApiAxios().get<IPropertyOverviewType>(url, {
        cancelToken: source.token,
        headers: accountId
          ? {
              [AccountIdentifierHeaderName]: accountId,
            }
          : {},
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => response!.data,
      enabled:
        accountId !== undefined &&
        propertyId.length > 0 &&
        accountId.length > 1,
    }
  );
};

export const useSaveAssetOverview = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation(async (item: IAssetOverviewService) => {
    const source = axios.CancelToken.source();
    let url = `assetService/v1/properties/${item.propertyId}/propertyOverviewAssetOverview`;
    const promise = authorizedApiAxios().patch<string>(url, item, {
      cancelToken: source.token,
      headers: { [AccountIdentifierHeaderName]: item.accountId },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useSaveWorkspaceStatus = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation(async (item: IWorkspaceStatusService) => {
    const source = axios.CancelToken.source();
    let url = `assetService/v1/properties/${item.propertyId}/PropertyOverviewAdmin`;
    const promise = authorizedApiAxios().patch<string>(url, item, {
      cancelToken: source.token,
      headers: { [AccountIdentifierHeaderName]: item.accountId },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useSavePropertyDetails = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation(async (item: IPropertyDetailsService) => {
    const source = axios.CancelToken.source();
    let url = `assetService/v1/properties/${item.propertyId}/PropertyOverviewPropertyDetails`;
    const promise = authorizedApiAxios().patch<string>(url, item, {
      cancelToken: source.token,
      headers: { [AccountIdentifierHeaderName]: item.accountId },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useSavePersonnel = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation(async (item: IPersonnelService) => {
    const source = axios.CancelToken.source();
    let url = `assetService/v1/properties/${item.propertyId}/PropertyOverviewPersonnel`;
    const promise = authorizedApiAxios().patch<string>(url, item, {
      cancelToken: source.token,
      headers: { [AccountIdentifierHeaderName]: item.accountId },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useSaveInvestmentDetails = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation(async (item: InvestmentDetailsService) => {
    const source = axios.CancelToken.source();
    let url = `assetService/v1/properties/${item.propertyId}/propertyOverviewInvestmentDetails`;
    const promise = authorizedApiAxios().patch<string>(url, item, {
      cancelToken: source.token,
      headers: { [AccountIdentifierHeaderName]: item.accountId },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useSaveSystems = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation(async (item: ISoftwareAndSystemsService) => {
    const source = axios.CancelToken.source();
    let url = `assetService/v1/properties/${item.propertyId}/PropertyOverviewSystems`;
    const promise = authorizedApiAxios().patch<string>(url, item, {
      cancelToken: source.token,
      headers: { [AccountIdentifierHeaderName]: item.accountId },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useUploadPropertyPhoto = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation(async (item: TCreatePhotoModel) => {
    const source = axios.CancelToken.source();
    let formData = new FormData();

    formData.append("file", item.file?.file);
    let i = 0;
    formData.append("tags[" + i.toString() + "].type", "Purpose");
    formData.append("tags[" + i.toString() + "].value", "Property Image");

    let url = `assetService/v1/properties/${item.propertyId}/propertyOverviewImage`;
    
    let result = await authorizedApiAxios().patch<string>(url, formData, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: item.accountIdentifier,
      },
    });

    (result as any).cancel = () => source.cancel();
    return result.data;
  });
};

