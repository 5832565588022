import React, { useState } from "react";
import { renderToString } from "react-dom/server";
import {
  Dialog,
  Box,
  FormControl,
  IconButton,
  Select,
  MenuItem,
  Typography,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FilePond, registerPlugin, FileStatus } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { UilFile } from "@iconscout/react-unicons";
import { UilMultiply } from "@iconscout/react-unicons";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "./downloaDocumentsModal";
import { DocumentType } from "../../../document/model/documentEnum";
import { Tag } from "../../../document/model/documentFilter";
import { TCreateDocumentModel } from "../../../document/model/createDocumentModel";
import {
  useGetDocumentTypes,
  useUploadDocument,
} from "../../../document/api/documentService";
import { usePropertyInfo } from "../../../util/propertyInfoContext";
import { DocumentUploadSourceEnum } from "../../../document/model/documentUploadSource";
import ConfirmDialog from "../../../../../shared/view/confirmationDialog";

const CustomizedDialog = styled(Dialog)`
  width: 99.7%;
  & .MuiDialog-paper {
    width: 500px;
    max-width: 90%;
  }
  & .filepond--drop-label {
    padding: 25px;
    height: 120px;
    background-color: var(--neutral-grey-50);
    border: 1px dashed var(--neutral-grey-200);
    border-radius: 6px;
    cursor: pointer;
    & label {
      cursor: pointer;
    }
    & .MuiTypography-h3 {
      font-family: lato;
      font-weight: 500;
      color: var(--neutral-grey-600);
    }
    & .MuiTypography-h3 + .MuiTypography-h3 {
      color: var(--primary-blue);
      text-decoration: underline;
    }
    & .MuiTypography-body3 {
      font-family: lato;
      font-size: 14px;
      font-weight: 400;
      color: var(--neutral-grey-600);
    }
  }
  & .filepond--drip {
    background-color: var(--neutral-white);
  }
  & .filepond--credits {
    display: none;
  }
  & .brs-files {
    color: blue;
    text-decoration: underline;
    font-size: 12px;
  }
  & .filepond--list {
    top: 7px !important;
    left: 0 !important;
    right: 0 !important;
    & .filepond--item {
      margin: 8px;
      & .filepond--file-wrapper {
        background-color: var(--neutral-white);
        border-radius: 6px;
        & .filepond--file-info {
          font-family: lato;
          font-size: 15px;
          font-weight: 500;
          color: var(--primary-dark-blue);
          & .filepond--file-info-main {
            font-size: 1em;
          }
        }
        & .filepond--file-action-button {
          padding: 6px;
          width: 32px;
          height: 32px;
          background: transparent;
          cursor: pointer;
          &:hover {
            box-shadow: 0;
            background-color: var(--neutral-grey-150);
            & svg path {
              fill: var(--semantic-red);
            }
          }
        }
        & svg path {
          fill: var(--primary-dark-blue);
        }
        & .filepond--image-preview-overlay-idle {
          mix-blend-mode: screen;
          color: rgba(255, 255, 255, 0.85);
        }
      }
    }
  }
`;

type FileDelete = {
  id: string;
  name: string;
};

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

interface UploadDocumentsProps {
  open: boolean;
  handleClose: () => void;
  uploadSource: DocumentUploadSourceEnum;
  onUploaded?: () => void;
  onError?: () => void;
  buildingId?: string;
  leaseGenId?: string;
  spaceIdList?: string[];
  tenantId?: string;
}

const UploadDocumentsModal: React.FC<UploadDocumentsProps> = (
  props: UploadDocumentsProps
) => {
  const {
    open,
    tenantId,
    buildingId,
    leaseGenId,
    spaceIdList,
    handleClose,
    uploadSource,
    onUploaded,
    onError,
  } = props;

  const [uploadType, setUploadType] = useState<DocumentType>(
    DocumentType.Analysis
  );
  const [label, setLabel] = useState<string>();
  const [files, setFiles] = useState<any[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [fileToBeDeleted, setFileToBeDeleted] = useState<
    FileDelete | undefined
  >(undefined);

  const propertyInfo = usePropertyInfo();
  const uploadDocumentResponse = useUploadDocument();
  const documentTypes = useGetDocumentTypes();

  const getTags = () => {
    const tags: Tag[] = [];
    if (leaseGenId !== undefined)
      tags.push({ type: "LeaseGenId", value: leaseGenId });
    if (tenantId !== undefined)
      tags.push({ type: "TenantId", value: tenantId });
    if (buildingId !== undefined)
      tags.push({ type: "BuildingId", value: buildingId });
    if (spaceIdList !== undefined) {
      spaceIdList.forEach((spaceId) => {
        tags.push({ type: "SpaceId", value: spaceId });
      });
    }

    return tags;
  };

  const handleUpload = async () => {
    for (const file of files) {
      const model: TCreateDocumentModel = {
        accountIdentifier: propertyInfo.accountIdentifier,
        documentType: `${uploadType}`,
        propertyId: propertyInfo.id,
        tags: getTags(),
        file: file,
        source: uploadSource,
        label: label,
      };

      await uploadDocumentResponse.mutateAsync(model, { onError: onError });
    }

    setFiles([]);
    handleClose();
    if (onUploaded) onUploaded();
  };

  function filepondCustomLabel() {
    return renderToString(
      <Box className="upload-document-container">
        <Box>
          <UilFile />
        </Box>
        <Box>
          <Typography variant="h3" component="span">
            Drag and drop here or&nbsp;
          </Typography>
          <Typography variant="h3" component="span">
            browse files
          </Typography>
        </Box>
        <Typography variant="body3" mt={2}>
          Maximum file size of 25MB
        </Typography>
      </Box>
    );
  }

  const SelectIcon = (props: any) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 0 0"
      fill="none"
    >
      <path
        d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z"
        fill="#021A3C"
      />
    </svg>
  );

  return (
    <Box>
      {openDeleteModal && fileToBeDeleted && (
        <ConfirmDialog
          title="Delete Document?"
          open={openDeleteModal}
          setOpen={(open: boolean) => setOpenDeleteModal(open)}
          onConfirm={() => {
            if (fileToBeDeleted !== undefined) {
              setFiles((prevFiles) => {
                return prevFiles.filter(
                  (file) => file.id !== fileToBeDeleted.id
                );
              });
              setFileToBeDeleted(undefined);
            }
          }}
        >
          Are you sure you want to delete '{fileToBeDeleted.name}'?
        </ConfirmDialog>
      )}
      <CustomizedDialog aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title">
          <Typography component="span" variant="h2" color="primary.dark">
            Add Document
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setFiles([]);
              handleClose();
            }}
            className="icon-dialog-close"
            size="large"
          >
            <UilMultiply />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {documentTypes.isFetching ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              {props.uploadSource ===
                DocumentUploadSourceEnum.PropertyOverview && (
                <Box>
                  <FormControl className={"form-control"}>
                    <Typography
                      variant="body3"
                      component="label"
                      className="input-label"
                    >
                      {"Group"}
                    </Typography>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      fullWidth
                      inputProps={{ maxLength: 80 }}
                      className={"form-control-field"}
                      onChange={(event) => setLabel(event.target.value)}
                    />
                  </FormControl>
                </Box>
              )}
              <Box>
                <FormControl className={"form-control"}>
                  <Typography
                    variant="body3"
                    component="label"
                    className="input-label"
                  >{`Type`}</Typography>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="documentType"
                    value={uploadType}
                    onChange={(event) =>
                      setUploadType(event.target.value as DocumentType)
                    }
                    className={"form-control-field"}
                    IconComponent={SelectIcon}
                  >
                    {documentTypes.data?.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mt={2}>
                <Typography
                  variant="body3"
                  component="label"
                  className="input-label"
                >
                  Attach Document
                </Typography>
              </Box>
              <Box>
                <FilePond
                  files={files}
                  allowReorder={true}
                  allowMultiple={true}
                  onupdatefiles={(files) => {
                    let validFiles = files.filter(
                      (f) => f.status !== FileStatus.LOAD_ERROR
                    );
                    setFiles(validFiles);
                  }}
                  allowFileTypeValidation={true}
                  acceptedFileTypes={[
                    "image/jpg",
                    "image/jpeg",
                    "image/png",
                    "application/pdf",
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "application/vnd.ms-excel",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "text/plain",
                    "application/xml",
                    "text/csv",
                  ]}
                  fileValidateTypeLabelExpectedTypesMap={{
                    "image/jpg": ".jpg",
                    "image/jpeg": ".jpeg",
                    "image/png": ".png",
                    "application/pdf": ".pdf",
                    "application/msword": ".doc",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                      ".docx",
                    "application/vnd.ms-excel": ".xls",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                      ".xlsx",
                    "text/plain": ".txt",
                    "application/xml": ".xml",
                    "text/csv": ".csv",
                  }}
                  labelFileTypeNotAllowed={
                    "One or more document types are not supported."
                  }
                  fileValidateTypeLabelExpectedTypes={
                    "Supported types: {allTypes}"
                  }
                  allowFileSizeValidation={true}
                  maxFileSize={"25MB"}
                  maxFiles={10}
                  maxTotalFileSize={"100MB"}
                  labelIdle={filepondCustomLabel()}
                  styleButtonRemoveItemPosition="right"
                  beforeRemoveFile={(file) => {
                    setOpenDeleteModal(true);
                    setFileToBeDeleted({
                      id: file.id,
                      name: file.filename,
                    });
                    return false;
                  }}
                  iconRemove='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#0a1a27" d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"/></svg>'
                  iconRetry='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#0a1a27" d="M21,11a1,1,0,0,0-1,1,8.05,8.05,0,1,1-2.22-5.5h-2.4a1,1,0,0,0,0,2h4.53a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4.77A10,10,0,1,0,22,12,1,1,0,0,0,21,11Z"/></svg>'
                />
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setFiles([]);
              handleClose();
            }}
            disabled={uploadDocumentResponse.isLoading}
            size="small"
            className={"btn-secondary"}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpload}
            variant="contained"
            disabled={!files.length || uploadDocumentResponse.isLoading}
            size="small"
            className={"btn-tertiary"}
          >
            Save
          </Button>
        </DialogActions>
      </CustomizedDialog>
    </Box>
  );
};

export default UploadDocumentsModal;
