export enum DashboardTypeEnum {
  Main = 1,
  Portfolio = 2,
  Property = 3,
}

export enum WidgetCategoryEnum {
  Unknown = 0,
  Financials = 1,
  AreaMetrics = 2,
  UnitMetrics = 3,
  UnweightedAndMisc = 4,
}

export enum WidgetTypeEnum {
  AreaOccupancy = 1,
  UnitOccupancy = 2,
  VacantArea = 3,
  VacantUnit = 4,
  RenewedLeasesArea = 5,
  RenewedLeasesUnit = 6,
  Revenue = 7,
  OperatingExpenses = 8,
  NetOperatingIncome = 9,
  CapitalExpenses = 10,
  TenantImprovements = 11,
  NetCashFlow = 12,
  NewLeaseArea = 13,
  NewLeaseUnit = 14,
  VacatedLeasesArea = 15,
  VacatedLeasesUnit = 16,
  VacancyDistributionArea = 17,
  VacancyDistributionUnit = 18,
  NetAbsorptionArea = 19,
  NetAbsorptionUnit = 20,
  WeightAverageLeaseExpiry = 21,
  WeightAverageLeaseExpiryInMonths = 22,
  InPlaceContractRentArea = 23,
  InPlaceContractRentUnit = 24,
  DebtService = 25,
  AverageTimeVacantArea = 26,
  AverageTimeVacantUnit = 27,
  Valuation = 28,
  CapitalizationRate = 29,
  LeaseCommissions = 30,
  LeasedPercentage = 31,
  NetAbsorptionRateArea = 32,
  NetAbsorptionRateYoYArea = 33,
}

export enum DashboardWidgetDenominationEnum {
  PerArea,
  PerUnit,
  Gross,
}

export enum DashboardWidgetSeriesModeEnum {
  Actual = 1,
  Budget = 2,
  Proforma = 4,
  ActualAndBudget = 8,
  BudgetAndProforma = 16,
  ActualAndProforma = 32,
  All = 64,
}
