import React, { Fragment } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material/";
import { UilMapMarker } from "@iconscout/react-unicons";

import { TRentRollAssetSummary } from "../model/rentRollAssetSummary";
import { LeaseView } from "./propertyViewRentRoll";
import "../../css/properties.css";
import { usePropertyInfo } from "../../util/propertyInfoContext";

interface RentRollListSummaryProps {
  assetSummary?: TRentRollAssetSummary;
  assetFiltered?: TRentRollAssetSummary;
  assetSummaryOther?: TRentRollAssetSummary;
  leaseView: LeaseView;
}

const PropertyViewRentRollSummary: React.FunctionComponent<
  RentRollListSummaryProps
> = ({ assetSummary, assetFiltered, leaseView }) => {
  const propertyInfo = usePropertyInfo();

  return (
    <Fragment>
      <Box>
        <Box className={"summary-property-image"}>
          {propertyInfo.imageUrl ? (
            <img
              height="210"
              width="100%"
              src={propertyInfo.imageUrl}
              alt={propertyInfo.title}
            />
          ) : (
            <img
              height="210"
              width="100%"
              src="/outline_business_black_48dp.png"
              alt={propertyInfo.title}
            />
          )}
        </Box>
      </Box>
      <TableContainer className={"summary-table-container"}>
        <Table /*className={classes.summaryTable}*/ aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell scope="row"></TableCell>
              <TableCell>
                <Typography variant="body3">Filtered Subtotal</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body3">Total</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell scope="row">
                <Typography variant="body2">Number of Units/Suites</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {(leaseView === LeaseView.SUITE_VIEW ||
                    leaseView === LeaseView.LEASE_VIEW) ? (
                      assetFiltered?.numberofSuitesDisplay &&
                    assetFiltered.numberofSuitesDisplay.toLocaleString()
                    ) : (
                      <span>-</span>
                    )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {assetSummary?.numberofSuitesDisplay &&
                    assetSummary.numberofSuitesDisplay.toLocaleString()}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">
                <Typography variant="body2">Rentable Area</Typography>
              </TableCell>
              <TableCell>
              <Typography variant="subtitle1">
                  {(leaseView === LeaseView.SUITE_VIEW ||
                  leaseView === LeaseView.LEASE_VIEW) ? (
                    assetFiltered?.rentableAreaDisplay &&
                    assetFiltered.rentableAreaDisplay.toLocaleString()
                    ) : (
                      <span>-</span>
                    )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {assetSummary?.rentableAreaDisplay &&
                    `${assetSummary.rentableAreaDisplay.toLocaleString()}`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">
                <Typography variant="body2">Number of Tenants</Typography>
              </TableCell>
              <TableCell>
              <Typography variant="subtitle1">
                  {(leaseView === LeaseView.SUITE_VIEW ||
                  leaseView === LeaseView.LEASE_VIEW) ? (
                    assetFiltered?.numberOfTenantsDisplay &&
                    assetFiltered.numberOfTenantsDisplay.toLocaleString()
                    ) : (
                      <span>-</span>
                    )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {assetSummary?.numberOfTenantsDisplay &&
                    `${assetSummary.numberOfTenantsDisplay.toLocaleString()}`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">
                <Typography variant="body2">Occupancy (Area)</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {leaseView === LeaseView.SUITE_VIEW ||
                  leaseView === LeaseView.LEASE_VIEW ? (
                    assetFiltered?.occupancyDisplay && (
                      <span>
                        {assetFiltered.occupancyDisplay.toLocaleString()}
                      </span>
                    )
                  ) : (
                    <span>-</span>
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {assetSummary?.occupancyDisplay &&
                    `${assetSummary.occupancyDisplay.toLocaleString()}`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">
                <Typography variant="body2">Leased (%)</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {leaseView === LeaseView.SUITE_VIEW ||
                  leaseView === LeaseView.LEASE_VIEW ? (
                    assetFiltered?.leasedPercentDisplay && (
                      <span>
                        {assetFiltered.leasedPercentDisplay.toLocaleString()}
                      </span>
                    )
                  ) : (
                    <span>-</span>
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {assetSummary?.leasedPercentDisplay &&
                    `${assetSummary.leasedPercentDisplay.toLocaleString()}`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">
                <Typography variant="body2">Monthly Rent/SF</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {(leaseView === LeaseView.SUITE_VIEW ||
                    leaseView === LeaseView.LEASE_VIEW) &&
                    assetFiltered?.monthlyRentPerSFDisplay &&
                    `${assetFiltered.monthlyRentPerSFDisplay.toLocaleString()}`}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {assetSummary?.monthlyRentPerSFDisplay &&
                    `${assetSummary.monthlyRentPerSFDisplay.toLocaleString()}`}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default PropertyViewRentRollSummary;
