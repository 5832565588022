import { useContext, createContext } from "react";
import { AlertSeverityType } from "../../UI/view/SimpleAlert";

export interface UserInfo {
  userId: string;
  accountIdentifier: string;
  email: string;

  alertMessage?: string;
  alertSeverity?: AlertSeverityType;
}

type ContextProps = UserInfo & {
  updateAlertDetails: (
    alertMessage: string,
    alertSeverity: AlertSeverityType
  ) => void;
};

export const UserInfoContext = createContext<ContextProps>({
  userId: "",
  accountIdentifier: "",
  email: "",

  updateAlertDetails: () => {},
});

export const useUserInfo = () => useContext(UserInfoContext);
