import { Property } from "../model/property";
import { PropertyQuery } from "../model/propertyQuery";
import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "../../../../shared/api/authorizedAxios";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import queryString from "query-string";

export const useGetPropertyList = (
  accountIdentifier?: string,
  filter?: PropertyQuery
) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getAMPropertyList", accountIdentifier, filter],
    () => {
      const source = axios.CancelToken.source();
      const url: string = `/assetService/v1/properties?${queryString.stringify(
        filter!
      )}`;

      let promise = authorizedApiAxios().get<Property[]>(url, {
        cancelToken: source.token,
        headers: accountIdentifier
          ? {
              [AccountIdentifierHeaderName]: accountIdentifier,
            }
          : {},
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      enabled: accountIdentifier !== undefined,
      select: (response) => {
        return response!.data;
      },
    }
  );
};
