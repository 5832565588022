import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "../../../../shared/api/authorizedAxios";
import { LedgerBookList } from "../models/ledgerBookList";

export const useGetLedgerBookList = (accountIdentifier?: string) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getLedgerBookList", accountIdentifier],
    () => {
      const source = axios.CancelToken.source();
      const url: string = `/assetService/v1/LedgerBooks`;
      let promise = authorizedApiAxios().get<LedgerBookList[]>(url, {
        cancelToken: source.token,
        headers: accountIdentifier
          ? {
              [AccountIdentifierHeaderName]: accountIdentifier,
            }
          : {},
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => {
        // Eliminating duplicates and then returning the list.
        return response!.data.filter(
          (ledgerBook, index, ledgerBooks) =>
            ledgerBooks.findIndex((t) => t.id === ledgerBook.id) === index
        );
        // return response!.data;
      },
      enabled: accountIdentifier !== undefined
    }
  );
};
