export const formatLoanDefaultValues = (loan: any) => {
  if (loan === undefined) return {};
  const updatedLoan: any = {
    ...loan,

    // Dropdowns
    amortizing: "amortizing" in loan ? loan.amortizing : -1,
    assumable: "assumable" in loan ? loan.assumable : -1,
    crossCollateralized:
      "crossCollateralized" in loan ? loan.crossCollateralized : -1,
    hedgeInstrumentTypeId:
      "hedgeInstrumentTypeId" in loan ? loan.hedgeInstrumentTypeId : -1,
    hedgeRateTypeId: "hedgeRateTypeId" in loan ? loan.hedgeRateTypeId : -1,
    interestRateBasisTypeId:
      "interestRateBasisTypeId" in loan ? loan.interestRateBasisTypeId : -1,
    interestRateTypeId:
      "interestRateTypeId" in loan ? loan.interestRateTypeId : -1,
    kpiFinancialMeasureTypeId:
      "kpiFinancialMeasureTypeId" in loan ? loan.kpiFinancialMeasureTypeId : -1,
    loanTypeId: "loanTypeId" in loan ? loan.loanTypeId : -1,
    noticeDateTypeId: "noticeDateTypeId" in loan ? loan.noticeDateTypeId : -1,
    prepayment: "prepayment" in loan ? loan.prepayment : -1,
    prepaymentPenalty:
      "prepaymentPenalty" in loan ? loan.prepaymentPenalty : -1,
    securitized: "securitized" in loan ? loan.securitized : -1,

    // Text boxes and areas

    covenantNotes: "covenantNotes" in loan ? loan.covenantNotes : "",
    hedgeNotes: "hedgeNotes" in loan ? loan.hedgeNotes : "",
    lenderNotes: "lenderNotes" in loan ? loan.lenderNotes : "",
    lenderState: "lenderState" in loan ? loan.lenderState : "",
    lenderTitle: "lenderTitle" in loan ? loan.lenderTitle : "",
    loanNotes: "loanNotes" in loan ? loan.loanNotes : "",
    loanNumber: "loanNumber" in loan ? loan.loanNumber : "",
    maturityNotes: "maturityNotes" in loan ? loan.maturityNotes : "",
    prepaymentPenaltyNotes:
      "prepaymentPenaltyNotes" in loan ? loan.prepaymentPenaltyNotes : "",
    servicerState: "servicerState" in loan ? loan.servicerState : "",
    servicerTitle: "servicerTitle" in loan ? loan.servicerTitle : "",

    // Numbers

    debtServiceCoverageRatio:
      "debtServiceCoverageRatio" in loan
        ? loan.debtServiceCoverageRatio
        : undefined,
    loanToValuePercent:
      "loanToValuePercent" in loan ? loan.loanToValuePercent : undefined,
    minimumDebtServiceCoverageRatio:
      "minimumDebtServiceCoverageRatio" in loan
        ? loan.minimumDebtServiceCoverageRatio
        : undefined,
    totalInterestRatePercent:
      "totalInterestRatePercent" in loan
        ? loan.totalInterestRatePercent
        : undefined,

    // Dates
    extensionMaturityDate:
      "extensionMaturityDate" in loan
        ? new Date(loan.extensionMaturityDate)
        : null,
    hedgeMaturityDate:
      "hedgeMaturityDate" in loan ? new Date(loan.hedgeMaturityDate) : null,
    maturityDate: "maturityDate" in loan ? new Date(loan.maturityDate) : null,
    noticeDateToExtendProvisions:
      "noticeDateToExtendProvisions" in loan
        ? new Date(loan.noticeDateToExtendProvisions)
        : null,
    originationDate:
      "originationDate" in loan ? new Date(loan.originationDate) : null,
  };

  return updatedLoan;
};
