import React from "react";
import { Box, Checkbox } from "@mui/material";
import { IHeaderParams } from "ag-grid-community";

import { NegateActualBudgetContext } from "./NegateActualBudgetContext";

const NegateActualCustomHeader: React.FC<IHeaderParams> = (props) => {
  const {
    allNegateActualChecked,
    updateAllNegateActualChecked,
    updateLastColumnChecked,
    updateProcessAllActualChecked,
  } = React.useContext(NegateActualBudgetContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateLastColumnChecked(props.column?.getColId());
    updateProcessAllActualChecked(true);
    updateAllNegateActualChecked(event.target.checked);
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
        <Checkbox
          name="actualheader"
          checked={allNegateActualChecked.checked}
          onChange={handleChange}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          className="grid-checkbox"
        />
      </Box>
      <Box>{props.displayName.toUpperCase()}</Box>
    </Box>
  );
};

export default NegateActualCustomHeader;
