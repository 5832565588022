export enum GridRowList {
  ACCOUNTS_RECEIVABLE = "Accounts Receivable",
  RENT_ROLL_LEASE_VIEW = "Rent Roll - Lease View",
  RENT_ROLL_SUITE_VIEW = "Rent Roll - Suite View",
  LEASING_ACTIVITY = "Leasing Activity",
  STACKING_PLAN = "Stacking Plan",
  FINANCIALS_BALANCE_SHEET = "Financials - Balance Sheet",
  FINANCIALS_INCOME_STATEMENT = "Financials - Income Statement",
  LOANS = "Loans",
  PROPERTY_OVERVIEW = "Property Overview",
  PROPERTY_SNAPSHOT = "Property Snapshot",
}
