import React, { useEffect, useContext, Fragment } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useIsFetching } from "@tanstack/react-query";
import {
  Box,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  FormHelperText,
  SelectChangeEvent,
  Grid,
  OutlinedInput,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import { UilMultiply } from "@iconscout/react-unicons";

import {
  AlertPost,
  AlertPut,
  IArAlertRule,
  IFrequencySelectList,
} from "../../model/alertsModel";
import {
  maxNumberField,
  numberErrorMessage,
  requiredErrorMessage,
  textErrorMessage,
} from "../../../properties/property details/util/errorTexts";
import InfoChip from "../../../properties/property details/views/InfoChip";
import { useCreateAlert, useEditAlert } from "../../api/alertsService";
import {
  getDisplayProperties,
  getDisplayAmount,
  getDisplayFrequency,
} from "../../util/alertsUtil";
import { UserInfoContext } from "../../util/userInfoContext";
import { useGetPropertyList } from "../../../assetmanagent/property/api/propertyService";

const createAlertText =
  "Your alert has been successfully created. You can see it in Notifications Center.";
const editAlertText = "Successfully updated alerts.";

const createAlertFailText =
  "Your alert could not been saved. Please try again later.";

const editAlertFailText =
  "Your alert could not been edited. Please try again later.";

const SELECT_ALL_OPTION = "all";

interface Props {
  handleClose: (shouldRefreshData: boolean) => void;
  accountId: string;
  editMode: boolean;
  frequencyList: IFrequencySelectList[];
  defaultValues?: IArAlertRule;
}

const CreateAlertDialog: React.FC<Props> = (props) => {
  const { updateAlertDetails } = useContext(UserInfoContext);
  const { handleClose, accountId, editMode, frequencyList, defaultValues } =
    props;

  const editModeDefault = editMode && defaultValues;

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    trigger,
    setError,
  } = useForm<AlertPost>({
    defaultValues: {
      alertTitle: editModeDefault ? defaultValues.alertTitle : "",
      propertyIdList: editModeDefault ? defaultValues.propertyIdList : [],
      totalBalanceThreshold: editModeDefault
        ? defaultValues.totalBalanceThreshold
        : undefined,
      alertRuleFrequencyId: editModeDefault
        ? defaultValues.alertRuleFrequencyId
        : -1,
      sendEmail: editModeDefault ? defaultValues.sendEmail : false,
    },
  });

  const isFetching = useIsFetching();
  const createAlert = useCreateAlert();
  const editAlert = useEditAlert();

  const { data: propertyList } = useGetPropertyList(accountId);

  const onSubmit = handleSubmit((model) => {
    model.accountId = accountId;
    model.propertyIdList = model.propertyIdList.filter(
      (propertyId) => propertyId !== SELECT_ALL_OPTION
    );

    if (editMode && defaultValues) {
      const editModel: AlertPut = {
        ...model,
        alertRuleId: defaultValues.id,
      };
      editAlert.mutate(editModel, {
        onSuccess: (data) => {
          handleClose(true);
          updateAlertDetails(editAlertText, "success");
        },
        onError: (data) => {
          handleClose(false);
          updateAlertDetails(editAlertFailText, "error");
        },
      });
    } else {
      createAlert.mutate(model, {
        onSuccess: (data) => {
          handleClose(true);
          updateAlertDetails(createAlertText, "success");
        },
        onError: (data) => {
          handleClose(false);
          updateAlertDetails(createAlertFailText, "error");
        },
      });
    }
  });

  const propertyIdList = useWatch({
    control,
    name: "propertyIdList",
  });

  const totalBalanceThreshold = useWatch({
    control,
    name: "totalBalanceThreshold",
  });

  const alertRuleFrequencyId = useWatch({
    control,
    name: "alertRuleFrequencyId",
  });

  const sendEmail = useWatch({
    control,
    name: "sendEmail",
  });

  /**
   * Limiting a number to two decimal places correctly
   */
  useEffect(() => {
    if (totalBalanceThreshold === undefined) return;
    const amount = totalBalanceThreshold.toString().split(".");
    if (amount.length > 1) {
      if (amount[1].length > 2) {
        const newNum = Number([amount[0], amount[1].substring(0, 2)].join("."));
        setValue("totalBalanceThreshold", Number(newNum));
      }
    }
  }, [totalBalanceThreshold, setValue]);

  /**
   * Trigger validation if alert is in edit mode and has 0 properties
   */
  useEffect(() => {
    if (
      propertyList &&
      propertyList.length > 0 &&
      props.editMode &&
      props.defaultValues &&
      props.defaultValues.propertyIdList.length === 0
    ) {
      setError("propertyIdList", {
        type: "manual",
        message: requiredErrorMessage,
      });
    }
  }, [props, setError, propertyList]);

  const SelectIcon = (props: Props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0"
      fill="none"
    >
      <path
        d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z"
        fill="#021A3C"
      />
    </svg>
  );

  return (
    <Dialog
      open={true}
      aria-labelledby="new-alert"
      aria-describedby="new-alert-description"
    >
      <DialogTitle id="new-alert">
        <Typography component="span" variant="h2" color="primary.dark">
          {editMode ? "Edit" : "Create"} A/R Alert
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleClose(false)}
          className="icon-dialog-close"
          size="large"
        >
          <UilMultiply />
        </IconButton>
        <Typography component="div" variant="h3" color="neutral700.light">
          Creation of an alert will trigger notifications based on the choices
          you make below.
        </Typography>
      </DialogTitle>
      <DialogContent>
        {isFetching > 0 || propertyList === undefined ? (
          <Box m="auto">
            <CircularProgress />
          </Box>
        ) : (
          <Fragment>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Alert Title*
                  </Typography>
                  <TextField
                    size="small"
                    id="name"
                    variant="outlined"
                    {...register("alertTitle", {
                      required: requiredErrorMessage,
                      maxLength: {
                        value: 50,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.alertTitle)}
                    helperText={
                      Boolean(errors?.alertTitle)
                        ? errors?.alertTitle?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  size="small"
                  error={Boolean(errors.propertyIdList)}
                  className={"form-control"}
                >
                  <Typography variant="body3" component="label">
                    Alert on Property*
                  </Typography>
                  <Controller
                    control={control}
                    name="propertyIdList"
                    rules={{
                      validate: {
                        required: (values) => {
                          if (Array.isArray(values)) {
                            if (
                              values.length === 0 ||
                              (values.length === 1 &&
                                values.includes(SELECT_ALL_OPTION))
                            )
                              return requiredErrorMessage;
                          }

                          return true;
                        },
                      },
                    }}
                    defaultValue={[]}
                    render={({ field }) => {
                      const currentValues = [...field.value];
                      const allPropertiesSelected =
                        (propertyList.length > 0 &&
                          currentValues.length === propertyList.length) ||
                        currentValues.includes(SELECT_ALL_OPTION);
                      const currentValuesLength = currentValues.includes(
                        SELECT_ALL_OPTION
                      )
                        ? currentValues.length - 1
                        : currentValues.length;
                      if (
                        allPropertiesSelected &&
                        !currentValues.includes("all")
                      ) {
                        currentValues.push("all");
                      }
                      return (
                        <Select
                          labelId="alert-properties"
                          multiple
                          fullWidth
                          id="properties"
                          className={"form-control-field"}
                          MenuProps={{ autoFocus: false }}
                          value={currentValues}
                          onChange={(e: SelectChangeEvent<string[]>) => {
                            const newVals = e.target.value;
                            let uniqueVals: string[] = [];
                            if (
                              Array.isArray(newVals) &&
                              Array.isArray(currentValues)
                            ) {
                              if (newVals.length > currentValues.length) {
                                uniqueVals = newVals.filter(
                                  (newVal) =>
                                    currentValues.indexOf(newVal) === -1
                                );
                              } else
                                uniqueVals = currentValues.filter(
                                  (currentValue) =>
                                    newVals.indexOf(currentValue) === -1
                                );
                            }

                            // if select all is checked
                            if (
                              newVals.includes(SELECT_ALL_OPTION) &&
                              uniqueVals.includes(SELECT_ALL_OPTION)
                            ) {
                              const setValues = propertyList.map(
                                (property) => property.id
                              );
                              setValues.push(SELECT_ALL_OPTION);
                              field.onChange(setValues);
                            }
                            // if select all is unchecked
                            else if (
                              currentValues.length ===
                                propertyList.length + 1 &&
                              !newVals.includes(SELECT_ALL_OPTION)
                            ) {
                              field.onChange([] as string[]);
                            }
                            // if all the properties are selected, then set "select all" as checked
                            else if (
                              newVals.length === propertyList.length &&
                              currentValues.length < propertyList.length
                            ) {
                              const setValues = propertyList.map(
                                (property) => property.id
                              );
                              setValues.push(SELECT_ALL_OPTION);
                              field.onChange(setValues);
                            }
                            // if all the properties are unchecked, set "Select all" as unchecked
                            else if (
                              currentValues.length === 2 &&
                              newVals.length === 1 &&
                              newVals.includes(SELECT_ALL_OPTION)
                            ) {
                              field.onChange([] as string[]);
                            }
                            // check/uncheck items in the case it is not an edge case
                            else {
                              if (
                                newVals.length < currentValues.length &&
                                Array.isArray(newVals)
                              ) {
                                field.onChange(
                                  newVals.filter(
                                    (newVal) => newVal !== SELECT_ALL_OPTION
                                  )
                                );
                              } else {
                                field.onChange(newVals);
                              }
                            }

                            if (editMode) trigger("propertyIdList");
                          }}
                          onBlur={field.onBlur}
                          renderValue={(selected: any) => {
                            if (!propertyList) return selected;
                            const newSelected = selected.filter(
                              (propertyId: any) =>
                                propertyId !== SELECT_ALL_OPTION
                            );
                            return newSelected
                              .map((selectedPropertyId: any) => {
                                const property = propertyList.find(
                                  (property) =>
                                    property.id === selectedPropertyId
                                );
                                return property?.title;
                              })
                              .join(", ");
                          }}
                          IconComponent={SelectIcon}
                        >
                          <MenuItem value={SELECT_ALL_OPTION}>
                            <Checkbox
                              checked={allPropertiesSelected}
                              indeterminate={
                                currentValuesLength > 0 &&
                                currentValuesLength < propertyList.length
                              }
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>
                          {propertyList.map((property) => (
                            <MenuItem key={property.id} value={property.id}>
                              <Checkbox
                                checked={
                                  currentValues.indexOf(property.id) > -1
                                }
                              />
                              <ListItemText primary={property.title} />
                            </MenuItem>
                          ))}
                        </Select>
                      );
                    }}
                  />
                  {Boolean(errors.propertyIdList) && (
                    <FormHelperText>
                      {(errors.propertyIdList as any)?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Over Dollar Amount*
                  </Typography>
                  <TextField
                    size="small"
                    id="dollar-amount"
                    variant="outlined"
                    type="number"
                    {...register("totalBalanceThreshold", {
                      valueAsNumber: true,
                      required: {
                        value: true,
                        message: requiredErrorMessage,
                      },
                      min: {
                        value: 0,
                        message: numberErrorMessage,
                      },
                      max: {
                        value: maxNumberField,
                        message: numberErrorMessage,
                      },
                      validate: {
                        overZero: (value) => {
                          if (value === 0)
                            return "Dollar value must be greater than $0";
                          else return true;
                        },
                      },
                    })}
                    error={Boolean(errors?.totalBalanceThreshold)}
                    helperText={
                      Boolean(errors?.totalBalanceThreshold)
                        ? errors?.totalBalanceThreshold?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  size="small"
                  error={Boolean(errors.alertRuleFrequencyId)}
                  className={"form-control"}
                >
                  <Typography variant="body3" component="label">
                    Alert Frequency*
                  </Typography>
                  <Controller
                    control={control}
                    name={"alertRuleFrequencyId"}
                    rules={{
                      validate: {
                        required: (v) =>
                          v === -1 ? requiredErrorMessage : true,
                      },
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="alertRuleFrequencyId"
                        className={"form-control-field"}
                        displayEmpty
                        input={<OutlinedInput />}
                        IconComponent={SelectIcon}
                      >
                        {frequencyList.map((frequencyOption) => (
                          <MenuItem
                            key={frequencyOption.key}
                            value={frequencyOption.key}
                          >
                            {frequencyOption.value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {Boolean(errors.alertRuleFrequencyId) && (
                    <FormHelperText>
                      {(errors.alertRuleFrequencyId as any)?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Controller
                    control={control}
                    name={"sendEmail"}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            size="small"
                            id="email-me"
                            {...register("sendEmail")}
                          />
                        }
                        label={
                          <Typography variant="body3">
                            And email me
                          </Typography>
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <InfoChip
              chipTheme="warning"
              chipText="Your alert"
              styles={{
                padding: "3px 0px",
                marginLeft: "8px",
                backgroundColor: "#DFE7F1",
                color: "darkslategray",
                width: "90px",
              }}
            />
            <Box pl={1} mt={1}>
              {`If a tenant's A/R balance for ${getDisplayProperties(
                propertyList,
                propertyIdList
              )} is greater than $${getDisplayAmount(
                totalBalanceThreshold
              )}, please alert me ${getDisplayFrequency(
                frequencyList,
                alertRuleFrequencyId
              )} via an in-app notification${
                sendEmail === true ? " and email me" : ""
              }.`}
            </Box>
            {createAlert.isLoading && (
              <Box m="auto">
                <CircularProgress />
              </Box>
            )}
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleClose(false)}
          disabled={createAlert.isLoading}
        >
          {editMode ? "Close" : "Cancel"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={createAlert.isLoading || isFetching > 0}
        >
          {editMode ? "Save" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAlertDialog;
