import React, { useState } from "react";
import { Box, Tooltip, Grid } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import EditLoan from "./EditLoan";
import { UilTrashAlt } from "@iconscout/react-unicons";
import { UilPen } from "@iconscout/react-unicons";

interface LoanCellRendererParams extends ICellRendererParams {
  hasLoanPermission: boolean;
  onLoanSaved: (loanId: string) => void;
  onDeleteLoan: (loanId: string) => void;
}

const EditLoanRenderer: React.FC<LoanCellRendererParams> = (props) => {
  const [editLoan, setEditLoan] = useState(false);

  if (!props.hasLoanPermission) return <React.Fragment />;

  return (
    <React.Fragment>
      {editLoan && (
        <EditLoan
          loanId={props.data.loanId}
          openDialog={editLoan}
          closeDialog={(isLoanSaved?: boolean) => {
            setEditLoan(false);
            if (isLoanSaved) props.onLoanSaved(props.data.loanId);
          }}
        />
      )}
      <Grid container spacing={2}>
        <Grid
          item
          sx={{
            "& svg": {
              maxHeight: "20px",
              maxWidth: "20px",
              lineHeight: "1",
            },
          }}
        >
          <Tooltip title="Edit" style={{ cursor: "pointer" }}>
            <Box
              onClick={() => setEditLoan(true)}
              className={"icon-documents"}
              aria-label="edit"
            >
              <UilPen />
            </Box>
          </Tooltip>
        </Grid>
        <Grid
          item
          sx={{
            "& svg": {
              maxHeight: "20px",
              maxWidth: "20px",
              lineHeight: "1",
            },
          }}
        >
          <Tooltip title="Delete" style={{ cursor: "pointer" }}>
            <Box
              onClick={() => props.onDeleteLoan(props.data.loanId)}
              className={"icon-documents"}
              aria-label="delete"
            >
              <UilTrashAlt />
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EditLoanRenderer;
