export enum PropertyTypeEnum {
  CommercialOffice = 1,
  CommercialRetail = 2,
  CommercialIndustrial = 3,
  CommercialHotel = 7,
  CommercialMedicalOffice = 9,
  CommercialMixedUse = 10,

  ResidentialMultifamily = 4,
  ResidentialSingleFamily = 5,

  Land = 8,

  SelfStorage = 11,

  Unknown = 99,
}

export function getRowHeaderPropertyTypeFriendlyText(type: PropertyTypeEnum) {
  switch (type) {
    case PropertyTypeEnum.CommercialOffice:
      return "Office";
    case PropertyTypeEnum.CommercialRetail:
      return "Retail";
    case PropertyTypeEnum.CommercialIndustrial:
      return "Industrial";
    case PropertyTypeEnum.CommercialHotel:
      return "Hotel";
    case PropertyTypeEnum.CommercialMedicalOffice:
      return "Medical Office";
    case PropertyTypeEnum.CommercialMixedUse:
      return "Mixed Use";
    case PropertyTypeEnum.ResidentialMultifamily:
      return "Multifamily";
    case PropertyTypeEnum.ResidentialSingleFamily:
      return "Single Family";
    case PropertyTypeEnum.Land:
      return "Land";
    case PropertyTypeEnum.SelfStorage:
      return "Self Storage";
    case PropertyTypeEnum.Unknown:
      return "Unknown";
    default:
      return "";
  }
}
