import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "../../../../shared/api/authorizedAxios";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";
import { FinancialsFilter } from "../../financials/views/PropertyViewFinancials";

interface FinancialsApiParams {
  propertyId: string;
  accountIdentifier: string;
  financialsFilter: FinancialsFilter;
}

export const useGetFinancialData = (
  financialsApiParams: FinancialsApiParams
) => {
  const { propertyId, accountIdentifier, financialsFilter } =
    financialsApiParams;
  let {
    startYear,
    startMonth,
    endYear,
    endMonth,
    groupingMode,
    businessUnitId,
    glTreeId,
    accountTypeId,
    budgetView,
  } = financialsFilter;

  const dummyRequest =
    accountTypeId.trim().length === 0 ||
    glTreeId.trim().length === 0;

  const authorizedApiAxios = useAuthorizedApiAxios();

  const item = { propertyId: propertyId, accountIdentifier: accountIdentifier };
  const inputs = {
    startYear,
    startMonth,
    endYear,
    endMonth,
    groupingMode,
    businessUnitId,
    glTreeId,
    amounttypeid: accountTypeId,
    budgetView,
  };
  const qry = queryString.stringify(inputs, { skipEmptyString: true });

  const queryName = dummyRequest
    ? "budget_invalid_request"
    : "budget" +
      propertyId +
      startYear +
      startMonth +
      endYear +
      endMonth +
      groupingMode +
      businessUnitId +
      glTreeId +
      accountTypeId +
      budgetView;

  return useQuery(
    [queryName, item],
    ({ signal }) => {
      const url: string = `/assetService/v1/financials/${item.propertyId}?${qry}`;

      return authorizedApiAxios().get<any>(url, {
        signal,
        headers: {
          [AccountIdentifierHeaderName]: item.accountIdentifier,
        },
      });
    },
    {
      select: (response) => response?.data,
      enabled:
        propertyId.length > 0 && accountIdentifier.length > 0 && !dummyRequest,
    }
  );
};
