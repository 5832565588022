export interface DealProposal {
  createdDate: string;
  dealProposalType: string;
  rentableArea: number;
  dealSpaces: string;
  dealProposalLeaseType: string;
  leaseTerm: number;
  commencementDate: string;
  leaseExpirationDate: string;
  baseRentBeginMonth?: number;
  baseRentValue: number;
  escalationBeginMonth?: number;
  escalationValue?: number;
  escalationRentUnitId?: DealProposalRentUnit;
  tenantImprovementAmount?: number;
  freeRentDuration?: number;
  netEffectiveRent: number;
}

export enum DealProposalRentUnit {
  Bump = 1,
  Percentage = 2,
  Month = 3,
  RSFMonth = 4,
  RSFYear = 5,
  Year = 6,
}
