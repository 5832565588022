import { ColDef } from "ag-grid-community";

import {
  currencyFormatter,
  negativeNumCellStyle,
  numberFormatter,
  numberGetter,
  pctGetter,
  percentFormatter,
  stringSortCaseInsensitive,
} from "../../util/gridUtils";

export const getPropertyColumns = () => {
  const columnDefs: ColDef[] = [
    {
      headerName: "Property",
      sortable: true,
      filter: "agTextColumnFilter",
      comparator: stringSortCaseInsensitive,
      cellRenderer: "propertyListImageRenderer",
      //autoHeight: true,
      minWidth: 300,
      cellClass: ["property-list-name-col"],
      valueGetter: 
        function (params) {
          return params.data.propertyName + params.data.street;
        },
    },
    {
      headerName: "Street",
      field: "street",
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      headerName: "City",
      field: "city",
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "State",
      field: "state",
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Zip",
      field: "zip",
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Asset Type",
      field: "assetType",
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Asset Class",
      field: "assetClass",
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Rentable Area",
      field: "rentableArea",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      cellClass: ["alignRight", "numberFormat"],
      valueFormatter: (params: any) =>
        numberFormatter(params.data.rentableArea, 0),
    },
    // Remove Occupancy (Area), it is duplicated and showing same data with Leased (%) in current gen
    // {
    //   headerName: "Occupancy (Area)",
    //   field: "occupancyByArea",
    //   filter: "agNumberColumnFilter",
    //   sortable: true,
    //   type: "numericColumn",
    //   valueFormatter: (params: any) =>
    //     percentFormatter(params.data.occupancyByArea, "%"),
    //   valueGetter: (params: any) => pctGetter(params.data.occupancyByArea),
    //   cellStyle: negativeNumCellStyle,
    // },
    {
      headerName: "Leased (%)",
      field: "leasedPercentage",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      valueFormatter: (params: any) =>
        percentFormatter(params.data.leasedPercentage, "%"),
      valueGetter: (params: any) => pctGetter(params.data.leasedPercentage),
      cellStyle: negativeNumCellStyle,
    },
    {
      field: "monthlyRentPerArea",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.monthlyRentPerArea, "$"),
      valueGetter: (params: any) =>
        numberGetter(params.data.monthlyRentPerArea),
      cellStyle: negativeNumCellStyle,
    },
    {
      field: "revenue",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.revenue, "$"),
      valueGetter: (params: any) => numberGetter(params.data.revenue),
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Expenses",
      field: "expenses",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.expenses, "$"),
      valueGetter: (params: any) => numberGetter(params.data.expenses),
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "NOI",
      field: "noi",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params: any) => currencyFormatter(params.data.noi, "$"),
      valueGetter: (params: any) => numberGetter(params.data.noi),
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "NCF",
      field: "ncf",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params: any) => currencyFormatter(params.data.ncf, "$"),
      valueGetter: (params: any) => numberGetter(params.data.ncf),
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Image Url",
      field: "imageUrl",
      sortable: true,
      filter: "agTextColumnFilter",
      hide: true,
    },
  ];
  return columnDefs;
};
