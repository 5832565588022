import React from "react";

import { usePropertyInfo } from "../../util/propertyInfoContext";
import { useGetLoan } from "../api/loansService";
import { formatLoanDefaultValues } from "../util/editLoan";
import LoansFormModal from "./LoansFormModal";

interface IEditLoanProps {
  loanId: string;
  openDialog: boolean;
  closeDialog: (isLoanSaved?: boolean) => void;
}

const EditLoan: React.FC<IEditLoanProps> = (props) => {
  const propertyInfo = usePropertyInfo();
  const loanQuery = useGetLoan({
    loanId: props.loanId,
    propertyId: propertyInfo.id,
    accountIdentifier: propertyInfo.accountIdentifier,
  });
  const { data: loanData, isLoading, isError } = loanQuery;

  const defaultLoanValues = React.useMemo(
    () => formatLoanDefaultValues(loanData),
    [loanData]
  );

  return (
    <React.Fragment>
      <LoansFormModal
        openDialog={props.openDialog}
        closeDialog={props.closeDialog}
        defaultLoanFormValues={defaultLoanValues}
        loanId={props.loanId}
        newLoan={false}
        isLoading={isLoading}
        isError={isError}
      />
    </React.Fragment>
  );
};

export default EditLoan;
