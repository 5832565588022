import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { useIsFetching } from "@tanstack/react-query";

import { TCreateUserModel } from "./../model/createUserModel";
import { useCreateUser } from "./../api/userService";
import { isValidEmail } from "../../properties/loans/util/lenderServicerForm";
import { UilMultiply } from "@iconscout/react-unicons";

interface UserInsertProps {
  handlePopupClose: (shouldRefreshData: boolean, accountId?: string) => void;
  showInsertPopup: boolean;
  accountIdentifier: string;
  emailList: string[] | undefined;
}

const UserInsert: React.FunctionComponent<UserInsertProps> = (props) => {
  const [disable, setDisable] = useState(false);
  const isFetching = useIsFetching();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<TCreateUserModel>({
    defaultValues: {
      email: "",
    },
  });
  const createUserMutate = useCreateUser();

  const onSubmit = handleSubmit((model: TCreateUserModel) => {
    model.accountIdentifier = props.accountIdentifier;
    createUserMutate.mutate(model, {
      onSettled: (data) => {
        props.handlePopupClose(true, props.accountIdentifier);
      },
    });
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      props.emailList !== undefined &&
      props.emailList
        .map((email) => email.toLowerCase())
        .includes(event.target.value.toLowerCase())
    ) {
      setDisable(true);
      setError("email", {
        type: "manual",
        message: "This email is already linked to an account.",
      });
    } else {
      clearErrors("email");
      setDisable(false);
    }
  };

  const handleClose = useCallback(() => {
    props.handlePopupClose(false);
  }, [props]);

  return (
    <Dialog
      open={props.showInsertPopup}
      onClose={handleClose}
      aria-labelledby="new-user"
      aria-describedby="new-user-description"
    >
      <DialogTitle id="new-user">
        <Typography component="span" variant="h2" color="primary.dark">
          Add New User
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="icon-dialog-close"
          size="large"
        >
          <UilMultiply />
        </IconButton>
        <Typography component="div" variant="h3" color="neutral700.light">
          To create a new user, please fill out the form below.
        </Typography>
      </DialogTitle>
      <DialogContent>
        {isFetching > 0 ? (
          <Box m="auto">
            <CircularProgress />
          </Box>
        ) : (
          <React.Fragment>
            <Grid container>
              <Grid item xs={12}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Email*
                  </Typography>
                  <TextField
                    size="small"
                    id="user-email"
                    //label="Email"
                    variant="outlined"
                    {...register("email", {
                      required: "Email is required",
                      maxLength: {
                        value: 50,
                        message: "Maximum length is 256",
                      },
                      validate: {
                        format: (value) =>
                          isValidEmail(value) || "Incorrect email address.",
                      },
                    })}
                    onChange={handleChange}
                    error={Boolean(errors?.email)}
                    helperText={
                      Boolean(errors?.email) ? errors?.email?.message : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {createUserMutate.isLoading && (
              <Box m="auto">
                <CircularProgress />
              </Box>
            )}
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={onSubmit}
          disabled={disable || createUserMutate.isLoading}
        >
          Add User
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserInsert;
