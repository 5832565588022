import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { FilterChangedEvent, GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridColumn } from "ag-grid-react";

import { IUserPropertyAssignment } from "./models/userPropertyAssignment";
import UserPropAssignCustomHeader from "./UserPropAssignCustomHeader";
import UPAAccessRenderer from "./UPAAccessRenderer";
import { RenderedRow } from "./models/userPropertyAssignmentContext";
import { UPAContext } from "./UserPropertyAssignmentContext";
import { stringSortCaseInsensitive } from "../../../properties/util/gridUtils";

interface UserPropAssignGridProps {
  rowData: IUserPropertyAssignment[];
  updateRenderedRows: (renderedRows: RenderedRow[]) => void;
}

const UserPropAssignGrid: React.FC<UserPropAssignGridProps> = (props) => {
  const { rowData, updateRenderedRows } = props;
  const { allUsersChecked, updateMultipleUserProperties } =
    React.useContext(UPAContext);

  const [gridApi, setGridApi] = useState<GridApi>();

  React.useEffect(() => {
    if (!allUsersChecked.touched) return;

    // Whenever the user clicks on check/uncheck all (on the "Grant Access" column header)
    const newUserProperties: IUserPropertyAssignment[] = [];
    gridApi?.forEachNodeAfterFilter((node) => {
      const state = node.data?.state ?? undefined;
      const city = node.data?.city ?? undefined;
      const zip = node.data?.zip ?? undefined;
      newUserProperties.push({
        userId: node.data.userId,
        userName: node.data.userName,
        email: node.data.email,
        propertyId: node.data.propertyId,
        propertyTitle: node.data.propertyTitle,
        grantAccess: allUsersChecked.checked,
        state,
        city,
        zip,
      });
    });

    updateMultipleUserProperties(newUserProperties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsersChecked.checked, allUsersChecked.touched]);

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);

    // Updating the list of rows that have been rendered onto the screen
    const newRenderedRows: RenderedRow[] = [];
    event.api.forEachNodeAfterFilter((node) => {
      newRenderedRows.push({
        userId: node.data.userId,
        propertyId: node.data.propertyId,
      });
    });
    updateRenderedRows(newRenderedRows);
  };

  const onFilterChanged = (event: FilterChangedEvent) => {
    // Updating the list of rows that have been rendered onto the screen
    const newRenderedRows: RenderedRow[] = [];
    event.api.forEachNodeAfterFilter((node) => {
      newRenderedRows.push({
        userId: node.data.userId,
        propertyId: node.data.propertyId,
      });
    });
    updateRenderedRows(newRenderedRows);
  };

  return (
    <div id="userprop-main-content" className="grid-container">
      <div
        id="userpropGrid"
        className="ag-theme-alpine ag-theme-alpine-container-override"
      >
        <AgGridReact
          rowData={rowData}
          suppressMenuHide={true}
          components={{
            customHeader: UserPropAssignCustomHeader,
            UPAAccessRenderer: UPAAccessRenderer,
          }}
          defaultColDef={{
            editable: true,
            flex: 1,
            minWidth: 100,
            resizable: true,
            filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
          }}
          onGridReady={onGridReady}
          onFilterChanged={onFilterChanged}
        >
          <AgGridColumn
            field="userName"
            headerName="Full Name"
            comparator={stringSortCaseInsensitive}
            sortable={true}
            filter={"agTextColumnFilter"}
            editable={false}
          />
          <AgGridColumn
            field="email"
            sortable={true}
            comparator={stringSortCaseInsensitive}
            filter={"agTextColumnFilter"}
            editable={false}
          />
          <AgGridColumn
            field="propertyTitle"
            headerName="Property Name"
            sortable={true}
            comparator={stringSortCaseInsensitive}
            filter={"agTextColumnFilter"}
            editable={false}
          />
          <AgGridColumn
            field="state"
            sortable={true}
            comparator={stringSortCaseInsensitive}
            filter={"agTextColumnFilter"}
            editable={false}
          />
          <AgGridColumn
            field="city"
            sortable={true}
            comparator={stringSortCaseInsensitive}
            filter={"agTextColumnFilter"}
            editable={false}
          />
          <AgGridColumn
            field="zip"
            sortable={true}
            comparator={stringSortCaseInsensitive}
            filter={"agTextColumnFilter"}
            editable={false}
          />
          <AgGridColumn
            field="grantAccess"
            headerComponent="customHeader"
            cellRenderer="UPAAccessRenderer"
            editable={false}
          />
        </AgGridReact>
      </div>
    </div>
  );
};

export default UserPropAssignGrid;
