import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  DialogActions,
  Button,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { formatDateFNS } from "../../property details/util/formatDateUtil";
import { formatCurrency, formatNumber } from "../../util/formatNumber";
import { useGetDealProposals } from "../api/leasingActivityService";
import { DealProposalRentUnit } from "../model/dealProposal";
import { DealProposalsFilter } from "../model/dealProposalsFilter";
import { UilMultiply } from "@iconscout/react-unicons";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

interface DealProposalsProps {
  handlePopupClose: () => void;
  filter: DealProposalsFilter;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leasingActivityModalDialog: {
      "& .MuiDialog-paper": {
        padding: 0,
      },
      "& .MuiDialog-paper .MuiDialogTitle-root": {
        padding: "16px 24px 8px 24px",
      },
      "& .MuiDialog-paper .MuiDialogActions-root": {
        padding: "8px 24px 16px 24px",
      },
      "& .MuiDialog-paper .MuiTypography-body1": {
        padding: 0,
        fontSize: 15,
        fontWeight: "normal",
        color: "var(--primary-dark-blue)",
      },
      "& .MuiDialog-paper .MuiDialogContent-root": {
        padding: "8px 0",
      },
      "& .MuiTable-root": {
        borderTop: "1px solid var(--neutral-grey-150)",
      },
      "& .MuiTableCell-root": {
        padding: "12px 20px",
        border: "1px solid var(--neutral-grey-150)",
        borderTop: 0,
        borderLeft: 0,
        verticalAlign: "top",
        overflowWrap: "break-word",
        breakWord: "break-all",
      },
      "& .MuiTableCell-head": {
        whiteSpace: "nowrap",
      },
      "& .MuiTableCell-root:last-child": {
        borderRight: 0,
      },
    },
  })
);

const DealProposals: React.FunctionComponent<DealProposalsProps> = (props) => {
  const classes = useStyles();
  const dealProposals = useGetDealProposals(props.filter);

  const getRentUnitValue = (rentUnit: DealProposalRentUnit, value: number) => {
    switch (rentUnit) {
      case DealProposalRentUnit.Bump:
        return formatCurrency(value);
      default:
        return formatNumber(value);
    }
  };

  const getRentUnitSuffix = (rentUnit: DealProposalRentUnit) => {
    switch (rentUnit) {
      case DealProposalRentUnit.Bump:
        return " /yr";
      case DealProposalRentUnit.Percentage:
        return "% /yr";
      default:
        return "";
    }
  };

  return (
    <Dialog
      open={true}
      aria-labelledby="deal-proposals"
      fullWidth
      maxWidth="md"
      className={classes.leasingActivityModalDialog}
    >
      <DialogTitle id="deal-proposals-modal">
        <Typography component="span" variant="h2" color="primary.dark">
          Terms
        </Typography>
        <IconButton
          aria-label="close"
          disabled={dealProposals.isLoading}
          onClick={() => props.handlePopupClose()}
          className="icon-dialog-close"
          size="large"
        >
          <UilMultiply />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {dealProposals.isFetching || !dealProposals.data ? (
          <Box m="auto">
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ width: "100%" }}>
            <TableContainer>
              <Table aria-label="terms" style={{ overflow: "hidden" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        Entered
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        By
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        RSF
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        Space
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        Type
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        Term
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        LCD
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        LXD
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        Base Rent/SF
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        Esca
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        TI/SF
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        Free
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        NER/Yr
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dealProposals.data.map((proposal, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {proposal.createdDate
                            ? formatDateFNS(proposal.createdDate, "MM/dd/yyyy")
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {proposal.dealProposalType}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {formatNumber(proposal.rentableArea)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {proposal.dealSpaces}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {proposal.dealProposalLeaseType}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {formatNumber(proposal.leaseTerm)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {proposal.commencementDate
                            ? formatDateFNS(
                                proposal.commencementDate,
                                "MM/dd/yyyy"
                              )
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {proposal.leaseExpirationDate
                            ? formatDateFNS(proposal.leaseExpirationDate, "MM/dd/yyyy")
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {proposal.baseRentBeginMonth
                            ? proposal.baseRentBeginMonth.toString() +
                              " - " +
                              proposal.leaseTerm.toString() +
                              ": " +
                              formatCurrency(proposal.baseRentValue || 0)
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {proposal.escalationBeginMonth
                            ? proposal.escalationBeginMonth.toString() +
                              " - " +
                              proposal.leaseTerm +
                              ": " +
                              (proposal.escalationRentUnitId
                                ? getRentUnitValue(
                                    proposal.escalationRentUnitId,
                                    proposal.escalationValue || 0
                                  ) +
                                  getRentUnitSuffix(
                                    proposal.escalationRentUnitId
                                  )
                                : "-")
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {proposal.tenantImprovementAmount
                            ? formatCurrency(proposal.tenantImprovementAmount)
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {proposal.freeRentDuration
                            ? proposal.freeRentDuration.toString() +
                              " Mo. Gross"
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {formatCurrency(proposal.netEffectiveRent || 0)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={dealProposals.isLoading}
          onClick={() => props.handlePopupClose()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DealProposals;
