import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  AccountIdentifierHeaderName,
  useAuthorizedApiAxios,
} from "../../../shared/api/authorizedAxios";
import {
  IPutWidgetConfigurations,
  IWidgetConfigRow,
} from "../model/widgetConfigModel";

export const useGetWidgetConfig = (accountId: string) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["usersWidgetConfiguration", accountId],
    () => {
      const source = axios.CancelToken.source();
      const url = `/AssetService/v1/WidgetConfiguration`;

      let promise = authorizedApiAxios().get<IWidgetConfigRow[]>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: accountId,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => response.data,
      enabled: accountId.trim().length > 0,
    }
  );
};

export const useUpdateWidgetsConfig = (accountId: string) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((widgetConfigurations: IPutWidgetConfigurations) => {
    const source = axios.CancelToken.source();

    const url = `/AssetService/v1/WidgetConfiguration`;
    let promise = authorizedApiAxios().put<string>(url, widgetConfigurations, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: accountId,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};
