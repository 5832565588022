import React, {
  useState,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import {
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  SelectChangeEvent
} from "@mui/material";

import "react-datepicker/dist/react-datepicker.css";
import { ICellRendererParams } from "ag-grid-community";
import { GridRowList } from "../util/constant";

export const CustomEditorComponent = forwardRef((props: ICellRendererParams, ref) => {
  const [editing, setEditing] = useState(true);

  const [selectedIndex, setSelectedIndex] = useState("Yes");

  useEffect(() => {
    if (!editing) {
      props.api.stopEditing();
    }
    if (props.data.report === GridRowList.FINANCIALS_INCOME_STATEMENT) {
      setSelectedIndex(props.value);
    } else {
      setSelectedIndex("");
    }
  }, [editing, props]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return selectedIndex;
      }
    };
  });

  return (
    <>
      {
        (props.data.report === GridRowList.FINANCIALS_INCOME_STATEMENT) && (
          <FormControl variant="outlined" size="small">
            <Select
              labelId="property-rr-perspective"
              displayEmpty
              input={<OutlinedInput />}
              className={"input-field"}
              value={selectedIndex}
              onChange={(event: SelectChangeEvent) => {
                setSelectedIndex(event.target.value);
                setEditing(false);
              }}
            >
              <MenuItem key={0} value="Yes">Yes</MenuItem>
              <MenuItem key={1} value="No">No</MenuItem>
            </Select>
          </FormControl>
        )
      }
    </>
  );
});

export default CustomEditorComponent;
