import { ColDef } from "ag-grid-community";
import { formatNumber } from "../../../properties/util/formatNumber";

import {
  IExpirationScheduleRsf,
  IExpirationScheduleSummary,
} from "../model/portfolioLeasingActivity";

export const computeExpirationTotals = (
  portfolioExpirationSummary: IExpirationScheduleSummary
) => {
  const totals: IExpirationScheduleRsf[] = [];
  totals.push({
    ...portfolioExpirationSummary,
    propertyId: "",
    propertyName: "TOTALS",
    year1RsfDescription: "",
    year2RsfDescription: "",
    year3RsfDescription: "",
    year4RsfDescription: "",
    year5RsfDescription: "",
    year6RsfDescription: "",
    year7RsfDescription: "",
    year8RsfDescription: "",
    year9RsfDescription: "",
    year10PlusRsfDescription: "",
  });
  return totals;
};

export const getPortfolioExpirationColumnNames = (
  portfolioExpirationRow?: IExpirationScheduleRsf
) => {
  if (portfolioExpirationRow === undefined) {
    const colNames: string[] = [];
    for (let i = 0; i < 10; i++) colNames.push("-");
    return colNames;
  }
  const columnNames: string[] = [
    portfolioExpirationRow.year1RsfDescription,
    portfolioExpirationRow.year2RsfDescription,
    portfolioExpirationRow.year3RsfDescription,
    portfolioExpirationRow.year4RsfDescription,
    portfolioExpirationRow.year5RsfDescription,
    portfolioExpirationRow.year6RsfDescription,
    portfolioExpirationRow.year7RsfDescription,
    portfolioExpirationRow.year8RsfDescription,
    portfolioExpirationRow.year9RsfDescription,
    portfolioExpirationRow.year10PlusRsfDescription,
  ];

  return columnNames;
};

export const getPortfolioExpirationScheduleColumns = (
  columnNames: string[]
) => {
  const columnDefs: ColDef[] = [
    {
      headerName: "Property",
      field: "propertyName",
      sortable: false,
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        if (params.data.propertyName === undefined) return "";
        return params.data.propertyName;
      },
      cellRenderer: "linkComponent",
    },
    {
      headerName: "Total Expiring",
      field: "totalExpiring",
      sortable: false,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "decimalPlaces"],
      valueGetter: (params: any) =>
        formatExpirationValue(
          params.data.totalExpiring,
          params.data.totalExpiringPercent
        ),
      valueFormatter: (params) =>
        formatExpirationValue(
          params.data.totalExpiring,
          params.data.totalExpiringPercent
        ),
    },
    {
      headerName: columnNames[0],
      sortable: false,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "decimalPlaces"],
      valueGetter: (params: any) =>
        formatExpirationValue(
          params.data.year1RsfValue,
          params.data.year1RsfPercentage
        ),
      valueFormatter: (params) =>
        formatExpirationValue(
          params.data.year1RsfValue,
          params.data.year1RsfPercentage
        ),
    },
    {
      headerName: columnNames[1],
      sortable: false,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "decimalPlaces"],
      valueGetter: (params: any) =>
        formatExpirationValue(
          params.data.year2RsfValue,
          params.data.year2RsfPercentage
        ),
      valueFormatter: (params) =>
        formatExpirationValue(
          params.data.year2RsfValue,
          params.data.year2RsfPercentage
        ),
    },
    {
      headerName: columnNames[2],
      sortable: false,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "decimalPlaces"],
      valueGetter: (params: any) =>
        formatExpirationValue(
          params.data.year3RsfValue,
          params.data.year3RsfPercentage
        ),
      valueFormatter: (params) =>
        formatExpirationValue(
          params.data.year3RsfValue,
          params.data.year3RsfPercentage
        ),
    },
    {
      headerName: columnNames[3],
      sortable: false,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "decimalPlaces"],
      valueGetter: (params: any) =>
        formatExpirationValue(
          params.data.year4RsfValue,
          params.data.year4RsfPercentage
        ),
      valueFormatter: (params) =>
        formatExpirationValue(
          params.data.year4RsfValue,
          params.data.year4RsfPercentage
        ),
    },
    {
      headerName: columnNames[4],
      sortable: false,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "decimalPlaces"],
      valueGetter: (params: any) =>
        formatExpirationValue(
          params.data.year5RsfValue,
          params.data.year5RsfPercentage
        ),
      valueFormatter: (params) =>
        formatExpirationValue(
          params.data.year5RsfValue,
          params.data.year5RsfPercentage
        ),
    },
    {
      headerName: columnNames[5],
      sortable: false,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "decimalPlaces"],
      valueGetter: (params: any) =>
        formatExpirationValue(
          params.data.year6RsfValue,
          params.data.year6RsfPercentage
        ),
      valueFormatter: (params) =>
        formatExpirationValue(
          params.data.year6RsfValue,
          params.data.year6RsfPercentage
        ),
    },

    {
      headerName: columnNames[6],
      sortable: false,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "currencyFormat"],
      valueGetter: (params: any) =>
        formatExpirationValue(
          params.data.year7RsfValue,
          params.data.year7RsfPercentage
        ),
      valueFormatter: (params) =>
        formatExpirationValue(
          params.data.year7RsfValue,
          params.data.year7RsfPercentage
        ),
    },
    {
      headerName: columnNames[7],
      sortable: false,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "currencyFormat"],
      valueGetter: (params: any) =>
        formatExpirationValue(
          params.data.year8RsfValue,
          params.data.year8RsfPercentage
        ),
      valueFormatter: (params) =>
        formatExpirationValue(
          params.data.year8RsfValue,
          params.data.year8RsfPercentage
        ),
    },
    {
      headerName: columnNames[8],
      sortable: false,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "currencyFormat"],
      valueGetter: (params: any) =>
        formatExpirationValue(
          params.data.year9RsfValue,
          params.data.year9RsfPercentage
        ),
      valueFormatter: (params) =>
        formatExpirationValue(
          params.data.year9RsfValue,
          params.data.year9RsfPercentage
        ),
    },
    {
      headerName: columnNames[9],
      sortable: false,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "currencyFormat"],
      valueGetter: (params: any) =>
        formatExpirationValue(
          params.data.year10PlusRsfValue,
          params.data.year10PlusRsfPercentage
        ),
      valueFormatter: (params) =>
        formatExpirationValue(
          params.data.year10PlusRsfValue,
          params.data.year10PlusRsfPercentage
        ),
    },
  ];
  return columnDefs;
};

const formatExpirationValue = (
  expirationValue?: number,
  expirationPercent?: number
) => {
  if (expirationValue === undefined && expirationPercent === undefined)
    return "-";
  else if (expirationValue === undefined && expirationPercent !== undefined)
    return `(${(expirationPercent * 100).toFixed(1)}%)`;
  else if (expirationValue !== undefined && expirationPercent === undefined)
    return `${expirationValue} (0%)`;
  else
    return `${formatNumber(expirationValue || 0)} (${(
      (expirationPercent || 0) * 100
    ).toFixed(1)}%)`;
};
