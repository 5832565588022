import axios from "axios";
import { Option } from "../model/rentRollListModel";
import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "../../../../shared/api/authorizedAxios";
import { useQuery } from "@tanstack/react-query";
import TLeaseOptionsFilter from "../model/leaseOptionFilter";

export const useGetLeaseOptionList = (filter?: TLeaseOptionsFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getLeaseOptionList", filter],
    () => {
      const source = axios.CancelToken.source();

      const url: string = `/assetService/v1/Lookups/LeaseOptions?leaseGenId=${filter?.LeaseGenId}`;

      let promise = authorizedApiAxios().get<Option[]>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: filter?.accountIdentifier!,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => response.data,
    }
  );
};