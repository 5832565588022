import React, { useEffect, useState } from "react";
import {
  Collapse,
  IconButton,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import { UilAngleLeft } from "@iconscout/react-unicons";
import { UilAngleRight } from "@iconscout/react-unicons";
import { UilApps } from "@iconscout/react-unicons";
import { UilBagAlt } from "@iconscout/react-unicons";
import { UilBuilding } from "@iconscout/react-unicons";
import { UilDocumentInfo } from "@iconscout/react-unicons";

import AssetManagmentMenu from "./menu/assetManagmentMenu";
import FacilityMenu from "./menu/facilityMenu";
import DocumentsMenu from "./menu/documentsMenu";
import AccountsMenu from "./menu/accountsMenu";
import ReportingMenu from "./menu/reportingMenu";
import { useGetAccounts } from "../../properties/property/api/accountService";
import headerStyles from "./headerStyles";
import { computeIndex } from "../util/headerUtil";
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import queryString from "query-string";
import { useIsTokenValid } from "../../createuser/api/createUserApi";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(9)} + 1px)`,
  /*
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  */
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  //...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface Props {
  userId: string;
}

const Header: React.FC<Props> = ({ userId }) => {
  const classes = headerStyles();
  const location = useLocation();

  const [leftMenuAMCollapseOpen, setLeftMenuAMCollapseOpen] = useState(false);
  const [leftMenuFacilityCollapseOpen, setLeftMenuFacilityCollapseOpen] =
    useState(false);
  const [leftMenuDocumentsCollapseOpen, setLeftMenuDocumentsCollapseOpen] =
    useState(false);
  const [leftMenuAccountingCollapseOpen, setLeftMenuAccountingCollapseOpen] =
    useState(false);
  const [leftMenuReportingCollapseOpen, setLeftMenuReportingCollapseOpen] =
    useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(
    computeIndex(location.pathname)
  );

  useEffect(() => {
    const pathname = computeIndex(location.pathname)
    onListItemChange(pathname);
  }, [location.pathname]);
  
  const handleDrawerOpen = () => {
    setDrawerOpen((prevDrawerOpen) => !prevDrawerOpen);
  };

  const onListItemChange = (listItemIndex: number) => {
    if (selectedIndex !== listItemIndex) setSelectedIndex(listItemIndex);
  };

  const parsedQueryParams = queryString.parse(location.search);
  const userEmail = parsedQueryParams.userName?.toString();
  const token = parsedQueryParams.token?.toString();
  const isTokenValidQuery = useIsTokenValid(token || "", userEmail || "");

  return (
    <>
      {(isTokenValidQuery.isError || isTokenValidQuery.data === false) ? (
        <></>
      ) :
      (
        <div className={classes.root}>
          <Drawer variant="permanent" open={drawerOpen}>
            <DrawerHeader>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={classes.handleDrawerOpenIcon}
                sx={{
                  ...(drawerOpen && { display: "none" }),
                }}
                size="large"
              >
                <UilAngleRight />
              </IconButton>
              <IconButton
                onClick={handleDrawerOpen}
                className={classes.handleDrawerCloseIcon}
                sx={{
                  display: "none",
                  ...(drawerOpen && { display: "flex" }),
                }}
                size="large"
              >
                <UilAngleLeft />
              </IconButton>
            </DrawerHeader>
            <div className={classes.toolbar}>
              <div className={classes.appBarNavBrand}>
                {userId !== "" && <CompanyName />}
              </div>
            </div>
            <List
              component="nav"
              aria-labelledby="leftMenuSubheader"
              className={classes.collapseRoot && "main-nav"}
              onChange={(event: React.FormEvent<HTMLElement>) => console.log(event)}
              subheader={<ListSubheader component="div" id="leftMenuSubheader" />}
            >
              {/* Dashboard */}
              <Tooltip title="Dashboard" placement="right">
                <ListItem
                  button
                  key="Dashboard"
                  component={Link}
                  to="/AMDashboard"
                  selected={selectedIndex === 0}
                  className={classes.listItemText}
                  onClick={() => onListItemChange(0)}
                >
                  <ListItemIcon className={classes.listIconSub}>
                    <UilApps />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="subtitle1"
                        sx={{
                          display: "none",
                          ...(drawerOpen && { display: "block" }),
                        }}
                      >
                        Dashboard
                      </Typography>
                    }
                  />
                </ListItem>
              </Tooltip>
              {/* Portfolios */}
              <Tooltip title="Portfolio" placement="right">
                <ListItem
                  button
                  key="Portfolio"
                  component={Link}
                  to="/portfolios"
                  className={classes.listItemText}
                  selected={selectedIndex === 1}
                  onClick={() => onListItemChange(1)}
                >
                  <ListItemIcon className={classes.listIconSub}>
                    <UilBagAlt />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="subtitle1"
                        sx={{
                          display: "none",
                          ...(drawerOpen && { display: "block" }),
                        }}
                      >
                        Portfolios
                      </Typography>
                    }
                  />
                </ListItem>
              </Tooltip>
              {/* Properties */}
              <Tooltip title="Properties" placement="right">
                <ListItem
                  button
                  key="Properties"
                  component={Link}
                  to="/propertylist"
                  className={classes.listItemText}
                  selected={selectedIndex === 2}
                  onClick={() => onListItemChange(2)}
                >
                  <ListItemIcon className={classes.listIconSub}>
                    <UilBuilding />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="subtitle1"
                        sx={{
                          display: "none",
                          ...(drawerOpen && { display: "block" }),
                        }}
                      >
                        Properties
                      </Typography>
                    }
                  />
                </ListItem>
              </Tooltip>
              {/* Reports */}
              <Tooltip title="Reports" placement="right">
                <ListItem
                  button
                  key="Reports"
                  component={Link}
                  to="/reportList/property"
                  className={classes.listItemText}
                  selected={selectedIndex === 3}
                  onClick={() => onListItemChange(3)}
                >
                  <ListItemIcon className={classes.listIconSub}>
                    <MenuBookOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="subtitle1"
                        sx={{
                          display: "none",
                          ...(drawerOpen && { display: "block" }),
                        }}
                      >
                        Reports
                      </Typography>
                    }
                  />
                </ListItem>
              </Tooltip>
              {/* Documents */}
              <Tooltip title="Documents" placement="right">
                <ListItem
                  button
                  key="Documents"
                  component={Link}
                  to="/documentslist"
                  className={classes.listItemText}
                  selected={selectedIndex === 4}
                  onClick={() => onListItemChange(4)}
                >
                  <ListItemIcon className={classes.listIconSub}>
                    <UilDocumentInfo />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="subtitle1"
                        sx={{
                          display: "none",
                          ...(drawerOpen && { display: "block" }),
                        }}
                      >
                        Documents
                      </Typography>
                    }
                  />
                </ListItem>
              </Tooltip>
            </List>
            {/* User Options */}
            {/*<UserOptions />*/}
          </Drawer>
          <Collapse in={leftMenuAMCollapseOpen} timeout="auto" unmountOnExit>
            <AssetManagmentMenu
              setLeftMenuAMCollapseClose={() => setLeftMenuAMCollapseOpen(false)}
            />
          </Collapse>

          <Collapse in={leftMenuFacilityCollapseOpen} timeout="auto" unmountOnExit>
            <FacilityMenu
              setLeftMenuFacilityCollapseClose={() =>
                setLeftMenuFacilityCollapseOpen(false)
              }
            />
          </Collapse>
          <Collapse in={leftMenuDocumentsCollapseOpen} timeout="auto" unmountOnExit>
            <DocumentsMenu
              setLeftMenuDocumentsCollapseClose={() =>
                setLeftMenuDocumentsCollapseOpen(false)
              }
            />
          </Collapse>
          <Collapse
            in={leftMenuAccountingCollapseOpen}
            timeout="auto"
            unmountOnExit
          >
            <AccountsMenu
              setLeftMenuAccountingCollapseClose={() =>
                setLeftMenuAccountingCollapseOpen(false)
              }
            />
          </Collapse>
          <Collapse in={leftMenuReportingCollapseOpen} timeout="auto" unmountOnExit>
            <ReportingMenu
              setLeftMenuReportingCollapseClose={() =>
                setLeftMenuReportingCollapseOpen(false)
              }
            />
          </Collapse>
        </div>
      )}
    </>
  );
};

const CompanyName: React.FC = () => {
  const classes = headerStyles();
  const accounts = useGetAccounts();
  let brandName = ``;
  let brandNameInitial = ``;

  if (
    accounts.isSuccess &&
    accounts.data !== undefined &&
    Array.isArray(accounts.data)
  ) {
    if (accounts.data.length === 1) {
      brandName = accounts.data[0].name;
      brandNameInitial = accounts.data[0].name.slice(0, 1);
    }
  }
  return (
    <div className={classes.appBarNavBrandClientLogoContainer}>
      <Typography variant="h5" className={classes.appBarNavBrandClientLogoIcon}>
        {brandNameInitial}
      </Typography>
      <Typography
        variant="h6"
        className={classes.appBarNavBrandClientLogo}
        noWrap
      >
        {brandName}
      </Typography>
    </div>
  );
};

export default Header;
