import React, { useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";

import { useGetAccounts } from "../../../properties/property/api/accountService";

import { useQueryClient } from "@tanstack/react-query";
import {
  useGetChartOfAccounts,
  useUpdateNegateActualBudget,
} from "../../api/glTreeService";
import NegateActualBudgetGrid from "./NegateActualBudgetGrid";
import { AllUsersChecked } from "../userPropertyAssignment/models/userPropertyAssignmentContext";
import { NegateActualBudgetContext } from "./NegateActualBudgetContext";
import {
  INegateActualBudgetRow,
  IPutNegateActualBudget,
  IPutNegateActualBudgetRow,
} from "../../model/negateActualBudgetModel";
// import { GridApi } from "ag-grid-community";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SimpleAlert, { AlertSeverityType } from "../../../UI/view/SimpleAlert";

const NegateActualBudget: React.FC = () => {
  const { data: accounts, isSuccess } = useGetAccounts();
  const accountId = React.useMemo(() => {
    if (!isSuccess || !accounts || accounts.length === 0) return "";
    return accounts[0].identifier;
  }, [accounts, isSuccess]);
  const [renderedRows, setRenderedRows] = useState<string[]>([]);

  const chartOfAccountsQuery = useGetChartOfAccounts(accountId);
  const updateNegateActualBudget = useUpdateNegateActualBudget(accountId);
  const queryClient = useQueryClient();
  const [mutationStatus, setMutationStatus] = useState<
    "loading" | "success" | "error" | "idle"
  >("idle");

  // const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);

  // const onGridReady = useCallback((gridApi: GridApi | undefined) => {
  //   setGridApi(gridApi);
  // }, []);

  React.useEffect(() => {
    const status = updateNegateActualBudget.status;
    if (status === "idle" || status === mutationStatus) return;

    if (status === "loading") setMutationStatus("loading");
    else
      setTimeout(() => {
        setMutationStatus(status);
      }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateNegateActualBudget.status]);

  const { data: chartOfAccountsData, isLoading: chartOfAccountsDataIsLoading } =
    chartOfAccountsQuery;

  const [editNegateActualBudgetRow, seteditNegateActualBudgetRow] = useState<
    INegateActualBudgetRow[]
  >([]);
  const [allNegateActualChecked, setAllNegateActualChecked] =
    useState<AllUsersChecked>({
      checked: false,
      touched: false,
    });

  const [allNegateBudgetChecked, setAllNegateBudgetChecked] =
    useState<AllUsersChecked>({
      checked: false,
      touched: false,
    });

  const updateAllNegateActualChecked = (checked: boolean) => {
    setAllNegateActualChecked({
      checked: checked,
      touched: true,
    });
  };

  const updateAllNegateBudgetChecked = (checked: boolean) => {
    setAllNegateBudgetChecked({
      checked: checked,
      touched: true,
    });
  };

  const [lastColumnChecked, setLastColumnChecked] = useState<string>(
    "negateActualOnImport"
  );

  const updateLastColumnChecked = (lastColumnChecked: string) => {
    setLastColumnChecked(lastColumnChecked);
  };

  const [processAllActualChecked, setProcessAllActualChecked] =
    useState<boolean>(false);
  const updateProcessAllActualChecked = (processAllActualChecked: boolean) => {
    setProcessAllActualChecked(processAllActualChecked);
  };

  const [processAllBudgetChecked, setProcessAllBudgetChecked] =
    useState<boolean>(false);
  const updateProcessAllBudgetChecked = (processAllBudgetChecked: boolean) => {
    setProcessAllBudgetChecked(processAllBudgetChecked);
  };

  const updateRenderedRows = (newRenderedRows: string[]) => {
    setRenderedRows(newRenderedRows);
  };

  const updateNegateActualBudgetRow = (
    negateActualBudgetRow: INegateActualBudgetRow
  ) => {
    seteditNegateActualBudgetRow((prevRows) => {
      let updatedRows: INegateActualBudgetRow[] = [];

      // creating a new copy
      prevRows.forEach((prevRow) => {
        updatedRows.push({ ...prevRow });
      });
      const prevUserPropIndex = updatedRows.findIndex(
        (prevRow) =>
          prevRow.financialAccountNumberId ===
          negateActualBudgetRow.financialAccountNumberId
      );
      // If the row item already exists, update the values.
      if (prevUserPropIndex > -1) {
        // console.log(
        //   "update actual for index: " +
        //     prevUserPropIndex +
        //     ", value: " +
        //     negateActualBudgetRow.negateActualOnImport
        // );
        // console.log(
        //   "update budget for index: " +
        //     prevUserPropIndex +
        //     ", value: " +
        //     negateActualBudgetRow.negateBudgetOnImport
        // );
        updatedRows[prevUserPropIndex].negateActualOnImport =
          negateActualBudgetRow.negateActualOnImport;
        updatedRows[prevUserPropIndex].negateBudgetOnImport =
          negateActualBudgetRow.negateBudgetOnImport;
      }
      // If the row item doesn't exist. In this case, add it to the existing list.
      else {
        updatedRows.push(negateActualBudgetRow);
      }

      return updatedRows;
    });
  };

  const updateMultipleNegateActualBudget = (
    negateActualBudgetRow: INegateActualBudgetRow[]
  ) => {
    seteditNegateActualBudgetRow(negateActualBudgetRow);
  };

  /**
   * save the data in the AG grid.
   */
  const onChartOfAccountsSave = () => {
    if (chartOfAccountsQuery.data === undefined) return;

    const finalUpdatedNegateActualBudgetRows: INegateActualBudgetRow[] = [];
    editNegateActualBudgetRow.forEach((editedRow) => {
      const untouchedData = chartOfAccountsQuery.data.find(
        (row) =>
          row.financialAccountNumberId === editedRow.financialAccountNumberId
      );

      if (
        untouchedData?.negateActualOnImport !==
          editedRow.negateActualOnImport ||
        untouchedData?.negateBudgetOnImport !== editedRow.negateBudgetOnImport
      )
        finalUpdatedNegateActualBudgetRows.push({ ...editedRow });
    });
    const updatedChartOfAccounts: IPutNegateActualBudgetRow[] = [];
    finalUpdatedNegateActualBudgetRows.forEach(
      (updatedNegateActualBudgetRow) => {
        const filteredRowIndex = renderedRows.findIndex(
          (renderedRow) =>
            renderedRow ===
            updatedNegateActualBudgetRow.financialAccountNumberId
        );
        if (filteredRowIndex > -1)
          updatedChartOfAccounts.push({
            accountingSystemId: updatedNegateActualBudgetRow.accountingSystemId,
            financialAccountNumberId:
              updatedNegateActualBudgetRow.financialAccountNumberId,
            negateActualOnImport:
              updatedNegateActualBudgetRow.negateActualOnImport,
            negateBudgetOnImport:
              updatedNegateActualBudgetRow.negateBudgetOnImport,
          });
      }
    );

    const dataToUpdate: IPutNegateActualBudget = {
      negateActualBudgets: updatedChartOfAccounts,
    };
    updateNegateActualBudget.mutate(dataToUpdate, {
      onSuccess: (successObj) =>
        queryClient.invalidateQueries(["getChartOfAccounts", accountId]),
    });
  };

  const rowsToBeUpdated = () => {
    if (
      editNegateActualBudgetRow.length === 0 ||
      updateNegateActualBudget.isLoading
    ) {
      return true;
    }

    if (chartOfAccountsData === undefined) return true;

    for (const rowData of editNegateActualBudgetRow) {
      const origData = chartOfAccountsData.find(
        (oData) =>
          oData.financialAccountNumberId === rowData.financialAccountNumberId
      );

      if (origData === undefined) continue;

      if (
        origData.negateActualOnImport !== rowData.negateActualOnImport ||
        origData.negateBudgetOnImport !== rowData.negateBudgetOnImport
      ) {
        return false;
      }
    }
    return true;
  };

  const renderAlert = mutationStatus !== "idle";
  let alertMessage = "";
  let alertType: AlertSeverityType = "info";

  if (mutationStatus === "loading") {
    alertMessage = "Updating Negate Actual/Budget.";
    alertType = "info";
  } else if (mutationStatus === "success") {
    alertMessage = "Successfully updated Negate Actual/Budget.";
    alertType = "success";
  } else if (mutationStatus === "error") {
    alertMessage = "Something went wrong. Please reload and try again.";
    alertType = "error";
  }

  return (
    <NegateActualBudgetContext.Provider
      value={{
        negateActualBudgetRow: editNegateActualBudgetRow,
        updatenegateActualBudgetRow: updateNegateActualBudgetRow,
        updateMultiplenegateActualBudgetRow: updateMultipleNegateActualBudget,
        allNegateActualChecked: allNegateActualChecked,
        allNegateBudgetChecked: allNegateBudgetChecked,
        updateAllNegateActualChecked: updateAllNegateActualChecked,
        updateAllNegateBudgetChecked: updateAllNegateBudgetChecked,
        lastColumnChecked: lastColumnChecked,
        updateLastColumnChecked: updateLastColumnChecked,
        processAllActualChecked: processAllActualChecked,
        updateProcessAllActualChecked: updateProcessAllActualChecked,
        processAllBudgetChecked: processAllBudgetChecked,
        updateProcessAllBudgetChecked: updateProcessAllBudgetChecked,
      }}
    >
      {/* Alert to show loading, error and success messages */}
      {renderAlert && (
        <SimpleAlert
          severityType={alertType}
          message={alertMessage}
          onClose={() => {
            setMutationStatus("idle");
          }}
          alertStyles={{ width: "100%" }}
          styles={{ position: "inherit", width: "100%" }}
        />
      )}
      <Grid container className="property-action-bar">
        <Grid item>
          <Grid container sx={{ width: "auto" }}>
            <Grid item></Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container sx={{ width: "auto" }}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveOutlinedIcon />}
                size="small"
                disabled={rowsToBeUpdated()}
                onClick={() => onChartOfAccountsSave()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Box
        display="flex"
        flexDirection="row-reverse"
        sx={{ padding: "24px 20px 16px 20px" }}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={rowsToBeUpdated()}
          onClick={() => onChartOfAccountsSave()}
        >
          Save
        </Button>
      </Box> */}
      {/* Chart of Accounts grid */}
      {chartOfAccountsDataIsLoading && (
        <Box m="auto">
          <CircularProgress />
        </Box>
      )}
      {isSuccess && chartOfAccountsData !== undefined && (
        <NegateActualBudgetGrid
          rows={chartOfAccountsData}
          updateRenderedRows={updateRenderedRows}
        />
      )}
    </NegateActualBudgetContext.Provider>
  );
};

export default NegateActualBudget;
