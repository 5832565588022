import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import { UilAngleLeft } from "@iconscout/react-unicons";
import { UilTimes } from "@iconscout/react-unicons";
import { UilExport } from "@iconscout/react-unicons";

import { computeDateWithinRange } from "../../../portfolio/rentRoll/util/portfolioRentRollUtil";
import { usePropertyInfo } from "../../util/propertyInfoContext";
import PropertyViewRentRollList from "./propertyViewRentRollList";
import PropertyViewRentRollListByTenant from "./propertyViewRentRollListByTenant";
import PropertyViewRentRollSummary from "./propertyViewRentRollSummary";
import PropertyViewRentRollSearch from "./propertyViewRentRollSearch";
import { TMonthYearFilter } from "../model/rentRollListFilter";
import { TRentRollAssetSummary } from "../model/rentRollAssetSummary";
import {
  useGetRentRollList,
  useGetRentRollOtherList,
} from "../api/rentrollService";
import PropertyViewRentRollOtherList from "./propertyViewRentRollOtherList";
import { formatRentRolls } from "../util/rentRollExcel";
import {
  ChargeScheduleExcel,
  GenerationsExcel,
  RentRollExcel,
} from "../model/rentRollExcel";
import RentRollContext from "../context/RentRollContext";
import { GridApi, ColumnApi } from "ag-grid-community";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import {
  GridConfigIds,
  PageConfigIds,
} from "../../../../constants/GridPageConfigurationIds";
import { useGetGridConfig } from "../../../../shared/api/pageConfigService";
import SaveGridStateOptions, {
  GRID_CONFIG_DEFAULT_VIEW,
} from "../../../../shared/view/SaveGridStateOptions";
import {
  GridConfig,
  GridConfiguration,
} from "../../../../shared/model/gridConfig";
import SimpleAlert from "../../../UI/view/SimpleAlert";
import fileSaver from "file-saver";
import { useExportReport } from "../../../reporting/api/propertyService";
import { LoadingButton } from "@mui/lab";

const drawerSummaryWidth = 420;
const drawerSummaryWidthMobile = 300;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export enum LeaseView {
  LEASE_VIEW = "LEASE VIEW",
  SUITE_VIEW = "SUITE VIEW",
  OTHER_VIEW = "OTHER LEASES",
}

const PropertyViewRentRoll: React.FC = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerSummaryOpen = () => {
    setOpen(true);
  };

  const handleDrawerSummaryClose = () => {
    setOpen(false);
  };

  const propertyInfo = usePropertyInfo();
  const location = useLocation();
  const [expandTenant, setExpandTenant] = useState("");
  const [orderByColumn, setOrderByColumn] = useState<string>();

  const {
    alertDetails,
    rentRollFilter,
    updateRentRollFilter,
    updateAlertDetails,
  } = useContext(RentRollContext);

  const updatedFromQueryParams = React.useRef(false);

  useEffect(() => {
    const parsedQueryParams = queryString.parse(location.search);

    const month = parsedQueryParams.month;
    const year = parsedQueryParams.year;
    const tenant = parsedQueryParams.tenant;
    const orderBy = parsedQueryParams.orderBy;

    if (month === undefined || year === undefined)
      return;

    if (updatedFromQueryParams.current === false) {
      updateRentRollFilter({
        ...rentRollFilter,
        monthYearFilter: {
          filterMonth: Number(month),
          filterYear: Number(year),
        },
      });
      setExpandTenant(tenant?.toString() || "");
      setOrderByColumn(orderBy?.toString() || "");

      updatedFromQueryParams.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [assetSummary, setAssetSummary] = useState<TRentRollAssetSummary>();
  const [assetSummaryFiltered, setAssetSummaryFiltered] =
    useState<TRentRollAssetSummary>();
  const [assetSummaryOther, setAssetSummaryOther] =
    useState<TRentRollAssetSummary>();
  const [leaseVieww, setLeaseView] = useState(LeaseView.LEASE_VIEW); //Default to Lease View
  const [rentRollsExcel, setRentRollsExcel] = useState<{
    rentRollExcel: RentRollExcel[];
    generationsExcel: GenerationsExcel[];
    chargeScheduleExcel: ChargeScheduleExcel[];
  }>({
    rentRollExcel: [],
    generationsExcel: [],
    chargeScheduleExcel: [],
  });

  const rrList = useGetRentRollList(rentRollFilter);
  const rrOtherList = useGetRentRollOtherList(rentRollFilter);

  const setRentRollListSearchFilter = useCallback(
    (value?: TMonthYearFilter) => {
      updateRentRollFilter({
        propertyId: rentRollFilter.propertyId,
        monthYearFilter: { ...value },
        accountIdentifier: rentRollFilter.accountIdentifier,
      });

      if (
        typeof value?.filterMonth === "undefined" ||
        typeof value?.filterYear === "undefined" ||
        value?.filterMonth.toString() === "" ||
        value?.filterYear.toString() === ""
      ) {
        setAssetSummary({
          rentableAreaDisplay: "",
          numberofSuitesDisplay: "",
          numberOfTenantsDisplay: "",
          occupancyDisplay: "",
          leasedPercentDisplay: "",
          monthlyRentPerSFDisplay: "",
          numberofSuitesPercent: "",
          rentableAreaPercent: "",
          numberOfTenantsPercent: "",
          monthlyRentPerSFPercent: "",
          totalNumTenantsOtherCount: "",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rentRollFilter]
  );

  const updateAssetSummary = useCallback(
    (assetSummary: TRentRollAssetSummary) => {
      setAssetSummary(assetSummary);
    },
    []
  );

  const updateAssetSummaryFiltered = useCallback(
    (assetSummary: TRentRollAssetSummary) => {
      setAssetSummaryFiltered(assetSummary);
    },
    []
  );

  const updateAssetSummaryOther = useCallback(
    (assetSummaryOther: TRentRollAssetSummary) => {
      setAssetSummaryOther(assetSummaryOther);
    },
    []
  );

  const getCurrentDate = () => {
    const year = rentRollFilter.monthYearFilter?.filterYear;
    const month = rentRollFilter.monthYearFilter?.filterMonth;
    if (year === undefined || month === undefined) return new Date();
    else return new Date(year, month - 1);
  };

  useEffect(() => {
    const boolOut =
      rentRollsExcel.rentRollExcel.length !== 0 ||
      rentRollsExcel.generationsExcel.length !== 0 ||
      rentRollsExcel.chargeScheduleExcel.length !== 0;

    if (boolOut) return;
    if (rrList.data?.rentrolls?.groupedresult === undefined) return;

    const {
      rentRollExcel,
      generations: generationsExcel,
      chargeSchedules: chargeSchedulesExcel,
    } = formatRentRolls(rrList.data?.rentrolls.groupedresult.slice());
    setRentRollsExcel({
      rentRollExcel: rentRollExcel,
      generationsExcel: generationsExcel,
      chargeScheduleExcel: chargeSchedulesExcel,
    });
  }, [rrList.data?.rentrolls?.groupedresult, rentRollsExcel]);

  const currentDate = getCurrentDate();

  /**
   * Re assigning date value to a valid date that lies between minDate and maxDate if it is not in that range already.
   */
  useEffect(() => {
    if (rrList.isSuccess && rrList.data !== undefined) {
      const newDate = computeDateWithinRange(
        currentDate,
        new Date(rrList.data.rentrolls?.minDate),
        new Date(rrList.data.rentrolls?.maxDate)
      );

      if (newDate !== undefined)
        setRentRollListSearchFilter({
          filterMonth: newDate.getMonth() + 1,
          filterYear: newDate.getFullYear(),
        });
    } else if (rrOtherList.isSuccess && rrOtherList.data !== undefined) {
      const newDate = computeDateWithinRange(
        currentDate,
        new Date(rrOtherList.data.rentrolls?.minDate),
        new Date(rrOtherList.data.rentrolls?.maxDate)
      );

      if (newDate !== undefined)
        setRentRollListSearchFilter({
          filterMonth: newDate.getMonth() + 1,
          filterYear: newDate.getFullYear(),
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rrList.isSuccess, rrList.data, rrOtherList.isSuccess, rrOtherList.data]);

  const [leaseGridApi, setLeaseGridApi] = React.useState<GridApi | undefined>(
    undefined
  );
  const [leaseGridColApi, setLeaseGridColApi] = React.useState<
    ColumnApi | undefined
  >(undefined);

  const [suiteGridApi, setSuiteGridApi] = React.useState<GridApi | undefined>(
    undefined
  );
  const [suiteGridColApi, setSuiteGridColApi] = React.useState<
    ColumnApi | undefined
  >(undefined);

  const [otherLeasesGridApi, setOtherLeasesGridApi] = React.useState<
    GridApi | undefined
  >(undefined);
  const [otherLeasesGridColApi, setOtherLeasesGridColApi] = React.useState<
    ColumnApi | undefined
  >(undefined);

  /**
   * exports the data that is rendered in the AG grid.
   */
  const onOtherLeasesExport = () => {
    const columnKeys = [
      "leaseTitle",
      "tenantTitle",
      "leaseFrom",
      "leaseTo",
      "leaseTerm",
      "moveInDate",
      "moveOutDate",
      "securityDeposit",
      "monthlyRent",
    ];

    const params = {
      columnWidth: 100,
      sheetName: "Other Leases data",
      fileName: "Rent Roll - Other Leases",
      exportMode: undefined,
      suppressTextAsCDATA: false,
      rowHeight: undefined,
      headerRowHeight: undefined,
      columnKeys: columnKeys,
    };
    gridApi?.exportDataAsExcel(params);
  };

  // Grid Config state and methods

  const [gridView, setGridView] = React.useState(GRID_CONFIG_DEFAULT_VIEW);

  const pageId = PageConfigIds.PROPERTIES_RENT_ROLL_GRIDS;
  const gridRrLeasesId = GridConfigIds.PROPERTY_RR_LEASES;
  const gridRrSuitesId = GridConfigIds.PROPERTY_RR_SUITES;
  const gridRrOtherLeasesId = GridConfigIds.PROPERTY_RR_OTHER_LEASES;

  const accountId = propertyInfo.accountIdentifier;

  const queryClient = useQueryClient();

  const leasesGridConfigsQuery = useGetGridConfig({
    pageId: pageId,
    gridIdentifier: gridRrLeasesId,
    accountId: accountId,
  });
  const suitesGridConfigsQuery = useGetGridConfig({
    pageId: pageId,
    gridIdentifier: gridRrSuitesId,
    accountId: accountId,
  });
  const otherLeasesGridConfigsQuery = useGetGridConfig({
    pageId: pageId,
    gridIdentifier: gridRrOtherLeasesId,
    accountId: accountId,
  });

  let gridConfigsQuery: UseQueryResult<GridConfig, unknown>;
  let gridApi: GridApi | undefined;
  let gridColumnApi: ColumnApi | undefined;
  let gridId: GridConfigIds;

  switch (leaseVieww) {
    case LeaseView.LEASE_VIEW:
      gridConfigsQuery = leasesGridConfigsQuery;
      gridApi = leaseGridApi;
      gridColumnApi = leaseGridColApi;
      gridId = gridRrLeasesId;
      break;
    case LeaseView.SUITE_VIEW:
      gridConfigsQuery = suitesGridConfigsQuery;
      gridApi = suiteGridApi;
      gridColumnApi = suiteGridColApi;
      gridId = gridRrSuitesId;
      break;
    case LeaseView.OTHER_VIEW:
      gridConfigsQuery = otherLeasesGridConfigsQuery;
      gridApi = otherLeasesGridApi;
      gridColumnApi = otherLeasesGridColApi;
      gridId = gridRrOtherLeasesId;
      break;
    default:
      gridConfigsQuery = leasesGridConfigsQuery;
      gridApi = leaseGridApi;
      gridColumnApi = leaseGridColApi;
      gridId = gridRrLeasesId;
      break;
  }

  /**
   * Sets the context that is retrieved from the backend whenever the gridView is changed.
   * gridView is either changed when data first loads (only if a default value exists) or when the user uses the dropdown
   */
  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1 ||
      gridApi === undefined ||
      gridColumnApi === undefined
    )
      return;

    if (gridView === GRID_CONFIG_DEFAULT_VIEW) {
      gridApi.setFilterModel({});
      gridColumnApi.resetColumnState();
      return;
    }

    const selectedGridConfig = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.id === Number(gridView)
    );

    if (selectedGridConfig === undefined) return;

    gridApi.setFilterModel(JSON.parse(selectedGridConfig.columnFilterState));
    gridColumnApi.applyColumnState({
      state: JSON.parse(selectedGridConfig.columnState),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gridView,
    leaseGridApi,
    leaseGridColApi,
    suiteGridApi,
    suiteGridColApi,
    otherLeasesGridApi,
    otherLeasesGridColApi,
  ]);

  useEffect(() => {
    if (leaseVieww !== LeaseView.LEASE_VIEW) return;
    if (
      !leasesGridConfigsQuery.isSuccess ||
      !leasesGridConfigsQuery.data ||
      leasesGridConfigsQuery.data.gridConfigurations.length < 1
    ) {
      if (gridView !== GRID_CONFIG_DEFAULT_VIEW)
        setGridView(GRID_CONFIG_DEFAULT_VIEW);
      return;
    }

    const isSaveAsRender =
      saveAsView.current.name.trim().length > 0 &&
      saveAsView.current.type === LeaseView.LEASE_VIEW;

    if (isSaveAsRender) {
      const savedAsView = leasesGridConfigsQuery.data.gridConfigurations.find(
        (gridConfig) =>
          gridConfig.name.toLowerCase().trim() ===
          saveAsView.current.name.toLowerCase().trim()
      );
      if (savedAsView) setGridView(savedAsView.id.toString());
      saveAsView.current = { name: "", type: LeaseView.LEASE_VIEW };
      return;
    }

    const defaultView = leasesGridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.isDefault
    );
    if (
      defaultView &&
      gridView !== defaultView.id.toString() &&
      gridView === GRID_CONFIG_DEFAULT_VIEW
    )
      setGridView(defaultView.id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leasesGridConfigsQuery.isSuccess, leasesGridConfigsQuery.data]);

  // The following three useEffects to set the default view on page load.
  useEffect(() => {
    if (
      !suitesGridConfigsQuery.isSuccess ||
      !suitesGridConfigsQuery.data ||
      suitesGridConfigsQuery.data.gridConfigurations.length < 1 ||
      leaseVieww !== LeaseView.SUITE_VIEW
    ) {
      if (gridView !== GRID_CONFIG_DEFAULT_VIEW)
        setGridView(GRID_CONFIG_DEFAULT_VIEW);
      return;
    }

    const isSaveAsRender =
      saveAsView.current.name.trim().length > 0 &&
      saveAsView.current.type === LeaseView.SUITE_VIEW;

    if (isSaveAsRender) {
      const savedAsView = suitesGridConfigsQuery.data.gridConfigurations.find(
        (gridConfig) =>
          gridConfig.name.toLowerCase().trim() ===
          saveAsView.current.name.toLowerCase().trim()
      );
      if (savedAsView) setGridView(savedAsView.id.toString());
      saveAsView.current = { name: "", type: LeaseView.SUITE_VIEW };
      return;
    }

    const defaultView = suitesGridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.isDefault
    );
    if (
      defaultView &&
      gridView !== defaultView.id.toString() &&
      gridView === GRID_CONFIG_DEFAULT_VIEW
    )
      setGridView(defaultView.id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suitesGridConfigsQuery.isSuccess, suitesGridConfigsQuery.data]);

  useEffect(() => {
    if (
      !otherLeasesGridConfigsQuery.isSuccess ||
      !otherLeasesGridConfigsQuery.data ||
      otherLeasesGridConfigsQuery.data.gridConfigurations.length < 1 ||
      leaseVieww !== LeaseView.OTHER_VIEW
    )
      return;

    const isSaveAsRender =
      saveAsView.current.name.trim().length > 0 &&
      saveAsView.current.type === LeaseView.OTHER_VIEW;

    if (isSaveAsRender) {
      const savedAsView =
        otherLeasesGridConfigsQuery.data.gridConfigurations.find(
          (gridConfig) =>
            gridConfig.name.toLowerCase().trim() ===
            saveAsView.current.name.toLowerCase().trim()
        );
      if (savedAsView) setGridView(savedAsView.id.toString());
      saveAsView.current = { name: "", type: LeaseView.OTHER_VIEW };
      return;
    }

    const defaultView =
      otherLeasesGridConfigsQuery.data.gridConfigurations.find(
        (gridConfig) => gridConfig.isDefault
      );
    if (
      defaultView &&
      gridView !== defaultView.id.toString() &&
      gridView === GRID_CONFIG_DEFAULT_VIEW
    )
      setGridView(defaultView.id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherLeasesGridConfigsQuery.isSuccess, otherLeasesGridConfigsQuery.data]);

  useEffect(() => {
    if (
      !leasesGridConfigsQuery.isSuccess ||
      !leasesGridConfigsQuery.data ||
      leasesGridConfigsQuery.data.gridConfigurations.length < 1
    )
      return;

    const defaultView = leasesGridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.isDefault
    );
    if (
      defaultView &&
      gridView !== defaultView.id.toString() &&
      gridView === GRID_CONFIG_DEFAULT_VIEW
    )
      setGridView(defaultView.id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leasesGridConfigsQuery.isSuccess, leasesGridConfigsQuery.data]);

  const saveAsView = React.useRef({ name: "", type: LeaseView.LEASE_VIEW });

  const changeGridView = (newView: LeaseView) => {
    if (newView === leaseVieww) return;

    setLeaseView(newView);

    let defaultView: GridConfiguration | undefined;
    if (newView === LeaseView.LEASE_VIEW) {
      defaultView = leasesGridConfigsQuery.data?.gridConfigurations.find(
        (gridConfig) => gridConfig.isDefault
      );
      setOtherLeasesGridApi(undefined);
      setOtherLeasesGridColApi(undefined);
      setSuiteGridApi(undefined);
      setSuiteGridColApi(undefined);
    } else if (newView === LeaseView.OTHER_VIEW) {
      defaultView = otherLeasesGridConfigsQuery.data?.gridConfigurations.find(
        (gridConfig) => gridConfig.isDefault
      );
      setLeaseGridApi(undefined);
      setLeaseGridColApi(undefined);
      setSuiteGridApi(undefined);
      setSuiteGridColApi(undefined);
    } else {
      defaultView = suitesGridConfigsQuery.data?.gridConfigurations.find(
        (gridConfig) => gridConfig.isDefault
      );
      setLeaseGridApi(undefined);
      setLeaseGridColApi(undefined);
      setOtherLeasesGridApi(undefined);
      setOtherLeasesGridColApi(undefined);
    }
    if (!defaultView || gridView === defaultView?.id.toString()) return;

    setGridView(defaultView.id.toString());
  };

  const leaseOnGridReady = useCallback(
    (gridApi: GridApi | undefined, gridColumnApi: ColumnApi | undefined) => {
      setLeaseGridApi(gridApi);
      setLeaseGridColApi(gridColumnApi);
    },
    []
  );

  const suiteOnGridReady = useCallback(
    (gridApi: GridApi | undefined, gridColumnApi: ColumnApi | undefined) => {
      setSuiteGridApi(gridApi);
      setSuiteGridColApi(gridColumnApi);
    },
    []
  );

  const otherLeasesOnGridReady = useCallback(
    (gridApi: GridApi | undefined, gridColumnApi: ColumnApi | undefined) => {
      setOtherLeasesGridApi(gridApi);
      setOtherLeasesGridColApi(gridColumnApi);
    },
    []
  );

  const SelectIcon = (props: any) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  const exportReportResponse = useExportReport();

  const onExportLeaseView = async () => {
    const fileName = "Rent Roll - Lease View.xlsx";
    const result = await exportReportResponse.mutateAsync({
      accountIdentifier: propertyInfo.accountIdentifier,
      properties: [propertyInfo.id],
      includeRentRollLeaseReport: true,
      rentRollLeaseReportYear: rentRollFilter.monthYearFilter?.filterYear,
      rentRollLeaseReportMonth: rentRollFilter.monthYearFilter?.filterMonth,
    });

    fileSaver.saveAs(result.data, fileName);
  };

  const onExportSuiteView = async () => {
    const fileName = "Rent Roll - Suite View.xlsx";
    const result = await exportReportResponse.mutateAsync({
      accountIdentifier: propertyInfo.accountIdentifier,
      properties: [propertyInfo.id],
      includeRentRollSuiteReport: true,
      rentRollSuiteReportYear: rentRollFilter.monthYearFilter?.filterYear,
      rentRollSuiteReportMonth: rentRollFilter.monthYearFilter?.filterMonth,
    });

    fileSaver.saveAs(result.data, fileName);
  };

  return (
    <Fragment>
      <div
        id="property-rentroll"
        className="tab-pane fade show active"
        role="tabpanel"
        aria-labelledby="property-tab-rentroll"
      >
        {alertDetails.message !== "" && (
          <SimpleAlert
            severityType={alertDetails.severityType}
            message={alertDetails.message}
            onClose={() =>
              updateAlertDetails({ severityType: "success", message: "" })
            }
            alertStyles={{ width: "100%" }}
            styles={{ position: "inherit", width: "100%" }}
          />
        )}
        <Grid container className="property-action-bar">
          <Grid item xs={6}>
            <Grid container sx={{ width: "auto" }}>
              {rrList.isSuccess && rrList.data !== undefined && (
                <Grid item>
                  <PropertyViewRentRollSearch
                    currentDate={currentDate}
                    maxDate={
                      rrList.data.rentrolls?.maxDate === undefined ||
                      Date.parse(rrList.data.rentrolls?.maxDate.toString()) >
                        Date.parse(new Date().toString())
                        ? new Date()
                        : rrList.data.rentrolls?.maxDate
                    }
                    minDate={rrList.data.rentrolls?.minDate}
                    setFilter={setRentRollListSearchFilter}
                    value={currentDate.toString()}
                  />
                </Grid>
              )}
              {/* Perspective dropdown */}
              <Grid item>
                <FormControl variant="outlined" size="small">
                  <Typography
                    variant="body3"
                    component="label"
                    className={"input-label"}
                    sx={{ marginTop: "0 !important" }}
                  >
                    Perspective
                  </Typography>
                  <Select
                    labelId="property-rr-perspective"
                    displayEmpty
                    input={<OutlinedInput />}
                    className={"input-field select-dropdown"}
                    value={leaseVieww}
                    onChange={(event: SelectChangeEvent) => {
                      changeGridView(event.target.value as LeaseView);
                    }}
                    MenuProps={{
                      variant: "menu",
                    }}
                    IconComponent={SelectIcon}
                  >
                    <MenuItem value={LeaseView.SUITE_VIEW}>Suite View</MenuItem>
                    <MenuItem value={LeaseView.LEASE_VIEW}>Lease View</MenuItem>
                    <MenuItem value={LeaseView.OTHER_VIEW}>
                      Other Leases
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container sx={{ width: "auto" }}>
              <Grid item>
                <SaveGridStateOptions
                  isSuccess={gridConfigsQuery.isSuccess}
                  gridView={gridView}
                  handleGridView={(newVal: string) => {
                    setGridView(newVal);
                  }}
                  gridConfigData={gridConfigsQuery.data}
                  gridApi={gridApi}
                  gridColumnApi={gridColumnApi}
                  gridId={gridId}
                  pageId={pageId}
                  accountId={accountId}
                  invalidateGridQuery={() =>
                    queryClient.invalidateQueries([
                      "getGridConfig",
                      pageId + gridId + accountId,
                    ])
                  }
                  updateSaveAsViewName={(name: string) => {
                    saveAsView.current = { name: name, type: leaseVieww };
                  }}
                  updateGridView={() => setGridView(GRID_CONFIG_DEFAULT_VIEW)}
                />
              </Grid>
              <Grid item>
                <LoadingButton
                  variant="contained"
                  size="small"
                  onClick={async() => {
                    if (leaseVieww === LeaseView.SUITE_VIEW) await onExportSuiteView();
                    else if(leaseVieww === LeaseView.LEASE_VIEW) await onExportLeaseView();
                    else onOtherLeasesExport();
                  }}
                  className={"btn-primary"}
                  sx={{ marginTop: "21px" }}
                  loading={exportReportResponse.isLoading}
                >
                  <UilExport /> Export
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div id="property-rentroll-content" className="tab-content">
          <div className="standard-content">
            <div
              className="table-standard-toggle-container"
              style={{ width: "100%" }}
            >
              {leaseVieww === LeaseView.LEASE_VIEW ? (
                <PropertyViewRentRollListByTenant
                  expandTenant={expandTenant}
                  rRollList={rrList}
                  monthYearFilter={rentRollFilter.monthYearFilter}
                  setAssetSummary={updateAssetSummary}
                  setAssetSummaryFiltered={updateAssetSummaryFiltered}
                  gridApi={leaseGridApi}
                  gridColumnApi={leaseGridColApi}
                  onGridRender={leaseOnGridReady}
                  orderByColumn={orderByColumn}
                />
              ) : leaseVieww === LeaseView.SUITE_VIEW ? (
                <PropertyViewRentRollList
                  rRollList={rrList}
                  monthYearFilter={rentRollFilter.monthYearFilter}
                  setAssetSummary={updateAssetSummary}
                  setAssetSummaryFiltered={updateAssetSummaryFiltered}
                  gridApi={suiteGridApi}
                  gridColumnApi={suiteGridColApi}
                  onGridRender={suiteOnGridReady}
                />
              ) : (
                <PropertyViewRentRollOtherList
                  rRollList={rrOtherList}
                  monthYearFilter={rentRollFilter.monthYearFilter}
                  setAssetSummaryOther={updateAssetSummaryOther}
                  setAssetSummaryFiltered={updateAssetSummaryFiltered}
                  gridApi={otherLeasesGridApi}
                  gridColumnApi={otherLeasesGridColApi}
                  onGridRender={otherLeasesOnGridReady}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <DrawerHeader
        sx={{
          position: "fixed",
          right: "10px",
          bottom: "50px",
          zIndex: 1201,
        }}
        className={"summary-drawer-action"}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerSummaryOpen}
          edge="start"
          sx={{
            ...(open && { display: "none" }),
          }}
          size="large"
        >
          <UilAngleLeft />
          <Typography>Summary</Typography>
        </IconButton>
        <IconButton
          onClick={handleDrawerSummaryClose}
          sx={{
            display: "none",
            ...(open && { display: "flex" }),
          }}
          size="large"
        >
          <UilTimes />
          <Typography>Close</Typography>
        </IconButton>
      </DrawerHeader>
      <Drawer
        sx={{
          position: "fixed",
          width: drawerSummaryWidthMobile,
          [theme.breakpoints.up("sm")]: {
            width: drawerSummaryWidth,
          },
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerSummaryWidthMobile,
            [theme.breakpoints.up("sm")]: {
              width: drawerSummaryWidth,
            },
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <div
          id="summaryContent"
          className="summary-content table-responsive collapse show"
        >
          <PropertyViewRentRollSummary
            leaseView={leaseVieww}
            assetSummary={assetSummary}
            assetFiltered={assetSummaryFiltered}
            assetSummaryOther={assetSummaryOther}
          />
        </div>
      </Drawer>
    </Fragment>
  );
};
export default PropertyViewRentRoll;
