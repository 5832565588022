import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import {
  AccountIdentifierHeaderName,
  useAuthorizedApiAxios,
} from "../../../../shared/api/authorizedAxios";
import { IGetPortfolio } from "../model/createPortfolioModel";
import {
  IGetPortfolioProperty,
  IPortfolioProperty,
} from "../model/portfolioProperty";

export const useGetPortfolioPropertiesList = (accountId: string) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getPortfolioProperties", accountId],
    () => {
      const source = axios.CancelToken.source();
      const url = `/assetService/v1/lookups/properties`;

      let promise = authorizedApiAxios().get<IPortfolioProperty[]>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: accountId,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      enabled: accountId.trim().length !== 0,
      select: (response) => response!.data,
      staleTime: Infinity,
    }
  );
};

export const useGetPortfolioProperty = (queryParams: IGetPortfolioProperty) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  const uniqueId = queryParams.accountId + queryParams.portfolioId;

  return useQuery(
    ["getPortfolioProperty", uniqueId],
    () => {
      const source = axios.CancelToken.source();
      const url = `/assetService/v1/portfolios/${queryParams.portfolioId}`;

      let promise = authorizedApiAxios().get<IGetPortfolio>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: queryParams.accountId,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      enabled:
        queryParams.accountId.trim().length !== 0 &&
        queryParams.portfolioId.trim().length !== 0 &&
        queryParams.allowQuery,
      select: (response) => response!.data,
      staleTime: Infinity,
    }
  );
};
