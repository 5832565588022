export const computeIndex = (path: string) => {
  const pathName = path.toLowerCase();
  let index: number;

  if (pathName.includes("documents")) index = 4;
  else if (pathName.includes("reportlist")) index = 3;
  else if (pathName.includes("property")) index = 2;
  else if (pathName.includes("portfolio")) index = 1;
  else if (pathName.includes("amdashboard")) index = 0;
  else index = -1;

  return index;
};
