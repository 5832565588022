import React, { useEffect } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import {
  ColumnApi,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  RowDataChangedEvent,
  StatusPanelDef,
} from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { getStatusPanels } from "../../../properties/leasing activity/util/gridTable";
import { IPortfolioRentRollOther } from "../model/getPortfolioRR";
import { portfolioRROtherColumns } from "../util/portfolioRROtherUtil";

interface PortfolioRROtherGridProps {
  rowData: IPortfolioRentRollOther[];
  gridApi?: GridApi;
  gridColumnApi?: ColumnApi;
  onGridRender: (gridApi?: GridApi, gridColumnApi?: ColumnApi) => void;
  updateAssetSummary: (summary: number[]) => void;
  updateFilteredAssetSummary: (summary: number[]) => void;
}

const PortfolioRROtherGrid: React.FC<PortfolioRROtherGridProps> = (props) => {
  const { rowData, onGridRender } = props;

  const columnDefs = portfolioRROtherColumns;

  const statusBar: { statusPanels: StatusPanelDef[] } = getStatusPanels();

  useEffect(() => {
    return () => {
      onGridRender(undefined, undefined);
    };
  }, [onGridRender]);

  const onGridReady = (event: GridReadyEvent) => {
    onGridRender(event.api, event.columnApi);

    if (rowData === undefined || rowData.length === 0) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }
  };

  /**
   * Computes Asset Summary or Filtered Asset Summary
   * @param isFiltered Determines whether we are trying to compute filtered asset summary or overall asset summary
   * @param event event object (could be FirstDataRenderedEvent or FilterChangedEvent, etc.)
   */
  const calculateTotals = (
    isFiltered: boolean,
    event: FirstDataRenderedEvent | FilterChangedEvent | RowDataChangedEvent
  ) => {
    let result: number[];
    let tenantList: string[] = [];

    // Filtered Asset Summary values
    if (isFiltered) {
      event.api.forEachNodeAfterFilter((node) => {
        if (node.data !== undefined) {
          if (
            node.data.tenantId !== undefined &&
            !tenantList.includes(node.data.tenantId)
          )
            tenantList.push(node.data.tenantId);
        }
      });
    }
    //Asset Summary values
    else {
      rowData.forEach((ele) => {
        if (ele !== undefined) {
          if (ele.tenantId !== undefined && !tenantList.includes(ele.tenantId))
            tenantList.push(ele.tenantId);
        }
      });
    }

    result = [-1, tenantList.length, -1, -1];
    return result;
  };

  /**
   * To resize all columns to fit to screen
   * @param event
   */
  const autoSizeAll = (event: FirstDataRenderedEvent) => {
    setTimeout(() => event.columnApi?.autoSizeAllColumns(), 500);// TODO remove setTimeout when Ag-grid fixes autoSizeColumns on React 18 
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    autoSizeAll(event);
  };

  const onRowDataChanged = (event: RowDataChangedEvent) => {
    props.updateAssetSummary(calculateTotals(false, event));
    props.updateFilteredAssetSummary(calculateTotals(true, event));
  };

  const onFilterChanged = (event: FilterChangedEvent) => {
    props.updateFilteredAssetSummary(calculateTotals(true, event));
  };

  return (
    <div
      id="myGrid"
      className="ag-theme-alpine ag-theme-alpine-container-override"
      style={{ height: "100%" }}
    >
      <AgGridReact
        excelStyles={[
          {
            id: "alignRight",
            alignment: {
              horizontal: "Right",
            },
          },
          {
            id: "currencyFormat",
            numberFormat: { format: "\u0024 #,##0.00" },
          },
          {
            id: "numberFormat",
            numberFormat: { format: "#,##0.00" },
          },
        ]}
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        rowData={rowData}
        onRowDataChanged={onRowDataChanged}
        onFilterChanged={onFilterChanged}
        onFirstDataRendered={onFirstDataRendered}
        statusBar={statusBar}
        enableCharts={true}
        enableRangeSelection={true}
        sortingOrder={["desc", "asc"]}
        defaultColDef={{
          minWidth: 50,
          resizable: true,
          sortable: true,
          filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
        }}
      />
    </div>
  );
};

export default PortfolioRROtherGrid;
