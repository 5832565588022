export enum DocumentTagType {
  BuildingId = 1,
  SpaceId = 2,
  LeaseGenId = 3,
  TenantId = 4,
}

export enum DocumentType {
  Analysis = "Analysis",
  FinancialBudget = "FinancialBudget",
  FinancialForecast = "FinancialForecast",
  FinancialOther = "FinancialOther",
  LeaseOriginal = "LeaseOriginal",
  LeaseRenewal = "LeaseRenewal",
  LeaseTerminationNotice = "LeaseTerminationNotice",
  LeaseAmendmentAddendum = "LeaseAmendmentAddendum",
  LeaseOther = "LeaseOther",
  LoansAgreement = "LoansAgreement",
  LoansOther = "LoansOther",
  ReportOther = "ReportOther",
  Miscellaneous = "Miscellaneous",
}
