import React, {
  Fragment,
  forwardRef,
  useEffect,
  useState,
  useContext,
} from "react";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { useGetStackingPlan } from "../api/stackingPlanService";
import {
  Accordion,
  AccordionSummary,
  Button,
  CircularProgress,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Tooltip,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatCurrency, formatNumber } from "../../util/formatNumber";
import { format } from "date-fns";
import { usePropertyInfo } from "../../util/propertyInfoContext";
import DatePicker from "react-datepicker";
import PropertyViewStackingPlanLeaseSummary from "./propertyViewStackingPlanLeaseSummary";
import {
  StackingBuilding,
  StackingFloor,
  StackingSpace,
} from "../model/stackingPlan";
import Box from "@mui/material/Box";
import { getBackgroundColor } from "../util/getBackgroundColor";
import { getBorderColor } from "../util/getBorderColor";
import { getHoverBorderColor } from "../util/getHoverBorderColor";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Backdrop from "@mui/material/Backdrop";
import { formatStackingPercentage } from "../util/format";
import { UilAngleDown } from "@iconscout/react-unicons";
import { UilAngleUp } from "@iconscout/react-unicons";
import { UilCalender } from "@iconscout/react-unicons";
import { UilBuilding } from "@iconscout/react-unicons";
import MaterialLink from "@mui/material/Link";
import { LoadingButton } from "@mui/lab";
import fileSaver from "file-saver";
import { useIsMutating } from "@tanstack/react-query";
import { useExportReport } from "../../../reporting/api/propertyService";
import { UilExport } from "@iconscout/react-unicons";
import RentRollContext from "../../rent roll/context/RentRollContext";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    flexBasis: "13%",
    flexShrink: 0,
    fontWeight: 400,
  },
  secondaryHeading: {
    fontWeight: 400,
  },
  stackingPlanContainer: {
    padding: 0,
  },
  popupTable: {
    "& tbody td": {
      borderBottom: 0,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const AccordionDetails = withStyles({
  root: {
    display: "block",
  },
})(MuiAccordionDetails);

interface Props {
  value?: string;
  onClick?: () => void;
}

const PropertyViewStackingPlan: React.FunctionComponent<Props> = (
  props: Props
) => {
  const propertyInfo = usePropertyInfo();
  const { propertyStackingPlanDate, updatePropertyStackingPlanDate } =
    useContext(RentRollContext);

  const location = useLocation();
  const year = propertyStackingPlanDate?.getFullYear();
  const month =
    propertyStackingPlanDate !== undefined
      ? propertyStackingPlanDate?.getMonth() + 1
      : undefined;

  const stackingplan = useGetStackingPlan(
    propertyInfo.id,
    propertyInfo.accountIdentifier,
    year,
    month
  );

  const [isAllExpanded, setIsAllExpanded] = useState<boolean>(false);
  const [expandStatus, setExpandStatus] = useState<boolean[]>([true]);
  const [exportEnabled, setExportEnabled] = useState<boolean>(true);

  useEffect(() => {
    if (
      expandStatus.length > 1 ||
      stackingplan.isFetching ||
      !stackingplan.data ||
      stackingplan.data.buildings.length === 0
    )
      return;

    let expandedElements = [true];
    expandedElements = expandedElements.concat(
      stackingplan.data?.buildings.map((b, index) =>
        index === 0 ? true : false
      ) ?? []
    );

    setExpandStatus(expandedElements);
    if (stackingplan.data?.buildings.length < 2) {
      setIsAllExpanded(true);
    }
  }, [stackingplan, expandStatus]);

  useEffect(() => {
    const isAnyRegionExpanded: boolean = expandStatus.some(
      (status) => status === true
    );
    setExportEnabled(isAnyRegionExpanded);
  }, [expandStatus]);

  useEffect(() => {
    const parsedQueryParams = queryString.parse(location.search);
    const monthString = parsedQueryParams.month;
    const yearString = parsedQueryParams.year;

    if (!propertyStackingPlanDate) {
      if (
        yearString !== undefined &&
        yearString !== null &&
        monthString !== undefined &&
        monthString !== null
      ) {
        updatePropertyStackingPlanDate(
          new Date(Number(yearString), Number(monthString) - 1)
        );
      } else {
        updatePropertyStackingPlanDate(new Date());
      }
    }
  }, [
    location.search,
    propertyStackingPlanDate,
    updatePropertyStackingPlanDate,
  ]);

  const onExpandStatusChanged = (index: number, expanded: boolean) => {
    expandStatus[index] = expanded;
    setExpandStatus([...expandStatus]);
    if (expandStatus.every((e) => !e)) {
      setIsAllExpanded(false);
    } else if (expandStatus.every((e) => e)) {
      setIsAllExpanded(true);
    }
  };
  type Props = {
    onClick?: () => void;
    value?: string;
  };
  type RefType = number;
  const CustomDatePickerInput = forwardRef<RefType, Props>(
    ({ onClick, value }, ref) => (
      <Box className={"react-datepicker-input"} ref={ref} onClick={onClick}>
        {value}
        <Box className={"react-datepicker-icon"}>
          <UilCalender />
        </Box>
      </Box>
    )
  );

  return stackingplan.isFetching || !propertyStackingPlanDate ? (
    <Box m="auto">
      <CircularProgress />
    </Box>
  ) : (
    <div
      id="property-stackingplan"
      className="tab-pane fade show active"
      role="tabpanel"
      aria-labelledby="property-tab-stackingplan"
    >
      <Grid container className="property-action-bar">
        <Grid item>
          <Grid container sx={{ width: "auto" }}>
            <Grid item>
              <Box className={"datepicker-outer-container"}>
                <Typography
                  variant="body3"
                  component="label"
                  className={"input-label"}
                >{`Show tenants for month:`}</Typography>
                <Box className={"datepicker-container"}>
                  <DatePicker
                    selected={propertyStackingPlanDate}
                    maxDate={new Date()}
                    onChange={(date: Date) => {
                      updatePropertyStackingPlanDate(date);
                    }}
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                    popperModifiers={[
                      {
                        name: "preventOverflow",
                        options: {
                          altAxis: true,
                        },
                      },
                      {
                        name: "offset",
                        options: {
                          offset: [-13, 0],
                        },
                      },
                    ]}
                    customInput={<CustomDatePickerInput {...props} />}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container sx={{ width: "auto" }}>
            <Grid
              item
              sx={{
                display: "flex",
                flexFlow: "column",
                alignItems: "flex-end",
              }}
            >
              <ExportButton year={year} month={month} />
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                flexFlow: "column",
                alignItems: "flex-end",
              }}
            >
              <PrintButtonGroup disabled={!exportEnabled} />
              <div style={{ marginTop: "20px", textAlign: "right" }}>
                <MaterialLink
                  component="button"
                  className="btn-link"
                  onClick={() => {
                    const allExpanded = !isAllExpanded;
                    setIsAllExpanded(allExpanded);
                    setExpandStatus([...expandStatus.map((m) => allExpanded)]);
                  }}
                  sx={{ display: "inline" }}
                >
                  {isAllExpanded ? "Collapse all" : "Expand all"}
                  {isAllExpanded ? <UilAngleUp /> : <UilAngleDown />}
                </MaterialLink>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div id="property-stackingplan-content" className="tab-content">
        <div className="standard-content">
          <div
            className="table-standard-toggle-container"
            style={{ width: "100%" }}
          >
            <div className="accordion-outer-propertyStackingPlan">
              <Box className="exportElement">
                <Accordion
                  expanded={expandStatus[0]}
                  onChange={(event, expanded) => {
                    onExpandStatusChanged(0, expanded);
                  }}
                  className={"accordion accordion-container-stacking-plan"}
                >
                  <AccordionSummary
                    expandIcon={<UilAngleDown />}
                    aria-controls="panel1a-content"
                    id="panel2a-header"
                    className="accordion-summary accordion-summary-stacking-plan"
                  >
                    <Typography
                      variant="h2"
                      color="primary.dark"
                      className="summary-table-stacking-plan-title-bar"
                    >
                      <Box className="summary-table-stacking-plan-icon-container">
                        <UilCalender />
                      </Box>
                      &nbsp;&nbsp;
                      {`Expiration Schedule for Total Property`}
                      &nbsp;&nbsp;
                      <Box className="tag">
                        <Typography variant="body3" color="primary.dark">
                          {formatNumber(
                            stackingplan.data?.header.rentableArea || 0
                          )}{" "}
                          {`RSF`}
                        </Typography>
                        <Typography
                          variant="body3"
                          color="primary.dark"
                        >{` | `}</Typography>
                        <Typography variant="body3" color="primary.dark">
                          {formatStackingPercentage(
                            stackingplan.data?.header.leasedPercentage || 0,
                            2
                          )}{" "}
                          {"Leased"}
                        </Typography>
                      </Box>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className={
                      "accordion-details accordion-details-stacking-plan"
                    }
                  >
                    <PropertyViewStackingPlanLeaseSummary
                      leaseSummary={stackingplan.data?.leaseSummary}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
              {stackingplan.data?.buildings.map(
                (building: StackingBuilding, buildingIndex) => {
                  const isBuildingExpanded =
                    expandStatus[buildingIndex + 1] || false;
                  return (
                    <Box
                      key={buildingIndex}
                      className={"exportElement"}
                      sx={{ marginTop: "1em" }}
                    >
                      <Accordion
                        expanded={isBuildingExpanded}
                        onChange={(event, expanded) => {
                          onExpandStatusChanged(buildingIndex + 1, expanded);
                        }}
                        className={
                          "accordion accordion-container-stacking-plan"
                        }
                      >
                        <AccordionSummary
                          expandIcon={<UilAngleDown />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="accordion-summary accordion-summary-stacking-plan"
                        >
                          <Typography
                            variant="h2"
                            color="primary.dark"
                            className="summary-table-stacking-plan-title-bar"
                          >
                            <Box className="summary-table-stacking-plan-icon-container">
                              <UilBuilding />
                            </Box>
                            &nbsp;&nbsp;
                            {building.buildingTitle}
                            &nbsp;&nbsp;
                            <Box className="tag">
                              <Typography variant="body3" color="primary.dark">
                                {formatNumber(building.buildingRsf)} {`RSF`}
                              </Typography>
                              <Typography
                                variant="body3"
                                color="primary.dark"
                              >{` | `}</Typography>
                              <Typography variant="body3" color="primary.dark">
                                {formatStackingPercentage(
                                  building.percentageLeased
                                )}
                                {` Leased`}
                              </Typography>
                            </Box>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          className={
                            "accordion-details accordion-details-stacking-plan"
                          }
                        >
                          {isBuildingExpanded &&
                            building.floors.map(
                              (floor: StackingFloor, floorIndex) => {
                                return (
                                  <Fragment key={floorIndex}>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={2}
                                        md={1}
                                        className={
                                          "label-stacking-plan-floor-container"
                                        }
                                      >
                                        <Box
                                          className={
                                            "label-stacking-plan-floor-name"
                                          }
                                        >
                                          <Typography
                                            variant="body3"
                                            color="primary.dark"
                                          >
                                            {floor.floorName}
                                          </Typography>
                                        </Box>
                                        <Box
                                          className={
                                            "label-stacking-plan-floor-area"
                                          }
                                        >
                                          <Typography
                                            variant="caption"
                                            color="neutral700.light"
                                          >
                                            {formatNumber(floor.floorRsf)}
                                            {" SF"}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={10}
                                        md={11}
                                        className="stacking-plan-floor-container-outer"
                                      >
                                        <Box
                                          className={
                                            "stacking-plan-floor-container"
                                          }
                                        >
                                          <PropertyViewStackingPlanSpaces
                                            spaces={floor.spaces}
                                            year={year}
                                            month={month}
                                          />
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Fragment>
                                );
                              }
                            )}
                          <Box
                            className={
                              "summary-table-stacking-plan-building-container"
                            }
                          >
                            <Typography
                              variant="h2"
                              color="primary.dark"
                              className="summary-table-stacking-plan-building-title-bar"
                            >
                              {`Expiration Schedule`}
                            </Typography>
                            <PropertyViewStackingPlanLeaseSummary
                              leaseSummary={building.leaseSummary}
                            />
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyViewStackingPlan;

interface PropertyViewStackingPlanSpaceProps {
  spaces: StackingSpace[];
  year: number | undefined;
  month: number | undefined;
}

const PropertyViewStackingPlanSpaces: React.FunctionComponent<
  PropertyViewStackingPlanSpaceProps
> = (props) => {
  //const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const handleOpen = (newPlacement: PopperPlacementType) => (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  return (
    <Fragment>
      {props.spaces.map((space: StackingSpace, spaceIndex) => {
        return (
          <Box
            key={spaceIndex}
            width={"calc(" + space.floorPercentage + "% - 2px)"}
            className={"label-stacking-plan-space-container"}
          >
            <Paper
              className={"label-stacking-plan-space-container-inner"}
              sx={{
                backgroundColor: getBackgroundColor(
                  space.leaseExpirationTerm,
                  space.isFutureLease
                ),
                borderColor: getBorderColor(
                  space.leaseExpirationTerm,
                  space.isFutureLease
                ),
                borderWidth: 2,
                borderStyle: "solid",
                ":hover": {
                  borderColor: getHoverBorderColor(
                    space.leaseExpirationTerm,
                    space.isFutureLease
                  ),
                },
              }}
              onClick={handleOpen("top")}
              id={space.id}
            >
              <Box className={"label-stacking-plan-space-tenant"}>
                <Typography variant="body3">
                  {space.isFutureLease
                    ? "Vacant: Future Lease Signed"
                    : space.tenantTitle}
                </Typography>
              </Box>
              <Box className={"label-stacking-plan-space-details-container"}>
                <UilCalender />
                {space.leaseTo && (
                  <Typography
                    variant="body3"
                    color="primary.dark"
                    className={"label-stacking-plan-space-expire"}
                  >
                    {format(new Date(space.leaseTo), "MM/dd/yyyy")}
                  </Typography>
                )}
                <Typography
                  variant="body3"
                  color="primary.dark"
                  className={"label-stacking-plan-space-area"}
                >
                  {space.spaceCode} {` | `}
                  {formatNumber(space.rentableArea)} RSF
                </Typography>
              </Box>
            </Paper>
            {anchorEl && anchorEl.id === space.id && (
              <Popper
                className="popover-stacking-plan"
                open={open}
                placement={placement}
                disablePortal={true}
                anchorEl={anchorEl}
                modifiers={[
                  {
                    name: "preventOverflow",
                    enabled: true,
                    options: {
                      altAxis: false,
                      altBoundary: false,
                      tether: true,
                      rootBoundary: "viewport",
                      padding: 8,
                    },
                  },
                ]}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Box
                    sx={{
                      background: "var(--neutral-white)",
                      boxShadow: "0 2px 2px 0 rgba(46, 57, 72, 0.3)",
                      borderRadius: "6px",
                      border: 0,
                      marginBottom: "12px",
                    }}
                  >
                    <Table
                      size="small"
                      aria-label="Popup table"
                      className="popover-stacking-plan-table"
                    >
                      <TableHead>
                        <TableRow className="popover-stacking-plan-table-header">
                          <TableCell
                            colSpan={2}
                            sx={{
                              padding: 0,
                              border: 0,
                            }}
                          >
                            <Box
                              sx={{
                                padding: "6px 16px 0 16px",
                                backgroundColor: getBackgroundColor(
                                  space.leaseExpirationTerm,
                                  space.isFutureLease
                                ),
                                borderColor: getHoverBorderColor(
                                  space.leaseExpirationTerm,
                                  space.isFutureLease
                                ),
                                borderWidth: 1,
                                borderBottomWidth: 0,
                                borderStyle: "solid",
                                borderRadius: "6px 6px 0 0",
                              }}
                            >
                              <Typography
                                variant="body2"
                                className="popover-stacking-plan-table-header-title"
                                color="primary.dark"
                              >
                                <Link
                                  target="_blank"
                                  to={`/propertyView/propertyViewRentRoll${
                                    location.search
                                  }&month=${props.month}&year=${
                                    props.year
                                  }&tenant=${encodeURIComponent(
                                    space.tenantTitle
                                  )}`}
                                  style={{ color: "black" }}
                                >
                                  {space.isFutureLease
                                    ? "Vacant"
                                    : space.tenantTitle}
                                </Link>
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow className="popover-stacking-plan-table-header">
                          <TableCell
                            sx={{
                              padding: 0,
                              border: 0,
                            }}
                          >
                            <Box
                              sx={{
                                padding: "0 16px 6px 16px",
                                backgroundColor: getBackgroundColor(
                                  space.leaseExpirationTerm,
                                  space.isFutureLease
                                ),
                                borderColor: getHoverBorderColor(
                                  space.leaseExpirationTerm,
                                  space.isFutureLease
                                ),
                                borderWidth: 1,
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderStyle: "solid",
                              }}
                            >
                              <Typography
                                variant="body3"
                                className="popover-stacking-plan-table-header-expires"
                                color="primary.dark"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <UilCalender />
                                {`Tenant expires on`}&nbsp;
                                {space.leaseTo && (
                                  <span>
                                    {format(
                                      new Date(space.leaseTo),
                                      "MM/dd/yyyy"
                                    )}
                                  </span>
                                )}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: 0,
                              border: 0,
                            }}
                          >
                            <Box
                              sx={{
                                padding: "0 16px 6px 16px",
                                backgroundColor: getBackgroundColor(
                                  space.leaseExpirationTerm,
                                  space.isFutureLease
                                ),
                                borderColor: getHoverBorderColor(
                                  space.leaseExpirationTerm,
                                  space.isFutureLease
                                ),
                                borderWidth: 1,
                                borderTopWidth: 0,
                                borderLeftWidth: 0,
                                borderStyle: "solid",
                                textAlign: "right",
                              }}
                            >
                              <Typography variant="body3" color="primary.dark">
                                {space.spaceCode}
                                {` | `}
                                {formatNumber(space.rentableArea)} RSF
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="popover-stacking-plan-table-body">
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: 0,
                              border: "0 !important",
                            }}
                          >
                            <Box
                              sx={{
                                padding: "6px 16px",
                                borderColor: "var(--neutral-grey-150)",
                                borderStyle: "solid",
                                borderWidth: "0 0 1px 1px",
                              }}
                            >
                              <Typography
                                variant="body3"
                                color="neutral700.light"
                              >
                                {`Lease Term`}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: 0,
                              border: "0 !important",
                            }}
                          >
                            <Box
                              sx={{
                                padding: "6px 16px",
                                borderColor: "var(--neutral-grey-150)",
                                borderStyle: "solid",
                                borderWidth: "0 1px 1px 0",
                              }}
                            >
                              <Typography variant="body3" color="primary.dark">
                                {space.leaseFrom && (
                                  <span>
                                    {format(
                                      new Date(space.leaseFrom),
                                      "MM/dd/yyyy"
                                    )}
                                  </span>
                                )}
                                {` - `}
                                {space.leaseTo && (
                                  <span>
                                    {format(
                                      new Date(space.leaseTo),
                                      "MM/dd/yyyy"
                                    )}
                                  </span>
                                )}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: 0,
                              border: "0 !important",
                            }}
                          >
                            <Box
                              sx={{
                                padding: "6px 16px",
                                borderColor: "var(--neutral-grey-150)",
                                borderStyle: "solid",
                                borderWidth: "0 0 1px 1px",
                              }}
                            >
                              <Typography
                                variant="body3"
                                color="neutral700.light"
                              >
                                {`Monthly Rent`}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: 0,
                              border: "0 !important",
                            }}
                          >
                            <Box
                              sx={{
                                padding: "6px 16px",
                                borderColor: "var(--neutral-grey-150)",
                                borderStyle: "solid",
                                borderWidth: "0 1px 1px 0",
                              }}
                            >
                              <Typography variant="body3" color="primary.dark">
                                {space.monthlyAmount === undefined
                                  ? "-"
                                  : formatCurrency(space.monthlyAmount, 0)}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: 0,
                              border: "0 !important",
                            }}
                          >
                            <Box
                              sx={{
                                padding: "6px 16px",
                                borderColor: "var(--neutral-grey-150)",
                                borderStyle: "solid",
                                borderWidth: "0 0 1px 1px",
                                borderRadius: "0 0 0 6px",
                              }}
                            >
                              <Typography
                                variant="body3"
                                color="neutral700.light"
                              >
                                {`Lease ID`}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: 0,
                              border: "0 !important",
                            }}
                          >
                            <Box
                              sx={{
                                padding: "6px 16px",
                                borderColor: "var(--neutral-grey-150)",
                                borderStyle: "solid",
                                borderWidth: "0 1px 1px 0",
                                borderRadius: "0 0 6px 0",
                              }}
                            >
                              <Typography variant="body3" color="primary.dark">
                                {space.leaseId === undefined
                                  ? "-"
                                  : space.leaseId}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </ClickAwayListener>
              </Popper>
            )}
          </Box>
        );
      })}
    </Fragment>
  );
};

interface PrintButtonGroupProps {
  disabled: boolean;
}

const PrintButtonGroup: React.FunctionComponent<PrintButtonGroupProps> = (
  props
) => {
  const options = ["Portrait", "Landscape"];
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<any>(null);
  const propertyInfo = usePropertyInfo();

  const handleMenuItemClick = async (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setOpen(false);
    await exportStakingPlan(index === 0 ? "p" : "l");
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const [isExporting, setIsExporting] = useState(false);

  const classes = useStyles();

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const exportStakingPlan = async function (orientation: "l" | "p") {
    setIsExporting(true);
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);
    await sleep(500);
    const topMargin: number = 10;
    const elements = document.querySelectorAll(".exportElement");
    let pdf = new jsPDF(orientation, "mm", "a4", true);
    pdf.setFontSize(10);

    let pageWidth = pdf.internal.pageSize.getWidth() - 10;
    let pageHeight = pdf.internal.pageSize.getHeight() - 20;

    let pageNumberWidth = pdf.internal.pageSize.getWidth() - 15;
    let pageNumberHeight = pdf.internal.pageSize.getHeight() - 5;

    let alreadyUsedHeightInPage = topMargin + 5;
    const tmpContainer = document.getElementById("exportTmpContainer");
    if (!tmpContainer) return;
    tmpContainer.style.display = "block";
    tmpContainer.style.position = "absolute";
    tmpContainer.style.top = "-100000px";
    tmpContainer.style.left = "-100000px";

    let pageNumber = 1;
    pdf.text(propertyInfo.title, 10, 10);
    pdf.text("Page " + pageNumber, pageNumberWidth, pageNumberHeight);

    for (let i: number = 0; i < elements.length; i++) {
      let element = elements[i] as HTMLElement;
      tmpContainer.append(element.cloneNode(true));
      tmpContainer.style.backgroundColor = "white !important";
      tmpContainer.style.boxShadow = "unset !important";
      let canvas = await html2canvas(tmpContainer, {
        scale: 2,
      });
      tmpContainer.innerHTML = "";

      const imgData = canvas.toDataURL("image/jpeg", 0.6);

      let imageWidth = canvas.width;
      let imageHeight = canvas.height;
      let ratio =
        imageWidth / imageHeight >= pageWidth / pageHeight
          ? pageWidth / imageWidth
          : pageHeight / imageHeight;

      if (
        alreadyUsedHeightInPage + imageHeight * ratio >
        pageHeight + topMargin
      ) {
        alreadyUsedHeightInPage = topMargin;
        pdf.addPage();
        pageNumber++;
        pdf.text("Page " + pageNumber, pageNumberWidth, pageNumberHeight);
      }

      pdf.addImage(
        imgData,
        "JPEG",
        5,
        alreadyUsedHeightInPage,
        imageWidth * ratio,
        imageHeight * ratio
      );

      alreadyUsedHeightInPage += imageHeight * ratio;
    }

    const fileName = `${propertyInfo.title} - Stacking Plan.pdf`;
    pdf.save(fileName);
    tmpContainer.style.display = "none";
    document.body.style.overflow = "";
    setIsExporting(false);
  };

  return (
    <Fragment>
      <Backdrop className={classes.backdrop} open={isExporting}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Tooltip
        title={props.disabled ? "Expand Stacking Plan(s) to print." : ""}
        placement="top"
      >
        <Box sx={{ marginTop: "21px" }}>
          <Button
            variant="contained"
            size="small"
            className={"btn-primary btn-dropdown"}
            onClick={handleToggle}
            disabled={props.disabled}
            ref={anchorRef}
          >
            Print
            <UilAngleDown />
          </Button>
        </Box>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

interface ExportButtonProps {
  year?: number;
  month?: number;
}

const ExportButton: React.FunctionComponent<ExportButtonProps> = (props) => {
  const exportReportResponse = useExportReport();
  const isMutating = useIsMutating();
  const propertyInfo = usePropertyInfo();

  const onExport = async () => {
    const data = await exportReportResponse.mutateAsync({
      accountIdentifier: propertyInfo.accountIdentifier,
      properties: [propertyInfo.id],
      includeStackingPlanReport: true,
      stackingPlanReportYear: props.year,
      stackingPlanReportMonth: props.month,
    });

    const fileName = `${propertyInfo.title} - Stacking Plan.xlsx`;
    fileSaver.saveAs(data.data, fileName);
  };

  return (
    <LoadingButton
      loading={isMutating > 0}
      variant="contained"
      size="small"
      className={"btn-primary"}
      sx={{ marginTop: "21px" }}
      onClick={async () => await onExport()}
    >
      <UilExport /> Export
    </LoadingButton>
  );
};
