import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Theme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, CircularProgress } from "@mui/material";

import { TUpdateBuildingModel } from "../model/updateBuildingModel";
import { useUpdateBuilding } from "../api/buildingService";
import { useGetStates } from "../api/stateService";
import { TBuildingListModel } from "../model/buildingListModel";
import { usePropertyInfo } from "../../util/propertyInfoContext";

interface BuildingUpdateProps {
  handleEditPopupClose: (shouldRefreshData: boolean) => void;
  showEditPopup: boolean;
  buildingProps?: TBuildingListModel;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
      borderRadius: 4,
    },
    title: {
      borderBottom: "1px solid #ccc",
      marginBottom: 15,
    },
  })
);

const BuildingUpdate: React.FunctionComponent<BuildingUpdateProps> = (
  props
) => {
  const classess = useStyles();
  const { data: stateLookupData, isLoading: isLoadingStates } = useGetStates();

  const {
    register,
    handleSubmit,
    control,

    formState: { errors },
  } = useForm<TUpdateBuildingModel>({
    defaultValues: {
      title: props.buildingProps?.title,
      street: props.buildingProps?.street,
      city: props.buildingProps?.city,
      province: props.buildingProps?.province,
      postalCode: props.buildingProps?.postalCode,
      rsf: props.buildingProps?.rsf,
      accountingKey: props.buildingProps?.accountingKey,
    },
  });

  const updateBuildingResponse = useUpdateBuilding();
  const propertyInfo = usePropertyInfo();

  const onSubmit = handleSubmit((model: TUpdateBuildingModel) => {
    model.propertyId = propertyInfo.id;
    model.accountIdentifier = propertyInfo.accountIdentifier;
    model.rsf = "" + model.rsf === "" ? null : model.rsf;
    model.accountingKey =
      "" + model.accountingKey === "" ? null : model.accountingKey;
    model.id = "" + props.buildingProps?.id;
    updateBuildingResponse.mutate(model, {
      onSuccess: (data) => {
        props.handleEditPopupClose(true);
      },
    });
  });

  const handleClose = () => {
    props.handleEditPopupClose(false);
  };

  const SelectIcon = (props: any) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  return (
    <Dialog
      open={props.showEditPopup}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classess.title}>
        Update Building
      </DialogTitle>
      <DialogContent>
        {isLoadingStates ? (
          <Box m="auto">
            <CircularProgress />
          </Box>
        ) : (
          <React.Fragment>
            <p>To update this building, please fill out the form below.</p>
            <Grid container>
              <Grid item xs={12}>
                {/* <FormGroup> */}
                {/* <Form.Label
                htmlFor="title"
                style={{ display: "none" }}
                className="label-custom"
              >
                Building Name
              </Form.Label> */}
                <FormControl className={classess.formControl}>
                  <TextField
                    size="small"
                    id="buildingTitle"
                    label="Title"
                    variant="outlined"
                    {...register("title", {
                      required: "Building Title is required",
                      maxLength: {
                        value: 500,
                        message: "Maximum length is 500",
                      },
                    })}
                    error={Boolean(errors?.title)}
                    helperText={
                      Boolean(errors?.title) ? errors?.title?.message : null
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classess.formControl}>
                  <TextField
                    size="small"
                    id="street"
                    label="Street"
                    variant="outlined"
                    {...register("street", {
                      required: "Address is required",
                      maxLength: {
                        value: 250,
                        message: "Maximum length is 250",
                      },
                    })}
                    error={Boolean(errors?.street)}
                    helperText={
                      Boolean(errors?.street) ? errors?.street?.message : null
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classess.formControl}>
                  <TextField
                    size="small"
                    id="city"
                    label="city"
                    variant="outlined"
                    {...register("city", {
                      required: "City is required",
                      maxLength: {
                        value: 50,
                        message: "Maximum length is 50",
                      },
                    })}
                    error={Boolean(errors?.city)}
                    helperText={
                      Boolean(errors?.city) ? errors?.city?.message : null
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={`${classess.formControl}`}
                >
                  <InputLabel id="province" shrink>
                    State
                  </InputLabel>
                  {/* province */}
                  <Controller
                    control={control}
                    name={"province"}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="province"
                        label="State"
                        displayEmpty
                        defaultValue={""}
                        input={<OutlinedInput notched label="State" />}
                        IconComponent={SelectIcon}
                      >
                        <MenuItem value={""}>Select State</MenuItem>
                        {stateLookupData &&
                          stateLookupData.map((province, index: number) => (
                            <MenuItem key={`${index}`} value={province.code}>
                              {province.code}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classess.formControl}>
                  <TextField
                    size="small"
                    id="postalCode"
                    label="Zip"
                    variant="outlined"
                    {...register("postalCode", {
                      required: "Zip is required",
                      maxLength: {
                        value: 50,
                        message: "Maximum length is 50",
                      },
                    })}
                    error={Boolean(errors?.postalCode)}
                    helperText={
                      Boolean(errors?.postalCode)
                        ? errors?.postalCode?.message
                        : null
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classess.formControl}>
                  <TextField
                    size="small"
                    id="rsf"
                    label="RSF"
                    type="number"
                    variant="outlined"
                    {...register("rsf", {
                      min: {
                        value: 1,
                        message: "Minimum value is 1",
                      },
                      max: {
                        value: 2147483647,
                        message: "Maximum value is 2,147,483,647",
                      },
                    })}
                    error={Boolean(errors?.rsf)}
                    helperText={
                      Boolean(errors?.rsf) ? errors?.rsf?.message : null
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classess.formControl}>
                  <TextField
                    size="small"
                    id="accountingKey"
                    label="Accounting Key"
                    variant="outlined"
                    {...register("accountingKey", {
                      maxLength: {
                        value: 500,
                        message: "Maximum length is 500",
                      },
                    })}
                    error={Boolean(errors?.accountingKey)}
                    helperText={
                      Boolean(errors?.accountingKey)
                        ? errors?.accountingKey?.message
                        : null
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            {updateBuildingResponse.isLoading && (
              <Box m="auto">
                <CircularProgress />
              </Box>
            )}
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={updateBuildingResponse.isLoading}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BuildingUpdate;
