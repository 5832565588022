import {
  formatCurrency,
  formatCurrencyNoAbbrev,
  formatNumber,
  formatNumberNoAbbrev,
  formatPercent,
} from "./formatNumber";

const NA_DATE = "09/09/9999";
const NA_DATE2 = "12/31/9999";

type CellStyle = { [cssProperty: string]: string };

export const negativeNumCellStyle = (params: any) => {
  let cellStyle: CellStyle | undefined;
  if (params.value === null) cellStyle = undefined;
  if (params.value < 0) {
    //Style negative cells as red
    cellStyle = { backgroundColor: "transparent", color: "var(--semantic-red)", textAlign: "right" };
  } else cellStyle = { textAlign: "right" };

  return cellStyle;
};

export const negativeAndPositiveNumCellStyle = (params: any) => {
  if (params.value === null) return null;
  if (params.value >= 0) {
    //Style positive cells as green
      return { backgroundColor: "transparent", color: "var(--semantic-green)", textAlign: "right" };
  } else if (params.value < 0) {
    //Style negative cells as red
      return { backgroundColor: "transparent", color: "var(--semantic-red)", textAlign: "right" };
  }
  return null;
};
export const numberGetter = (input: number | undefined) => {
  if (typeof input === "undefined") return null;
  //var sansDec = currency.toFixed(decPlaces);
  //var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return Math.round(input);
};

export const numberGetterForExcel = (places: number, input?: number) => {
  if (input === undefined) return "";
  return input.toFixed(places).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const currencyGetterForExcel = (places: number, input?: number) => {
  if (input === undefined) return "";
  var formatted = input.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${"$ " + formatted}`;
};

export const numberWithCommas = (number: number | undefined) => {
  if (number === undefined) return undefined;
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberGetterWithPlaces = (
  input: number | undefined,
  places: number
) => {
  if (typeof input === "undefined") return null;
  return parseFloat(input.toFixed(places));
};
export const numberFormatter = (
  input: number | undefined,
  decPlaces: number
) => {
  if (input === undefined || input === null) return "-";
  // var sansDec = input.toFixed(decPlaces);
  // var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  var formatted = formatNumber(input);
  // if (formatted.substr(0,1)==="-"){
  //     return formatted = "(" + formatted.substr(1) + ")";
  // }
  return `${formatted}`;
};

export const numberFormatterNoAbbrev = (
  input: number | undefined,
  decPlaces: number
) => {
  if (input === undefined || input === null) return "-";
  var formatted = formatNumberNoAbbrev(input);
  return `${formatted}`;
};

export const dateValueGetter = (dateinput: string | undefined) => {
  if (typeof dateinput === "undefined") return "";
  if (dateinput.length === 0) return "";
  var dateObj = new Date(dateinput);
  // return new Date(dateinput + 'Z');
  return dateObj.toISOString();
};

export const currencyFormatter = (
  currency: number | undefined,
  sign: string
) => {
  // if (typeof (currency) === "undefined") return "";
  if (typeof currency === "undefined" || currency === null) return "-";
  var formatted = formatCurrency(currency);
  // var sansDec = currency.toFixed(0);
  // var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // if (formatted.substr(0,1)==="-"){
  //     return formatted = "(" + sign + formatted.substr(1) + ")";
  // }
  return `${formatted}`;
};

export const currencyFormatterNoAbbrev = (
  currency: number | undefined,
  sign: string
) => {
  // if (typeof (currency) === "undefined") return "";
  if (typeof currency === "undefined" || currency === null) return "-";
  var formatted = formatCurrencyNoAbbrev(currency);
  // var sansDec = currency.toFixed(0);
  // var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // if (formatted.substr(0,1)==="-"){
  //     return formatted = "(" + sign + formatted.substr(1) + ")";
  // }
  return `${formatted}`;
};

export const currencyFormatterWithPlaces = (
  input: number | undefined,
  places: number,
  sign: string
) => {
  if (typeof input === "undefined" || input === null) return "-";

  var sansDec = input.toFixed(places);
  var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (formatted.substr(0, 1) === "-") {
    return (formatted = "(" + sign + formatted + ")");
  } else formatted = sign + formatted;
  return `${formatted}`;
};

export const pctGetter = (input: number | undefined) => {
  if (typeof input === "undefined") return null;
  //var sansDec = currency.toFixed(decPlaces);
  //var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return Math.round(input * 100);
};

export const percentFormatter = (input: number | undefined, sign: string) => {
  if (typeof input === "undefined" || input === null) return "-";
  var formatted = formatPercent(input);
  // var sansDec = (input * 100).toFixed(1);
  // var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // if (formatted.substr(0,1)==="-"){
  //     return formatted = "(" + formatted.substr(1) + ")";
  // }
  return `${formatted}`;
};

export const percentFormatterWithPlaces = (
  input: number | undefined,
  places: number,
  sign: string
) => {
  // if (typeof (input) === "undefined") return "";
  if (typeof input === "undefined" || input === null) return "-";

  var sansDec = (input * 100).toFixed(places);
  var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (formatted.substr(0, 1) === "-") {
    return (formatted = "(" + formatted.substr(1) + ")");
  }
  return `${formatted}` + sign;
};

export const dateValueGetterNA = (dateinput: string | undefined) => {
  if (typeof dateinput === "undefined") return "";
  if (dateinput.length === 0) return "";
  const newDate =
  ("0" + (new Date(dateinput).getUTCMonth() + 1)).slice(-2) +
  "/" +
  ("0" + new Date(dateinput).getUTCDate()).slice(-2) +
  "/" +
  new Date(dateinput).getUTCFullYear();
if (newDate === NA_DATE) return "N/A";
if (newDate === NA_DATE2) return "N/A";
  var dateObj = new Date(dateinput);
  return dateObj.toISOString();
};

export const dateValueFormatter = (dateinput: string | undefined) => {
  if (typeof dateinput === "undefined") return "";
  if (dateinput.length === 0) return "";
  const newDate =
    ("0" + (new Date(dateinput).getUTCMonth() + 1)).slice(-2) +
    "/" +
    ("0" + new Date(dateinput).getUTCDate()).slice(-2) +
    "/" +
    new Date(dateinput).getUTCFullYear();
  if (newDate === NA_DATE) return "N/A";
  if (newDate === NA_DATE2) return "N/A";
  return newDate;
};

export const percentFormatterWithPlacesPlusMinus = (
  input: number | undefined,
  places: number,
  sign: string
) => {
  if (typeof input === "undefined" || input === null) return "-";

  var sansDec = (input * 100).toFixed(places);
  var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (formatted.substr(0, 1) !== "-") {
    formatted = "+" + formatted;
  }
  //Remove .0 if applicable
  if (formatted.length > 2 && formatted.slice(-2) === ".0")
  {
    formatted = formatted.slice(0,-2);
  }
  return `${formatted}` + sign;
};

export const dateValueMMMYYFormatter = (dateinput: string | undefined) => {
  if (typeof dateinput === "undefined") return "";
  if (dateinput.length === 0) return "";
  const newDate =
    new Date(dateinput).toLocaleString('default', { month: 'short' }) +
    " '" +
    new Date(dateinput).getFullYear().toString().substr(-2);
  if (newDate === NA_DATE) return "N/A";
  if (newDate === NA_DATE2) return "N/A";
  return newDate;
};

export const textFormatter = (input: string | undefined) => {
  if (typeof input === "undefined" || input === null) return "-";
  return input;
};

export const formatSqFt = (value: number) => {
  if (value < 1000000) return numberFormatter(value, 0);
  if (value >= 1000000) return numberFormatter(value / 1000000, 2) + "M";
};

// export const pctFilterParams = {
//     numberParser: (text:string) => {
//         return text === null ? null : parseFloat(text)/100;
//     }
// };

export const dateFilterParams = {
  comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
    const dateAsString =
      ("0" + (new Date(cellValue).getUTCMonth() + 1)).slice(-2) +
      "/" +
      ("0" + new Date(cellValue).getUTCDate()).slice(-2) +
      "/" +
      new Date(cellValue).getUTCFullYear();
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[0]) - 1,
      Number(dateParts[1])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};

export const numberSort = (num1: number, num2: number) => {
  return num1 - num2;
};

export const stringSortCaseInsensitive = (valueA: any, valueB: any)  => {
    if ((valueA === null && valueB === null) || (valueA === undefined && valueB === undefined)) {
        return 0;
    }
  
    if (valueA === null || valueA === undefined) {
        return -1;
    }
    if (valueB === null || valueB === undefined) {
        return 1;
    }
  
    var upperValueA = typeof valueA === "string" ? valueA.toUpperCase() : valueA;
    var upperValueB = typeof valueB === "string" ? valueB.toUpperCase() : valueB;
    return upperValueA === upperValueB ? 0 : (upperValueA > upperValueB) ? 1 : -1;
  }