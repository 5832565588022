import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  ColDef,
  FirstDataRenderedEvent,
  FilterChangedEvent,
  GridApi,
  GridReadyEvent,
  RowDataChangedEvent,
} from "ag-grid-community";

// import { IFrequencySelectList } from "../../model/alertsModel";
// import ConfirmDialog from "../../../../shared/view/confirmationDialog";
// import { useDeleteAlert } from "../../api/alertsService";
// import { UserInfoContext } from "../../util/userInfoContext";
import { stringSortCaseInsensitive } from "../../../properties/util/gridUtils";
// import GLAccountTreeActionsRenderer from "../glAccountTrees/GLAccountTreeActionsRenderer";
import NegateActualCustomHeader from "./NegateActualCustomHeader";
import NegateBudgetCustomHeader from "./NegateBudgetCustomHeader";
import { NegateActualBudgetContext } from "./NegateActualBudgetContext";
import {
  IChartOfAccounts,
  INegateActualBudgetRow,
} from "../../model/negateActualBudgetModel";
import NegateActualBudgetActionsRenderer from "./NegateActualBudgetActionsRenderer";

// const deleteAlertText = "Successfully updated alerts.";

// const deleteAlertFailText =
//   "Your alert could not been deleted. Please try again later.";

type Props = {
  rows: IChartOfAccounts[] | undefined;
  updateRenderedRows: (renderedRows: string[]) => void;
};

const NegateActualBudgetGrid: React.FC<Props> = (props) => {
  //   const { updateAlertDetails } = useContext(UserInfoContext);

  const { rows } = props;

  const {
    allNegateActualChecked,
    allNegateBudgetChecked,
    updateMultiplenegateActualBudgetRow,
    processAllActualChecked,
    updateProcessAllActualChecked,
    processAllBudgetChecked,
    updateProcessAllBudgetChecked,
  } = React.useContext(NegateActualBudgetContext);

  const [gridApi, setGridApi] = useState<GridApi>();

  React.useEffect(() => {
    if (!(allNegateActualChecked.touched || allNegateBudgetChecked.touched))
      return;
    if (!(processAllActualChecked || processAllBudgetChecked)) return;
    // Whenever the user clicks on check/uncheck all on the checkbox in header
    const updatedRows: INegateActualBudgetRow[] = [];
    gridApi?.forEachNodeAfterFilter((node) => {
      updatedRows.push({
        financialAccountNumberId: node.data.financialAccountNumberId,
        accountingSystemId: node.data.accountingSystemId,
        accountingSystemTitle: node.data.accountingSystemTitle,
        financialAccountNumberDescription:
          node.data.financialAccountNumberDescription,
        financialAccountNumber: node.data.financialAccountNumber,
        negateActualOnImport: processAllActualChecked
          ? allNegateActualChecked.checked
          : node.data.negateActualOnImport,
        negateBudgetOnImport: processAllBudgetChecked
          ? allNegateBudgetChecked.checked
          : node.data.negateBudgetOnImport,
      });
    });
    updateProcessAllActualChecked(false);
    updateProcessAllBudgetChecked(false);
    updateMultiplenegateActualBudgetRow(updatedRows);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allNegateActualChecked.checked,
    allNegateActualChecked.touched,
    allNegateBudgetChecked.checked,
    allNegateBudgetChecked.touched,
  ]);

  //   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  //   const [deleteAlertName, setDeleteAlertName] = useState("");
  //   const [deleteAlertId, setDeleteAlertId] = useState<string | undefined>(
  //     undefined
  //   );

  //   const deleteAlert = useDeleteAlert();

  //   const handleDeleteDialog = (alertName?: string, alertId?: string) => {
  //     setOpenDeleteDialog(true);
  //     if (alertName !== undefined) setDeleteAlertName(alertName);
  //     if (alertId !== undefined) setDeleteAlertId(alertId);
  //   };

  //   const onDeleteAlert = () => {
  //     if (deleteAlertId !== undefined)
  //       deleteAlert.mutate(
  //         {
  //           alertRuleId: deleteAlertId,
  //           accountId,
  //         },
  //         {
  //           onSuccess: (data) => {
  //             handleClose(true);
  //             setOpenDeleteDialog(false);
  //             updateAlertDetails(deleteAlertText, "success");
  //           },
  //           onError: (data) => {
  //             handleClose(false);
  //             setOpenDeleteDialog(false);
  //             updateAlertDetails(deleteAlertFailText, "error");
  //           },
  //         }
  //       );
  //   };

  const columnDefs = React.useMemo(() => {
    const defs: ColDef[] = [
      {
        headerName: "Code",
        comparator: stringSortCaseInsensitive,
        field: "financialAccountNumber",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Title",
        comparator: stringSortCaseInsensitive,
        field: "financialAccountNumberDescription",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Accounting System",
        field: "accountingSystemTitle",
        comparator: stringSortCaseInsensitive,
        filter: "agTextColumnFilter",
      },
      {
        // headerName: "Negate Actual On Import",
        headerComponent: "customNegateActualHeader",
        // headerCheckboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
        // checkboxSelection: true,
        field: "negateActualOnImport",
        cellRenderer: "actionsRenderer",
        sortable: false,
        filter: false,
      },
      {
        headerComponent: "customNegateBudgetHeader",
        field: "negateBudgetOnImport",
        cellRenderer: "actionsRenderer",
        sortable: false,
        filter: false,
      },
    ];
    return defs;
  }, []);

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);

    // Updating the list of rows that have been rendered onto the screen
    const newRenderedRows: string[] = [];
    event.api.forEachNodeAfterFilter((node) => {
      newRenderedRows.push(node.data.financialAccountNumberId);
    });
    props.updateRenderedRows(newRenderedRows);

    // onGridRender(event.api);
    if (rows === undefined || rows.length < 1) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }
  };

  const onFilterChanged = (event: FilterChangedEvent) => {
    // Updating the list of rows that have been rendered onto the screen
    const newRenderedRows: string[] = [];
    event.api.forEachNodeAfterFilter((node) => {
      newRenderedRows.push(node.data.financialAccountNumberId);
    });
    props.updateRenderedRows(newRenderedRows);
  };

  const rowDataChangedHandler = (event: RowDataChangedEvent) => {
    event.columnApi.resetColumnState();
    event.api.setFilterModel(null);
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    // Auto sizing columns to fit the whole screen
    event.api.sizeColumnsToFit();
  };

  return (
    <div id="negateactualbudget-main-content" className="grid-container">
      <div
        id="negateactualbudgetGrid"
        className="ag-theme-alpine ag-theme-alpine-container-override"
      >
        <AgGridReact
          onGridReady={onGridReady}
          onFilterChanged={onFilterChanged}
          onRowDataChanged={rowDataChangedHandler}
          onFirstDataRendered={onFirstDataRendered}
          rowData={rows}
          columnDefs={columnDefs}
          components={{
            customNegateActualHeader: NegateActualCustomHeader,
            customNegateBudgetHeader: NegateBudgetCustomHeader,
            actionsRenderer: NegateActualBudgetActionsRenderer,
          }}
          sortingOrder={["desc", "asc"]}
          defaultColDef={{
            filter: true,
            resizable: true,
            sortable: true,
            filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
          }}
        />
      </div>
    </div>
  );
};

export default NegateActualBudgetGrid;
