import React from "react";
import { ListItemIcon, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { UilPen } from '@iconscout/react-unicons'

import AccordionChip from "./AccordionChip";

/*
const listItemStyles = {
  minWidth: 0,
  padding: 6,
  backgroundColor: "rgba(6, 108, 250, 0.08)",
  borderRadius: 6,
};
*/

interface Props {
  startIcon: JSX.Element;
  title: string;
  label: string;
  displayEditBtn?: boolean;
  onEditClick?: () => void;
}

const PropOverviewAccordionSum: React.FC<Props> = (props) => {
  const { startIcon, title, label, onEditClick } = props;

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <Box mr={1} className="icon-overview">
        {startIcon}
      </Box>
      <Box>
        <Typography variant="h2" color="primary.dark">{title}</Typography>
      </Box>
      {label && (
        <Box ml={1}>
          <AccordionChip label={label} />
        </Box>
      )}
      {onEditClick && props.displayEditBtn && (
        <Box ml={"auto"} mr={2}>
          <Button
            variant="outlined"
            //startIcon={<UilPen />}
            size="small"
            color="inherit"
            onClick={(event) => {
              event.stopPropagation();
              if (!!onEditClick) onEditClick();
            }}
            className="btn-secondary"
          >
            <UilPen />
            Edit
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PropOverviewAccordionSum;
