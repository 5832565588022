import React from "react";
import { DialogTitle, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { UilMultiply } from "@iconscout/react-unicons";

interface Props {
  title: string;
  onClose: () => void;
}

const DialogTitleCustom: React.FC<Props> = ({ title, onClose }) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <DialogTitle id={`${title}-edit`} style={{ padding: 0 }}>
        <Typography component="span" variant="h2" color="primary.dark">
          {title}
        </Typography>
      </DialogTitle>
      <UilMultiply onClick={onClose} style={{ cursor: "pointer" }} />
    </Box>
  );
};

export default DialogTitleCustom;
