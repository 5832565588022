import SpaceFloorGroup from "./spaceGroup";

class SpaceBuildingGroup{
  spaceFloors: SpaceFloorGroup[];
  buildingTitle: string;
  buildingId: string;

  constructor(
    spaceFloors: SpaceFloorGroup[],
    building: string,
    buildingId: string
  ) {
    this.spaceFloors = spaceFloors.sort(
      (a: SpaceFloorGroup, b: SpaceFloorGroup) => {
        return (
          a.floor.localeCompare(b.floor)
        );
      }
    );

    this.buildingTitle = building;
    this.buildingId = buildingId;

  }
}
export default SpaceBuildingGroup;

export type TSpaceBuildingGroupListModel = {
  spaceBuildings: SpaceBuildingGroup[];
};
