import axios from "axios";
import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "./../../../shared/api/authorizedAxios";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { TUserList } from "../model/userListModel";
import { TDeleteUserModel } from "../model/deleteUserModel";
import { TCreateUserModel } from "../model/createUserModel";
import { TUserFilter, TUserListFilter } from "../model/userListFilter";
import { UAUser } from "../model/userModel";
import { TEditUserModel } from "../model/editUserModel";
import { parseEditUser } from "../util/userAdministration";
import { IAccountRoles } from "../model/getAccountRoles";
import { TUserListByPropertyFilter } from "../model/userListByPropertyFilter";
import { TUserListByProperty } from "../model/userListByPropertyModel";

export const useGetUserList = (filter: TUserListFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getUserList", filter.accountIdentifier, filter.value],
    () => {
      const source = axios.CancelToken.source();
      const url = `/PropertyService/v1/users/${filter.value}`;

      let promise = authorizedApiAxios().get<TUserList>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: filter.accountIdentifier, //'Xwjgd8gaAO'
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => response.data,
      enabled: filter.accountIdentifier !== "",
    }
  );
};

export const useGetUserListByProperty = (filter: TUserListByPropertyFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getUserListByProperty", filter.accountIdentifier, filter.propertyId],
    () => {
      const source = axios.CancelToken.source();
      const url = `/PropertyService/v1/users/${filter.propertyId}/UsersByProperty`;

      let promise = authorizedApiAxios().get<TUserListByProperty>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: filter.accountIdentifier, //'Xwjgd8gaAO'
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => response.data,
      enabled: filter.accountIdentifier !== "",
    }
  );
};


/**
 * To retrieve a single user record
 * @param filter
 */
export const useGetUser = (filter: TUserFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getUser", filter.userId],
    () => {
      const source = axios.CancelToken.source();
      const url: string = `/PropertyService/v1/users/${filter.userId}`;

      let promise = authorizedApiAxios().get<UAUser>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: filter.accountIdentifier,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => response.data,
      // enabled: shouldExecute,
      // staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};

export const useCreateUser = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: TCreateUserModel) => {
    const source = axios.CancelToken.source();

    const url: string = `/IdentityService/v1/Invitations`;
    let promise = authorizedApiAxios().post<string>(
      url,
      {
        email: item.email,
      },
      {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: item.accountIdentifier,
        },
      }
    );

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useUpdateUser = (userId: string) => {
  const authorizedApiAxios = useAuthorizedApiAxios();
  const queryClient = useQueryClient();

  return useMutation(
    (user: TEditUserModel) => {
      const source = axios.CancelToken.source();

      const url = `PropertyService/v1/users/${user.userId}`;
      let promise = authorizedApiAxios().put<string>(url, parseEditUser(user), {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: user.accountIdentifier,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      // Optimistic update
      onMutate: async (user: TEditUserModel) => {
        await queryClient.cancelQueries(["getUser", user.userId]);
        const previousUser = queryClient.getQueryData<UAUser>([
          "getUser",
          user.userId,
        ]);
        if (previousUser !== undefined) {
          queryClient.setQueryData<UAUser>(
            ["getUser", user.userId],
            (oldUser) => {
              const updatedUser: UAUser = {
                ...previousUser,
                id: user.userId,
                // email: user.email,
                // phoneNumber: user.phoneNumber,
                // status: user.status,
                roles: [...user.roles],
              };
              return updatedUser;
            }
          );
        }

        return { previousUser };
      },
      onError: (err, editedUser, context) => {
        queryClient.setQueryData(
          ["getUser", editedUser.userId],
          context?.previousUser
        );
      },
      onSettled: () => queryClient.invalidateQueries(["getUser", userId]),
    }
  );
};

export const useDeleteUser = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((userToBeDeleted: TDeleteUserModel) => {
    const source = axios.CancelToken.source();

    const url = `/IdentityService/v1/Users/${userToBeDeleted.userId}/Accounts`;
    // const url: string = `PropertyService/v1/users/${userToBeDeleted.userId}`;
    let promise = authorizedApiAxios().delete<string>(url, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: userToBeDeleted.accountIdentifier,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useGetAccountRoles = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getAccountRoles"],
    () => {
      const source = axios.CancelToken.source();
      const url = `/PropertyService/v1/UserPermissions/accountroles`;

      let promise = authorizedApiAxios().get<IAccountRoles>(url, {
        cancelToken: source.token,
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => response.data,
      staleTime: Infinity,
    }
  );
};
