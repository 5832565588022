import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  AccountIdentifierHeaderName,
  useAuthorizedApiAxios,
} from "../../../shared/api/authorizedAxios";
import {
  AlertCreate,
  AlertDelete,
  AlertPost,
  AlertPut,
  ArNotificationResult,
  DismissNotifications,
  DismissNotificationsPatch,
  IArAlertResult,
} from "../model/alertsModel";

export const useGetAlerts = (accountId: string) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getArAlerts", accountId],
    () => {
      const source = axios.CancelToken.source();
      const url = `AssetService/v1/ArAlert/Rules`;

      let promise = authorizedApiAxios().get<IArAlertResult>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: accountId,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => response.data,
      enabled: accountId.trim() !== "",
    }
  );
};

export const useCreateAlert = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: AlertPost) => {
    const alertPostItem: AlertCreate = {
      alertTitle: item.alertTitle,
      propertyIdList: item.propertyIdList,
      totalBalanceThreshold: item.totalBalanceThreshold,
      alertRuleFrequencyId: item.alertRuleFrequencyId,
      sendEmail: item.sendEmail,
    };

    const source = axios.CancelToken.source();

    const url: string = `AssetService/v1/ArAlert/Rules`;
    let promise = authorizedApiAxios().post<string>(url, alertPostItem, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: item.accountId,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useEditAlert = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: AlertPut) => {
    const alertPutItem: AlertCreate = {
      alertTitle: item.alertTitle,
      propertyIdList: item.propertyIdList,
      totalBalanceThreshold: item.totalBalanceThreshold,
      alertRuleFrequencyId: item.alertRuleFrequencyId,
      sendEmail: item.sendEmail,
    };

    const source = axios.CancelToken.source();

    const url = `AssetService/v1/ArAlert/Rules/${item.alertRuleId}`;
    let promise = authorizedApiAxios().put<string>(url, alertPutItem, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: item.accountId,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useDeleteAlert = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: AlertDelete) => {
    const source = axios.CancelToken.source();

    const url = `AssetService/v1/ArAlert/Rules/${item.alertRuleId}`;
    let promise = authorizedApiAxios().delete<string>(url, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: item.accountId,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useGetAlertNotifications = (accountId: string) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getArAlertsNotifications", accountId],
    () => {
      const source = axios.CancelToken.source();
      const url = `PropertyService/v1/Notifications`;

      let promise = authorizedApiAxios().get<ArNotificationResult>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: accountId,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      refetchInterval: 30000,  //30 seconds
      select: (response) => response.data,
      enabled: accountId.trim() !== "",
    }
  );
};

export const useDismissNotifications = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: DismissNotificationsPatch) => {
    const source = axios.CancelToken.source();
    const url = `PropertyService/v1/Notifications/DismissNotifications`;
    const dismissNotifications: DismissNotifications = {
      notificationIds: item.notificationIds,
      dismissAll: item.dismissAll,
    };

    let promise = authorizedApiAxios().patch<string>(
      url,
      dismissNotifications,
      {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: item.accountId,
        },
      }
    );

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};
