import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import {
  AccountIdentifierHeaderName,
  useAuthorizedApiAxios,
} from "../../../../shared/api/authorizedAxios";
import {
  IGetPortfolioRRFilter,
  IPortfolioRentRollModel,
} from "../model/getPortfolioRR";

/**
 * Gets all the portfolio - rent roll data from the backend
 * @param params Has portfolio id, month and year filter
 * @param accountId accountId refers to the currently selected account in portfolios page
 */
export const useGetPortfolioRentRoll = (
  params: IGetPortfolioRRFilter,
  accountId: string
) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  const uniqueId =
    params.portfolioId + params.filterMonth + params.filterYear + accountId;

  return useQuery(
    ["getPortfolioRentRoll", uniqueId],
    () => {
      const source = axios.CancelToken.source();
      const queryParams = queryString.stringify(params);
      const url = `/assetService/v1/rentroll/portfolio?${queryParams}`;

      let promise = authorizedApiAxios().get<IPortfolioRentRollModel>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: accountId,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      enabled: accountId.trim().length > 0,
      select: (response) => {
        return response!.data;
      },
      staleTime: Infinity,
    }
  );
};
