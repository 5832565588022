import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { UilMultiply } from "@iconscout/react-unicons";

import LoansForms from "./LoansForms";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import {
  formatDatePO,
  EDateFormat,
} from "../../property details/util/formatDateUtil";

export const steps = [
  "Primary Info",
  "Lenders & Servicers",
  "Hedge & Covenants",
];

interface Props {
  newLoan: boolean;
  defaultLoanFormValues: any;
  openDialog: boolean;
  closeDialog: (isLoanSaved?: boolean) => void;
  loanId?: string;
  isLoading?: boolean;
  isError?: boolean;
}

const LoansFormModal: React.FC<Props> = (props) => {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const { newLoan, openDialog, closeDialog } = props;

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [saveLoan, setSaveLoan] = useState(false);
  const [resetScrollPosition, setResetScrollPosition] = useState(false);

  const setSaveLoanFalse = React.useCallback(() => setSaveLoan(false), []);

  const scrollRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef === null || scrollRef === undefined) return;
    if (scrollRef.current === null || scrollRef.current === undefined) return;

    scrollRef.current.scrollTop = 0;
  }, [resetScrollPosition]);

  const updateActiveStep = React.useCallback((activeStep: number) => {
    setActiveStep(activeStep);
  }, []);

  const onSwitchTab = React.useCallback(
    () =>
      setResetScrollPosition((prevState) => {
        return !prevState;
      }),
    []
  );

  const isLoading = isFetching > 0;
  const isSaving = isMutating > 0;
  const lastModifiedBy = props.defaultLoanFormValues.lastModifiedUserFullName;
  const lastModifiedAt = props.defaultLoanFormValues.lastModifiedAt;

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="loans-form"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="loans-form">
        <Typography component="span" variant="h2" color="primary.dark">
          {newLoan ? "Add New Loan" : "Edit Loan"}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => closeDialog()}
          className="icon-dialog-close"
          size="large"
        >
          <UilMultiply />
        </IconButton>
        <Typography component="div" variant="h3" color="neutral700.light">
          {newLoan
            ? `Please complete the form below to add a new loan.`
            : `Please complete the form below to edit the loan.`}
        </Typography>
      </DialogTitle>
      <DialogContent ref={scrollRef}>
        {Boolean(props.isLoading) ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : props.isError ? (
          <Typography component="div" variant="h3" color="neutral700.light">
            Something went wrong, please try later.
          </Typography>
        ) : (
          <React.Fragment>
            {/* Tabs */}
            <LoansForms
              loanId={props.loanId}
              onCloseDialog={closeDialog}
              onSwitchTab={onSwitchTab}
              defaultLoansForm={props.defaultLoanFormValues}
              saveLoan={saveLoan}
              setSaveLoanFalse={setSaveLoanFalse}
              activeStep={activeStep}
              updateActiveStep={updateActiveStep}
            />
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container sx={{ justifyContent: "space-between" }}>
          {newLoan ||
          lastModifiedBy === undefined ||
          lastModifiedBy?.trim().length === 0 ||
          lastModifiedAt === undefined ? (
            <Grid item />
          ) : (
            <Grid item>
              <Typography component="div" variant="h3" color="neutral700.light">
                Last updated by {lastModifiedBy} on{" "}
                {formatDatePO(lastModifiedAt, EDateFormat.MM_DD_YYYY)}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              disabled={isLoading || isSaving}
              onClick={() => closeDialog()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="small"
              sx={{ marginLeft: "8px" }}
              disabled={isLoading || isSaving}
              onClick={() => {
                if (activeStep === steps.length - 1) {
                  setSaveLoan(true);
                } else {
                  setActiveStep((activeStep) => activeStep + 1);
                  onSwitchTab();
                }
              }}
            >
              {activeStep === steps.length - 1 ? "Save" : "Next"}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default LoansFormModal;
