export function formatCurrency(input: number, maximumFractionDigits: number = 0) {
  let formattedInput;
  if (input > 1000000000) {
    formattedInput =  (input / 1000000000).toLocaleString("en-US", {
      maximumFractionDigits: 2,
    }) + "B";
  } else {
    if (input > 1000000)
    {
      formattedInput =  (input / 1000000).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      }) + "M";
    }
    else
    {
      formattedInput =  (input).toLocaleString("en-US", {
        maximumFractionDigits: maximumFractionDigits,
      });
    }
  }
  if (formattedInput.substr(0,1)==="-"){
    formattedInput = "($" + formattedInput.substr(1) + ")";
  }
  else{
    formattedInput = "$" + formattedInput;
  }
  return formattedInput;
}

export function formatCurrencyNoAbbrev(input: number) {
  let formattedInput;
 
    
  formattedInput =  (input).toLocaleString("en-US", {
    maximumFractionDigits: 0,
  });
    
  
  if (formattedInput.substr(0,1)==="-"){
    formattedInput = "($" + formattedInput.substr(1) + ")";
  }
  else{
    formattedInput = "$" + formattedInput;
  }
  return formattedInput;
}

export function formatNumber(input: number) {
  let formattedInput;
  if (input > 1000000000) {
    formattedInput =  (input / 1000000000).toLocaleString("en-US", {
      maximumFractionDigits: 2,
    }) + "B";
  } else {
    if (input > 1000000)
    {
      formattedInput =  (input / 1000000).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      }) + "M";
    }
    else
    {
      formattedInput =  (input).toLocaleString("en-US", {
        maximumFractionDigits: 0,
      });
    }
  }
  if (formattedInput.substr(0,1)==="-"){
    formattedInput = "(" + formattedInput.substr(1) + ")";
  }
  return formattedInput;  
}

export function formatNumberNoAbbrev(input: number) {
  let formattedInput;

  formattedInput =  (input).toLocaleString("en-US", {
    maximumFractionDigits: 0,
  });

  if (formattedInput.substr(0,1)==="-"){
    formattedInput = "(" + formattedInput.substr(1) + ")";
  }
  return formattedInput;  
}

export function formatPercent(input: number) {
  let formattedInput = (input * 100).toLocaleString("en-US", {
    maximumFractionDigits: 0,
  }) + "%";
  if (formattedInput.substr(0,1)==="-"){
    formattedInput = "(" + formattedInput.substr(1) + ")";
  }  
  return formattedInput;
}

export const percentFormattedForLoans = (input: number | undefined) => {
  if (input === undefined) return "-";
  var formatted = formatPercentWithoutMul(input);
  return `${formatted}`;
};

export function formatPercentWithoutMul(input: number) {
  let formattedInput = input.toLocaleString("en-US", {
    maximumFractionDigits: 0,
  }) + "%";
  if (formattedInput.substr(0,1)==="-"){
    formattedInput = "(" + formattedInput.substr(1) + ")";
  }  
  return formattedInput;
}