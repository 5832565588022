import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  useGetDatabaseStatus,
  useMigrateDatabase,
} from "../api/databaseService";
import UserOptions from "../../layout/views/userOptions";
import { useQueryClient } from "@tanstack/react-query";
import { UilExport } from "@iconscout/react-unicons";
import { DatabaseMigration } from "../model/databaseMigration";

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: "fixed",
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Database: React.FunctionComponent = () => {
  const classes = useStyles();

  const services = [
    { name: "assetservice", description: "Asset Management" },
    { name: "documentservice", description: "Document" },
    { name: "propertyservice", description: "Property & Facility" },
  ];

  const [selectedService, setSelectedService] =
    useState<string>("assetservice");
  const [isMigrating, setIsMigrating] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupData, setPopupData] = useState<DatabaseMigration | null>(null);

  const queryClient = useQueryClient();
  const databaseStatus = useGetDatabaseStatus(selectedService);
  const migrateDatabase = useMigrateDatabase();

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedService(event.target.value);
  };

  const getBackgrounColor = (category: string, success: boolean) => {
    if (category === "Pending") return "Orange";
    if (!success) return "Red";
    return "Green";
  };

  const onMigrateDatabase = (id?: number) => {
    setIsMigrating(true);
    migrateDatabase.mutate(
      { service: selectedService, databaseId: id },
      {
        onSuccess: (successObj) => {
          queryClient.invalidateQueries(["databaseStatus", selectedService]);
          setPopupData(successObj.data);
          setShowPopup(true);
        },
        onSettled: () => setIsMigrating(false),
      }
    );
  };

  const SelectIcon = (props: any) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  return (
    <Box className="mainSettingsContainer mainSettingsContainer-database">
      <DatabaseResult
        showPropup={showPopup}
        closePopup={() => setShowPopup(false)}
        data={popupData}
      />
      <Backdrop className={classes.backdrop} open={isMigrating}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className={"appHeader"} sx={{ backgroundColor: "neutralSecondary.main", }}>
        <Box>
          <Typography variant="h1" sx={{ color: "primary.dark" }}>
            Database
          </Typography>
        </Box>
        {/* User Options */}
        <UserOptions />
      </Box>
      <Grid container className="property-action-bar">
        <Grid item>
          <Grid container sx={{ width: "auto", }}>
            <Grid item>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container sx={{ width: "auto", }}>
            <Grid item>
              <FormControl variant="outlined" size="small">
                <Typography
                  variant="body3"
                  component="label"
                  className={"input-label"}
                >{`Select service:`}</Typography>
                <Select
                  labelId="componenet"
                  id="service-select"
                  value={selectedService}
                  //label="Service"
                  onChange={handleChange}
                  className={"input-field"}
                  IconComponent={SelectIcon}
                >
                  {services.map((service) => (
                    <MenuItem value={service.name} key={service.name}>
                      <Typography variant="body1">
                        {service.description}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="outlined" size="small">
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => onMigrateDatabase(undefined)}
                  className={"btn-primary"}
                  sx={{ marginTop: "21px" }}
                  disabled={databaseStatus.isFetching}
                >
                  <UilExport /> Migrate All
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {databaseStatus.isFetching ? (
        <Box m="auto">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="database-panel-container">
          {databaseStatus.data?.databaseStatusResultItems?.map((item) => (
            <Accordion defaultExpanded={false} key={item.id}>
              <AccordionSummary>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "primary.dark" }}
                    >
                      {item.server}/{item.database}. Pending scripts:{" "}
                      {
                        item.migrationInformation.filter(
                          (m) => m.category === "Pending"
                        ).length
                      }
                    </Typography>
                  </Box>
                  <Box ml={"auto"}>
                    <Button
                      variant="contained"
                      size="small"
                      className={"btn-primary"}
                      onClick={(event) => {
                        event.stopPropagation();
                        onMigrateDatabase(item.id);
                      }}
                    >
                      <UilExport /> Migrate
                    </Button>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="database table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell width="15%" align="center">Version</TableCell>
                        <TableCell width="15%" align="center">Category</TableCell>
                        <TableCell width="15%" align="center">Description</TableCell>
                        <TableCell width="15%" align="center">InstalledOn</TableCell>
                        <TableCell width="40%" align="center">Checksum</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.migrationInformation.map((item, index) => (
                        <TableRow
                          key={index}
                          style={{
                            backgroundColor: getBackgrounColor(
                              item.category,
                              item.success
                            ),
                          }}
                        >
                          <TableCell align="center">{item.version}</TableCell>
                          <TableCell align="center">
                            {item.category}
                          </TableCell>
                          <TableCell align="center">
                            {item.description}
                          </TableCell>
                          <TableCell align="center">
                            {item.installedOn}
                          </TableCell>
                          <TableCell align="center">
                            {item.checksum}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Database;

interface DatabaseResultProps {
  showPropup: boolean;
  closePopup: () => void;
  data: DatabaseMigration | null;
}

const DatabaseResult: React.FunctionComponent<DatabaseResultProps> = (
  props
) => {
  return (
    <Dialog
      open={props.showPropup}
      aria-labelledby="migration-results"
      aria-describedby="migration-results-description"
    >
      <DialogTitle id="migration-results">Results</DialogTitle>
      <DialogContent>
        {props.data?.resultItems.map((item) => (
          <Grid container key={item.id}>
            <Grid item xs={11}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "primary.dark" }}
                  >
                    {item.server} / {item.database}:{" "}
                    {item.migrationSucceeded ? "Success" : "Error"}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {!item.migrationSucceeded && (
                    <Alert severity="error">{item.error}</Alert>
                  )}
                  {item.appliedMigrations.length > 0 && (
                    <TableContainer component={Paper}>
                      <Table aria-label="database table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">
                              Applied Scripts
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {item.appliedMigrations.map((item) => (
                            <TableRow key={item}>
                              <TableCell align="center">{item}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => props.closePopup()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
