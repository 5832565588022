import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { ICellRendererParams } from "ag-grid-community";
import { Box, Grid } from "@mui/material";
import { UilUpload } from "@iconscout/react-unicons";
import { UilPlusCircle } from "@iconscout/react-unicons";
import CreateGLAccountTreeDialog from "./CreateGLAccountTreeDialog";

import {
  IGLTreeAccountingSystemSelectList,
  IGLTreeTypeSelectList,
} from "../../model/glTreeModel";
import {
  UseDownloadGLTreeDocuments,
  // useExportGLTreeDocument,
} from "../../api/glTreeService";
import { TDownloadDocumentModel } from "../../model/downloadDocument";
import fileSaver from "file-saver";

type Props = ICellRendererParams & {
  accountId: string;
  glAccountTreeTypes: IGLTreeTypeSelectList[];
  glAccountingSystems: IGLTreeAccountingSystemSelectList[];
  title: string;
  handleClose: (shouldRefresh: boolean) => void;
  onUploaded?: (msg: any) => void;
  onError?: (msg: any) => void;
  onErrorDownload?: () => void;
};

export const documentUploadSuccessMessage =
  "Your document was successfully uploaded.";
export const documentUploadErrorMessage =
  "Could not upload the document. Please try again later.";

const GLAccountTreeActionsRenderer: React.FC<Props> = (props) => {
  const {
    accountId,
    glAccountTreeTypes,
    glAccountingSystems,
    handleClose,
    onUploaded,
    onError,
    onErrorDownload,
  } = props;
  // const downloadDocuments = UseDownloadGLTreeDocuments();
  const [initialTitle] = useState<string>(props.data?.glAccountTreeName);
  const [initialAccountingSystem] = useState<string>(
    props.data?.accountingSystemId
  );
  const [initialTreeType] = useState<string>(props.data?.glAccountTreeTypeId);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  // const exportDocumentResponse = useExportGLTreeDocument();
  const dnldDocumentResponse = UseDownloadGLTreeDocuments();
  // const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const btnDownloadHandler = async () => {
    const model: TDownloadDocumentModel = {
      accountIdentifier: accountId,
      treeId: props.data?.glAccountTreeId,
    };
    // const documentToDownload = await downloadDocuments.mutateAsync(model);

    //    if (documentToDownload !== "") {
    // if (documentToDownload === "") {
    // window.open(documentToDownload);
    // fileSaver.saveAs(documentToDownload, "test.xlsx");
    // } else {
    // get generated excel
    // setIsLoadingExcel(true);
    setError(null);
    // await exportDocumentResponse.mutateAsync(model, {
    await dnldDocumentResponse.mutateAsync(model, {
      onSuccess: (data: any) => {
        // const date = new Date();
        // const nowYear = date.getFullYear();
        // const nowMonth = date.getMonth() + 1;
        // const nowDay = date.getDate();
        const fileName =
          props.data?.glAccountTreeName +
          " - " +
          props.data?.accountingSystemTitle +
          // nowYear +
          // "-" +
          // (nowMonth > 9 ? nowMonth : "0" + nowMonth) +
          // "-" +
          // (nowDay > 9 ? nowDay : "0" + nowDay) +
          ".csv";
        fileSaver.saveAs(data.data, fileName);
        // setIsLoadingExcel(false);
      },
      onError: (message: any) => {
        // setIsLoadingExcel(false);
        if (onErrorDownload) onErrorDownload();
      },
    });
    // }
  };

  const handleDialogClose = (shouldRefresh: boolean) => {
    handleClose(shouldRefresh);
    setOpenEditDialog(false);
  };

  return (
    <React.Fragment>
      {openEditDialog &&
        glAccountTreeTypes &&
        glAccountingSystems &&
        accountId.trim().length > 0 && (
          <CreateGLAccountTreeDialog
            open={true}
            handleClose={handleDialogClose}
            accountId={accountId}
            treeId={props.data?.glAccountTreeId}
            editMode={true}
            glAccountTreeTypes={glAccountTreeTypes}
            glAccountingSystems={glAccountingSystems}
            initialTitle={initialTitle}
            initialAccountingSystem={initialAccountingSystem}
            initialTreeType={initialTreeType}
            // glAccountTreeTypes={[
            //   { key: 0, value: "value1" },
            //   { key: 1, value: "value2" },
            // ]}
            onUploaded={onUploaded}
            onError={onError}
          />
        )}
      <Grid container spacing={2}>
        <Grid
          item
          sx={{
            "& svg": {
              maxHeight: "20px",
              maxWidth: "20px",
              lineHeight: "1",
            },
          }}
        >
          <Tooltip title="Export" style={{ cursor: "pointer" }}>
            <Box
              onClick={btnDownloadHandler}
              className={"icon-documents"}
              aria-label="exoirt"
            >
              <UilUpload />
            </Box>
          </Tooltip>
        </Grid>
        <Grid
          item
          sx={{
            "& svg": {
              maxHeight: "20px",
              maxWidth: "20px",
              lineHeight: "1",
            },
          }}
        >
          <Tooltip title="Upload" style={{ cursor: "pointer" }}>
            <Box
              onClick={() => setOpenEditDialog(true)}
              className={"icon-documents"}
              aria-label="upload"
            >
              <UilPlusCircle />
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default GLAccountTreeActionsRenderer;
