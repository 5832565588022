export type StackingPlan = {
  buildings: StackingBuilding[];
  leaseSummary: StackingLeaseSummary[];
  header: StackingHeader;
};

export type StackingBuilding = {
  buildingTitle: string;
  buildingRsf: number;
  percentageLeased: number;
  numberOfSuites: number;
  numberOfTenants: number;
  floors: StackingFloor[];
  leaseSummary: StackingLeaseSummary[];
};

export type StackingFloor = {
  floorName: string;
  floorRsf: number;
  spaces: StackingSpace[];
};

export type StackingSpace = {
  id: string;
  spaceCode: string;
  tenantTitle: string;
  rentableArea: number;
  leaseFrom: string;
  leaseTo: string;
  executedDate: string;
  leaseId: string;
  leaseExpirationTerm: string;
  floorPercentage: number;
  isFutureLease: boolean;
  monthlyAmount: number;
};

export type StackingLeaseSummary = {
  title: string;
  area: number;
  percentageOfArea: number;
  unitsExpiring: number;
  percentageOfUnitsExpiring: number;
  leaseExpirationTerm: string;
};

type StackingHeader = {
  numberOfSuites: number;
  rentableArea: number;
  numberOfTenants: number;
  occupancyPercentage: number;
  leasedPercentage: number;
};

export enum LeaseExpirationTerm {
  Current = 0,
  Year1 = 1,
  Year2 = 2,
  Year3 = 3,
  Year4Plus = 4,
  MonthToMonth = 9999,
  Vacant,
}
