import axios, { AxiosInstance } from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import {
  AccountIdentifierHeaderName,
  useAuthorizedApiAxios,
} from "../../../../shared/api/authorizedAxios";
import {
  LoanDateFilter,
  LoanDeleteProps,
  LoansData,
} from "../model/LoansModel";

export interface LoansServiceProps {
  propertyId: string;
  accountIdentifier: string;
}

export interface LoanServiceProps {
  propertyId: string;
  loanId: string;
  accountIdentifier: string;
}

export const useGetLoans = (filter: LoanDateFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();
  const uniqueId =
    filter.propertyId +
    filter.monthYearFilter.month +
    filter.monthYearFilter.year;
  return useQuery<LoansData | undefined>(["getAllLoans", uniqueId], () =>
    getLoans(filter, authorizedApiAxios)
  );
};

const getLoans = async (
  filter: LoanDateFilter,
  authorizedApiAxios: () => AxiosInstance
) => {
  const source = axios.CancelToken.source();

  const monthYear = queryString.stringify(filter.monthYearFilter);
  const qry = monthYear;
  const url = `/AssetService/v1/Properties/${filter.propertyId}/Loans?${qry}`;

  const requiredFilters = ["month", "year"];
  const reqfilters = queryString.parse(monthYear);
  const containsRequiredFilters = requiredFilters.every((s) => {
    if (s in reqfilters) {
      return (
        reqfilters[s] != null && (reqfilters[s] + "").toString().length > 0
      );
    } else return false;
  });
  if (filter.propertyId === null || !containsRequiredFilters) return;

  let promise = authorizedApiAxios().get<LoansData>(url, {
    cancelToken: source.token,
    headers: {
      [AccountIdentifierHeaderName]: filter?.accountIdentifier,
    },
  });

  (promise as any).cancel = () => source.cancel();
  return await promise.then((response) => {
    const newLoans = response.data?.loans.slice();

    if (Array.isArray(newLoans)) {
      newLoans.sort((loanA, loanB) => {
        return (
          new Date(loanB.originationDate).valueOf() -
          new Date(loanA.originationDate).valueOf()
        );
      });
      response.data.loans = newLoans;
    }
    return response.data;
  });
};

export const useGetLoan = (loanProps: LoanServiceProps) => {
  const authorizedApiAxios = useAuthorizedApiAxios();
  const uniqueId =
    loanProps.loanId + loanProps.accountIdentifier + loanProps.propertyId;
  return useQuery<string | undefined>(["getLoan", uniqueId], () =>
    getLoan(loanProps, authorizedApiAxios)
  );
};

const getLoan = async (
  loanProps: LoanServiceProps,
  authorizedApiAxios: () => AxiosInstance
) => {
  const source = axios.CancelToken.source();
  const url = `/AssetService/v1/Properties/${loanProps.propertyId}/Loans/${loanProps.loanId}`;

  let promise = authorizedApiAxios().get<string>(url, {
    cancelToken: source.token,
    headers: {
      [AccountIdentifierHeaderName]: loanProps.accountIdentifier,
    },
  });

  (promise as any).cancel = () => source.cancel();
  return await promise.then((response) => response.data);
};

export const useDeleteLoan = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: LoanDeleteProps) => {
    const source = axios.CancelToken.source();

    const url = `assetService/v1/Loans/${item.loanId}`;
    let promise = authorizedApiAxios().delete<string>(url, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: item.accountId,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};
