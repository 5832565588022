import React from "react";
import { useForm } from "react-hook-form";
import { useIsFetching } from "@tanstack/react-query";
import {
  Box,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  IconButton,
} from "@mui/material";

import { IPersonnelService } from "../model/propertyOverviewModel";
import { useSavePersonnel } from "../api/propertyOverviewService";
import { cleanupPersonnel } from "../util/cleanUpProperties";
import { maxTextField, textErrorMessage } from "../util/errorTexts";
import { UilMultiply } from "@iconscout/react-unicons";

const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

interface Props {
  handlePopupClose: (shouldRefreshData?: boolean) => void;
  showDialog: boolean;
  personnel: IPersonnelService;
}

const PersonnelEdit: React.FC<Props> = (props) => {
  const { personnel } = props;
  const isFetching = useIsFetching();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IPersonnelService>({
    defaultValues: {
      assetManagementCompany: personnel.assetManagementCompany,
      assetManagerName: personnel.assetManagerName,
      assetManagerContactEmail: personnel.assetManagerContactEmail,
      propertyManagementCompany: personnel.propertyManagementCompany,
      propertyManagerContactEmail: personnel.propertyManagerContactEmail,
      propertyManagerName: personnel.propertyManagerName,
      leasingManagementCompany: personnel.leasingManagementCompany,
      leasingManagerContactEmail: personnel.leasingManagerContactEmail,
      leasingManagerName: personnel.leasingManagerName,
      engineeringHVACManagementCompany:
        personnel.engineeringHVACManagementCompany,
      engineeringHVACManagerContactEmail:
        personnel.engineeringHVACManagerContactEmail,
      engineeringHVACManagerName: personnel.engineeringHVACManagerName,
    },
  });

  const patchPersonnel = useSavePersonnel();

  const onSubmit = handleSubmit((model: IPersonnelService) => {
    model.accountId = personnel.accountId;
    model.propertyId = personnel.propertyId;
    cleanupPersonnel(model);
    patchPersonnel.mutate(model, {
      onSuccess: () => {
        props.handlePopupClose(true);
      },
    });
  });

  return (
    <Dialog
      open={props.showDialog}
      aria-labelledby="personnel-edit"
      aria-describedby="personnel-edit-description"
      fullWidth
    >
      <DialogTitle id="personnel-edit">
        <Typography component="span" variant="h2" color="primary.dark">
          Edit Personnel
        </Typography>
        <IconButton
          aria-label="close"
          disabled={patchPersonnel.isLoading}
          onClick={() => props.handlePopupClose(false)}
          className="icon-dialog-close"
          size="large"
        >
          <UilMultiply />
        </IconButton>
        <Typography component="div" variant="h3" color="neutral700.light">
        Please enter values for the fields and click the save button to update personnel information.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <React.Fragment>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={1}>
              {/* Asset Management Company */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Asset Management Company
                  </Typography>
                  <TextField
                    size="small"
                    id="asset-management-company"
                    variant="outlined"
                    {...register("assetManagementCompany", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.assetManagementCompany)}
                    helperText={
                      Boolean(errors?.assetManagementCompany)
                        ? errors?.assetManagementCompany?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Asset Manager Name */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Asset Manager Name
                  </Typography>
                  <TextField
                    size="small"
                    id="asset-manager-name"
                    variant="outlined"
                    {...register("assetManagerName", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.assetManagerName)}
                    helperText={
                      Boolean(errors?.assetManagerName)
                        ? errors?.assetManagerName?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Asset Manager Contact Email */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Asset Manager Contact Email
                  </Typography>
                  <TextField
                    size="small"
                    id="asset-manager-email"
                    variant="outlined"
                    {...register("assetManagerContactEmail", {
                      pattern: {
                        value: emailPattern,
                        message: textErrorMessage,
                      },
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.assetManagerContactEmail)}
                    helperText={
                      Boolean(errors?.assetManagerContactEmail)
                        ? errors?.assetManagerContactEmail?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Property Management Company */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Property Management Company
                  </Typography>
                  <TextField
                    size="small"
                    id="property-management-company"
                    variant="outlined"
                    {...register("propertyManagementCompany", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.propertyManagementCompany)}
                    helperText={
                      Boolean(errors?.propertyManagementCompany)
                        ? errors?.propertyManagementCompany?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Property Manager Name */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Property Manager Name
                  </Typography>
                  <TextField
                    size="small"
                    id="property-manager-name"
                    variant="outlined"
                    {...register("propertyManagerName", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.propertyManagerName)}
                    helperText={
                      Boolean(errors?.propertyManagerName)
                        ? errors?.propertyManagerName?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Property Manager Contact Email */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Property Manager Contact Email
                  </Typography>
                  <TextField
                    size="small"
                    id="property-manager-email"
                    variant="outlined"
                    {...register("propertyManagerContactEmail", {
                      pattern: {
                        value: emailPattern,
                        message: textErrorMessage,
                      },
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.propertyManagerContactEmail)}
                    helperText={
                      Boolean(errors?.propertyManagerContactEmail)
                        ? errors?.propertyManagerContactEmail?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Leasing Management Company */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Leasing Management Company
                  </Typography>
                  <TextField
                    size="small"
                    id="leasing-management-company"
                    variant="outlined"
                    {...register("leasingManagementCompany", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.leasingManagementCompany)}
                    helperText={
                      Boolean(errors?.leasingManagementCompany)
                        ? errors?.leasingManagementCompany?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Leasing Manager Name */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Leasing Manager Name
                  </Typography>
                  <TextField
                    size="small"
                    id="leasing-manager-name"
                    variant="outlined"
                    {...register("leasingManagerName", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.leasingManagerName)}
                    helperText={
                      Boolean(errors?.leasingManagerName)
                        ? errors?.leasingManagerName?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Leasing Manager Contact Email */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Leasing Manager Contact Email
                  </Typography>
                  <TextField
                    size="small"
                    id="leasing-manager-email"
                    variant="outlined"
                    {...register("leasingManagerContactEmail", {
                      pattern: {
                        value: emailPattern,
                        message: textErrorMessage,
                      },
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.leasingManagerContactEmail)}
                    helperText={
                      Boolean(errors?.leasingManagerContactEmail)
                        ? errors?.leasingManagerContactEmail?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Engineering/HVAC Company */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Engineering/HVAC Company
                  </Typography>
                  <TextField
                    size="small"
                    id="engineering-hvac-company"
                    variant="outlined"
                    {...register("engineeringHVACManagementCompany", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.engineeringHVACManagementCompany)}
                    helperText={
                      Boolean(errors?.engineeringHVACManagementCompany)
                        ? errors?.engineeringHVACManagementCompany?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Engineering/HVAC Name */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Engineering/HVAC Name
                  </Typography>
                  <TextField
                    size="small"
                    id="engineering-hvac-name"
                    variant="outlined"
                    {...register("engineeringHVACManagerName", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.engineeringHVACManagerName)}
                    helperText={
                      Boolean(errors?.engineeringHVACManagerName)
                        ? errors?.engineeringHVACManagerName?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Engineering/HVAC Contact Email */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Engineering/HVAC Contact Email
                  </Typography>
                  <TextField
                    size="small"
                    id="engineering-hvac-contact-email"
                    variant="outlined"
                    {...register("engineeringHVACManagerContactEmail", {
                      pattern: {
                        value: emailPattern,
                        message: textErrorMessage,
                      },
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.engineeringHVACManagerContactEmail)}
                    helperText={
                      Boolean(errors?.engineeringHVACManagerContactEmail)
                        ? errors?.engineeringHVACManagerContactEmail?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </React.Fragment>
        {patchPersonnel.isLoading && (
          <Box m="auto">
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          disabled={patchPersonnel.isLoading}
          onClick={() => props.handlePopupClose(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={patchPersonnel.isLoading || isFetching > 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PersonnelEdit;
