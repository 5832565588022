import { ColDef } from "ag-grid-community";
import {
  currencyFormatter,
  dateFilterParams,
  dateValueFormatter,
  dateValueGetter,
  stringSortCaseInsensitive,
} from "../../util/gridUtils";
import { OpenTenant, Tenant } from "../model/tenant";

export const getPropertyArColumns = () => {
  const columnDefs: ColDef[] = [
    {
      headerName: "Notes",
      field: "hasNotes",
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        cellRenderer: 'noteFilterRenderer'
      },
      filterValueGetter: params => {
        const col = params.column.getColId();
        return params.data[col].toString();
      }  ,
      cellRenderer: "viewNotesRenderer",
      minWidth: 50,
    },   
    {
      headerName: "Tenant",
      field: "tenant",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: "agGroupCellRenderer",
      minWidth: 50,
      comparator: stringSortCaseInsensitive,
    },
    {
      headerName: "Last Payment Date",
      field: "lastPaymentDate",
      cellClass: "dateFormat",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params) =>
        dateValueFormatter(params.data.lastPaymentDate),
      valueGetter: (params: any) =>
        dateValueGetter(params.data.lastPaymentDate),
      minWidth: 50,
      // getQuickFilterText:(params: any) =>dateGetQuickFilterText(dateValueFormatter(params.data.lastPaymentDate))
    },
    {
      headerName: "Prepaid",
      field: "prepaid",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      minWidth: 50,
      valueFormatter: (params) => {
        return params.data.prepaid === undefined
          ? params.data.prepaid
          : `${currencyFormatter(params.data.prepaid, "$")}`;
      },
      // getQuickFilterText:(params: any) => currencyFormatter(params.data.prepaid, "$") + ' ' + currencyFormatter(params.data.prepaid, "$").replace(/,/g, '')   + " " +  currencyFormatter(params.data.prepaid, "$").replace(/,/g, '').split("$").join("") + " " +  currencyFormatter(params.data.prepaid, "$").split("$").join(""),
    },
    {
      headerName: "0 - 30 Days",
      field: "thirtyDays",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      minWidth: 50,
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.thirtyDays, "$"),
      // getQuickFilterText:(params: any) => currencyFormatter(params.data.thirtyDays, "$") + ' ' + currencyFormatter(params.data.thirtyDays, "$").replace(/,/g, '')  + " " +    currencyFormatter(params.data.thirtyDays, "$").replace(/,/g, '').split("$").join("") + " " +  currencyFormatter(params.data.thirtyDays, "$").split("$").join(""),
      },
    {
      headerName: "31 - 60 Days",
      field: "sixtyDays",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      minWidth: 50,
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.sixtyDays, "$"),
      // getQuickFilterText:(params: any) => currencyFormatter(params.data.sixtyDays, "$") + ' ' + currencyFormatter(params.data.sixtyDays, "$").replace(/,/g, '')  + " " +   currencyFormatter(params.data.sixtyDays, "$").replace(/,/g, '').split("$").join("") + " " +  currencyFormatter(params.data.sixtyDays, "$").split("$").join(""),
      },
    {
      headerName: "61 - 90 Days",
      field: "ninetyDays",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      minWidth: 50,
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.ninetyDays, "$"),
      // getQuickFilterText:(params: any) => currencyFormatter(params.data.ninetyDays, "$") + ' ' + currencyFormatter(params.data.ninetyDays, "$").replace(/,/g, '')  + " " +   currencyFormatter(params.data.ninetyDays, "$").replace(/,/g, '').split("$").join("") + " " +  currencyFormatter(params.data.ninetyDays, "$").split("$").join(""),
    },
    {
      headerName: "90+ Days",
      field: "ninetyPlusDays",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      minWidth: 50,
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.ninetyPlusDays, "$"),
      // getQuickFilterText:(params: any) => currencyFormatter(params.data.ninetyPlusDays, "$") + ' ' + currencyFormatter(params.data.ninetyPlusDays, "$").replace(/,/g, '') + " " +    currencyFormatter(params.data.ninetyPlusDays, "$").replace(/,/g, '').split("$").join("") + " " +  currencyFormatter(params.data.ninetyPlusDays, "$").split("$").join(""),
    },
    {
      headerName: "Total Balance",
      field: "totalBalance",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      minWidth: 50,
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.totalBalance, "$"),
      // getQuickFilterText:(params: any) => currencyFormatter(params.data.totalBalance, "$") + ' ' + currencyFormatter(params.data.totalBalance, "$").replace(/,/g, '')  + " " +    currencyFormatter(params.data.totalBalance, "$").replace(/,/g, '').split("$").join("") + " " +  currencyFormatter(params.data.totalBalance, "$").split("$").join(""),
    },
    // { field: "", filter: false, sortable: false, minWidth: 50 },
    // this column must be added to be included in the quick filter
    // {field: 'searchableOrDetailRows', hide: true}
  ];
  return columnDefs;
};

// const dateGetQuickFilterText = (params:any) => {
//   return params.toString();
// };


export const getTenantRows = () => {
  const rows: Tenant[] = [
    {
      tenant: "Superior Products, INC.",
      tenantId: "12345",
      lastPaymentDate: new Date(),
      prepaid: 5000,
      thirtyDays: 6400,
      sixtyDays: 3500,
      ninetyDays: 2000,
      ninetyPlusDays: 1000,
      totalBalance: 17900,
      receipts: getOpenTenants(),
      charges: getOpenTenants(),
      hasNotes: true
    },
    {
      tenant: "Superior Products, INC.",
      tenantId: "123456",
      lastPaymentDate: new Date(),
      prepaid: 5000,
      thirtyDays: 6400,
      sixtyDays: 3500,
      ninetyDays: 2000,
      ninetyPlusDays: 1000,
      totalBalance: 17900,
      receipts: getOpenTenants(),
      charges: getOpenTenants(),
      hasNotes: true
    },
    {
      tenant: "Superior Products, INC.",
      tenantId: "1234567",
      lastPaymentDate: new Date(),
      prepaid: 5000,
      thirtyDays: 6400,
      sixtyDays: 3500,
      ninetyDays: 2000,
      ninetyPlusDays: 1000,
      totalBalance: 17900,
      receipts: getOpenTenants(),
      charges: getOpenTenants(),
      hasNotes: true
    },
  ];

  return rows;
};

const getOpenTenants = () => {
  const openReceipts: OpenTenant[] = [
    {
      code: "221",
      chargeTypeCode: "DUE",
      chargeTypeName: "Due To Name",
      arDate: new Date(),
      description: "Lorem ipsum",
      thirtyDays: 5000,
      sixtyDays: 4000,
      ninetyDays: 15000,
      ninetyPlusDays: 25000,
      totalBalance: 17900,
    },
    {
      code: "222",
      chargeTypeCode: "DUE",
      chargeTypeName: "Due To Name",
      arDate: new Date(),
      description: "Lorem ipsum",
      thirtyDays: 5000,
      sixtyDays: 4000,
      ninetyDays: 15000,
      ninetyPlusDays: 25000,
      totalBalance: 17900,
    },
    {
      code: "223",
      chargeTypeCode: "DUE",
      chargeTypeName: "Due To Name",
      arDate: new Date(),
      description: "Lorem ipsum",
      thirtyDays: 5000,
      sixtyDays: 4000,
      ninetyDays: 15000,
      ninetyPlusDays: 25000,
      totalBalance: 17900,
    },
  ];

  return openReceipts;
};
