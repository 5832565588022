import { ColDef } from "ag-grid-community";
import {
  negativeNumCellStyle,
  numberFormatter,
} from "../../../properties/util/gridUtils";

export const getPortfolioLeasingOverviewColumns = () => {
  const columnDefs: ColDef[] = [
    {
      headerName: "Property",
      field: "propertyName",
      sortable: false,
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        if (params.data.propertyName === undefined) return "";
        return params.data.propertyName;
      },
      cellRenderer: "linkComponent",
    },
    {
      headerName: "Total RSF",
      field: "rentableArea",
      sortable: false,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "decimalPlaces"],
      valueFormatter: (params: any) =>
        numberFormatter(params.data.rentableArea, 0) || "-",
      valueGetter: (params: any) => params.data.rentableArea?.toFixed(0) || "",
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Leased Area",
      field: "leaseArea",
      sortable: false,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "decimalPlaces"],
      valueFormatter: (params: any) =>
        numberFormatter(params.data.leaseArea, 0) || "-",
      valueGetter: (params: any) => params.data.leaseArea?.toFixed(0),
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Vacant Area",
      field: "vacantArea",
      sortable: false,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "decimalPlaces"],
      valueFormatter: (params: any) =>
        numberFormatter(params.data.vacantArea, 0) || "-",
      valueGetter: (params: any) => params.data.vacantArea?.toFixed(0),
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Leased (%)",
      field: "leasedPercentage",
      sortable: false,
      filter: "agNumberColumnFilter",
      type: "numericColumn",
      valueFormatter: (params: any) =>
        params.data.leasedPercentage
          ? (params.data.leasedPercentage * 100).toFixed(1) + "%"
          : "-",
      valueGetter: (params: any) =>
        params.data.leasedPercentage
          ? (params.data.leasedPercentage * 100).toFixed(1)
          : null,
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Wale (Yrs)",
      field: "wale",
      sortable: false,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params: any) => params.data.wale?.toFixed(2) || "-",
      valueGetter: (params: any) => params.data.wale?.toFixed(2),
    },
  ];
  return columnDefs;
};
