import React, { useEffect, useState, useMemo } from "react";
import { useIsFetching } from "@tanstack/react-query";
import {
  Box,
  CircularProgress,
  FormControl,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { UilPen } from "@iconscout/react-unicons";
import { UilTrashAlt } from "@iconscout/react-unicons";
import RadioGroup from "@mui/material/RadioGroup";
import { UilCheck } from "@iconscout/react-unicons";
import { UilTimes } from "@iconscout/react-unicons";

import { GridConfiguration } from "../model/gridConfig";
import { GRID_CONFIG_DEFAULT_VIEW } from "./SaveGridStateOptions";
import {
  useDeleteGridConfig,
  usePutGridConfig,
} from "../api/pageConfigService";
import {
  requiredErrorMessage,
  textErrorMessage,
} from "../../components/properties/property details/util/errorTexts";
import { computeDefaultGrid } from "../util/isDefaultGridUtil";
import {
  GridConfigIds,
  PageConfigIds,
} from "../../constants/GridPageConfigurationIds";
import DialogTitleCustom from "../../components/UI/DialogTitleCustom";
import { viewNameExistsErrorMessage } from "./CreateGridConfigModal";

const iconStyles = { cursor: "pointer" };
const iconSize = "24";

type GridInfo = {
  isInEditMode: boolean;
  // state after the edit has been finished and the user clicks on "tick" mark
  newName: string;
  // state used while editing
  editName: string;
  hasBeenEdited: boolean;
  hasBeenDeleted: boolean;
};

type ManageGridConfig = GridInfo & GridConfiguration;

enum MutateType {
  EDIT_MODE = "edit mode",
  EDIT_NAME = "edit name",
  DELETE = "delete",
}

interface Props {
  handlePopupClose: (shouldRefreshData?: boolean) => void;
  currentlySelectedConfigDeleted: (
    deletedConfigsIds: string[],
    newDefaultConfigId: string
  ) => void;
  showDialog: boolean;
  gridConfigs?: GridConfiguration[];
  accountId?: string;
  gridId?: GridConfigIds;
  pageId?: PageConfigIds;
}

const ManageViewsModal: React.FC<Props> = (props) => {
  const saveGrid = usePutGridConfig();
  const deleteGrid = useDeleteGridConfig();
  const isFetching = useIsFetching();

  const originalDefaultGrid = useMemo(
    () => computeDefaultGrid(props.gridConfigs),
    [props.gridConfigs]
  );

  const gridNames = useMemo(() => {
    if (props.gridConfigs)
      return props.gridConfigs.map((gridConfig) =>
        gridConfig.name.trim().toLowerCase()
      );
    return [];
  }, [props.gridConfigs]);

  const [gridConfigs, setGridConfigs] = useState<ManageGridConfig[]>([]);
  const [radioValue, setRadioValue] = useState(() =>
    computeDefaultGrid(props.gridConfigs)
  );

  const [selectedViewDeleted, setSelectedViewDeleted] = useState(false);

  useEffect(() => {
    if (!selectedViewDeleted) return;
    setRadioValue(GRID_CONFIG_DEFAULT_VIEW);
    setSelectedViewDeleted(false);
  }, [selectedViewDeleted]);

  useEffect(() => {
    if (!!props.gridConfigs && props.gridConfigs.length > 0) {
      const newGridConfigs: ManageGridConfig[] = [];
      props.gridConfigs.forEach((gridConfig) => {
        newGridConfigs.push({
          ...gridConfig,
          isInEditMode: false,
          newName: gridConfig.name,
          editName: gridConfig.name,
          hasBeenEdited: false,
          hasBeenDeleted: false,
        });
      });
      setGridConfigs(newGridConfigs);
    }
  }, [props.gridConfigs]);

  const onSubmit = async () => {
    const { pageId, gridId, accountId } = props;
    if (pageId === undefined || gridId === undefined || accountId === undefined)
      return;

    const hasDefaultChanged = originalDefaultGrid !== radioValue;
    let shouldRefreshData = false;
    const deletedConfigsIds: string[] = [];

    // Looping over all grid configs to either edit, delete or do nothing to them and eventually closing the modal
    for (let gridConfig of gridConfigs) {
      const hasDefaultChangedToCurrentGrid =
        hasDefaultChanged && radioValue === gridConfig.id.toString();
      const hasDefaultChangedToStandardFromCurrent =
        hasDefaultChanged &&
        radioValue === GRID_CONFIG_DEFAULT_VIEW &&
        gridConfig.isDefault;
      // if the grid has been deleted
      if (gridConfig.hasBeenDeleted) {
        const result = await deleteGrid.mutateAsync({
          pageId: pageId,
          accountId: accountId,
          gridIdentifier: gridId,
          pageGridConfigurationId: gridConfig.id,
        });
        if (result.status === 200) {
          deletedConfigsIds.push(gridConfig.id.toString());
          shouldRefreshData = true;
        }
      }
      // if the grid's name has been edited or if the grid's isDefault value has been changed to true
      // or if the standard view is set as default (in which case we unset the current default view)
      else if (
        gridConfig.hasBeenEdited ||
        hasDefaultChangedToCurrentGrid ||
        hasDefaultChangedToStandardFromCurrent
      ) {
        const result = await saveGrid.mutateAsync({
          pageId: pageId,
          accountId: accountId,
          gridIdentifier: gridId,
          pageGridConfigId: gridConfig.id,
          gridConfig: {
            name: gridConfig.hasBeenEdited
              ? gridConfig.newName.trim()
              : gridConfig.name,
            isDefault: hasDefaultChangedToCurrentGrid
              ? true
              : hasDefaultChangedToStandardFromCurrent
              ? false
              : gridConfig.isDefault,
            columnState: gridConfig.columnState,
            columnGroupState: gridConfig.columnGroupState,
            columnFilterState: gridConfig.columnFilterState,
          },
        });
        if (result.status === 200) shouldRefreshData = true;
      }
    }
    props.handlePopupClose(shouldRefreshData);
    props.currentlySelectedConfigDeleted(deletedConfigsIds, radioValue);
  };

  const mutateGridConfigs = (
    mutateType: MutateType,
    mutateVal: boolean | string,
    currentGridId: number
  ) => {
    setGridConfigs((prevGridConfigs) => {
      const slicedGridConfigs = prevGridConfigs.slice();
      const gridIndex = slicedGridConfigs.findIndex(
        (slicedGridConfig) => slicedGridConfig.id === currentGridId
      );
      if (gridIndex > -1) {
        if (
          mutateType === MutateType.EDIT_MODE &&
          typeof mutateVal === "boolean"
        )
          slicedGridConfigs[gridIndex].isInEditMode = mutateVal;
        else if (MutateType.DELETE && typeof mutateVal === "boolean")
          slicedGridConfigs[gridIndex].hasBeenDeleted = mutateVal;
        else if (MutateType.EDIT_NAME && typeof mutateVal === "string")
          slicedGridConfigs[gridIndex].editName = mutateVal;
      }

      return slicedGridConfigs;
    });
  };

  const gridConfigsLoaded = !!props.gridConfigs;

  let gridBeingEdited = false;

  for (const gridConfig of gridConfigs) {
    if (gridConfig.isInEditMode) {
      gridBeingEdited = true;
      break;
    }
  }

  return (
    <Dialog
      open={props.showDialog}
      aria-labelledby="asset-overview-edit"
      aria-describedby="asset-overview-edit-description"
      fullWidth
    >
      <DialogTitleCustom
        title="Manage Views"
        onClose={() => props.handlePopupClose(false)}
      />
      <DialogContent style={{ padding: 0 }}>
        <DialogContentText>
          <Typography component="div" variant="h3" color="neutral700.light">
            Set default view.
          </Typography>
        </DialogContentText>
        {!!props.gridConfigs && (
          <RadioGroup
            aria-labelledby="grid-radio-buttons"
            name="radio-buttons-group"
            value={radioValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setRadioValue((event.target as HTMLInputElement).value);
            }}
            style={{ paddingLeft: "11px" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                marginBottom: "10px",
                paddingBottom: "10px",
                borderBottom: "1px solid var(--neutral-grey-150)",
              }}
            >
              <FormControlLabel
                value={GRID_CONFIG_DEFAULT_VIEW}
                control={<Radio size="small" />}
                label={
                  <Typography variant="h3" color="primary.dark">
                    Standard
                  </Typography>
                }
              />
            </Box>
            {gridConfigs.map((gridConfig) => {
              const isGridNameEmpty = gridConfig.editName.trim().length === 0;
              const isGridNameTooLong = gridConfig.editName.trim().length > 50;
              let errorMessage = isGridNameEmpty
                ? requiredErrorMessage
                : isGridNameTooLong
                ? textErrorMessage
                : "";

              if (
                gridNames
                  .filter(
                    (gridName) =>
                      gridName !== gridConfig.name.trim().toLowerCase()
                  )
                  .includes(gridConfig.editName.trim().toLowerCase())
              )
                errorMessage = viewNameExistsErrorMessage;
              const btnDisabledStyle =
                isGridNameEmpty || isGridNameTooLong || errorMessage !== ""
                  ? { cursor: "default", color: "#dddddd" }
                  : {};

              return (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  style={gridConfig.hasBeenDeleted ? { display: "none" } : {}}
                  key={gridConfig.id}
                  sx={{
                    marginBottom: "10px",
                    paddingBottom: "10px",
                    borderBottom: "1px solid var(--neutral-grey-150)",
                  }}
                >
                  {gridConfig.isInEditMode ? (
                    <React.Fragment>
                      <FormControl
                        className={"form-control"}
                        style={{ width: "85%" }}
                      >
                        <TextField
                          size="small"
                          variant="outlined"
                          type="text"
                          value={gridConfig.editName}
                          error={
                            isGridNameEmpty ||
                            isGridNameTooLong ||
                            errorMessage !== ""
                          }
                          helperText={errorMessage}
                          onChange={(event) =>
                            mutateGridConfigs(
                              MutateType.EDIT_NAME,
                              event.target.value,
                              gridConfig.id
                            )
                          }
                          className={"form-control-field"}
                        />
                      </FormControl>
                      <Box sx={{ whiteSpace: "nowrap", paddingLeft: "10px" }}>
                        <UilCheck
                          size={iconSize}
                          style={{ ...iconStyles, ...btnDisabledStyle }}
                          onClick={() => {
                            // Making sure the grid name isn't empty or too long before saving it.
                            if (
                              !isGridNameEmpty &&
                              !isGridNameTooLong &&
                              errorMessage === ""
                            )
                              setGridConfigs((prevGridConfigs) => {
                                const slicedGridConfigs =
                                  prevGridConfigs.slice();
                                const gridIndex = slicedGridConfigs.findIndex(
                                  (slicedGridConfig) =>
                                    slicedGridConfig.id === gridConfig.id
                                );
                                if (gridIndex > -1) {
                                  slicedGridConfigs[gridIndex].isInEditMode =
                                    false;
                                  slicedGridConfigs[gridIndex].hasBeenEdited =
                                    true;
                                  slicedGridConfigs[gridIndex].newName =
                                    slicedGridConfigs[gridIndex].editName;
                                }

                                return slicedGridConfigs;
                              });
                          }}
                        />
                        <UilTimes
                          size={iconSize}
                          style={{ ...iconStyles, marginLeft: "8px" }}
                          onClick={() => {
                            setGridConfigs((prevGridConfigs) => {
                              const slicedGridConfigs = prevGridConfigs.slice();
                              const gridIndex = slicedGridConfigs.findIndex(
                                (slicedGridConfig) =>
                                  slicedGridConfig.id === gridConfig.id
                              );
                              if (gridIndex > -1) {
                                slicedGridConfigs[gridIndex].editName =
                                  slicedGridConfigs[gridIndex].newName;
                                slicedGridConfigs[gridIndex].isInEditMode =
                                  false;
                              }

                              return slicedGridConfigs;
                            });
                          }}
                        />
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <FormControlLabel
                        value={gridConfig.id.toString()}
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="h3" color="primary.dark">
                            {gridConfig.newName}
                          </Typography>
                        }
                      />
                      <Box sx={{ whiteSpace: "nowrap", paddingLeft: "10px" }}>
                        <UilPen
                          size={iconSize}
                          style={{ ...iconStyles }}
                          onClick={() =>
                            mutateGridConfigs(
                              MutateType.EDIT_MODE,
                              true,
                              gridConfig.id
                            )
                          }
                          className={"icon icon-edit"}
                        />
                        <UilTrashAlt
                          size={iconSize}
                          style={{ ...iconStyles, marginLeft: "8px" }}
                          onClick={() => {
                            mutateGridConfigs(
                              MutateType.DELETE,
                              true,
                              gridConfig.id
                            );
                            const currentSelectedView = Number(radioValue);
                            const isCurrentSelectedViewDeleted = isNaN(
                              currentSelectedView
                            )
                              ? false
                              : currentSelectedView === gridConfig.id;
                            if (
                              !selectedViewDeleted &&
                              isCurrentSelectedViewDeleted
                            )
                              setSelectedViewDeleted(
                                isCurrentSelectedViewDeleted
                              );
                          }}
                          className={"icon icon-delete"}
                        />
                      </Box>
                    </React.Fragment>
                  )}
                </Box>
              );
            })}
          </RadioGroup>
        )}
        {(!gridConfigsLoaded || saveGrid.isLoading || deleteGrid.isLoading) && (
          <Box m="auto">
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          disabled={saveGrid.isLoading || deleteGrid.isLoading}
          onClick={() => props.handlePopupClose(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={
            !gridConfigsLoaded ||
            gridBeingEdited ||
            saveGrid.isLoading ||
            deleteGrid.isLoading ||
            isFetching > 0
          }
          onClick={onSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageViewsModal;
