import React, { Fragment, useRef } from "react";
import queryString from "query-string";
import { UseQueryResult } from "@tanstack/react-query";
import { Theme, Button, FormControl, InputLabel, Select, OutlinedInput, SelectChangeEvent } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { TBuildingList } from "../../building/model/buildingListModel";
import SpaceListSearchFilter from "../model/spaceListSearchFilter";

interface PropertyViewSpacesFilterProps {
  setFilter: (filter: string) => void;
  handlePopupOpen: (shouldBeOpened: boolean) => void;
  buildings: UseQueryResult<TBuildingList>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      width: "25%",
      borderRadius: 4,
    },
  })
);

const PropertyViewSpacesSearch: React.FunctionComponent<PropertyViewSpacesFilterProps> =
  (props) => {
    const classes = useStyles();
    const innerFilter = useRef<SpaceListSearchFilter>({
      buildingId: undefined,
    });
    const { data: buildingList, isLoading: isBuildingListLoading } =
      props.buildings;

    function setValueToFilterProperty(name: string, value: string) {
      innerFilter.current = {
        ...innerFilter.current,
        [name]: value,
      };
    }

    function handleChange(
      e: SelectChangeEvent
    ) {
      setValueToFilterProperty("buildingId", e.target.value);
      setFilter();
    }

    function setFilter() {
      const filter = queryString.stringify(innerFilter.current);
      props.setFilter(filter);
    }

    const SelectIcon = (props: any) => (
      <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
        <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
      </svg>
    );

    return isBuildingListLoading ? (
      <Fragment />
    ) : (
      <Fragment>
        <div
          id="property-spaces-action-bar"
          className="d-flex flex-row align-items-center bg-light border-bottom pt-2 pb-2"
        >
          <div id="main-actions" className="w-10 pr-1 pl-3">
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => props.handlePopupOpen(true)}
            >
              + Add Space
            </Button>
          </div>
          <div id="main-bar-filters" className="flex-fill pr-1 pl-1">
            <FormControl
              size="small"
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel htmlFor="buildingId" shrink>
                Building
              </InputLabel>
              <Select
                native
                onChange={handleChange}
                label="Building"
                defaultValue=""
                input={<OutlinedInput notched label="Building" />}
                IconComponent={SelectIcon}
              >
                <option value="">All Buildings</option>
                {buildingList &&
                  buildingList.map((bldg) => (
                    <option value={bldg.id} key={bldg.id}>
                      {bldg.title}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </Fragment>
    );
  };

export default PropertyViewSpacesSearch;
