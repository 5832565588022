import React, { useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { UseQueryResult } from "@tanstack/react-query";
import {
  Box,
  CircularProgress,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Theme,
  Typography,
  Button,
  Link,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import { useCreateSpace } from "../api/spaceService";
import {
  TBuildingList,
  TBuildingListModel,
} from "../../building/model/buildingListModel";
import { TCreateSpaceModel } from "../model/createSpaceModel";
import { TLeaseByPropertyList } from "../model/leaseByPropertyListModel";
import { formatDate } from "../../util/formatDate";
import { usePropertyInfo } from "../../util/propertyInfoContext";

interface PropertyInsertProps {
  handlePopupClose: (shouldRefreshData: boolean) => void;
  showInsertPopup: boolean;
  leases: UseQueryResult<TLeaseByPropertyList>;
  buildings: UseQueryResult<TBuildingList>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
      borderRadius: 4,
    },
    title: {
      borderBottom: "1px solid #ccc",
      marginBottom: 15,
    },
  })
);

const SpaceInsert: React.FunctionComponent<PropertyInsertProps> = (props) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    control,

    formState: { errors },
  } = useForm<TCreateSpaceModel>();
  const createSpaceResponse = useCreateSpace();
  const { data: buildingList, isLoading: isBuildingListLoading } =
    props.buildings;
  const propertyInfo = usePropertyInfo();

  const onSubmit = handleSubmit((model: TCreateSpaceModel) => {
    model.propertyId = propertyInfo.id;
    model.accountIdentifier = propertyInfo.accountIdentifier;
    model.leaseId = "" + model.leaseId === "" ? null : model.leaseId;
    createSpaceResponse.mutate(model, {
      onSuccess: (data) => {
        props.handlePopupClose(true);
      },
    });
  });

  const handleClose = useCallback(() => {
    props.handlePopupClose(false);
  }, [props]);

  const SelectIcon = (props: any) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  return (
    <Dialog
      open={props.showInsertPopup}
      onClose={handleClose}
      aria-labelledby="property-view-space-insert"
      aria-describedby="property-view-space-insert-description"
    >
      <DialogTitle id="property-view-space-insert" className={classes.title}>
        Add New Space
      </DialogTitle>
      <DialogContent>
        {isBuildingListLoading || props.leases.isLoading ? (
          <Box m="auto">
            <CircularProgress />
          </Box>
        ) : (
          <React.Fragment>
            <Typography>
              {`To create a new space, fill out the form below.  For additional details, view the help article on `}
              <Link
                component="button"
                variant="body2"
                color="primary"
                onClick={() => console.log("Clicked 'New Space'")}
              >
                {`Adding a New Space.`}
              </Link>
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                {buildingList && buildingList.length === 1 ? (
                  <FormControl
                    style={{ display: "none" }}
                    className={classes.formControl}
                  >
                    <TextField
                      id="buildingId"
                      value={buildingList[0].id}
                      {...register("buildingId")}
                    />
                  </FormControl>
                ) : (
                  <FormControl
                    size="small"
                    variant="outlined"
                    className={`${classes.formControl}`}
                    error={Boolean(errors.buildingId)}
                  >
                    <InputLabel id="buildingId" shrink>
                      Building
                    </InputLabel>
                    {/* buildingId */}
                    <Controller
                      control={control}
                      name={"buildingId"}
                      rules={{
                        required: "Building is required",
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <Select
                          labelId="buildingId"
                          label="Building"
                          displayEmpty
                          input={<OutlinedInput notched label="Building" />}
                          IconComponent={SelectIcon}
                          {...field}
                        >
                          <MenuItem value={""}>Select Building</MenuItem>
                          {buildingList &&
                            buildingList.map(
                              (building: TBuildingListModel, index: number) => (
                                <MenuItem key={`${index}`} value={building.id}>
                                  {building.title}
                                </MenuItem>
                              )
                            )}
                        </Select>
                      )}
                    />
                    {Boolean(errors.buildingId) && (
                      <FormHelperText>
                        {errors.buildingId?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    size="small"
                    id="floor"
                    label="Floor"
                    variant="outlined"
                    {...register("floor", {
                      required: "Floor is required",
                      maxLength: {
                        value: 100,
                        message: "Maximum length is 100",
                      },
                    })}
                    error={Boolean(errors?.floor)}
                    helperText={
                      Boolean(errors?.floor) ? errors?.floor?.message : null
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    size="small"
                    id="title"
                    label="Suite Name"
                    variant="outlined"
                    {...register("title", {
                      required: "Suite Name is required",
                      maxLength: {
                        value: 500,
                        message: "Maximum length is 500",
                      },
                    })}
                    error={Boolean(errors?.title)}
                    helperText={
                      Boolean(errors?.title) ? errors?.title?.message : null
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    size="small"
                    id="rsf"
                    type="number"
                    label="RSF"
                    variant="outlined"
                    {...register("rsf", {
                      required: "RSF is required",
                      min: {
                        value: 1,
                        message: "Minimum value is 1",
                      },
                      max: {
                        value: 2147483647,
                        message: "Maximum value is 2,147,483,647",
                      },
                    })}
                    error={Boolean(errors?.rsf)}
                    helperText={
                      Boolean(errors?.rsf) ? errors?.rsf?.message : null
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  error={Boolean(errors.isCommonArea)}
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        size="small"
                        color="primary"
                      />
                    }
                    {...register("isCommonArea")}
                    label="Is Common Area"
                  />
                  {Boolean(errors.isCommonArea) && (
                    <FormHelperText>
                      {errors.isCommonArea?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  error={Boolean(errors.hasEquipment)}
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        size="small"
                        color="primary"
                      />
                    }
                    {...register("hasEquipment")}
                    label="Has Equipment"
                  />
                  {Boolean(errors.hasEquipment) && (
                    <FormHelperText>
                      {errors.hasEquipment?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  error={Boolean(errors.isPrimary)}
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        size="small"
                        color="primary"
                      />
                    }
                    {...register("isPrimary")}
                    label="Is Primary"
                  />
                  {Boolean(errors.isPrimary) && (
                    <FormHelperText>{errors.isPrimary?.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={`${classes.formControl}`}
                  error={Boolean(errors.leaseId)}
                >
                  <InputLabel id="leaseId" shrink>
                    Lease
                  </InputLabel>
                  {/* leaseId */}
                  <Controller
                    control={control}
                    name={"leaseId"}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        labelId="leaseId"
                        label="Lease"
                        displayEmpty
                        input={<OutlinedInput notched label="Lease" />}
                        IconComponent={SelectIcon}
                        {...field}
                      >
                        <MenuItem value={""}>Vacant</MenuItem>
                        {props.leases.data &&
                          props.leases.data.map((lease, index: number) => (
                            <MenuItem key={`${index}`} value={lease.id}>
                              {`${lease.name}, ID#${
                                lease.externalId
                              }, Exp:${formatDate(lease.endDate + "")}`}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                  {Boolean(errors.leaseId) && (
                    <FormHelperText>{errors.leaseId?.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            {createSpaceResponse.isLoading && (
              <Box m="auto">
                <CircularProgress />
              </Box>
            )}
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={createSpaceResponse.isLoading}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SpaceInsert;
