import { useMemo, useState } from "react";

interface SortConfig{
    key: string;
    direction: string;
}

export const useSortableData = (items:any[], config?:SortConfig) => {
    const [sortConfig, setSortConfig] = useState<SortConfig>(config?config:{key:'',direction:'ascending'});
    const sortedItems = useMemo(() => {
      if (typeof items === 'undefined' || Object.entries(items).length === 0 ) return;
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);
  
    const requestSort = (key:string) => {
      let direction = 'ascending';
      if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    }
  
    return { items: sortedItems, requestSort };
}