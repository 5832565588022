import axios from "axios";
import { TSpaceListModel } from "../model/spaceListModel";
import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "../../../../shared/api/authorizedAxios";
import { TSpaceListFilter } from "../model/spaceListFilter";
import TLeasesByPropertyFilter from "../model/leaseListByPropertyFilter";
import { TLeaseByPropertyList } from "../model/leaseByPropertyListModel";
import { TCreateSpaceModel } from "../model/createSpaceModel";
import { useQuery, useMutation } from "@tanstack/react-query";
import SpaceBuildingGroup from "../model/spaceBuildingGroup";
import SpaceFloorGroup from "../model/spaceGroup";

export const useGetSpaceList = (filter: TSpaceListFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getSpaceList", filter],
    () => {
      const source = axios.CancelToken.source();

      const url: string = `/propertyService/v1/properties/${filter?.propertyId}/spaces?${filter.value}`;
      let promise = authorizedApiAxios().get<TSpaceListModel>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: filter?.accountIdentifier,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => {
        //Get distinct list of buildings
        type TBuilding = {
          buildingId: string;
          buildingTitle: string;
        };
        type TBuildingList = TBuilding[];

        const buildingList = [];
        const map = new Map();
        for (const item of response.data) {
          if (!map.has(item.buildingId)) {
            map.set(item.buildingId, true);
            buildingList.push({
              buildingId: item.buildingId,
              buildingTitle: item.buildingTitle,
            });
          }
        }

        const groupedFloorFiltered = (
          data: TSpaceListModel,
          filter: string
        ) => {
          let filtered = data.filter((ele) => ele.buildingId === filter);
          return filtered.reduce(
            (entryMap, e) =>
              entryMap.set(e.floor, [...(entryMap.get(e.floor) || []), e]),
            new Map()
          );
        };

        const groupFilterBy = function (data: TSpaceListModel, filter: string) {
          let spaceFloorGroupsArray2 = [];
          for (const [key, value] of groupedFloorFiltered(
            response.data,
            filter
          )) {
            spaceFloorGroupsArray2.push(new SpaceFloorGroup(value, key));
          }
          return spaceFloorGroupsArray2;
        };

        const getBuildingGroups = function (
          data: TBuildingList,
          key: string,
          key2: string
        ) {
          let spaceFloorGroups: SpaceBuildingGroup[] = [];
          data.forEach((ele) => {
            //get floor data per building
            let bldfloors = groupFilterBy(response.data, ele.buildingId);
            spaceFloorGroups.push(
              new SpaceBuildingGroup(
                bldfloors,
                ele.buildingTitle,
                ele.buildingId
              )
            );
          });
          return spaceFloorGroups;
        };

        const buildingGroups = getBuildingGroups(
          buildingList,
          "buildingId",
          "floor"
        );

        const data = {
          spaceBuildings: buildingGroups,
        };

        return data;
      },
    }
  );
};

export const useGetLeaseList = (filter?: TLeasesByPropertyFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getLeaseList", filter],
    () => {
      const source = axios.CancelToken.source();

      const url: string = `/propertyService/v1/properties/${filter?.PropertyId}/leases`;

      let promise = authorizedApiAxios().get<TLeaseByPropertyList>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: filter!.accountIdentifier,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => response!.data,
      enabled:
        filter !== undefined && filter !== null && filter.PropertyId !== null,
    }
  );
};

export const useCreateSpace = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: TCreateSpaceModel) => {
    const source = axios.CancelToken.source();
    const url: string = `propertyService/v1/properties/${item.propertyId}/buildings/${item.buildingId}/spaces`;

    let promise = authorizedApiAxios().post<string>(url, item, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: item.accountIdentifier,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};
