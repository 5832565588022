import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "../../../../shared/api/authorizedAxios";
import { PortfolioFinancialsFilter } from "../model/portfolioFinancialsFilter";

export const useGetPortfolioFinancialsData = (
  financialsApiParams: PortfolioFinancialsFilter
) => {
  const { portfolioId, accountIdentifier, monthYearFilter } =
    financialsApiParams;
  const { startYear, startMonth, endYear, endMonth, groupingMode, budgetView } =
    monthYearFilter;

  const authorizedApiAxios = useAuthorizedApiAxios();

  var item = { accountIdentifier: accountIdentifier };
  var inputs = {
    startYear,
    startMonth,
    endMonth,
    endYear,
    portfolioId,
    groupingMode,
    budgetView,
  };
  var qry = queryString.stringify(inputs);

  const queryName =
    "portfoliofinancials" +
    portfolioId +
    startYear +
    startMonth +
    endYear +
    endMonth +
    groupingMode + 
    budgetView;

  return useQuery(
    [queryName, item],
    () => {
      const source = axios.CancelToken.source();
      const url: string = `/assetService/v1/financials/portfolio?${qry}`;

      const promise = authorizedApiAxios().get<any>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: item.accountIdentifier,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => response?.data,
    }
  );
};
