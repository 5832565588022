import React, { forwardRef, useState, useEffect, useContext } from "react";
import { CircularProgress } from "@mui/material";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";

import LoanSummary from "../../../properties/loans/views/LoanSummary";
import { usePortfolioInfo } from "../../util/portfolioInfoContext";
import { useGetPortfolioLoans } from "../api/portfolioLoansService";
import PortfolioLoansGrid from "./PortfolioLoansGrid";
import { GridApi, ColumnApi, ExcelExportParams } from "ag-grid-community";
import { useQueryClient } from "@tanstack/react-query";
import {
  GridConfigIds,
  PageConfigIds,
} from "../../../../constants/GridPageConfigurationIds";
import { useGetGridConfig } from "../../../../shared/api/pageConfigService";
import SaveGridStateOptions, {
  GRID_CONFIG_DEFAULT_VIEW,
} from "../../../../shared/view/SaveGridStateOptions";

import Drawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import { UilAngleLeft } from "@iconscout/react-unicons";
import { UilTimes } from "@iconscout/react-unicons";
import { UilExport } from "@iconscout/react-unicons";
import DatePicker from "react-datepicker";
import { UilCalender } from "@iconscout/react-unicons";
import {
  // AppliedFilter,
  LoansMonthYearFilter,
} from "../../../properties/loans/model/LoansModel";
import PortfoliosStateContext from "../../context/PortfoliosStateContext";

const drawerSummaryWidth = 420;
const drawerSummaryWidthMobile = 300;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

interface Props {
  value?: string;
  onClick?: () => void;
}

const PortfolioLoans: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerSummaryOpen = () => {
    setOpen(true);
  };

  const handleDrawerSummaryClose = () => {
    setOpen(false);
  };

  const portfolioInfo = usePortfolioInfo();
  const { loansDate, updateLoansDate } = useContext(PortfoliosStateContext);
  const [monthFilter, setMonthFilter] = useState<LoansMonthYearFilter>({
    year: loansDate.getFullYear(),
    month: loansDate.getMonth() + 1,
  });

  const portfolioLoansQuery = useGetPortfolioLoans({
    portfolioId: portfolioInfo.portfolioId,
    accountIdentifier: portfolioInfo.accountIdentifier,
    monthYearFilter: monthFilter,
  });

  const { data: portfolioData, isSuccess } = portfolioLoansQuery;
  const [gridApi, setGridApi] = React.useState<GridApi | undefined>(undefined);
  const [gridColumnApi, setGridColumnApi] = React.useState<
    ColumnApi | undefined
  >(undefined);

  const getMaxDate = (minDate: Date, maxDate?: Date) => {
    if (maxDate === undefined) return minDate;
    return Date.parse(maxDate.toString()) > Date.parse(new Date().toString())
      ? new Date()
      : new Date(maxDate);
  };

  useEffect(() => {
    if (portfolioData === undefined || !isSuccess) return;
    if (
      portfolioData.minDate === undefined ||
      portfolioData.maxDate === undefined
    )
      return;

    const newCurrentDate = getMaxDate(
      new Date(portfolioData.minDate),
      new Date(portfolioData.maxDate)
    );

    updateLoansDate(newCurrentDate);
    setMonthFilter({
      month: newCurrentDate.getMonth() + 1,
      year: newCurrentDate.getFullYear(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioData, isSuccess]);

  /**
   * exports the data that is rendered in the AG grid.
   */
  const onExport = () => {
    const params: ExcelExportParams = {
      sheetName: "Loans Table",
      fileName: "Portfolios - Loans",
      exportMode: undefined,
      suppressTextAsCDATA: false,
      rowHeight: undefined,
      headerRowHeight: undefined,
    };
    gridApi?.exportDataAsExcel(params);
  };

  // Grid Config state and methods

  const [gridView, setGridView] = useState(GRID_CONFIG_DEFAULT_VIEW);

  const gridId = GridConfigIds.PORTFOLIO_LOANS_GRID;
  const pageId = PageConfigIds.PORTFOLIO_LOANS_GRID;

  const accountId = portfolioInfo.accountIdentifier;

  const queryClient = useQueryClient();
  const gridConfigsQuery = useGetGridConfig({
    pageId: pageId,
    gridIdentifier: gridId,
    accountId: accountId,
  });

  /**
   * Sets the context that is retrieved from the backend whenever the gridView is changed.
   * gridView is either changed when data first loads (only if a default value exists) or when the user uses the dropdown
   */
  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1 ||
      gridApi === undefined ||
      gridColumnApi === undefined
    )
      return;

    if (gridView === GRID_CONFIG_DEFAULT_VIEW) {
      gridApi.setFilterModel({});
      gridColumnApi.resetColumnState();
      return;
    }

    const selectedGridConfig = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.id === Number(gridView)
    );

    if (selectedGridConfig === undefined) return;

    gridApi.setFilterModel(JSON.parse(selectedGridConfig.columnFilterState));
    gridColumnApi.applyColumnState({
      state: JSON.parse(selectedGridConfig.columnState),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridView, gridApi, gridColumnApi]);

  const saveAsView = React.useRef("");

  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1
    ) {
      if (gridView !== GRID_CONFIG_DEFAULT_VIEW)
        setGridView(GRID_CONFIG_DEFAULT_VIEW);
      return;
    }

    const isSaveAsRender = saveAsView.current.trim().length > 0;

    if (isSaveAsRender) {
      const savedAsView = gridConfigsQuery.data.gridConfigurations.find(
        (gridConfig) =>
          gridConfig.name.toLowerCase().trim() ===
          saveAsView.current.toLowerCase().trim()
      );
      if (savedAsView) setGridView(savedAsView.id.toString());
      saveAsView.current = "";
      return;
    }

    const defaultView = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.isDefault
    );
    if (
      defaultView &&
      gridView !== defaultView.id.toString() &&
      gridView === GRID_CONFIG_DEFAULT_VIEW
    )
      setGridView(defaultView.id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridConfigsQuery.isSuccess, gridConfigsQuery.data]);

  type Props = {
    onClick?: () => void;
    value?: string;
  };
  type RefType = number;
  const CustomDatePickerInput = forwardRef<RefType, Props>(
    ({ onClick, value }, ref) => (
      <Box className={"react-datepicker-input"} ref={ref} onClick={onClick}>
        {value}
        <Box className={"react-datepicker-icon"}>
          <UilCalender />
        </Box>
      </Box>
    )
  );

  return (
    <React.Fragment>
      {!isSuccess ? (
        <Box m="auto">
          <CircularProgress />
        </Box>
      ) : (
        <React.Fragment>
          <div
            id="portfolio-loans"
            className="tab-pane fade show active"
            role="tabpanel"
            aria-labelledby="portfolio-tab-loans"
          >
            <Grid container className="property-action-bar">
              <Grid item>
                <Grid container sx={{ width: "auto" }}>
                  <Grid item>
                    <Box className={"datepicker-outer-container"}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >{`Show loans for month:`}</Typography>
                      <Box className={"datepicker-container"}>
                        <DatePicker
                          selected={new Date(loansDate)}
                          onChange={(date: Date) => {
                            setMonthFilter({
                              month: date.getMonth() + 1,
                              year: date.getFullYear(),
                            });
                            updateLoansDate(date);
                          }}
                          dateFormat="MMMM yyyy"
                          showMonthYearPicker
                          //showFullMonthYearPicker
                          //showTwoColumnMonthYearPicker
                          minDate={new Date()}
                          maxDate={getMaxDate(new Date(), new Date())}
                          //popperPlacement="auto"
                          popperModifiers={[
                            {
                              name: "preventOverflow",
                              options: {
                                altAxis: true,
                              },
                            },
                            {
                              name: "offset",
                              options: {
                                offset: [-13, 0],
                              },
                            },
                          ]}
                          customInput={<CustomDatePickerInput {...props} />}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container sx={{ width: "auto" }}>
                  <Grid item>
                    <SaveGridStateOptions
                      isSuccess={gridConfigsQuery.isSuccess}
                      gridView={gridView}
                      handleGridView={(newVal: string) => {
                        setGridView(newVal);
                      }}
                      gridConfigData={gridConfigsQuery.data}
                      gridApi={gridApi}
                      gridColumnApi={gridColumnApi}
                      gridId={gridId}
                      pageId={pageId}
                      accountId={accountId}
                      invalidateGridQuery={() =>
                        queryClient.invalidateQueries([
                          "getGridConfig",
                          pageId + gridId + accountId,
                        ])
                      }
                      updateSaveAsViewName={(name: string) => {
                        saveAsView.current = name;
                      }}
                      updateGridView={() =>
                        setGridView(GRID_CONFIG_DEFAULT_VIEW)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      className={"btn-primary"}
                      sx={{ marginTop: "21px" }}
                      onClick={onExport}
                    >
                      <UilExport /> Export
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {portfolioData?.loans !== undefined && (
              <div id="property-ar-content" className="tab-content">
                <PortfolioLoansGrid
                  gridApi={gridApi}
                  gridColumnApi={gridColumnApi}
                  onGridRender={(
                    gridApi: GridApi,
                    gridColumnApi: ColumnApi
                  ) => {
                    setGridApi(gridApi);
                    setGridColumnApi(gridColumnApi);
                  }}
                  tableContent={portfolioData?.loans}
                />
              </div>
            )}
          </div>
          <DrawerHeader
            sx={{
              position: "fixed",
              right: "10px",
              bottom: "50px",
              zIndex: 1201,
            }}
            className={"summary-drawer-action"}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerSummaryOpen}
              edge="start"
              sx={{
                ...(open && { display: "none" }),
              }}
              size="large"
            >
              <UilAngleLeft />
              <Typography>Summary</Typography>
            </IconButton>
            <IconButton
              onClick={handleDrawerSummaryClose}
              sx={{
                display: "none",
                ...(open && { display: "flex" }),
              }}
              size="large"
            >
              <UilTimes />
              <Typography>Close</Typography>
            </IconButton>
          </DrawerHeader>
          <Drawer
            sx={{
              position: "fixed",
              width: drawerSummaryWidthMobile,
              [theme.breakpoints.up("sm")]: {
                width: drawerSummaryWidth,
              },
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerSummaryWidthMobile,
                [theme.breakpoints.up("sm")]: {
                  width: drawerSummaryWidth,
                },
              },
            }}
            variant="persistent"
            anchor="right"
            open={open}
          >
            <div
              id="summaryContent"
              className="summary-content table-responsive collapse show"
            >
              <LoanSummary loansSummary={portfolioData?.loansSummary} />
            </div>
          </Drawer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PortfolioLoans;
