import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  AccountIdentifierHeaderName,
  useAuthorizedApiAxios,
} from "../../../shared/api/authorizedAxios";
import {
  IPutUserPropertyAssignment,
  IUserPropertyAssignment,
} from "../views/userPropertyAssignment/models/userPropertyAssignment";

export const useGetUsersPropertyPermissions = (accountId: string) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["usersPropertyPermissions", accountId],
    () => {
      const source = axios.CancelToken.source();
      const url = `/PropertyService/v1/Users/UsersPropertyPermissions`;

      let promise = authorizedApiAxios().get<IUserPropertyAssignment[]>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: accountId,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => response.data,
      enabled: accountId !== "",
    }
  );
};

export const useUpdateUsersPropertyPermissions = (accountId: string) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation(
    (userProperties: IPutUserPropertyAssignment[]) => {
      const source = axios.CancelToken.source();

      const url = `/PropertyService/v1/Users/UsersPropertyPermissions`;
      let promise = authorizedApiAxios().put<string>(
        url,
        { usersPropertyPermissions: userProperties },
        {
          cancelToken: source.token,
          headers: {
            [AccountIdentifierHeaderName]: accountId,
          },
        }
      );

      (promise as any).cancel = () => source.cancel();
      return promise;
    }
  );
};
