import React, { useState, useCallback } from "react";
import { Box, Button, CircularProgress } from "@mui/material";

import GLAccountTreeGrid from "./GLAccountTreeGrid";

import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import CreateGLAccountTreeDialog from "./CreateGLAccountTreeDialog";
import { useGetGLAccountTrees } from "../../api/glTreeService";
import { IUAAlertMessage } from "../../util/userContextModels";
import SimpleAlert from "../../../UI/view/SimpleAlert";
import { useGetAccounts } from "../../../properties/property/api/accountService";

export const documentUploadSuccessMessage = "Your spreadsheet was saved.";
export const documentUploadErrorMessage =
  "The spreadsheet uploaded is invalid or missing information. Please update the spreadsheet and try again.";
export const documentDownloadErrorMessage =
  "The spreadsheet could not be downloaded. Please try again.";

const GLAccountTrees: React.FC = () => {
  const { data: accounts, isSuccess } = useGetAccounts();
  const accountId = React.useMemo(() => {
    if (!isSuccess || !accounts || accounts.length === 0) return "";
    return accounts[0].identifier;
  }, [accounts, isSuccess]);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const glTreeAccountQuery = useGetGLAccountTrees(accountId);
  const queryClient = useQueryClient();
  const isLoading = useIsFetching();

  const { data: accountTreeData, isLoading: glTreeDataIsLoading } =
    glTreeAccountQuery;

  const onCreateDialogClose = (shouldRefresh: boolean) => {
    refreshGridData(shouldRefresh);
    setOpenCreateDialog(false);
  };

  const refreshGridData = useCallback(
    (shouldRefresh: boolean) => {
      if (shouldRefresh) {
        queryClient.invalidateQueries(["getGLAccountTrees", accountId]);
      }
    },
    [accountId, queryClient]
  );

  const [openUpload] = useState(true);

  const defaultAlertMessage: IUAAlertMessage = {
    severityType: "success",
    message: "",
  };
  const [alertMessage, setAlertMessage] =
    useState<IUAAlertMessage>(defaultAlertMessage);

  const onUploaded = (msg: any) => {
    setAlertMessage({
      severityType: "success",
      message:
        documentUploadSuccessMessage +
        " " +
        (typeof msg.result === "string" || msg.result instanceof String
          ? msg.result
          : msg),
    });
  };

  return (
    <React.Fragment>
      {alertMessage.message !== "" && (
        <SimpleAlert
          severityType={alertMessage.severityType}
          message={alertMessage.message}
          onClose={() => setAlertMessage(defaultAlertMessage)}
          alertStyles={{ width: "100%" }}
          styles={{ position: "inherit", width: "100%" }}
        />
      )}
      {/* Create GL Tree Dialog */}
      {isSuccess &&
        accountId.trim().length > 0 &&
        openCreateDialog &&
        accountTreeData !== undefined && (
          <CreateGLAccountTreeDialog
            open={openUpload}
            handleClose={onCreateDialogClose}
            accountId={accountId}
            editMode={false}
            glAccountTreeTypes={accountTreeData.glTreeTypeList}
            glAccountingSystems={accountTreeData.glAccountingSystems}
            initialTitle={""}
            initialAccountingSystem={""}
            initialTreeType={""}
            onUploaded={(msg: any) => {
              onUploaded(msg);
            }}
            onError={(msg: any) => {
              setAlertMessage({
                severityType: "error",
                message: documentUploadErrorMessage + " " + msg,
              });
            }}
          />
        )}
      {/* Create gl tree button */}

      <Box
        display="flex"
        flexDirection="row-reverse"
        sx={{ padding: "24px 20px 16px 20px" }}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={isLoading > 0}
          // disabled={true}
          onClick={() => setOpenCreateDialog(true)}
        >
          Add new GL Tree
        </Button>
      </Box>
      {/* GL Tree grid */}
      {glTreeDataIsLoading && (
        <Box m="auto">
          <CircularProgress />
        </Box>
      )}
      {isSuccess && accountTreeData !== undefined && (
        <GLAccountTreeGrid
          rows={accountTreeData.glAccountTrees}
          accountId={accountId}
          glAccountTreeTypes={accountTreeData.glTreeTypeList}
          glAccountingSystems={accountTreeData.glAccountingSystems}
          handleClose={refreshGridData}
          onUploaded={(msg: any) => {
            onUploaded(msg);
          }}
          onError={(msg: any) => {
            setAlertMessage({
              severityType: "error",
              message: documentUploadErrorMessage + " " + msg,
            });
          }}
          onErrorDownload={() => {
            setAlertMessage({
              severityType: "error",
              message: documentDownloadErrorMessage,
            });
          }}
        />
      )}
    </React.Fragment>
  );
};

export default GLAccountTrees;
