import React from "react";
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "@mui/material";
import { Document } from "../../../document/model/documentListModel";

const CustomizedBoxRoot = styled(Box)`
  width: 100%;
`;
const CustomizedBoxContainer = styled(Box)`
  width: 100%;
  min-width: 70%;
  margin-bottom: theme.spacing(2);
`;
const CustomizedTable = styled(Table)`
  & thead th {
    border-top: 1px solid var(--neutral-grey-150);
  }
  & th:nth-child(1) {
    border-right: 1px solid var(--neutral-grey-150);
  }
  & th {
    padding: 12px;
    border-bottom: 1px solid var(--neutral-grey-150);
  }
  & th:first-child {
    padding-right: 34px;
    padding-left: 34px;
  }
  & td {
    padding: 12px;
    border-bottom: 1px solid var(--neutral-grey-150);
  }
  & .MuiTypography-body1 {
    padding: 0 !important;
    font-size: inherit !important;
    color: var(--primary-dark-blue) !important;
  }
  & .listitems-checklist {
    padding: 0 !important;
  }
  & svg {
    fill: var(--neutral-grey-200);
  }
  & .Mui-checked svg {
    fill: var(--primary-blue);
  }
  & path {
    stroke-width: 0.5;
    stroke: white;
  }
`;
const CustomizedTableSortLabel = styled(TableSortLabel)`
  color: var(--neutral-grey-600);
  &.Mui-active {
    color: var(--neutral-grey-600);
  }
  & svg {
    fill: var(--neutral-grey-600);
  }
  &:hover {
    color: var(--primary-blue);
  }
  &:hover svg {
    fill: var(--primary-blue);
  }
`;
const CustomizedLink = styled(Link)`
  margin-left: 8px;
  text-decoration: none;
  color: var(--primary-dark-blue);
`;



function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: string }, b: { [key in Key]: string }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Document;
  label: string;
}

const headCells: HeadCell[] = [
  { id: "displayName", label: "Document Name" },
  { id: "documentType", label: "Document Type" },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Document
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const EnhancedTableHead: React.FC<EnhancedTableProps> = (
  props: EnhancedTableProps
) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Document) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <CustomizedTableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box sx={{
                  border: 0,
                  clip: "rect(0 0 0 0)",
                  height: 1,
                  margin: -1,
                  overflow: "hidden",
                  padding: 0,
                  position: "absolute",
                  top: 20,
                  width: 1,
                }}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </CustomizedTableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface DocumentProps {
  documents: Document[];
  selectedDocuments: Document[];
  updateSelected: (newSelected: Document[]) => void;
}

const DocumentsCopyTable: React.FC<DocumentProps> = (props) => {
  const { documents, selectedDocuments, updateSelected } = props;
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Document>("displayName");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Document
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      updateSelected(documents);
      return;
    }
    updateSelected([]);
  };

  /**
   * Checks/un-checks an item in the list of documents.
   * @param event click event.
   * @param document Document which was clicked.
   */
  const handleClick = (
    event: React.MouseEvent<unknown>,
    document: Document
  ) => {
    const selectedIndex = selectedDocuments.indexOf(document);
    let newSelected: Document[] = [];

    // if the clicked item is currently unchecked
    if (selectedIndex === -1)
      newSelected = newSelected.concat(selectedDocuments, document);
    // if the clicked item is currently checked and is the first element
    else if (selectedIndex === 0)
      newSelected = newSelected.concat(selectedDocuments.slice(1));
    // if the clicked item is currently checked and is the last element
    else if (selectedIndex === selectedDocuments.length - 1)
      newSelected = newSelected.concat(selectedDocuments.slice(0, -1));
    // if the clicked item is currently checked and is neither the first nor the last element
    else if (selectedIndex > 0)
      newSelected = newSelected.concat(
        selectedDocuments.slice(0, selectedIndex),
        selectedDocuments.slice(selectedIndex + 1)
      );

    updateSelected(newSelected);
  };

  const isSelected = (document: Document) =>
    selectedDocuments.indexOf(document) !== -1;

  return (
    <CustomizedBoxRoot>
      <CustomizedBoxContainer>
        <TableContainer>
          <CustomizedTable
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selectedDocuments.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={documents.length}
            />
            <TableBody>
              {stableSort(documents, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((document: Document, index: number) => {
                  const isItemSelected = isSelected(document);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, document)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                      >
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          className={"listitems-checklist"}
                        />
                        <CustomizedLink
                          variant="body1"
                          color="primary"
                          underline="always"
                        >
                          {document.displayName}
                        </CustomizedLink>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1">
                          {document.documentType}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </CustomizedTable>
        </TableContainer>
      </CustomizedBoxContainer>
    </CustomizedBoxRoot>
  );
};

export default DocumentsCopyTable;