import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { GridRowList } from "../util/constant";

export const DownArrowIconRenderer = (props: ICellRendererParams) => {
  return (
    <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
      {((props.data.report === GridRowList.FINANCIALS_BALANCE_SHEET && props.column?.getColId() !== "includeBudget") || props.data.report === GridRowList.FINANCIALS_INCOME_STATEMENT) && (
        <>
          <div>{props.value}</div>
          <div style={{display: "flex", alignItems: "center"}}><ArrowDropDownOutlinedIcon style={{fontSize: "30px", color: "var(--neutral-grey-600)"}} /></div>
        </>
      )}
    </div>
  );
};
export default DownArrowIconRenderer;