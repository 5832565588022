import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { UilTrashAlt } from "@iconscout/react-unicons";

const ConfirmDialog: React.FC<any> = (props: any) => {
  const { title, children, open, setOpen, onConfirm, onCancel } = props;
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        if (onCancel) onCancel();
      }}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">
        <Typography
          component="span"
          variant="h2"
          color="primary.dark"
          display="flex"
          justifyContent="center"
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          component="div"
          variant="h3"
          color="neutral700.light"
          textAlign="center"
        >
          {children}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            setOpen(false);
            if (onCancel) onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          className="btn-delete"
        >
          <UilTrashAlt />
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
