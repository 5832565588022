import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "../../../shared/api/authorizedAxios";
import { useMutation } from "@tanstack/react-query";
import { propertyModel } from "../model/propertiesModel";

export const useExportReport = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();
  return useMutation(async (properties: propertyModel) => {
    const url: string = `assetService/v1/Reports`;
    const propertiesDetails = { ...properties };
    // delete propertiesDetails.accountIdentifier;
    const result = await authorizedApiAxios().post(url, propertiesDetails, {
      headers: {
        [AccountIdentifierHeaderName]: properties.accountIdentifier,
      },
      responseType: "blob",
    });
    return result;
  });
};