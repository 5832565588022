import { List, ListItem, ListSubheader, ListItemText, ClickAwayListener } from "@mui/material";
import headerStyles from "../headerStyles";
import { Link } from "react-router-dom";

interface FacilityMenuProps {
  setLeftMenuFacilityCollapseClose: () => void;
}

const FacilityMenu: React.FunctionComponent<FacilityMenuProps> = (props) => {
  const classes = headerStyles();
  return (
    <ClickAwayListener
    onClickAway={() => {
        props.setLeftMenuFacilityCollapseClose();
    }}
  >
    <List
      component="div"
      aria-labelledby="leftMenuFacilityCollapseContainer"
      disablePadding
      className={classes.leftMenuList}
      onClick={() => props.setLeftMenuFacilityCollapseClose()}
      subheader={
        <ListSubheader
          component="div"
          id="leftMenuFacilityCollapseContainer"
        ></ListSubheader>
      }
    >
      <ListItem button component={Link} to="/">
        <ListItemText primary="Broadcast" />
      </ListItem>
      <ListItem button component={Link} to="/">
        <ListItemText primary="Incidents" />
      </ListItem>
      <ListItem button component={Link} to="/">
        <ListItemText primary="Inspections" />
      </ListItem>
      <ListItem button component={Link} to="/">
        <ListItemText primary="Preventive Maintenance" />
      </ListItem>
      <ListItem button component={Link} to="/">
        <ListItemText primary="Resource Reservations" />
      </ListItem>
      <ListItem button component={Link} to="/">
        <ListItemText primary="Service Requests" />
      </ListItem>
      <ListItem button component={Link} to="/">
        <ListItemText primary="Visitor Security" />
      </ListItem>
    </List>
    </ClickAwayListener>
  );
};

export default FacilityMenu;
