import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { UilMultiply } from "@iconscout/react-unicons";

import { IValuation } from "../model/propertyOverviewModel";
import { EDateFormat, formatDatePO } from "../util/formatDateUtil";

interface Props {
  open: boolean;
  onDialogClose: () => void;
  valuations?: IValuation[];
}

const borderRight = "0.5px solid lightgray";

const ValuationsTable: React.FC<Props> = (props) => {
  const { valuations } = props;
  return (
    <Dialog open={props.open}>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="h2">Valuation History</Typography>
        <UilMultiply
          style={{ cursor: "pointer" }}
          onClick={props.onDialogClose}
        />
      </Box>
      {valuations === undefined ? (
        <Box>No valuations to display.</Box>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="valuations table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderRight: borderRight }} align="left">
                  Valuation Amount
                </TableCell>
                <TableCell sx={{ borderRight: borderRight }} align="left">
                  Valuation Date
                </TableCell>
                <TableCell align="left">Added By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {valuations.map((valuation) => (
                <TableRow key={valuation.id}>
                  <TableCell sx={{ borderRight: borderRight }} align="left">
                    ${valuation.valuation?.toLocaleString()}
                  </TableCell>
                  <TableCell sx={{ borderRight: borderRight }} align="left">
                    {valuation.valuationDate === null
                      ? "N/A"
                      : formatDatePO(
                          valuation.valuationDate,
                          EDateFormat.MM_DD_YYYY
                        )}
                  </TableCell>
                  <TableCell align="left">
                    {valuation.lastModifiedUserFullName}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Dialog>
  );
};

export default ValuationsTable;
