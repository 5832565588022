import axios from "axios";
import { useAuthorizedApiAxios } from "../../../../shared/api/authorizedAxios";
import { useQuery } from "@tanstack/react-query";
import { TAccount } from "../model/account";

export const useGetAccounts = (allAccounts: boolean = false) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getAccounts", allAccounts],
    () => {
      const source = axios.CancelToken.source();
      const url: string = `/identityService/v1/accounts?allAccounts=${allAccounts}`;

      const promise = authorizedApiAxios().get<TAccount[]>(url, {
        cancelToken: source.token,
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      staleTime: 600000,
      select: (response) => response.data
    }
  );
};
