import React, { useEffect, useState } from "react";
// import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Theme,
  Typography,
  IconButton,
  Box,
  Grid,
  List,
  ListItem,
  Button,
} from "@mui/material";
import { UilPen } from "@iconscout/react-unicons";
import { UilTrashAlt } from "@iconscout/react-unicons";
import { UilMultiply } from "@iconscout/react-unicons";
// import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useDeleteNote, useGetARNotes } from "../api/ARService";
import { usePropertyInfo } from "../../util/propertyInfoContext";
// import AddNoteChildModal from "./AddNoteChildModal";
import { formatDateMMddyyyy } from "../../util/formatDate";
import { useQueryClient } from "@tanstack/react-query";
// import { EditNotificationsRounded } from "@mui/icons-material";
// import EditNoteChildModal from "./EditNoteChildModal";
// import DeleteNoteChildModal from "./DeleteNoteChildModal";
import ConfirmDialog from "../../../../shared/view/confirmationDialog";
import { TDeleteNoteModel } from "../model/deleteNoteModel";
// import SimpleAlert from "../../../UI/view/SimpleAlert";
// import { IUAAlertMessage } from "../../../usermanagement/util/userContextModels";
// import TestDialog from "./TestDialog";
import EditArNoteChildModal from "./EditArNoteChildModal";
import AddArNoteChildModal from "./AddArNoteChildModal";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.primary.dark,
    },
    arNoteList: {
      paddingTop: 0,
      //marginTop: "8px",
      //marginBottom: "8px",
      //border: "1px solid #ccc",
      "& > li": {
        padding: "8px 0 16px 0",
        borderBottom: "1px solid var(--neutral-grey-150)",
      },
    },
    arNoteText: {
      //fontSize: "0.9rem",
    },
  })
);

// const style = {
//   position: 'absolute' as 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   pt: 2,
//   px: 4,
//   pb: 3,
// };

interface ViewNotesDialogProps {
  setCurrHasNotes: (hasNotes: boolean) => void;
  isNoteOpen: boolean;
  tenantId: string;
  handlePopupClose: (shouldRefreshData: boolean) => void;
}

const ViewNotesModal: React.FC<ViewNotesDialogProps> = (props) => {
  const { tenantId, isNoteOpen, handlePopupClose, setCurrHasNotes } = props;
  const [showEditNotePopup, setShowEditNotePopup] = useState(false);
  const [showAddNotePopup, setShowAddNotePopup] = useState(false);
  // const [showDeleteNotePopup, setShowDeleteNotePopup] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  // const [testOpen, setTestOpen] = useState<boolean>(false);
  const [editNoteId, setEditNoteId] = useState("");
  const [messageToEdit, setMessageToEdit] = useState("");
  const propertyInfo = usePropertyInfo();
  const classes = useStyles();
  const arNoteQuery = useGetARNotes({
    tenantId: tenantId,
    propertyId: propertyInfo.id,
    accountIdentifier: propertyInfo.accountIdentifier,
  });
  const arNotes = arNoteQuery.data;
  const queryClient = useQueryClient();
  const deleteNoteAction = useDeleteNote();

  const [resetScrollPosition, setResetScrollPosition] = useState(false);

  const scrollRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef === null || scrollRef === undefined) return;
    if (scrollRef.current === null || scrollRef.current === undefined) return;

    scrollRef.current.scrollTop = 0;
  }, [resetScrollPosition]);
  // const defaultAlertMessage: IUAAlertMessage = {
  //   severityType: "success",
  //   message: "",
  // };
  // const [alertMessage, setAlertMessage] =    useState<IUAAlertMessage>(defaultAlertMessage);

  // const createdArNote = async (
  //   success: boolean,
  // ) => {
  //   const uniqueId = tenantId + propertyInfo.accountIdentifier + propertyInfo.id;
  //   queryClient.invalidateQueries(["getARNotes", uniqueId]);
  //   queryClient.invalidateQueries("arService");
  //   setShowEditNotePopup(false);
  // };

  // const handleEditPopupClose = useCallback(
  //   (shouldRefreshData: boolean) => {
  //     setShowEditNotePopup(false);
  //     const uniqueId = tenantId + propertyInfo.accountIdentifier + propertyInfo.id;
  //     shouldRefreshData && queryClient.invalidateQueries(["getARNotes", uniqueId]);
  //     shouldRefreshData && queryClient.invalidateQueries("arService");
  //   },
  //   [propertyInfo.accountIdentifier, propertyInfo.id, queryClient, tenantId]
  // );

  const handleAddPopupClose = (shouldRefreshData: boolean) => {
    const uniqueId =
      tenantId + propertyInfo.accountIdentifier + propertyInfo.id;
    queryClient.invalidateQueries(["getARNotes", uniqueId]);
    queryClient.invalidateQueries(["arService"]);
    setCurrHasNotes(arNotes !== undefined && arNotes.length + 1 > 0);
    setResetScrollPosition((prevState) => {
      return !prevState;
    });
  };

  const handleEditPopupClose = (shouldRefreshData: boolean) => {
    const uniqueId =
      tenantId + propertyInfo.accountIdentifier + propertyInfo.id;
    queryClient.invalidateQueries(["getARNotes", uniqueId]);
    queryClient.invalidateQueries(["arService"]);
    setResetScrollPosition((prevState) => {
      return !prevState;
    });
  };

  const deleteNoteClicked = (noteId: string) => {
    // setDeleteDocument({ documentId, description });
    setEditNoteId(noteId);
    setConfirmOpen(true);
  };

  // const handleDeletePopupClose = useCallback(
  //   (shouldRefreshData: boolean) => {
  //     setShowDeleteNotePopup(false);
  //     const uniqueId = tenantId + propertyInfo.accountIdentifier + propertyInfo.id;
  //     shouldRefreshData && queryClient.invalidateQueries(["getARNotes", uniqueId]);
  //     shouldRefreshData && queryClient.invalidateQueries("arService");
  //   },
  //   [propertyInfo.accountIdentifier, propertyInfo.id, queryClient, tenantId]
  // );

  const handleClose = () => {
    handlePopupClose(false);
  };

  const editNoteClicked = (noteId: string, message: string) => {
    setEditNoteId(noteId);
    setMessageToEdit(message);
    // setTestOpen(true);
    setShowEditNotePopup(true);
  };

  const addNoteClicked = () => {
    setShowAddNotePopup(true);
  };

  const deleteNoteEvent = () => {
    const model: TDeleteNoteModel = {
      accountIdentifier: propertyInfo.accountIdentifier,
      noteId: editNoteId,
    };

    deleteNoteAction.mutate(model, {
      onSuccess: () => {
        const uniqueId =
          tenantId + propertyInfo.accountIdentifier + propertyInfo.id;
        queryClient.invalidateQueries(["getARNotes", uniqueId]);
        queryClient.invalidateQueries(["arService"]);
        setCurrHasNotes(arNotes !== undefined && arNotes.length - 1 > 0);
        setResetScrollPosition((prevState) => {
          return !prevState;
        });
        // setAlertMessage({
        //   severityType: "success",
        //   message: "Note was successfully deleted.",
        // });
      },
      // onError: () => {
      //   setAlertMessage({
      //     severityType: "error",
      //     message: "Could not delete the note. Please try again later.",
      //   });
      // },
    });
  };

  // const deleteNote = (noteId: string) =>{
  //   console.log("noteId: " + noteId);
  //       setEditNoteId(noteId);
  //       setShowDeleteNotePopup(true);
  //     };

  return (
    <React.Fragment>
      {/* {alertMessage.message !== "" && (
        <SimpleAlert
          severityType={alertMessage.severityType}
          message={alertMessage.message}
          onClose={() => setAlertMessage(defaultAlertMessage)}
        />
      )} */}

      <Dialog
        open={isNoteOpen}
        onClose={handleClose}
        aria-labelledby="view-notes-dialog-title"
        //aria-describedby="parent-modal-description"
        className="dialog-view-notes"
      >
        <DialogTitle id="view-notes-dialog-title">
          <Typography component="span" variant="h2" color="primary.dark">
            Notes
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="icon-dialog-close"
            size="large"
          >
            <UilMultiply />
          </IconButton>
        </DialogTitle>
        <DialogContent ref={scrollRef}>
          {arNotes !== undefined &&
          arNoteQuery.isSuccess &&
          arNotes.length > 0 ? (
            <List className={classes.arNoteList}>
              {arNotes?.map((element) => {
                return (
                  <React.Fragment key={element.id}>
                    <ListItem
                      key={element.id}
                      role={undefined}
                      //alignItems="flex-start"
                    >
                      <Box sx={{ width: "100%" }}>
                        <Grid
                          container
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Grid item>
                            <Grid container sx={{ alignItems: "center" }}>
                              <Grid item>
                                <Typography
                                  variant="body2"
                                  color="neutral700.light"
                                >
                                  {element.lastModifiedUserFullName}
                                </Typography>
                              </Grid>
                              <Grid item ml={1} mr={1}>
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: 10 }}
                                  color="neutral700.light"
                                >
                                  •
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: 14 }}
                                  color="neutral700.light"
                                >
                                  {formatDateMMddyyyy(
                                    element.lastModifiedAt + ""
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container>
                              <Grid item>
                                <IconButton className="icon-dialog-actions">
                                  <UilPen
                                    onClick={() =>
                                      editNoteClicked(
                                        element.id,
                                        element.message
                                      )
                                    }
                                  />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton className="icon-dialog-actions">
                                  <UilTrashAlt
                                    onClick={() =>
                                      deleteNoteClicked(element.id)
                                    }
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Typography
                          variant="body2"
                          color="primary.dark"
                          dangerouslySetInnerHTML={{
                            __html: element.message
                              .replace(/\n\r?/g, "<br />")
                              .replace(/~~{[^~~{/]*~~}/g, "")
                              .replace(
                                /~~\(/g,
                                "<span style='background-color: rgba(6, 108, 250, 0.12); border-radius: 6px;'>"
                              )
                              .replace(/~~\)/g, "</span>"),
                          }}
                        ></Typography>
                        {/* Sample of message with markup: "Hello ~~{aa8f067c-872e-4f8d-8084-08d8bcd79bbe~~}~~(@Bob Smith~~) and ~~{ab8f067c-872e-4f8d-8084-08d8bcd79bbe~~}~~(@Jane Smith~~)" */}
                        {/* Sample of display after regex replace: "Hello <span style=''>@Bob Smith</span> and  <span style=''>@Jane Smith</span>" */}
                      </Box>
                    </ListItem>
                  </React.Fragment>
                );
              })}
            </List>
          ) : arNotes !== undefined &&
            arNoteQuery.isSuccess &&
            arNotes.length === 0 ? (
            <Box sx={{ padding: "8px 34px 32px 34px" }}>
              <Typography component="div" variant="h3" color="neutral700.light">
                There are no notes.
              </Typography>
            </Box>
          ) : arNoteQuery.isLoading ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {arNoteQuery.isError && (
                <Box sx={{ padding: "8px 34px 32px 34px" }}>
                  <Typography
                    component="div"
                    variant="h3"
                    color="neutral700.light"
                  >
                    An error occured while retrieving the notes.
                  </Typography>
                </Box>
              )}
            </>
          )}

          {deleteNoteAction.isLoading && (
            <Box m="auto">
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {/* <AddNoteChildModal 
          tenantId = {tenantId}
          onNoteAdded={createdArNote}
        /> */}
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={addNoteClicked}
          >
            Add new note
          </Button>

          {showAddNotePopup && (
            <AddArNoteChildModal
              title="Add Note"
              tenantId={tenantId}
              open={showAddNotePopup}
              setOpen={setShowAddNotePopup}
              handleEditPopupClose={handleAddPopupClose}
            />
          )}
          {/* {showEditNotePopup &&  (
          <EditNoteChildModal 
            id = {editNoteId}
            editMessage = {messageToEdit}
            tenantId = {tenantId}
            handleEditPopupClose={handleEditPopupClose}
          />
        )} */}
          {showEditNotePopup && (
            <EditArNoteChildModal
              title="Edit Note"
              id={editNoteId}
              editMessage={messageToEdit}
              tenantId={tenantId}
              open={showEditNotePopup}
              setOpen={setShowEditNotePopup}
              handleEditPopupClose={handleEditPopupClose}
            />
          )}
          {confirmOpen && (
            <ConfirmDialog
              title="Delete Note"
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={deleteNoteEvent}
            >
              Are you sure you want to delete this note?
            </ConfirmDialog>
          )}

          {/* {showDeleteNotePopup &&  (
          <DeleteNoteChildModal 
            id = {editNoteId}
            handleDeletePopupClose={handleDeletePopupClose}
          />
        )}           */}
        </DialogActions>

        {/*
      <Box sx={{ ...style, width: 400 }}>
      <div >
          <Typography align="left" variant="h1">
              Notes
          </Typography>

          <IconButton aria-label="Close"  className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
          </IconButton>

      </div>

        <p id="parent-modal-description">
          {arNotes !== undefined && arNoteQuery.isSuccess && arNotes.length >0 ? (

          <Grid item xs={12}>
          <List className={classes.arNoteList}>
          {arNotes?.map((element) => {
              return (
                  <React.Fragment>
              <ListItem
                  key={element.id}
                  alignItems="flex-start"
              >
                  <ListItemText className={classes.arNoteText}>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Box>                      
                        <Typography variant="body2">{element.lastModifiedUserFullName}</Typography>
                      </Box>
                      <Box>                            
                        <Typography variant="body2">{formatDateMMddyyyy(element.lastModifiedAt + "")}</Typography>
                      </Box>
                      <Box>
                        <UilPen />
                      </Box>
                      <Box>
                        <UilTrashAlt />
                      </Box>                        
                    </Box>                      
                    <Box>                      
                        <Typography variant="body2">{element.message}</Typography>
                    </Box>
                  </ListItemText>
              </ListItem>
              <Divider />
              </React.Fragment>
              );
          })}
          </List>
          </Grid>
          ):arNotes !== undefined && arNoteQuery.isSuccess && arNotes.length ===0 ? (
              <Typography>There are no notes.</Typography>
          ): arNoteQuery.isLoading ? (
              <Box textAlign="center">
                <CircularProgress />
              </Box>
            ) : (
              <>
                {arNoteQuery.isError && (
                  <Typography>
                    An error occured while retrieving the notes.
                  </Typography>
                )}
              </>
            )}
        </p>
        <AddNotesChildModal />
      </Box>
      */}
      </Dialog>
    </React.Fragment>
  );
};

export default ViewNotesModal;
