import axios, { AxiosInstance } from "axios";
import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "../../../../shared/api/authorizedAxios";
import { useQuery } from "@tanstack/react-query";

export interface BusinessUnitsOption {
  id: string;
  name: string;
}

interface BusinessUnitsProps {
  accountIdentifier: string;
  propertyId: string;
}

export const useGetBusinessUnitsOptions = (
  businessUnitsProps: BusinessUnitsProps
) => {
  const { propertyId } = businessUnitsProps;
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery<BusinessUnitsOption[]>(
    ["glTreeProps" + propertyId],
    () => getBusinessUnits(businessUnitsProps, authorizedApiAxios),
    {
      enabled:
        businessUnitsProps.accountIdentifier.trim().length !== 0 &&
        businessUnitsProps.propertyId.trim().length !== 0,
      select: (response) => {
        return response.sort((a, b) => {
          let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      },
    }
  );
};

const getBusinessUnits = async (
  businessUnitsProps: BusinessUnitsProps,
  authorizedApiAxios: () => AxiosInstance
) => {
  const { propertyId, accountIdentifier } = businessUnitsProps;
  const source = axios.CancelToken.source();
  const url: string = `/assetService/v1/lookups/${propertyId}/financialbusinessunits`;

  const promise = authorizedApiAxios().get<BusinessUnitsOption[]>(url, {
    cancelToken: source.token,
    headers: {
      [AccountIdentifierHeaderName]: accountIdentifier,
    },
  });

  (promise as any).cancel = () => source.cancel();
  return await promise.then((response) => response.data);
};
