import React from "react";
import { Tooltip, Grid, Box } from "@mui/material";
import { UilPen } from '@iconscout/react-unicons';
import { useQueryClient } from "@tanstack/react-query";

import EditPortfolioModal from "./EditPortfolioModal";
import { useGetPortfolioProperty } from "../api/portfolioProperties";

interface EditPortfolioProps {
  portfolioId: string;
  accountId: string;
  refreshTableData: () => void;
}

const EditPortfolio: React.FC<EditPortfolioProps> = (props) => {
  const queryClient = useQueryClient();
  const [openEditModal, setOpenEditModal] = React.useState(false);

  const portfolioQuery = useGetPortfolioProperty({
    accountId: props.accountId,
    portfolioId: props.portfolioId,
    allowQuery: openEditModal,
  });

  const onEditModalClose = (shouldRefresh: boolean) => {
    if (shouldRefresh) {
      // Refreshing the content of this portfolio.
      const uniqueId = props.accountId + props.portfolioId;
      queryClient.invalidateQueries(["getPortfolioProperty", uniqueId]);
      props.refreshTableData();
    }
    setOpenEditModal(false);
  };

  return (
    <React.Fragment>
      {openEditModal &&
        portfolioQuery.isSuccess &&
        portfolioQuery.data !== undefined && (
          <EditPortfolioModal
            handlePopupClose={onEditModalClose}
            showInsertPopup={openEditModal}
            accountIdentifier={props.accountId}
            defaultPortfolio={portfolioQuery.data}
          />
        )}
        <Grid
          item
          sx={{
            "& svg": {
              maxHeight: "20px",
              maxWidth: "20px",
              lineHeight: "1",
            },
          }}
        >
          <Tooltip title="Edit" style={{ cursor: "pointer" }}>
            <Box
              onClick={()=>setOpenEditModal(true)}
              className={"icon-documents"}
              aria-label="edit"
            >
              <UilPen />
            </Box>
          </Tooltip>
        </Grid>
    </React.Fragment>
  );
};

export default EditPortfolio;
