 export function fromArrayOfObjectsToQueryString (obj: any, urlEncode:boolean) 
 {
    //
    // Helper function that flattens an array of objects:
    //
    // Input:
    // { tags: 
    //  [
    //    { type: 'LeaseGenId', value: '1111111111'},  
    //    { type: 'BuildingId', value: '222222222'}
    //  ] 
    // }
    // Example output: tags[0].type=LeaseGenId&tags[0].value=1111111111&tags[1].type=BuildingId&tags[1].value=222222222 
    //
    const flattenObj = (x:any, path1?:any) => {
        var result :any[]= [] ;

        let path = path1 || [];
        Object.keys(x).forEach(function (key) {
            if (!x.hasOwnProperty(key)) return;

            let newPath = path.slice();
            newPath.push(key);

            var vals = [];
            if (typeof x[key] == 'object') {
                vals = flattenObj(x[key], newPath);
            } else {
                vals.push({ path: newPath, val: x[key] });
            }
            vals.forEach(function (obj) {
                return result.push(obj);
            });
        });

        return result;
    } // flattenObj

    // start with  flattening `obj`
    var parts = flattenObj(obj); // [ { path: [ ...parts ], val: ... }, ... ]

    // convert to array notation:
    parts = parts.map(function (varInfo) {
        if (varInfo.path.length === 1) varInfo.path = varInfo.path[0];else {
            var first = varInfo.path[0];
            var rest = varInfo.path.slice(1);
            // varInfo.path = first + '[' + rest.join('][') + ']';
            varInfo.path = first + '[' + rest[0] + '].' + rest[1];
        }
        return varInfo;
    }); // parts.map

    // join the parts to a query-string url-component
    var queryString = parts.map(function (varInfo) {
        return varInfo.path + '=' + varInfo.val;
    }).join('&');
    if (urlEncode) return encodeURIComponent(queryString);else return queryString;
}
