import React from "react";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { UilNotes } from '@iconscout/react-unicons'
import { ICellRendererParams } from "ag-grid-community";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    disableFocusListener
    placement="top"
    {...props}
    classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 0,
    maxWidth: 220,
    backgroundColor: "var(--neutral-white)",
    border: "1px solid var(--neutral-grey-200)",
    borderRadius: 6,
    boxShadow: "0 2px 2px 0 rgba(46, 57, 72, 0.06)",
    "& .noteMessage": {
      margin: 12,
      marginRight: 0,
      paddingright: 12,
      overflow: "auto",
      height: 90,
    },
    "& .createDate": {
      padding: 12,
      borderTop: "1px solid var(--neutral-grey-200)",
    },
  },
}));

const BudgetNoteRenderer: React.FunctionComponent<ICellRendererParams> = (props: any) => {

  var noteMessage:string = (props.data !== undefined)
    ? (props.data[props.colDef.field+"Message"] === null)? "": props.data[props.colDef.field+"Message"]
    : "";
  var createdDate = (props.data !== undefined)
    ? props.data[props.colDef.field+"Created"]
    : "";

  return (
    <Box sx={{ marginTop: "5px", }}>
      {((props.data !== undefined) && (noteMessage !== "")) &&  (
        <span>
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography variant="body1" color="primary.dark" className={"noteMessage"}>{noteMessage}</Typography>
                <Typography variant="body2" color="neutral700.light" className={"createDate"}>({createdDate})</Typography>
              </React.Fragment>
            }
          >
            <span>
              <UilNotes />
            </span>
          </HtmlTooltip>
        </span>
      )}
    </Box>
  );
};

export default BudgetNoteRenderer;