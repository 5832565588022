import React from "react";
import {
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Theme,
  Typography,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { LoansSummary } from "../model/LoansModel";
import {
  percentFormatterWithPlaces,
} from "../../util/gridUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summaryTable: {
      "& thead th": {
        paddingTop: 8,
        paddingBottom: 8,
        borderBottom: 0,
        //fontSize: 12,
        color: "#727272",
        whiteSpace: "nowrap",
      },
      "& tbody th": {
        paddingTop: 8,
        paddingBottom: 8,
        fontWeight: "bold",
        backgroundColor: "#fff",
        whiteSpace: "nowrap",
        border: 0,
      },
      "& tbody td": {
        paddingTop: 8,
        paddingBottom: 8,
        //fontSize: 24,
        backgroundColor: "#fff",
        whiteSpace: "nowrap",
        border: 0,
      },
      "& tbody tr td:last-child": {
        width: "100%",
      },
    },
  })
);

interface LoanSummaryProps {
  loansSummary?: LoansSummary;
}

const LoanSummary: React.FC<LoanSummaryProps> = (props: LoanSummaryProps) => {
  const classes = useStyles();
  const loansSummary = props.loansSummary;

  const formatSummaryPercentValueNoDecimal = (value?: number) => {
    if (value !== undefined)
      return `${percentFormatterWithPlaces(value, 0, "%")}`;
    return "-";
  };

  const formatSummaryPercentValue2Places = (value?: number) => {
    if (value !== undefined)
      return `${percentFormatterWithPlaces(value, 2, "%")}`;
    return "-";
  };

  const formatSummaryPercentValueNoDecimalNoPercent = (value?: number) => {
    if (value !== undefined)
      return `${percentFormatterWithPlaces(value, 1, "")}x`;
    return "-";
  };

  return (
    <Box borderBottom="1px solid #ccc">
      <Grid container>
        <Grid item xs={12}>
          <div
            id="summaryContent"
            className="summary-content table-responsive collapse show"
          >
            <Table className={classes.summaryTable} aria-label="simple table">
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Current Balance</TableCell>
                  <TableCell>
                    <Typography variant="h5">
                      {formatSummaryPercentValueNoDecimal(
                        loansSummary?.currentBalance
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>LTC</TableCell>
                  <TableCell>
                    <Typography variant="h5">
                      {formatSummaryPercentValueNoDecimal(
                        loansSummary?.loanToCost
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>LTV</TableCell>
                  <TableCell>
                    <Typography variant="h5">
                      {formatSummaryPercentValueNoDecimal(
                        loansSummary?.loanToValue
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Debt Yield</TableCell>
                  <TableCell>
                    <Typography variant="h5">
                      {formatSummaryPercentValueNoDecimal(
                        loansSummary?.debtYield
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>DSCR</TableCell>
                  <TableCell>
                    <Typography variant="h5">
                      {formatSummaryPercentValueNoDecimal(
                        loansSummary?.debtServiceCoverageRatio
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoanSummary;
