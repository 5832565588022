import axios, { AxiosInstance, AxiosResponse } from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import queryString from "query-string";
import {
  AccountIdentifierHeaderName,
  useAuthorizedApiAxios,
} from "../../../../shared/api/authorizedAxios";
import { ARDateFilter, ARNote, ARTenantData } from "../model/tenant";
import { TAddARNoteModel } from "../model/addARNoteModel";
import { TDeleteNoteModel } from "../model/deleteNoteModel";

export const useGetARData = (filter: ARDateFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();
  const uniqueId =
    filter.propertyId +
    filter.monthYearFilter.month +
    filter.monthYearFilter.year;
  return useQuery<ARTenantData | undefined>(
    ["arService" + uniqueId, filter],
    () => getARData(filter, authorizedApiAxios),
    {
      enabled:
        filter.monthYearFilter !== undefined &&
        filter.monthYearFilter.month !== undefined &&
        filter.monthYearFilter.year !== undefined,
      select: (response) => {
        return response;
      },
    }
  );
};

const getARData = async (
  filter: ARDateFilter,
  authorizedApiAxios: () => AxiosInstance
) => {
  const source = axios.CancelToken.source();

  const monthYear = queryString.stringify(filter.monthYearFilter);
  const qry = monthYear;
  const url = `/AssetService/v1/AR/${filter.propertyId}?${qry}`;

  const requiredFilters = ["month", "year"];
  const reqfilters = queryString.parse(monthYear);
  const containsRequiredFilters = requiredFilters.every((s) => {
    if (s in reqfilters) {
      return (
        reqfilters[s] != null && (reqfilters[s] + "").toString().length > 0
      );
    } else return false;
  });
  if (filter.propertyId === null || !containsRequiredFilters) return;

  let promise = authorizedApiAxios().get<ARTenantData>(url, {
    cancelToken: source.token,
    headers: {
      [AccountIdentifierHeaderName]: filter?.accountIdentifier,
    },
  });

  (promise as any).cancel = () => source.cancel();
  return await promise.then((response) => response.data);
};

export interface ARNotesServiceProps {
  propertyId: string;
  tenantId: string;
  accountIdentifier: string;
}

export const useGetARNotes = (arNotesProps: ARNotesServiceProps) => {
  const authorizedApiAxios = useAuthorizedApiAxios();
  const uniqueId =
    arNotesProps.tenantId +
    arNotesProps.accountIdentifier +
    arNotesProps.propertyId;
  return useQuery<ARNote[] | undefined>(["getARNotes", uniqueId], () =>
    getARNotes(arNotesProps, authorizedApiAxios)
  );
};

const getARNotes = async (
  arNotesProps: ARNotesServiceProps,
  authorizedApiAxios: () => AxiosInstance
) => {
  const source = axios.CancelToken.source();
  const url = `/AssetService/v1/AR/${arNotesProps.propertyId}/Tenant/${arNotesProps.tenantId}/Notes`;

  let promise = authorizedApiAxios().get<ARNote[]>(url, {
    cancelToken: source.token,
    headers: {
      [AccountIdentifierHeaderName]: arNotesProps.accountIdentifier,
    },
  });

  (promise as any).cancel = () => source.cancel();
  return await promise.then((response) => response.data);
};

export const useAddARNote = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation(async (item: TAddARNoteModel) => {
    const source = axios.CancelToken.source();

    let promise: Promise<AxiosResponse<string>>;
    let headers = undefined;

    if (item.accountIdentifier) {
      headers = {
        [AccountIdentifierHeaderName]: item.accountIdentifier!,
      };
    }
    if (item?.id) {
      let url = `assetService/v1/AR/${item.propertyId}/Tenant/${item.tenantId}/Notes/${item.id}`;
      promise = authorizedApiAxios().put<string>(url, item, {
        cancelToken: source.token,
        headers: headers,
      });
    } else {
      let url = `assetService/v1/AR/${item.propertyId}/Tenant/${item.tenantId}/Notes`;
      promise = authorizedApiAxios().post<string>(url, item, {
        cancelToken: source.token,
        headers: headers,
      });
    }

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useDeleteNote = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: TDeleteNoteModel) => {
    const source = axios.CancelToken.source();

    const url: string = `assetService/v1/AR/${item.noteId}/DeleteNote`;
    let promise = authorizedApiAxios().delete<string>(url, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: item.accountIdentifier,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};
