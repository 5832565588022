import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import { styled } from '@mui/material/styles';
import { UilMultiply } from '@iconscout/react-unicons';
import CircularProgress from "@mui/material/CircularProgress";
import { Theme, Typography, IconButton, Button, Box, Grid, Link } from "@mui/material";

import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

import DocumentsCopyTable from "./documentsCopyTable";
import { usePropertyInfo } from "../../../util/propertyInfoContext";
import {
  UseDownloadDocuments,
  useDeleteDocument,
  useGetDocumentList,
} from "../../../document/api/documentService";
import { TDeleteDocumentModel } from "../../../document/model/deleteDocumentModel";
import { TDownloadDocumentModel } from "../../../document/model/downloadDocumentModel";
import { downloadBlob } from "../../util/documents";
import { Document } from "../../../document/model/documentListModel";
import PortfolioContext from "../../../../portfolio/util/portfolioContext";
import { hasSpecificPermission } from "../../../../portfolio/util/accountPermissions";
import { EAccountPermission } from "../../../../usermanagement/model/editUserModel";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const styles = (theme: Theme) =>
  createStyles({
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose?: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <CustomizedMuiDialogTitle {...other}>
      <Typography variant="h6">{children}</Typography>
    </CustomizedMuiDialogTitle>
  );
});

export const DialogContent = withStyles((theme: Theme) => ({
}))(MuiDialogContent);

export const DialogActions = withStyles((theme: Theme) => ({
}))(MuiDialogActions);

interface DocumentsDialogProps {
  leaseGenId: string;
  openDocument: boolean;
  handlePopupClose: (shouldRefreshData: boolean) => void;
}

const CustomizedMuiDialogTitle = styled(MuiDialogTitle)`
  margin: 0;
`;
const CustomizedGridButtons = styled(Grid)`
  justify-content: flex-end;
  & > :not(:first-of-type) {
    margin-left: 8px;
  }
`;
const CustomizedDialogContent = styled(DialogContent)`
  margin: 0 -34px;
`;
const CustomizedLinkSelectAll = styled(Link)`
  cursor: pointer;
  font-size: inherit !important;
  color: var(--primary-blue) !important;
  &:hover {
    color: var(--link-hover) !important;
  }
`;

const DocumentsDownloadModal: React.FC<DocumentsDialogProps> = (props) => {
  const { leaseGenId, openDocument, handlePopupClose } = props;
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);
  const [deletingDocuments, setDeletingDocuments] = useState(false);
  const propertyInfo = usePropertyInfo();
  const { accountPermissions } = useContext(PortfolioContext);
  const docList = useGetDocumentList({
    propertyId: propertyInfo.id,
    documentType: "",

    tags: [{ type: "LeaseGenId", value: leaseGenId }],
    accountIdentifier: propertyInfo.accountIdentifier,
  });
  const documents = docList.data;
  const deleteDocumentResponse = useDeleteDocument();
  const downloadDocuments = UseDownloadDocuments();

  const hasDeleteDocAccess = React.useMemo(
    () =>
      hasSpecificPermission(
        accountPermissions,
        propertyInfo.accountIdentifier,
        EAccountPermission.DOCUMENTS_UPLOAD_AND_DELETE
      ),
    [accountPermissions, propertyInfo.accountIdentifier]
  );

  const handleClose = () => {
    handlePopupClose(false);
  };

  const onDeleteDocuments = () => {
    handlePopupClose(false);
  };

  /**
   * To download selected documents: First makes a GET request to retrieve the urls of the blob files,
   * Next, makes another request to get the actual blob. Then downloads the blob using a custom anchor tag.
   */
  const onDownloadDocuments = async () => {
    selectedDocuments.forEach(async (selectedDocument) => {
      const model: TDownloadDocumentModel = {
        accountIdentifier: propertyInfo.accountIdentifier,
        documentId: selectedDocument.id,
      };
      const documentToDownload = await downloadDocuments.mutateAsync(model);

      if (documentToDownload !== "") downloadBlob(documentToDownload);
    });
  };

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={openDocument}
      >
        <DialogTitle id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography component="span" variant="h2" color="primary.dark">
            Documents
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="icon-dialog-close"
            size="large"
          >
            <UilMultiply />
          </IconButton>
        </DialogTitle>
        <CustomizedDialogContent>
          {documents !== undefined &&
          docList.isSuccess &&
          documents.length > 0 ? (
            <DocumentsCopyTable
              documents={documents}
              selectedDocuments={selectedDocuments}
              updateSelected={(newSelected: Document[]) =>
                setSelectedDocuments(newSelected)
              }
            />
          ) : documents !== undefined &&
            docList.isSuccess &&
            documents.length === 0 ? (
              <Box sx={{ margin: "20px 34px" }}>
                <Typography component="div" variant="h3" color="neutral700.light">No documents available.</Typography>
              </Box>
          ) : docList.isLoading ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {docList.isError && (
                <Box sx={{ margin: "20px 34px" }}>
                  <Typography component="div" variant="h3" color="neutral700.light">
                    An error occured while retrieving the documents
                  </Typography>
                </Box>
              )}
            </>
          )}
          {deletingDocuments && (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          )}
        </CustomizedDialogContent>
        <DialogActions>
          {
            documents !== undefined &&
            docList.isSuccess &&
            documents.length > 0 && (
            <Grid container>
              <CustomizedLinkSelectAll
                variant="body1"
                underline="always"
                onClick={()=>{
                  selectedDocuments.length === documents.length ? setSelectedDocuments([]) : setSelectedDocuments(documents || [])
                }}
              >
                {selectedDocuments.length === documents.length ? "Deselect all" : "Select all"}
              </CustomizedLinkSelectAll>
            </Grid>
          )}
          <CustomizedGridButtons container>
            {hasDeleteDocAccess && (
              <Button
                onClick={onDeleteDocuments}
                variant="outlined"
                size="small"
                className={"btn-secondary"}
              >
                Cancel
              </Button>
            )}
            <Button
              disabled={selectedDocuments.length === 0 || deletingDocuments}
              onClick={onDownloadDocuments}
              variant="contained"
              size="small"
              className={"btn-tertiary"}
            >
              <FileDownloadOutlinedIcon />
              Download
            </Button>
          </CustomizedGridButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DocumentsDownloadModal;
