import React from "react";
import { Box } from "@mui/material";

export type ChipTheme = "warning" | "danger" | "success";

interface Props {
  chipTheme: ChipTheme;
  chipText: string;
  startIcon?: React.ReactNode;
  styles?: Object;
}

const warningStyles = {
  backgroundColor: "#FFF0CD",
  color: "#E1AC55",
};

export const dangerStyles = {
  backgroundColor: "#FDDEDC",
  color: "#F43229",
};

const successStyles = {
  backgroundColor: "#DCF1E7",
  color: "#53B887",
};

const InfoChip: React.FC<Props> = ({
  chipTheme,
  chipText,
  startIcon,
  styles,
}) => {
  let chipStyles = {};
  if (chipTheme === "warning") chipStyles = { ...warningStyles };
  else if (chipTheme === "danger") chipStyles = { ...dangerStyles };
  else if (chipTheme === "success") chipStyles = { ...successStyles };

  let customStyles = {};
  if (styles !== undefined) customStyles = styles;

  return (
    <Box
      mt={2}
      display="flex"
      alignItems="center"
      sx={{
        ...chipStyles,
        borderRadius: "6px",
        padding: "3px 8px",
        fontWeight: 600,
        ...customStyles,
      }}
    >
      {startIcon && startIcon}
      <Box ml={1}>{chipText}</Box>
    </Box>
  );
};

export default InfoChip;
