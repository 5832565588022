import { LeaseExpirationTerm } from "../model/stackingPlan";

export function getBackgroundColor(
  leaseExpirationTermString: string,
  isFutureLease: boolean
) {
  const leaseExpirationTermEnum =
    LeaseExpirationTerm[
      leaseExpirationTermString as keyof typeof LeaseExpirationTerm
    ];
  if (isFutureLease) {
    return "#fbba00";
  }
  switch (leaseExpirationTermEnum) {
    case LeaseExpirationTerm.Current:
      return "rgba(255, 76, 87, 0.74)";
    case LeaseExpirationTerm.Year1:
      return "rgba(249, 120, 1, 0.4)";
    case LeaseExpirationTerm.Year2:
      return "rgba(37, 165, 103, 0.56)";
    case LeaseExpirationTerm.Year3:
      return "rgba(37, 165, 103, 0.28)";
    case LeaseExpirationTerm.Year4Plus:
      return "#deb4a1";
    case LeaseExpirationTerm.MonthToMonth:
      return "rgba(168, 111, 245, 0.64)";
    case LeaseExpirationTerm.Vacant:
      return "rgba(99, 126, 151, 0.48)";
  }
}
