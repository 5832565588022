import queryString from "query-string";

const STS_DOMAIN: string = document.location.origin;
const parsedQueryParams = queryString.parse(window.location.search);
const workspaceToGenesisTransferToken = parsedQueryParams.transferToken;

const configuration = {
  config: {
    silent_redirect_uri: `${STS_DOMAIN}/authentication/silent-callback`,
    redirect_uri: `${STS_DOMAIN}/authentication/callback`,
    authority: "",
    client_id: "spa",
    scope: "openid profile email IdentityServerApi apigateway.all",
    refresh_time_before_tokens_expiration_in_second: 60,
    extras: {},
  },
  apiBaseUrl: "",
};

if (workspaceToGenesisTransferToken) {
  configuration.config.extras = {
    transferToken: workspaceToGenesisTransferToken,
  };
}

if (
  STS_DOMAIN === "https://www.goworkspace.io" ||
  STS_DOMAIN === "https://goworkspace.io"
) {
  configuration.config.authority = "https://idp.goworkspace.io";
  configuration.apiBaseUrl = "https://api.goworkspace.io";
} else if (STS_DOMAIN === "https://dev.goworkspace.io") {
  configuration.config.authority = "https://idp-dev.goworkspace.io";
  configuration.apiBaseUrl = "https://api-dev.goworkspace.io";
} else if (STS_DOMAIN === "https://qa.goworkspace.io") {
  configuration.config.authority = "https://idp-qa.goworkspace.io";
  configuration.apiBaseUrl = "https://api-qa.goworkspace.io";
} else if(STS_DOMAIN === "https://adf.goworkspace.io"){
  configuration.config.authority = "https://idp-adf.goworkspace.io";
  configuration.apiBaseUrl = "https://api-adf.goworkspace.io";
} else if (STS_DOMAIN === "http://localhost:3000") {
  configuration.config.authority = "https://localhost:44342";
  configuration.apiBaseUrl = "https://localhost:44357";
} else if (STS_DOMAIN === "https://web-dev-client.azurewebsites.net") {
  configuration.config.authority = "https://ms-dev-idp.azurewebsites.net";
  configuration.apiBaseUrl = "https://web-dev-wsapi.azurewebsites.net";
} else if (STS_DOMAIN === "https://web-qa-client.azurewebsites.net") {
  configuration.config.authority = "https://ms-qa-idp.azurewebsites.net";
  configuration.apiBaseUrl = "https://web-qa-wsapi.azurewebsites.net";
} else if(STS_DOMAIN === "https://web-adf-client.azurewebsites.net"){
  configuration.config.authority = "https://ms-adf-idp.azurewebsites.net";
  configuration.apiBaseUrl = "https://web-adf-wsapi.azurewebsites.net";
} else {
  configuration.config.authority = "https://ms-prod-idp.azurewebsites.net";
  configuration.apiBaseUrl = "https://web-prod-wsapi.azurewebsites.net";
}

export default configuration;
