import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import {
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  IconButton,
  Theme,
  FormControl,
  TextField,
  Grid
} from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from "@mui/icons-material/Close";
import { UilTrashAlt } from '@iconscout/react-unicons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogStyles: {
      width: "100%",
      "& #customized-dialog-title": {
        paddingBottom: 0,
      },
    },
    form: {
      minWidth: 400,
    },
    formControl: {
      width: "100%",
    },
    toggleButton: {
      "& .MuiTypography-body1": {
        padding: "10px 0px !important",
        fontSize: "15px !important",
        color: "var(--primary-dark-blue) !important"
      }
    },
    deleteButton: {
      flex: 1,
      marginRight: theme.spacing(1)
    }
  })
);

interface Props {
  disableButtons: boolean;
  publicDash?: boolean;
  defaultDash?: boolean;
  dashboardName?: string;
  deleteDash?: boolean;
  isLast?: boolean;
  handlePopupClose: () => void;
  handleSubmit: (publicDash: boolean, defaultDash: boolean) => void;
  handleDeleteDashboard: () => void;
}

const DashboardPropertiesModal: React.FC<Props> = (props) => {
  const [publicDash, setPublicDash] = useState(props.publicDash || false);
  const [defaultDash, setDefaultDash] = useState(props.defaultDash || false);
  const [dashboardName] = useState(props.dashboardName || "");
  const [deleteDash] = useState(props.deleteDash || false);
  const [isLast] = useState(props.isLast || false);
  const classes = useStyles();

  const onSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    props.handleSubmit(publicDash, defaultDash);
  };

  const disableSaveBtn =
    props.publicDash === publicDash && props.defaultDash === defaultDash;

  return (
    <Dialog open={true} aria-labelledby="dashboard-properties-title">
      <form onSubmit={onSubmit} className={classes.form}>
        <DialogTitle
          id="customized-dialog-title"
          onClose={props.handlePopupClose}
        >
          <Typography variant="h2">{"Settings"}</Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <Typography variant="body3" component="label" style={{color: "var(--neutral-grey-600)"}}>{`Name*`}</Typography>
            <TextField
              size="small"
              variant="outlined"
              type="text"
              value={dashboardName}
              fullWidth
              disabled
            />
          </FormControl>
          <Grid container>
            {deleteDash && (
              <FormControlLabel
                control={
                  <Switch
                    checked={publicDash}
                    onChange={() => setPublicDash((prevState) => !prevState)}
                  />
                }
                label="Set as public"
                className={classes.toggleButton}
              />
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={defaultDash}
                  onChange={() => setDefaultDash((prevState) => !prevState)}
                />
              }
              label="Set as default"
              className={classes.toggleButton}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          {deleteDash && !isLast && (
            <Grid className={classes.deleteButton}>
              <Button
                onClick={props.handleDeleteDashboard}
                color="error"
                startIcon={<UilTrashAlt />}
                disabled={props.disableButtons}
              >
                Delete Dashboard
              </Button>
            </Grid>
          )}
          <Button
            onClick={props.handlePopupClose}
            variant="outlined"
            disabled={props.disableButtons}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={onSubmit}
            autoFocus
            disabled={props.disableButtons || disableSaveBtn}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DashboardPropertiesModal;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(2),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

