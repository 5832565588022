import axios from "axios";
import { useAuthorizedApiAxios } from "../../../../shared/api/authorizedAxios";
import { TState } from "../model/state";
import { useQuery } from "@tanstack/react-query";

export const useGetStates = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getStates"],
    () => {
      const source = axios.CancelToken.source();
      const url: string = `/propertyService/v1/states`;

      const promise = authorizedApiAxios().get<TState[]>(url, {
        cancelToken: source.token,
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      staleTime: 3600000,
      select: (response) => response.data,
    }
  );
};
