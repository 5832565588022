import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { RegisterOptions, useFormContext } from "react-hook-form";

import { LoansForm } from "../model/LoansForm";
import { unformatNumber } from "../util/primaryInfoForm";

type P = keyof LoansForm;
interface Props {
  name: P;
  textFieldProps: TextFieldProps;
  defaultValue?: string | number; // default value of this field (should be passed here instead of in text field props to avoid warning)
  register?: RegisterOptions; // react-hook-form
  format?: boolean; // indicates if this field should be formatted
}

// const formatRegex = /(\d)(?=(\d{3})+(?!\d))/g;
const formatRegex = /(\d)(?=(\d\d\d)+(?!\d))/g;

/**
 * Custom input for numerical values only
 * @param props
 */
const NumberInput: React.FC<Props> = (props) => {
  const { register } = useFormContext<LoansForm>();
  const { textFieldProps } = props;

  /**
   * Formats a number with added commas
   * @param value
   */
  const formatNumber = (value?: string) => {
    if (value) return value.replace(formatRegex, "$1,");
    else return value;
  };
  const [value, setNumberValue] = React.useState<string | undefined>(
    props?.defaultValue
      ? props.format
        ? formatNumber(String(props.defaultValue))
        : String(props.defaultValue)
      : undefined
  );

  const limitToTwoDecimalPlaces = (value: string) => {
    const amount = value.split(".");
    if (amount.length > 1) {
      if (amount[1].length > 2) {
        const newNum = [amount[0], amount[1].substring(0, 2)].join(".");
        return newNum;
      } else return [amount[0], amount[1]].join(".");
    }
    return value;
  };

  /**
   * Handles event change internally, sets new value to formatted value
   * @param event
   */
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let { value: eventValue } = event.target;
    if (props.format) {
      const unformattedNumber = unformatNumber(eventValue);
      const commaRemovedNum =
        unformattedNumber === undefined
          ? undefined
          : limitToTwoDecimalPlaces(unformattedNumber);
      if (commaRemovedNum !== value) {
        const formattedValue = formatNumber(commaRemovedNum);
        setNumberValue(formattedValue);
      }
    } else setNumberValue(eventValue);
  };

  return (
    <TextField
      {...textFieldProps}
      {...register(props.name, {
        ...props.register,
        setValueAs: (value) => {
          if (!value) return undefined;
          if (typeof value === "number") {
            return value;
          }
          return Number(unformatNumber(limitToTwoDecimalPlaces(value)));
        },
        onChange: (e) => {
          handleChange(e);
        },
      })}
      value={value || ""}
      className={"form-control-field"}
    />
  );
};

export default NumberInput;
