import React from "react";
import { Checkbox } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";

import { WidgetConfigContext } from "./WidgetConfigContext";
import { IWidgetConfigRow } from "../model/widgetConfigModel";

const WidgetConfigAccessRenderer: React.FC<ICellRendererParams> = (props) => {
  const { widgetsConfig, updateWidgetsConfig } =
    React.useContext(WidgetConfigContext);

  let defaultCheckedValue = false;
  const widgetIndex = widgetsConfig.findIndex(
    (widgetConfig) => widgetConfig.id === props.data.id
  );
  if (widgetIndex > -1)
    defaultCheckedValue = widgetsConfig[widgetIndex].isAvailable;
  else defaultCheckedValue = props.value;

  const [checked, setChecked] = React.useState(defaultCheckedValue);

  React.useEffect(() => {
    const widgetConfigIndex = widgetsConfig.findIndex(
      (updatedWidgetConfig) => updatedWidgetConfig.id === props.data.id
    );

    if (widgetConfigIndex > -1) {
      const latestCheckedVal = widgetsConfig[widgetConfigIndex].isAvailable;
      if (latestCheckedVal !== checked) setChecked(latestCheckedVal);
    }
  }, [widgetsConfig, checked, props.data.id]);

  if (props.data === undefined || props.value === undefined)
    return <React.Fragment></React.Fragment>;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setChecked(checked);

    const editedUserProperty: IWidgetConfigRow = {
      id: props.data.id,
      widgetName: props.data.widgetName,
      widgetCategory: props.data.widgetCategory,
      isAvailable: checked,
    };
    updateWidgetsConfig(editedUserProperty);
  };

  return (
    <div>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        color="primary"
        inputProps={{ "aria-label": "secondary checkbox" }}
        className="grid-checkbox"
      />
    </div>
  );
};

export default WidgetConfigAccessRenderer;
