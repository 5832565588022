import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import { useAuthorizedApiAxios } from "../../../shared/api/authorizedAxios";
import { isValidEmail } from "../../properties/loans/util/lenderServicerForm";
import { ICreateUser } from "../model/createUserModel";
import { ITokenValid } from "../model/tokenModel";

export const useNewCreateUser = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((userToBeCreated: ICreateUser) => {
    const source = axios.CancelToken.source();
    const url = `/IdentityService/v1/UsersProfile`;

    let promise = authorizedApiAxios().post(url, userToBeCreated, {
      cancelToken: source.token,
      // headers: {
      //   [AccountIdentifierHeaderName]: loan.accountIdentifier,
      // },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useIsTokenValid = (token: string, email: string) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  const uniqueId = token + email;

  return useQuery(
    ["isCreateTokenValid", uniqueId],
    () => {
      const source = axios.CancelToken.source();
      const url: string = `/identityService/v1/invitations/?${queryString.stringify(
        {
          email,
          token,
        }
      )}`;
      let promise = authorizedApiAxios().get<boolean | ITokenValid>(url, {
        cancelToken: source.token,
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      enabled: token.trim().length > 0 && isValidEmail(email),
      select: (response) => {
        if (typeof response.data === "object") return response.data.result;
        else return response.data;
      },
    }
  );
};
