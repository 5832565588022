import React, { useState, useEffect, useMemo } from "react";
import { useLocation, Link, useNavigate, Outlet } from "react-router-dom";
import queryString from "query-string";
import { Link as MaterialLink } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Breadcrumbs,
  Tabs,
  Tab,
  Typography,
  CircularProgress,
} from "@mui/material";
import { UilAngleLeft } from "@iconscout/react-unicons";

import { useGetPropertyDetailData } from "../api/propertyService";
import { TPropertyGetFilter } from "../../property details/model/propertyGetFilter";
import { PropertyInfoContext } from "../../util/propertyInfoContext";
import { RentRollProvider } from "../../rent roll/context/RentRollContext";
import UserOptions from "../../../layout/views/userOptions";
import { PropertyTypeEnum } from "../../../../shared/model/propertyTypeEnum";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainTopTabPanel: {
      width: "100%",
      height: "100%",
      backgroundColor: "white",
      borderTop: "1px solid #ccc",
      [theme.breakpoints.up("lg")]: {
        width: "100%",
      },
    },
  })
);

const PropertyView: React.FunctionComponent = () => {
  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();
  const navigateToProperties = () => {
    navigate("/propertylist");
  };
  const parsed = queryString.parse(location.search);
  const propId = parsed.propertyId as string;
  const acctIdentifier = parsed.accountIdentifier as string;
  let propertyQueryString: string;

  // const queryParams = useMemo(() => {return
  //   {
  //   new URLSearchParams(location.search);
  // };, [])
  // };

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  propertyQueryString = `?${queryParams.toString()}`;

  const [propertyFilter, setPropertyFilter] = useState<TPropertyGetFilter>({
    propertyId: propId,
    accountIdentifier: acctIdentifier,
  });
  const propertyDetails = useGetPropertyDetailData(propertyFilter);

  useEffect(() => {
    setPropertyFilter({
      propertyId: propId,
      accountIdentifier: acctIdentifier,
    });
  }, [acctIdentifier, propId, queryParams]);

  return propertyDetails.data === undefined ? (
    <Box m="auto">
      <CircularProgress />
    </Box>
  ) : (
    <PropertyInfoContext.Provider value={propertyDetails.data}>
      <RentRollProvider>
        <Box
          className="mainPropertiesContainer" /*className={classes.mainTopContainer}*/
        >
          <Box
            className={"appHeader"}
            sx={{ backgroundColor: "neutralSecondary.main" }}
          >
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  marginRight: "12px",
                  width: "60px",
                  height: "60px",
                  backgroundColor: "neutral.light",
                  borderRadius: "4px",
                }}
              >
                {propertyDetails.data.imageUrl ? (
                  <img
                    height="60"
                    width="60"
                    src={propertyDetails.data.imageUrl}
                    alt={propertyDetails.data.title}
                  />
                ) : (
                  <img
                    height="60"
                    width="60"
                    src="/outline_business_black_48dp.png"
                    alt={propertyDetails.data.title}
                  />
                )}
                {/*</Paper> */}
              </Box>
              <Box>
                <Typography variant="h1" sx={{ color: "primary.dark" }}>
                  {propertyDetails.data.title}
                </Typography>
                <Breadcrumbs
                  separator={<UilAngleLeft />}
                  aria-label="breadcrumb"
                >
                  <MaterialLink
                    component="button"
                    onClick={navigateToProperties}
                    variant="subtitle1"
                    sx={{
                      paddingTop: "8px",
                      color: "neutral700.light",
                      textDecoration: "none",
                      fontWeight: "500",
                      "& svg": {
                        width: "20px",
                        height: "20px",
                        verticalAlign: "text-bottom",
                      },
                    }}
                  >
                    <UilAngleLeft /> Back to properties
                  </MaterialLink>
                </Breadcrumbs>
              </Box>
            </Box>
            {/* User Options */}
            <UserOptions />
          </Box>

          <Tabs
            value={location.pathname}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Property View Navigation Tabs"
            sx={{ width: "100%", backgroundColor: "neutralSecondary.main" }}
          >
            <Tab
              label="Overview"
              value={`/propertyView/propertyViewDetails`}
              component={Link}
              to={`/propertyView/propertyViewDetails${propertyQueryString}`}
              className={"mainTopTab"}
            />
            {/*<Tab
              label="Snapshot"
              value={`/propertyView/propertyViewSnapshot`}
              component={Link}
              to={`/propertyView/propertyViewSnapshot${propertyQueryString}`}
              className={"mainTopTab"}
            />*/}
            <Tab
              label="Rent Roll"
              value={`/propertyView/propertyViewRentRoll`}
              component={Link}
              to={`/propertyView/propertyViewRentRoll${propertyQueryString}`}
              className={"mainTopTab"}
            />
            {parseInt(PropertyTypeEnum[propertyDetails.data.propertyType]) !==
              PropertyTypeEnum.ResidentialMultifamily && (
              <Tab
                label="Stacking Plan"
                value={`/propertyView/propertyViewStackingPlan`}
                component={Link}
                to={`/propertyView/propertyViewStackingPlan${propertyQueryString}`}
                className={"mainTopTab"}
              />
            )}
            <Tab
              label="Leasing Activity"
              value={`/propertyView/activity`}
              component={Link}
              to={`/propertyView/activity${propertyQueryString}`}
              className={"mainTopTab"}
            />
            <Tab
              label="AR"
              value={`/propertyView/ar`}
              component={Link}
              to={`/propertyView/ar${propertyQueryString}`}
              className={"mainTopTab"}
            />
            <Tab
              label="Loans"
              value={`/propertyView/loans`}
              component={Link}
              to={`/propertyView/loans${propertyQueryString}`}
              className={"mainTopTab"}
            />
            <Tab
              label="Financials"
              value={`/propertyView/financials`}
              component={Link}
              to={`/propertyView/financials${propertyQueryString}`}
              className={"mainTopTab"}
            />
          </Tabs>
          <Box className={classes.mainTopTabPanel}>
            <Outlet />
          </Box>
        </Box>
      </RentRollProvider>
    </PropertyInfoContext.Provider>
  );
};
export default PropertyView;
