import React, { useState } from "react";
import { renderToString } from "react-dom/server";
import { Box, IconButton, Typography, Button } from "@mui/material";

import { styled } from "@mui/material/styles";
import { FilePond, registerPlugin, FileStatus } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageCrop from "filepond-plugin-image-crop"; // Crops image
import FilePondPluginImageTransform from "filepond-plugin-image-transform"; // Changes image to match crop
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { UilFile } from "@iconscout/react-unicons";
import { UilMultiply } from "@iconscout/react-unicons";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Tag } from "../../document/model/documentFilter";
import { usePropertyInfo } from "../../util/propertyInfoContext";
import ConfirmDialog from "../../../../shared/view/confirmationDialog";
import { TCreatePhotoModel } from "../model/createPhotoModel";
import { useUploadPropertyPhoto } from "../api/propertyOverviewService";

const CustomizedDialog = styled(Dialog)`
  width: 99.7%;
  & .MuiDialog-paper {
    width: 500px;
    max-width: 90%;
  }
  & .filepond--drop-label {
    padding: 25px;
    height: 120px;
    background-color: var(--neutral-grey-50);
    border: 1px dashed var(--neutral-grey-200);
    border-radius: 6px;
    cursor: pointer;
    & label {
      cursor: pointer;
    }
    & .MuiTypography-h3 {
      font-family: lato;
      font-weight: 500;
      color: var(--neutral-grey-600);
    }
    & .MuiTypography-h3 + .MuiTypography-h3 {
      color: var(--primary-blue);
      text-decoration: underline;
    }
    & .MuiTypography-body3 {
      font-family: lato;
      font-size: 14px;
      font-weight: 400;
      color: var(--neutral-grey-600);
    }
  }
  & .filepond--drip {
    background-color: var(--neutral-white);
  }
  & .filepond--credits {
    display: none;
  }
  & .brs-files {
    color: blue;
    text-decoration: underline;
    font-size: 12px;
  }
  & .filepond--list {
    top: 7px !important;
    left: 0 !important;
    right: 0 !important;
    & .filepond--item {
      margin: 8px;
      & .filepond--file-wrapper {
        // background-color: var(--neutral-white);
        border-radius: 6px;
        & .filepond--file-info {
          font-family: lato;
          font-size: 15px;
          font-weight: 500;
          color: var(--primary-dark-blue);
          & .filepond--file-info-main {
            font-size: 1em;
          }
        }
        & .filepond--file-action-button {
          padding: 6px;
          width: 32px;
          height: 32px;
          background: transparent;
          cursor: pointer;
          &:hover {
            box-shadow: 0;
            background-color: var(--neutral-grey-150);
            & svg path {
              fill: var(--semantic-red);
            }
          }
        }
        & svg path {
          fill: var(--primary-dark-blue);
        }
        & .filepond--image-preview-overlay-idle {
          mix-blend-mode: screen;
          color: rgba(255, 255, 255, 0.85);
        }
      }
    }
  }
`;

type FileDelete = {
  id: string;
  name: string;
};

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageCrop,
  FilePondPluginImageTransform
);

interface UploadPhotoProps {
  open: boolean;
  handleClose: () => void;
  onUploaded?: (url: string) => void;
  onError?: () => void;
}

const UploadPhotoModal: React.FC<UploadPhotoProps> = (
  props: UploadPhotoProps
) => {
  const { open, handleClose, onUploaded, onError } = props;

  const [files, setFiles] = useState<any[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [fileToBeDeleted, setFileToBeDeleted] = useState<
    FileDelete | undefined
  >(undefined);

  const propertyInfo = usePropertyInfo();
  const uploadPhotoResponse = useUploadPropertyPhoto();

  const getTags = () => {
    const tags: Tag[] = [];
    tags.push({ type: "Purpose", value: "Image" });
    return tags;
  };

  const handleUpload = async () => {
    let imageUrl = "";
    for (const file of files) {
      const model: TCreatePhotoModel = {
        accountIdentifier: propertyInfo.accountIdentifier,
        propertyId: propertyInfo.id,
        tags: getTags(),
        file: file,
      };

      imageUrl = await uploadPhotoResponse.mutateAsync(model, {
        onError: onError,
      });
    }

    setFiles([]);
    handleClose();
    if (onUploaded) onUploaded(imageUrl);
  };

  function filepondCustomLabel() {
    return renderToString(
      <Box className="upload-document-container">
        <Box>
          <UilFile />
        </Box>
        <Box>
          <Typography variant="h3" component="span">
            Drag and drop here or&nbsp;
          </Typography>
          <Typography variant="h3" component="span">
            browse files
          </Typography>
        </Box>
        <Typography variant="body3" mt={2}>
          Maximum image size of 2MB
        </Typography>
      </Box>
    );
  }

  // const SelectIcon = (props: any) => (
  //   <svg
  //     {...props}
  //     xmlns="http://www.w3.org/2000/svg"
  //     width="20"
  //     height="20"
  //     viewBox="0 0 0 0"
  //     fill="none"
  //   >
  //     <path
  //       d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z"
  //       fill="#021A3C"
  //     />
  //   </svg>
  // );

  return (
    <Box>
      {openDeleteModal && fileToBeDeleted && (
        <ConfirmDialog
          title="Delete Photo?"
          open={openDeleteModal}
          setOpen={(open: boolean) => setOpenDeleteModal(open)}
          onConfirm={() => {
            if (fileToBeDeleted !== undefined) {
              setFiles((prevFiles) => {
                return prevFiles.filter(
                  (file) => file.id !== fileToBeDeleted.id
                );
              });
              setFileToBeDeleted(undefined);
            }
          }}
        >
          Are you sure you want to delete '{fileToBeDeleted.name}'?
        </ConfirmDialog>
      )}
      <CustomizedDialog aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title">
          <Typography component="span" variant="h2" color="primary.dark">
            Upload Photo
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setFiles([]);
              handleClose();
            }}
            className="icon-dialog-close"
            size="large"
          >
            <UilMultiply />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* {documentTypes.isFetching ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : ( */}
          <Box>
            {/* {props.uploadSource ===
                DocumentUploadSourceEnum.PropertyOverview && (
                <Box>
                  <FormControl className={"form-control"}>
                    <Typography
                      variant="body3"
                      component="label"
                      className="input-label"
                    >
                      {"Group"}
                    </Typography>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      fullWidth
                      inputProps={{ maxLength: 80 }}
                      className={"form-control-field"}
                      onChange={(event) => setLabel(event.target.value)}
                    />
                  </FormControl>
                </Box>
              )} */}
            {/* <Box>
              <FormControl className={"form-control"}>
                <Typography
                  variant="body3"
                  component="label"
                  className="input-label"
                >{`Type`}</Typography>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="documentType"
                  value={uploadType}
                  onChange={(event) =>
                    setUploadType(event.target.value as DocumentType)
                  }
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                >
                  {documentTypes.data?.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box> */}
            <Box mt={2}>
              <Typography
                variant="body3"
                component="label"
                className="input-label"
              >
                Attach Photo
              </Typography>
            </Box>
            <Box>
              <FilePond
                files={files}
                labelMaxFileSizeExceeded={"File is too large"}
                allowFileSizeValidation={true}
                maxFileSize={"2MB"}
                maxFiles={1}
                // allowReorder={true}
                allowMultiple={false}
                onupdatefiles={(files) => {
                  let validFiles = files.filter(
                    (f) => f.status !== FileStatus.LOAD_ERROR
                  );
                  setFiles(validFiles);
                }}
                allowFileTypeValidation={true}
                acceptedFileTypes={["image/jpg", "image/jpeg", "image/png"]}
                fileValidateTypeLabelExpectedTypesMap={{
                  "image/jpg": ".jpg",
                  "image/jpeg": ".jpeg",
                  "image/png": ".png",
                }}
                imageCropAspectRatio={"1:1"}
                allowImageCrop={true}
                allowImageTransform={true}
                labelFileTypeNotAllowed={
                  "One or more document types are not supported."
                }
                fileValidateTypeLabelExpectedTypes={
                  "Supported types: {allTypes}"
                }
                labelIdle={filepondCustomLabel()}
                styleButtonRemoveItemPosition="right"
                beforeRemoveFile={(file) => {
                  setOpenDeleteModal(true);
                  setFileToBeDeleted({
                    id: file.id,
                    name: file.filename,
                  });
                  return false;
                }}
                iconRemove='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#0a1a27" d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"/></svg>'
                iconRetry='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#0a1a27" d="M21,11a1,1,0,0,0-1,1,8.05,8.05,0,1,1-2.22-5.5h-2.4a1,1,0,0,0,0,2h4.53a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4.77A10,10,0,1,0,22,12,1,1,0,0,0,21,11Z"/></svg>'
              />
            </Box>
          </Box>
          {/* )} */}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setFiles([]);
              handleClose();
            }}
            disabled={uploadPhotoResponse.isLoading}
            size="small"
            className={"btn-secondary"}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpload}
            variant="contained"
            disabled={!files.length || uploadPhotoResponse.isLoading}
            size="small"
            className={"btn-tertiary"}
          >
            Save
          </Button>
        </DialogActions>
      </CustomizedDialog>
    </Box>
  );
};

export default UploadPhotoModal;
