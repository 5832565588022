/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  Typography,
  Button,
  Dialog,
  OutlinedInput,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  MenuItem,
  Select,
  Link,
  FormHelperText,
} from "@mui/material";
import { UilCalender } from "@iconscout/react-unicons";
import { UilMultiply } from "@iconscout/react-unicons";

import {
  IAssetOverview,
  IAssetOverviewService,
  IPOSelectList,
  IPOStateList,
} from "../model/propertyOverviewModel";
import { useSaveAssetOverview } from "../api/propertyOverviewService";
import {
  maxNotesField,
  maxNumberField,
  maxTextField,
  notesErrorMessage,
  numberErrorMessage,
  requiredErrorMessage,
  textErrorMessage,
} from "../util/errorTexts";
import UploadPhotoModal from "./UploadPhotoModal";
import { IUAAlertMessage } from "../../../usermanagement/util/userContextModels";
import { usePropertyInfo } from "../../util/propertyInfoContext";
import SimpleAlert from "../../../UI/view/SimpleAlert";

export const photoUploadSuccessMessage =
  "Your photo was successfully uploaded.";

interface Props {
  onUploadPhoto: (newUrl: string) => void;
  handlePopupClose: (shouldRefreshData?: boolean) => void;
  showDialog: boolean;
  accountId: string;
  propertyId: string;
  assetOverview: IAssetOverview;
  assetTypeList: IPOSelectList[];
  assetClassList: IPOSelectList[];
  states: IPOStateList[];
}

const errorColor = "#f43027";

const AssetOverviewEdit: React.FC<Props> = (props) => {
  const { assetTypeList, assetClassList, states } = props;
  const [openUpload, setOpenUpload] = React.useState(false);
  const [propertyUrl, setPropertyUrl] = React.useState(
    props.assetOverview.imageUrl
  );

  const defaultAlertMessage: IUAAlertMessage = {
    severityType: "success",
    message: "",
  };
  const [alertMessage, setAlertMessage] =
    React.useState<IUAAlertMessage>(defaultAlertMessage);
  const isFetching = useIsFetching();
  const yearBuilt = props.assetOverview.yearBuilt;
  const [isYearBuilt, setIsYearBuilt] = React.useState(null);
  const invalidDateString = "Please enter a valid date";
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IAssetOverviewService>({
    defaultValues: {
      ...props.assetOverview,
      yearBuilt: yearBuilt === undefined ? null : yearBuilt,
    },
  });

  const propertyInfo = usePropertyInfo();
  const patchAssetOverview = useSaveAssetOverview();
  const queryClient = useQueryClient();

  const invalidateListCache = () => {
    const uniqueId = propertyInfo.accountIdentifier + propertyInfo.id;
    queryClient.invalidateQueries(["getPropertyDetailData", uniqueId]);
  };

  const onUploaded = (newUrl: string) => {
    setPropertyUrl(newUrl);
    props.onUploadPhoto(newUrl);
    invalidateListCache();
    setAlertMessage({
      severityType: "success",
      message: photoUploadSuccessMessage,
    });
  };

  const onSubmit = handleSubmit((model: IAssetOverviewService) => {
    model.accountId = props.accountId;
    model.propertyId = props.propertyId;
    if (model.assetOverviewNotes?.trim().length === 0)
      model.assetOverviewNotes = undefined;
    patchAssetOverview.mutate(model, {
      onSuccess: (data) => {
        props.handlePopupClose(true);
      },
    });
  });

  const onError = (error: any) => {
    setIsYearBuilt(error);
  };

  const SelectIcon = (props: Props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 0 0"
      fill="none"
    >
      <path
        d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z"
        fill="#021A3C"
      />
    </svg>
  );

  return (
    <React.Fragment>
      <Dialog
        open={props.showDialog}
        aria-labelledby="asset-overview-edit"
        aria-describedby="asset-overview-edit-description"
        fullWidth
      >
        <DialogTitle id="asset-overview-edit">
          <Typography component="span" variant="h2" color="primary.dark">
            Edit Asset Overview
          </Typography>
          <IconButton
            aria-label="close"
            disabled={patchAssetOverview.isLoading}
            onClick={() => props.handlePopupClose(false)}
            className="icon-dialog-close"
            size="large"
          >
            <UilMultiply />
          </IconButton>
          <Typography component="div" variant="h3" color="neutral700.light">
            Please select or enter values for the fields and click the save
            button to update asset overview information.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <React.Fragment>
            <Box sx={{ width: "100%" }}>
              <Grid container spacing={1}>
                {/* Image */}
                <Grid item xs={6}>
                  <FormControl className={"form-control"}>
                    <Typography variant="body3" component="label">
                      Photo
                    </Typography>
                    <Box display="flex">
                      <img
                        style={{ borderRadius: 5 }}
                        width="60px"
                        height="63px"
                        alt="property"
                        src={propertyUrl}
                      />
                      <Box
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginLeft: 8,
                        }}
                      >
                        <Link
                          component="button"
                          variant="body2"
                          underline="always"
                          onClick={() => setOpenUpload(true)}
                        >
                          Change photo
                        </Link>
                        {/* <a href="#">Change photo</a> */}
                      </Box>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  direction="column"
                  display="flex"
                  justifyContent="flex-end"
                >
                  {alertMessage.message !== "" && (
                    <SimpleAlert
                      severityType={alertMessage.severityType}
                      message={alertMessage.message}
                      onClose={() => setAlertMessage(defaultAlertMessage)}
                      alertStyles={{ width: "100%" }}
                      styles={{ position: "inherit", width: "100%" }}
                    />
                  )}
                </Grid>
                {/* Address
              <Grid item xs={12}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Address*
                  </Typography>
                  <TextField
                    size="small"
                    id="street"
                    variant="outlined"
                    {...register("street", {
                      required: requiredErrorMessage,
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.street)}
                    helperText={
                      Boolean(errors?.street) ? errors?.street?.message : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* City */}
                <Grid item xs={6}>
                  <FormControl className={"form-control"}>
                    <Typography variant="body3" component="label">
                      City*
                    </Typography>
                    <TextField
                      size="small"
                      id="city"
                      variant="outlined"
                      {...register("city", {
                        required: requiredErrorMessage,
                        maxLength: {
                          value: maxTextField,
                          message: textErrorMessage,
                        },
                      })}
                      error={Boolean(errors?.city)}
                      helperText={
                        Boolean(errors?.city) ? errors?.city?.message : null
                      }
                      className={"form-control-field"}
                    />
                  </FormControl>
                </Grid>
                {/* zip code */}
                <Grid item xs={6}>
                  <FormControl className={"form-control"}>
                    <Typography variant="body3" component="label">
                      ZIP Code*
                    </Typography>
                    <TextField
                      size="small"
                      id="zip-code"
                      variant="outlined"
                      type="number"
                      {...register("zip", {
                        required: requiredErrorMessage,
                        min: {
                          value: 0,
                          message: numberErrorMessage,
                        },
                        max: {
                          value: 99999,
                          message: numberErrorMessage,
                        },
                      })}
                      error={Boolean(errors?.zip)}
                      helperText={
                        Boolean(errors?.zip) ? errors?.zip?.message : null
                      }
                      className={"form-control-field"}
                    />
                  </FormControl>
                </Grid>
                {/* state */}
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    rules={{
                      validate: {
                        isRequired: (value) =>
                          value !== states[0].key || requiredErrorMessage,
                      },
                    }}
                    name={"state"}
                    render={({ field, fieldState }) => (
                      <FormControl className={"form-control"}>
                        <Typography variant="body3" component="label">
                          State*
                        </Typography>
                        <Select
                          labelId="state"
                          displayEmpty
                          input={<OutlinedInput />}
                          className={"form-control-field"}
                          error={Boolean(errors?.state)}
                          IconComponent={SelectIcon}
                          {...field}
                        >
                          {states.map((state) => (
                            <MenuItem key={state.key} value={state.key}>
                              {state.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  {Boolean(errors.state) && (
                    <FormHelperText style={{ color: errorColor }}>
                      {errors.state?.message}
                    </FormHelperText>
                  )}
                </Grid>
                {/* asset type */}
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={"assetTypeId"}
                    rules={{
                      validate: {
                        isRequired: (value) =>
                          value !== assetTypeList[0].key ||
                          requiredErrorMessage,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <FormControl className={"form-control"}>
                        <Typography variant="body3" component="label">
                          Asset Type*
                        </Typography>
                        <Select
                          labelId="asset-type"
                          displayEmpty
                          input={<OutlinedInput />}
                          className={"form-control-field"}
                          error={Boolean(errors?.assetTypeId)}
                          IconComponent={SelectIcon}
                          {...field}
                        >
                          {assetTypeList.map((assetTypeObj) => (
                            <MenuItem
                              key={assetTypeObj.key}
                              value={assetTypeObj.key}
                            >
                              {assetTypeObj.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  {Boolean(errors.assetTypeId) && (
                    <FormHelperText style={{ color: errorColor }}>
                      {errors.assetTypeId?.message}
                    </FormHelperText>
                  )}
                </Grid>
                {/* Asset class */}
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    rules={{
                      validate: {
                        isRequired: (value) =>
                          value !== assetClassList[0].key ||
                          requiredErrorMessage,
                      },
                    }}
                    name={"assetClassId"}
                    render={({ field, fieldState }) => (
                      <FormControl className={"form-control"}>
                        <Typography variant="body3" component="label">
                          Asset Class*
                        </Typography>
                        <Select
                          labelId="asset-class"
                          displayEmpty
                          input={<OutlinedInput />}
                          className={"form-control-field"}
                          error={Boolean(errors?.assetClassId)}
                          IconComponent={SelectIcon}
                          {...field}
                        >
                          {assetClassList.map((assetClassObj) => (
                            <MenuItem
                              key={assetClassObj.key}
                              value={assetClassObj.key}
                            >
                              {assetClassObj.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  {Boolean(errors.assetClassId) && (
                    <FormHelperText style={{ color: errorColor }}>
                      {errors.assetClassId?.message}
                    </FormHelperText>
                  )}
                </Grid>
                {/* Year Built */}
                <Grid item xs={6}>
                  <FormControl className={"form-control"}>
                    <Typography
                      variant="body3"
                      component="label"
                    >{`Year Built`}</Typography>
                    <Controller
                      name="yearBuilt"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          inputRef={field.ref}
                          views={["year", "month", "day"]}
                          onChange={field.onChange}
                          value={field.value}
                          components={{
                            OpenPickerIcon: UilCalender,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // error={Boolean(errors?.yearBuilt)}
                              helperText={
                                isYearBuilt !== null ? invalidDateString : null
                              }
                              className={
                                "form-control-field form-control-datepicker"
                              }
                            />
                          )}
                          onError={(error) => onError(error)}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} />
                {/* Notes */}
                <Grid item xs={12}>
                  <FormControl className={"form-control"}>
                    <Typography
                      variant="body3"
                      component="label"
                    >{`Notes`}</Typography>
                    <TextField
                      size="small"
                      variant="outlined"
                      multiline
                      rows={4}
                      className={"form-control-field form-control-textarea"}
                      error={Boolean(errors?.assetOverviewNotes)}
                      helperText={
                        Boolean(errors?.assetOverviewNotes)
                          ? errors?.assetOverviewNotes?.message
                          : null
                      }
                      {...register("assetOverviewNotes", {
                        validate: {
                          maxCharacters: (value) => {
                            if (!value) return true;
                            if (value?.trim().length > maxNotesField)
                              return notesErrorMessage;
                          },
                        },
                      })}
                    />
                  </FormControl>
                </Grid>
                {/* Number of buildings */}
                <Grid item xs={6}>
                  <FormControl className={"form-control"}>
                    <Typography variant="body3" component="label">
                      Number of Buildings
                    </Typography>
                    <TextField
                      size="small"
                      id="number-buildings"
                      variant="outlined"
                      type="number"
                      {...register("numBuildings", {
                        valueAsNumber: true,
                        min: {
                          value: 0,
                          message: numberErrorMessage,
                        },
                        max: {
                          value: maxNumberField,
                          message: numberErrorMessage,
                        },
                      })}
                      error={Boolean(errors?.numBuildings)}
                      helperText={
                        Boolean(errors?.numBuildings)
                          ? errors?.numBuildings?.message
                          : null
                      }
                      className={"form-control-field"}
                    />
                  </FormControl>
                </Grid>
                {/* Number of floors */}
                <Grid item xs={6}>
                  <FormControl className={"form-control"}>
                    <Typography variant="body3" component="label">
                      Number of Floors
                    </Typography>
                    <TextField
                      size="small"
                      id="number-floors"
                      variant="outlined"
                      type="number"
                      {...register("numFloors", {
                        valueAsNumber: true,
                        min: {
                          value: 0,
                          message: numberErrorMessage,
                        },
                        max: {
                          value: maxNumberField,
                          message: numberErrorMessage,
                        },
                      })}
                      error={Boolean(errors?.numFloors)}
                      helperText={
                        Boolean(errors?.numFloors)
                          ? errors?.numFloors?.message
                          : null
                      }
                      className={"form-control-field"}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </React.Fragment>
          {patchAssetOverview.isLoading && (
            <Box m="auto">
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            disabled={patchAssetOverview.isLoading}
            onClick={() => props.handlePopupClose(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={
              patchAssetOverview.isLoading ||
              isFetching > 0 ||
              isYearBuilt !== null
            }
          >
            Save
          </Button>
          {openUpload && (
            // <Button>hi</Button>
            <UploadPhotoModal
              handleClose={() => setOpenUpload(false)}
              open={openUpload}
              onUploaded={(newUrl: string) => onUploaded(newUrl)}
              onError={() => {
                setAlertMessage({
                  severityType: "error",
                  message: "documentUploadErrorMessage",
                });
              }}
            />
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AssetOverviewEdit;
