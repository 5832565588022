import { List, ListItem, ListSubheader, ListItemText, ClickAwayListener } from "@mui/material";
import headerStyles from "../headerStyles";
import { Link } from "react-router-dom";

interface AssetManagmentMenuProps {
  setLeftMenuAMCollapseClose: () => void;
}

const AssetManagmentMenu: React.FunctionComponent<AssetManagmentMenuProps> = (
  props
) => {
  const classes = headerStyles();

  return (
    <ClickAwayListener
    onClickAway={() => {
        props.setLeftMenuAMCollapseClose();
    }}
  >
    <List
      component="div"
      aria-labelledby="leftMenuAssetManagementCollapseContainer"
      disablePadding
      className={classes.leftMenuList}
      onClick={() => props.setLeftMenuAMCollapseClose()}
      subheader={
        <ListSubheader
          component="div"
          id="leftMenuAssetManagementCollapseContainer"
        ></ListSubheader>
      }
    >
      <ListItem button component={Link} to="/portfolios">
        <ListItemText primary="Portfolio" />
      </ListItem>
      <ListItem button component={Link} to="/propertylist">
        <ListItemText primary="Properties" />
      </ListItem>
      <ListItem button component={Link} to="/">
        <ListItemText primary="Tenant" />
      </ListItem>
      <ListItem button component={Link} to="/">
        <ListItemText primary="Vendors" />
      </ListItem>
    </List>
    </ClickAwayListener>
  );
};

export default AssetManagmentMenu;
