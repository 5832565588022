import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useAuthorizedApiAxios } from "../../../shared/api/authorizedAxios";
import { InfoModel } from "../model/infoModel";

export const useGetInfo = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();
  return useQuery(
    ["infoData"],
    () => {
      const source = axios.CancelToken.source();
      const url = `/Info`;

      let promise = authorizedApiAxios().get<InfoModel>(url, {
        cancelToken: source.token,
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => {
        return response!.data;
      },
    }
  );
};
