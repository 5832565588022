import React from "react";
import { Link } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  GridReadyEvent,
  GridApi,
  ColumnApi,
  ColDef,
  StatusPanelDef,
  ExcelStyle,
  FirstDataRenderedEvent,
} from "ag-grid-community";
import "ag-grid-enterprise";

import { usePortfolioInfo } from "../../util/portfolioInfoContext";
import { IPortfolioLoan } from "../models/portfolioLoans";
import { getPortfolioLoansColumns } from "../utils/portfolioLoansUtil";
import { getStatusPanels } from "../../../properties/leasing activity/util/gridTable";

interface PortfolioLoanGridProps {
  tableContent: IPortfolioLoan[];
  gridApi?: GridApi;
  gridColumnApi?: ColumnApi;
  onGridRender: (gridApi: GridApi, gridColumnApi: ColumnApi) => void;
}

const PortfolioLoansGrid: React.FC<PortfolioLoanGridProps> = (props) => {
  const { tableContent, gridColumnApi, onGridRender } = props;
  const portfolioInfo = usePortfolioInfo();

  const columnDefs: ColDef[] = getPortfolioLoansColumns();

  const statusBar: { statusPanels: StatusPanelDef[] } = getStatusPanels();

  const autoSizeAll = () => {
    setTimeout(() => gridColumnApi?.autoSizeAllColumns(), 500);// TODO remove setTimeout when Ag-grid fixes autoSizeColumns on React 18 
  };

  const onGridReady = (event: GridReadyEvent) => {
    onGridRender(event.api, event.columnApi);

    if (tableContent === undefined || tableContent.length === 0) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }
  };

  const aggFuncs = {
    // this overrides the grids built-in sum function
    list: (values: any) => {
      let listOfItems = "";

      values.forEach((value: any) => (listOfItems += value + ", "));
      listOfItems = listOfItems.replace(/,\s*$/, "");

      return listOfItems;
    },
    distinctcount: (values: any) => {
      const testCnt: string[] = [];
      values.forEach((value: any) => {
        testCnt.push(value);
      });
      let test = new Set(testCnt);
      return test.size;
    },
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    autoSizeAll();
  };

  const LinkComponent = (props: any) => {
    const propertyId = props.data?.propertyId;

    return (
      <Link
        target="_blank"
        href={`/propertyView/ar?propertyId=${propertyId}&accountIdentifier=${portfolioInfo.accountIdentifier}`}
      >
        {props.value ?? ""}
      </Link>
    );
  };

  const excelStyles: ExcelStyle[] = [
    {
      id: "alignRight",
      alignment: {
        horizontal: "Right",
      },
    },
    {
      id: "dateFormat",
      dataType: "DateTime",
      numberFormat: { format: "mm/dd/yyyy;@" },
    },
    {
      id: "currencyFormat",
      numberFormat: { format: "\u0024 #,##0.00" },
    },
  ];

  return (
    <div className="standard-content">
      <div
        className="table-standard-toggle-container"
        style={{ width: "100%" }}
      >
        <div
          id="property-loans-content"
          className="example-wrapper"
          style={{ width: "100%" }}
        >
          <div
            id="myGrid"
            className="ag-theme-alpine ag-theme-alpine-container-override"
            style={{ width: "100%" }}
          >
            <AgGridReact
              excelStyles={excelStyles}
              onGridReady={onGridReady}
              columnDefs={columnDefs}
              rowData={tableContent}
              onFirstDataRendered={onFirstDataRendered}
              suppressAggFuncInHeader
              aggFuncs={aggFuncs}
              statusBar={statusBar}
              enableRangeSelection={true}
              autoGroupColumnDef={{ minWidth: 100 }}
              sortingOrder={["desc", "asc"]}
              defaultColDef={{
                minWidth: 120,
                resizable: true,
                filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
              }}
              components={{
                linkComponent: LinkComponent,
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioLoansGrid;
