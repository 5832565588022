import React, {
  ReactElement,
  useState,
  useMemo,
  useContext,
  useRef,
  useEffect,
} from "react";
import Drawer from "@mui/material/Drawer";
import BarChartIcon from "@mui/icons-material/BarChart";
import TimelineIcon from "@mui/icons-material/Timeline";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import AddIcon from "@mui/icons-material/Add";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
  Switch,
} from "@mui/material";
import { useIsFetching } from "@tanstack/react-query";

import { useGetWidgetCategories, useGetWidgets } from "../api/dashboardService";
import useStyles from "./dashboardStyles";
import {
  DashboardTypeEnum,
  WidgetCategoryEnum,
} from "../models/dashboardEnums";
import PortfolioContext from "../../../portfolio/util/portfolioContext";
import { hasSpecificPermission } from "../../../portfolio/util/accountPermissions";
import { capitalizeFirstLetter } from "../../../usermanagement/util/capitalizeFirstLetter";
import { EAccountPermission } from "../../../usermanagement/model/editUserModel";

interface Props {
  dashboardType: DashboardTypeEnum;
  isInAddWidgetMode: boolean;
  closeDrawer: () => void;
  accountId?: string;
}

const DashboardAddWidget: React.FC<Props> = (props) => {
  const [widgetCategories, setWidgetCategories] = useState<Map<number, string>>(
    new Map()
  );
  const [widgetCategory, setWidgetCategory] = useState<WidgetCategoryEnum>();
  const [switchChecked, setSwitchChecked] = useState(false);

  const hasComputedWidgetCategories = useRef<boolean>(false);

  const classes = useStyles();
  const isFetching = useIsFetching();
  const widgetsQuery = useGetWidgets(
    props.accountId || "",
    props.dashboardType,
    widgetCategory
  );
  const widgetCategoriesQuery = useGetWidgetCategories(props.dashboardType);
  const { accountPermissions } = useContext(PortfolioContext);

  const hasWidgetConfigPermission = useMemo(() => {
    if (!props.accountId) return false;

    return hasSpecificPermission(
      accountPermissions,
      props.accountId,
      EAccountPermission.CONFIGURE_WIDGETS
    );
  }, [accountPermissions, props.accountId]);

  /**
   * Computes the widget categories for the widget config dropdown. It is dependent on the widgets that the user sees in the first place.
   */
  useEffect(() => {
    const widgetCatMap = new Map<number, string>();

    if (hasComputedWidgetCategories.current) return;

    if (
      !widgetCategoriesQuery.isSuccess ||
      !widgetCategoriesQuery.data ||
      !widgetsQuery.data ||
      !widgetsQuery.isSuccess
    )
      return;

    hasComputedWidgetCategories.current = true;
    // All the ids of widgets that the user can currently view. - this depends on which widgets are "checked" in Widget Configuration
    const widgetCategoryIds: number[] = [];

    for (const widget of widgetsQuery.data) {
      // isAvailable property tells whether a widget is to be seen or not seen by a user
      if (widgetCategoryIds.includes(widget.id) || !widget.isAvailable)
        continue;
      widgetCategoryIds.push(widget.widgetCategoryId);
    }

    // We make sure that we only include those widget categories for which there is at least one widget in the list of available widgets
    for (const widgetCategory of widgetCategoriesQuery.data)
      if (widgetCategoryIds.includes(widgetCategory.id))
        widgetCatMap.set(widgetCategory.id, widgetCategory.description);

    setWidgetCategories(widgetCatMap);
  }, [
    widgetCategoriesQuery.data,
    widgetCategoriesQuery.isSuccess,
    widgetsQuery.data,
    widgetsQuery.isSuccess,
  ]);

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSwitchChecked(event.target.checked);

  const getIcon = function (type: string): ReactElement {
    switch (type) {
      case "line":
        return <TimelineIcon className={classes.widgetIcon} />;
      case "area":
        return <BarChartIcon className={classes.widgetIcon} />;
      case "bar":
        return <BarChartIcon className={classes.widgetIcon} />;
    }

    return <AddIcon className={classes.widgetIcon} />;
  };

  const SelectIcon = (props: Props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  return (
    <Drawer
      anchor={"right"}
      open={props.isInAddWidgetMode}
      variant={"persistent"}
      className={classes.addWidgetDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {isFetching > 0 ? (
        <Box m="auto">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container className={classes.addWidgetContainer}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  onClick={props.closeDrawer}
                  className={classes.addWidgetCloseIcon}
                >
                  <CloseIcon />
                </Button>
              </Grid>
            </Grid>
            <Typography variant="h6" paragraph={true}>
              <DashboardIcon
                color="primary"
                className={classes.addWidgetIcon}
              />{" "}
              Add Widget
            </Typography>
            {hasWidgetConfigPermission && (
              <React.Fragment>
                <Switch checked={switchChecked} onChange={handleSwitch} />
                Show all widgets
              </React.Fragment>
            )}
            <Typography variant="body2" paragraph={true}>
              Please drag &amp; drop the applicable widget(s) below onto the
              dashboard in the desired location
            </Typography>
            <Grid item xs={12}>
              <Select
                style={{ width: "100%" }}
                id="widgetCategory"
                name="widgetCategory"
                value={widgetCategory || ""}
                displayEmpty
                onChange={(event) => {
                  setWidgetCategory(event.target.value as WidgetCategoryEnum);
                }}
                className={classes.addWidgetSelect}
                IconComponent={SelectIcon}
              >
                <MenuItem key="select" value="">
                  Select category
                </MenuItem>
                {widgetCategoriesQuery.data?.map((widgetCategory) => {
                  const showWidgetCategory =
                    switchChecked || widgetCategories.get(widgetCategory.id)
                      ? true
                      : false;

                  return (
                    <MenuItem
                      key={widgetCategory.id}
                      value={widgetCategory.id}
                      style={!showWidgetCategory ? { display: "none" } : {}}
                    >
                      {capitalizeFirstLetter(widgetCategory.description)}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <List className={classes.addWidgetList}>
                {widgetsQuery.data?.map((element) => {
                  const showWidget = element.isAvailable || switchChecked;

                  return (
                    <ListItem
                      key={element.id}
                      alignItems="flex-start"
                      style={!showWidget ? { display: "none" } : {}}
                    >
                      <ListItemIcon
                        className={classes.widgetIcon}
                        draggable={true}
                        onDragStart={(e) =>
                          e.dataTransfer.setData(
                            "text/plain",
                            JSON.stringify({
                              widgetId: element.id,
                              widgetName: element.name,
                              widgetCategoryId: element.widgetCategoryId,
                              w: element.defaultWidth,
                              h: element.defaultHeight,
                              minW: element.minimumWidth,
                              maxW: element.maximumWidth,
                              minH: element.minimumHeight,
                              maxH: element.maximumHeight,
                            })
                          )
                        }
                      >
                        {getIcon(element.type)}
                      </ListItemIcon>
                      <ListItemText className={classes.addWidgetText}>
                        <Typography variant="body2">{element.name}</Typography>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Drawer>
  );
};

export default DashboardAddWidget;
