import React, { useEffect, useMemo, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Box, CircularProgress, IconButton, Typography, } from "@mui/material";

import PortfolioRRGrids from "./PortfolioRRGrids";
import PortfolioRRAssetSummary from "./PortfolioRRAssetSummary";
import { useGetPortfolioRentRoll } from "../api/portfolioRentRoll";
import {
  assetSummaryColumnNames,
  computeDateWithinRange,
} from "../util/portfolioRentRollUtil";
import PortfoliosStateContext from "../../context/PortfoliosStateContext";

import Drawer from '@mui/material/Drawer';
import { styled, useTheme } from "@mui/material/styles";
import { UilAngleLeft } from "@iconscout/react-unicons";
import { UilTimes } from '@iconscout/react-unicons';

const drawerSummaryWidth = 420;
const drawerSummaryWidthMobile = 300;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const PortfolioRentRoll: React.FC = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerSummaryOpen = () => {
    setOpen(true);
  };

  const handleDrawerSummaryClose = () => {
    setOpen(false);
  };

  const { rentRollDate, updateRentRollDate } = useContext(
    PortfoliosStateContext
  );
  const location = useLocation();
  const queryParams = location.search;
  const parsedQueryParams = useMemo(() => {
    const newParams = queryString.parse(queryParams);
    return newParams;
  }, [queryParams]);

  const [assetSummary, setAssetSummary] = useState<number[]>([
    100, 150, 250, 125, 0,
  ]);
  const [filteredAssetSummary, setFilteredAssetSummary] = useState<number[]>([
    100, 150, 250, 125, 0,
  ]);

  const updateAssetSummary = (newAssetSummary: number[]) => {
    setAssetSummary(newAssetSummary);
  };

  const updateFilteredAssetSummary = (newFilteredAssetSummary: number[]) => {
    setFilteredAssetSummary(newFilteredAssetSummary);
  };

  const portfolioRRQuery = useGetPortfolioRentRoll(
    {
      portfolioId: parsedQueryParams.portfolioId as string,
      filterMonth: rentRollDate.getMonth() + 1,
      filterYear: rentRollDate.getFullYear(),
    },
    parsedQueryParams.accountIdentifier as string
  );

  const { data: portfolioRRData, isSuccess, isLoading } = portfolioRRQuery;

  /**
   * Re assigning date value to a valid date that lies between minDate and maxDate if it is not in that range already.
   */
  useEffect(() => {
    if (!isSuccess || portfolioRRData === undefined) return;

    const newDate = computeDateWithinRange(
      rentRollDate,
      new Date(portfolioRRData.minDate),
      new Date(portfolioRRData.maxDate)
    );

    if (newDate !== undefined) updateRentRollDate(newDate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, portfolioRRData]);

  const maxDate =
    portfolioRRData === undefined
      ? undefined
      : new Date(portfolioRRData.maxDate);
  const minDate =
    portfolioRRData === undefined
      ? undefined
      : new Date(portfolioRRData.minDate);

  return (
    <React.Fragment>
      <div className={"portfolios-pane"}>
        {isLoading && (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        )}
        {isSuccess && portfolioRRData !== undefined && (
          <React.Fragment>
            <PortfolioRRGrids
              date={rentRollDate}
              updateDate={(newDate: Date) => updateRentRollDate(newDate)}
              minDate={minDate}
              maxDate={maxDate}            
              leaseRentRoll={portfolioRRData.portfolioRentRoll}
              otherRentRoll={portfolioRRData.portfolioRentRollOther}
              updateAssetSummary={updateAssetSummary}
              updateFilteredAssetSummary={updateFilteredAssetSummary}
            />
            <DrawerHeader sx={{
              position: "fixed",
              right: "10px",
              bottom: "50px",
              zIndex: 1201,
            }}
            className={"summary-drawer-action"}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerSummaryOpen}
                edge="start"
                sx={{
                  ...(open && { display: "none" }),
                }}
                size="large"
              >
                <UilAngleLeft />
                <Typography>Summary</Typography>
              </IconButton>
              <IconButton
                onClick={handleDrawerSummaryClose}
                sx={{
                  display: "none",
                  ...(open && { display: "flex" }),
                }}
                size="large"
              >
                <UilTimes />
                <Typography>Close</Typography>
              </IconButton>
            </DrawerHeader>
            <Drawer
              sx={{
                position: "fixed",
                width: drawerSummaryWidthMobile,
                [theme.breakpoints.up("sm")]: {
                  width: drawerSummaryWidth,
                },
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerSummaryWidthMobile,
                  [theme.breakpoints.up("sm")]: {
                    width: drawerSummaryWidth,
                  },
                },
              }}
              variant="persistent"
              anchor="right"
              open={open}
            >
              <div
                id="summaryContent"
                className="summary-content table-responsive collapse show"
              >
                <PortfolioRRAssetSummary
                  assetSummaryValues={assetSummary}
                  filteredSummaryValues={filteredAssetSummary}
                  columnNames={assetSummaryColumnNames}
                />
              </div>
            </Drawer>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default PortfolioRentRoll;
