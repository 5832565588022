import React from "react";
import InputMask from "react-input-mask";
import { TextField, TextFieldProps, Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiInputBase-input": {
        backgroundColor: "#f1f1f1",
      },
    },
  })
);

interface Props {
  mask: string;
  hasValue: boolean; // indicates that the field has a value
  TextFieldProps: TextFieldProps;
  addStyle: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  value: string;
}

/**
 * Wrapper around the input mask component for custom logic
 * @param props
 */
const MaterialInputMask: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { mask } = props;
  const [focused, setFocused] = React.useState(false); // focus state for ensuring MUI works properly

  /**
   * Processing before the masked input changes
   * @param newState
   * @param oldState
   * @param userInput
   */
  const beforeMaskedStateChange = ({ nextState }: { nextState: any }) => {
    const { value, selection } = nextState;
    // if the field isn't highlighted
    if (selection.length === 0) {
      let firstEmptyIndex = value.indexOf("_"); // the first index of an empty placeholder
      const firstEmptyIndexWithDash = value.indexOf("-_"); // the first index with an empty dash in the format
      // if there is an empty "section" in the formatted string
      if (
        firstEmptyIndex > firstEmptyIndexWithDash &&
        firstEmptyIndexWithDash !== -1
      )
        firstEmptyIndex = firstEmptyIndexWithDash;
      // correctly set the cursor index
      const cursorIndex =
        firstEmptyIndex !== -1 ? firstEmptyIndex : value.length;
      nextState.selection = {
        ...selection,
        start: cursorIndex,
        end: cursorIndex,
      };
    }
    return nextState;
  };

  return (
    <InputMask
      mask={mask}
      beforeMaskedStateChange={beforeMaskedStateChange}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onChange={props.onChange}
      value={props.value}
    >
      {/* material ui field */}
      <TextField
        {...props.TextFieldProps}
        InputLabelProps={{ shrink: props.hasValue || focused }}
        className={props.addStyle ? classes.root : ""}
      />
    </InputMask>
  );
};

export default MaterialInputMask;
