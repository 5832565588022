import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "../../../../shared/api/authorizedAxios";
import { DashboardGeneralDataFilter } from "../models/dashboardGeneralDataFilter";
import { DashboardInfo } from "../models/dashboardInfo";
import { Dashboard, DashboardDelete } from "../models/dashboard";
import { DashboardModification } from "../models/dashboardModification";
import { DashboardList } from "../models/dashboardList";
import {
  DashboardTypeEnum,
  WidgetCategoryEnum,
} from "../models/dashboardEnums";
import { Widget } from "../models/widget";
import { WidgetCategory } from "../models/widgetCategory";
import { DashboardModifyDefault } from "../models/dashboardModifyDefault";

export const useGetDashboardData = (
  dashboardId?: number,
  accountIdentifier?: string,
  filter?: DashboardGeneralDataFilter
) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getDashboardGeneralData", dashboardId, accountIdentifier, filter],
    () => {
      const source = axios.CancelToken.source();
      const url = `/assetService/v1/dashboards/${dashboardId}/GetMainDashboardData`;

      let promise = authorizedApiAxios().post<DashboardInfo>(url, filter, {
        cancelToken: source.token,
        headers: accountIdentifier
          ? {
              [AccountIdentifierHeaderName]: accountIdentifier,
            }
          : {},
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => {
        return response!.data;
      },
      enabled: dashboardId !== undefined &&
      dashboardId !== 0 &&
      filter !== undefined &&  
      filter !== null &&
      filter.accountIdentifier !== undefined &&
      filter.bookId !== undefined && 
      filter.bookId !== "" &&               
      filter.denomination !== undefined &&
      filter.denomination !== ""
    }
  );
};

export const useGetDashboard = (id: number, accountIdentifier?: string) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getDashboard", id, accountIdentifier],
    () => {
      const source = axios.CancelToken.source();
      const url: string = `/assetService/v1/dashboards/${id}`;
      let promise = authorizedApiAxios().get<Dashboard>(url, {
        cancelToken: source.token,
        headers: accountIdentifier
          ? {
              [AccountIdentifierHeaderName]: accountIdentifier,
            }
          : {},
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => {
        return response!.data;
      },
      enabled: id !== undefined && id !== 0
    }
  );
};

export const useGetDashboardList = (dashboardType: DashboardTypeEnum) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getDashboards", dashboardType],
    () => {
      const source = axios.CancelToken.source();
      const url = `/assetService/v1/dashboards?dashboardType=${DashboardTypeEnum[dashboardType]}`;
      let promise = authorizedApiAxios().get<DashboardList[]>(url, {
        cancelToken: source.token,
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => {
        return response!.data;
      },
    }
  );
};

export const useGetWidgets = (
  accountIdentifier: string,
  dashboardType: DashboardTypeEnum,
  widgetCategory?: WidgetCategoryEnum
) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getWidgets", dashboardType, widgetCategory],
    () => {
      const source = axios.CancelToken.source();
      let url: string = `/assetService/v1/dashboardTypes/${dashboardType}/Widgets`;
      if (widgetCategory) {
        url += `?widgetCategory=${WidgetCategoryEnum[widgetCategory]}`;
      }

      let promise = authorizedApiAxios().get<Widget[]>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: accountIdentifier,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => {
        return response!.data;
      },
      enabled: accountIdentifier !== undefined && accountIdentifier !== "",
    }
  );
};

export const useGetWidgetCategories = (dashboardType: DashboardTypeEnum) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getWidgetCategories", dashboardType],
    () => {
      const source = axios.CancelToken.source();
      let url: string = `/assetService/v1/dashboardTypes/${dashboardType}/WidgetCategories`;
      let promise = authorizedApiAxios().get<WidgetCategory[]>(url, {
        cancelToken: source.token,
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => {
        return response!.data;
      },
    }
  );
};

export const useSaveDashboard = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation(async (item: DashboardModification) => {
    const source = axios.CancelToken.source();
    let promise: Promise<AxiosResponse<Dashboard>>;
    let headers = undefined;
    if (item.accountIdentifier) {
      headers = {
        [AccountIdentifierHeaderName]: item.accountIdentifier!,
      };
    }
    if (item.dashboardId) {
      let url = `assetService/v1/dashboards/${item.dashboardId}`;
      promise = authorizedApiAxios().patch<Dashboard>(url, item, {
        cancelToken: source.token,
        headers: headers,
      });
    } else {
      let url = `assetService/v1/dashboards`;
      promise = authorizedApiAxios().post<Dashboard>(url, item, {
        cancelToken: source.token,
        headers: headers,
      });
    }

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useSetDashboardAsPublic = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation(async (item: DashboardModifyDefault) => {
    const source = axios.CancelToken.source();
    let url = `/AssetService/v1/Dashboards/${item.id}/SetDashboardAsPublic`;
    let promise = authorizedApiAxios().patch(
      url,
      {},
      {
        cancelToken: source.token,
        headers: { [AccountIdentifierHeaderName]: item.accountIdentifier! },
      }
    );

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useUnsetDashboardAsPublic = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation(async (item: DashboardModifyDefault) => {
    const source = axios.CancelToken.source();
    let url = `/AssetService/v1/Dashboards/${item.id}/UnsetDashboardAsPublic`;
    let promise = authorizedApiAxios().delete(url, {
      cancelToken: source.token,
      headers: { [AccountIdentifierHeaderName]: item.accountIdentifier! },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useSetDashboardAsDefault = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation(async (item: DashboardModifyDefault) => {
    const source = axios.CancelToken.source();
    let url = `assetService/v1/dashboards/${item.id}/SetDashboardAsDefault`;
    let promise = authorizedApiAxios().patch(
      url,
      {},
      {
        cancelToken: source.token,
        headers: { [AccountIdentifierHeaderName]: item.accountIdentifier! },
      }
    );

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useUnsetDashboardAsDefault = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation(async (item: DashboardModifyDefault) => {
    const source = axios.CancelToken.source();
    let url = `assetService/v1/dashboards/${item.id}/UnsetDashboardAsDefault`;
    let promise = authorizedApiAxios().delete(url, {
      cancelToken: source.token,
      headers: { [AccountIdentifierHeaderName]: item.accountIdentifier! },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useDeleteDashboard = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();
  const queryClient = useQueryClient();

  return useMutation(
    (dashboardInfo: DashboardDelete) => {
      const source = axios.CancelToken.source();

      const url = `assetService/v1/dashboards/${dashboardInfo.dashboardId}`;
      let promise = authorizedApiAxios().delete<string>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: dashboardInfo.accountId,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      onMutate: async (dashboardInfo: DashboardDelete) => {
        await queryClient.cancelQueries([
          "getDashboards",
          dashboardInfo.dashboardType,
        ]);

        const previousDashboardList = queryClient.getQueryData<
          UseQueryResult<DashboardList[], unknown>
        >(["getDashboards", dashboardInfo.dashboardType]);

        // Optimistically update to the new value
        if (previousDashboardList && previousDashboardList.data) {
          const prevDashes = previousDashboardList.data.slice();
          const dashIndex = prevDashes
            .map((dashboard) => {
              return dashboard.id;
            })
            .indexOf(dashboardInfo.dashboardId);
          prevDashes.splice(dashIndex, 1);
          queryClient.setQueryData<DashboardList[]>(
            ["getDashboards", dashboardInfo.dashboardType],
            prevDashes
          );
        }

        return { previousDashboardList };
      },
      onError: (err, dashboardInfo: DashboardDelete, context) => {
        if (
          context?.previousDashboardList &&
          context?.previousDashboardList.data
        )
          queryClient.setQueryData<DashboardList[]>(
            ["getDashboards", dashboardInfo.dashboardType],
            context.previousDashboardList.data
          );
      },
      onSettled: (data, error, dashboardInfo: DashboardDelete) => {
        queryClient.invalidateQueries([
          "getDashboards",
          dashboardInfo.dashboardType,
        ]);
      },
    }
  );
};
