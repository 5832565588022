import { AppliedFilter } from "../model/tenant";

export const getInitialAppliedFilterValues = () => {
    const filterValues: AppliedFilter[] = [
        {value: 0, percent: 0},
        {value: 0, percent: 0},
        {value: 0, percent: 0},
        {value: 0, percent: 0},
        {value: 0, percent: 0},
        {value: 0, percent: 0}
    ];

    return filterValues;
};