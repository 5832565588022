import React from "react";
import { Link } from "@mui/material";

const UserViewLink = (props: any) => {
  return (
    <Link variant="body2" href={`mailto:${props.value}`} gutterBottom>
      {props.value}
    </Link>
  );
};

export default UserViewLink;
