import axios from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import queryString from "query-string";

import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "../../../../shared/api/authorizedAxios";
import { PortfolioDetailItem } from "../model/portfolioDetailsListModel";
import { TDeletePortfolioModel } from "../model/deletePortfolioModel";
import {
  ICreatePortfolioModel,
  IPostPortfolio,
  IPutPortfolio,
  IPutPortfolioModel,
} from "../model/createPortfolioModel";
import { TPortfolioListFilter } from "../model/portfolioListFilter";
import { TPortfolioList } from "../model/portfolioListModel";

export const useGetPortfolioList = (accountIdentifier?: string) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getPortfolioList", accountIdentifier],
    () => {
      const source = axios.CancelToken.source();
      const url: string = `/assetService/v1/portfolios`;

      let promise = authorizedApiAxios().get<TPortfolioList>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: accountIdentifier!,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      enabled: accountIdentifier !== undefined && accountIdentifier?.length > 0,
      select: (response) => {
        return response.data;
      },
    }
  );
};

export const useGetPortfolioDetailsList = (filter: TPortfolioListFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();
  const uniqueIdForQuery =
    filter.accountIdentifier +
    filter.startMonth +
    filter.startYear +
    filter.endMonth +
    filter.endYear;
  return useQuery(
    ["getPortfolioDetailsList", uniqueIdForQuery],
    () => {
      const source = axios.CancelToken.source();
      const qry = queryString.stringify(filter);
      const url = `/assetService/v1/portfolios/properties?${qry}`;

      let promise = authorizedApiAxios().get<PortfolioDetailItem[]>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: filter.accountIdentifier,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      enabled: filter.accountIdentifier.trim().length !== 0,
      select: (response) => {
        const portfolioGridDetails = response!.data;
        for (const portfolioGridItem of portfolioGridDetails) {
          portfolioGridItem.accountIdentifier = filter.accountIdentifier;
        }
        return response.data;
      },
      staleTime: Infinity,
    }
  );
};

export const useCreatePortfolio = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: ICreatePortfolioModel) => {
    const portfolioPostItem: IPostPortfolio = {
      name: item.name,
      code: item.code,
      description: item.description,
      portfolioType: item.portfolioType,
      properties: item.properties,
    };

    const source = axios.CancelToken.source();

    const url: string = `assetService/v1/portfolios`;
    let promise = authorizedApiAxios().post<string>(url, portfolioPostItem, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: item.accountIdentifier,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useEditPortfolio = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: IPutPortfolioModel) => {
    const portfolioPostItem: IPutPortfolio = {
      name: item.name,
      description: item.description,
      properties: item.properties,
    };

    const source = axios.CancelToken.source();

    const url: string = `assetService/v1/portfolios/${item.portfolioId}`;
    let promise = authorizedApiAxios().put<string>(url, portfolioPostItem, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: item.accountId,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useDeletePortfolio = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: TDeletePortfolioModel) => {
    const source = axios.CancelToken.source();

    const url: string = `assetService/v1/portfolios/${item.portfolioId}`;
    let promise = authorizedApiAxios().delete<string>(url, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: item.accountIdentifier,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};
