import axios from "axios";
import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "../../../../shared/api/authorizedAxios";
import { useQuery } from "@tanstack/react-query";

export interface GLTreeOption {
  id: string;
  name: string;
  typeId: string;
  typeName: string;
}

interface GLTreeProps {
  accountIdentifier: string;
  propertyId: string;
}

export const useGetGLTreeOptions = (glTreeProps: GLTreeProps) => {
  const { accountIdentifier, propertyId } = glTreeProps;
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["glTree" + propertyId],
    () => {
      const source = axios.CancelToken.source();
      const url: string = `/assetService/v1/lookups/${propertyId}/financialgltrees`;

      const promise = authorizedApiAxios().get<GLTreeOption[]>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: accountIdentifier,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => {
        return response?.data.sort((a, b) => {
          let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      },
    }
  );
};
