import axios from "axios";
import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "../../../../shared/api/authorizedAxios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { TDocumentListFilter } from "../model/documentFilter";
import { Document } from "../model/documentListModel";
import { TCreateDocumentModel } from "../model/createDocumentModel";
import { TDeleteDocumentModel } from "../model/deleteDocumentModel";
import { TDownloadDocumentModel } from "../model/downloadDocumentModel";
import { fromArrayOfObjectsToQueryString } from "../util/fromArrayOfObjectsToQueryString";
import { DocumentTypeModel } from "../model/documentTypeModel";

export const useGetDocumentList = (filter: TDocumentListFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getDocumentList", filter],
    () => {
      const source = axios.CancelToken.source();
      const tags = filter.tags ? fromArrayOfObjectsToQueryString(
        { tags: filter.tags },
        false
      ) : "";

      let url: string = `/documentService/v1/documents?1=1`;
      if(filter.documentType) url += `&documentType=${filter.documentType}`;
      if(filter.propertyId) url += `&propertyId=${filter.propertyId}`;
      if(filter.documentSource) url += `&documentSource=${filter.documentSource}`;
      if(tags)  url += `&${tags}`;

      let promise = authorizedApiAxios().get<Document[]>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: filter.accountIdentifier,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => {
        return response.data;
      },
      enabled: filter.accountIdentifier !== "",
    }
  );
};

export const useUploadDocument = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: TCreateDocumentModel) => {
    const source = axios.CancelToken.source();
    let formData = new FormData();

    //Note:  Still not working ...  we don't have the file
    // item.file
    formData.append("file", item.file?.file);
    formData.append("documentType", item.documentType);
    formData.append("propertyId", item.propertyId || "");
    formData.append("source", item.source.toString());
    if(item.label)
      formData.append("label", item.label);
    let i = 0;
    for (i = 0; i < item.tags.length; i++) {
      formData.append("tags[" + i.toString() + "].type", item.tags[i].type);
      formData.append("tags[" + i.toString() + "].value", item.tags[i].value);
    }

    const url: string = `documentService/v1/documents`;
    let promise = authorizedApiAxios().post<string>(url, formData, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: item.accountIdentifier,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useDeleteDocument = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: TDeleteDocumentModel) => {
    const source = axios.CancelToken.source();

    const url: string = `documentService/v1/documents/${item.documentId}`;
    let promise = authorizedApiAxios().delete<string>(url, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: item.accountIdentifier,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useGetDocumentDownloadUrl = (item: TDownloadDocumentModel) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getDocumentDownloadUrl", item],
    () => {
      const source = axios.CancelToken.source();

      const url: string = `/documentService/v1/documents?${item.accountIdentifier}/download`;

      let promise = authorizedApiAxios().get<string>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: item.accountIdentifier,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => {
        return response!.data;
      },
    }
  );
};

export const UseDownloadDocuments = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation(async (downloadDocument: TDownloadDocumentModel) => {
    const url: string = `/documentService/v1/documents/${downloadDocument.documentId}/download`;

    const result = await authorizedApiAxios().get<string>(url, {
      headers: {
        [AccountIdentifierHeaderName]: downloadDocument.accountIdentifier,
      },
    });

    return result.data;
  });
};

/**
 * Using the temporary url, we make a get request to get the blob.
 * @param documentUrl url of the document received from the backend
 */
export const getDocumentBlob = async (documentUrl: string) => {
  const docBlob = await axios
    .get(`${documentUrl}`)
    .then((res) => res.data)
    .catch((error) => console.error("Blob not retrieved: ", error));
  return docBlob;
};

export const useGetDocumentTypes = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getDocumentTypes"],
    () => {
      const source = axios.CancelToken.source();

      const url: string = `/documentService/v1/documentTypes`;

      let promise = authorizedApiAxios().get<DocumentTypeModel[]>(url, {
        cancelToken: source.token,
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      staleTime: 600000,
      select: (response) => {
        return response!.data;
      },
    }
  );
};
