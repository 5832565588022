import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  AccountIdentifierHeaderName,
  useAuthorizedApiAxios,
} from "../../../shared/api/authorizedAxios";
import { TCreateGLTreeModel } from "../model/createGLTreeModel";
import { TDownloadDocumentModel } from "../model/downloadDocument";
// import { generatedGLTreeDocumentModel } from "../model/generatedGLDocumentModel";
import {
    // IChartOfAccounts,
    // IGlAccountTree,
    IGLAccountTreeResult,
} from "../model/glTreeModel";
import { IChartOfAccounts, IPutNegateActualBudget } from "../model/negateActualBudgetModel";

export const useGetGLAccountTrees = (accountId: string) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getGLAccountTrees", accountId],
    () => {
      const source = axios.CancelToken.source();
      const url = `AssetService/v1/Financials/GLAccountTrees`;

      let promise = authorizedApiAxios().get<IGLAccountTreeResult>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: accountId,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => response.data,
      enabled: accountId.trim() !== "",
    }
  );
};


export const useGetChartOfAccounts = (accountId: string) => {
    const authorizedApiAxios = useAuthorizedApiAxios();
  
    return useQuery(
      ["getChartOfAccounts", accountId],
      () => {
        const source = axios.CancelToken.source();
        const url = `AssetService/v1/Financials/ChartOfAccounts`;
  
        let promise = authorizedApiAxios().get<IChartOfAccounts[]>(url, {
          cancelToken: source.token,
          headers: {
            [AccountIdentifierHeaderName]: accountId,
          },
        });
  
        (promise as any).cancel = () => source.cancel();
        return promise;
      },
      {
        select: (response) => response.data,
        enabled: accountId.trim() !== "",
      }
    );
  };

  export const useUploadGLDocument = () => {
    const authorizedApiAxios = useAuthorizedApiAxios();
  
    return useMutation((item: TCreateGLTreeModel) => {
      const source = axios.CancelToken.source();
      let formData = new FormData();
  
      formData.append("file", item.file?.file);
      formData.append("treeType", item.accountTreeType);
      formData.append("accountingSystem", item.accountingSystem);
      formData.append("title", item.title);
      if (item.treeId !== undefined)
        formData.append("treeId", item.treeId);

      const url: string = `assetService/v1/financials/gltree`;
      let promise = authorizedApiAxios().post<string>(url, formData, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: item.accountIdentifier,
        },
      });
  
      (promise as any).cancel = () => source.cancel();
      return promise;
    });
  };
  
  export const useUpdateNegateActualBudget = (accountId: string) => {
    const authorizedApiAxios = useAuthorizedApiAxios();
  
    return useMutation((negateActualBudget: IPutNegateActualBudget) => {
      const source = axios.CancelToken.source();
  
      const url = `/AssetService/v1/Financials/ChartOfAccounts`;
      let promise = authorizedApiAxios().put<string>(url, negateActualBudget, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: accountId,
        },
      });
  
      (promise as any).cancel = () => source.cancel();
      return promise;
    });
  };

  export const UseDownloadGLTreeDocuments = () => {
    const authorizedApiAxios = useAuthorizedApiAxios();
  
    return useMutation(async (downloadDocument: TDownloadDocumentModel) => {
      const source = axios.CancelToken.source();
      const url: string = `/AssetService/v1/financials/${downloadDocument.treeId}/download`;
  
      const result = await authorizedApiAxios().post(url, downloadDocument, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: downloadDocument.accountIdentifier,
        },
        responseType: "blob",
      });

      return result;
      // const result = await authorizedApiAxios().get<string>(url, {
      //   headers: {
      //     [AccountIdentifierHeaderName]: downloadDocument.accountIdentifier,
      //   },
      // });
  
      // return result.data;
    });
  };

  // export const useExportGLTreeDocument = () => {
  //   const authorizedApiAxios = useAuthorizedApiAxios();
  //   return useMutation(async (downloadDocument: TDownloadDocumentModel) => {
  //     const source = axios.CancelToken.source();
  //     const url: string = `assetService/v1/Financials`;

  //     const result = await authorizedApiAxios().post(url, downloadDocument, {
  //       cancelToken: source.token,
  //       headers: {
  //         [AccountIdentifierHeaderName]: downloadDocument.accountIdentifier,
  //       },
  //       responseType: "blob",
  //     });
  //     return result;
  //   });
  // };