import React, { useState, useContext } from "react";
import { useOidcUser, useOidc } from "@axa-fr/react-oidc";
import {
  Avatar,
  Box,
  Button,
  Fade,
  List,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  Badge,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  ListItemButton,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { TimelineDot } from "@mui/lab";
import { Link } from "react-router-dom";
import { UilAngleDown } from "@iconscout/react-unicons";
import { UilAngleUp } from "@iconscout/react-unicons";
import { UilUserCircle } from "@iconscout/react-unicons";
import { UilSetting } from "@iconscout/react-unicons";
import { UilBell } from "@iconscout/react-unicons";
import { UilInfoCircle } from "@iconscout/react-unicons";
import { UilDatabase } from "@iconscout/react-unicons";
import { UilSignOutAlt } from "@iconscout/react-unicons";

import PortfolioContext from "../../portfolio/util/portfolioContext";
import { UseGetZendeskPageUrl } from "../../zendesk/api/zendeskService";
import { ZendeskPageType } from "../../zendesk/model/zendeskPageType";
import HelpIcon from "@mui/icons-material/Help";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useNavigate } from "react-router-dom";
import {
  useDismissNotifications,
  useGetAlertNotifications,
} from "../../usermanagement/api/alertsService";
import { Notification } from "../../usermanagement/model/alertsModel";

const ITEM_HEIGHT = 48;
const menuItemStyles = {
  padding: "12px 16px",
  borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
  borderBottomWidth: "thin",
};

const UserOptions: React.FC = () => {
  const { oidcUser } = useOidcUser();
  const { login, logout } = useOidc();
  const accountId = oidcUser?.account_identifier ?? "";

  const queryClient = useQueryClient();

  const notificationsQuery = useGetAlertNotifications(accountId);
  const dismissNotifications = useDismissNotifications();

  const notificationsData = notificationsQuery.data;

  const [accountCollapseOpen, setAccountCollapseOpen] = useState(false);

  const { updateUserId, isSuperAdmin } = useContext(PortfolioContext);

  const getZendeskPageUrl = UseGetZendeskPageUrl();

  const btnZendeskLinkClickedHandler = async (
    zendeskPageType: ZendeskPageType
  ) => {
    const zendeskPageUrl = await getZendeskPageUrl.mutateAsync(zendeskPageType);

    if (zendeskPageUrl !== "") {
      window.open(zendeskPageUrl);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  function GetARNotification(props: Notification) {
    switch (props.notificationTypeId) {
      //User Mention notifications
      case 2:
      case 3:
        let mentionBodyArr = props.message.split("mentioned you on ");
        let mentionBody = mentionBodyArr[0] + "mentioned you on ";
        let mentionTenantAndDate = mentionBodyArr[1];
        let mentionTenantAndDateArr = mentionTenantAndDate.split(" (");
        let mentionTenant = mentionTenantAndDateArr[0];
        let mentionDate =
          mentionTenantAndDateArr[1] != null &&
          mentionTenantAndDateArr[1].length > 0
            ? " (" + mentionTenantAndDateArr[1]
            : "";
        return (
          <Typography variant="inherit" noWrap sx={{ fontWeight: "500" }}>
            {mentionBody}
            <span
              onClick={(e) => {
                e.stopPropagation();
                if (props.notificationTypeId === 2) {
                  navigate(`/propertyView/ar${props.queryString}`);
                } else {
                  navigate(
                    `/propertyView/propertyViewSnapshot${props.queryString}`
                  );
                }
              }}
              style={{ textDecoration: "underline" }}
            >
              {mentionTenant}
            </span>
            {mentionDate}
          </Typography>
        );

      //AR alert rule notifications
      default:
        let title = "";
        let body = "";
        const notificationSplit = props.message.split("-");
        const notificationSplitLength = notificationSplit.length;
        if (notificationSplitLength > 0) {
          title = notificationSplit[0].trim();
          if (notificationSplitLength > 1)
            body = notificationSplit.slice().splice(1).join("-");
          return (
            <Typography variant="inherit" noWrap sx={{ fontWeight: "500" }}>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/propertyView/ar${props.queryString}`);
                }}
                style={{ textDecoration: "underline" }}
              >
                {title}
              </span>{" "}
              - {body}
            </Typography>
          );
        }
    }
  }

  return oidcUser ? (
    <Box sx={{ display: "flex" }}>
      {/* Users */}
      <Button
        component={Link}
        to="/usermanagement"
        onClick={() => setAccountCollapseOpen(false)}
        sx={{
          margin: "0 8px",
          padding: "6px",
          maxHeight: "32px",
          minWidth: "32px",
          backgroundColor: "var(--neutral-white)",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "var(--neutral-grey-150)",
          borderRadius: "6px",
          boxShadow: "0 2px 2px 0 rgba(46, 57, 72, 0.06)",
          color: "var(--primary-dark-blue)",
          "&:hover": {
            background: "var(--neutral-white)",
          },
          "& svg": {
            maxHeight: "20px",
            maxWidth: "20px",
          },
          "&:hover svg": {
            fill: "var(--primary-blue)",
          },
        }}
      >
        <UilSetting />
      </Button>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{
          margin: "0 8px",
          padding: "6px",
          maxHeight: "32px",
          minWidth: "32px",
          backgroundColor: "var(--neutral-white)",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "var(--neutral-grey-150)",
          borderRadius: "6px",
          boxShadow: "0 2px 2px 0 rgba(46, 57, 72, 0.06)",
          color: "var(--primary-dark-blue)",
          "&:hover": {
            background: "var(--neutral-white)",
          },
          "& svg": {
            maxHeight: "20px",
            maxWidth: "20px",
          },
          "&:hover svg": {
            fill: "var(--primary-blue)",
          },
        }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Badge
          badgeContent={notificationsData?.totalNotDismissed ?? 0}
          color="primary"
          sx={{ zIndex: 0 }}
        >
          <UilBell />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            overflow: "auto",
          },
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {notificationsData &&
        notificationsData.notifications &&
        notificationsData.notifications.length > 0 ? (
          <div>
            <Box
              sx={{ justifyContent: "space-between", marginTop: "5px" }}
              display="flex"
            >
              <Box ml={2} sx={{ fontWeight: "bold" }}>
                Notifications
              </Box>
              <Box
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "bold",
                  color: "#076DFA",
                }}
                mr={2}
                onClick={(e) => {
                  e.stopPropagation();
                  dismissNotifications.mutate(
                    {
                      accountId: accountId,
                      notificationIds: [],
                      dismissAll: true,
                    },
                    {
                      onSuccess: (data) => {
                        queryClient.invalidateQueries([
                          "getArAlertsNotifications",
                          accountId,
                        ]);
                      },
                    }
                  );
                }}
              >
                Mark all as read
              </Box>
            </Box>
            <Divider light sx={{ marginTop: "8px" }} />
            {notificationsData?.notifications.map((notification, index) => {
              const unreadStyles = !notification.dismissed
                ? { backgroundColor: "#F6F8FB" }
                : {};
              const unreadDot = !notification.dismissed ? (
                <TimelineDot color="primary" sx={{ marginRight: "8px" }} />
              ) : null;
              // let title = "";
              // let body = "";
              // const notificationSplit = notification.message.split("-");
              // const notificationSplitLength = notificationSplit.length;
              // if (notificationSplitLength > 0) {
              //   title = notificationSplit[0].trim();
              //   if (notificationSplitLength > 1)
              //     body = notificationSplit.slice().splice(1).join("-");
              // }
              return (
                <MenuItem
                  key={notification.id}
                  style={{ ...menuItemStyles, ...unreadStyles }}
                  onClick={(e) => {
                    e.stopPropagation();
                    dismissNotifications.mutate(
                      {
                        accountId: accountId,
                        notificationIds: [notification.id],
                        dismissAll: false,
                      },
                      {
                        onSuccess: (data) => {
                          queryClient.invalidateQueries([
                            "getArAlertsNotifications",
                            accountId,
                          ]);
                        },
                      }
                    );
                  }}
                >
                  {unreadDot}
                  {GetARNotification(notification)}
                  {/* <Typography
                    variant="inherit"
                    noWrap
                    sx={{ fontWeight: "500" }}
                  >
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/propertyView/ar${notification.queryString}`);
                      }}
                      style={{ textDecoration: "underline" }}
                    >
                      {title}
                    </span>{" "}
                    - {body}
                  </Typography> */}
                </MenuItem>
              );
            })}
          </div>
        ) : (
          <Box mt={1} mr={2} mb={1} ml={2}>
            You have no notifications.
          </Box>
        )}
      </Menu>
      <Box
        sx={{
          marginLeft: "16px",
          paddingLeft: "16px",
          height: "32px",
          borderLeftWidth: "1px",
          borderLeftStyle: "solid",
          borderLeftColor: "neutral100.dark",
        }}
      />
      <ClickAwayListener
        onClickAway={() => {
          if (!accountCollapseOpen) return;
          setAccountCollapseOpen(false);
        }}
      >
        <List
          component="nav"
          className={"nav-account-container"}
          sx={{
            padding: "0",
          }}
        >
          <ListItemButton
            onClick={() => setAccountCollapseOpen((prevState) => !prevState)}
            className="nav-account-list-item"
            sx={{
              padding: 0,
              "& svg": {
                maxHeight: "32px",
                maxWidth: "32px",
                height: "32px",
                width: "32px",
                fill: "var(--neutral-grey-500)",
              },
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <ListItemIcon>
              <Avatar
                alt={oidcUser.name}
                sx={{
                  height: "auto",
                  width: "auto",
                  background: "none",
                  color: "primary.dark",
                }}
                className=""
              >
                <UilUserCircle />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                oidcUser.name && oidcUser.name.length > 40
                  ? oidcUser.name?.slice(0, 40).concat("...")
                  : oidcUser.name
              }
              sx={{
                "& span": {
                  whiteSpace: "nowrap",
                },
              }}
            />
            {accountCollapseOpen ? <UilAngleUp /> : <UilAngleDown />}
          </ListItemButton>
          <Fade in={accountCollapseOpen} unmountOnExit>
            <List
              component="div"
              /*
                    className={clsx(classes.accountCollapseContainer, {
                      [classes.accountCollapseContainerOpen]:
                        drawerState.drawerOpen,
                      [classes.accountCollapseContainerClose]:
                        !drawerState.drawerOpen,
                    })}
                    */
              id="nav-account-sub-container"
              aria-labelledby="accountCollapseContainer"
              disablePadding
              subheader={
                <ListSubheader component="div" id="accountCollapseContainer" />
              }
              sx={{
                position: "absolute",
                right: 0,
                backgroundColor: "neutral.light",
                marginTop: "8px",
                zIndex: 99,
              }}
            >
              <ListItemButton
                href="#"
                onClick={() => {
                  btnZendeskLinkClickedHandler(
                    ZendeskPageType.SupportCenterHome
                  );
                  setAccountCollapseOpen(false);
                }}
                className={"nav-account-list-item"}
              >
                <ListItemIcon sx={{ marginRight: 11, minWidth: "auto" }}>
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Customer Support"
                  sx={{ whiteSpace: "nowrap" }}
                />
              </ListItemButton>

              <ListItemButton
                href="#"
                onClick={() => {
                  btnZendeskLinkClickedHandler(
                    ZendeskPageType.MySupportTickets
                  );
                  setAccountCollapseOpen(false);
                }}
                className={"nav-account-list-item"}
              >
                <ListItemIcon sx={{ marginRight: 11, minWidth: "auto" }}>
                  <SupportAgentIcon />
                </ListItemIcon>
                <ListItemText
                  primary="My Tickets"
                  sx={{ whiteSpace: "nowrap" }}
                />
              </ListItemButton>

              {isSuperAdmin && (
                <ListItemButton
                  component={Link}
                  to="/info"
                  onClick={() => setAccountCollapseOpen(false)}
                  className={"nav-account-list-item"}
                >
                  <ListItemIcon sx={{ minWidth: "auto" }}>
                    <UilInfoCircle />
                  </ListItemIcon>
                  <ListItemText primary="Info" sx={{ whiteSpace: "nowrap" }} />
                </ListItemButton>
              )}
              {isSuperAdmin && (
                <ListItemButton
                  component={Link}
                  to="/database"
                  onClick={() => setAccountCollapseOpen(false)}
                  className={"nav-account-list-item"}
                >
                  <ListItemIcon sx={{ marginRight: 11, minWidth: "auto" }}>
                    <UilDatabase />
                  </ListItemIcon>
                  <ListItemText
                    primary="Database"
                    sx={{ whiteSpace: "nowrap" }}
                  />
                </ListItemButton>
              )}
              {/* Logout */}
              <ListItemButton
                href="#"
                onClick={() => {
                  updateUserId("");
                  setAccountCollapseOpen(false);
                  logout("");
                }}
                className={"nav-account-list-item"}
              >
                <ListItemIcon sx={{ marginRight: 11, minWidth: "auto" }}>
                  <UilSignOutAlt />
                </ListItemIcon>
                <ListItemText primary="Logout" sx={{ whiteSpace: "nowrap" }} />
              </ListItemButton>
            </List>
          </Fade>
        </List>
      </ClickAwayListener>
    </Box>
  ) : (
    <Box sx={{ display: "flex" }}>
      <List
        component="nav"
        className={"nav-account-container"}
        sx={{
          padding: "0",
        }}
      >
        <ListItemButton
          onClick={() => login()}
          className={"nav-account-list-item"}
          sx={{
            padding: 0,
            "& svg": {
              maxHeight: "32px",
              maxWidth: "32px",
              height: "32px",
              width: "32px",
            },
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <ListItemIcon>
            <UilUserCircle
              sx={{
                height: "auto",
                width: "auto",
                background: "none",
                color: "primary.dark",
              }}
            />
          </ListItemIcon>
          <ListItemText primary="Login" />
        </ListItemButton>
      </List>
    </Box>
  );
};

export default UserOptions;
