import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  DialogActions,
  Button,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useGetDealSpaces } from "../api/leasingActivityService";
import { DealSpacesFilter } from "../model/dealSpacesFilter";
import { UilMultiply } from "@iconscout/react-unicons";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

interface DealSpacesProps {
  handlePopupClose: () => void;
  filter: DealSpacesFilter;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leasingActivityModalDialog: {
      "& .MuiDialog-paper": {
        padding: 0,
      },
      "& .MuiDialog-paper .MuiDialogTitle-root": {
        padding: "16px 24px 8px 24px",
      },
      "& .MuiDialog-paper .MuiDialogActions-root": {
        padding: "8px 24px 16px 24px",
      },
      "& .MuiDialog-paper .MuiTypography-body1": {
        padding: 0,
        fontSize: 15,
        fontWeight: "normal",
        color: "var(--primary-dark-blue)",
      },
      "& .MuiDialog-paper .MuiDialogContent-root": {
        padding: "8px 0",
      },
      "& .MuiTable-root": {
        borderTop: "1px solid var(--neutral-grey-150)",
      },
      "& .MuiTableCell-root": {
        padding: "12px 20px",
        border: "1px solid var(--neutral-grey-150)",
        borderTop: 0,
        borderLeft: 0,
        verticalAlign: "top",
        overflowWrap: "break-word",
        breakWord: "break-all",
      },
      "& .MuiTableCell-head": {
        whiteSpace: "nowrap",
      },
      "& .MuiTableCell-root:last-child": {
        borderRight: 0,
      },
    },
  })
);

const DealSpaces: React.FunctionComponent<DealSpacesProps> = (props) => {
  const classes = useStyles();
  const dealSpaces = useGetDealSpaces(props.filter);

  return (
    <Dialog open={true} aria-labelledby="deal-spaces" fullWidth maxWidth="md" className={classes.leasingActivityModalDialog}>
      <DialogTitle id="deal-spaces-modal">
        <Typography component="span" variant="h2" color="primary.dark">
          Spaces
        </Typography>
        <IconButton
          aria-label="close"
          disabled={dealSpaces.isLoading}
          onClick={() => props.handlePopupClose()}
          className="icon-dialog-close"
          size="large"
        >
          <UilMultiply />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {dealSpaces.isFetching || !dealSpaces.data ? (
          <Box m="auto">
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ width: "100%" }}>
            <TableContainer>
              <Table
                aria-label="spaces"
                style={{ overflow: "hidden" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell><Typography variant="h4" color="neutral700.light">Suite</Typography></TableCell>
                    <TableCell><Typography variant="h4" color="neutral700.light">Floor</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dealSpaces.data.map((space, index) => (
                    <TableRow key={index}>
                      <TableCell><Typography variant="body1" color="primary.dark">{space.suite}</Typography></TableCell>
                      <TableCell><Typography variant="body1" color="primary.dark">{space.floor}</Typography></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={dealSpaces.isLoading}
          onClick={() => props.handlePopupClose()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DealSpaces;
