import { useContext, createContext } from "react";

export interface DrawerState {
  drawerOpen: boolean;
  setDrawerOpened: (value: boolean) => void;
}

export const DrawerStateContext = createContext<DrawerState>({
  drawerOpen: false,
  setDrawerOpened: (value) => {},
});
export const useDrawerState = () => useContext(DrawerStateContext);
