import React, {
  forwardRef,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import MaterialLink from "@mui/material/Link";
import { usePortfolioInfo } from "../../util/portfolioInfoContext";
import { useGetPortfolioLeasingActivity } from "../api/portfolioLeasingActivityService";
import PortfolioLeasingOverViewGrid from "./PortfolioLeasingOveriewGrid";
import PortfolioExpirationScheduleGrid from "./PortfolioExpirationScheduleGrid";
import { UilAngleDown } from "@iconscout/react-unicons";
import { UilAngleUp } from "@iconscout/react-unicons";
import {
  computeExpirationTotals,
  getPortfolioExpirationColumnNames,
} from "../utils/portfolioExpirationSchedule";
import PortfoliosStateContext from "../../context/PortfoliosStateContext";
import { ColumnApi, GridApi } from "ag-grid-community";
import { UilExport } from "@iconscout/react-unicons";
import DatePicker from "react-datepicker";
import { UilCalender } from "@iconscout/react-unicons";
interface Props {
  value?: string;
  onClick?: () => void;
}

const PortfolioLeasingActivity: React.FC<Props> = (props) => {
  const portfolioInfo = usePortfolioInfo();

  const { laStartDate, updateLAStartDate } = useContext(PortfoliosStateContext);

  const portfolioLeaseQuery = useGetPortfolioLeasingActivity({
    portfolioId: portfolioInfo.portfolioId,
    accountIdentifier: portfolioInfo.accountIdentifier,
    year: laStartDate.getFullYear(),
    month: laStartDate.getMonth() + 1,
  });

  const [isAllExpanded, setIsAllExpanded] = useState<boolean>(true);
  const initialExpandStatus = useMemo(() => [true, true], []);
  const [expandStatus, setExpandStatus] =
    useState<boolean[]>(initialExpandStatus);

  const onExpandStatusChanged = (index: number, expanded: boolean) => {
    expandStatus[index] = expanded;
    setExpandStatus([...expandStatus]);
    if (expandStatus[0] === false && expandStatus[1] === false) {
      setIsAllExpanded(false);
    }
    if (expandStatus[0] === true && expandStatus[1] === true) {
      setIsAllExpanded(true);
    }
  };

  const {
    isSuccess,
    isFetching,
    data: portfolioLeasingActivityData,
  } = portfolioLeaseQuery;

  const [overviewGridApi, setOverviewGridApi] = React.useState<
    GridApi | undefined
  >(undefined);
  const [overviewGridColumnApi, setOverviewGridColumnApi] = React.useState<
    ColumnApi | undefined
  >(undefined);

  const [expirationGridApi, setExpirationGridApi] = useState<
    GridApi | undefined
  >(undefined);
  const [expirationGridColumnApi, setExpirationGridColumnApi] = useState<
    ColumnApi | undefined
  >(undefined);

  const onExport = () => {
    const overviewSheet = overviewGridApi?.getSheetDataForExcel({
      sheetName: "Leasing Overview",
    });
    const expirationSheet = expirationGridApi?.getSheetDataForExcel({
      sheetName: "10 Year Expiration Schedule",
    });

    if (!overviewSheet || !expirationSheet) return;

    const spreadSheets = [overviewSheet, expirationSheet];
    overviewGridApi?.exportMultipleSheetsAsExcel({
      data: spreadSheets,
      fileName:
        portfolioLeaseQuery.data?.portfolioName + " Leasing Export.xlsx",
    });
  };

  const onGridExpirationRender = useCallback(
    (gridApi: GridApi, gridColumnApi: ColumnApi) => {
      setExpirationGridApi(gridApi);
      setExpirationGridColumnApi(gridColumnApi);
    },
    []
  );

  const onGridOverviewRender = useCallback(
    (gridApi: GridApi, gridColumnApi: ColumnApi) => {
      setOverviewGridApi(gridApi);
      setOverviewGridColumnApi(gridColumnApi);
    },
    []
  );

  type RefType = number;
  const CustomDatePickerInput = forwardRef<RefType, Props>(
    ({ onClick, value }, ref) => (
      <Box className={"react-datepicker-input"} ref={ref} onClick={onClick}>
        {value}
        <Box className={"react-datepicker-icon"}>
          <UilCalender />
        </Box>
      </Box>
    )
  );

  return isFetching ? (
    <Box m="auto">
      <CircularProgress />
    </Box>
  ) : (
    <React.Fragment>
      {isSuccess && portfolioLeasingActivityData !== undefined && (
        <div
          id="portfolio-leasingactivity"
          className="tab-pane fade show active"
          role="tabpanel"
          aria-labelledby="portfolio-tab-leasingactivity"
        >
          <Grid container className="property-action-bar">
            <Grid item>
              <Grid container sx={{ width: "auto" }}>
                <Grid item>
                  <Box className={"datepicker-outer-container"}>
                    <Typography
                      variant="body3"
                      component="label"
                      className={"input-label"}
                    >{`Select date:`}</Typography>
                    <Box className={"datepicker-container"}>
                      <DatePicker
                        selected={laStartDate}
                        startDate={laStartDate}
                        onChange={(date: Date) => {
                          updateLAStartDate(date);
                        }}
                        minDate={new Date(portfolioLeasingActivityData.minDate)}
                        maxDate={new Date()}
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        selectsStart
                        popperModifiers={[
                          {
                            name: "preventOverflow",
                            options: {
                              altAxis: true,
                            },
                          },
                          {
                            name: "offset",
                            options: {
                              offset: [-13, 0],
                            },
                          },
                        ]}
                        customInput={<CustomDatePickerInput {...props} />}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container sx={{ width: "auto" }}>
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    className={"btn-primary"}
                    sx={{ marginTop: "21px" }}
                    onClick={onExport}
                  >
                    <UilExport /> Export
                  </Button>
                  <div style={{ marginTop: "10px", textAlign: "right" }}>
                    <MaterialLink
                      component="button"
                      className="btn-link"
                      onClick={() => {
                        const allExpanded = !isAllExpanded;
                        setIsAllExpanded(allExpanded);
                        setExpandStatus([
                          ...expandStatus.map((m) => allExpanded),
                        ]);
                      }}
                      sx={{ display: "inline" }}
                    >
                      {isAllExpanded ? "Collapse all" : "Expand all"}
                      {isAllExpanded ? (
                        <UilAngleUp style={{ verticalAlign: "middle" }} />
                      ) : (
                        <UilAngleDown style={{ verticalAlign: "middle" }} />
                      )}
                    </MaterialLink>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div id="property-ar-content" className="tab-content">
            <div id="property-leasing-widgets">
              {/* Leasing overview */}
              <Accordion
                expanded={expandStatus[0]}
                onChange={(event, expanded) => {
                  onExpandStatusChanged(0, expanded);
                }}
                className={"accordion accordion-container-leasing"}
              >
                <AccordionSummary
                  expandIcon={
                    <Box className="icon-expand">
                      <UilAngleDown />
                    </Box>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h2" color="primary.dark">
                    Leasing Overview
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  className={"accordion-details"}
                  sx={{
                    display: "block",
                    "& .property-action-bar": {
                      paddingTop: 0,
                      backgroundColor: "var(--neutral-white)",
                    },
                    "& .ag-theme-alpine .ag-root-wrapper": {
                      borderWidth: "1px 0 0 0",
                    },
                  }}
                >
                  <PortfolioLeasingOverViewGrid
                    tableContent={
                      portfolioLeasingActivityData?.leasingPortfolioOverview
                    }
                    summary={
                      portfolioLeasingActivityData.leasingPortfolioOverviewSummary
                    }
                    gridApi={overviewGridApi}
                    gridColumnApi={overviewGridColumnApi}
                    onGridRender={onGridOverviewRender}
                    leasingActivityDate={laStartDate}
                  />
                </AccordionDetails>
              </Accordion>
              {/* 10 year expiration schedule (RSF) */}
              <Accordion
                expanded={expandStatus[1]}
                onChange={(event, expanded) => {
                  onExpandStatusChanged(1, expanded);
                }}
                className={"accordion accordion-container-leasing"}
              >
                <AccordionSummary
                  expandIcon={
                    <Box className="icon-expand">
                      <UilAngleDown />
                    </Box>
                  }
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h2" color="primary.dark">
                    10 Year Expiration Schedule (RSF/%)
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{ display: "block" }}
                  className={"accordion-details"}
                  sx={{
                    display: "block",
                    "& .property-action-bar": {
                      paddingTop: 0,
                      backgroundColor: "var(--neutral-white)",
                    },
                    "& .ag-theme-alpine .ag-root-wrapper": {
                      borderWidth: "1px 0 0 0",
                    },
                  }}
                >
                  <PortfolioExpirationScheduleGrid
                    tableContent={
                      portfolioLeasingActivityData?.expirationScheduleRsf
                    }
                    columnNames={getPortfolioExpirationColumnNames(
                      portfolioLeasingActivityData?.expirationScheduleRsf[0]
                    )}
                    totals={computeExpirationTotals(
                      portfolioLeasingActivityData?.expirationScheduleSummary
                    )}
                    gridApi={expirationGridApi}
                    gridColumnApi={expirationGridColumnApi}
                    onGridRender={onGridExpirationRender}
                    leasingActivityDate={laStartDate}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PortfolioLeasingActivity;
