import React, { useEffect, useContext } from "react";
import {
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { LoansForm } from "../model/LoansForm";
import NumberInput from "./NumberInput";
import { unformatNumber } from "../util/primaryInfoForm";
import { LoanKeyValue } from "../model/LoansModel";
import LoansContext from "../context/LoansContext";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { UilCalender } from "@iconscout/react-unicons";
import {
  requiredErrorMessage,
  numberErrorMessage,
  maxNotesField,
  notesErrorMessage,
} from "../../property details/util/errorTexts";
import { isValidDate } from "./PrimaryInfoForm";

interface Props {
  disableSave: (value: boolean) => void;
}

const HedgeCovenantsForm: React.FC<Props> = (props) => {
  const { disableSave } = props;
  const { hedgeInstrumentTypeSelectList, hedgeRateTypeSelectList } =
    useContext(LoansContext);

  const methods = useFormContext<LoansForm>();
  const {
    formState: { errors },
    control,
    clearErrors,
    getValues,
    watch,
    register,
  } = methods;

  const { isValid } = methods.formState;

  const watchHedgeMaturityDate: Date | null | undefined =
    watch("hedgeMaturityDate");

  useEffect(() => {
    if (
      watchHedgeMaturityDate === null ||
      watchHedgeMaturityDate === undefined ||
      watchHedgeMaturityDate?.toString().trim() === ""
    )
      return;

    const hedgeMaturityDate = new Date(watchHedgeMaturityDate);

    if (isValidDate(hedgeMaturityDate)) {
      clearErrors("hedgeMaturityDate");
    }
  }, [clearErrors, watchHedgeMaturityDate]);

  useEffect(() => {
    disableSave(!isValid);
  }, [isValid, disableSave]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const SelectIcon = (props: Props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 0 0"
      fill="none"
    >
      <path
        d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z"
        fill="#021A3C"
      />
    </svg>
  );

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        {/* Loan to value % */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Loan to Value (%)*`}</Typography>
            <TextField
              size="small"
              //label="Loan to Value (%)*"
              variant="outlined"
              id="loan-to-value"
              error={Boolean(errors?.loanToValuePercent)}
              helperText={
                Boolean(errors?.loanToValuePercent)
                  ? errors?.loanToValuePercent?.message
                  : null
              }
              {...register("loanToValuePercent", {
                required: requiredErrorMessage,
                setValueAs: (v) => {
                  if (typeof v === "number") return v;
                  if (v === undefined || v.trim().length === 0)
                    return undefined;
                  if (isNaN(v)) return v;
                  return Number(v);
                },
                validate: {
                  positive: (v) => {
                    if (isNaN(v)) return true;
                    if (v > 0) return true;
                    return numberErrorMessage;
                  },
                  numFormat: (v) => !isNaN(v) || numberErrorMessage,
                },
                max: {
                  value: 100,
                  message: numberErrorMessage,
                },
                min: {
                  value: 0,
                  message: numberErrorMessage,
                },
              })}
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
        {/* DSCR */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">{`DSCR*`}</Typography>
            <NumberInput
              name="debtServiceCoverageRatio"
              textFieldProps={{
                error: Boolean(errors?.debtServiceCoverageRatio),
                helperText: Boolean(errors?.debtServiceCoverageRatio)
                  ? errors?.debtServiceCoverageRatio?.message
                  : null,
                //label: "DSCR*",
                size: "small",
                variant: "outlined",
              }}
              register={{
                required: requiredErrorMessage,
                validate: {
                  req: (v) => {
                    if (typeof v === "string") return true;
                    if (isNaN(v)) return requiredErrorMessage;
                    else return true;
                  },
                  positive: (v) => {
                    const value = Number(unformatNumber(v));
                    if (value === undefined) return true;
                    if (value > 0 || isNaN(value)) return true;
                    else return numberErrorMessage;
                  },
                  numFormat: (v) => {
                    const value = Number(unformatNumber(v));
                    if (value === undefined) return true;
                    return !isNaN(value) || numberErrorMessage;
                  },
                },
              }}
              defaultValue={getValues("debtServiceCoverageRatio")}
              format
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {/* Minimum DSCR */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Minimum DSCR*`}</Typography>
            <NumberInput
              name="minimumDebtServiceCoverageRatio"
              defaultValue={getValues("minimumDebtServiceCoverageRatio")}
              textFieldProps={{
                error: Boolean(errors?.minimumDebtServiceCoverageRatio),
                helperText: Boolean(errors?.minimumDebtServiceCoverageRatio)
                  ? errors?.minimumDebtServiceCoverageRatio?.message
                  : null,
                //label: "Minimum DSCR*",
                size: "small",
                variant: "outlined",
              }}
              register={{
                required: requiredErrorMessage,
                setValueAs: (v) => {
                  if (typeof v === "number") return v;
                  if (v === undefined || v.trim().length === 0)
                    return undefined;
                  if (isNaN(v)) return undefined;
                  return Number(v);
                },
                validate: {
                  req: (v) => {
                    if (typeof v === "string") return true;
                    if (isNaN(v)) return requiredErrorMessage;
                    else return true;
                  },
                  numFormat: (v) => !isNaN(v) || numberErrorMessage,
                },
              }}
              format
            />
          </FormControl>
        </Grid>
        {/* Current debt yield % */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Current Debt Yield (%)`}</Typography>
            <TextField
              size="small"
              //label="Current Debt Yield (%)"
              variant="outlined"
              id="current-debt-yield"
              error={Boolean(errors?.currentDebtYieldPercent)}
              helperText={
                Boolean(errors?.currentDebtYieldPercent)
                  ? errors?.currentDebtYieldPercent?.message
                  : null
              }
              {...register("currentDebtYieldPercent", {
                setValueAs: (v) => {
                  if (typeof v === "number") return v;
                  if (v === undefined || v.trim().length === 0)
                    return undefined;
                  if (isNaN(v)) return v;
                  return Number(v);
                },
                validate: {
                  numFormat: (v) =>
                    v === undefined || !isNaN(v) || numberErrorMessage,
                },
                max: {
                  value: 100,
                  message: numberErrorMessage,
                },
                min: {
                  value: 0,
                  message: numberErrorMessage,
                },
              })}
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {/* Minimum LTV % */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Minimum LTV (%)`}</Typography>
            <TextField
              size="small"
              //label="Minimum LTV (%)"
              variant="outlined"
              id="minimum-ltv"
              error={Boolean(errors?.minimumLoanToValuePercent)}
              helperText={
                Boolean(errors?.minimumLoanToValuePercent)
                  ? errors?.minimumLoanToValuePercent?.message
                  : null
              }
              {...register("minimumLoanToValuePercent", {
                setValueAs: (v) => {
                  if (typeof v === "number") return v;
                  if (v === undefined || v.trim().length === 0)
                    return undefined;
                  if (isNaN(v)) return v;
                  return Number(v);
                },
                validate: {
                  numFormat: (v) =>
                    v === undefined || !isNaN(v) || numberErrorMessage,
                },
                max: {
                  value: 100,
                  message: numberErrorMessage,
                },
                min: {
                  value: 0,
                  message: numberErrorMessage,
                },
              })}
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
        {/* Minimum Debt Yield % */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Minimum Debt Yield (%)`}</Typography>
            <TextField
              size="small"
              //label="Minimum Debt Yield (%)"
              variant="outlined"
              id="minimum-debt-yield"
              error={Boolean(errors?.minimumDebtYieldPercent)}
              helperText={
                Boolean(errors?.minimumDebtYieldPercent)
                  ? errors?.minimumDebtYieldPercent?.message
                  : null
              }
              {...register("minimumDebtYieldPercent", {
                setValueAs: (v) => {
                  if (typeof v === "number") return v;
                  if (v === undefined || v.trim().length === 0)
                    return undefined;
                  if (isNaN(v)) return v;
                  return Number(v);
                },
                validate: {
                  numFormat: (v) =>
                    v === undefined || !isNaN(v) || numberErrorMessage,
                },
                max: {
                  value: 100,
                  message: numberErrorMessage,
                },
                min: {
                  value: 0,
                  message: numberErrorMessage,
                },
              })}
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* Covenant Notes */}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Covenant Notes`}</Typography>
            <TextField
              size="small"
              error={Boolean(errors?.covenantNotes)}
              helperText={
                Boolean(errors?.covenantNotes)
                  ? errors?.covenantNotes?.message
                  : null
              }
              variant="outlined"
              multiline
              rows={4}
              className={"form-control-field form-control-textarea"}
              {...register("covenantNotes", {
                validate: {
                  maxCharacters: (value) => {
                    if (!value) return true;
                    if (value?.trim().length > maxNotesField)
                      return notesErrorMessage;
                  },
                },
              })}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* Maturity Notes */}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Maturity Notes`}</Typography>
            <TextField
              size="small"
              error={Boolean(errors?.maturityNotes)}
              helperText={
                Boolean(errors?.maturityNotes)
                  ? errors?.maturityNotes?.message
                  : null
              }
              variant="outlined"
              multiline
              rows={4}
              className={"form-control-field form-control-textarea"}
              {...register("maturityNotes", {
                validate: {
                  maxCharacters: (value) => {
                    if (!value) return true;
                    if (value?.trim().length > maxNotesField)
                      return notesErrorMessage;
                  },
                },
              })}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* Hedge Instrument & Hedge Rate */}
      <Grid container spacing={1}>
        {/* Hedge Instrument */}
        <Grid item xs={6}>
          <FormControl
            size="small"
            variant="outlined"
            className={"form-control"}
          >
            <Typography
              variant="body3"
              component="label"
            >{`Hedge Instrument`}</Typography>
            <Controller
              control={control}
              name={"hedgeInstrumentTypeId"}
              render={({ field, fieldState }) => (
                <Select
                  labelId="hedge-instrument"
                  displayEmpty
                  input={<OutlinedInput />}
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                  {...field}
                >
                  {hedgeInstrumentTypeSelectList.map(
                    (hedgeInstrumentOption: LoanKeyValue, index: number) => (
                      <MenuItem
                        key={`${index}`}
                        value={hedgeInstrumentOption.key}
                      >
                        {hedgeInstrumentOption.value}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        {/* Hedge Rate */}
        <Grid item xs={6}>
          <FormControl
            size="small"
            variant="outlined"
            className={"form-control"}
          >
            <Typography
              variant="body3"
              component="label"
            >{`Hedge Rate`}</Typography>
            <Controller
              control={control}
              name={"hedgeRateTypeId"}
              render={({ field, fieldState }) => (
                <Select
                  labelId="hedge-rate"
                  displayEmpty
                  input={<OutlinedInput />}
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                  {...field}
                >
                  {hedgeRateTypeSelectList.map(
                    (hedgeRateOption: LoanKeyValue, index: number) => (
                      <MenuItem key={`${index}`} value={hedgeRateOption.key}>
                        {hedgeRateOption.value}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* Hedge Maturity & Hedge Rate % */}
      <Grid container spacing={1}>
        {/* Hedge Maturity */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Hedge Maturity`}</Typography>
            <Controller
              name="hedgeMaturityDate"
              control={control}
              rules={{
                validate: {
                  format: (value) => {
                    if (
                      value === null ||
                      value === undefined ||
                      value?.toString().trim() === ""
                    )
                      return true;
                    if (isValidDate(new Date(value))) return true;
                    else return "Invalid date entry.";
                  },
                },
              }}
              render={({ field }) => (
                <DatePicker
                  //label="Hedge Maturity"
                  inputRef={field.ref}
                  views={["year", "month", "day"]}
                  onChange={field.onChange}
                  value={field.value}
                  components={{
                    OpenPickerIcon: UilCalender,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors?.hedgeMaturityDate)}
                      helperText={
                        errors?.hedgeMaturityDate
                          ? errors?.hedgeMaturityDate.message
                          : null
                      }
                      className={"form-control-field form-control-datepicker"}
                    />
                  )}
                />
              )}
            />
          </FormControl>
        </Grid>
        {/* Hedge Rate % */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Hedge Rate (%)`}</Typography>
            <TextField
              size="small"
              variant="outlined"
              id="hedge-rate-percent"
              error={Boolean(errors?.hedgeRatePercent)}
              helperText={
                Boolean(errors?.hedgeRatePercent)
                  ? errors?.hedgeRatePercent?.message
                  : null
              }
              {...register("hedgeRatePercent", {
                setValueAs: (v) => {
                  if (typeof v === "number") return v;
                  if (v === undefined || v.trim().length === 0)
                    return undefined;
                  if (isNaN(v)) return v;
                  return Number(v);
                },
                validate: {
                  numFormat: (v) =>
                    v === undefined || !isNaN(v) || numberErrorMessage,
                },
                max: {
                  value: 100,
                  message: numberErrorMessage,
                },
                min: { value: 0, message: numberErrorMessage },
              })}
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* Hedge Notes */}
      <Grid container>
        <Grid item xs={12}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Hedge Notes`}</Typography>
            <TextField
              size="small"
              variant="outlined"
              multiline
              rows={4}
              error={Boolean(errors?.hedgeNotes)}
              helperText={
                Boolean(errors?.hedgeNotes) ? errors?.hedgeNotes?.message : null
              }
              className={"form-control-field form-control-textarea"}
              {...register("hedgeNotes", {
                validate: {
                  maxCharacters: (value) => {
                    if (!value) return true;
                    if (value?.trim().length > maxNotesField)
                      return notesErrorMessage;
                  },
                },
              })}
            />
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default HedgeCovenantsForm;
