import { useOidc } from "@axa-fr/react-oidc";
import { Navigate } from "react-router";

const Login: React.FunctionComponent = () => {
  const { login, isAuthenticated } = useOidc();

  return isAuthenticated ? <Navigate to="/" /> : login();
};

export default Login;
