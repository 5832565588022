import React, { useState, useEffect, useContext, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { GridApi, ColumnApi } from "ag-grid-community";

import PortfolioInsert from "./portfolioInsert";
import { TPortfolioListFilter } from "../model/portfolioListFilter";
import PortfolioListSearch from "./portfolioListSearch";
import {
  PortfolioInfo,
  PortfolioInfoContext,
} from "../../util/portfolioInfoContext";
import PortfolioContext from "../../util/portfolioContext";
import PortfoliosGrid from "./PortfoliosGrid";
import UserOptions from "../../../layout/views/userOptions";
import { hasSpecificPermission } from "../../util/accountPermissions";
import { EAccountPermission } from "../../../usermanagement/model/editUserModel";

const Portfolios: React.FC = () => {
  const { accountPermissions } = useContext(PortfolioContext);
  const queryClient = useQueryClient();

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | undefined>(
    undefined
  );

  const [filter, setFilter] = useState<TPortfolioListFilter>({
    accountIdentifier: "",
    startYear: new Date().getFullYear(),
    startMonth: new Date().getMonth() + 1,
    endYear: new Date().getFullYear(),
    endMonth: new Date().getMonth() + 1,
  });

  const [showInsertPopup, setShowInsertPopup] = useState(false);

  const [portfolioInfo, setPortfolioInfo] = useState<PortfolioInfo>({
    portfolioId: "",
    accountIdentifier: "",
    description: "",
  });

  const hasPortfolioAccess = React.useMemo(
    () =>
      hasSpecificPermission(
        accountPermissions,
        filter.accountIdentifier,
        EAccountPermission.CONFIGURE_PORTFOLIOS
      ),
    [accountPermissions, filter.accountIdentifier]
  );

  useEffect(() => {
    document.title = "Workspace | Portfolios";
  }, []);

  const handlePopupOpen = useCallback(() => {
    setShowInsertPopup(true);
  }, []);

  const handlePopupClose = (shouldRefreshData: boolean) => {
    setShowInsertPopup(false);

    if (shouldRefreshData) {
      const uniqueIdForQuery =
        filter.accountIdentifier +
        filter.startMonth +
        filter.startYear +
        filter.endMonth +
        filter.endYear;

      queryClient.invalidateQueries([
        "getPortfolioDetailsList",
        uniqueIdForQuery,
      ]);
    }
  };

  const setPortfolioListSearchFilter = useCallback(
    (
      acctIdentifier: string,
      startMonth: number,
      startYear: number,
      endMonth: number,
      endYear: number
    ) => {
      setFilter({
        accountIdentifier: acctIdentifier,
        startMonth: startMonth,
        startYear: startYear,
        endMonth: endMonth,
        endYear: endYear,
      });
      setPortfolioInfo({
        ...portfolioInfo,
        accountIdentifier: acctIdentifier,
      });
    },
    [portfolioInfo]
  );

  return (
    <React.Fragment>
      <Box className="mainContainer">
        <Box className={"appHeader"}>
          <Box>
            <Typography variant="h1" sx={{ color: "primary.dark" }}>
              Portfolios
            </Typography>
          </Box>
          {/* User Options */}
          <UserOptions />
        </Box>
        <PortfolioInfoContext.Provider value={portfolioInfo}>
          <PortfolioListSearch
            accountId={filter.accountIdentifier}
            gridApi={gridApi}
            gridColumnApi={gridColumnApi}
            setFilter={setPortfolioListSearchFilter}
            handlePopupOpen={handlePopupOpen}
          />
          <PortfoliosGrid
            filter={filter}
            gridApi={gridApi}
            gridColumnApi={gridColumnApi}
            onGridRender={(
              gridApi: GridApi | undefined,
              gridColumnApi: ColumnApi | undefined
            ) => {
              setGridApi(gridApi);
              setGridColumnApi(gridColumnApi);
            }}
            handlePopupOpen={handlePopupOpen}
          />
          {showInsertPopup && hasPortfolioAccess && (
            <PortfolioInsert
              handlePopupClose={handlePopupClose}
              showInsertPopup={showInsertPopup}
              accountIdentifier={filter.accountIdentifier}
            />
          )}
        </PortfolioInfoContext.Provider>
      </Box>
    </React.Fragment>
  );
};

export default Portfolios;
