import React from "react";
import Chip from '@mui/material/Chip';
import { ICellRendererParams } from "ag-grid-community";

const LoanStatusRenderer: React.FunctionComponent<ICellRendererParams> = (
  props: any
) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  return (
    <Chip className={"grid-chip"} label={cellValue} />
  );
};

export default LoanStatusRenderer;
