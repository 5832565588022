import React, { useState } from "react";
import { useLocation, Link, useNavigate, Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import queryString from "query-string";
import { Link as MaterialLink } from "@mui/material";
import { Box, Breadcrumbs, Tabs, Tab, Typography } from "@mui/material/";
import { UilAngleLeft } from "@iconscout/react-unicons";

import {
  PortfolioInfo,
  PortfolioInfoContext,
} from "../../util/portfolioInfoContext";
import { PortfoliosStateProvider } from "../../context/PortfoliosStateContext";
import UserOptions from "../../../layout/views/userOptions";

const CustomizedTabs = styled(Tabs)`
  width: 100%;
  background-color: var(--neutral-white);
`;
const CustomizedBox = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: var(--neutral-white);
  border-top: solid 1px var(--neutral-grey-150);
`;

const PortfolioView: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateToPortfolios = () => {
    navigate("/portfolios");
  };
  const parsed = queryString.parse(location.search);
  const pId = parsed.portfolioId as string;
  const acctIdentifier = parsed.accountIdentifier as string;
  const description = parsed.description as string;
  const portfolioQueryString = location.search;

  const [portfolioInfo] = useState<PortfolioInfo>({
    portfolioId: pId,
    accountIdentifier: acctIdentifier,
    description: description,
  });

  return (
    <PortfolioInfoContext.Provider value={portfolioInfo}>
      <PortfoliosStateProvider>
        <Box className="mainPropertiesContainer">
          <Box className={"appHeader"}>
            <Box sx={{ display: "flex" }}>
              <Box>
                <Typography variant="h1" sx={{ color: "primary.dark" }}>
                  {portfolioInfo.description.length > 75
                    ? portfolioInfo.description?.slice(0, 75).concat("...")
                    : portfolioInfo.description}
                </Typography>
                <Breadcrumbs
                  separator={<UilAngleLeft />}
                  aria-label="breadcrumb"
                >
                  <MaterialLink
                    component="button"
                    onClick={navigateToPortfolios}
                    variant="subtitle1"
                    sx={{
                      paddingTop: "8px",
                      color: "neutral700.light",
                      textDecoration: "none",
                      fontWeight: "500",
                      "& svg": {
                        width: "20px",
                        height: "20px",
                        verticalAlign: "text-bottom",
                      },
                    }}
                  >
                    <UilAngleLeft /> Back to portfolios
                  </MaterialLink>
                </Breadcrumbs>
              </Box>
            </Box>
            {/* User Options */}
            <UserOptions />
          </Box>
          <CustomizedTabs
            value={location.pathname}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Portfolio View Navigation Tabs"
          >
            {/* <Tab
              label="Snapshot"
              value={`/portfolioView/snapshot`}
              component={Link}
              to={`/portfolioView/snapshot${portfolioQueryString}`}
              className={"mainTopTab"}
            /> */}
            <Tab
              label="Financials"
              value={`/portfolioView/financials`}
              component={Link}
              to={`/portfolioView/financials${portfolioQueryString}`}
              className={"mainTopTab"}
            />
            <Tab
              label="Rent Roll"
              value={`/portfolioView/rentroll`}
              component={Link}
              to={`/portfolioView/rentroll${portfolioQueryString}`}
              className={"mainTopTab"}
            />
            <Tab
              label="Leasing Activity"
              value={`/portfolioView/activity`}
              component={Link}
              to={`/portfolioView/activity${portfolioQueryString}`}
              className={"mainTopTab"}
            />
            <Tab
              label="AR"
              value={`/portfolioView/ar`}
              component={Link}
              to={`/portfolioView/ar${portfolioQueryString}`}
              className={"mainTopTab"}
            />
          </CustomizedTabs>
          <CustomizedBox>
            <Outlet />
          </CustomizedBox>
        </Box>
      </PortfoliosStateProvider>
    </PortfolioInfoContext.Provider>
  );
};
export default PortfolioView;
