import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { ICellRendererParams } from "ag-grid-community";
import { Box, Grid } from "@mui/material";
import { UilTrashAlt } from "@iconscout/react-unicons";
import { UilPen } from "@iconscout/react-unicons";

import CreateAlertDialog from "./CreateAlertDialog";
import {
  IFrequencySelectList,
  IArAlertRule,
} from "../../model/alertsModel";

type Props = ICellRendererParams & {
  accountId: string;
  frequencyList: IFrequencySelectList[];
  handleClose: (shouldRefresh: boolean) => void;
  handleOpenDeleteDialog: (alertName?: string, alertId?: string) => void;
};

const AlertsActionsRenderer: React.FC<Props> = (props) => {
  const {
    accountId,
    frequencyList,
    handleClose,
    handleOpenDeleteDialog,
  } = props;

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleDialogClose = (shouldRefresh: boolean) => {
    handleClose(shouldRefresh);
    setOpenEditDialog(false);
  };

  return (
    <React.Fragment>
      {openEditDialog &&
        frequencyList &&
        accountId.trim().length > 0 && (
          <CreateAlertDialog
            handleClose={handleDialogClose}
            accountId={accountId}
            editMode={true}
            frequencyList={frequencyList}

            defaultValues={props.data as IArAlertRule}
          />
        )}
      <Grid container spacing={2}>
        <Grid
          item
          sx={{
            "& svg": {
              maxHeight: "20px",
              maxWidth: "20px",
              lineHeight: "1",
            },
          }}
        >
          <Tooltip title="Edit" style={{ cursor: "pointer" }}>
            <Box
              onClick={() => setOpenEditDialog(true)}
              className={"icon-documents"}
              aria-label="edit"
            >
              <UilPen />
            </Box>
          </Tooltip>
        </Grid>
        <Grid
          item
          sx={{
            "& svg": {
              maxHeight: "20px",
              maxWidth: "20px",
              lineHeight: "1",
            },
          }}
        >
          <Tooltip title="Delete" style={{ cursor: "pointer" }}>
            <Box
              onClick={() =>
                handleOpenDeleteDialog(props.data?.alertTitle, props.data?.id)
              }
              className={"icon-documents"}
              aria-label="delete"
            >
              <UilTrashAlt />
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AlertsActionsRenderer;
