/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useContext } from "react";
import {
  Typography,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Grid,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import { UilCheck } from "@iconscout/react-unicons";
import { UilDocumentInfo } from "@iconscout/react-unicons";
import "react-datepicker/dist/react-datepicker.css";
import { usePropertyInfo } from "../../util/propertyInfoContext";
import { UilBuilding } from "@iconscout/react-unicons";
import { UilFileGraph } from "@iconscout/react-unicons";
import { UilUsersAlt } from "@iconscout/react-unicons";
import { UilSearchAlt } from "@iconscout/react-unicons";
import { UilUsdCircle } from "@iconscout/react-unicons";
import { UilLaptop } from "@iconscout/react-unicons";
import { UilAngleDown } from "@iconscout/react-unicons";
import Link from "@mui/material/Link";

import PropertyViewOverviewDocuments from "./propertyviewOverViewDocuments";
import PropOverviewAccordionSum from "./PropOverviewAccordionSum";
import { useGetPropertyOverview } from "../api/propertyOverviewService";
import { EDateFormat, formatDatePO } from "../util/formatDateUtil";
import AssetOverviewEdit from "./AssetOverviewEdit";
import { useQueryClient } from "@tanstack/react-query";
import WorkspaceEdit from "./WorkspaceEdit";
import {
  assetOverviewAddressFields,
  assetOverviewFields,
  investmentDetailsFields,
  IValuation,
  personnelFields,
  propertyDetailsFields,
  softwareAndSystemsFields,
  TStatus,
  workspaceStatusFields,
} from "../model/propertyOverviewModel";
import PropertyDetailsEdit from "./PropertyDetailsEdit";
import PersonnelEdit from "./PersonnelEdit";
import SoftwareAndSystemsEdit from "./softwareAndSystemsEdit";
import {
  computeFieldsFilledText,
  computeNumberOfFieldsFilled,
} from "../util/numOfFieldsFilled";
import headerStyles from "../../../layout/views/headerStyles";
import InvestmentDetailsEdit from "./InvestmentDetailsEdit";
import ValuationsTable from "./ValuationsTable";
import PortfolioContext from "../../../portfolio/util/portfolioContext";
import { hasSpecificPermission } from "../../../portfolio/util/accountPermissions";
import { EAccountPermission } from "../../../usermanagement/model/editUserModel";

enum ModalType {
  ASSET_OVERVIEW = "Asset Overview",
  WORKSPACE_STATUS = "Workspace Status",
  PROPERTY_DETAILS = "Property Details",
  PERSONNEL = "Personnel",
  SOFTWARE_AND_SYSTEMS = "Software and Systems",
  INVESTMENT_DETAILS = "Investment Details",
}

const accordionSectionStyles = {
  width: "50%",
  padding: 16,
};

const border = "1px solid var(--neutral-grey-150)";
const defaultMissingText = "";

const PropertyViewDetailsNew: React.FC = () => {
  const classes = headerStyles();
  const propertyInfo = usePropertyInfo();

  // Permissions
  const { accountPermissions } = useContext(PortfolioContext);

  const hasPropertyPermission = useMemo(() => {
    if (!propertyInfo.accountIdentifier) return false;

    return hasSpecificPermission(
      accountPermissions,
      propertyInfo.accountIdentifier,
      EAccountPermission.PROPERTY_EDIT
    );
  }, [accountPermissions, propertyInfo.accountIdentifier]);

  const hasAdminPermission = useMemo(() => {
    if (!propertyInfo.accountIdentifier) return false;

    return hasSpecificPermission(
      accountPermissions,
      propertyInfo.accountIdentifier,
      EAccountPermission.ADMINISTRATION
    );
  }, [accountPermissions, propertyInfo.accountIdentifier]);

  const propertyOverviewQuery = useGetPropertyOverview(
    propertyInfo.id,
    propertyInfo.accountIdentifier
  );

  const propertyOverview = propertyOverviewQuery.data?.propertyOverview;
  let propertyAddress = "undefined";
  if (
    propertyOverview?.city &&
    propertyOverview?.state &&
    propertyOverview?.zip
  ) {
    propertyAddress = `${propertyOverview?.city ?? "-"} ${
      propertyOverview?.state ?? "-"
    } ${propertyOverview?.zip ?? "-"}`;
  }

  const [openValuationsModal, setOpenValuationsModal] = useState(false);
  const [showModal, setShowModal] = useState<{
    open: boolean;
    modalType?: ModalType;
  }>({
    open: false,
    modalType: undefined,
  });

  const queryClient = useQueryClient();
  const onSave = (shouldRefresh?: boolean) => {
    if (shouldRefresh)
      queryClient.invalidateQueries([
        "getPropertyOverview",
        propertyInfo.id,
        propertyInfo.accountIdentifier,
      ]);
  };

  const getPropertyStatusId = (propertyStatus?: TStatus) => {
    if (propertyStatus === undefined) return "";
    const statusObj = propertyOverviewQuery.data?.propertyStatusSelectList.find(
      (status) => status.value === propertyStatus.toString()
    );
    if (statusObj) {
      return statusObj.key.toString();
    } else return "";
  };

  const formatDollarNumber = (num?: number) => {
    if (num === undefined) return defaultMissingText;
    else return `$${num.toLocaleString()}`;
  };

  const [propertyUrl, setPropertyUrl] = React.useState(propertyInfo.imageUrl);

  const onUploadedPhoto = (newUrl: string) => {
    setPropertyUrl(newUrl);
  };

  const valuations = propertyOverviewQuery.data?.valuations;
  const capitalPartners = propertyOverviewQuery.data?.capitalPartners;

  const renderAssetOverviewModal =
    showModal.open && showModal.modalType === ModalType.ASSET_OVERVIEW;

  const renderWorkspaceStatus =
    showModal.open && showModal.modalType === ModalType.WORKSPACE_STATUS;

  const renderPropertyDetailsModal =
    showModal.open && showModal.modalType === ModalType.PROPERTY_DETAILS;

  const renderPersonnelModal =
    showModal.open && showModal.modalType === ModalType.PERSONNEL;

  const renderSoftwareAndSystemsModal =
    showModal.open && showModal.modalType === ModalType.SOFTWARE_AND_SYSTEMS;

  const renderInvestmentDetailsModal =
    showModal.open && showModal.modalType === ModalType.INVESTMENT_DETAILS;

  const assetOverviewFieldsFilled = useMemo(() => {
    let numOfFieldsFilled = 0;
    if (
      computeNumberOfFieldsFilled(
        assetOverviewAddressFields,
        propertyOverview
      ) > 0
    )
      numOfFieldsFilled++;

    numOfFieldsFilled =
      numOfFieldsFilled +
      computeNumberOfFieldsFilled(assetOverviewFields, propertyOverview);

    // + 1 because all of the asset overview address fields are considered as one big field.
    const totalFields = assetOverviewFields.length + 1;

    return computeFieldsFilledText(totalFields, numOfFieldsFilled);
  }, [propertyOverview]);

  const workspaceStatusFieldsFilled = useMemo(() => {
    const numOfFieldsFilled = computeNumberOfFieldsFilled(
      workspaceStatusFields,
      propertyOverview
    );
    const totalFields = workspaceStatusFields.length;

    return computeFieldsFilledText(totalFields, numOfFieldsFilled);
  }, [propertyOverview]);

  const propertyDetailsFieldsFilled = useMemo(() => {
    const numOfFieldsFilled = computeNumberOfFieldsFilled(
      propertyDetailsFields,
      propertyOverview
    );
    const totalFields = propertyDetailsFields.length;

    return computeFieldsFilledText(totalFields, numOfFieldsFilled);
  }, [propertyOverview]);

  const personnelFieldsFilled = useMemo(() => {
    const numOfFieldsFilled = computeNumberOfFieldsFilled(
      personnelFields,
      propertyOverview
    );
    const totalFields = personnelFields.length;

    return computeFieldsFilledText(totalFields, numOfFieldsFilled);
  }, [propertyOverview]);

  const softwareAndSystemsFieldsFilled = useMemo(() => {
    const numOfFieldsFilled = computeNumberOfFieldsFilled(
      softwareAndSystemsFields,
      propertyOverview
    );
    const totalFields = softwareAndSystemsFields.length;

    return computeFieldsFilledText(totalFields, numOfFieldsFilled);
  }, [propertyOverview]);

  const investmentDetailsFieldsFilled = useMemo(() => {
    let numOfFieldsFilled = 0;
    if (valuations && valuations.length > 0) numOfFieldsFilled++;
    if (capitalPartners && capitalPartners.length > 0) numOfFieldsFilled++;

    numOfFieldsFilled =
      numOfFieldsFilled +
      computeNumberOfFieldsFilled(investmentDetailsFields, propertyOverview);

    // + 2 because all of the valuations are considered as one field and all of the capital partners are considered as one field.
    const totalFields = investmentDetailsFields.length + 2;

    return computeFieldsFilledText(totalFields, numOfFieldsFilled);
  }, [propertyOverview, valuations, capitalPartners]);

  const valuationsToDisplay = useMemo(() => {
    let filteredValuations: IValuation[] = [];
    if (valuations && valuations.length > 0) {
      filteredValuations = valuations.slice();
      filteredValuations = filteredValuations
        .sort((valuationA, valuationB) => {
          if (
            valuationB.valuationDate === null &&
            valuationA.valuationDate === null
          )
            return 0;
          else if (valuationA.valuationDate === null) return -1;
          else if (valuationB.valuationDate === null) return 1;
          else
            return (
              new Date(valuationB.valuationDate).valueOf() -
              new Date(valuationA.valuationDate).valueOf()
            );
        })
        .slice(0, 4);
    }
    return filteredValuations;
  }, [valuations]);

  return (
    <React.Fragment>
      {renderAssetOverviewModal && (
        <AssetOverviewEdit
          onUploadPhoto={(newUrl: string) => onUploadedPhoto(newUrl)}
          showDialog={renderAssetOverviewModal}
          accountId={propertyInfo.accountIdentifier}
          propertyId={propertyInfo.id}
          assetTypeList={propertyOverviewQuery.data?.assetTypeSelectList ?? []}
          states={propertyOverviewQuery.data?.usStatesList ?? []}
          assetClassList={
            propertyOverviewQuery.data?.assetClassSelectList ?? []
          }
          assetOverview={{
            street: propertyOverview?.street,
            city: propertyOverview?.city,
            state: propertyOverview?.state ?? "",
            zip: propertyOverview?.zip,
            assetOverviewNotes: propertyOverview?.assetOverviewNotes,
            yearBuilt: propertyOverview?.yearBuilt,
            numBuildings: propertyOverview?.numBuildings,
            numFloors: propertyOverview?.numFloors,
            assetClassId: propertyOverview?.assetClassId ?? -1,
            assetTypeId: propertyOverview?.assetTypeId ?? -1,
            // This field (imageUrl) will be added in the future
            imageUrl: propertyUrl ?? "",
          }}
          handlePopupClose={(shouldRefreshData?: boolean) => {
            onSave(shouldRefreshData);
            setShowModal({
              open: false,
              modalType: undefined,
            });
          }}
        />
      )}
      {renderWorkspaceStatus && (
        <WorkspaceEdit
          showDialog={renderWorkspaceStatus}
          propertyStatusSelectList={
            propertyOverviewQuery.data?.propertyStatusSelectList ?? []
          }
          workspaceStatus={{
            status: getPropertyStatusId(propertyOverview?.status),
            activationDate: propertyOverview?.activationDate,
            deactivatedDate: propertyOverview?.deactivatedDate,
            accountId: propertyInfo.accountIdentifier,
            propertyId: propertyInfo.id,
          }}
          handlePopupClose={(shouldRefreshData?: boolean) => {
            onSave(shouldRefreshData);
            setShowModal({
              open: false,
              modalType: undefined,
            });
          }}
        />
      )}
      {renderPropertyDetailsModal && (
        <PropertyDetailsEdit
          showDialog={renderPropertyDetailsModal}
          propertyDetails={{
            accountId: propertyInfo.accountIdentifier,
            propertyId: propertyInfo.id,

            lastRemeasureDate: propertyOverview?.lastRemeasureDate,
            deckToDeckHeight: propertyOverview?.deckToDeckHeight,
            floorToGridHeight: propertyOverview?.floorToGridHeight,
            roofType: propertyOverview?.roofType,
            roofRepairs: propertyOverview?.roofRepairs,
            roofReplacementDate: propertyOverview?.roofReplacementDate,
            elevatorPermitDate: propertyOverview?.elevatorPermitDate,
            isSprinklered: propertyOverview?.isSprinklered,
            year5SprinklerInspectionDate:
              propertyOverview?.year5SprinklerInspectionDate,
            year3SprinklerInspectionDate:
              propertyOverview?.year3SprinklerInspectionDate,
            hasPressurizedStairwells:
              propertyOverview?.hasPressurizedStairwells,
            floorType: propertyOverview?.floorType,
            buildingStandardPaint: propertyOverview?.buildingStandardPaint,
            buildingStandardCarpet: propertyOverview?.buildingStandardCarpet,
            commonAreaPaint: propertyOverview?.commonAreaPaint,
            commonAreaCarpet: propertyOverview?.commonAreaCarpet,
            parkingType: propertyOverview?.parkingType,
            parkingStallCount: propertyOverview?.parkingStallCount,
            parkingRatio: propertyOverview?.parkingRatio,
            parkingRates: propertyOverview?.parkingRates,
            providerTelecom: propertyOverview?.providerTelecom,
            providerFiber: propertyOverview?.providerFiber,
            utilityWaterCompany: propertyOverview?.utilityWaterCompany,
            utilityElectricCompany: propertyOverview?.utilityElectricCompany,
            utilityGasCompany: propertyOverview?.utilityGasCompany,
            buildingHours: propertyOverview?.buildingHours,
            hvacHours: propertyOverview?.hvacHours,
            hvacOvertimeRate: propertyOverview?.hvacOvertimeRate,
            lastRenovation: propertyOverview?.lastRenovation,
            lastRenovationDate: propertyOverview?.lastRenovationDate,
          }}
          handlePopupClose={(shouldRefreshData?: boolean) => {
            onSave(shouldRefreshData);
            setShowModal({
              open: false,
              modalType: undefined,
            });
          }}
        />
      )}
      {renderPersonnelModal && (
        <PersonnelEdit
          showDialog={renderPersonnelModal}
          personnel={{
            accountId: propertyInfo.accountIdentifier,
            propertyId: propertyInfo.id,

            assetManagementCompany: propertyOverview?.assetManagementCompany,
            assetManagerContactEmail:
              propertyOverview?.assetManagerContactEmail,
            assetManagerName: propertyOverview?.assetManagerName,
            propertyManagementCompany:
              propertyOverview?.propertyManagementCompany,
            propertyManagerContactEmail:
              propertyOverview?.propertyManagerContactEmail,
            propertyManagerName: propertyOverview?.propertyManagerName,
            leasingManagementCompany:
              propertyOverview?.leasingManagementCompany,
            leasingManagerContactEmail:
              propertyOverview?.leasingManagerContactEmail,
            leasingManagerName: propertyOverview?.leasingManagerName,
            engineeringHVACManagementCompany:
              propertyOverview?.engineeringHVACManagementCompany,
            engineeringHVACManagerContactEmail:
              propertyOverview?.engineeringHVACManagerContactEmail,
            engineeringHVACManagerName:
              propertyOverview?.engineeringHVACManagerName,
          }}
          handlePopupClose={(shouldRefreshData?: boolean) => {
            onSave(shouldRefreshData);
            setShowModal({
              open: false,
              modalType: undefined,
            });
          }}
        />
      )}
      {renderSoftwareAndSystemsModal && (
        <SoftwareAndSystemsEdit
          showDialog={renderSoftwareAndSystemsModal}
          accountingSystemsSelectList={
            propertyOverviewQuery.data?.accountingSystemsSelectList ?? []
          }
          investorRelationsSystemsSelectList={
            propertyOverviewQuery.data?.investorRelationsSystemsSelectList ?? []
          }
          leasingSystemsSelectList={
            propertyOverviewQuery.data?.leasingSystemsSelectList ?? []
          }
          propertyManagementSystemsSelectList={
            propertyOverviewQuery.data?.propertyManagementSystemsSelectList ??
            []
          }
          valuationSystemsSelectList={
            propertyOverviewQuery.data?.valuationSystemsSelectList ?? []
          }
          tenantExperienceSystemsSelectList={
            propertyOverviewQuery.data?.tenantExperienceSystemsSelectList ?? []
          }
          softwareAndSystems={{
            ...propertyOverview,
            accountId: propertyInfo.accountIdentifier,
            propertyId: propertyInfo.id,
          }}
          handlePopupClose={(shouldRefreshData?: boolean) => {
            onSave(shouldRefreshData);
            setShowModal({
              open: false,
              modalType: undefined,
            });
          }}
        />
      )}
      {renderInvestmentDetailsModal && (
        <InvestmentDetailsEdit
          showDialog={renderInvestmentDetailsModal}
          accountId={propertyInfo.accountIdentifier}
          propertyId={propertyInfo.id}
          investmentDetails={{
            acquisitionDate: propertyOverview?.acquisitionDate,
            dispositionDate: propertyOverview?.dispositionDate,
            valuationAtClose: propertyOverview?.valuationAtClose,
            valuationAtCloseDate: propertyOverview?.valuationAtCloseDate,
            investedEquity: propertyOverview?.investedEquity,
            acquisitionPrice: propertyOverview?.acquisitionPrice,
            leverageAtAcquisition: propertyOverview?.leverageAtAcquisition,
            investmentDetailNotes: propertyOverview?.investmentDetailNotes,
          }}
          valuations={propertyOverviewQuery.data?.valuations}
          capitalPartners={propertyOverviewQuery.data?.capitalPartners}
          handlePopupClose={(shouldRefreshData?: boolean) => {
            onSave(shouldRefreshData);
            setShowModal({
              open: false,
              modalType: undefined,
            });
          }}
        />
      )}
      <ValuationsTable
        open={openValuationsModal}
        onDialogClose={() => setOpenValuationsModal(false)}
        valuations={valuations}
      />
      <div
        id="property-details"
        className="tab-pane fade show active"
        role="tabpanel"
        aria-labelledby="property-tab-details"
      >
        <div id="property-details-widgets">
          {/* Asset Overview */}
          <Accordion
            defaultExpanded={true}
            className="accordion accordion-container-overview"
          >
            <AccordionSummary
              expandIcon={
                <Box className="icon-expand">
                  <UilAngleDown />
                </Box>
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <PropOverviewAccordionSum
                startIcon={<UilBuilding />}
                title={`Asset Overview`}
                label={assetOverviewFieldsFilled}
                displayEditBtn={hasAdminPermission || hasPropertyPermission}
                onEditClick={() =>
                  setShowModal({
                    open: true,
                    modalType: ModalType.ASSET_OVERVIEW,
                  })
                }
              />
            </AccordionSummary>
            <AccordionDetails style={{ borderTop: border, padding: 0 }}>
              <Box display="flex">
                <Box
                  display="flex"
                  flexDirection="row"
                  style={{
                    ...accordionSectionStyles,
                    borderRight: border,
                  }}
                >
                  <img
                    style={{ borderRadius: 5 }}
                    width="60px"
                    height="63px"
                    alt="property"
                    src={propertyUrl}
                  />
                  <Box display="flex" flexDirection="column" ml={2}>
                    <Typography
                      variant="subtitle1"
                      //component="label"
                      //className={"input-label"}
                      color="primary.dark"
                    >
                      {propertyOverview?.street ?? defaultMissingText}
                    </Typography>
                    <Typography
                      variant="h3"
                      //component="label"
                      //className={"input-label"}
                      color="neutral700.light"
                    >
                      {propertyAddress}
                    </Typography>
                    {/* <Link
                      variant="body1"
                      color="primary.main"
                      mt={1}
                      href="#"
                    >
                        View Map
                    </Link> */}
                  </Box>
                </Box>
                <Box
                  style={accordionSectionStyles}
                  display="flex"
                  flexDirection="column"
                  sx={{ maxWidth: "50vw" }}
                >
                  <Typography
                    variant="body3"
                    component="label"
                    className={"input-label"}
                  >
                    Notes
                  </Typography>
                  <Typography variant="body1" color="primary.dark">
                    {propertyOverview?.assetOverviewNotes ?? defaultMissingText}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" style={{ borderTop: border }}>
                <Box
                  style={{
                    ...accordionSectionStyles,
                    borderRight: border,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Asset Type
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.assetTypeDescription ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Asset Class
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.assetClassDescription ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Year Built
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.yearBuilt
                          ? formatDatePO(
                              propertyOverview.yearBuilt,
                              EDateFormat.MM_DD_YYYY
                            )
                          : defaultMissingText}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box style={accordionSectionStyles}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Number of Buildings
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.numBuildings ?? defaultMissingText}
                        {/* <LinkButton onClick={() => console.log("Add number")}>
                            Add Number
                          </LinkButton> */}
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Number of Floors
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.numFloors ?? defaultMissingText}
                        {/* <LinkButton onClick={() => console.log("Add number")}>
                            Add Number
                          </LinkButton> */}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* Investment Details */}
          <Accordion
            defaultExpanded={true}
            className="accordion accordion-container-overview"
          >
            <AccordionSummary
              expandIcon={
                <Box className="icon-expand">
                  <UilAngleDown />
                </Box>
              }
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <PropOverviewAccordionSum
                startIcon={<UilUsdCircle />}
                title={`Investment Details`}
                label={investmentDetailsFieldsFilled}
                displayEditBtn={hasAdminPermission || hasPropertyPermission}
                onEditClick={() =>
                  setShowModal({
                    open: true,
                    modalType: ModalType.INVESTMENT_DETAILS,
                  })
                }
              />
            </AccordionSummary>
            <AccordionDetails style={{ borderTop: border, padding: 0 }}>
              {/* Section 1 */}
              <Box display="flex">
                <Box
                  style={{
                    width: accordionSectionStyles.width,
                    borderRight: border,
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    style={{ padding: accordionSectionStyles.padding }}
                  >
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Acquisition Date
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.acquisitionDate
                          ? formatDatePO(
                              propertyOverview.acquisitionDate,
                              EDateFormat.MM_DD_YYYY
                            )
                          : defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Valuation at Close
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {formatDollarNumber(propertyOverview?.valuationAtClose)}{" "}
                        {propertyOverview?.valuationAtCloseDate
                          ? `(${formatDatePO(
                              propertyOverview?.valuationAtCloseDate,
                              EDateFormat.MM_DD_YYYY
                            )})`
                          : defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Disposition Date
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.dispositionDate
                          ? formatDatePO(
                              propertyOverview.dispositionDate,
                              EDateFormat.MM_DD_YYYY
                            )
                          : defaultMissingText}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* <Box style={{ borderBottom: border }}>
                    <Grid
                      container
                      spacing={2}
                      style={{ padding: accordionSectionStyles.padding }}
                    >
                      <Grid item xs={3}>
                        <Typography
                          variant="body3"
                          component="label"
                          className={"input-label"}
                        >
                          Acquisition Date
                        </Typography>
                        <Typography variant="body1" color="primary.dark">
                          {propertyOverview?.acquisitionDate
                            ? formatDatePO(
                                propertyOverview.acquisitionDate,
                                EDateFormat.MM_DD_YYYY
                              )
                            : defaultMissingText}
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          variant="body3"
                          component="label"
                          className={"input-label"}
                        >
                          Disposition Date
                        </Typography>
                        <Typography variant="body1" color="primary.dark">
                          {propertyOverview?.dispositionDate
                            ? formatDatePO(
                                propertyOverview.dispositionDate,
                                EDateFormat.MM_DD_YYYY
                              )
                            : defaultMissingText}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box> */}
                  <Grid
                    container
                    spacing={2}
                    style={{ padding: accordionSectionStyles.padding }}
                  >
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Invested Equity ($)
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {formatDollarNumber(propertyOverview?.investedEquity)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Acquisition Price ($)
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {formatDollarNumber(propertyOverview?.acquisitionPrice)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Leverage at Acquisition (%)
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.leverageAtAcquisition === undefined
                          ? defaultMissingText
                          : `${propertyOverview?.leverageAtAcquisition}%`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box style={accordionSectionStyles} sx={{ maxWidth: "50vw" }}>
                  <Typography
                    variant="body3"
                    component="label"
                    className={"input-label"}
                  >
                    Notes
                  </Typography>
                  <Typography variant="body1" color="primary.dark">
                    {propertyOverview?.investmentDetailNotes ??
                      defaultMissingText}
                  </Typography>
                </Box>
              </Box>
              {/* Section 2 */}
              <Box display="flex" style={{ borderTop: border }}>
                <Box
                  style={{
                    ...accordionSectionStyles,
                    borderRight: border,
                  }}
                >
                  <Grid container spacing={2}>
                    {/* If valuations is undefined or if valuations is present but the length of that array is 0 */}
                    {(valuations === undefined ||
                      (valuations !== undefined &&
                        valuations.length === 0)) && (
                      <React.Fragment>
                        <Grid item xs={3}>
                          <Typography
                            variant="body3"
                            component="label"
                            className={"input-label"}
                          >
                            Valuation Amount ($)
                          </Typography>
                          <Typography variant="body1" color="primary.dark">
                            {defaultMissingText}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant="body3"
                            component="label"
                            className={"input-label"}
                          >
                            Valuation Date
                          </Typography>
                          <Typography variant="body1" color="primary.dark">
                            {defaultMissingText}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="body3"
                            component="label"
                            className={"input-label"}
                          >
                            Added By
                          </Typography>
                          <Typography variant="body1" color="primary.dark">
                            {defaultMissingText}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    )}
                    {/* if valuations exist and its length is greater than 0 */}
                    {valuationsToDisplay.map((valuation) => (
                      <React.Fragment key={valuation.id}>
                        <Grid item xs={3}>
                          <Typography
                            variant="body3"
                            component="label"
                            className={"input-label"}
                          >
                            Valuation Amount ($)
                          </Typography>
                          <Typography variant="body1" color="primary.dark">
                            {formatDollarNumber(valuation.valuation)}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant="body3"
                            component="label"
                            className={"input-label"}
                          >
                            Valuation Date
                          </Typography>
                          <Typography variant="body1" color="primary.dark">
                            {valuation.valuationDate &&
                            valuation.valuationDate !== null
                              ? formatDatePO(
                                  valuation.valuationDate,
                                  EDateFormat.MM_DD_YYYY
                                )
                              : defaultMissingText}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="body3"
                            component="label"
                            className={"input-label"}
                          >
                            Added By
                          </Typography>
                          <Typography variant="body1" color="primary.dark">
                            {valuation.lastModifiedUserFullName ??
                              defaultMissingText}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                  {/* Evaluation History */}
                  {valuations !== undefined && valuations.length > 4 && (
                    <Box mt={4}>
                      <Link
                        component="button"
                        variant="body1"
                        color="primary.main"
                        className={classes.noBgColor}
                        onClick={() => setOpenValuationsModal(true)}
                      >
                        See valuation history
                      </Link>
                    </Box>
                  )}
                </Box>
                <Box style={accordionSectionStyles}>
                  <Grid container spacing={2}>
                    {/* If capitalPartners is undefined or if capitalPartners is present but the length of that array is 0 */}
                    {(capitalPartners === undefined ||
                      (capitalPartners && capitalPartners.length === 0)) && (
                      <React.Fragment>
                        <Grid item xs={1} />
                        <Grid item xs={3}>
                          <Typography
                            variant="body3"
                            component="label"
                            className={"input-label"}
                          >
                            Capital Partner
                          </Typography>
                          <Typography variant="body1" color="primary.dark">
                            {defaultMissingText}
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            variant="body3"
                            component="label"
                            className={"input-label"}
                          >
                            Ownership
                          </Typography>
                          <Typography variant="body1" color="primary.dark">
                            {defaultMissingText}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    )}
                    {/* If capitalPartners exists and its length is greater than 0 */}
                    {capitalPartners &&
                      capitalPartners.length > 0 &&
                      capitalPartners.map((capitalPartner) => (
                        <React.Fragment key={capitalPartner.id}>
                          <Grid item xs={1}>
                            {capitalPartner.isOwner && (
                              <Tooltip title="Your Firm’s Ownership">
                                <IconButton>
                                  <UilCheck />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              variant="body3"
                              component="label"
                              className={"input-label"}
                            >
                              Capital Partner
                            </Typography>
                            <Typography variant="body1" color="primary.dark">
                              {capitalPartner.capitalPartnerName ??
                                defaultMissingText}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography
                              variant="body3"
                              component="label"
                              className={"input-label"}
                            >
                              Capital Partner Ownership
                            </Typography>
                            <Typography variant="body1" color="primary.dark">
                              {capitalPartner.capitalPartnerOwnership ===
                              undefined
                                ? defaultMissingText
                                : `${capitalPartner.capitalPartnerOwnership}%`}
                            </Typography>
                          </Grid>
                        </React.Fragment>
                      ))}
                  </Grid>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* workspace Status */}
          <Accordion
            defaultExpanded={true}
            className="accordion accordion-container-overview"
          >
            <AccordionSummary
              expandIcon={
                <Box className="icon-expand">
                  <UilAngleDown />
                </Box>
              }
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <PropOverviewAccordionSum
                startIcon={<UilFileGraph />}
                title={`Workspace Status`}
                label={workspaceStatusFieldsFilled}
                displayEditBtn={hasAdminPermission}
                onEditClick={() =>
                  setShowModal({
                    open: true,
                    modalType: ModalType.WORKSPACE_STATUS,
                  })
                }
              />
            </AccordionSummary>
            <AccordionDetails style={{ borderTop: border, padding: 0 }}>
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Typography
                      variant="body3"
                      component="label"
                      className={"input-label"}
                    >
                      Status
                    </Typography>
                    <Typography variant="body1" color="primary.dark">
                      {propertyOverview?.status?.toString() ??
                        defaultMissingText}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="body3"
                      component="label"
                      className={"input-label"}
                    >
                      Activation Date
                    </Typography>
                    <Typography variant="body1" color="primary.dark">
                      {propertyOverview?.activationDate
                        ? formatDatePO(
                            propertyOverview.activationDate,
                            EDateFormat.MM_DD_YYYY
                          )
                        : defaultMissingText}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="body3"
                      component="label"
                      className={"input-label"}
                    >
                      Deactivation Date
                    </Typography>
                    <Typography variant="body1" color="primary.dark">
                      {propertyOverview?.deactivatedDate
                        ? formatDatePO(
                            propertyOverview.deactivatedDate,
                            EDateFormat.MM_DD_YYYY
                          )
                        : defaultMissingText}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* Property Details */}
          <Accordion
            defaultExpanded={true}
            className="accordion accordion-container-overview"
          >
            <AccordionSummary
              expandIcon={
                <Box className="icon-expand">
                  <UilAngleDown />
                </Box>
              }
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <PropOverviewAccordionSum
                startIcon={<UilSearchAlt />}
                title={`Property Details`}
                label={propertyDetailsFieldsFilled}
                displayEditBtn={hasAdminPermission || hasPropertyPermission}
                onEditClick={() =>
                  setShowModal({
                    open: true,
                    modalType: ModalType.PROPERTY_DETAILS,
                  })
                }
              />
            </AccordionSummary>
            <AccordionDetails style={{ borderTop: border, padding: 0 }}>
              {/* Section 1 */}
              <Box display="flex">
                <Box
                  style={{
                    ...accordionSectionStyles,
                    borderRight: border,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Last Remeasure Date
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.lastRemeasureDate
                          ? formatDatePO(
                              propertyOverview.lastRemeasureDate,
                              EDateFormat.MM_DD_YYYY
                            )
                          : defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Deck to Deck Height (ft)
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.deckToDeckHeight
                          ? propertyOverview.deckToDeckHeight
                          : defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Floor to Grid Height (ft)
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.floorToGridHeight
                          ? propertyOverview.floorToGridHeight
                          : defaultMissingText}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box style={accordionSectionStyles}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Parking Type
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.parkingType ?? defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Floor Type
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.floorType ?? defaultMissingText}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              {/* Section 2 */}
              <Box display="flex" style={{ borderTop: border }}>
                <Box
                  style={{
                    ...accordionSectionStyles,
                    borderRight: border,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Roof Type
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.roofType ?? defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Roof Repairs
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.roofRepairs ?? defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Roof Replacement Date
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.roofReplacementDate
                          ? formatDatePO(
                              propertyOverview.roofReplacementDate,
                              EDateFormat.MM_DD_YYYY
                            )
                          : defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Elevator Permit Date
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.elevatorPermitDate
                          ? formatDatePO(
                              propertyOverview.elevatorPermitDate,
                              EDateFormat.MM_DD_YYYY
                            )
                          : defaultMissingText}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box style={accordionSectionStyles}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Number of Stalls
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.parkingStallCount
                          ? propertyOverview.parkingStallCount
                          : defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Parking Ratio
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.parkingRatio
                          ? propertyOverview.parkingRatio
                          : defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Parking Rates
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.parkingRates ?? defaultMissingText}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              {/* Section 3 */}
              <Box display="flex" style={{ borderTop: border }}>
                <Box
                  style={{
                    ...accordionSectionStyles,
                    borderRight: border,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Sprinklered
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.isSprinklered !== undefined
                          ? propertyOverview.isSprinklered
                            ? "Yes"
                            : "No"
                          : defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Pressurized Stairwells
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.hasPressurizedStairwells !==
                        undefined
                          ? propertyOverview.hasPressurizedStairwells
                            ? "Yes"
                            : "No"
                          : defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        3-Year Sprinkler Inspection Date
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.year3SprinklerInspectionDate
                          ? formatDatePO(
                              propertyOverview.year3SprinklerInspectionDate,
                              EDateFormat.MM_DD_YYYY
                            )
                          : defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        5-Year Sprinkler Inspection Date
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.year5SprinklerInspectionDate
                          ? formatDatePO(
                              propertyOverview.year5SprinklerInspectionDate,
                              EDateFormat.MM_DD_YYYY
                            )
                          : defaultMissingText}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box style={accordionSectionStyles}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Telecom Provider
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.providerTelecom ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Fiber Provider
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.providerFiber ?? defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Water Company
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.utilityWaterCompany ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Electric Company
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.utilityElectricCompany ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Gas Company
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.utilityGasCompany ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              {/* Section 4 */}
              <Box display="flex" style={{ borderTop: border }}>
                <Box
                  style={{
                    ...accordionSectionStyles,
                    borderRight: border,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Building Standard Paint
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.buildingStandardPaint ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Building Standard Carpet
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.buildingStandardCarpet ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Common Area Paint
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.commonAreaPaint ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Common Area Carpet
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.commonAreaCarpet ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box style={accordionSectionStyles}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Building Hours
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.buildingHours ?? defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        HVAC Hours
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.hvacHours ?? defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        After Hours HVAC Rate
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.hvacOvertimeRate ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Last Major Renovation
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.lastRenovation ?? defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Last Major Renovation Date
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.lastRenovationDate
                          ? formatDatePO(
                              propertyOverview.lastRenovationDate,
                              EDateFormat.MM_DD_YYYY
                            )
                          : defaultMissingText}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* Personnel */}
          <Accordion
            defaultExpanded={true}
            className="accordion accordion-container-overview"
          >
            <AccordionSummary
              expandIcon={
                <Box className="icon-expand">
                  <UilAngleDown />
                </Box>
              }
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <PropOverviewAccordionSum
                startIcon={<UilUsersAlt />}
                title={`Personnel`}
                label={personnelFieldsFilled}
                displayEditBtn={hasAdminPermission || hasPropertyPermission}
                onEditClick={() =>
                  setShowModal({
                    open: true,
                    modalType: ModalType.PERSONNEL,
                  })
                }
              />
            </AccordionSummary>
            <AccordionDetails style={{ borderTop: border, padding: 0 }}>
              <Box display="flex">
                {/* Section 1 */}
                <Box
                  style={{
                    ...accordionSectionStyles,
                    borderRight: border,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Asset Management Company
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.assetManagementCompany ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Asset Manager Name
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.assetManagerName ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Asset Manager Contact Email
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.assetManagerContactEmail ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {/* Section 2 */}
                <Box style={accordionSectionStyles}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Leasing Management Company
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.leasingManagementCompany ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Leasing Manager Name
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.leasingManagerName ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Leasing Manager Contact Email
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.leasingManagerContactEmail ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              {/* Section 3 */}
              <Box display="flex" style={{ borderTop: border }}>
                <Box
                  style={{
                    ...accordionSectionStyles,
                    borderRight: border,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Property Management Company
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.propertyManagementCompany ??
                          defaultMissingText}
                        {/* <LinkButton
                            onClick={() => {
                              console.info("I'm a button.");
                            }}
                          >
                            Add Company
                          </LinkButton> */}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Property Manager Name
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.propertyManagerName ??
                          defaultMissingText}
                        {/* <LinkButton
                            onClick={() => {
                              console.info("I'm a button.");
                            }}
                          >
                            Add Manager Name
                          </LinkButton> */}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Property Manager Contact Email
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.propertyManagerContactEmail ??
                          defaultMissingText}
                        {/* <LinkButton
                            onClick={() => {
                              console.info("I'm a button.");
                            }}
                          >
                            Add Contact Email
                          </LinkButton> */}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {/* Section 4 */}
                <Box style={accordionSectionStyles}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Engineering/HVAC Company
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.engineeringHVACManagementCompany ??
                          defaultMissingText}
                        {/* <LinkButton
                            onClick={() => {
                              console.info("I'm a button.");
                            }}
                          >
                            Add Company
                          </LinkButton> */}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Engineering/HVAC Name
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.engineeringHVACManagerName ??
                          defaultMissingText}
                        {/* <LinkButton
                            onClick={() => {
                              console.info("I'm a button.");
                            }}
                          >
                            Add HVAC Name
                          </LinkButton> */}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Engineering/HVAC Contact Email
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.engineeringHVACManagerContactEmail ??
                          defaultMissingText}
                        {/* <LinkButton
                            onClick={() => {
                              console.info("I'm a button.");
                            }}
                          >
                            Add Contact Email
                          </LinkButton> */}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* Software Systems */}
          <Accordion
            defaultExpanded={true}
            className="accordion accordion-container-overview"
          >
            <AccordionSummary
              expandIcon={
                <Box className="icon-expand">
                  <UilAngleDown />
                </Box>
              }
              aria-controls="panel6bh-content"
              id="panel6bh-header"
            >
              <PropOverviewAccordionSum
                startIcon={<UilLaptop />}
                title={`Software & Systems`}
                label={softwareAndSystemsFieldsFilled}
                displayEditBtn={hasAdminPermission || hasPropertyPermission}
                onEditClick={() =>
                  setShowModal({
                    open: true,
                    modalType: ModalType.SOFTWARE_AND_SYSTEMS,
                  })
                }
              />
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex">
                {/* Section 1 */}
                <Box
                  style={{
                    ...accordionSectionStyles,
                    borderRight: border,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Accounting
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.accountingSystemDescription ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Work Orders/Property Management
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.propertyManagementSystemDescription ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Leasing
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.leasingSystemDescription ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Valuation
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.valuationSystemDescription ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Investor Relations
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.investorRelationsSystemDescription ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Tenant Experiences
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.tenantExperienceSystemDescription ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {/* Section 2 */}
                <Box style={accordionSectionStyles}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Loan Management
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.loanManagementSystem ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Building Access &amp; Security
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.buildingAccessSecuritySystem ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Fire &amp; Life Safety
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.fireLifeSafetySystem ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Compliance
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.complianceSystem ??
                          defaultMissingText}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >
                        Other
                      </Typography>
                      <Typography variant="body1" color="primary.dark">
                        {propertyOverview?.otherSystem ?? defaultMissingText}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* Documents */}
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            defaultExpanded={true}
            className="accordion accordion-container-overview"
          >
            <AccordionSummary
              expandIcon={
                <Box className="icon-expand">
                  <UilAngleDown />
                </Box>
              }
              aria-controls="panel7bh-content"
              id="panel7bh-header"
            >
              <PropOverviewAccordionSum
                startIcon={<UilDocumentInfo />}
                title={"Documents"}
                label={""}
              />
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <PropertyViewOverviewDocuments />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PropertyViewDetailsNew;
