import { ColDef } from "ag-grid-community";
import { maxNotesField } from "../../property details/util/errorTexts";
import { percentFormattedForLoans } from "../../util/formatNumber";

import {
  currencyFormatter,
  dateFilterParams,
  dateValueFormatter,
  dateValueGetter,
  negativeNumCellStyle,
  stringSortCaseInsensitive,
  // numberGetter,
  // pctGetter,
} from "../../util/gridUtils";
import { LoansForm } from "../model/LoansForm";
import { isValidDate } from "../views/PrimaryInfoForm";

const inactiveCellClassRules = {
  "loan-status-inactive": (params: any) => {
    if (params !== undefined && params.data !== undefined) {
      return (
        params.data.loanStatusDescription === undefined ||
        params.data.loanStatusDescription.toUpperCase() === "INACTIVE"
      );
    } else {
      return false;
    }
  },
};

/**
 * returns the column definitions for loans table.
 * @param hasLoanPermission permission to determine whether the user can edit or not
 * @param onLoanSaved callback to be executed once the loan has been edited
 * @param onDeleteLoan callback to be executed once the loan has been deleted
 * @returns
 */
export const getPropertyLoansColumns = (
  hasLoanPermission: boolean,
  onLoanSaved: (loanId: string) => void,
  onDeleteLoan: (loanId: string) => void
) => {
  const columnDefs: ColDef[] = [
    {
      headerName: "Actions",
      field: "PlaylistAddIcon",
      cellRenderer: "editLoan",
      cellRendererParams: {
        hasLoanPermission: hasLoanPermission,
        onLoanSaved: onLoanSaved,
        onDeleteLoan: onDeleteLoan,
      },
      minWidth: 120,
    },
    {
      headerName: "Status",
      headerTooltip: "Status",
      field: "loanStatusDescription",
      cellRenderer: "loanStatusRenderer",
      cellClassRules: inactiveCellClassRules,
      sortable: true,
      filter: true,
    },

    {
      headerName: "Lender",
      headerTooltip: "Lender Name",
      field: "lenderName",
      cellClassRules: inactiveCellClassRules,
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.data.lenderName === undefined
          ? "-"
          : params.data.lenderName;
      },
    },
    {
      headerName: "Servicer",
      headerTooltip: "Servicer Name",
      field: "servicerName",
      cellClassRules: inactiveCellClassRules,
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.data.servicerName === undefined
          ? "-"
          : params.data.servicerName;
      },
    },
    {
      headerName: "Original Balance",
      headerTooltip: "Original Balance",
      field: "originalLoanBalance",
      cellClassRules: inactiveCellClassRules,
      sortable: true,
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params) =>
        currencyFormatter(params.data.originalLoanBalance, "$"),
      cellStyle: negativeNumCellStyle,
    },
    // {
    //   headerName: "Current Balance",
    //   headerTooltip: "Current Balance",
    //   field: "principalBalance",
    //   cellClassRules: inactiveCellClassRules,
    //   sortable: true,
    //   filter: "agNumberColumnFilter",
    //   cellClass: ["alignRight", "currencyFormat"],
    //   valueFormatter: (params: any) =>
    //     currencyFormatter(params.data.principalBalance, "$"),
    //   valueGetter: (params: any) => numberGetter(params.data.principalBalance),
    //   cellStyle: negativeNumCellStyle,
    // },

    {
      headerName: "Loan Type",
      headerTooltip: "Loan Type",
      field: "loanType",
      cellClassRules: inactiveCellClassRules,
      sortable: true,
      filter: true,
      valueFormatter: (params) => {
        return params.data.loanType === undefined ? "-" : params.data.loanType;
      },
    },
    {
      headerName: "Interest Type",
      headerTooltip: "Interest Type",
      field: "interestRateType",
      cellClassRules: inactiveCellClassRules,
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: true,
    },
    {
      headerName: "Base",
      headerTooltip: "Base",
      field: "interestRateBasisType",
      cellClassRules: inactiveCellClassRules,
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: true,
      valueFormatter: (params) => {
        return params.data.interestRateBasisType === undefined
          ? "-"
          : params.data.interestRateBasisType;
      },
    },
    {
      headerName: "Spread %",
      headerTooltip: "Spread %",
      field: "spreadPercent",
      cellClassRules: inactiveCellClassRules,
      cellClass: ["alignRight", "decimalPlaces"],
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        percentFormattedForLoans(params.data.spreadPercent),
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Total Interest Rate %",
      headerTooltip: "Total Interest Rate %",
      field: "totalInterestRatePercent",
      sortable: true,
      filter: "agNumberColumnFilter",
      cellClassRules: inactiveCellClassRules,
      cellClass: ["alignRight", "decimalPlaces"],
      valueFormatter: (params) =>
        percentFormattedForLoans(params.data.totalInterestRatePercent),
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Origination Date",
      headerTooltip: "Origination Date",
      field: "originationDate",
      cellClassRules: inactiveCellClassRules,
      sortable: true,
      filter: "agDateColumnFilter",
      cellClass: ["dateFormat"],
      filterParams: dateFilterParams,
      valueGetter: (params: any) =>
        dateValueGetter(params.data.originationDate),
      valueFormatter: (params: any) =>
        dateValueFormatter(params.data.originationDate),
    },
    {
      headerName: "Maturity Date",
      headerTooltip: "Maturity Date",
      field: "maturityDate",
      cellClassRules: inactiveCellClassRules,
      sortable: true,
      filter: "agDateColumnFilter",
      cellClass: ["dateFormat"],
      filterParams: dateFilterParams,
      valueGetter: (params: any) => dateValueGetter(params.data.maturityDate),
      valueFormatter: (params: any) =>
        dateValueFormatter(params.data.maturityDate),
    },
    {
      headerName: "Term (Years)",
      headerTooltip: "Term (Years)",
      field: "term",
      cellClassRules: inactiveCellClassRules,
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => params.data.term,
    },
    {
      headerName: "Extension Maturity Date",
      headerTooltip: "Extension Maturity Date",
      field: "extensionMaturityDate",
      cellClassRules: inactiveCellClassRules,
      sortable: true,
      filter: "agDateColumnFilter",
      cellClass: ["dateFormat"],
      filterParams: dateFilterParams,
      valueGetter: (params: any) =>
        dateValueGetter(params.data.extensionMaturityDate),
      valueFormatter: (params: any) =>
        dateValueFormatter(params.data.extensionMaturityDate),
    },

    {
      headerName: "Amortizing",
      headerTooltip: "Amortizing",
      field: "amortizingDescription",
      cellClassRules: inactiveCellClassRules,
      sortable: true,
      filter: true,
    },
    {
      headerName: "Prepayment Penalty",
      headerTooltip: "Prepayment Penalty",
      field: "prepaymentPenaltyDescription",
      cellClassRules: inactiveCellClassRules,
      sortable: true,
      filter: true,
    },
    {
      headerName: "Securitized",
      headerTooltip: "Securitized",
      field: "securitizedDescription",
      cellClassRules: inactiveCellClassRules,
      sortable: true,
      filter: true,
    },
    {
      headerName: "Cross Collateralized",
      headerTooltip: "Cross Collateralized",
      field: "crossCollateralizedDescription",
      cellClassRules: inactiveCellClassRules,
      sortable: true,
      filter: true,
    },
  ];
  return columnDefs;
};

export const getLoansExportColumns = () => {
  return [
    "loanStatusDescription",
    "lenderName",
    "servicerName",
    "originalLoanBalance",
    // "principalBalance",
    "loanType",
    "interestRateType",
    "interestRateBasisType",
    "spreadPercent",
    "totalInterestRatePercent",
    "originationDate",
    "maturityDate",
    "term",
    "extensionMaturityDate",
    "amortizingDescription",
    "prepaymentPenaltyDescription",
    "securitizedDescription",
    "crossCollateralizedDescription",
  ];
};

export const getNewDefaultLoan = () => {
  const defaultLoan: any = {
    kpiFinancialMeasureTypeId: -1,
    loanNumber: "",
    originationDate: null,
    maturityDate: null,
    extensionMaturityDate: null,
    noticeDateTypeId: -1,
    noticeDateToExtendProvisions: null,
    termYears: 0,
    interestRateTypeId: -1, // write your own model in the frontend for now. REQUIRED
    totalInterestRatePercent: undefined,
    interestRateBasisTypeId: -1, // write your own model in the frontend for now.
    loanTypeId: -1,
    amortizing: -1,
    securitized: -1,
    assumable: -1,
    prepayment: -1,
    crossCollateralized: -1,
    prepaymentPenalty: -1,
    prepaymentPenaltyNotes: "",
    loanNotes: "",
    loanStatus: -1,
    // Lender
    // lenderId: string; // Not Required maybe - ask mike - do we have lender id at the time of making a post req.
    lenderTitle: "",
    lenderState: "",

    // Servicer
    // servicerId: string; // Not required maybe, ask mike
    servicerTitle: "",
    servicerState: "",
    lenderNotes: "",

    // Edge & Covenants
    loanToValuePercent: undefined,
    debtServiceCoverageRatio: undefined,
    minimumDebtServiceCoverageRatio: undefined,
    covenantNotes: "",
    maturityNotes: "",
    hedgeInstrumentTypeId: -1, // create your own model for now, ask mike
    hedgeRateTypeId: -1, // create your own model for now, ask mike
    hedgeMaturityDate: null,
    hedgeNotes: "",
  };

  return defaultLoan;
};

/**
 * Formatting boolean values to yes or - for the Loans table.
 * @param value
 */
// const formatPrepayment = (value: boolean | undefined) => {
//   if (value === undefined || value === false) return "-";
//   return "Yes";
// };

/**
 * Checks whether the individual form is valid/invalid.
 * @param loanModel The loan form
 * @param tabValue 0 - is on primary info tab, 1 - is on lenders and servicers tab, 2 - is on hedge and covenants form
 * @returns returns true if the form is valid, false if the form is invalid and undefined if its on that current tab for all three forms
 */
export const validateForEmptyFields = (loanModel: LoansForm) => {
  const isLenderNotValid =
    loanModel.lenderTitle.trim().length === 0 ||
    loanModel.servicerTitle.trim().length === 0 ||
    loanModel.lenderNotes?.trim().length > maxNotesField;

  const isHedgeNotValid =
    !loanModel.loanToValuePercent ||
    !loanModel.debtServiceCoverageRatio ||
    !loanModel.minimumDebtServiceCoverageRatio ||
    loanModel.covenantNotes?.trim().length > maxNotesField ||
    loanModel.maturityNotes?.trim().length > maxNotesField ||
    loanModel.hedgeNotes?.trim().length > maxNotesField ||
    (!!loanModel.hedgeMaturityDate &&
      !isValidDate(loanModel.hedgeMaturityDate));

  const isPrimaryNotValid =
    !loanModel.originalLoanBalance ||
    !loanModel.originationDate ||
    !loanModel.maturityDate ||
    loanModel.interestRateTypeId === -1 ||
    !loanModel.totalInterestRatePercent ||
    loanModel.amortizing === -1 ||
    loanModel.prepaymentPenalty === -1 ||
    loanModel.loanStatus === -1 ||
    loanModel.loanStatus === undefined ||
    !loanModel.prepaymentPenaltyNotes.trim() ||
    loanModel.loanNotes?.trim().length > maxNotesField ||
    (!!loanModel.extensionMaturityDate &&
      !isValidDate(loanModel.extensionMaturityDate));

  return {
    isPrimaryValid: !isPrimaryNotValid,
    isLenderValid: !isLenderNotValid,
    isHedgeValid: !isHedgeNotValid,
  };
};
