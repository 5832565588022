import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import { CssBaseline } from "@mui/material";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from './theme';

import 'ag-grid-enterprise';

import {LicenseManager} from "ag-grid-enterprise";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

LicenseManager.setLicenseKey("CompanyName=Work space LLC,LicensedApplication=GoWorkSpace,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=6,LicensedProductionInstancesCount=1,AssetReference=AG-015853,ExpiryDate=21_May_2022_[v2]_MTY1MzA4NzYwMDAwMA==94a3fcb20220e704c49167bd43234a60");

const container  = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <StylesProvider injectFirst>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </StylesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
