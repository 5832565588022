import React, { useState, useMemo } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Theme,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  createStyles,
  makeStyles,
} from "@mui/styles";
import CircleIcon from "@mui/icons-material/Circle";
import { UilStar } from '@iconscout/react-unicons';
import { UilSave } from "@iconscout/react-unicons";
import { GridApi, ColumnApi } from "ag-grid-community";
import { UilFileCheck } from "@iconscout/react-unicons";
import { UilSetting } from "@iconscout/react-unicons";

import { GridConfig } from "../model/gridConfig";
import CreateGridConfigModal from "./CreateGridConfigModal";
import { usePostGridConfig, usePutGridConfig } from "../api/pageConfigService";
import {
  GridConfigIds,
  PageConfigIds,
} from "../../constants/GridPageConfigurationIds";
import ManageViewsModal from "./ManageViewsModal";
import StarIcon from '@mui/icons-material/Star';

const blueShade = "primary.dark";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectedItem: {
      backgroundColor: "var(--neutral-grey-150)",
    },
    defaultItem: {
      "& svg": {
        width: "18px",
        height: "18px",
      },
    },
    select: {
      minWidth: 125,
    },
    actionableItem: {
      "& svg": {
        fill: "var(--neutral-grey-600)",
      },
      "&:hover svg": {
        fill: "var(--primary-light-blue)",
      },
    },
  })
);

export enum GridContextOptions {
  SAVE_AS = "Save As",
  SAVE_VIEW = "Save Current View",
  MANAGE_VIEWS = "Manage Views",
}

export const GRID_CONFIG_DEFAULT_VIEW = "Standard";

interface Props {
  isSuccess: boolean;
  gridView: string;
  handleGridView: (newVal: string) => void;
  gridConfigData: GridConfig | undefined;
  handleMenuItemClick?: (option: GridContextOptions) => void;
  gridApi?: GridApi;
  gridColumnApi?: ColumnApi;
  gridId: GridConfigIds;
  pageId: PageConfigIds;
  accountId: string;
  invalidateGridQuery: () => void;
  updateSaveAsViewName: (name: string) => void;
  updateGridView: () => void;
}

const SaveGridStateOptions: React.FC<Props> = (props) => {
  const {
    isSuccess,
    gridView,
    handleGridView,
    gridConfigData,
    gridApi,
    gridColumnApi,
    gridId,
    pageId,
    accountId,
    invalidateGridQuery,
    updateSaveAsViewName,
  } = props;
  const classes = useStyles();

  const gridNames = useMemo(() => {
    if (gridConfigData)
      return gridConfigData.gridConfigurations.map((gridItem) =>
        gridItem.name.toLowerCase()
      );
    else return [];
  }, [gridConfigData]);

  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [manageViewsModal, setManageViewsModal] = useState(false);

  const createGrid = usePostGridConfig();
  const saveGrid = usePutGridConfig();

  const defaultGridConfig = gridConfigData?.gridConfigurations.find(
    (gridConfig) => gridConfig.isDefault
  );

  const standardViewSelected = gridView === GRID_CONFIG_DEFAULT_VIEW;
  const defaultViewSelected =
    String(defaultGridConfig ? defaultGridConfig.id : "") === String(gridView);

  const onSelectChange = (event: SelectChangeEvent) => {
    const option = event.target.value as GridContextOptions;
    const isContextOption = Object.values(GridContextOptions).includes(option);
    if (isContextOption) handleMenuItemClick(option);
    else handleGridView(event.target.value.toString());
  };

  /**
   * Saving grid config
   * @param name
   */
  const saveGridConfig = async (
    name: string,
    isDefault: boolean,
    saveType?: GridContextOptions
  ) => {
    if (!gridApi || !gridColumnApi) return;

    if (saveType === GridContextOptions.SAVE_AS)
      createGrid.mutate(
        {
          pageId: pageId,
          gridConfig: {
            name: name,
            isDefault: isDefault,
            columnState: JSON.stringify(gridColumnApi.getColumnState()),
            columnGroupState: JSON.stringify(
              gridColumnApi.getColumnGroupState()
            ),
            columnFilterState: JSON.stringify(gridApi.getFilterModel()),
          },
          gridIdentifier: gridId,
          accountId: accountId,
        },
        {
          onSuccess: () => {
            invalidateGridQuery();
            if (saveType === GridContextOptions.SAVE_AS) {
              setOpenSaveModal(false);
              updateSaveAsViewName(name);
            }
          },
        }
      );
    else if (saveType === GridContextOptions.SAVE_VIEW) {
      const gridConfigId = Number(gridView);
      const isValidGridConfigId = !isNaN(Number(gridView));

      isValidGridConfigId &&
        saveGrid.mutate(
          {
            pageId: pageId,
            pageGridConfigId: gridConfigId,
            gridConfig: {
              name: name,
              isDefault: isDefault,
              columnState: JSON.stringify(gridColumnApi.getColumnState()),
              columnGroupState: JSON.stringify(
                gridColumnApi.getColumnGroupState()
              ),
              columnFilterState: JSON.stringify(gridApi.getFilterModel()),
            },
            gridIdentifier: gridId ?? GridConfigIds.DOCUMENTS_GRID,
            accountId: accountId,
          },
          {
            onSuccess: () => invalidateGridQuery(),
          }
        );
    }
  };

  /**
   *
   * @returns Current grid's info.
   */
  const getGridDetails = () => {
    if (
      !isSuccess ||
      !gridConfigData ||
      gridConfigData.gridConfigurations.length < 1
    )
      return undefined;
    const selectedView = gridConfigData.gridConfigurations.find(
      (gridConfig) => gridConfig.id === Number(gridView)
    );
    if (!selectedView) return undefined;

    if (!gridApi || !gridColumnApi) return undefined;

    return selectedView;
  };

  const handleMenuItemClick = (option: GridContextOptions) => {
    const gridDetails = getGridDetails();

    switch (option) {
      case GridContextOptions.SAVE_AS:
        setOpenSaveModal(true);
        break;
      case GridContextOptions.SAVE_VIEW:
        if (!gridDetails) break;

        saveGridConfig(
          gridDetails.name,
          gridDetails.isDefault,
          GridContextOptions.SAVE_VIEW
        );
        break;
      case GridContextOptions.MANAGE_VIEWS:
        setManageViewsModal(true);
        break;
      default:
        console.log("no action");
        break;
    }
  };

  const getViewDot = () => {
    return (
      <StarIcon />
    );
  };

  const SelectIcon = (props: Props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  return (
    <React.Fragment>
      {openSaveModal && (
        <CreateGridConfigModal
          handlePopupClose={() => {
            setOpenSaveModal(false);
          }}
          onSubmit={saveGridConfig}
          gridNames={gridNames}
        />
      )}
      {manageViewsModal && (
        <ManageViewsModal
          showDialog={manageViewsModal}
          handlePopupClose={(shouldRefreshData?: boolean) => {
            invalidateGridQuery();
            setManageViewsModal(false);
          }}
          currentlySelectedConfigDeleted={(
            deletedConfigsIds: string[],
            newDefaultConfigId: string
          ) => {
            handleGridView(newDefaultConfigId);
          }}
          gridConfigs={gridConfigData?.gridConfigurations}
          accountId={accountId}
          pageId={pageId}
          gridId={gridId}
        />
      )}
      <Box flexGrow={1}>
        {isSuccess && gridConfigData && (
          <FormControl variant="outlined" size="small">
            <Typography
              variant="body3"
              component="label"
              className={"input-label"}
            >{`Choose view`}</Typography>
            <Select
              labelId="grid-view-label"
              id="grid-view"
              value={gridView}
              onChange={onSelectChange}
              className={"input-field select-dropdown"}
              classes={{ select: classes.select }}
              renderValue={(value) => {
                if (value === GRID_CONFIG_DEFAULT_VIEW) return value;

                const viewName = gridConfigData.gridConfigurations.find(
                  (view) => view.id.toString() === value.toString()
                );
                return `${viewName?.name ?? ""}`;
              }}
              IconComponent={SelectIcon}
            >
              {defaultGridConfig && (
                <MenuItem
                  value={defaultGridConfig.id}
                  className={`${
                    defaultViewSelected ? classes.selectedItem : ""
                  }`}
                >
                  <Typography variant="body1" noWrap sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                    className={ classes.defaultItem}
                  >
                    {defaultGridConfig.name}
                    {getViewDot()}
                  </Typography>
                </MenuItem>
              )}
              {/* Standard view */}
              <MenuItem
                value={GRID_CONFIG_DEFAULT_VIEW}
                className={`${
                  standardViewSelected ? classes.selectedItem : ""
                }`}
              >
                <Typography variant="body1" noWrap sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  className={ classes.defaultItem}
                >
                  {GRID_CONFIG_DEFAULT_VIEW}
                  {defaultGridConfig === undefined && getViewDot()}
                </Typography>
              </MenuItem>
              {gridConfigData.gridConfigurations
                .filter((gridConfig) => !gridConfig.isDefault)
                .map((gridConfig) => {
                  const isCurrentViewSelected =
                    String(gridView) === String(gridConfig.id);
                  return (
                    <MenuItem
                      key={gridConfig.id}
                      value={gridConfig.id}
                      className={`${
                        isCurrentViewSelected ? classes.selectedItem : ""
                      }`}
                    >
                      <Typography variant="body1" noWrap>
                        {gridConfig.name}
                      </Typography>
                    </MenuItem>
                  );
                })}
              <hr />
              <MenuItem
                value={GridContextOptions.SAVE_VIEW}
                disabled={standardViewSelected}
                className={classes.actionableItem}
              >
                <Box
                  display="flex"
                  sx={standardViewSelected ? {} : { color: blueShade }}
                  alignItems="center"
                >
                  <UilFileCheck />
                  <Typography variant="body1" sx={{ marginLeft: "4px" }}>
                    {GridContextOptions.SAVE_VIEW}
                  </Typography>
                </Box>
              </MenuItem>
              <MenuItem value={GridContextOptions.SAVE_AS} className={classes.actionableItem}>
                <Box
                  display="flex"
                  sx={{ color: blueShade }}
                  alignItems="center"
                >
                  <UilSave />
                  <Typography variant="body1" sx={{ marginLeft: "4px" }}>
                    {GridContextOptions.SAVE_AS}
                  </Typography>
                </Box>
              </MenuItem>
              <MenuItem value={GridContextOptions.MANAGE_VIEWS} className={classes.actionableItem}>
                <Box
                  display="flex"
                  sx={{ color: blueShade }}
                  alignItems="center"
                >
                  <UilSetting />
                  <Typography variant="body1" sx={{ marginLeft: "4px" }}>
                    {GridContextOptions.MANAGE_VIEWS}
                  </Typography>
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </Box>
    </React.Fragment>
  );
};

export default SaveGridStateOptions;
