import React from "react";
import { DashboardProps } from "../../models/dashboardProps";
import {
  formatAsPercentage,
  getDateTickLabelsFormatter,
} from "../../utils/widgetUtils";
import DashboardItem from "./dashboardItem";

const NetAbsorptionRateAreaWidget: React.FunctionComponent<DashboardProps> = (
  props
) => {
  const chartOptions = {
    autoSize: true,
    data: props.data?.info,
    series: [
      {
        type: "area",
        xKey: "period",
        yKey: "actual",
        yName: "Actual",
        tooltip: {
          renderer: (params: any) => {
            return {
              title: params.xValue,
              content: formatAsPercentage(params.yValue, 2),
            };
          },
        },
      },
    ],
    legend: {
      color: "Orange",
    },
    axes: [
      {
        type: "category",
        position: "bottom",
        label: {
          rotation: 320,
          formatter: getDateTickLabelsFormatter(props.data?.info),
        },
      },
      {
        type: "number",
        position: "left",
        tick: {
          count: 5,
        },
        label: {
          formatter: (params: any) => {
            return formatAsPercentage(params.value, 2);
          },
        },
      },
    ],
  };

  return (
    <DashboardItem
      {...props.data}
      options={chartOptions}
      title={props.data?.title || "Net Absorption Rate (Area)"}
      tooltip=""
      highlightHeader={props.highlightHeader}
    />
  );
};

export default NetAbsorptionRateAreaWidget;
