import React from "react";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Tooltip,
  Typography,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import "react-datepicker/dist/react-datepicker.css";
import "../../../properties/css/properties.css";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "../../../properties/util/formatNumber";

interface Props {
  assetSummaryValues: number[];
  filteredSummaryValues: number[];
  columnNames: string[];
  tooltipTitle?: string;
}

const PortfolioRRAssetSummary: React.FC<Props> = (props) => {
  const {
    assetSummaryValues,
    filteredSummaryValues,
    columnNames,
    tooltipTitle,
  } = props;

  const percentageValues: string[] = [];
  if (assetSummaryValues.length === filteredSummaryValues.length) {
    for (let i = 0; i < assetSummaryValues.length; i++) {
      if (assetSummaryValues[i] === -1 || filteredSummaryValues[i] === -1) {
        percentageValues.push("-");
        continue;
      }
      if (assetSummaryValues[i] === 0 && filteredSummaryValues[i] === 0)
        percentageValues.push("100%");
      else
        percentageValues.push(
          `${Math.round(
            (assetSummaryValues[i] === undefined || assetSummaryValues[i] === 0
              ? 0
              : filteredSummaryValues[i] / assetSummaryValues[i]) * 100
          )}%`
        );
    }
  }

  return (
    <Box>
      <div
        id="summaryContent"
        className="summary-content table-responsive collapse show"
      >
        <Box sx={{ paddingTop: "20px" }}>
          <TableContainer className={"summary-table-container"}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell scope="row"></TableCell>
                  <TableCell>Asset Summary</TableCell>
                  <TableCell>Filtered Summary</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {columnNames &&
                  columnNames.map((column: string, index: number) => (
                    <TableRow key={`${index}`}>
                      <TableCell scope="row">
                        <Typography variant="body2">{column}</Typography>
                        {tooltipTitle !== undefined && (
                          <Box display="flex" flexDirection="row">
                            {column}
                            <Tooltip
                              title={tooltipTitle}
                              className={"icon-tooltip"}
                            >
                              <div>
                                <ErrorIcon />
                              </div>
                            </Tooltip>
                          </Box>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {(() => {
                            const assetSummaryValue =
                              assetSummaryValues[index] !== undefined
                                ? assetSummaryValues[index]
                                : 0;
                            switch (index) {
                              case 0:
                              case 1:
                                return assetSummaryValue === -1
                                  ? "-"
                                  : formatNumber(assetSummaryValue);
                              case 2:
                              case 3:
                                return assetSummaryValue === -1
                                  ? "-"
                                  : formatPercent(assetSummaryValue);
                              case 4:
                                return assetSummaryValue === -1
                                  ? "-"
                                  : formatCurrency(assetSummaryValue);
                              default:
                                return assetSummaryValue.toString();
                            }
                          })()}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {(() => {
                            const filteredSummaryValue =
                              filteredSummaryValues[index] !== undefined
                                ? filteredSummaryValues[index]
                                : 0;
                            switch (index) {
                              case 0:
                              case 1:
                                return filteredSummaryValue === -1 ? (
                                  "-"
                                ) : (
                                  <>{formatNumber(filteredSummaryValue)}</>
                                );
                              case 2:
                              case 3:
                                return filteredSummaryValue === -1
                                  ? "-"
                                  : formatPercent(filteredSummaryValue);
                              case 4:
                                return filteredSummaryValue === -1 ? (
                                  "-"
                                ) : (
                                  <>{formatCurrency(filteredSummaryValue)}</>
                                );
                              default:
                                return <>{filteredSummaryValue.toString()}</>;
                            }
                          })()}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    </Box>
  );
};

export default PortfolioRRAssetSummary;
