import React, { Fragment } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { UseQueryResult } from "@tanstack/react-query";
import {
  Box,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
} from "@mui/material";

import { TSpaceBuildingGroupListModel } from "../model/spaceBuildingGroup";
import "../../css/properties.css";
import ToggleChevron from "../../../../shared/view/ToggleChevron";

interface SpaceListProps {
  spaceList: UseQueryResult<TSpaceBuildingGroupListModel>;
}

const PropertyViewSpaceList: React.FunctionComponent<SpaceListProps> = (
  props
) => {
  const spaceList = props.spaceList;

  return spaceList.isLoading ? (
    <Box m="auto">
      <CircularProgress />
    </Box>
  ) : (
    <Fragment>
      <SpaceListMain spaceList={spaceList} />
    </Fragment>
  );
};

const SpaceListMain: React.FunctionComponent<SpaceListProps> = (props) => {
  const getStatusClass = (status: string) => {
    switch (status) {
      case "Active":
        return "badge badge-success";
      case "Docked":
      case "Pending":
        return "badge badge-warning";
      default:
        return "badge badge-danger";
    }
  };

  /**
   * Setting the initial accordions to open state.
   */
  const getInitialAccordions = () => {
    const initialAccordions: string[] = [];
    props.spaceList.data?.spaceBuildings.forEach((building) => {
      building.spaceFloors.forEach((floorGroup) => {
        initialAccordions.push(floorGroup.floor);
      });
    });

    return initialAccordions;
  };

  // state of the accordions
  const [expandedAccordions, setExpandedAccordions] = React.useState<string[]>(
    getInitialAccordions()
  );

  /**
   * Either adds or removes an accordion from a list of accordions.
   * @param accordionName
   */
  const handleAccordionChange =
    (accordionName: string) =>
    (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      if (isExpanded)
        setExpandedAccordions((expandedAccordions) =>
          expandedAccordions.concat(accordionName)
        );
      else
        setExpandedAccordions(
          expandedAccordions.filter((accordion) => accordion !== accordionName)
        );
    };

  return (
    <div id="property-spaces-content" className="table-responsive-md">
      <table className="table table-sm table-hover tbl-space-list">
        <colgroup>
          <col className="tbl-space-list-ten" />
          <col className="tbl-space-list-twenty" />
          <col className="tbl-space-list-ten" />
          <col className="tbl-space-list-ten" />
          <col className="tbl-space-list-twenty" />
          <col className="tbl-space-list-ten" />
          <col className="tbl-space-list-ten" />
          <col className="tbl-space-list-ten" />
        </colgroup>
        <thead>
          <tr>
            <th scope="col" className="text-uppercase border-top-0 pl-3">
              <small>Status</small>
            </th>
            <th scope="col" className="text-uppercase border-top-0">
              <small>Title</small>
            </th>
            <th scope="col" className="text-uppercase border-top-0">
              <small>Floor</small>
            </th>
            <th scope="col" className="text-uppercase border-top-0">
              <small>RSF</small>
            </th>
            <th scope="col" className="text-uppercase border-top-0">
              <small>Tenant</small>
            </th>
            <th scope="col" className="text-uppercase border-top-0">
              <small>Common</small>
            </th>
            <th scope="col" className="text-uppercase border-top-0">
              <small>Equip.</small>
            </th>
            <th scope="col" className="text-uppercase border-top-0">
              <small>Primary</small>
            </th>
          </tr>
        </thead>
      </table>

      {props &&
      props.spaceList &&
      props.spaceList.data &&
      props.spaceList.data.spaceBuildings.length > 0 ? (
        <div>
          {props.spaceList.data?.spaceBuildings.map((bldg) => {
            return (
              <div key={bldg.buildingId}>
                <table width="100%">
                  <tbody>
                    <tr className="table-secondary">
                      <td className="pl-3 text-uppercase">
                        <h6>{bldg.buildingTitle}</h6>
                      </td>
                    </tr>
                  </tbody>
                </table>

                {bldg.spaceFloors.map((floorGroup, index: number) => {
                  return (
                    <Accordion
                      key={`${index}`}
                      expanded={
                        expandedAccordions.indexOf(floorGroup.floor) > -1
                      }
                      onChange={handleAccordionChange(floorGroup.floor)}
                      // className="card"
                    >
                      <AccordionSummary
                        aria-controls={`panel1bh-content-${index}`}
                        id={`anel1bh-header-${index}`}
                        // className="row"
                      >
                        <span className="col text-nowrap text-uppercase text-left mr-3">
                          <ToggleChevron
                            isExpanded={
                              expandedAccordions.indexOf(floorGroup.floor) > -1
                            }
                          />
                          <span>FLOOR: {floorGroup.floor}</span>
                        </span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table className="bg-light mb-0 table-sm tbl-space-list">
                          <colgroup>
                            <col className="tbl-space-list-ten" />
                            <col className="tbl-space-list-twenty" />
                            <col className="tbl-space-list-ten" />
                            <col className="tbl-space-list-ten" />
                            <col className="tbl-space-list-twenty" />
                            <col className="tbl-space-list-ten" />
                            <col className="tbl-space-list-ten" />
                            <col className="tbl-space-list-ten" />
                          </colgroup>
                          <tbody>
                            {floorGroup.spaces &&
                              floorGroup.spaces.map((spc) => (
                                <tr id={spc.id} key={spc.id}>
                                  <td className="pl-3">
                                    <span
                                      className={getStatusClass(spc.status)}
                                    >
                                      {spc.status}
                                    </span>
                                  </td>
                                  <td>
                                    <a
                                      href="#modalUpdateSpace"
                                      data-toggle="modal"
                                      data-target="#modalUpdateSpace"
                                    >
                                      {spc.title}
                                    </a>
                                  </td>
                                  <td>{spc.floor}</td>
                                  <td>
                                    {spc.rsf === undefined
                                      ? "-"
                                      : spc.rsf.toLocaleString()}
                                  </td>
                                  <td>{spc.tenantName}</td>
                                  <td>{spc.isCommonArea && <CheckIcon />}</td>
                                  <td>{spc.hasEquipment && <CheckIcon />}</td>
                                  <td>{spc.isPrimary && <CheckIcon />}</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <span>{`No spaces found.`}</span>
        </div>
      )}
    </div>
  );
};

export default PropertyViewSpaceList;
