import React, { useState } from "react";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { UilDocumentInfo } from '@iconscout/react-unicons'
import { ICellRendererParams } from "ag-grid-community";

import DocumentsDownloadModal from "./property rent roll generations/downloaDocumentsModal";
import { Document } from "../model/rentRollListModel";

const DocumentRenderer: React.FunctionComponent<ICellRendererParams> = (
  props: any
) => {
  const [openDocument, setOpenDocument] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [documnts, setDocuments] = useState<Document[]>([]);

  const getDocuments = () => {
    const suiteLeaseOtherId = props.data?.id;
    for (let i = 0; i < props.data?.generations?.length; i++) {
      if (props.data?.generations[i].leaseId === suiteLeaseOtherId) {
        setDocuments(props.data?.generations[i].documents);
        break;
      }
    }
  };

  const openDocuments = () => {
    getDocuments();
    setOpenDocument(true);
  };

  return (
    <React.Fragment>
      {openDocument && (
        <DocumentsDownloadModal
          openDocument={openDocument}
          handlePopupClose={() => setOpenDocument(false)}
          leaseGenId={props.data.id}
        />
      )}
      {props.data.leaseCode !== undefined && (
      <Box display="flex" flexDirection="row"
        sx={{
          "& svg": {
            maxHeight: "20px",
            maxWidth: "20px",
          },
        }}
      >
        <Box>{props.data.leaseCode}</Box>
        <Box>
          <Tooltip title="View Lease Documents" onClick={openDocuments}>
            <div style={{ marginLeft: 8, paddingTop: 4, cursor: "pointer" }}>
              <UilDocumentInfo
                className={"icon"}
              />
            </div>
          </Tooltip>
        </Box>
      </Box>
      )}
    </React.Fragment>
  );
};

export default DocumentRenderer;
