import React, { useCallback, useRef } from "react";
import { useEffect, useState, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  GridReadyEvent,
  GridApi,
  ColumnApi,
  StatusPanelDef,
} from "ag-grid-community";
import "ag-grid-enterprise";

import { getPropertyArColumns } from "../util/tenantTable";
import { getStatusPanels } from "../../leasing activity/util/gridTable";
import { AppliedFilter, Tenant } from "../model/tenant";
import TenantDetailTable from "./TenantDetailTable";
import { useGetGridConfig } from "../../../../shared/api/pageConfigService";
import {
  GridConfigIds,
  PageConfigIds,
} from "../../../../constants/GridPageConfigurationIds";
import { usePropertyInfo } from "../../util/propertyInfoContext";
import "../../css/properties.css";
import ViewNotesRenderer from "./ViewNotesRenderer";
import { GRID_CONFIG_DEFAULT_VIEW } from "../../../../shared/view/SaveGridStateOptions";
import { useSearchParams } from "react-router-dom";

interface Props {
  tableContent: Tenant[];
  updateAppliedFilterValues: (appliedFilterValues: AppliedFilter[]) => void;
  propertyTitle: string;
  onGridRender: (gridApi: GridApi, gridColumnApi: ColumnApi) => void;
  agFilterParam?: string;
  agOrderBy: string | null;
}

const TenantTable: React.FC<Props> = (props) => {
  const propertyInfo = usePropertyInfo();
  const {
    tableContent,
    updateAppliedFilterValues,
    onGridRender,
    agFilterParam,
    agOrderBy,
  } = props;

  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();

  const [searchParams, setSearchParams] = useSearchParams();
  const newParams: { [key: string]: string } = {};
  searchParams.forEach((value: string, key: string) => {
    if (
      key.toLowerCase() === "propertyid" ||
      key.toLowerCase() === "accountidentifier"
    )
      newParams[key] = value;
  });

  const columnDefs = useMemo(() => getPropertyArColumns(), []);

  const statusBar: { statusPanels: StatusPanelDef[] } = getStatusPanels();

  const [gridView, setGridView] = useState(GRID_CONFIG_DEFAULT_VIEW);

  const gridId = useMemo(() => GridConfigIds.PROPERTY_AR_GRID, []);
  const pageId = useMemo(() => PageConfigIds.PROPERTY_AR_GRID, []);
  const accountId = propertyInfo.accountIdentifier;

  const gridConfigsQuery = useGetGridConfig({
    pageId: pageId,
    gridIdentifier: gridId,
    accountId: accountId,
  });

  /**
   * Sets the context that is retrieved from the backend whenever the gridView is changed.
   * gridView is either changed when data first loads (only if a default value exists) or when the user uses the dropdown
   */
  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1 ||
      gridApi === undefined ||
      gridColumnApi === undefined
    )
      return;

    if (gridView === GRID_CONFIG_DEFAULT_VIEW) {
      gridApi.setFilterModel({});
      gridColumnApi.resetColumnState();
      gridApi.redrawRows();
      return;
    }

    const selectedGridConfig = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.id === Number(gridView)
    );

    if (selectedGridConfig === undefined) return;

    try {
      gridApi.setFilterModel(JSON.parse(selectedGridConfig.columnFilterState));
      gridColumnApi.applyColumnState({
        state: JSON.parse(selectedGridConfig.columnState),
      });
    } catch (ex) {
      setGridView(GRID_CONFIG_DEFAULT_VIEW);
    }
    gridApi.redrawRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridView, gridApi, gridColumnApi]);

  const saveAsView = React.useRef("");
  // const [gridFilters, setGridFilters] = useState<boolean>(false);

  /**
   * If we have only one view and its not set to default, then it is set to default.
   * Otherwise, the newly created grid is set as the current grid.
   */
  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1
    ) {
      if (gridView !== GRID_CONFIG_DEFAULT_VIEW) {
        setGridView(GRID_CONFIG_DEFAULT_VIEW);
        gridApi?.redrawRows();
      }
      return;
    }

    // Setting the newly saved grid config as the currently selected view.
    const isSaveAsRender = saveAsView.current.trim().length > 0;

    if (isSaveAsRender) {
      const savedAsView = gridConfigsQuery.data.gridConfigurations.find(
        (gridConfig) =>
          gridConfig.name.toLowerCase().trim() ===
          saveAsView.current.toLowerCase().trim()
      );
      if (savedAsView) setGridView(savedAsView.id.toString());
      saveAsView.current = "";
      return;
    }

    const defaultView = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.isDefault
    );
    if (
      defaultView &&
      gridView !== defaultView.id.toString() &&
      gridView === GRID_CONFIG_DEFAULT_VIEW
    )
      setGridView(defaultView.id.toString());
    gridApi?.redrawRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridConfigsQuery.isSuccess, gridConfigsQuery.data]);

  useEffect(() => {
    calculateTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContent]);

  const autoSizeAll = () => {
    if (gridColumnApi !== null)
      setTimeout(() => gridColumnApi?.autoSizeAllColumns(), 500); // TODO remove setTimeout when Ag-grid fixes autoSizeColumns on React 18
  };

  const onGridReady = (event: GridReadyEvent) => {
    onGridRender(event.api, event.columnApi);
    if (tableContent === undefined || tableContent.length === 0) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }

    setGridApi(event.api);
    setGridColumnApi(event.columnApi);
  };

  const aggFuncs = {
    // this overrides the grids built-in sum function
    list: (values: any) => {
      let listOfItems = "";

      values.forEach((value: any) => (listOfItems += value + ", "));
      listOfItems = listOfItems.replace(/,\s*$/, "");

      return listOfItems;
    },
    distinctcount: (values: any) => {
      const testCnt: string[] = [];
      values.forEach((value: any) => {
        testCnt.push(value);
      });
      let test = new Set(testCnt);
      return test.size;
    },
  };

  /**
   * Calculates the filtered values for the Assest Summary - Previous values are taken from tableContent and
   * filtered values are retrieved from forEachNodeAfterFilter.
   */
  const calculateTotals = () => {
    let prepaid = 0,
      prepaidPercent = 0,
      prevPrepaid = 0;
    let thirtyDays = 0,
      thirtyDaysPercent = 0,
      prevThirtyDays = 0;
    let sixtyDays = 0,
      sixtyDaysPercent = 0,
      prevSixtyDays = 0;
    let ninetyDays = 0,
      ninetyDaysPercent = 0,
      prevNinetyDays = 0;
    let ninetyPlusDays = 0,
      ninetyPlusDaysPercent = 0,
      prevNinetyPlusDays = 0;
    let totalBalance = 0,
      totalBalancePercent = 0,
      prevTotalBalance = 0;
    tableContent.forEach((tableContentEl) => {
      prevPrepaid = prevPrepaid + tableContentEl.prepaid;
      prevThirtyDays = prevThirtyDays + tableContentEl.thirtyDays;
      prevSixtyDays = prevSixtyDays + tableContentEl.sixtyDays;
      prevNinetyDays = prevNinetyDays + tableContentEl.ninetyDays;
      prevNinetyPlusDays = prevNinetyPlusDays + tableContentEl.ninetyPlusDays;
      prevTotalBalance = prevTotalBalance + tableContentEl.totalBalance;
    });
    gridApi?.forEachNodeAfterFilter((node) => {
      prepaid = prepaid + node.data.prepaid;
      thirtyDays = thirtyDays + node.data.thirtyDays;
      sixtyDays = sixtyDays + node.data.sixtyDays;
      ninetyDays = ninetyDays + node.data.ninetyDays;
      ninetyPlusDays = ninetyPlusDays + node.data.ninetyPlusDays;
      totalBalance = totalBalance + node.data.totalBalance;
    });

    prepaidPercent = formatNumber((prepaid / prevPrepaid) * 100, true);
    thirtyDaysPercent = formatNumber((thirtyDays / prevThirtyDays) * 100, true);
    sixtyDaysPercent = formatNumber((sixtyDays / prevSixtyDays) * 100, true);
    ninetyDaysPercent = formatNumber((ninetyDays / prevNinetyDays) * 100, true);
    ninetyPlusDaysPercent = formatNumber(
      (ninetyPlusDays / prevNinetyPlusDays) * 100,
      true
    );
    totalBalancePercent = formatNumber(
      (totalBalance / prevTotalBalance) * 100,
      true
    );

    updateAppliedFilterValues([
      { value: formatNumber(prepaid, false), percent: prepaidPercent },
      { value: formatNumber(thirtyDays, false), percent: thirtyDaysPercent },
      { value: formatNumber(sixtyDays, false), percent: sixtyDaysPercent },
      { value: formatNumber(ninetyDays, false), percent: ninetyDaysPercent },
      {
        value: formatNumber(ninetyPlusDays, false),
        percent: ninetyPlusDaysPercent,
      },
      {
        value: formatNumber(totalBalance, false),
        percent: totalBalancePercent,
      },
    ]);
  };

  /**
   * If a number is NaN, returns 0; otherwise returns filtered number
   * @param filteredVal number to be filtered
   */
  const formatNumber = (filteredVal: number, isPercent: boolean) => {
    if (isNaN(filteredVal)) return 0;
    if (isPercent) return Number(Math.abs(filteredVal).toFixed(2));
    return Number(filteredVal.toFixed(2));
  };

  /**
   * Executed when table data is first received. Calculates Filtered values and sets state from context.
   * @param params
   */
  const onFirstDataRendered = (params: any) => {
    //    params.api.sizeColumnsToFit();
    autoSizeAll();

    calculateTotals();
  };

  /**
   * Executed whenever filter is applied on the table
   * @param params
   */
  const onFilterChanged = (params: any) => {
    calculateTotals();
  };

  function NoteFilterRenderer() {}

  NoteFilterRenderer.prototype.init = function (params: { value: any }) {
    this.eGui = document.createElement("div");
    switch (params.value) {
      case "true":
        // this.eGui.innerHTML = '<i class="fas fa-cat"></i>';
        // this.eGui.innerHTML = '<div><UilNotes /></div>'
        this.eGui.innerHTML = "<label>Has Notes</label>";

        // this.eGui.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M16,14H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Zm0-4H10a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Zm4-6H17V3a1,1,0,0,0-2,0V4H13V3a1,1,0,0,0-2,0V4H9V3A1,1,0,0,0,7,3V4H4A1,1,0,0,0,3,5V19a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V5A1,1,0,0,0,20,4ZM19,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V6H7V7A1,1,0,0,0,9,7V6h2V7a1,1,0,0,0,2,0V6h2V7a1,1,0,0,0,2,0V6h2Z"></path></svg>';
        break;
      case "false":
        // this.eGui.innerHTML = '<i class="fas fa-crow"></i>';
        this.eGui.innerHTML = "<label>No Notes</label>";
        break;
      default:
        // this.eGui.innerHTML = '<i class="fas fa-frog"></i>';
        this.eGui.innerHTML = "<label>(Select All)</label>";
    }
  };

  NoteFilterRenderer.prototype.getGui = function () {
    return this.eGui;
  };

  const hasBeenFiltered = useRef<boolean>(false);

  const restoreFromHardCoded = useCallback(
    (
      toBeFilteredParam: string | undefined,
      gridApi: GridApi,
      toBeOrderedParam: string | null,
      gridColumnApi: ColumnApi
    ) => {
      if (toBeFilteredParam) {
        const hardcodedFilter = {
          tenant: { type: "contains", filter: toBeFilteredParam },
        };
        gridApi.setFilterModel(hardcodedFilter);
        gridApi?.redrawRows();
      }

      if (toBeOrderedParam)
        gridColumnApi?.applyColumnState({
          state: [{ colId: toBeOrderedParam, sort: "desc" }],
          defaultState: { sort: null },
        });
      gridApi.redrawRows();
    },
    []
  );

  useEffect(() => {
    if (
      (agFilterParam || agOrderBy) &&
      gridApi &&
      gridColumnApi //&&
      //!hasBeenFiltered.current
    ) {
      const hardCodedTenantTitle = searchParams.get("tenantTitle");
      restoreFromHardCoded(
        hardCodedTenantTitle !== null ? hardCodedTenantTitle : agFilterParam,
        gridApi,
        agOrderBy,
        gridColumnApi
      );
      hasBeenFiltered.current = true;
    }

    const hardCodedPropertyId = searchParams.get("propertyId");

    /**
     *  Removes notification parameters from the url as it is not needed
     */
    if (propertyInfo.id == hardCodedPropertyId) {
      setSearchParams(newParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    agFilterParam,
    gridApi,
    gridColumnApi,
    agOrderBy,
    restoreFromHardCoded,
    props.propertyTitle,
    searchParams,
  ]);

  return (
    <React.Fragment>
      <div className="standard-content">
        <div
          className="table-standard-toggle-container"
          style={{ width: "100%" }}
        >
          <div
            id="property-ar-content"
            className="example-wrapper"
            style={{ width: "100%" }}
          >
            <div
              id="myGrid"
              className="ag-theme-alpine ag-theme-alpine-container-override"
              style={{ width: "100%" }}
            >
              <AgGridReact
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                rowData={tableContent}
                onFilterChanged={onFilterChanged}
                onFirstDataRendered={onFirstDataRendered}
                suppressAggFuncInHeader
                aggFuncs={aggFuncs}
                statusBar={statusBar}
                enableRangeSelection={true}
                autoGroupColumnDef={{ minWidth: 100 }}
                sortingOrder={["desc", "asc"]}
                defaultColDef={{
                  minWidth: 120,
                  resizable: true,
                  filterParams: {
                    buttons: ["apply", "reset"],
                    closeOnApply: true,
                  },
                }}
                masterDetail
                getRowId={(params) => {
                  return params.data.tenantId;
                }}
                detailCellRenderer={"tenantDetail"}
                components={{
                  tenantDetail: TenantDetailTable,
                  viewNotesRenderer: ViewNotesRenderer,
                  noteFilterRenderer: NoteFilterRenderer,
                }}
                excelStyles={[
                  {
                    id: "dateFormat",
                    dataType: "DateTime",
                    numberFormat: { format: "mm/dd/yyyy;@" },
                  },
                ]}
                // isExternalFilterPresent={isExternalFilterPresent}
                // doesExternalFilterPass={doesExternalFilterPass}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TenantTable;
