import { gridHeaderModel } from "../model/gridHeaderModel";
import { ColDef } from "ag-grid-community";
import { GridRowList } from "./constant";
export const getRowData = () => {
  const data: gridHeaderModel[] = [
    {
      report: GridRowList.ACCOUNTS_RECEIVABLE,
      dateRange: "Mar 2022",
      includeBudget: "",
      groupBy: "",
      financialBasis: "",
      businessUnit: "",
      exportType: "EXCEL",
    },
    {
      report: GridRowList.RENT_ROLL_LEASE_VIEW,
      dateRange: "Mar 2022",
      includeBudget: "",
      groupBy: "",
      financialBasis: "",
      businessUnit: "",
      exportType: "EXCEL",
    },
    {
      report: GridRowList.RENT_ROLL_SUITE_VIEW,
      dateRange: "Mar 2022",
      includeBudget: "",
      groupBy: "",
      financialBasis: "",
      businessUnit: "",
      exportType: "EXCEL",
    },
    {
      report: GridRowList.LEASING_ACTIVITY,
      dateRange: "Mar 2022",
      includeBudget: "",
      groupBy: "",
      financialBasis: "",
      businessUnit: "",
      exportType: "EXCEL",
    },
    {
      report: GridRowList.STACKING_PLAN,
      dateRange: "Mar 2022",
      includeBudget: "",
      groupBy: "",
      financialBasis: "",
      businessUnit: "",
      exportType: "EXCEL",
    },
    {
      report: GridRowList.FINANCIALS_BALANCE_SHEET,
      dateRange: "Mar 2022 - Mar 2022",
      includeBudget: "",
      groupBy: "Month",
      financialBasis: "fb1",
      businessUnit: "bu1",
      exportType: "EXCEL",
    },
    {
      report: GridRowList.FINANCIALS_INCOME_STATEMENT,
      dateRange: "Mar 2022 - Mar 2022",
      includeBudget: "No",
      groupBy: "Year",
      financialBasis: "fb2",
      businessUnit: "bu2",
      exportType: "EXCEL",
    },
    {
      report: GridRowList.LOANS,
      dateRange: "Mar 2022",
      includeBudget: "",
      groupBy: "",
      financialBasis: "",
      businessUnit: "",
      exportType: "EXCEL",
    },
    {
      report: GridRowList.PROPERTY_OVERVIEW,
      dateRange: "",
      includeBudget: "",
      groupBy: "",
      financialBasis: "",
      businessUnit: "",
      exportType: "EXCEL",
    },
    {
      report: GridRowList.PROPERTY_SNAPSHOT,
      dateRange: "Mar 2022",
      includeBudget: "",
      groupBy: "",
      financialBasis: "",
      businessUnit: "",
      exportType: "EXCEL"
    },
  ];
  return data;
};

export const getColumnDefs = (param?: string) => {
  const columnDefs: ColDef[] = [
    {
      headerName: "Report",
      field: "report",
      headerCheckboxSelection: param === "properties" ? false : true,
      headerCheckboxSelectionFilteredOnly:
        param === "properties" ? false : true,
      checkboxSelection: true,
    },
    {
      headerName: "Date",
      field: "dateRange",
      editable: true,
      cellEditor: "dateEditor",
      cellEditorPopup: true,
      cellRenderer: "EditIconRenderer",
    },
    {
      headerName: "Include Budget",
      field: "includeBudget",
      editable: true,
      cellEditor: "budgetEditor",
      cellEditorPopup: true,
      cellRenderer: "DownArrowIconRenderer",
    },
    {
      headerName: "Group By",
      field: "groupBy",
      editable: true,
      cellEditor: "groupEditor",
      cellEditorPopup: true,
      cellRenderer: "DownArrowIconRenderer",
    },
    {
      headerName: "Export Type",
      field: "exportType",
      // editable: true,
      // cellEditor: "exportEditor",
      // cellEditorPopup: true,
    },
  ];
  return columnDefs;
};
