import { Loan } from "../model/LoansModel";

export const dummyLoans: Loan[] = [
  {
    loanId: "1",
    lenderName: "Wells Fargo",
    principalBalance: 890000,
    originalLoanBalance: 1000000,
    loanTypeId: -1,
    loanType: "Traditional Bank Loan",
    prepaymentPenalty: true,
    interestRateTypeId: 0,
    interestRateType: "Fixed",
    interestRateBasisTypeId: 0,
    interestRateBasisType: "Libor",
    spreadPercent: 3,
    totalInterestRatePercent: 7,
    originationDate: new Date("10/03/2021"),
    maturityDate: new Date("10/03/2025"),
    term: 4,
    extensionMaturityDate: new Date("10/03/2024"),
    servicerName: "Wells Fargo",
    loanStatusDescription: "Inactive",
    amortizingDescription: "",
    securitizedDescription: "",
    crossCollateralizedDescription: "",
    prepaymentPenaltyDescription: ""
  },
  {
    loanId: "2",
    lenderName: "Bank of America",
    principalBalance: 754000,
    originalLoanBalance: 2000000,
    loanTypeId: -2,
    loanType: "USDA",
    prepaymentPenalty: true,
    interestRateTypeId: 0,
    interestRateType: "Fixed",
    interestRateBasisTypeId: 0,
    interestRateBasisType: "Libor",
    spreadPercent: 4,
    totalInterestRatePercent: 6.8,
    originationDate: new Date("09/05/2020"),
    maturityDate: new Date("09/05/2025"),
    term: 5,
    extensionMaturityDate: new Date("09/05/2024"),
    servicerName: "Bank of America",
    loanStatusDescription: "Active",
    amortizingDescription: "",
    securitizedDescription: "",
    crossCollateralizedDescription: "",
    prepaymentPenaltyDescription: ""    
  },
  {
    loanId: "3",
    lenderName: "Union Bank",
    principalBalance: 1200000,
    originalLoanBalance: 1500000,
    loanTypeId: -3,
    loanType: "Construction Loan",
    prepaymentPenalty: false,
    interestRateTypeId: 0,
    interestRateType: "Fixed",
    interestRateBasisTypeId: 2,
    interestRateBasisType: "Other",
    spreadPercent: 3,
    totalInterestRatePercent: 3,
    originationDate: new Date("04/13/2019"),
    maturityDate: new Date("04/13/2024"),
    term: 5,
    extensionMaturityDate: new Date("04/13/2024"),
    servicerName: "Union Bank",
    loanStatusDescription: "Active",
    amortizingDescription: "",
    securitizedDescription: "",
    crossCollateralizedDescription: "",
    prepaymentPenaltyDescription: ""
  },
];
