import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

export interface FinancialDropdownOption {
  id: string;
  name: string;
  typeid?: string;
  typename?: string;
}

interface Props {
  label: string;
  dropdownOptions: FinancialDropdownOption[];
  dropdownValue: string;
  optionSelected: (newValue: string) => void;
}

const FinancialsDropdown: React.FC<Props> = (props: Props) => {
  const { dropdownOptions, dropdownValue, optionSelected } = props;

  const SelectIcon = (props: Props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  return (
    <FormControl variant="outlined" size="small">
      <Select
        value={dropdownValue}
        onChange={(event) => optionSelected(event.target.value)}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        className={"input-field"}
        IconComponent={SelectIcon}
      >
        {dropdownOptions.map(
          (dropdownOption: FinancialDropdownOption, index: number) => (
            <MenuItem key={`${index}`} value={dropdownOption.id}>
              {dropdownOption.name}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

export default FinancialsDropdown;
