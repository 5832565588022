export const computeFieldsFilledText = (
  totalFields: number,
  filledFields: number
) => {
  if (totalFields === filledFields) return "All fields completed";
  else return `${filledFields} of ${totalFields} completed fields`;
};

export const computeNumberOfFieldsFilled = (
  sectionFields: string[],
  object?: Object
) => {
  let numOfFieldsFilled = 0;
  if (object === undefined) return numOfFieldsFilled;

  sectionFields.forEach((field) => {
    if (field in object) numOfFieldsFilled++;
  });
  
  return numOfFieldsFilled;
};
