import React, { Fragment, useState, useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Table, Box, Link, CircularProgress } from "@mui/material";

import BuildingEdit from "./buildingEdit";
import { useGetBuildingList } from "../api/buildingService";
import { TBuildingList, TBuildingListModel } from "../model/buildingListModel";
import { usePropertyInfo } from "../../util/propertyInfoContext";
import { useSortableData } from "../util/sortData";

const BuildingList: React.FunctionComponent = (props) => {
  const propertyInfo = usePropertyInfo();
  const { data: buildingList, isLoading } = useGetBuildingList({
    PropertyId: propertyInfo.id,
    accountIdentifier: propertyInfo.accountIdentifier,
  });
  return (
    <BuildingListChild
      buildingList={buildingList}
      isLoading={isLoading}
    ></BuildingListChild>
  );
};

interface BuildingListChildProps {
  buildingList?: TBuildingList;
  isLoading: boolean;
}

const BuildingListChild: React.FunctionComponent<BuildingListChildProps> = (
  props
) => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [modalData, setModalData] = useState<TBuildingListModel>();
  const queryClient = useQueryClient();
  const handleEditPopupOpen = useCallback(
    (buildingProps?: TBuildingListModel) => {
      setModalData(buildingProps);
      setShowEditPopup(true);
    },
    []
  );
  const handleEditPopupClose = useCallback(
    (shouldRefreshData: boolean) => {
      setShowEditPopup(false);
      shouldRefreshData && queryClient.invalidateQueries(["getBuildingList"]);
    },
    [queryClient]
  );

  const buildings = props.buildingList ? props.buildingList : ({} as any);
  const { items, requestSort } = useSortableData(buildings);

  return props.isLoading ? (
    <Box m="auto">
      <CircularProgress />
    </Box>
  ) : (
    <Fragment>
      <div id="main-content">
        <div className="row mt-3">
          <div className="col">
            <div
              id="property-buildings-content"
              className="table-responsive-md"
            >
              <Table className="table table-sm table-striped">
                <thead>
                  <tr>
                    <th scope="col" className="border-top-0 pl-3">
                      <Link
                        component="button"
                        variant="body2"
                        className="text-uppercase"
                        color="primary"
                        onClick={() => requestSort("status")}
                      >
                        <small>STATUS</small>
                      </Link>
                    </th>
                    <th scope="col" className="border-top-0">
                      <Link
                        component="button"
                        variant="body2"
                        className="text-uppercase"
                        color="primary"
                        onClick={() => requestSort("title")}
                      >
                        <small>TITLE</small>
                      </Link>
                    </th>
                    <th scope="col" className="border-top-0">
                      <Link
                        component="button"
                        variant="body2"
                        className="text-uppercase"
                        color="primary"
                        onClick={() => requestSort("street")}
                      >
                        <small>ADDRESS</small>
                      </Link>
                    </th>
                    <th scope="col" className="border-top-0">
                      <Link
                        component="button"
                        variant="body2"
                        className="text-uppercase"
                        color="primary"
                        onClick={() => requestSort("rsf")}
                      >
                        <small>RSF</small>
                      </Link>
                    </th>
                    <th scope="col" className="border-top-0">
                      <Link
                        component="button"
                        variant="body2"
                        className="text-uppercase"
                        color="primary"
                        onClick={() => requestSort("accountingKey")}
                      >
                        <small>Accounting Code</small>
                      </Link>
                    </th>
                    <th scope="col" className="border-top-0">
                      <Link
                        component="button"
                        variant="body2"
                        className="text-uppercase"
                        color="primary"
                        onClick={() => requestSort("rsf")}
                      >
                        <small>GL Code Prefix</small>
                      </Link>
                    </th>
                  </tr>
                </thead>
                {props &&
                props.buildingList &&
                props.buildingList.length > 0 ? (
                  <tbody>
                    {props &&
                      props.buildingList &&
                      items &&
                      items.map((bld) => (
                        <tr id={bld.id} key={bld.id}>
                          <td className="p1-3">
                            <span className="badge badge-success">
                              {bld.status}
                            </span>
                          </td>
                          <td>
                            <Link
                              component="button"
                              variant="body2"
                              className="text-uppercase"
                              color="primary"
                              onClick={() => handleEditPopupOpen(bld)}
                            >
                              {bld.title}
                            </Link>
                          </td>
                          <td>
                            <div>
                              <address>
                                {bld.street} <br />
                                {bld.city}, {bld.province}
                                {"  "}
                                {bld.postalCode}
                              </address>
                            </div>
                          </td>
                          <td>
                            {bld.rsf === undefined
                              ? "-"
                              : bld.rsf.toLocaleString()}
                          </td>
                          <td>
                            {bld.accountingKey === undefined
                              ? "-"
                              : bld.accountingKey.toLocaleString()}
                          </td>
                          <td>
                            {bld.glCodePrefix === undefined
                              ? "-"
                              : bld.accountingKey.toLocaleString()}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={6}>
                        <span>{`No buildings found.`}</span>
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
          </div>
        </div>
      </div>
      {showEditPopup && (
        <BuildingEdit
          handleEditPopupClose={handleEditPopupClose}
          showEditPopup={showEditPopup}
          buildingProps={modalData}
        />
      )}
    </Fragment>
  );
};
export default BuildingList;
