import React, { FC, useEffect } from "react";
import { Link } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  GridReadyEvent,
  GridApi,
  ColumnApi,
  StatusPanelDef,
  ExcelStyle,
  FirstDataRenderedEvent,
} from "ag-grid-community";
import "ag-grid-enterprise";

import { getStatusPanels } from "../../../properties/leasing activity/util/gridTable";
import { usePortfolioInfo } from "../../util/portfolioInfoContext";
import { getPortfolioARColumns } from "../utils/portfolioArTable";
import { PortfolioARProperty } from "../models/portfolioARModel";
import { AppliedFilter } from "../../../properties/AR/model/tenant";

interface PortfolioArGridProps {
  gridApi?: GridApi;
  gridColumnApi?: ColumnApi;
  onGridRender: (gridApi: GridApi, gridColumnApi: ColumnApi) => void;
  tableContent: PortfolioARProperty[];
  updateAppliedFilterValues: (appliedFilterValues: AppliedFilter[]) => void;
}

const PortfolioArGrid: FC<PortfolioArGridProps> = (
  props: PortfolioArGridProps
) => {
  const { tableContent, gridApi, gridColumnApi, onGridRender } = props;
  const portfolioInfo = usePortfolioInfo();

  const columnDefs = getPortfolioARColumns();

  const statusBar: { statusPanels: StatusPanelDef[] } = getStatusPanels();

  useEffect(() => {
    calculateTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContent]);

  const autoSizeAll = () => {
    setTimeout(() => gridColumnApi?.autoSizeAllColumns(), 500); // TODO remove setTimeout when Ag-grid fixes autoSizeColumns on React 18
  };

  const onGridReady = (event: GridReadyEvent) => {
    onGridRender(event.api, event.columnApi);

    if (tableContent === undefined || tableContent.length === 0) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }
  };

  const aggFuncs = {
    // this overrides the grids built-in sum function
    list: (values: any) => {
      let listOfItems = "";

      values.forEach((value: any) => (listOfItems += value + ", "));
      listOfItems = listOfItems.replace(/,\s*$/, "");

      return listOfItems;
    },
    distinctcount: (values: any) => {
      const testCnt: string[] = [];
      values.forEach((value: any) => {
        testCnt.push(value);
      });
      let test = new Set(testCnt);
      return test.size;
    },
  };

  /**
   * Calculates the filtered values for the Assest Summary - Previous values are taken from tableContent and
   * filtered values are retrieved from forEachNodeAfterFilter.
   */
  const calculateTotals = () => {
    let prepaid = 0,
      prepaidPercent = 0,
      prevPrepaid = 0;
    let thirtyDays = 0,
      thirtyDaysPercent = 0,
      prevThirtyDays = 0;
    let sixtyDays = 0,
      sixtyDaysPercent = 0,
      prevSixtyDays = 0;
    let ninetyDays = 0,
      ninetyDaysPercent = 0,
      prevNinetyDays = 0;
    let ninetyPlusDays = 0,
      ninetyPlusDaysPercent = 0,
      prevNinetyPlusDays = 0;
    let totalBalance = 0,
      totalBalancePercent = 0,
      prevTotalBalance = 0;
    tableContent.forEach((tableContentEl) => {
      prevPrepaid = prevPrepaid + (tableContentEl.prepaid ?? 0);
      prevThirtyDays = prevThirtyDays + (tableContentEl.thirtyDays ?? 0);
      prevSixtyDays = prevSixtyDays + (tableContentEl.sixtyDays ?? 0);
      prevNinetyDays = prevNinetyDays + (tableContentEl.ninetyDays ?? 0);
      prevNinetyPlusDays =
        prevNinetyPlusDays + (tableContentEl.ninetyPlusDays ?? 0);
      prevTotalBalance = prevTotalBalance + (tableContentEl.totalBalance ?? 0);
    });

    gridApi?.forEachNodeAfterFilter((node) => {
      prepaid = prepaid + (node.data.prepaid ?? 0);
      thirtyDays = thirtyDays + (node.data.thirtyDays ?? 0);
      sixtyDays = sixtyDays + (node.data.sixtyDays ?? 0);
      ninetyDays = ninetyDays + (node.data.ninetyDays ?? 0);
      ninetyPlusDays = ninetyPlusDays + (node.data.ninetyPlusDays ?? 0);
      totalBalance = totalBalance + (node.data.totalBalance ?? 0);
    });

    prepaidPercent = formatNumber((prepaid / prevPrepaid) * 100, true);
    thirtyDaysPercent = formatNumber((thirtyDays / prevThirtyDays) * 100, true);
    sixtyDaysPercent = formatNumber((sixtyDays / prevSixtyDays) * 100, true);
    ninetyDaysPercent = formatNumber((ninetyDays / prevNinetyDays) * 100, true);
    ninetyPlusDaysPercent = formatNumber(
      (ninetyPlusDays / prevNinetyPlusDays) * 100,
      true
    );
    totalBalancePercent = formatNumber(
      (totalBalance / prevTotalBalance) * 100,
      true
    );

    props.updateAppliedFilterValues([
      { value: formatNumber(prepaid, false), percent: prepaidPercent },
      { value: formatNumber(thirtyDays, false), percent: thirtyDaysPercent },
      { value: formatNumber(sixtyDays, false), percent: sixtyDaysPercent },
      { value: formatNumber(ninetyDays, false), percent: ninetyDaysPercent },
      {
        value: formatNumber(ninetyPlusDays, false),
        percent: ninetyPlusDaysPercent,
      },
      {
        value: formatNumber(totalBalance, false),
        percent: totalBalancePercent,
      },
    ]);
  };

  /**
   * If a number is NaN, returns 0; otherwise returns filtered number
   * @param filteredVal number to be filtered
   */
  const formatNumber = (filteredVal: number, isPercent: boolean) => {
    if (isNaN(filteredVal)) return 0;
    if (isPercent) return Number(Math.abs(filteredVal).toFixed(2));
    return Number(filteredVal.toFixed(2));
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    autoSizeAll();
    calculateTotals();
  };

  const LinkComponent = (props: any) => {
    const propertyId = props.data?.propertyId;
    const isTotals = String(props.value)
      .toUpperCase()
      .trim()
      .localeCompare("TOTALS");
    return (
      <React.Fragment>
        {isTotals === 0 ? (
          props.value
        ) : (
          <Link
            target="_blank"
            href={`/propertyView/ar?propertyId=${propertyId}&accountIdentifier=${portfolioInfo.accountIdentifier}`}
          >
            {props.value ?? ""}
          </Link>
        )}
      </React.Fragment>
    );
  };

  const excelStyles: ExcelStyle[] = [
    {
      id: "alignRight",
      alignment: {
        horizontal: "Right",
      },
    },
    {
      id: "dateFormat",
      dataType: "DateTime",
      numberFormat: { format: "mm/dd/yyyy;@" },
    },
    {
      id: "currencyFormat",
      numberFormat: { format: "\u0024 #,##0.00" },
    },
  ];

  return (
    <div className="standard-content">
      <div
        className="table-standard-toggle-container"
        style={{ width: "100%" }}
      >
        <div
          id="property-ar-content"
          className="example-wrapper"
          style={{ width: "100%" }}
        >
          <div
            id="myGrid"
            className="ag-theme-alpine ag-theme-alpine-container-override"
            style={{ width: "100%" }}
          >
            <AgGridReact
              excelStyles={excelStyles}
              onGridReady={onGridReady}
              columnDefs={columnDefs}
              rowData={tableContent}
              onFirstDataRendered={onFirstDataRendered}
              suppressAggFuncInHeader
              aggFuncs={aggFuncs}
              statusBar={statusBar}
              enableRangeSelection={true}
              autoGroupColumnDef={{ minWidth: 100 }}
              sortingOrder={["desc", "asc"]}
              defaultColDef={{
                minWidth: 120,
                resizable: true,
                filterParams: {
                  buttons: ["apply", "reset"],
                  closeOnApply: true,
                },
                flex: 1,
              }}
              components={{
                linkComponent: LinkComponent,
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioArGrid;
