import axios from "axios";
// import  TPropertyListModel  from "../model/propertyListModel";
import { TCreatePropertyModel } from "../model/createPropertyModel";
import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "../../../../shared/api/authorizedAxios";
import {
  MonthYearFilter,
} from "../model/propertyOverviewFilter";
import { TPropertyDetailModel } from "../model/propertyDetailModel";
import { TPropertyGetFilter } from "../../property details/model/propertyGetFilter";
import { TUpdatePropertyStatusModel } from "../model/updatePropertyStatusModel";
import { useQuery, useMutation } from "@tanstack/react-query";
import queryString from "query-string";
import { TPropertyModel } from "../model/propertyListModel";

export const useGetPropertyList = (filter: MonthYearFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getPropertyList", filter],
    () => {
      const source = axios.CancelToken.source();
      let queryParams = "";
      queryParams = queryString.stringify(filter);
      const url = `/AssetService/v1/Properties/PropertyAndAssets?${queryParams}`;

      const promise = authorizedApiAxios().get<TPropertyModel>(url, {
        cancelToken: source.token,
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      //staleTime: Infinity,
      select: (response) => {
        return response?.data;
      },
    }
  );
};

export const useCreateProperty = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: TCreatePropertyModel) => {
    const source = axios.CancelToken.source();

    const url: string = `propertyService/v1/properties`;
    let promise = authorizedApiAxios().post<string>(url, item, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: item.accountIdentifier,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useGetPropertyDetailData = (item: TPropertyGetFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  const uniqueId = item.accountIdentifier + item.propertyId;
  return useQuery(
    ["getPropertyDetailData", uniqueId],
    () => {
      const source = axios.CancelToken.source();
      const url = `/AssetService/v1/properties/${item.propertyId}`;

      const promise = authorizedApiAxios().get<TPropertyDetailModel>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: item.accountIdentifier,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => response?.data,
    }
  );
};

export const useUpdatePropertyStatus = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((item: TUpdatePropertyStatusModel) => {
    const source = axios.CancelToken.source();
    const url: string = `propertyService/v1/properties/${item.id}/UpdatePropertyStatus`;

    let promise = authorizedApiAxios().patch(url, item, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: item.accountIdentifier,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};
