import axios from "axios";
import { TRentRollList } from "../model/rentRollListModel";
import { TRentRollListFilter } from "../model/rentRollListFilter";
import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "../../../../shared/api/authorizedAxios";
import queryString from "query-string";
import { TRentRollOtherList } from "../model/rentRollOtherListModel";
import { useQuery } from "@tanstack/react-query";

export const useGetRentRollList = (filter?: TRentRollListFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getRentRollList", filter],
    () => {
      const source = axios.CancelToken.source();

      const monthYear =
        typeof filter?.monthYearFilter != "undefined"
          ? queryString.stringify(filter.monthYearFilter)
          : "";
      const qry = monthYear;
      const url: string = `/assetService/v1/rentroll/${filter?.propertyId}?${qry}`;

      let promise = authorizedApiAxios().get<TRentRollList>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: filter!.accountIdentifier,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => {
        return response!.data;
      },
      enabled: isUseGetRentRollListEnabled(filter),
    }
  );
};

const isUseGetRentRollListEnabled = (filter?: TRentRollListFilter) => {
  const requiredFilters = ["filterMonth", "filterYear"];
  const monthYear =
    typeof filter?.monthYearFilter != "undefined"
      ? queryString.stringify(filter.monthYearFilter)
      : "";
  const reqfilters = queryString.parse(monthYear);
  const containsRequiredFilters = requiredFilters.every((s) => {
    if (s in reqfilters) {
      return (
        reqfilters[s] != null && (reqfilters[s] + "").toString().length > 0
      );
    } else return false;
  });

  return (
    filter !== undefined &&
    filter !== null &&
    filter.propertyId !== null &&
    containsRequiredFilters
  );
};

export const useGetRentRollOtherList = (filter?: TRentRollListFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getRentRollOtherList", filter],
    () => {
      const source = axios.CancelToken.source();

      const monthYear =
        typeof filter?.monthYearFilter != "undefined"
          ? queryString.stringify(filter.monthYearFilter)
          : "";
      const qry = monthYear;
      const url: string = `/assetService/v1/rentroll/${filter?.propertyId}/GetOtherLeasesByPropertyId?${qry}`;

      let promise = authorizedApiAxios().get<TRentRollOtherList>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: filter!.accountIdentifier,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => {
        return response!.data;
      },
      enabled: isUseGetRentRollOtherListEnabled(filter),
    }
  );
};

const isUseGetRentRollOtherListEnabled = (filter?: TRentRollListFilter) => {
  const requiredFilters = ["filterMonth", "filterYear"];
  const monthYear =
    typeof filter?.monthYearFilter != "undefined"
      ? queryString.stringify(filter.monthYearFilter)
      : "";
  const reqfilters = queryString.parse(monthYear);
  const containsRequiredFilters = requiredFilters.every((s) => {
    if (s in reqfilters) {
      return (
        reqfilters[s] != null && (reqfilters[s] + "").toString().length > 0
      );
    } else return false;
  });

  return (
    filter !== undefined &&
    filter !== null &&
    filter.propertyId !== null &&
    containsRequiredFilters
  );
};
