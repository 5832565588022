import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import React from "react";

import "react-datepicker/dist/react-datepicker.css";
import { AppliedFilter, AssetSummaryValues } from "../model/tenant";
import { UilMapMarker } from '@iconscout/react-unicons';
import { currencyFormatter } from "../../util/gridUtils";
import { usePropertyInfo } from "../../util/propertyInfoContext";

interface Props {
  assetSummaryValues?: AssetSummaryValues;
  appliedFilterValues: AppliedFilter[];
  assetSummaryExists: boolean;
}

const ARAssetSummary: React.FC<Props> = (props) => {
  const propertyInfo = usePropertyInfo();

  const {
    assetSummaryValues,
    appliedFilterValues,
    assetSummaryExists,
  } = props;

  return (
    <Box>
      <Box>
      <Box className={"summary-property-image"}>
          {propertyInfo.imageUrl ? (
            <img
              height="210"
              width="100%"
              src={propertyInfo.imageUrl}
              alt={propertyInfo.title}
            />
          ) : (
            <img
              height="210"
              width="100%"
              src="/outline_business_black_48dp.png"
              alt={propertyInfo.title}
            />
          )}
        </Box>
      </Box>
      <TableContainer className={"summary-table-container"}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell scope="row"></TableCell>
              <TableCell>
                <Typography variant="body3">Filtered Subtotal</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body3">Total</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell scope="row">
                <Typography variant="body2">Prepaid</Typography>
              </TableCell>
              {assetSummaryExists ? (
                <>
                  {assetSummaryValues !== undefined && (
                    <TableCell>
                      <Typography variant="subtitle1">{`${currencyFormatter(
                        assetSummaryValues.prepaid,
                        "$"
                      )}`}</Typography>
                    </TableCell>
                  )}
                </>
              ) : (
                <TableCell>
                  <Typography variant="subtitle1"></Typography>
                </TableCell>
              )}
              {appliedFilterValues &&
                appliedFilterValues.map(
                  (filterValues: AppliedFilter, index: number) => (
                    index < 1 && (
                      <TableCell key={`${index}`}>
                        <Typography variant="subtitle1">
                          {`${currencyFormatter(filterValues.value, "$")}`}
                        </Typography>
                      </TableCell>
                    )
                  )
                )}
            </TableRow>
            <TableRow>
              <TableCell scope="row">
                <Typography variant="body2">0-30 Days</Typography>
              </TableCell>
              {assetSummaryExists ? (
                <>
                  {assetSummaryValues !== undefined && (
                    <TableCell>
                      <Typography variant="subtitle1">{`${currencyFormatter(
                        assetSummaryValues.thirtyDays,
                        "$"
                      )}`}</Typography>
                    </TableCell>
                  )}
                </>
              ) : (
                <TableCell>
                  <Typography variant="subtitle1"></Typography>
                </TableCell>
              )}
              {appliedFilterValues &&
                appliedFilterValues.map(
                  (filterValues: AppliedFilter, index: number) => (
                    index < 1 && (
                      <TableCell key={`${index}`}>
                        <Typography variant="subtitle1">
                          {`${currencyFormatter(filterValues.value, "$")}`}
                        </Typography>
                      </TableCell>
                    )
                  )
                )}
            </TableRow>
            <TableRow>
              <TableCell scope="row">
                <Typography variant="body2">31-60 Days</Typography>
              </TableCell>
              {assetSummaryExists ? (
                <>
                  {assetSummaryValues !== undefined && (
                    <TableCell>
                      <Typography variant="subtitle1">{`${currencyFormatter(
                        assetSummaryValues.sixtyDays,
                        "$"
                      )}`}</Typography>
                    </TableCell>
                  )}
                </>
              ) : (
                <TableCell>
                  <Typography variant="subtitle1"></Typography>
                </TableCell>
              )}
              {appliedFilterValues &&
                appliedFilterValues.map(
                  (filterValues: AppliedFilter, index: number) => (
                    index < 1 && (
                      <TableCell key={`${index}`}>
                        <Typography variant="subtitle1">
                          {`${currencyFormatter(filterValues.value, "$")}`}
                        </Typography>
                      </TableCell>
                    )
                  )
                )}
            </TableRow>
            <TableRow>
              <TableCell scope="row">
                <Typography variant="body2">61-90 Days</Typography>
              </TableCell>
              {assetSummaryExists ? (
                <>
                  {assetSummaryValues !== undefined && (
                    <TableCell>
                      <Typography variant="subtitle1">{`${currencyFormatter(
                        assetSummaryValues.ninetyDays,
                        "$"
                      )}`}</Typography>
                    </TableCell>
                  )}
                </>
              ) : (
                <TableCell>
                  <Typography variant="subtitle1"></Typography>
                </TableCell>
              )}
              {appliedFilterValues &&
                appliedFilterValues.map(
                  (filterValues: AppliedFilter, index: number) => (
                    index < 1 && (
                      <TableCell key={`${index}`}>
                        <Typography variant="subtitle1">
                          {`${currencyFormatter(filterValues.value, "$")}`}
                        </Typography>
                      </TableCell>
                    )
                  )
                )}
            </TableRow>
            <TableRow>
              <TableCell scope="row">
                <Typography variant="body2">90+ Days</Typography>
              </TableCell>
              {assetSummaryExists ? (
                <>
                  {assetSummaryValues !== undefined && (
                    <TableCell>
                      <Typography variant="subtitle1">{`${currencyFormatter(
                        assetSummaryValues.ninetyPlusDays,
                        "$"
                      )}`}</Typography>
                    </TableCell>
                  )}
                </>
              ) : (
                <TableCell>
                  <Typography variant="subtitle1"></Typography>
                </TableCell>
              )}
              {appliedFilterValues &&
                appliedFilterValues.map(
                  (filterValues: AppliedFilter, index: number) => (
                    index < 1 && (
                      <TableCell key={`${index}`}>
                        <Typography variant="subtitle1">
                          {`${currencyFormatter(filterValues.value, "$")}`}
                        </Typography>
                      </TableCell>
                    )
                  )
                )}
            </TableRow>
            <TableRow>
              <TableCell scope="row">
                <Typography variant="body2">Total Balance</Typography>
              </TableCell>
              {assetSummaryExists ? (
                <>
                  {assetSummaryValues !== undefined && (
                    <TableCell>
                      <Typography variant="subtitle1">{`${currencyFormatter(
                        assetSummaryValues.totalBalance,
                        "$"
                      )}`}</Typography>
                    </TableCell>
                  )}
                </>
              ) : (
                <TableCell>
                  <Typography variant="subtitle1"></Typography>
                </TableCell>
              )}
              {appliedFilterValues &&
                appliedFilterValues.map(
                  (filterValues: AppliedFilter, index: number) => (
                    index < 1 && (
                      <TableCell key={`${index}`}>
                        <Typography variant="subtitle1">
                          {`${currencyFormatter(filterValues.value, "$")}`}
                        </Typography>
                      </TableCell>
                    )
                  )
                )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ARAssetSummary;
