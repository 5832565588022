import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  GridApi,
  ColumnApi,
  SelectionChangedEvent,
  GridReadyEvent,
  FirstDataRenderedEvent,
  CellValueChangedEvent
} from "ag-grid-community";
import "ag-grid-enterprise";
import DateComponent from '../../components/DateComponent';
import IncludeBudgetComponent from '../../components/IncludeBudgetComponent';
import GroupByComponent from '../../components/GroupByComponent';
import FinancialBasisComponent from '../../components/FinancialBasisComponent';
import BusinessUnitComponent from '../../components/BusinessUnitComponent';
import ExportTypeComponent from '../../components/ExportTypeComponent';
import EditIconRenderer from "../../components/EditIconRenderer";
import DownArrowIconRenderer from "../../components/DownArrowIconRenderer";
import { getRowData, getColumnDefs } from "../../util/gridInfo";

interface Props {
  gridApi: GridApi | undefined;
  gridColumnApi: ColumnApi | undefined;
  updateGridApi: (gridApi: GridApi) => void;
  updateColumnApi: (columnApi: ColumnApi) => void;
}

const ReportByPropertyGrid: React.FC<Props> = (props) => {
  const rowData = getRowData();
  const columnDefs = getColumnDefs("properties");

  const frameworkComponents = {
    dateEditor: DateComponent,
    budgetEditor: IncludeBudgetComponent,
    groupEditor: GroupByComponent,
    financialEditor: FinancialBasisComponent,
    businessEditor: BusinessUnitComponent,
    exportEditor: ExportTypeComponent,
    EditIconRenderer: EditIconRenderer,
    DownArrowIconRenderer: DownArrowIconRenderer,
  };
  
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
  }, []);

  const onGridReady = (event: GridReadyEvent) => {
    props.updateGridApi(event.api);
    props.updateColumnApi(event.columnApi);
    if (typeof rowData === "undefined" || rowData.length === 0) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }
  };

  const onCellValueChanged = (event: CellValueChangedEvent ) => {
    props.updateGridApi(event.api);
  };

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    props.updateGridApi(event.api);
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.api.sizeColumnsToFit();
  };
  return (
    <React.Fragment>
      {rowData && rowData.length > 0 && (
      <div className="standard-content">
        <div
          className="table-standard-toggle-container"
          style={{ height: "100%", width: "100%" }}
        >
          <div
            id="property-details-content"
            className="example-wrapper"
            style={{ height: "100%", width: "100%" }}
          >
            <div
              id="myGrid"
              className="ag-theme-alpine ag-theme-alpine-container-override"
              style={{ height: "100%", width: "100%" }}
            >
              <AgGridReact
                onGridReady={onGridReady}
                rowData={rowData}
                columnDefs={columnDefs}
                rowSelection={'single'}
                singleClickEdit={true}
                onCellValueChanged ={onCellValueChanged}
                onSelectionChanged={onSelectionChanged}
                onFirstDataRendered={onFirstDataRendered}
                suppressRowClickSelection={true}
                defaultColDef={defaultColDef}
                components={frameworkComponents}
              >
              </AgGridReact>
            </div>
          </div>
        </div>
      </div>
      )}
    </React.Fragment>
  );
};

export default ReportByPropertyGrid;
