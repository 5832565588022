import { GridConfiguration } from "../model/gridConfig";
import { GRID_CONFIG_DEFAULT_VIEW } from "../view/SaveGridStateOptions";

export const computeDefaultGrid = (
  gridConfigs: GridConfiguration[] | undefined
) => {
  let defaultRadioVal = GRID_CONFIG_DEFAULT_VIEW;
  if (!!gridConfigs) {
    const defaultConfig = gridConfigs.find((config) => config.isDefault);
    if (defaultConfig) defaultRadioVal = defaultConfig.id.toString();
  }

  return defaultRadioVal;
};
