import React, {
  useEffect,
  useState,
  useCallback,
  Fragment,
  useMemo,
  useRef,
} from "react";
import { Layouts, Layout, Responsive } from "react-grid-layout";
import { SizeMe } from "react-sizeme";
import Grid from "@mui/material/Grid";import { useQueryClient } from "@tanstack/react-query";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { v4 } from "uuid";
import {
  CircularProgress,
  IconButton,
  Button,
  FormControl,
  Typography,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
  Link,
  Divider
} from "@mui/material";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ListSubheader from "@mui/material/ListSubheader";
import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircleIcon from "@mui/icons-material/Circle";
import clsx from "clsx";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import {
  useDeleteDashboard,
  useGetDashboard,
  useGetDashboardList,
  useSaveDashboard,
  useSetDashboardAsPublic,
  useUnsetDashboardAsPublic,
  useSetDashboardAsDefault,
  useUnsetDashboardAsDefault,
} from "../api/dashboardService";
import {
  DashboardTypeEnum,
  WidgetTypeEnum,
  WidgetCategoryEnum,
} from "../models/dashboardEnums";
import { DashboardList } from "../models/dashboardList";
import { Dashboard, DashboardLayout } from "../models/dashboard";
import useStyles from "./dashboardStyles";
import DashboardCreateModal from "./dashboardCreateModal";
import DashboardAddWidget from "./dashboardAddWidget";
import DashboardEditWidgetSettings from "./dashboardEditWidgetSettings";
import { DashboardWidgetSettings } from "../models/dashboardWidgetSettings";
import DashboardPropertiesModal from "./DashboardPropertiesModal";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import { UilSetting } from "@iconscout/react-unicons";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import ConfirmDialog from "../../../../shared/view/confirmationDialog";
import StarIcon from '@mui/icons-material/Star';
import { DateRangeEnum } from "../models/dateRangeEnum";
import { DashboardGeneralDataFilter } from "../models/dashboardGeneralDataFilter";

interface WidgetSettings {
  widgetType: WidgetTypeEnum;
  widgetCategory: WidgetCategoryEnum;
  groupIdentifier: string;
  widgetSettings?: DashboardWidgetSettings;
}

interface SelectedDashboard {
  fullId?: string;
  id: number;
  isPublic?: boolean;
  accountIdentifier?: string;
  isDefault?: boolean;
  name?: string;
  description?: string;
  denomination?: string;
  dateRange?: number;
  startDate?: Date;
  endDate?: Date;  
  bookId?: string;  
  properties?: string[];
  portfolios?: string[];
}

interface Props {
  dashboardType: DashboardTypeEnum;
  filter?: React.ReactNode;
  hasDataLoaded: boolean;
  currentFilters?: DashboardGeneralDataFilter;
  defaultBookId?: string;
  getControl: (widgetId: number, id: string) => JSX.Element | undefined;
  modifySelectedDashboard: (id: number, accountIdentifier: string, name: string, isDefault: boolean, isPublic: boolean, denomination: string, dateRange: number, startDate: Date, endDate: Date, bookId: string, properties: string[], portfolios: string[]) => void;
  accountId?: string;
}

const options = [
  "Save",
  "Save as",
];

const DashboardBase: React.FC<Props> = (props) => {
  const classes = useStyles();
  const defaultLayout = useMemo(() => {
    return {
      id: 0,
      name: "",
      description: "",
      isPublic: true,
      layout: {
        lg: [],
        md: [],
        sm: [],
      },
    };
  }, []);

  const editWidget = useRef<WidgetSettings>();
  const lastLoadedDashboard = useRef({ id: 0, dataUpdatedAt: 0 });
  const [dashboardType] = useState<DashboardTypeEnum>(props.dashboardType);
  const [selectedDashboard, setSelectedDashboard] = useState<SelectedDashboard>(
    {
      id: 0,
      accountIdentifier: "",
      name: "",
      isDefault: false,
      isPublic: false,
      properties: [],
      portfolios: []
    }
  );

  const dashboardList = useGetDashboardList(dashboardType);
  const deleteDashboard = useDeleteDashboard();
  const dashboard = useGetDashboard(
    selectedDashboard?.id,
    selectedDashboard?.accountIdentifier
  );

  const saveDashboard = useSaveDashboard();
  const setDashboardAsPublic = useSetDashboardAsPublic();
  const unsetDashboardAsPublic = useUnsetDashboardAsPublic();
  const setDashboardAsDefault = useSetDashboardAsDefault();
  const unsetDashboardAsDefault = useUnsetDashboardAsDefault();
  const queryClient = useQueryClient();

  const [memoryDashboard, setMemoryDashboard] =
    useState<Dashboard>(defaultLayout);
  const [dbDashboard, setDbDashboard] = useState<Dashboard>(defaultLayout);
  const [breakPoint, setBreakPoint] = useState("");
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isInEditWidgetMode, setIsInEditWidgetMode] = useState(false);
  const [isInAddWidgetMode, setIsInAddWidgetMode] = useState(false);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showDashProperties, setShowDashProperties] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isLast, setIsLast] = useState(false);

  // const userInfo = useUserInfo();
  const dispatchResize = useCallback(() => {
    window.dispatchEvent(new Event("resize"));
  }, []);

  const onResizeStop = function (
    newLayout: Layout[],
    _oldItem: Layout,
    _newItem: Layout,
    _placeholder: Layout,
    _event: MouseEvent,
    _element: HTMLElement
  ) {
    let item = memoryDashboard.layout[breakPoint];
    for (let i = 0; i < item.length; i++) {
      for (let j = 0; j < newLayout.length; j++) {
        if (item[i].i === newLayout[j].i) {
          item[i].x = newLayout[j].x;
          item[i].y = newLayout[j].y;
          item[i].h = newLayout[j].h;
          item[i].w = newLayout[j].w;
        }
      }
    }
    setMemoryDashboard({ ...memoryDashboard });
    dispatchResize();
  };

  const onLayoutChange = function (_: Layout[], allLayouts: Layouts) {
    dispatchResize();
  };

  const onBreakpointChange = useCallback(
    (newBreakPoint: string) => {
      setBreakPoint(newBreakPoint);
    },
    [setBreakPoint]
  );

  const onDrop = (_: Layout[], item: Layout, event: any) => {
    const data = JSON.parse(event.dataTransfer.getData("text/plain"));

    let groupIdentifier = v4();
    for (let key in memoryDashboard.layout) {
      let newItem = {
        ...item,
        i: groupIdentifier,
        w: data.w,
        h: data.h,
        minW: data.minW,
        maxW: data.maxW,
        minH: data.minH,
        maxH: data.maxH,
        widgetId: data.widgetId,
        widgetName: data.widgetName,
        widgetCategoryId: data.widgetCategoryId,
        isDraggable: true,
        isResizable: true,
      };

      memoryDashboard.layout[key].push(newItem);
    }

    setMemoryDashboard({ ...memoryDashboard });
  };

  const onRemoveItem = useCallback(
    (groupIdentifier: string) => {
      for (let key in memoryDashboard.layout) {
        memoryDashboard.layout[key].forEach((item, index) => {
          if (item.i === groupIdentifier) {
            memoryDashboard.layout[key].splice(index, 1);
          }
        });
      }

      let existingSettingsIndex = memoryDashboard.settings?.findIndex(
        (s) => s.groupIdentifier === groupIdentifier
      );
      if (existingSettingsIndex && existingSettingsIndex !== -1) {
        memoryDashboard.settings?.splice(existingSettingsIndex, 1);
      }
      setMemoryDashboard({ ...memoryDashboard });
    },
    [memoryDashboard]
  );

  useEffect(() => {
    if (
      !dashboard.data ||
      !dashboard.isFetched ||
      (dashboard.data?.id === lastLoadedDashboard.current.id &&
        dashboard.dataUpdatedAt === lastLoadedDashboard.current.dataUpdatedAt)
    )
      return;

    setDbDashboard(dashboard.data);
    lastLoadedDashboard.current = {
      id: dashboard.data?.id || 0,
      dataUpdatedAt: dashboard.dataUpdatedAt,
    };
  }, [dashboard, dbDashboard]);

  useEffect(() => {
    if (!dbDashboard) return;
    let clone = JSON.parse(JSON.stringify(dbDashboard));
    for (let key in clone.layout) {
      clone.layout[key].forEach((value: Layout, i: number) => {
        value.isDraggable =
          (key === "lg" || key === "md" || key === "sm") && isInEditMode;
        value.isResizable = (key === "lg" || key === "md") && isInEditMode;
      });
    }
    console.log('memorydashboard = ', clone);

    let currDate = new Date();
    let startDateCalc = (dbDashboard.startMonth !== undefined && dbDashboard.startYear !== undefined)? new Date(dbDashboard.startYear, dbDashboard.startMonth-1): new Date(currDate.getFullYear(), currDate.getMonth());
    let endDateCalc = (dbDashboard.endMonth !== undefined && dbDashboard.endYear !== undefined)? new Date(dbDashboard.endYear, dbDashboard.endMonth-1): new Date(currDate.getFullYear(), currDate.getMonth());
    let dateRangeWithDefault = (dbDashboard?.dateRange != null)? dbDashboard.dateRange : "LastTwoYears";
    setSelectedDashboard((prevSelectedDash) => {
      return { 
        ...prevSelectedDash, 
        denomination: dbDashboard.denomination || "Gross",       
        dateRange: DateRangeEnum[dateRangeWithDefault as keyof typeof DateRangeEnum],
        startDate: startDateCalc,
        endDate: endDateCalc,
        bookId: dbDashboard.bookId || props.defaultBookId,
        properties: (dbDashboard.properties !== undefined && dbDashboard.properties.length>0)? dbDashboard.properties : [], 
        portfolios: dbDashboard.portfolios || [], 
      };
    });
    setMemoryDashboard({ ...clone });
    dispatchResize();
  }, [dbDashboard, isInEditMode, dispatchResize, props.defaultBookId]);

  useEffect(() => {
    let currDate = new Date();
    let startDateCalc = (selectedDashboard.startDate !== undefined)? new Date(selectedDashboard.startDate?.getFullYear(), selectedDashboard.startDate?.getMonth()): new Date(currDate.getFullYear(), currDate.getMonth());
    let endDateCalc = (selectedDashboard.endDate !== undefined)? new Date(selectedDashboard.endDate?.getFullYear(), selectedDashboard.endDate?.getMonth()): new Date(currDate.getFullYear(), currDate.getMonth());

    props.modifySelectedDashboard(
      selectedDashboard.id,
      selectedDashboard.accountIdentifier || "",
      selectedDashboard.name || "",
      selectedDashboard.isDefault || false,
      selectedDashboard.isPublic || false,
      selectedDashboard.denomination || "",
      selectedDashboard.dateRange || DateRangeEnum.LastTwoYears,
      startDateCalc,
      endDateCalc,
      selectedDashboard.bookId || "",
      selectedDashboard.properties || [],
      selectedDashboard.portfolios || []
    );

  }, [dashboard, selectedDashboard, props]);

  const handleIsDefaultChange = async (checked: boolean) => {
    let isRequestSuccessful: boolean;
    if (checked) {
      try {
        await setDashboardAsDefault.mutateAsync({ ...selectedDashboard });
        isRequestSuccessful = true;
      } catch (error) {
        isRequestSuccessful = false;
      }
    } else {
      try {
        await unsetDashboardAsDefault.mutateAsync({
          ...selectedDashboard,
        });
        setSelectedDashboard((prevSelectedDash) => {
          return { ...prevSelectedDash, isDefault: checked };
        });
        isRequestSuccessful = true;
      } catch (error) {
        isRequestSuccessful = false;
      }
    }
    return isRequestSuccessful;
  };

  const handleIsPublicChange = async (checked: boolean) => {
    let isRequestSuccessful: boolean;
    if (checked) {
      try {
        await setDashboardAsPublic.mutateAsync({ ...selectedDashboard });
        setSelectedDashboard((prevSelectedDash) => {
          return { ...prevSelectedDash, isPublic: checked };
        });
        isRequestSuccessful = true;
      } catch (error) {
        isRequestSuccessful = false;
      }
    } else {
      try {
        await unsetDashboardAsPublic.mutateAsync({
          ...selectedDashboard,
        });
        isRequestSuccessful = true;
      } catch (error) {
        isRequestSuccessful = false;
      }
    }
    return isRequestSuccessful;
  };

  const onDashPropertiesSubmit = async (
    publicDash: boolean,
    defaultDash: boolean
  ) => {
    let publicResult = false,
      defaultResult = false;

    if (publicDash !== selectedDashboard.isPublic)
      publicResult = await handleIsPublicChange(publicDash);
    if (defaultDash !== selectedDashboard.isDefault)
      defaultResult = await handleIsDefaultChange(defaultDash);

    if (publicResult || defaultResult) {
      queryClient.invalidateQueries(["getDashboards", dashboardType]);
      queryClient.invalidateQueries([
        "getDashboard",
        selectedDashboard.id,
        selectedDashboard.accountIdentifier,
      ]);
    }
    setSelectedDashboard({
      ...selectedDashboard,
      isPublic: publicDash,
      isDefault: defaultDash
    })
    setShowDashProperties(false);
    setIsInEditMode(false);
  };

  const generateDOM = useMemo(() => {
    if (
      breakPoint === "" ||
      !selectedDashboard?.id ||
      !memoryDashboard.layout[breakPoint] ||
      !props.hasDataLoaded
    )
      return [];

    const elements = memoryDashboard.layout[breakPoint].map(
      (value: DashboardLayout, i: number) => {
        return (
          <div key={value.i} data-grid={value}>
            {props.getControl(value.widgetId, value.i)}
            {(breakPoint === "lg" || breakPoint === "md") &&
              isInEditMode &&
              !isInAddWidgetMode &&
              !isInEditWidgetMode && (
                <>
                  <IconButton
                    disableFocusRipple
                    disableRipple
                    size="small"
                    className={classes.dragWidget}
                  >
                    <DragIndicatorIcon />
                  </IconButton>
                  <IconButton
                    disableFocusRipple
                    disableRipple
                    onMouseDown={(event) => event.stopPropagation()}
                    onTouchStart={(event) => event.stopPropagation()}
                    size="small"
                    className={classes.removeWidget}
                    onClick={() => onRemoveItem(value.i)}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              )}
          </div>
        );
      }
    );

    return elements;
  }, [
    breakPoint,
    memoryDashboard,
    onRemoveItem,
    isInEditMode,
    isInAddWidgetMode,
    isInEditWidgetMode,
    selectedDashboard,
    classes,
    props,
  ]);

  const saveDashboardEvent = async () => {
    let result = await saveDashboard.mutateAsync({
      accountIdentifier: selectedDashboard.accountIdentifier,
      dashboardId: selectedDashboard.id,
      bookId: props.currentFilters?.bookId,
      denomination: props.currentFilters?.denomination,
      dateRange: props.currentFilters?.dateRange,
      startMonth: props.currentFilters?.startMonth,
      startYear: props.currentFilters?.startYear,
      endMonth: props.currentFilters?.endMonth,
      endYear: props.currentFilters?.endYear,
      properties: props.currentFilters?.properties,
      portfolios: props.currentFilters?.portfolios,
      lg: memoryDashboard.layout["lg"],
      md: memoryDashboard.layout["md"],
      sm: memoryDashboard.layout["sm"],
      settings: memoryDashboard.settings || [],
    });

    queryClient.invalidateQueries(["getDashboardGeneralData"]);
    setDbDashboard({ ...result.data });
    setIsInEditMode(false);
  };

  const createDashboard = async (
    name: string,
    description: string,
    isPublic: boolean,
    isDefault: boolean
  ) => {
    let result = await saveDashboard.mutateAsync({
      name: name,
      description: description,
      isPublic: isPublic,
      isDefault: isDefault,
      bookId: props.currentFilters?.bookId,
      denomination: props.currentFilters?.denomination,
      dateRange: props.currentFilters?.dateRange,
      startMonth: props.currentFilters?.startMonth,
      startYear: props.currentFilters?.startYear,
      endMonth: props.currentFilters?.endMonth,
      endYear: props.currentFilters?.endYear,
      properties: props.currentFilters?.properties,
      portfolios: props.currentFilters?.portfolios,  
      type: DashboardTypeEnum[dashboardType],
      lg: memoryDashboard.layout["lg"],
      md: memoryDashboard.layout["md"],
      sm: memoryDashboard.layout["sm"],
      settings: memoryDashboard.settings || [],
    });

    queryClient.invalidateQueries(["getDashboards", dashboardType]);
    queryClient.invalidateQueries(["getDashboardGeneralData"]);
    setDbDashboard({ ...result.data });
    setSelectedDashboard({
      ...result.data,
      bookId: "",
      dateRange: DateRangeEnum.LastTwoYears,
      startDate: new Date(),
      endDate: new Date(),
      fullId: getFullDashboardIdBase(
      result.data.accountIdentifier,
      result.data.id,
      ),
    });
    setShowCreatePopup(false);
    setIsInEditMode(false);
  };

  const updateSettings = (updatedSettings: DashboardWidgetSettings) => {
    if (!memoryDashboard.settings) return;

    let existingSettingsIndex = memoryDashboard.settings.findIndex(
      (s) => s.groupIdentifier === updatedSettings.groupIdentifier
    );
    if (existingSettingsIndex !== -1) {
      memoryDashboard.settings[existingSettingsIndex] = {
        ...memoryDashboard.settings[existingSettingsIndex],
        ...updatedSettings,
      };
    } else {
      memoryDashboard.settings.push(updatedSettings);
    }

    setMemoryDashboard({ ...memoryDashboard });
  };

  const getFullDashboardId = useCallback((item?: DashboardList) => {
    return getFullDashboardIdBase(item?.accountIdentifier, item?.id);
  }, []);

  const getFullDashboardIdBase = (accountIdentifier?: string, id?: number) => {
    return (accountIdentifier || "") + (id || 0).toString();
  };

  // TODO: if set as default button is rendered for standard dashboards, then change the code to select a default dashboard
  useEffect(() => {
    if (dashboardList.data && dashboardList.data.length <= 1) setIsLast(true);
    else setIsLast(false);
    if (!dashboardList.data || selectedDashboard?.id) return;

    let defaultDashboard = dashboardList.data.find((d) => d.isDefault);
    if (!defaultDashboard)
      defaultDashboard = dashboardList.data.find((d) => d.isPublic);

    if (!defaultDashboard && dashboardList.data.length > 0)
      defaultDashboard = dashboardList.data[0];

    if (defaultDashboard)
      setSelectedDashboard({
        ...selectedDashboard,
        accountIdentifier: defaultDashboard.accountIdentifier,
        description: defaultDashboard.description,
        name: defaultDashboard.name,
        id: defaultDashboard.id,
        isDefault: defaultDashboard.isDefault,
        isPublic: defaultDashboard.isPublic,
        fullId: getFullDashboardId(defaultDashboard),
      });
  }, [dashboardList, getFullDashboardId, selectedDashboard]);

  // ButtonGroup state
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleEditBtn = () => {
    if (isInEditMode) {
      setDbDashboard({ ...dbDashboard });
      setIsInAddWidgetMode(false);
      setIsInEditWidgetMode(false);
    }
    setIsInEditMode((prevState) => !prevState);
  };

  const cancelEdit = () => {
    if (isInEditMode) {
      setDbDashboard({ ...dbDashboard });
      setIsInAddWidgetMode(false);
      setIsInEditWidgetMode(false);
      setIsInEditMode(false);
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
    option: string
  ) => {
    setSelectedIndex(index);
    setOpen(false);

    const optionVal = option.toLowerCase();
    // Save
    if (optionVal === "save") saveDashboardEvent();
    // Save as
    else if (optionVal === "save as") setShowCreatePopup(true);
    // Add widget
    // else if (optionVal === "add widget") setIsInAddWidgetMode(true);
    // Delete Dashboard
    // else if (optionVal === "delete dashboard") {
    //   if (selectedDashboard.accountIdentifier && selectedDashboard.id) {
    //     deleteDashboard.mutate(
    //       {
    //         accountId: selectedDashboard.accountIdentifier,
    //         dashboardId: selectedDashboard.id,
    //         dashboardType,
    //       },
    //       {
    //         onSuccess: () =>
    //           setSelectedDashboard({ id: 0, accountIdentifier: "" }),
    //       }
    //     );
    //   }
    // }
    // Dashboard Properties
    // else if (optionVal === "dashboard properties") {
    //   setShowDashProperties(true);
    // }
  };

  const handleDeleteDashboard = () => {
    if (selectedDashboard.accountIdentifier && selectedDashboard.id) {
      if (dashboardList.data) {
        deleteDashboard.mutate(
          {
            accountId: selectedDashboard.accountIdentifier,
            dashboardId: selectedDashboard.id,
            dashboardType,
          },
          {
            onSuccess: () => {
              setSelectedDashboard({
                ...dashboard,
                id: 0,
              });
              setShowDeletePopup(false)
              setShowDashProperties(false);
            },
          }
        );
      }
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const computeIsDefault = () => {
    if (!dashboard.isSuccess || !dashboardList.isSuccess || dashboardList.data === undefined) return false;
    const currentSelectedId = dashboard.data.id;
    const dashboardObj = dashboardList.data.find(
      (dash) => dash.id === currentSelectedId
    );

    if (dashboardObj === undefined) return false;
    return dashboardObj.isDefault;
  };

  const SelectIcon = (props: any) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  return (
    <div
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isInAddWidgetMode || isInEditWidgetMode,
      })}
      id="dashboard-container"
    >
      <div className="action-bar">
        {isInAddWidgetMode && (
          <DashboardAddWidget
            dashboardType={dashboardType}
            isInAddWidgetMode={isInAddWidgetMode}
            closeDrawer={() => {
              setIsInAddWidgetMode(false);
            }}
            accountId={props.accountId}
          />
        )}

        {isInEditWidgetMode && editWidget.current && (
          <DashboardEditWidgetSettings
            dashboardType={dashboardType}
            isInEditWidgetMode={isInEditWidgetMode}
            widgetType={editWidget.current.widgetType}
            widgetCategory={editWidget.current.widgetCategory}
            widgetSettings={editWidget.current.widgetSettings}
            groupIdentifier={editWidget.current.groupIdentifier}
            updateSettings={updateSettings}
            closeDrawer={() => {
              editWidget.current = undefined;
              setIsInEditWidgetMode(false);
            }}
          />
        )}
      </div>
      <Box
        display={isInEditMode ? "none" : "block"}
        className="dashboardFilters"
      >
        {/* Filter compnents */}
        {props.filter}
        {/* Dashboard select */}
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "end"}}>
          <Grid item>
            <FormControl
              variant="outlined"
              size="small"
              className={"form-control form-control-dashboard"}
            >
              <Typography
                variant="body3"
                component="label"
                className={"input-label"}
              >{`Dashboard`}</Typography>
              <Select
                id="dashboardList"
                value={selectedDashboard?.fullId ?? ""}
                renderValue={(value) => {
                  return selectedDashboard.name;
                }}
                onChange={(event) => {
                  let selectedId = event.target.value as string;
                  cancelEdit();
                  if (!selectedId) {
                    setSelectedDashboard({
                      id: 0,
                    });
                    return;
                  }
                  if (selectedId === "settings") {
                    setShowDashProperties(true);
                    return;
                  }
                  if (selectedId === "create") {
                    setShowCreatePopup(true);
                    return;
                  }
                  let dashboard = dashboardList.data?.find(
                    (d) => getFullDashboardId(d) === selectedId
                  );
                  if (!dashboard) return;

                  setSelectedDashboard({
                    ...dashboard,
                    fullId: getFullDashboardId(dashboard),
                  });
                  setShowDashProperties(false);
                }}
                className={"input-field"}
                IconComponent={SelectIcon}
              >
                <ListSubheader style={{ paddingLeft: 8, fontSize: 16 }}>
                  Public Dashboards
                </ListSubheader>
                {dashboardList.data &&
                  dashboardList.data
                    .filter((d) => d.isPublic)
                    .map((dashboard: DashboardList) => {
                      let fullId = getFullDashboardId(dashboard);
                      return (
                        <MenuItem key={fullId} value={fullId}>
                          <Typography variant="body1" noWrap sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                            className={ classes.defaultItem}
                          >
                            {dashboard.name}
                            {dashboard.isDefault && <StarIcon />}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                <ListSubheader style={{ paddingLeft: 8, paddingRight: 0, fontSize: 16 }}>
                  My Saved Dashboards
                </ListSubheader>
                {dashboardList.data
                  ?.filter((d) => !d.isPublic)
                  .map((dashboard: DashboardList) => {
                    let fullId = getFullDashboardId(dashboard);
                    return (
                      <MenuItem key={fullId} value={fullId}>
                        <Typography variant="body1" noWrap sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                          className={ classes.defaultItem}
                        >
                          {dashboard.name}
                          {dashboard.isDefault && <StarIcon />}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                <Divider />
                <MenuItem key="settings" value="settings">
                  <Box mr={1} style={{ display: "flex" }}>
                    <UilSetting style={{ width: "20px", height: "20px" }} />
                  </Box>
                  Settings
                </MenuItem>
                <MenuItem key="create" value="create">
                  <Box mr={1} style={{ display: "flex" }}>
                    <AddCircleOutlineRoundedIcon style={{ width: "20px", height: "20px" }} />
                  </Box>
                  Create New
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={{display: "flex", justifyContent: "right", marginTop: "34px"}}>
          {selectedDashboard.id !== 0 &&
            dashboard.isFetched &&
            props.hasDataLoaded && (
              <React.Fragment>
                  {isInEditMode && (
                    <Grid container spacing={1}>
                    <Grid item sx={{display: "flex", alignItems: "center"}}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        className={"form-control form-control-dashboard"}
                      >
                        <Link component="button" variant="body2" underline="always" className={classes.linkButton} onClick={cancelEdit}>
                          Cancel
                        </Link>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={()=>setIsInAddWidgetMode(true)}
                        className={classes.whiteButton}
                        >
                        Add Widget
                      </Button>
                    </Grid>                 
                    <Grid item>                      
                      <ButtonGroup
                        variant="outlined"
                        ref={anchorRef}
                        aria-label="split button"
                      >
                        <Button
                          onClick={saveDashboardEvent}
                          className={classes.blueButton}
                          sx={{paddingRight: "0px !important"}}>
                          Save
                        </Button>
                        <Button
                          size="small"
                          aria-controls={open ? "split-button-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={handleToggle}
                          disabled={!isInEditMode}
                          className={classes.dropIconButton}
                        >
                          <ArrowDropDownIcon />
                        </Button>
                      </ButtonGroup>
                      <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        className={classes.splitButtonMenuContainer}
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom"
                                  ? "center top"
                                  : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                  {options.map((option, index) => {
                                    return (
                                      <MenuItem
                                        key={option}
                                        disabled={
                                          isInAddWidgetMode ||
                                          (index === 3 && !dashboard.data?.canDelete)
                                        }
                                        selected={index === selectedIndex}
                                        onClick={(event) =>
                                          handleMenuItemClick(event, index, option)
                                        }
                                      >
                                        {option}
                                      </MenuItem>
                                    );
                                  })}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </Grid>
                  </Grid>
                  )}
                  {!isInEditMode && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        startIcon={<EditIcon />}
                        onClick={()=>handleEditBtn()}
                        className={classes.blueButton}
                        >
                        Edit
                      </Button>
                    </Grid>
                  )}
              </React.Fragment>
            )}
          </Grid>
        </Box>
      </Box>

      <SizeMe>
        {({ size }) =>
          dashboardList.isLoading ||
          dashboard.isLoading ||
          !props.hasDataLoaded ? (
            <Box textAlign="center" m="auto" style={{ background: "none" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Responsive
              className="layout dashboard-grid-container"
              breakpoints={{ lg: 1200, md: 600, sm: 0 }}
              cols={{ lg: 12, md: 8, sm: 4 }}
              layouts={memoryDashboard.layout}
              rowHeight={100}
              isDroppable={true}
              onBreakpointChange={onBreakpointChange}
              onDrop={onDrop}
              onResizeStop={onResizeStop}
              onLayoutChange={onLayoutChange}
              onDragStop={onResizeStop}
              width={(size.width as number) || 1200}
            >
              {breakPoint ? generateDOM : <Fragment />}
            </Responsive>
          )
        }
      </SizeMe>

      {showCreatePopup && (
        <DashboardCreateModal
          handlePopupClose={() => {
            setShowCreatePopup(false);
            setIsInEditMode(false);
          }}
          handleSubmit={createDashboard}
        />
      )}
      {showDashProperties && (
        <DashboardPropertiesModal
          publicDash={dashboard.data?.isPublic}
          defaultDash={computeIsDefault()}
          handlePopupClose={() => {
            setShowDashProperties(false);
            setIsInEditMode(false);
          }}
          handleSubmit={onDashPropertiesSubmit}
          handleDeleteDashboard={()=>setShowDeletePopup(true)}
          disableButtons={
            setDashboardAsDefault.isLoading ||
            unsetDashboardAsPublic.isLoading ||
            setDashboardAsPublic.isLoading ||
            unsetDashboardAsPublic.isLoading
          }
          dashboardName={dashboard.data?.name}
          deleteDash={dashboard.data?.canDelete}
          isLast={isLast}
        />
      )}
      {showDeletePopup && (
        <ConfirmDialog
          title="Delete Dashboard?"
          open={showDeletePopup}
          setOpen={(open: boolean) => setShowDeletePopup(open)}
          onConfirm={handleDeleteDashboard}
        >
          This action will be permanent.
        </ConfirmDialog>
      )}
    </div>
  );
};

export default DashboardBase;
