import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useIsFetching } from "@tanstack/react-query";
import {
  Box,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  Typography,
  Button,
  Dialog,
  OutlinedInput,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  MenuItem,
  Select,
  DialogContentText,
} from "@mui/material";
import { UilMultiply } from "@iconscout/react-unicons";

import {
  IPOSelectList,
  ISoftwareAndSystemsService,
} from "../model/propertyOverviewModel";
import { useSaveSystems } from "../api/propertyOverviewService";
import { maxTextField, textErrorMessage } from "../util/errorTexts";
interface Props {
  handlePopupClose: (shouldRefreshData?: boolean) => void;
  showDialog: boolean;
  accountingSystemsSelectList: IPOSelectList[];
  investorRelationsSystemsSelectList: IPOSelectList[];
  leasingSystemsSelectList: IPOSelectList[];
  propertyManagementSystemsSelectList: IPOSelectList[];
  valuationSystemsSelectList: IPOSelectList[];
  tenantExperienceSystemsSelectList: IPOSelectList[];
  softwareAndSystems: ISoftwareAndSystemsService;
}

const SoftwareAndSystemsEdit: React.FC<Props> = (props) => {
  const {
    softwareAndSystems,
    accountingSystemsSelectList,
    investorRelationsSystemsSelectList,
    leasingSystemsSelectList,
    propertyManagementSystemsSelectList,
    valuationSystemsSelectList,
    tenantExperienceSystemsSelectList,
  } = props;

  const isFetching = useIsFetching();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<ISoftwareAndSystemsService>({
    defaultValues: {
      ...softwareAndSystems,
      accountingSystemId:
        softwareAndSystems.accountingSystemId?.toString() ?? "",
      propertyManagementSystemId:
        softwareAndSystems.propertyManagementSystemId?.toString() ?? "",
      leasingSystemId: softwareAndSystems.leasingSystemId?.toString() ?? "",
      valuationSystemId: softwareAndSystems.valuationSystemId?.toString() ?? "",
      investorRelationsSystemId:
        softwareAndSystems.investorRelationsSystemId?.toString() ?? "",
      tenantExperienceSystemId:
        softwareAndSystems.tenantExperienceSystemId?.toString() ?? "",
    },
  });

  const patchPropertySystems = useSaveSystems();

  const onSubmit = handleSubmit((model: ISoftwareAndSystemsService) => {
    if (!model.accountingSystemId) model.accountingSystemId = undefined;
    if (!model.propertyManagementSystemId)
      model.propertyManagementSystemId = undefined;
    if (!model.leasingSystemId) model.leasingSystemId = undefined;
    if (!model.valuationSystemId) model.valuationSystemId = undefined;
    if (!model.investorRelationsSystemId)
      model.investorRelationsSystemId = undefined;
    if (!model.tenantExperienceSystemId)
      model.tenantExperienceSystemId = undefined;

    if (!model.loanManagementSystem) model.loanManagementSystem = undefined;
    if (!model.buildingAccessSecuritySystem)
      model.buildingAccessSecuritySystem = undefined;
    if (!model.fireLifeSafetySystem) model.fireLifeSafetySystem = undefined;
    if (!model.complianceSystem) model.complianceSystem = undefined;
    if (!model.otherSystem) model.otherSystem = undefined;

    patchPropertySystems.mutate(model, {
      onSuccess: () => {
        props.handlePopupClose(true);
      },
    });
  });

  const SelectIcon = (props: Props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  return (
    <Dialog
      open={props.showDialog}
      aria-labelledby="software-and-systems-edit"
      aria-describedby="property-details-edit-description"
      fullWidth
    >
      <DialogTitle id="software-and-systems-edit">
        <Typography component="span" variant="h2" color="primary.dark">
          Edit Software &amp; Systems
        </Typography>
        <IconButton
          aria-label="close"
          disabled={patchPropertySystems.isLoading}
          onClick={() => props.handlePopupClose(false)}
          className="icon-dialog-close"
          size="large"
        >
          <UilMultiply />
        </IconButton>
        <Typography component="div" variant="h3" color="neutral700.light">
          Please select or enter values for the fields and click the save button to update software and systems information.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <React.Fragment>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={1}>
              {/* Accounting System */}
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name={"accountingSystemId"}
                  render={({ field, fieldState }) => (
                    <FormControl className={"form-control"}>
                      <Typography variant="body3" component="label">
                        Accounting
                      </Typography>
                      <Select
                        labelId="accounting-system"
                        displayEmpty
                        input={<OutlinedInput />}
                        className={"form-control-field"}
                        error={Boolean(errors?.accountingSystemId)}
                        IconComponent={SelectIcon}
                        {...field}
                      >
                        {accountingSystemsSelectList.map((accountingSystem) => (
                          <MenuItem
                            key={accountingSystem.key}
                            value={accountingSystem.key}
                          >
                            {accountingSystem.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              {/* Work Orders/Property Management */}
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name={"propertyManagementSystemId"}
                  render={({ field, fieldState }) => (
                    <FormControl className={"form-control"}>
                      <Typography variant="body3" component="label">
                        Work Orders/Property Management
                      </Typography>
                      <Select
                        labelId="property-management"
                        displayEmpty
                        input={<OutlinedInput />}
                        className={"form-control-field"}
                        error={Boolean(errors?.propertyManagementSystemId)}
                        IconComponent={SelectIcon}
                        {...field}
                      >
                        {propertyManagementSystemsSelectList.map(
                          (propertySystem) => (
                            <MenuItem
                              key={propertySystem.key}
                              value={propertySystem.key}
                            >
                              {propertySystem.value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              {/* Leasing */}
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name={"leasingSystemId"}
                  render={({ field, fieldState }) => (
                    <FormControl className={"form-control"}>
                      <Typography variant="body3" component="label">
                        Leasing
                      </Typography>
                      <Select
                        labelId="leasing-system"
                        displayEmpty
                        input={<OutlinedInput />}
                        className={"form-control-field"}
                        error={Boolean(errors?.leasingSystemId)}
                        IconComponent={SelectIcon}
                        {...field}
                      >
                        {leasingSystemsSelectList.map((leasingSystem) => (
                          <MenuItem
                            key={leasingSystem.key}
                            value={leasingSystem.key}
                          >
                            {leasingSystem.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              {/* Valuation */}
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name={"valuationSystemId"}
                  render={({ field, fieldState }) => (
                    <FormControl className={"form-control"}>
                      <Typography variant="body3" component="label">
                        Valuation
                      </Typography>
                      <Select
                        labelId="valuation-system"
                        displayEmpty
                        input={<OutlinedInput />}
                        className={"form-control-field"}
                        error={Boolean(errors?.valuationSystemId)}
                        IconComponent={SelectIcon}
                        {...field}
                      >
                        {valuationSystemsSelectList.map((valuationSystem) => (
                          <MenuItem
                            key={valuationSystem.key}
                            value={valuationSystem.key}
                          >
                            {valuationSystem.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              {/* Investor Relations*/}
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name={"investorRelationsSystemId"}
                  render={({ field, fieldState }) => (
                    <FormControl className={"form-control"}>
                      <Typography variant="body3" component="label">
                        Investor Relations
                      </Typography>
                      <Select
                        labelId="investor-relations"
                        displayEmpty
                        input={<OutlinedInput />}
                        className={"form-control-field"}
                        error={Boolean(errors?.investorRelationsSystemId)}
                        IconComponent={SelectIcon}
                        {...field}
                      >
                        {investorRelationsSystemsSelectList.map(
                          (investorSystem) => (
                            <MenuItem
                              key={investorSystem.key}
                              value={investorSystem.key}
                            >
                              {investorSystem.value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              {/* Tenant Experiences */}
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name={"tenantExperienceSystemId"}
                  render={({ field, fieldState }) => (
                    <FormControl className={"form-control"}>
                      <Typography variant="body3" component="label">
                        Tenant Experiences
                      </Typography>
                      <Select
                        labelId="tenant-experience"
                        displayEmpty
                        input={<OutlinedInput />}
                        className={"form-control-field"}
                        error={Boolean(errors?.tenantExperienceSystemId)}
                        IconComponent={SelectIcon}
                        {...field}
                      >
                        {tenantExperienceSystemsSelectList.map(
                          (tenantExperienceSystem) => (
                            <MenuItem
                              key={tenantExperienceSystem.key}
                              value={tenantExperienceSystem.key}
                            >
                              {tenantExperienceSystem.value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              {/* Loan Management */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Loan Management
                  </Typography>
                  <TextField
                    size="small"
                    id="loan-management-system"
                    variant="outlined"
                    {...register("loanManagementSystem", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.loanManagementSystem)}
                    helperText={
                      Boolean(errors?.loanManagementSystem)
                        ? errors?.loanManagementSystem?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Building Access & Security */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Building Access & Security
                  </Typography>
                  <TextField
                    size="small"
                    id="building-access-security-system"
                    variant="outlined"
                    {...register("buildingAccessSecuritySystem", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.buildingAccessSecuritySystem)}
                    helperText={
                      Boolean(errors?.buildingAccessSecuritySystem)
                        ? errors?.buildingAccessSecuritySystem?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Fire & Life Safety */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Fire & Life Safety
                  </Typography>
                  <TextField
                    size="small"
                    id="fire-life-safety-system"
                    variant="outlined"
                    {...register("fireLifeSafetySystem", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.fireLifeSafetySystem)}
                    helperText={
                      Boolean(errors?.fireLifeSafetySystem)
                        ? errors?.fireLifeSafetySystem?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Compliance */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Compliance
                  </Typography>
                  <TextField
                    size="small"
                    id="compliance-system"
                    variant="outlined"
                    {...register("complianceSystem", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.complianceSystem)}
                    helperText={
                      Boolean(errors?.complianceSystem)
                        ? errors?.complianceSystem?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Other */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Other
                  </Typography>
                  <TextField
                    size="small"
                    id="other-system"
                    variant="outlined"
                    {...register("otherSystem", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.otherSystem)}
                    helperText={
                      Boolean(errors?.otherSystem)
                        ? errors?.otherSystem?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </React.Fragment>
        {patchPropertySystems.isLoading && (
          <Box m="auto">
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          disabled={patchPropertySystems.isLoading}
          onClick={() => props.handlePopupClose(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={patchPropertySystems.isLoading || isFetching > 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SoftwareAndSystemsEdit;
