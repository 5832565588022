import { dateValueFormatter, numberFormatter } from "./gridUtils";

export function getTerm(leaseTo: any, leaseTerm: number): string {
    if (dateValueFormatter(leaseTo) === "N/A") {
      return "N/A";
    } else {
      return numberFormatter(leaseTerm, 0);
    }
  }

  export function getTermValue(leaseTo: any, leaseTerm: number): string | number {
    if (dateValueFormatter(leaseTo) === "N/A") {
      return "N/A";
    } else {
      return numberFormatter(leaseTerm, 0);
    }
  }