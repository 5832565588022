import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  CircularProgress,
  Typography,
  Grid,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useIsFetching } from "@tanstack/react-query";

import { SSelectList } from "../model/userModel";
import { EAccountPermission, TEditUserModel } from "../model/editUserModel";
import { useGetUser, useUpdateUser } from "../api/userService";
import UserContext from "../util/userContext";
import { UilMultiply } from "@iconscout/react-unicons";

interface EditUserModalProps {
  userId: string;
  openDialog: boolean;
  closeDialog: () => void;
}

const EditUserModal: React.FC<EditUserModalProps> = (props) => {
  const { userId, openDialog, closeDialog } = props;

  const [rolesChecked, setRolesChecked] = useState<string[]>([]);
  const [isAdminChecked, setIsAdminChecked] = useState(false);

  const rolesSet = React.useRef(false);

  const isFetching = useIsFetching();

  const { userAccountDDValue } = useContext(UserContext);
  const { handleSubmit, reset } = useForm<TEditUserModel>();

  const {
    data: user,
    isSuccess,
    isLoading,
  } = useGetUser({
    accountIdentifier: userAccountDDValue,
    userId: userId,
  });

  const editUserMutate = useUpdateUser(userId);

  const sortedSelectList = React.useMemo(() => {
    if (!user || !isSuccess) return [];

    const selectList: SSelectList[] = [...user.rolesSelectList];
    const optionsOrder = [
      EAccountPermission.ADMINISTRATION.toString(),
      EAccountPermission.ADMINISTER_USERS.toString(),
      EAccountPermission.CONFIGURE_WIDGETS.toString(),
      EAccountPermission.CONFIGURE_PORTFOLIOS.toString(),
      EAccountPermission.CONFIGURE_LOANS.toString(),
      EAccountPermission.DOCUMENTS_UPLOAD_AND_DELETE.toString(),
      EAccountPermission.PROPERTY_EDIT.toString(),
    ];
    selectList.sort((optionA, optionB) => {
      return (
        optionsOrder.indexOf(optionA.name) - optionsOrder.indexOf(optionB.name)
      );
    });

    return selectList;
  }, [user, isSuccess]);

  useEffect(() => {
    if (user !== undefined && isSuccess && rolesSet.current === false) {
      reset({
        roles: user.roles,
      });
      setRolesChecked(user.roles);

      // Checking if admin is checked from backend.
      let adminChecked = false;
      const adminRole = user.rolesSelectList.find(
        (roleOption) => roleOption.name.toLowerCase() === "admin"
      );
      if (adminRole !== undefined) {
        for (const roleId of user.roles) {
          if (roleId === adminRole.id) {
            adminChecked = true;
            break;
          }
        }
      }
      if (isAdminChecked !== adminChecked) setIsAdminChecked(adminChecked);

      rolesSet.current = true;
    }
  }, [user, isSuccess, isAdminChecked, reset]);

  const handleRoles = (roleId: string, isAdmin: boolean) => () => {
    const newChecked: string[] = [];

    let shouldSetAdminChecked = false;

    if (user !== undefined) {
      const adminRoleIndex = user.rolesSelectList.findIndex(
        (role) => role.name.toLowerCase() === "admin"
      );
      const adminId = user.rolesSelectList[adminRoleIndex].id;

      if (isAdmin) {
        if (!rolesChecked.includes(adminId)) {
          shouldSetAdminChecked = true;
          newChecked.push(adminId);
        }
      } else {
        const currentIndex = rolesChecked.indexOf(roleId);
        if (currentIndex === -1) {
          rolesChecked.forEach((role) => {
            newChecked.push(role);
          });
          newChecked.push(roleId);
        } else {
          const newRoles = rolesChecked.filter((oldRole) => oldRole !== roleId);
          newRoles.forEach((newRole) => {
            newChecked.push(newRole);
          });
        }
      }
    }

    setRolesChecked(newChecked);

    if (shouldSetAdminChecked !== isAdminChecked)
      setIsAdminChecked(shouldSetAdminChecked);
  };

  const onSubmit = handleSubmit((model: TEditUserModel) => {
    model.roles = rolesChecked;
    model.userId = user?.id || "";
    model.accountIdentifier = userAccountDDValue;
    editUserMutate.mutate(model, {
      onSuccess: () => closeDialog(),
    });
  });

  return (
    <React.Fragment>
      <Dialog
        open={openDialog}
        onClose={() => closeDialog()}
        aria-labelledby="edit-user"
        aria-describedby="edit-user-description"
      >
        <DialogTitle id="edit-user">
          <Typography component="span" variant="h2" color="primary.dark">
            Edit User Permissions
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => closeDialog()}
            className="icon-dialog-close"
            size="large"
          >
            <UilMultiply />
          </IconButton>
          <Typography component="div" variant="h3" color="neutral700.light">
            To edit a user’s permissions, please select from the permissions below:
          </Typography>
        </DialogTitle>
        <DialogContent>
          {isFetching > 0 || isLoading ? (
            <Box m="auto">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {user !== undefined && isSuccess && (
                <React.Fragment>
                  <Grid container>
                    {/* Roles */}
                    <Grid item xs={12}>
                      <List
                        // subheader={
                        //   <Typography variant="body3" component="label">
                        //     Permissions
                        //   </Typography>
                        // }
                      >
                        {sortedSelectList.map((role: SSelectList) => {
                          const labelId = `checkbox-list-label-${role.name}`;
                          let roleName = "";
                          let isAdmin = false;

                          switch (role.name.toLowerCase()) {
                            case EAccountPermission.ADMINISTRATION.toLowerCase():
                              roleName = "Administration";
                              isAdmin = true;
                              break;
                            case EAccountPermission.CONFIGURE_LOANS.toLowerCase():
                              roleName = "Configure Loans";
                              break;
                            case EAccountPermission.CONFIGURE_PORTFOLIOS.toLowerCase():
                              roleName = "Configure Portfolios";
                              break;
                            case EAccountPermission.DOCUMENTS_UPLOAD_AND_DELETE.toLowerCase():
                              roleName = "Document Upload and Delete";
                              break;
                            case EAccountPermission.ADMINISTER_USERS.toLowerCase():
                              roleName = "Administer Users";
                              break;
                            case EAccountPermission.CONFIGURE_WIDGETS.toLowerCase():
                              roleName = "Configure Widgets";
                              break;
                            case EAccountPermission.PROPERTY_EDIT.toLowerCase():
                              roleName = "Property Edit";
                              break;
                            default:
                              roleName = role.name;
                              break;
                          }

                          return (
                            <ListItem
                              key={role.id}
                              role={undefined}
                              dense
                              button
                              onClick={handleRoles(role.id, isAdmin)}
                              disabled={!isAdmin && isAdminChecked}
                              className="listitems-checklist"
                            >
                              <ListItemIcon sx={{width: "25px", height: "25px"}}>
                                <Checkbox
                                  edge="start"
                                  checked={rolesChecked.indexOf(role.id) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText id={labelId} primary={roleName} />
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => closeDialog()}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onSubmit}
            disabled={editUserMutate.isLoading || isLoading}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default EditUserModal;
