import React from "react";
import {
  GridReadyEvent,
  ICellRendererParams,
  StatusPanelDef,
} from "ag-grid-community";
import { Box, Tab, Tabs } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material";
import { getStatusPanels } from "../../leasing activity/util/gridTable";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { getOpenTableColumns } from "../util/tenantDetailTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const mainTopPropertyInfoWidth = 400;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainTopTabsContainer: {},
    mainTopTabs: {
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        marginTop: -48,
        width: `calc(100% - ${mainTopPropertyInfoWidth}px)`,
        "& > div > div > a:first-child > span": {
          paddingLeft: 10,
        },
        "& a": {
          fontWeight: "bold",
        },
      },
    },
    mainTopTabPanel: {
      width: "100%",
      height: "100%",
      backgroundColor: "white",
      borderTop: "1px solid #ccc",
      [theme.breakpoints.up("lg")]: {
        width: "100%",
      },
    },
  })
);

const TenantDetailTable: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const rowId = props.node.id + "";

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const statusBar: { statusPanels: StatusPanelDef[] } = getStatusPanels();

  const defaultColDef = {
    minWidth: 150,
    filter: true,
    resizable: false,
    filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
  };

  const autoSizeAll = () => {
    let detailGrid = props.api.getDetailGridInfo(rowId);
    detailGrid?.columnApi?.autoSizeAllColumns();
  };

  const onGridReadyReceipts = (params: GridReadyEvent) => {
    const gridInfo = {
      id: props.node.id + "",
      parent: props.node.parent?.data,
      api: params.api,
      columnApi: params.columnApi,
    };

    props.api.addDetailGridInfo(rowId, gridInfo);
  };

  function onFirstDataRendered(params: any) {
    autoSizeAll();
  }

  const colDefs = getOpenTableColumns();
  return (
    <div className={classes.mainTopTabsContainer}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Generations Navigation Tabs"
      >
        <Tab label="Open Receipts" className={"tab-nested-grid"} />
        <Tab label="Open Charges" className={"tab-nested-grid"} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <div
          style={{
            height: "250px",
            width: "100%",
          }}
          className={"ag-theme-alpine, ag-theme-openreceipts-table"}
        >
          {/* OPEN RECEIPTS */}
          <AgGridReact
            autoGroupColumnDef={{
              minWidth: 150,
            }}
            statusBar={statusBar}
            enableRangeSelection={true}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            rowData={props.data.receipts}
            onGridReady={onGridReadyReceipts}
            onFirstDataRendered={onFirstDataRendered}
            getRowId={function (params) {
              return "detail_" + params.data.code + "_open_receipt";
            }}
            excelStyles={[
              {
                id: "dateFormat",
                dataType: "DateTime",
                numberFormat: { format: "mm/dd/yyyy;@" },
              },
            ]}
          />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div
          style={{
            height: "250px",
            width: "100%",
          }}
          className={"ag-theme-alpine, ag-theme-opencharges-table"}
        >
          {/* OPEN CHARGES */}
          <AgGridReact
            autoGroupColumnDef={{
              minWidth: 150,
            }}
            statusBar={statusBar}
            enableRangeSelection={true}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            rowData={props.data.charges}
            onFirstDataRendered={onFirstDataRendered}
            getRowId={function (params) {
              return "detail_" + params.data.code + "_open_charge";
            }}
            excelStyles={[
              {
                id: "dateFormat",
                dataType: "DateTime",
                numberFormat: { format: "mm/dd/yyyy;@" },
              },
            ]}
          />
        </div>
      </TabPanel>
    </div>
  );
};

export default TenantDetailTable;
