import {
  useAuthorizedApiAxios
} from "../../../shared/api/authorizedAxios";
import { useMutation } from "@tanstack/react-query";
import { ZendeskPageType } from './../model/zendeskPageType';

export const UseGetZendeskPageUrl = () => {
    const authorizedApiAxios = useAuthorizedApiAxios();
  
    return useMutation(async (zendeskPageType: ZendeskPageType) => {
      let url: string = `/IdentityService/v1/Token/ZendeskSsoUrl?1=1`;
      if(zendeskPageType) url += `&ZendeskPageType=${zendeskPageType}`;

      const result = await authorizedApiAxios().get<string>(url);
  
      return result.data;
    });
};