import React, { useState, useCallback } from "react";
import { Box, Button, CircularProgress } from "@mui/material";

import AlertsGrid from "./AlertsGrid";
import { useGetAccounts } from "../../../properties/property/api/accountService";
import { useGetAlerts } from "../../api/alertsService";
import CreateAlertDialog from "./CreateAlertDialog";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";

const Alerts: React.FC = () => {
  const { data: accounts, isSuccess } = useGetAccounts();
  const accountId = React.useMemo(() => {
    if (!isSuccess || !accounts || accounts.length === 0) return "";
    return accounts[0].identifier;
  }, [accounts, isSuccess]);

  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  const alertsQuery = useGetAlerts(accountId);
  const queryClient = useQueryClient();
  const isLoading = useIsFetching();

  const onCreateAlertDialogClose = (shouldRefresh: boolean) => {
    refreshGridData(shouldRefresh);
    setOpenAlertDialog(false);
  };

  const refreshGridData = useCallback(
    (shouldRefresh: boolean) => {
      if (shouldRefresh) {
        queryClient.invalidateQueries(["getArAlerts", accountId]);
      }
    },
    [accountId, queryClient]
  );

  return (
    <React.Fragment>
      {/* Create Alert Dialog */}
      {alertsQuery.isSuccess &&
        alertsQuery.data &&
        accountId.trim().length > 0 &&
        openAlertDialog && (
          <CreateAlertDialog
            handleClose={onCreateAlertDialogClose}
            accountId={accountId}
            editMode={false}
            frequencyList={alertsQuery.data.frequencySelectList}
          />
        )}
      {/* Create ar alert button */}
      <Box
        display="flex"
        flexDirection="row-reverse"
        sx={{ padding: "24px 20px 16px 20px" }}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={isLoading > 0}
          onClick={() => setOpenAlertDialog(true)}
        >
          Create A/R Alert
        </Button>
      </Box>
      {/* Alerts grid */}
      {alertsQuery.isLoading && (
        <Box m="auto">
          <CircularProgress />
        </Box>
      )}
      {alertsQuery.isSuccess && (
        <AlertsGrid
          rows={alertsQuery.data.arAlertRules}
          accountId={accountId}
          frequencyList={alertsQuery.data.frequencySelectList}
          handleClose={refreshGridData}
        />
      )}
    </React.Fragment>
  );
};

export default Alerts;
