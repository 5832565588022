import { AccountTypesOption } from "../api/accountTypes";
import { BusinessUnitsOption } from "../api/businessUnits";
import { GLTreeOption } from "../api/glTree";
import { FinancialDropdownOption } from "../views/FinancialsDropdown";

export const transformBusinessOptions = (businessUnitOptions: BusinessUnitsOption[]) => {
    const dropdownOptions: FinancialDropdownOption[] = [];
    dropdownOptions.push({
        id: "",
        name: "All Business Units"
    })

    businessUnitOptions.forEach(businessOption => {
        dropdownOptions.push({
            id: businessOption.id,
            name: businessOption.name
        });
    });

    return dropdownOptions;
};

export const transformGLTreeOptions = (glTreeOptions: GLTreeOption[]) => {
    const dropdownOptions: FinancialDropdownOption[] = [];

    glTreeOptions.forEach(glTreeOption => {
        dropdownOptions.push({
            id: glTreeOption.id,
            name: glTreeOption.name,
            typeid: glTreeOption.typeId,
            typename: glTreeOption.typeName,
        });
    });

    return dropdownOptions;
};

export const transformAccountOptions = (accountOptions: AccountTypesOption[]) => {
    const dropdownOptions: FinancialDropdownOption[] = [];

    accountOptions.forEach(accountOption => {
        dropdownOptions.push({
            id: accountOption.id.toString(),
            name: accountOption.name
        });
    });

    return dropdownOptions;
};