import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

import {
  GetGridConfigParams,
  GridConfig,
  PatchGridConfigParams,
  PostGridConfigParams,
  PutGridConfigParams,
} from "../model/gridConfig";
import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "./authorizedAxios";

export const useGetGridConfig = (gridConfigParams: GetGridConfigParams) => {
  const { pageId, gridIdentifier, accountId } = gridConfigParams;
  const uniqueId = pageId + gridIdentifier + accountId;

  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getGridConfig", uniqueId],
    () => {
      const source = axios.CancelToken.source();
      const url = `/assetService/v1/pageGridConfiguration/${pageId}/gridIdentifier/${gridIdentifier}/configurations`;

      const promise = authorizedApiAxios().get<GridConfig>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: accountId,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      select: (response) => response.data,
      enabled: accountId.trim().length > 0,
    }
  );
};

export const usePostGridConfig = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((gridConfig: PostGridConfigParams) => {
    const source = axios.CancelToken.source();

    const url = `assetService/v1/pageGridConfiguration/${gridConfig.pageId}/gridIdentifier/${gridConfig.gridIdentifier}/configurations`;
    let promise = authorizedApiAxios().post(url, gridConfig.gridConfig, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: gridConfig.accountId,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const usePutGridConfig = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((gridConfig: PutGridConfigParams) => {
    const source = axios.CancelToken.source();

    const url = `assetService/v1/pageGridConfiguration/${gridConfig.pageId}/gridIdentifier/${gridConfig.gridIdentifier}/configurations/${gridConfig.pageGridConfigId}`;
    let promise = authorizedApiAxios().put(url, gridConfig.gridConfig, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: gridConfig.accountId,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useDeleteGridConfig = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((gridConfig: PatchGridConfigParams) => {
    const source = axios.CancelToken.source();
    const url = `assetService/v1/pageGridConfiguration/${gridConfig.pageId}/gridIdentifier/${gridConfig.gridIdentifier}/configurations/${gridConfig.pageGridConfigurationId}`;
    let promise = authorizedApiAxios().delete(url, {
      cancelToken: source.token,
      headers: { [AccountIdentifierHeaderName]: gridConfig.accountId },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};
