import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIsFetching } from "@tanstack/react-query";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  Typography,
  Button,
  Dialog,
  OutlinedInput,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  DialogContentText,
  IconButton,
} from "@mui/material";
import { UilCalender } from "@iconscout/react-unicons";
import { UilMultiply } from "@iconscout/react-unicons";

import { IPropertyDetailsService } from "../model/propertyOverviewModel";
import { useSavePropertyDetails } from "../api/propertyOverviewService";
import { cleanupPropertyDetails } from "../util/cleanUpProperties";
import {
  numberErrorMessage,
  maxNumberField,
  maxTextField,
  textErrorMessage,
} from "../util/errorTexts";

const yesNoSelectList = [
  {
    key: -1,
    value: "Select option",
  },
  {
    key: 1,
    value: "Yes",
  },
  {
    key: 0,
    value: "No",
  },
];

interface Props {
  handlePopupClose: (shouldRefreshData?: boolean) => void;
  showDialog: boolean;
  propertyDetails: IPropertyDetailsService;
}

const PropertyDetailsEdit: React.FC<Props> = (props) => {
  const { propertyDetails } = props;

  const [sprinklered, setSprinklered] = useState<number>(
    propertyDetails.isSprinklered === undefined
      ? -1
      : propertyDetails.isSprinklered === true
      ? 1
      : 0
  );
  const [isPressurized, setIsPressurized] = useState<number>(
    propertyDetails.hasPressurizedStairwells === undefined
      ? -1
      : propertyDetails.hasPressurizedStairwells === true
      ? 1
      : 0
  );
  const isFetching = useIsFetching();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<IPropertyDetailsService>({
    defaultValues: {
      lastRemeasureDate:
        propertyDetails.lastRemeasureDate === undefined
          ? null
          : propertyDetails.lastRemeasureDate,
      deckToDeckHeight: propertyDetails.deckToDeckHeight,
      floorToGridHeight: propertyDetails.floorToGridHeight,
      roofType: propertyDetails.roofType,
      roofRepairs: propertyDetails.roofRepairs,
      roofReplacementDate:
        propertyDetails.roofReplacementDate === undefined
          ? null
          : propertyDetails.roofReplacementDate,
      elevatorPermitDate:
        propertyDetails.elevatorPermitDate === undefined
          ? null
          : propertyDetails.elevatorPermitDate,
      //   isSprinklered: propertyDetails.isSprinklered,
      year5SprinklerInspectionDate:
        propertyDetails.year5SprinklerInspectionDate === undefined
          ? null
          : propertyDetails.year5SprinklerInspectionDate,
      year3SprinklerInspectionDate:
        propertyDetails.year3SprinklerInspectionDate === undefined
          ? null
          : propertyDetails.year3SprinklerInspectionDate,
      //   hasPressurizedStairwells: propertyDetails.hasPressurizedStairwells,
      floorType: propertyDetails.floorType,
      buildingStandardPaint: propertyDetails.buildingStandardPaint,
      buildingStandardCarpet: propertyDetails.buildingStandardCarpet,
      commonAreaPaint: propertyDetails.commonAreaPaint,
      commonAreaCarpet: propertyDetails.commonAreaCarpet,
      parkingType: propertyDetails.parkingType,
      parkingStallCount: propertyDetails.parkingStallCount,
      parkingRatio: propertyDetails.parkingRatio,
      parkingRates: propertyDetails.parkingRates,
      providerTelecom: propertyDetails.providerTelecom,
      providerFiber: propertyDetails.providerFiber,
      utilityWaterCompany: propertyDetails.utilityWaterCompany,
      utilityElectricCompany: propertyDetails.utilityElectricCompany,
      utilityGasCompany: propertyDetails.utilityGasCompany,
      buildingHours: propertyDetails.buildingHours,
      hvacHours: propertyDetails.hvacHours,
      hvacOvertimeRate: propertyDetails.hvacOvertimeRate,
      lastRenovation: propertyDetails.lastRenovation,
      lastRenovationDate:
        propertyDetails.lastRenovationDate === undefined
          ? null
          : propertyDetails.lastRenovationDate,
    },
  });

  const patchPropertyDetails = useSavePropertyDetails();

  const onSubmit = handleSubmit((model: IPropertyDetailsService) => {
    model.accountId = propertyDetails.accountId;
    model.propertyId = propertyDetails.propertyId;

    // manual fields
    if (sprinklered !== -1) model.isSprinklered = Boolean(model.isSprinklered);
    if (isPressurized !== -1)
      model.hasPressurizedStairwells = Boolean(model.hasPressurizedStairwells);
    cleanupPropertyDetails(model);
    patchPropertyDetails.mutate(model, {
      onSuccess: () => {
        props.handlePopupClose(true);
      },
    });
  });

  const [isLastRemeasureDate, setIsLastRemeasureDate] = React.useState(null);
  const [isRoofReplacementDate, setIsRoofReplacementDate] = React.useState(null);
  const [isElevatorPermitDate, setIsElevatorPermitDate] = React.useState(null);
  const [isYear5SprinklerInspectionDate, setIsYear5SprinklerInspectionDate] = React.useState(null);
  const [isYear3SprinklerInspectionDate, setIsYear3SprinklerInspectionDate] = React.useState(null);
  const [isLastRenovationDate, setIsLastRenovationDate] = React.useState(null);
  const invalidDateString = "Please enter a valid date";
  
  const onError = (error: any, item: string) => {
    if (item === "lastRemeasureDate") setIsLastRemeasureDate(error);
    else if (item === "roofReplacementDate") setIsRoofReplacementDate(error);
    else if (item === "elevatorPermitDate") setIsElevatorPermitDate(error);
    else if (item === "year5SprinklerInspectionDate") setIsYear5SprinklerInspectionDate(error);
    else if (item === "year3SprinklerInspectionDate") setIsYear3SprinklerInspectionDate(error);
    else if (item === "lastRenovationDate") setIsLastRenovationDate(error);
  };

  const isDisable = () => {
    if (
      patchPropertyDetails.isLoading ||
      isFetching > 0 ||
      isLastRemeasureDate !== null ||
      isRoofReplacementDate !== null ||
      isElevatorPermitDate !== null ||
      isYear5SprinklerInspectionDate !== null ||
      isYear3SprinklerInspectionDate !== null ||
      isLastRenovationDate !== null
    ) return true;
    else return false;
  };

  const SelectIcon = (props: Props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  return (
    <Dialog
      open={props.showDialog}
      aria-labelledby="property-details-edit"
      aria-describedby="property-details-edit-description"
      fullWidth
    >
      <DialogTitle id="property-details-edit">
        <Typography component="span" variant="h2" color="primary.dark">
          Edit Property Details
        </Typography>
        <IconButton
          aria-label="close"
          disabled={patchPropertyDetails.isLoading}
          onClick={() => props.handlePopupClose(false)}
          className="icon-dialog-close"
          size="large"
        >
          <UilMultiply />
        </IconButton>
        <Typography component="div" variant="h3" color="neutral700.light">
          Please select or enter values for the fields and click the save button to update property details.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <React.Fragment>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={1}>
              {/* Last Remeasure Date */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography
                    variant="body3"
                    component="label"
                  >{`Last Remeasure Date`}</Typography>
                  <Controller
                    name="lastRemeasureDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        inputRef={field.ref}
                        onChange={field.onChange}
                        value={field.value}
                        components={{
                          OpenPickerIcon: UilCalender,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // error={Boolean(errors?.lastRemeasureDate)}
                            helperText={
                              isLastRemeasureDate !== null
                                ? invalidDateString
                                : null
                            }
                            className={
                              "form-control-field form-control-datepicker"
                            }
                          />
                        )}
                        onError={(error)=>onError(error, "lastRemeasureDate")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              {/* Deck to deck height */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Deck to Deck Height (ft)
                  </Typography>
                  <TextField
                    size="small"
                    id="deck-to-deck-height"
                    variant="outlined"
                    type="number"
                    {...register("deckToDeckHeight", {
                      valueAsNumber: true,
                      min: {
                        value: 0,
                        message: numberErrorMessage,
                      },
                      max: {
                        value: maxNumberField,
                        message: numberErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.deckToDeckHeight)}
                    helperText={
                      Boolean(errors?.deckToDeckHeight)
                        ? errors?.deckToDeckHeight?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Floor to grid height */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Floor to Grid Height (ft)
                  </Typography>
                  <TextField
                    size="small"
                    id="floor-to-grid-height"
                    variant="outlined"
                    type="number"
                    {...register("floorToGridHeight", {
                      valueAsNumber: true,
                      min: {
                        value: 0,
                        message: numberErrorMessage,
                      },
                      max: {
                        value: maxNumberField,
                        message: numberErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.floorToGridHeight)}
                    helperText={
                      Boolean(errors?.floorToGridHeight)
                        ? errors?.floorToGridHeight?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Roof type */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Roof Type
                  </Typography>
                  <TextField
                    size="small"
                    id="roof-type"
                    variant="outlined"
                    {...register("roofType", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.roofType)}
                    helperText={
                      Boolean(errors?.roofType) ? errors?.roofType?.message : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Roof repairs */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Roof Repairs
                  </Typography>
                  <TextField
                    size="small"
                    id="roof-repairs"
                    variant="outlined"
                    {...register("roofRepairs", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.roofRepairs)}
                    helperText={
                      Boolean(errors?.roofRepairs)
                        ? errors?.roofRepairs?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Roof replacement Date */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography
                    variant="body3"
                    component="label"
                  >{`Roof Replacement Date`}</Typography>
                  <Controller
                    name="roofReplacementDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        inputRef={field.ref}
                        views={["year", "month", "day"]}
                        onChange={field.onChange}
                        value={field.value}
                        components={{
                          OpenPickerIcon: UilCalender,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // error={Boolean(errors?.roofReplacementDate)}
                            helperText={
                              isRoofReplacementDate !== null
                                ? invalidDateString
                                : null
                            }
                            className={
                              "form-control-field form-control-datepicker"
                            }
                          />
                        )}
                        onError={(error)=>onError(error, "roofReplacementDate")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              {/* Elevator Permit Date */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography
                    variant="body3"
                    component="label"
                  >{`Elevator Permit Date`}</Typography>
                  <Controller
                    name="elevatorPermitDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        inputRef={field.ref}
                        views={["year", "month", "day"]}
                        onChange={field.onChange}
                        value={field.value}
                        components={{
                          OpenPickerIcon: UilCalender,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // error={Boolean(errors?.elevatorPermitDate)}
                            helperText={
                              isElevatorPermitDate !== null
                                ? invalidDateString
                                : null
                            }
                            className={
                              "form-control-field form-control-datepicker"
                            }
                          />
                        )}
                        onError={(error)=>onError(error, "elevatorPermitDate")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              {/* Sprinklered */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Sprinklered
                  </Typography>
                  <Select
                    labelId="sprinklered"
                    displayEmpty
                    input={<OutlinedInput />}
                    className={"form-control-field"}
                    value={sprinklered}
                    onChange={(event) =>
                      setSprinklered(Number(event.target.value))
                    }
                    IconComponent={SelectIcon}
                  >
                    {yesNoSelectList.map((yesNoSelectItem) => (
                      <MenuItem
                        key={yesNoSelectItem.key}
                        value={yesNoSelectItem.key}
                      >
                        {yesNoSelectItem.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* 5-Year Sprinkler Inspection Date */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography
                    variant="body3"
                    component="label"
                  >{`5-Year Sprinkler Inspection Date`}</Typography>
                  <Controller
                    name="year5SprinklerInspectionDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        inputRef={field.ref}
                        views={["year", "month", "day"]}
                        onChange={field.onChange}
                        value={field.value}
                        components={{
                          OpenPickerIcon: UilCalender,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // error={Boolean(errors?.year5SprinklerInspectionDate)}
                            helperText={
                              isYear5SprinklerInspectionDate !== null
                                ? invalidDateString
                                : null
                            }
                            className={
                              "form-control-field form-control-datepicker"
                            }
                          />
                        )}
                        onError={(error)=>onError(error, "year5SprinklerInspectionDate")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              {/* 3-Year Sprinkler Inspection Date */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography
                    variant="body3"
                    component="label"
                  >{`3-Year Sprinkler Inspection Date`}</Typography>
                  <Controller
                    name="year3SprinklerInspectionDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        inputRef={field.ref}
                        views={["year", "month", "day"]}
                        onChange={field.onChange}
                        value={field.value}
                        components={{
                          OpenPickerIcon: UilCalender,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // error={Boolean(errors?.year3SprinklerInspectionDate)}
                            helperText={
                              isYear3SprinklerInspectionDate !== null
                                ? invalidDateString
                                : null
                            }
                            className={
                              "form-control-field form-control-datepicker"
                            }
                          />
                        )}
                        onError={(error)=>onError(error, "year3SprinklerInspectionDate")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              {/* Pressurized stairwells */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Pressurized Stairwells
                  </Typography>
                  <Select
                    labelId="pressurized-stairwells"
                    displayEmpty
                    input={<OutlinedInput />}
                    className={"form-control-field"}
                    value={isPressurized}
                    onChange={(event) =>
                      setIsPressurized(Number(event.target.value))
                    }
                    IconComponent={SelectIcon}
                  >
                    {yesNoSelectList.map((yesNoSelectItem) => (
                      <MenuItem
                        key={yesNoSelectItem.key}
                        value={yesNoSelectItem.key}
                      >
                        {yesNoSelectItem.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* Floor type */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Floor Type
                  </Typography>
                  <TextField
                    size="small"
                    id="floor-type"
                    variant="outlined"
                    {...register("floorType", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.floorType)}
                    helperText={
                      Boolean(errors?.floorType)
                        ? errors?.floorType?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Building standard paint */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Building Standard Paint
                  </Typography>
                  <TextField
                    size="small"
                    id="building-standard-paint"
                    variant="outlined"
                    {...register("buildingStandardPaint", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.buildingStandardPaint)}
                    helperText={
                      Boolean(errors?.buildingStandardPaint)
                        ? errors?.buildingStandardPaint?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Building Standard Carpet */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Building Standard Carpet
                  </Typography>
                  <TextField
                    size="small"
                    id="building-standard-carpet"
                    variant="outlined"
                    {...register("buildingStandardCarpet", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.buildingStandardCarpet)}
                    helperText={
                      Boolean(errors?.buildingStandardCarpet)
                        ? errors?.buildingStandardCarpet?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Common area paint */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Common Area Paint
                  </Typography>
                  <TextField
                    size="small"
                    id="common-area-paint"
                    variant="outlined"
                    {...register("commonAreaPaint", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.commonAreaPaint)}
                    helperText={
                      Boolean(errors?.commonAreaPaint)
                        ? errors?.commonAreaPaint?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Common Area Carpet */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Common Area Carpet
                  </Typography>
                  <TextField
                    size="small"
                    id="common-area-carpet"
                    variant="outlined"
                    {...register("commonAreaCarpet", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.commonAreaCarpet)}
                    helperText={
                      Boolean(errors?.commonAreaCarpet)
                        ? errors?.commonAreaCarpet?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Parking Type */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Parking Type
                  </Typography>
                  <TextField
                    size="small"
                    id="parking-type"
                    variant="outlined"
                    {...register("parkingType", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.parkingType)}
                    helperText={
                      Boolean(errors?.parkingType)
                        ? errors?.parkingType?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Number of Stalls */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Number of Stalls
                  </Typography>
                  <TextField
                    size="small"
                    id="number-of-stalls"
                    variant="outlined"
                    type="number"
                    {...register("parkingStallCount", {
                      valueAsNumber: true,
                      min: {
                        value: 0,
                        message: numberErrorMessage,
                      },
                      max: {
                        value: maxNumberField,
                        message: numberErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.parkingStallCount)}
                    helperText={
                      Boolean(errors?.parkingStallCount)
                        ? errors?.parkingStallCount?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Parking Ratio */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Parking Ratio
                  </Typography>
                  <TextField
                    size="small"
                    id="parking-ratio"
                    variant="outlined"
                    type="number"
                    {...register("parkingRatio", {
                      valueAsNumber: true,
                      min: {
                        value: 0,
                        message: numberErrorMessage,
                      },
                      max: {
                        value: maxNumberField,
                        message: numberErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.parkingRatio)}
                    helperText={
                      Boolean(errors?.parkingRatio)
                        ? errors?.parkingRatio?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Parking Rates */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Parking Rates
                  </Typography>
                  <TextField
                    size="small"
                    id="parking-rates"
                    variant="outlined"
                    {...register("parkingRates", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.parkingRates)}
                    helperText={
                      Boolean(errors?.parkingRates)
                        ? errors?.parkingRates?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Telecom Provider */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Telecom Provider
                  </Typography>
                  <TextField
                    size="small"
                    id="telecom-provider"
                    variant="outlined"
                    {...register("providerTelecom", {
                      maxLength: {
                        value: maxNumberField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.providerTelecom)}
                    helperText={
                      Boolean(errors?.providerTelecom)
                        ? errors?.providerTelecom?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Fiber Provider */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Fiber Provider
                  </Typography>
                  <TextField
                    size="small"
                    id="fiber-provider"
                    variant="outlined"
                    {...register("providerFiber", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.providerFiber)}
                    helperText={
                      Boolean(errors?.providerFiber)
                        ? errors?.providerFiber?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Water Company */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Water Company
                  </Typography>
                  <TextField
                    size="small"
                    id="water-company"
                    variant="outlined"
                    {...register("utilityWaterCompany", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.utilityWaterCompany)}
                    helperText={
                      Boolean(errors?.utilityWaterCompany)
                        ? errors?.utilityWaterCompany?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Electric Company */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Electric Company
                  </Typography>
                  <TextField
                    size="small"
                    id="electric-company"
                    variant="outlined"
                    {...register("utilityElectricCompany", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.utilityElectricCompany)}
                    helperText={
                      Boolean(errors?.utilityElectricCompany)
                        ? errors?.utilityElectricCompany?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Gas Company */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Gas Company
                  </Typography>
                  <TextField
                    size="small"
                    id="gas-company"
                    variant="outlined"
                    {...register("utilityGasCompany", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.utilityGasCompany)}
                    helperText={
                      Boolean(errors?.utilityGasCompany)
                        ? errors?.utilityGasCompany?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Building Hours */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Building Hours
                  </Typography>
                  <TextField
                    size="small"
                    id="building-hours"
                    variant="outlined"
                    {...register("buildingHours", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.buildingHours)}
                    helperText={
                      Boolean(errors?.buildingHours)
                        ? errors?.buildingHours?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* HVAC Hours */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    HVAC Hours
                  </Typography>
                  <TextField
                    size="small"
                    id="hvac-hours"
                    variant="outlined"
                    {...register("hvacHours", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.hvacHours)}
                    helperText={
                      Boolean(errors?.hvacHours)
                        ? errors?.hvacHours?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* After Hours HVAC Rate */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    After Hours HVAC Rate
                  </Typography>
                  <TextField
                    size="small"
                    id="hvac-overtime-rate"
                    variant="outlined"
                    {...register("hvacOvertimeRate", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.hvacOvertimeRate)}
                    helperText={
                      Boolean(errors?.hvacOvertimeRate)
                        ? errors?.hvacOvertimeRate?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Last Major Renovation */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography variant="body3" component="label">
                    Last Major Renovation
                  </Typography>
                  <TextField
                    size="small"
                    id="last-major-renovation"
                    variant="outlined"
                    {...register("lastRenovation", {
                      maxLength: {
                        value: maxTextField,
                        message: textErrorMessage,
                      },
                    })}
                    error={Boolean(errors?.lastRenovation)}
                    helperText={
                      Boolean(errors?.lastRenovation)
                        ? errors?.lastRenovation?.message
                        : null
                    }
                    className={"form-control-field"}
                  />
                </FormControl>
              </Grid>
              {/* Last Major Renovation Date */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography
                    variant="body3"
                    component="label"
                  >{`Last Major Renovation Date`}</Typography>
                  <Controller
                    name="lastRenovationDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        inputRef={field.ref}
                        views={["year", "month", "day"]}
                        onChange={field.onChange}
                        value={field.value}
                        components={{
                          OpenPickerIcon: UilCalender,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // error={Boolean(errors?.lastRenovationDate)}
                            helperText={
                              isLastRenovationDate !== null
                                ? invalidDateString
                                : null
                            }
                            className={
                              "form-control-field form-control-datepicker"
                            }
                          />
                        )}
                        onError={(error)=>onError(error, "lastRenovationDate")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </React.Fragment>
        {patchPropertyDetails.isLoading && (
          <Box m="auto">
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          disabled={patchPropertyDetails.isLoading}
          onClick={() => props.handlePopupClose(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={isDisable()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PropertyDetailsEdit;
