import { ColDef } from "ag-grid-community";
import {
  currencyFormatter,
  negativeNumCellStyle,
  numberFormatter,
  numberGetter,
  pctGetter,
  percentFormatter,
} from "../../../properties/util/gridUtils";

export const getPortfolioLoansColumns = () => {
  const columnDefs: ColDef[] = [
    {
      headerName: "Property Name",
      field: "propertyName",
      sortable: true,
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        if (params.data.propertyName === undefined) return "-";
        return params.data.propertyName;
      },
      cellRenderer: "linkComponent",
    },
    {
      headerName: "LTV",
      field: "loanToValue",
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: (params: any) =>
        percentFormatter(params.data.loanToValue, "%"),
      valueGetter: (params: any) => pctGetter(params.data.loanToValue),
    },
    {
      headerName: "LTC",
      field: "loanToCost",
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: (params: any) =>
        percentFormatter(params.data.loanToCost, "%"),
      valueGetter: (params: any) => pctGetter(params.data.loanToCost),
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Debt Yield",
      field: "debtYield",
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: (params: any) =>
        percentFormatter(params.data.debtYield, "%"),
      valueGetter: (params: any) => pctGetter(params.data.debtYield),
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "DSCR",
      field: "debtServiceCoverageRatio",
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => {
        if (params.data.debtServiceCoverageRatio === undefined) return "-";
        return `${numberFormatter(params.data.debtServiceCoverageRatio, 1)}x`;
      },
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "NOI TTM",
      field: "noittm",
      sortable: true,
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.noittm, "$"),
      valueGetter: (params: any) => numberGetter(params.data.noittm),
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Debt Service TTM",
      field: "debtServiceTTM",
      sortable: true,
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.debtServiceTTM, "$"),
      valueGetter: (params: any) => numberGetter(params.data.debtServiceTTM),
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Cost Basis",
      field: "costBasis",
      sortable: true,
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.costBasis, "$"),
      valueGetter: (params: any) => numberGetter(params.data.costBasis),
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Market Value",
      field: "marketValue",
      sortable: true,
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.marketValue, "$"),
      valueGetter: (params: any) => numberGetter(params.data.marketValue),
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Loan Balance",
      field: "principalBalance",
      sortable: true,
      filter: "agNumberColumnFilter",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params: any) =>
        currencyFormatter(params.data.principalBalance, "$"),
      valueGetter: (params: any) => numberGetter(params.data.principalBalance),
      cellStyle: negativeNumCellStyle,
    },
  ];
  return columnDefs;
};
