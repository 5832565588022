import React from "react";
import { useContext } from "react";
import Tooltip from "@mui/material/Tooltip";
import { ICellRendererParams } from "ag-grid-community";
import { Box, Grid, IconButton } from "@mui/material";
import { UilTrashAlt } from '@iconscout/react-unicons';
import { UilPen } from '@iconscout/react-unicons';
import { UilMessage } from '@iconscout/react-unicons';

import EditUserModal from "./EditUserModal";
import { TCreateUserModel } from "../model/createUserModel";
import { useCreateUser } from "../api/userService";
import UserContext from "../util/userContext";

const DeleteUserRenderer: React.FunctionComponent<ICellRendererParams> = (
  props: any
) => {
  const { updateAlertMessage } = useContext(UserContext);

  const [openDialog, setOpenDialog] = React.useState(false);

  const btnClickedHandler = () => {
    const userEmail = props.data?.email;
    props.clicked(props.value, userEmail);
  };

  const handleEditClose = () => {
    setOpenDialog(false);
  };

  const createUserMutate = useCreateUser();

  /**
   * Re sending invitations to users that have already been sent one.
   */
  const onResendInvitation = () => {
    updateAlertMessage({
      severityType: "info",
      message: "Resending the invitation.",
    });
    const model: TCreateUserModel = {
      accountIdentifier: props.accountId,
      email: props.data?.email,
    };
    createUserMutate.mutate(model, {
      onSuccess: (data) => {
        updateAlertMessage({
          severityType: "success",
          message: "Successfully sent the invitation. Please check your email.",
        });
      },
      onError: (data) => {
        updateAlertMessage({
          severityType: "error",
          message: "Could not resend the invitation. Please try again later.",
        });
      },
    });
  };

  const isInvitationSent = props.node?.data?.status === 2;

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid
          item
          sx={{
            "& svg": {
              maxHeight: "20px",
              maxWidth: "20px",
              lineHeight: "1",
            },
          }}
        >
          {openDialog && (
            <EditUserModal
              userId={props.data.id}
              openDialog={openDialog}
              closeDialog={handleEditClose}
            />
          )}
          <Tooltip title="Edit" style={{ cursor: "pointer" }}>
            <Box
              onClick={() => setOpenDialog(true)}
              className={"icon-documents"}
              aria-label="edit"
            >
              <UilPen />
            </Box>
          </Tooltip>
        </Grid>
        <Grid
          item
          sx={{
            "& svg": {
              maxHeight: "20px",
              maxWidth: "20px",
              lineHeight: "1",
            },
          }}
        >
          <Tooltip title="Delete" style={{ cursor: "pointer" }}>
            <Box
              onClick={btnClickedHandler}
              className={"icon-documents"}
              aria-label="delete"
            >
              <UilTrashAlt />
            </Box>
          </Tooltip>
        </Grid>
        <Grid
          item
          sx={{
            "& svg": {
              maxHeight: "20px",
              maxWidth: "20px",
              lineHeight: "1",
            },
          }}
        >
          {isInvitationSent && (
            <Tooltip title="Resend Invitation" style={{ cursor: "pointer" }}>
              <Box
                  onClick={onResendInvitation}
                className={"icon-documents"}
                aria-label="resend-invitation"
              >
                <UilMessage />
              </Box>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DeleteUserRenderer;
