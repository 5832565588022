import React from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { UilTrashAlt } from '@iconscout/react-unicons';
import { ICellRendererParams } from "ag-grid-community";
import { Grid, Box } from "@mui/material";

import EditPortfolio from "./EditPortfolio";

const DeletePortfolioRenderer: React.FunctionComponent<ICellRendererParams> = (
  params: any
) => {
  const navigate = useNavigate();

  const leafChildren = params.node.allLeafChildren;
  if (params.data !== undefined) return <React.Fragment />;

  const btnClickedHandler = () => {
    if (Array.isArray(leafChildren) && leafChildren.length > 0) {
      const firstChild = leafChildren[0].data;
      params.clicked(firstChild.id, firstChild.name);
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item>
          {Array.isArray(leafChildren) && leafChildren.length > 0 && (
            <React.Fragment>
              <EditPortfolio
                portfolioId={leafChildren[0].data.id}
                accountId={leafChildren[0].data.accountIdentifier}
                refreshTableData={params.refreshData}
              />
            </React.Fragment>
          )}
        </Grid>
        <Grid
          item
          sx={{
            "& svg": {
              maxHeight: "20px",
              maxWidth: "20px",
              lineHeight: "1",
            },
          }}
        >
          <Tooltip title="Delete" style={{ cursor: "pointer" }}>
            <Box
              onClick={btnClickedHandler}
              className={"icon-documents"}
              aria-label="delete"
            >
              <UilTrashAlt />
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DeletePortfolioRenderer;
