import { Paper } from "@mui/material";
import React from "react";
import { formatNumber } from "../../util/formatNumber";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { StackingLeaseSummary } from "../model/stackingPlan";
import { getBackgroundColor } from "../util/getBackgroundColor";
import { formatStackingPercentage } from "../util/format";

interface Props {
  leaseSummary?: StackingLeaseSummary[];
}

const PropertyViewStackingPlanLeaseSummary: React.FunctionComponent<Props> = (
  props
) => {
  return (
    <TableContainer
      component={Paper}
      className={"summary-table-stacking-plan-container"}
    >
      <Table
        size="small"
        aria-label="Lease Summary Table"
        className={"summary-table-stacking-plan"}
      >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {props.leaseSummary?.map((header, headerIndex) => {
              return (
                <TableCell
                  key={headerIndex}
                  className={"col-head-stacking-plan-key"}
                  style={{
                    borderBottomColor: getBackgroundColor(
                      header.leaseExpirationTerm,
                      false
                    ),
                  }}
                >
                  <Typography variant="body3" color="neutral700.light">
                    {header.title}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1" color="neutral700.light">
                Expiring Rentable Area
              </Typography>
            </TableCell>
            {props.leaseSummary?.map((row, areaIndex) => {
              return (
                <TableCell key={areaIndex}>
                  <Typography variant="subtitle1" color="primary.dark">
                    {formatNumber(row.area)} {"SF"}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1" color="neutral700.light">
                % of Total Rentable Area
              </Typography>
            </TableCell>
            {props.leaseSummary?.map((row, percentageIndex) => {
              return (
                <TableCell key={percentageIndex}>
                  <Typography variant="subtitle1" color="primary.dark">
                    {formatStackingPercentage(row.percentageOfArea)}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1" color="neutral700.light">
                Number of Expiring Suites
              </Typography>
            </TableCell>
            {props.leaseSummary?.map((row, unitsExpiringIndex) => {
              return (
                <TableCell key={unitsExpiringIndex}>
                  <Typography variant="subtitle1" color="primary.dark">
                    {formatNumber(row.unitsExpiring)}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1" color="neutral700.light">
                % of Total Suites
              </Typography>
            </TableCell>
            {props.leaseSummary?.map((row, percentageOfUnitsIndex) => {
              return (
                <TableCell key={percentageOfUnitsIndex}>
                  <Typography variant="subtitle1" color="primary.dark">
                    {formatStackingPercentage(row.percentageOfUnitsExpiring)}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PropertyViewStackingPlanLeaseSummary;
