import React from 'react';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { ICellRendererParams } from 'ag-grid-community';
import { GridRowList } from "../util/constant";

export const EditIconRenderer = (props: ICellRendererParams) => {
  return (
    <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
      {props.data.report !== GridRowList.PROPERTY_OVERVIEW && (
        <> 
          <div>{props.value}</div>
          <div style={{display: "flex", alignItems: "center"}}><CalendarTodayOutlinedIcon style={{fontSize: "20px", color: "var(--neutral-grey-600)"}} /></div>
        </>
      )}
    </div>
  );
};
export default EditIconRenderer;