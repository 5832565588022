import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  CircularProgress,
  Box,
  Button,
  Typography,
  FormControl,
  TextField,
  Theme,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Grid,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { useCreateBuilding } from "../api/buildingService";
import { useGetStates } from "../api/stateService";
import { TCreateBuildingModel } from "../model/createBuildingModel";
import { usePropertyInfo } from "../../util/propertyInfoContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
      borderRadius: 4,
    },
    title: {
      borderBottom: "1px solid #ccc",
      marginBottom: 15,
    },
  })
);

interface BuildingInsertProps {
  handlePopupClose: (shouldRefreshData: boolean) => void;
  showInsertPopup: boolean;
}

const BuildingInsert: React.FunctionComponent<BuildingInsertProps> = (
  props
) => {
  const classess = useStyles();
  const { data: stateLookupData, isLoading: isLoadingStates } = useGetStates();
  const {
    register,
    handleSubmit,
    control,

    formState: { errors },
  } = useForm<TCreateBuildingModel>({
    defaultValues: {
      title: "",
      street: "",
      city: "",
      province: "",
      postalCode: "",
      rsf: undefined,
      accountingKey: "",
    },
  });

  const createBuildingResponse = useCreateBuilding();
  const propertyInfo = usePropertyInfo();

  const onSubmit = handleSubmit((model: TCreateBuildingModel) => {
    model.propertyId = propertyInfo.id;
    model.accountIdentifier = propertyInfo.accountIdentifier;
    model.rsf = "" + model.rsf === "" ? null : model.rsf;
    model.accountingKey =
      "" + model.accountingKey === "" ? null : model.accountingKey;
    createBuildingResponse.mutate(model, {
      onSuccess: (data) => {
        props.handlePopupClose(true);
      },
    });
  });

  const handleClose = () => {
    props.handlePopupClose(false);
  };

  const SelectIcon = (props: any) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  return (
    <Dialog
      open={props.showInsertPopup}
      onClose={handleClose}
      aria-labelledby="new-building"
      aria-describedby="new-building-description"
    >
      <DialogTitle id="new-building" className={classess.title}>
        Add New Building
      </DialogTitle>
      <DialogContent>
        {isLoadingStates ? (
          <Box m="auto">
            <CircularProgress />
          </Box>
        ) : (
          <React.Fragment>
            <Typography>
              To add a new building, please fill out the form below.
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <FormControl className={classess.formControl}>
                  <TextField
                    size="small"
                    id="buildingTitle"
                    label="Title"
                    variant="outlined"
                    {...register("title", {
                      required: "Building Title is required",
                      maxLength: {
                        value: 500,
                        message: "Maximum length is 500",
                      },
                    })}
                    error={Boolean(errors?.title)}
                    helperText={
                      Boolean(errors?.title) ? errors?.title?.message : null
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classess.formControl}>
                  <TextField
                    size="small"
                    id="street"
                    label="Street"
                    variant="outlined"
                    {...register("street", {
                      required: "Address is required",
                      maxLength: {
                        value: 250,
                        message: "Maximum length is 250",
                      },
                    })}
                    error={Boolean(errors?.street)}
                    helperText={
                      Boolean(errors?.street) ? errors?.street?.message : null
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classess.formControl}>
                  <TextField
                    size="small"
                    id="city"
                    label="city"
                    variant="outlined"
                    {...register("city", {
                      required: "City is required",
                      maxLength: {
                        value: 50,
                        message: "Maximum length is 50",
                      },
                    })}
                    error={Boolean(errors?.city)}
                    helperText={
                      Boolean(errors?.city) ? errors?.city?.message : null
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={`${classess.formControl}`}
                >
                  <InputLabel id="province" shrink>
                    State
                  </InputLabel>
                  {/* province */}
                  <Controller
                    control={control}
                    name={"province"}
                    render={({ field }) => (
                      <Select
                        labelId="province"
                        label="State"
                        displayEmpty
                        defaultValue=""
                        input={<OutlinedInput notched label="State" />}
                        IconComponent={SelectIcon}
                      >
                        <MenuItem value={""}>Select State</MenuItem>
                        {stateLookupData &&
                          stateLookupData.map((province, index: number) => (
                            <MenuItem key={`${index}`} value={province.code}>
                              {province.code}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classess.formControl}>
                  <TextField
                    size="small"
                    id="postalCode"
                    label="Zip"
                    variant="outlined"
                    {...register("postalCode", {
                      required: "Postal code is required",
                      maxLength: {
                        value: 50,
                        message: "Maximum length is 50",
                      },
                    })}
                    error={Boolean(errors?.postalCode)}
                    helperText={
                      Boolean(errors?.postalCode)
                        ? errors?.postalCode?.message
                        : null
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classess.formControl}>
                  <TextField
                    size="small"
                    id="rsf"
                    label="RSF"
                    type="number"
                    variant="outlined"
                    {...register("rsf", {
                      min: {
                        value: 1,
                        message: "Minimum value is 1",
                      },
                      max: {
                        value: 2147483647,
                        message: "Maximum value is 2,147,483,647",
                      },
                    })}
                    error={Boolean(errors?.rsf)}
                    helperText={
                      Boolean(errors?.rsf) ? errors?.rsf?.message : null
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classess.formControl}>
                  <TextField
                    size="small"
                    id="accountingKey"
                    label="Accounting Key"
                    variant="outlined"
                    {...register("accountingKey", {
                      maxLength: {
                        value: 500,
                        message: "Maximum length is 500",
                      },
                    })}
                    error={Boolean(errors?.accountingKey)}
                    helperText={
                      Boolean(errors?.accountingKey)
                        ? errors?.accountingKey?.message
                        : null
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            {createBuildingResponse.isLoading && (
              <Box m="auto">
                <CircularProgress />
              </Box>
            )}
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={createBuildingResponse.isLoading}
          onClick={onSubmit}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BuildingInsert;
