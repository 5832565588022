import { createContext } from "react";

import { IWidgetConfigContext } from "../model/widgetConfigModel";

export const WidgetConfigContext = createContext<IWidgetConfigContext>({
  widgetsConfig: [],
  updateWidgetsConfig: () => {},
  updateMultipleWidgetsConfig: () => {},
  allWidgetsChecked: {
    checked: false,
    touched: false,
  },
  updateAllWidgetsChecked: () => {},
});
