import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import NumberInput from "./NumberInput";
import {
  unformatNumber,
  computeTerms,
  areDatesEqual,
  dateBehind,
  originationMaturityErrorText,
} from "../util/primaryInfoForm";
import LoansContext from "../context/LoansContext";
import { LoansForm } from "../model/LoansForm";
import { LoanKeyValue } from "../model/LoansModel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { UilCalender } from "@iconscout/react-unicons";
import {
  maxNotesField,
  notesErrorMessage,
  numberErrorMessage,
  requiredErrorMessage,
} from "../../property details/util/errorTexts";

const prepaymentPenaltyNotesNA = "N/A";

export const isValidDate = (date: string | Date) => {
  if (isNaN(Date.parse(date.toString()))) return false;
  return true;
};

interface Props {
  disableSave: (value: boolean) => void;
}

const PrimaryInfoForm: React.FC<Props> = (props) => {
  const { disableSave } = props;
  const {
    kpiFinancialMeasureTypeSelectList,
    yesNoSelectList,
    activeInactiveSelectList,
    noticeDateTypeSelectList,
    interestRateTypeSelectList,
    interestRateBasisTypeSelectList,
    loanTypeSelectList,
  } = React.useContext(LoansContext);

  const [rerender, setRerender] = useState(true);

  const methods = useFormContext<LoansForm>();
  const {
    formState: { errors },
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    watch,
    register,
  } = methods;

  const watchOriginationDate: Date | null = watch("originationDate");
  const watchMaturityDate: Date | null = watch("maturityDate");
  const watchExtensionMaturityDate: Date | null = watch(
    "extensionMaturityDate"
  );
  const watchPrepaymentPenalty: number = watch("prepaymentPenalty");
  const watchPrepaymentPenaltyNotes: string = watch("prepaymentPenaltyNotes");
  const watchNoticeDateTypeId: number = watch("noticeDateTypeId");

  const hasBeenOrigDateResetted = React.useRef(false);
  const hasBeenMaturDateResetted = React.useRef(false);

  /**
   * Resets the error message in the case origination date has "Invalid Date" as its value
   */
  useEffect(() => {
    if (!hasBeenOrigDateResetted.current) {
      hasBeenOrigDateResetted.current = true;

      if (
        watchOriginationDate !== null &&
        isNaN(Date.parse(watchOriginationDate.toString()))
      )
        setValue("originationDate", null);
    }
  }, [watchOriginationDate, setValue]);

  /**
   * Resets the error message in the case maturity date has "Invalid Date" as its value
   */
  useEffect(() => {
    if (!hasBeenMaturDateResetted.current) {
      hasBeenMaturDateResetted.current = true;

      if (
        watchMaturityDate !== null &&
        isNaN(Date.parse(watchMaturityDate.toString()))
      )
        setValue("maturityDate", null);
    }
  }, [watchMaturityDate, setValue]);

  const { isValid } = methods.formState;
  /**
   * enabling/disabling save button based on whether the form is valid or not.
   */
  useEffect(() => {
    disableSave(!isValid);
  }, [isValid, disableSave]);

  /**
   * Updating term years field whenever origination date or maturity date changes.
   */
  useEffect(() => {
    if (
      watchOriginationDate === null ||
      !isValidDate(watchOriginationDate) ||
      watchMaturityDate === null ||
      !isValidDate(watchMaturityDate)
    )
      return;

    const termYears = computeTerms(watchOriginationDate, watchMaturityDate);
    // watchMaturityDate.getFullYear() - watchOriginationDate.getFullYear();

    setValue("termYears", termYears);
  }, [watchOriginationDate, watchMaturityDate, setValue]);

  /**
   * Setting prepaymentPenaltyNotes based on prepaymentPenalty
   * if prepaymentPenalty is No, then set
   */
  useEffect(() => {
    if (
      watchPrepaymentPenalty === 0 &&
      watchPrepaymentPenaltyNotes !== prepaymentPenaltyNotesNA
    ) {
      if (Boolean(errors) && Boolean(errors.prepaymentPenaltyNotes))
        delete errors.prepaymentPenaltyNotes;
      setValue("prepaymentPenaltyNotes", prepaymentPenaltyNotesNA);
      setRerender(!rerender);
    }
  }, [
    watchPrepaymentPenalty,
    watchPrepaymentPenaltyNotes,
    errors,
    rerender,
    setValue,
  ]);

  useEffect(() => {
    if (
      watchOriginationDate === null ||
      watchOriginationDate.toString().trim() === "" ||
      watchMaturityDate === null ||
      watchMaturityDate.toString().trim() === ""
    )
      return;

    const originationDate = new Date(watchOriginationDate);
    const maturityDate = new Date(watchMaturityDate);
    if (isValidDate(originationDate) && isValidDate(maturityDate)) {
      if (
        originationDate.valueOf() > maturityDate.valueOf() ||
        areDatesEqual(originationDate, maturityDate)
      ) {
        setError("originationDate", {
          type: "manual",
          message: originationMaturityErrorText,
        });
        setError("maturityDate", {
          type: "manual",
          message: originationMaturityErrorText,
        });
      } else {
        clearErrors("originationDate");
        clearErrors("maturityDate");
      }
    }
  }, [setError, clearErrors, watchOriginationDate, watchMaturityDate]);

  useEffect(() => {
    if (
      watchExtensionMaturityDate === null ||
      watchExtensionMaturityDate.toString().trim() === ""
    )
      return;

    const extensionMaturityDate = new Date(watchExtensionMaturityDate);

    if (isValidDate(extensionMaturityDate)) {
      clearErrors("extensionMaturityDate");
    }
  }, [clearErrors, watchExtensionMaturityDate]);

  const SelectIcon = (props: Props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 0 0"
      fill="none"
    >
      <path
        d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z"
        fill="#021A3C"
      />
    </svg>
  );

  return (
    <React.Fragment>
      {/* Loan Status & Original Balance */}
      <Grid container spacing={1}>
        {/* Loan Status */}
        <Grid item xs={6}>
          <FormControl
            size="small"
            variant="outlined"
            className={"form-control"}
            error={Boolean(errors.loanStatus)}
          >
            <Typography
              variant="body3"
              component="label"
            >{`Active Status*`}</Typography>
            <Controller
              control={control}
              name={"loanStatus"}
              rules={{
                required: requiredErrorMessage,
                validate: {
                  required: (v) => (v === -1 ? requiredErrorMessage : true),
                },
              }}
              render={({ field, fieldState }) => (
                <Select
                  labelId="loan-status"
                  displayEmpty
                  input={<OutlinedInput />}
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                  {...field}
                >
                  {activeInactiveSelectList.map(
                    (activeInactiveOption: LoanKeyValue, index: number) => (
                      <MenuItem
                        key={`${index}`}
                        value={activeInactiveOption.key}
                      >
                        {activeInactiveOption.value}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
            {Boolean(errors.loanStatus) && (
              <FormHelperText>{errors.loanStatus?.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        {/* GL Account Mapping */}
        <Grid item xs={6}>
          <FormControl
            size="small"
            variant="outlined"
            className={"form-control"}
          >
            <Typography
              variant="body3"
              component="label"
            >{`GL Account Mapping`}</Typography>
            <Controller
              control={control}
              name={"kpiFinancialMeasureTypeId"}
              render={({ field, fieldState }) => (
                <Select
                  labelId="KPI-Measure"
                  //label="GL Account Mapping"
                  displayEmpty
                  input={
                    <OutlinedInput
                    //notched
                    //label="GL Account Mapping"
                    />
                  }
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                  {...field}
                >
                  {kpiFinancialMeasureTypeSelectList.map(
                    (kpiOption: LoanKeyValue, index: number) => (
                      <MenuItem key={`${index}`} value={kpiOption.key}>
                        {kpiOption.value}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        {/* Original Loan Balance */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Original Loan Balance*`}</Typography>
            <NumberInput
              name="originalLoanBalance"
              defaultValue={getValues("originalLoanBalance")}
              textFieldProps={{
                error: Boolean(errors?.originalLoanBalance),
                helperText: Boolean(errors?.originalLoanBalance)
                  ? errors?.originalLoanBalance?.message
                  : null,
                size: "small",
                variant: "outlined",
              }}
              register={{
                required: requiredErrorMessage,
                validate: {
                  req: (v) => {
                    if (typeof v === "string") return true;
                    if (isNaN(v)) return requiredErrorMessage;
                    else return true;
                  },
                  positive: (v) => {
                    const value = Number(unformatNumber(v));
                    if (value === undefined) return true;
                    if (value > 0 || isNaN(value)) return true;
                    else return numberErrorMessage;
                  },
                  numFormat: (v) => {
                    const value = Number(unformatNumber(v));
                    if (value === undefined) return true;
                    return !isNaN(value) || numberErrorMessage;
                  },
                },
              }}
              format
            />
          </FormControl>
        </Grid>
        {/* Origination Date */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Origination Date*`}</Typography>
            <Controller
              rules={{
                required: requiredErrorMessage,
                validate: {
                  format: (value) => {
                    if (value === null || value.toString().trim() === "")
                      return true;
                    if (isValidDate(new Date(value))) return true;
                    else return "Invalid date entry.";
                  },
                  dateBehind: (value) => dateBehind(value, watchMaturityDate),
                },
              }}
              name="originationDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  inputRef={field.ref}
                  views={["year", "month", "day"]}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  value={field.value}
                  components={{
                    OpenPickerIcon: UilCalender,
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        className={"form-control-field form-control-datepicker"}
                        error={Boolean(errors?.originationDate)}
                        helperText={
                          errors?.originationDate
                            ? errors?.originationDate.message
                            : null
                        }
                      />
                    );
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        {/* Maturity Date */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Maturity Date*`}</Typography>
            <Controller
              rules={{
                required: requiredErrorMessage,
                validate: {
                  format: (value) => {
                    if (value === null || value.toString().trim() === "")
                      return true;
                    if (isValidDate(new Date(value))) return true;
                    else return "Invalid date entry.";
                  },
                  dateBehind: (value) =>
                    dateBehind(watchOriginationDate, value),
                },
              }}
              name="maturityDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  inputRef={field.ref}
                  views={["year", "month", "day"]}
                  onChange={field.onChange}
                  value={field.value}
                  components={{
                    OpenPickerIcon: UilCalender,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={"form-control-field form-control-datepicker"}
                      error={Boolean(errors?.maturityDate)}
                      helperText={
                        errors?.maturityDate
                          ? errors?.maturityDate.message
                          : null
                      }
                    />
                  )}
                />
              )}
            />
          </FormControl>
        </Grid>
        {/* Terms (yrs) */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Term (Years)`}</Typography>
            <TextField
              size="small"
              id="term"
              disabled
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              {...register("termYears", {
                valueAsNumber: true,
              })}
              error={Boolean(errors?.termYears)}
              helperText={
                Boolean(errors?.termYears) ? errors?.termYears?.message : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
        {/* Interest Type */}
        <Grid item xs={6}>
          <FormControl
            size="small"
            variant="outlined"
            className={"form-control"}
            error={Boolean(errors.interestRateTypeId)}
          >
            <Typography
              variant="body3"
              component="label"
            >{`Interest Type*`}</Typography>
            {/*
            <InputLabel id="interest-type" shrink>
              Interest Type*
            </InputLabel>
            */}
            <Controller
              control={control}
              name={"interestRateTypeId"}
              rules={{
                validate: {
                  required: (v) => (v === -1 ? requiredErrorMessage : true),
                },
              }}
              render={({ field, fieldState }) => (
                <Select
                  labelId="interest-type"
                  //label="Interest Type*"
                  displayEmpty
                  input={
                    <OutlinedInput
                    //notched
                    //label="Interest Type*"
                    />
                  }
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                  {...field}
                >
                  {interestRateTypeSelectList.map(
                    (interestTypeOption: LoanKeyValue, index: number) => (
                      <MenuItem key={`${index}`} value={interestTypeOption.key}>
                        {interestTypeOption.value}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
            {Boolean(errors.interestRateTypeId) && (
              <FormHelperText>
                {errors.interestRateTypeId?.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {/* Total Interest Rate */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Total Interest Rate (%)*`}</Typography>
            <TextField
              size="small"
              //label="Total Interest Rate (%)*"
              variant="outlined"
              id="total-interest-rate"
              error={Boolean(errors?.totalInterestRatePercent)}
              helperText={
                Boolean(errors?.totalInterestRatePercent)
                  ? errors?.totalInterestRatePercent?.message
                  : null
              }
              {...register("totalInterestRatePercent", {
                required: requiredErrorMessage,
                setValueAs: (v) => {
                  if (typeof v === "number") return v;
                  if (v === undefined || v.trim().length === 0)
                    return undefined;
                  if (isNaN(v)) return v;
                  return Number(v);
                },
                // valueAsNumber: true,
                validate: {
                  positiveInteger: (v) => (v > 0 ? true : numberErrorMessage),
                  numFormat: (v) => !isNaN(v) || numberErrorMessage,
                },
                max: {
                  value: 100,
                  message: numberErrorMessage,
                },
                min: {
                  value: 0,
                  message: numberErrorMessage,
                },
              })}
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
        {/* Amortizing */}
        <Grid item xs={6}>
          <FormControl
            size="small"
            variant="outlined"
            className={"form-control"}
            error={Boolean(errors.amortizing)}
          >
            <Typography
              variant="body3"
              component="label"
            >{`Amortizing*`}</Typography>
            {/*
            <InputLabel id="amortizing" shrink>
              Amortizing*
            </InputLabel>
            */}
            <Controller
              control={control}
              name={"amortizing"}
              rules={{
                validate: {
                  required: (v) => (v === -1 ? requiredErrorMessage : true),
                },
              }}
              render={({ field, fieldState }) => (
                <Select
                  labelId="amortizing"
                  //label="Amortizing*"
                  displayEmpty
                  input={
                    <OutlinedInput
                    //notched
                    //label="Amortizing*"
                    />
                  }
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                  {...field}
                >
                  {yesNoSelectList.map(
                    (yesNoOption: LoanKeyValue, index: number) => (
                      <MenuItem key={`${index}`} value={yesNoOption.key}>
                        {yesNoOption.value}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />

            {Boolean(errors.amortizing) && (
              <FormHelperText>{errors.amortizing?.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        {/* Prepayment Penalty */}
        <Grid item xs={6}>
          <FormControl
            size="small"
            variant="outlined"
            className={"form-control"}
            error={Boolean(errors.prepaymentPenalty)}
          >
            <Typography
              variant="body3"
              component="label"
            >{`Prepayment Penalty*`}</Typography>
            {/*
            <InputLabel id="prepayment-penalty" shrink>
              Prepayment Penalty*
            </InputLabel>
            */}
            <Controller
              control={control}
              name={"prepaymentPenalty"}
              rules={{
                validate: {
                  required: (v) => (v === -1 ? requiredErrorMessage : true),
                },
              }}
              render={({ field, fieldState }) => (
                <Select
                  labelId="prepayment-penalty"
                  //label="Prepayment Penalty*"
                  displayEmpty
                  input={
                    <OutlinedInput
                    //notched
                    //label="Prepayment Penalty*"
                    />
                  }
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                  {...field}
                >
                  {yesNoSelectList.map(
                    (yesNoOption: LoanKeyValue, index: number) => (
                      <MenuItem key={`${index}`} value={yesNoOption.key}>
                        {yesNoOption.value}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
            {Boolean(errors.prepaymentPenalty) && (
              <FormHelperText>
                {errors.prepaymentPenalty?.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {/* Prepayment Penalty Notes */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Prepayment Penalty Notes*`}</Typography>
            <TextField
              size="small"
              id="prepayment-penalty-notes"
              //label="Prepayment Penalty Notes*"
              variant="outlined"
              InputProps={{
                readOnly: watchPrepaymentPenalty === 0,
              }}
              InputLabelProps={
                watchPrepaymentPenalty === 0 ||
                watchPrepaymentPenaltyNotes.trim().length > 0
                  ? {
                      shrink: true,
                    }
                  : {}
              }
              {...register("prepaymentPenaltyNotes", {
                required: requiredErrorMessage,
              })}
              error={Boolean(errors?.prepaymentPenaltyNotes)}
              helperText={
                Boolean(errors?.prepaymentPenaltyNotes)
                  ? errors?.prepaymentPenaltyNotes?.message
                  : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
        {/* Loan Number */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Loan Number`}</Typography>
            <TextField
              size="small"
              id="loan-number"
              //label="Loan Number"
              variant="outlined"
              {...register("loanNumber")}
              error={Boolean(errors?.loanNumber)}
              helperText={
                Boolean(errors?.loanNumber) ? errors?.loanNumber?.message : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
        {/* Extension Maturity Date */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Extension Maturity Date`}</Typography>
            <Controller
              name="extensionMaturityDate"
              rules={{
                validate: {
                  format: (value) => {
                    if (value === null || value.toString().trim() === "")
                      return true;
                    if (isValidDate(new Date(value))) return true;
                    else return "Invalid date entry.";
                  },
                },
              }}
              control={control}
              render={({ field }) => (
                <DatePicker
                  //label="Extension Maturity Date"
                  inputRef={field.ref}
                  views={["year", "month", "day"]}
                  onChange={field.onChange}
                  value={field.value}
                  components={{
                    OpenPickerIcon: UilCalender,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={"form-control-field form-control-datepicker"}
                      error={Boolean(errors?.extensionMaturityDate)}
                      helperText={
                        errors?.extensionMaturityDate
                          ? errors?.extensionMaturityDate.message
                          : null
                      }
                    />
                  )}
                />
              )}
            />
          </FormControl>
        </Grid>
        {/* Notice Date to Extend Provisions */}
        <Grid item xs={6}>
          <FormControl
            size="small"
            variant="outlined"
            className={"form-control"}
          >
            <Typography
              variant="body3"
              component="label"
            >{`Notice Date to Extend Provisions`}</Typography>
            {/*
            <InputLabel id="notice-date" shrink>
              Notice Date to Extend Provisions
            </InputLabel>
            */}
            <Controller
              control={control}
              name={"noticeDateTypeId"}
              render={({ field }) => (
                <Select
                  labelId="notice-date"
                  //label="Notice Date to Extend Provisions"
                  displayEmpty
                  input={
                    <OutlinedInput
                    //notched
                    //label="Notice Date to Extend Provisions"
                    />
                  }
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                  {...field}
                >
                  {noticeDateTypeSelectList.map(
                    (noticeDateOption: LoanKeyValue, index: number) => (
                      <MenuItem key={`${index}`} value={noticeDateOption.key}>
                        {noticeDateOption.value}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        {/* Notice Date */}
        {/* When the user selects "Select notice date" */}
        {watchNoticeDateTypeId === 2 && (
          <Grid item xs={6}>
            <FormControl className={"form-control"}>
              <Typography
                variant="body3"
                component="label"
              >{`Notice Date`}</Typography>
              <Controller
                rules={{ required: requiredErrorMessage }}
                name="noticeDateToExtendProvisions"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    //label="Notice Date"
                    inputRef={field.ref}
                    views={["year", "month", "day"]}
                    onChange={field.onChange}
                    value={field.value}
                    components={{
                      OpenPickerIcon: UilCalender,
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={"form-control-field form-control-datepicker"}
                        error={Boolean(errors?.noticeDateToExtendProvisions)}
                        helperText={
                          errors?.noticeDateToExtendProvisions
                            ? errors?.noticeDateToExtendProvisions.message
                            : null
                        }
                      />
                    )}
                  />
                )}
              />
            </FormControl>
          </Grid>
        )}
        {/* Base */}
        <Grid item xs={6}>
          <FormControl
            size="small"
            variant="outlined"
            className={"form-control"}
          >
            <Typography variant="body3" component="label">{`Base`}</Typography>
            {/*
            <InputLabel id="base" shrink>
              Base
            </InputLabel>
            */}
            <Controller
              control={control}
              name={"interestRateBasisTypeId"}
              render={({ field }) => (
                <Select
                  labelId="base"
                  //label="Base"
                  displayEmpty
                  input={
                    <OutlinedInput
                    //notched
                    //label="Base"
                    />
                  }
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                  {...field}
                >
                  {interestRateBasisTypeSelectList.map(
                    (baseOption: LoanKeyValue, index: number) => (
                      <MenuItem key={`${index}`} value={baseOption.key}>
                        {baseOption.value}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        {/* Loan Type */}
        <Grid item xs={6}>
          <FormControl
            size="small"
            variant="outlined"
            className={"form-control"}
          >
            <Typography
              variant="body3"
              component="label"
            >{`Loan Type`}</Typography>
            {/*
            <InputLabel id="loanType" shrink>
              Loan Type
            </InputLabel>
            */}
            <Controller
              control={control}
              name={"loanTypeId"}
              render={({ field }) => (
                <Select
                  labelId="loanType"
                  //label="Loan Type"
                  displayEmpty
                  input={
                    <OutlinedInput
                    //notched
                    //label="Loan Type"
                    />
                  }
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                  {...field}
                >
                  {loanTypeSelectList.map(
                    (loanTypeOption: LoanKeyValue, index: number) => (
                      <MenuItem key={`${index}`} value={loanTypeOption.key}>
                        {loanTypeOption.value}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        {/* Spread */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Spread (%)`}</Typography>
            <TextField
              size="small"
              //label="Spread (%)"
              variant="outlined"
              id="spread-percent"
              error={Boolean(errors?.spreadPercent)}
              helperText={
                Boolean(errors?.spreadPercent)
                  ? errors?.spreadPercent?.message
                  : null
              }
              {...register("spreadPercent", {
                // required: 'Spread is required',
                setValueAs: (v) => {
                  if (typeof v === "number") return v;
                  if (v === undefined || v.trim().length === 0)
                    return undefined;
                  if (isNaN(v)) return v;
                  return Number(v);
                },
                validate: {
                  numFormat: (v) =>
                    v === undefined || !isNaN(v) || numberErrorMessage,
                },
                max: {
                  value: 100,
                  message: numberErrorMessage,
                },
                min: { value: 0, message: numberErrorMessage },
              })}
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
        {/* Interest rate notes */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Interest Rate Notes`}</Typography>
            <TextField
              size="small"
              id="interest-rate-notes"
              //label="Interest Rate Notes"
              variant="outlined"
              {...register("interestRateNotes")}
              error={Boolean(errors?.interestRateNotes)}
              helperText={
                Boolean(errors?.interestRateNotes)
                  ? errors?.interestRateNotes?.message
                  : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
        {/* Securitized */}
        <Grid item xs={6}>
          <FormControl
            size="small"
            variant="outlined"
            className={"form-control"}
          >
            <Typography
              variant="body3"
              component="label"
            >{`Securitized`}</Typography>
            {/*
            <InputLabel id="securitized" shrink>
              Securitized
            </InputLabel>
            */}
            <Controller
              control={control}
              name={"securitized"}
              render={({ field }) => (
                <Select
                  labelId="securitized"
                  //label="Securitized"
                  displayEmpty
                  input={
                    <OutlinedInput
                    //notched
                    //label="Securitized"
                    />
                  }
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                  {...field}
                >
                  {yesNoSelectList.map(
                    (yesNoOption: LoanKeyValue, index: number) => (
                      <MenuItem key={`${index}`} value={yesNoOption.key}>
                        {yesNoOption.value}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        {/* Assumable */}
        <Grid item xs={6}>
          <FormControl
            size="small"
            variant="outlined"
            className={"form-control"}
          >
            <Typography
              variant="body3"
              component="label"
            >{`Assumable`}</Typography>
            {/*
            <InputLabel id="assumable" shrink>
              Assumable
            </InputLabel>
            */}
            <Controller
              control={control}
              name={"assumable"}
              render={({ field }) => (
                <Select
                  labelId="assumable"
                  //label="Assumable"
                  displayEmpty
                  input={
                    <OutlinedInput
                    //notched
                    //label="Assumable"
                    />
                  }
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                  {...field}
                >
                  {yesNoSelectList.map(
                    (yesNoOption: LoanKeyValue, index: number) => (
                      <MenuItem key={`${index}`} value={yesNoOption.key}>
                        {yesNoOption.value}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        {/* Prepayment */}
        {/* <Grid item xs={6}>
          <FormControl
            size="small"
            variant="outlined"
            className={"form-control"}
          >
            <Typography
              variant="body3"
              component="label"
            >{`Prepayment`}</Typography>
             <Controller
              control={control}
              name={"prepayment"}
              render={({ field }) => (
                <Select
                  labelId="prepayment"
                  displayEmpty
                  input={
                    <OutlinedInput
                    />
                  }
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                  {...field}
                >
                  {yesNoSelectList.map(
                    (yesNoOption: LoanKeyValue, index: number) => (
                      <MenuItem key={`${index}`} value={yesNoOption.key}>
                        {yesNoOption.value}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </FormControl>
        </Grid> */}
        {/* Cross Collateralized */}
        <Grid item xs={6}>
          <FormControl
            size="small"
            variant="outlined"
            className={"form-control"}
          >
            <Typography
              variant="body3"
              component="label"
            >{`Cross Collateralized`}</Typography>
            {/*
            <InputLabel id="cross-collateralized" shrink>
              Cross Collateralized
            </InputLabel>
            */}
            <Controller
              control={control}
              name={"crossCollateralized"}
              render={({ field }) => (
                <Select
                  labelId="cross-collateralized"
                  //label="Cross Collateralized"
                  displayEmpty
                  input={
                    <OutlinedInput
                    //notched
                    //label="Cross Collateralized"
                    />
                  }
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                  {...field}
                >
                  {yesNoSelectList.map(
                    (yesNoOption: LoanKeyValue, index: number) => (
                      <MenuItem key={`${index}`} value={yesNoOption.key}>
                        {yesNoOption.value}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        {/* Lien Info */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Lien Information`}</Typography>
            <TextField
              size="small"
              id="lienInfo"
              //label="Lien Information"
              variant="outlined"
              {...register("lienInfo")}
              error={Boolean(errors?.lienInfo)}
              helperText={
                Boolean(errors?.lienInfo) ? errors?.lienInfo?.message : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* Loan notes */}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl className={"form-control"}>
            <Typography
              variant="body3"
              component="label"
            >{`Loan Notes`}</Typography>
            <TextField
              size="small"
              variant="outlined"
              multiline
              error={Boolean(errors?.loanNotes)}
              helperText={
                Boolean(errors?.loanNotes) ? errors?.loanNotes?.message : null
              }
              rows={4}
              className={"form-control-field form-control-textarea"}
              {...register("loanNotes", {
                validate: {
                  maxCharacters: (value) => {
                    if (!value) return true;
                    if (value?.trim().length > maxNotesField)
                      return notesErrorMessage;
                  },
                },
              })}
            />
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PrimaryInfoForm;
