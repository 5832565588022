import { TSpaceModel } from "./spaceListModel";

class SpaceFloorGroup {
  spaces: TSpaceModel[];
  floor: string;

  constructor(
    spaces: TSpaceModel[],
    floor: string
  ) {
    this.spaces = spaces.sort(
      (a: TSpaceModel, b: TSpaceModel) => {
        return (
          a.title.localeCompare(b.title)
        );
      }
    );

    this.floor = floor;
  }

}
export default SpaceFloorGroup;

export type TSpaceGroupListModel = {
  spaceFloors: SpaceFloorGroup[];
};
