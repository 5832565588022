import React, { useState, useEffect } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import { useLocation, Link, Outlet } from "react-router-dom";
import {
  ReportInfo,
  ReportInfoContext,
} from "../../util/reportInfoContext";
import UserOptions from "../../../layout/views/userOptions";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const Reporting: React.FC = () => {

  const [reportInfo, setReportInfo] = useState<ReportInfo>({
    reportId: "",
    accountIdentifier: "",
    description: "",
  });

  useEffect(() => {
    document.title = "Workspace | Reports";
  }, []);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      mainTopTabs: {
        width: "100%",
      },
      mainTopTabPanel: {
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        borderTop: "1px solid #ccc",
        [theme.breakpoints.up("lg")]: {
          width: "100%",
        },
      },
    })
  );

  const location = useLocation();
  const classes = useStyles();
  return (
    <React.Fragment>
      <Box className="mainPropertiesContainer">
        <Box className={"appHeader"}>
          <Box>
            <Typography variant="h1" sx={{ color: "primary.dark" }}>
              Reports
            </Typography>
          </Box>
          {/* User Options */}
          <UserOptions />
        </Box>
        <ReportInfoContext.Provider value={reportInfo}>
          <Tabs
            value={location.pathname}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Report View Navigation Tabs"
            sx={{ width: "100%", backgroundColor: "neutralSecondary.main", }}
          >
            <Tab
              label="Reports by Property"
              value={`/reportList/property`}
              component={Link}
              to={`/reportList/property`}
              className={"mainTopTab"}
            />
            <Tab
              label="Report by Properties"
              value={`/reportList/properties`}
              component={Link}
              to={`/reportList/properties`}
              className={"mainTopTab"}
            />
          </Tabs>
          <Box className={classes.mainTopTabPanel}>
            <Outlet />
          </Box>
        </ReportInfoContext.Provider>
      </Box>
    </React.Fragment>
  );
};

export default Reporting;
