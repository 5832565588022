import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import {
  AccountIdentifierHeaderName,
  useAuthorizedApiAxios,
} from "../../../../shared/api/authorizedAxios";

import { PostLoansForm, PutLoansForm } from "../model/LoansForm";

export const useCreateLoan = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((loan: PostLoansForm) => {
    const source = axios.CancelToken.source();
    const url: string = `assetService/v1/properties/${loan.propertyId}/loans`;
    const loanDetails = { ...loan };
    delete loanDetails.accountIdentifier;

    let promise = authorizedApiAxios().post<string>(url, loanDetails, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: loan.accountIdentifier!,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};

export const useUpdateLoan = () => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useMutation((loan: PutLoansForm) => {
    const source = axios.CancelToken.source();
    const url: string = `assetService/v1/properties/${loan.propertyId}/loans/${loan.loanId}`;

    let promise = authorizedApiAxios().put<string>(url, loan, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: loan.accountIdentifier,
      },
    });

    (promise as any).cancel = () => source.cancel();
    return promise;
  });
};
