import React, {
  forwardRef,
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { CircularProgress, Typography } from "@mui/material";
import { Box, Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { UilExport } from "@iconscout/react-unicons";
import { AgGridReact } from "ag-grid-react";
import {
  ColumnApi,
  GridApi,
  GridReadyEvent,
  FirstDataRenderedEvent,
} from "ag-grid-community";
import { useQueryClient } from "@tanstack/react-query";
import { UilUsdCircle } from "@iconscout/react-unicons";
import { UilPlusCircle } from "@iconscout/react-unicons";

// import LoanSummary from "./LoanSummary";
import { usePropertyInfo } from "../../util/propertyInfoContext";
import { useDeleteLoan, useGetLoans } from "../api/loansService";
import LoansFormModal from "./LoansFormModal";
import LoansContext from "../context/LoansContext";
import {
  getLoansExportColumns,
  getNewDefaultLoan,
  getPropertyLoansColumns,
} from "../util/propertyViewLoans";
import EditLoanRenderer from "./EditLoanRenderer";
import { getStatusPanels } from "../../leasing activity/util/gridTable";
import {
  GridConfigIds,
  PageConfigIds,
} from "../../../../constants/GridPageConfigurationIds";
import { useGetGridConfig } from "../../../../shared/api/pageConfigService";
import SaveGridStateOptions, {
  GRID_CONFIG_DEFAULT_VIEW,
} from "../../../../shared/view/SaveGridStateOptions";
import PortfolioContext from "../../../portfolio/util/portfolioContext";
import { hasSpecificPermission } from "../../../portfolio/util/accountPermissions";
import { EAccountPermission } from "../../../usermanagement/model/editUserModel";
import { dummyLoans } from "../util/dummyLoans";
import SimpleAlert from "../../../UI/view/SimpleAlert";
import DatePicker from "react-datepicker";
import { UilCalender } from "@iconscout/react-unicons";
import RentRollContext from "../../rent roll/context/RentRollContext";
import { LoanDeleteProps, LoansMonthYearFilter } from "../model/LoansModel";
import LoanStatusRenderer from "./LoanStatusRenderer";
import ConfirmDialog from "../../../../shared/view/confirmationDialog";

const grayStripStyles = {
  width: "110px",
  backgroundColor: "#A6B0BE",
  borderRadius: "4px",
  height: "12px",
};

interface Props {
  value?: string;
  onClick?: () => void;
}

const PropertyViewLoans: React.FC<Props> = (props: Props) => {
  const {
    hedgeInstrumentTypeSelectList,
    updateHedgeInstrumentTypeSelectList,
    hedgeRateTypeSelectList,
    updateHedgeRateTypeSelectList,
    interestRateBasisTypeSelectList,
    updateInterestRateBasisTypeSelectList,
    interestRateTypeSelectList,
    updateInterestRateTypeSelectList,
    kpiFinancialMeasureTypeSelectList,
    updateKpiFinancialMeasureTypeSelectList,
    loanTypeSelectList,
    updateLoanTypeSelectList,
    yesNoSelectList,
    updateYesNoSelectList,
    activeInactiveSelectList,
    updateActiveInactiveSelectList,
    noticeDateTypeSelectList,
    updateNoticeDateTypeSelectList,

    usStatesList,
    updateUsStatesList,
  } = useContext(LoansContext);

  const { accountPermissions } = useContext(PortfolioContext);
  const { loanDate, updateLoanDate } = useContext(RentRollContext);

  const queryClient = useQueryClient();
  const propertyInfo = usePropertyInfo();
  const accountId = propertyInfo.accountIdentifier;

  const [monthFilter, setMonthFilter] = useState<LoansMonthYearFilter>({
    year: loanDate.getFullYear(),
    month: loanDate.getMonth() + 1,
  });
  const { data: loansData, isSuccess } = useGetLoans({
    propertyId: propertyInfo.id,
    accountIdentifier: accountId,
    monthYearFilter: monthFilter,
  });

  const getMaxDate = (minDate: Date, maxDate?: Date) => {
    if (maxDate === undefined) return minDate;
    return Date.parse(maxDate.toString()) > Date.parse(new Date().toString())
      ? new Date()
      : new Date(maxDate);
  };

  const noLoans = isSuccess && loansData?.loans.length === 0;

  const [openDialog, setOpenDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState({
    deleteLoan: false,
    loanId: "",
  });

  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [toaster, setToaster] = useState({
    showToaster: false,
    message: "",
  });

  const hasLoanPermission = useMemo(
    () =>
      hasSpecificPermission(
        accountPermissions,
        accountId,
        EAccountPermission.CONFIGURE_LOANS
      ),
    [accountPermissions, accountId]
  );

  const refreshLoans = useCallback(() => {
    const uniqueId = propertyInfo.id + monthFilter.month + monthFilter.year;
    queryClient.invalidateQueries(["getAllLoans", uniqueId]);
  }, [propertyInfo.id, monthFilter.month, monthFilter.year, queryClient]);

  const columnDefs = useMemo(
    () =>
      getPropertyLoansColumns(
        hasLoanPermission,
        (loanId: string) => {
          queryClient.invalidateQueries([
            "getLoan",
            loanId + accountId + propertyInfo.id,
          ]);
          refreshLoans();
          setToaster({
            showToaster: true,
            message: "Your loan has been successfully edited!",
          });
        },
        (loanId: string) =>
          setConfirmDelete({ deleteLoan: true, loanId: loanId })
      ),
    [hasLoanPermission, accountId, propertyInfo.id, queryClient, refreshLoans]
  );
  const statusBar = useMemo(() => getStatusPanels(), []);

  const aggFuncs = {
    // this overrides the grids built-in sum function
    list: (values: any) => {
      let listOfItems = "";

      values.forEach((value: any) => (listOfItems += value + ", "));
      listOfItems = listOfItems.replace(/,\s*$/, "");

      return listOfItems;
    },
    distinctcount: (values: any) => {
      const testCnt: string[] = [];
      values.forEach((value: any) => {
        testCnt.push(value);
      });
      let test = new Set(testCnt);
      return test.size;
    },
  };

  /**
   * exports the data that is rendered in the AG grid.
   */
  const onExport = () => {
    const params = {
      columnWidth: 100,
      fileName: "Loans - Properties",
      exportMode: undefined,
      suppressTextAsCDATA: false,
      rowHeight: undefined,
      headerRowHeight: undefined,
      columnKeys: getLoansExportColumns(),
    };
    gridApi?.exportDataAsExcel(params);
  };

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
    setGridColumnApi(event.columnApi);
    event.api.hideOverlay();
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    setTimeout(() => event.columnApi.autoSizeAllColumns(), 500);// TODO remove setTimeout when Ag-grid fixes autoSizeColumns on React 18 
  };

  useEffect(() => {
    const exitCondition =
      hedgeInstrumentTypeSelectList.length !== 0 &&
      hedgeRateTypeSelectList.length !== 0 &&
      interestRateBasisTypeSelectList.length !== 0 &&
      interestRateTypeSelectList.length !== 0 &&
      kpiFinancialMeasureTypeSelectList.length !== 0 &&
      loanTypeSelectList.length !== 0 &&
      yesNoSelectList.length !== 0 &&
      activeInactiveSelectList.length !== 0 &&
      noticeDateTypeSelectList.length !== 0 &&
      usStatesList.length !== 0;

    if (!isSuccess || loansData === undefined || exitCondition) return;
    updateHedgeInstrumentTypeSelectList(
      loansData.hedgeInstrumentTypeSelectList
    );
    updateHedgeRateTypeSelectList(loansData.hedgeRateTypeSelectList);
    updateInterestRateBasisTypeSelectList(
      loansData.interestRateBasisTypeSelectList
    );
    updateInterestRateTypeSelectList(loansData.interestRateTypeSelectList);
    updateKpiFinancialMeasureTypeSelectList(
      loansData.kpiFinancialMeasureTypeSelectList
    );
    updateLoanTypeSelectList(loansData.loanTypeSelectList);
    updateNoticeDateTypeSelectList(loansData.noticeDateTypeSelectList);
    updateYesNoSelectList(loansData.yesNoSelectList);
    updateActiveInactiveSelectList(loansData.activeInactiveSelectList);

    updateUsStatesList(loansData.usStatesList);

    const newCurrentDate = getMaxDate(
      new Date(loansData.minDate),
      new Date(loansData.maxDate)
    );
    updateLoanDate(newCurrentDate);
    setMonthFilter({
      month: newCurrentDate.getMonth() + 1,
      year: newCurrentDate.getFullYear(),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, loansData]);

  // Grid Config state and methods

  const [gridView, setGridView] = useState(GRID_CONFIG_DEFAULT_VIEW);

  const gridId = GridConfigIds.PROPERTY_LOANS_GRID;
  const pageId = PageConfigIds.PROPERTY_LOANS_GRID;

  const deleteLoan = useDeleteLoan();
  const gridConfigsQuery = useGetGridConfig({
    pageId: pageId,
    gridIdentifier: gridId,
    accountId: accountId,
  });

  /**
   * Sets the context that is retrieved from the backend whenever the gridView is changed.
   * gridView is either changed when data first loads (only if a default value exists) or when the user uses the dropdown
   */
  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1 ||
      gridApi === undefined ||
      gridColumnApi === undefined
    )
      return;

    if (gridView === GRID_CONFIG_DEFAULT_VIEW) {
      gridApi.setFilterModel({});
      gridColumnApi.resetColumnState();
      return;
    }

    const selectedGridConfig = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.id === Number(gridView)
    );

    if (selectedGridConfig === undefined) return;

    gridApi.setFilterModel(JSON.parse(selectedGridConfig.columnFilterState));
    gridColumnApi.applyColumnState({
      state: JSON.parse(selectedGridConfig.columnState),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridView, gridApi, gridColumnApi]);

  const saveAsView = React.useRef("");

  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1
    ) {
      if (gridView !== GRID_CONFIG_DEFAULT_VIEW)
        setGridView(GRID_CONFIG_DEFAULT_VIEW);
      return;
    }

    const isSaveAsRender = saveAsView.current.trim().length > 0;

    if (isSaveAsRender) {
      const savedAsView = gridConfigsQuery.data.gridConfigurations.find(
        (gridConfig) =>
          gridConfig.name.toLowerCase().trim() ===
          saveAsView.current.toLowerCase().trim()
      );
      if (savedAsView) setGridView(savedAsView.id.toString());
      saveAsView.current = "";
      return;
    }

    const defaultView = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.isDefault
    );
    if (
      defaultView &&
      gridView !== defaultView.id.toString() &&
      gridView === GRID_CONFIG_DEFAULT_VIEW
    )
      setGridView(defaultView.id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridConfigsQuery.isSuccess, gridConfigsQuery.data]);

  type Props = {
    onClick?: () => void;
    value?: string;
  };
  type RefType = number;
  const CustomDatePickerInput = forwardRef<RefType, Props>(
    ({ onClick, value }, ref) => (
      <Box className={"react-datepicker-input"} ref={ref} onClick={onClick}>
        {value}
        <Box className={"react-datepicker-icon"}>
          <UilCalender />
        </Box>
      </Box>
    )
  );

  const onDeleteLoan = () => {
    const model: LoanDeleteProps = {
      accountId: accountId,
      loanId: confirmDelete.loanId,
    };

    deleteLoan.mutate(model, {
      onSuccess: () => {
        refreshLoans();
        setToaster({
          showToaster: true,
          message: "Your loan was successfully deleted.",
        });
      },
    });
  };

  const _getTable = (noLoansLoaded: boolean) => {
    const rowData = noLoansLoaded ? dummyLoans : loansData?.loans;

    return (
      <div
        id="property-loans-content"
        className={`tab-content ${noLoans ? "top-element" : ""}`}
      >
        <div
          className="table-standard-toggle-container"
          style={{ height: "100%", width: "100%" }}
        >
          <div
            id="property-loans-content"
            className="example-wrapper"
            style={{ height: "100%", width: "100%" }}
          >
            <div
              id="myGrid"
              className="ag-theme-alpine"
              style={{ height: "100%", width: "100%" }}
            >
              {confirmDelete.deleteLoan && (
                <ConfirmDialog
                  title="Delete Loan?"
                  open={confirmDelete.deleteLoan}
                  setOpen={(open: boolean) =>
                    setConfirmDelete((prevDel) => ({
                      deleteLoan: open,
                      loanId: "",
                    }))
                  }
                  onConfirm={onDeleteLoan}
                >
                  Are you sure you want to delete this loan?
                </ConfirmDialog>
              )}
              <AgGridReact
                onGridReady={onGridReady}
                tooltipShowDelay={500}
                columnDefs={columnDefs}
                rowData={rowData}
                onFirstDataRendered={onFirstDataRendered}
                suppressAggFuncInHeader
                aggFuncs={aggFuncs}
                statusBar={statusBar}
                enableRangeSelection={true}
                autoGroupColumnDef={{ minWidth: 100 }}
                sortingOrder={["desc", "asc"]}
                defaultColDef={{
                  minWidth: 100,
                  resizable: true,
                  filterParams: {
                    buttons: ["apply", "reset"],
                    closeOnApply: true,
                  },
                }}
                components={{
                  editLoan: EditLoanRenderer,
                  loanStatusRenderer: LoanStatusRenderer,
                }}
                excelStyles={[
                  {
                    id: "alignRight",
                    alignment: {
                      horizontal: "Right",
                    },
                  },
                  {
                    id: "dateFormat",
                    dataType: "DateTime",
                    numberFormat: { format: "mm/dd/yyyy;@" },
                  },
                  {
                    id: "currencyFormat",
                    numberFormat: { format: "\u0024 #,##0.00" },
                  },
                  {
                    id: "decimalPlaces",
                    numberFormat: { format: "#,##0.00" },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {!isSuccess ? (
        <Box m="auto">
          <CircularProgress />
        </Box>
      ) : (
        <React.Fragment>
          {openDialog && (
            <LoansFormModal
              openDialog={openDialog}
              closeDialog={(isLoanSaved?: boolean) => {
                setOpenDialog(false);
                if (isLoanSaved) {
                  refreshLoans();
                  setToaster({
                    showToaster: true,
                    message: "Your loan has been successfully added!",
                  });
                }
              }}
              defaultLoanFormValues={getNewDefaultLoan()}
              newLoan={true}
            />
          )}
          {isSuccess && !!loansData && (
            <div
              id="property-loans"
              className="tab-pane fade show active"
              role="tabpanel"
              aria-labelledby="property-tab-loans"
            >
              {/* {loansData !== undefined && (
              <LoanSummary loansSummary={loansData.loansSummary} />
            )} */}
              {toaster.showToaster && (
                <SimpleAlert
                  severityType="success"
                  onClose={() => setToaster({ showToaster: false, message: "" })}
                  message={toaster.message}
                  styles={{ position: "relative", display: "block", width: "100%", }}
                />
              )}
              <Grid container className="property-action-bar">
                <Grid item>
                  <Grid container sx={{ width: "auto" }}>
                    <Grid item>
                      <Box className={"datepicker-outer-container"}>
                        <Typography
                          variant="body3"
                          component="label"
                          className={"input-label"}
                        >{`Select date:`}</Typography>
                        <Box className={"datepicker-container"}>
                          <DatePicker
                            selected={new Date(loanDate)}
                            onChange={(date: Date) => {
                              setMonthFilter({
                                month: date.getMonth() + 1,
                                year: date.getFullYear(),
                              });
                              updateLoanDate(date);
                            }}
                            dateFormat="MMMM yyyy"
                            showMonthYearPicker
                            //showFullMonthYearPicker
                            //showTwoColumnMonthYearPicker
                            minDate={new Date(loansData.minDate)}
                            maxDate={getMaxDate(
                              new Date(loansData.minDate),
                              new Date(loansData.maxDate)
                            )}
                            //popperPlacement="auto"
                            popperModifiers={[
                              {
                                name: "preventOverflow",
                                options: {
                                  altAxis: true,
                                },
                              },
                              {
                                name: "offset",
                                options: {
                                  offset: [-13, 0],
                                },
                              },
                            ]}
                            customInput={<CustomDatePickerInput {...props} />}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container sx={{ width: "auto" }}>
                    <Grid item>
                      {hasLoanPermission && (
                        <Button
                          variant="contained"
                          size="small"
                          className={"btn-primary"}
                          sx={{ marginTop: "21px" }}
                          onClick={() => setOpenDialog(true)}
                        >
                          <AddIcon fontSize="small" />
                          {"Add Loan"}
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      <SaveGridStateOptions
                        isSuccess={gridConfigsQuery.isSuccess}
                        gridView={gridView}
                        handleGridView={(newVal: string) => {
                          setGridView(newVal);
                        }}
                        gridConfigData={gridConfigsQuery.data}
                        gridApi={gridApi}
                        gridColumnApi={gridColumnApi}
                        gridId={gridId}
                        pageId={pageId}
                        accountId={accountId}
                        invalidateGridQuery={() =>
                          queryClient.invalidateQueries([
                            "getGridConfig",
                            pageId + gridId + accountId,
                          ])
                        }
                        updateSaveAsViewName={(name: string) => {
                          saveAsView.current = name;
                        }}
                        updateGridView={() =>
                          setGridView(GRID_CONFIG_DEFAULT_VIEW)
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        className={"btn-primary"}
                        sx={{ marginTop: "21px" }}
                        onClick={onExport}
                      >
                        <UilExport /> Export
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {noLoans ? (
                <div className={"parent-element"}>{_getTable(noLoans)}</div>
              ) : (
                <React.Fragment>{_getTable(noLoans)}</React.Fragment>
              )}
              {noLoans && (
                <Box className="bottom-element">
                  <Box
                    sx={{
                      margin: "auto",
                      width: "270px",
                      backgroundColor: "white",
                      borderRadius: "4px",
                      marginBottom: "20px",
                      padding: "12px 8px",
                      boxShadow: "0 0 4px lightgray",
                    }}
                  >
                    <Box display="flex" justifyContent="space-between">
                      <Box sx={grayStripStyles} />
                      <UilUsdCircle color="#418FFB" size="20" />
                    </Box>
                    <Box
                      sx={{
                        ...grayStripStyles,
                        width: "150px",
                      }}
                    />
                  </Box>
                  <Typography variant="h2" sx={{ textAlign: "center" }}>
                    This property doesn't have any loans yet.
                  </Typography>
                  <Button
                    color="inherit"
                    sx={{
                      width: "160px",
                      marginTop: "12px",
                      boxShadow: "0 0 4px lightgray",
                    }}
                    onClick={() => setOpenDialog(true)}
                  >
                    <UilPlusCircle />
                    Add New Loan
                  </Button>
                </Box>
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PropertyViewLoans;
