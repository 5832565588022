import React, { useState, useContext, useEffect } from "react";
import {
  GridReadyEvent,
  ColumnApi,
  ColDef,
  SortChangedEvent,
  FilterChangedEvent,
  FirstDataRenderedEvent,
} from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { Box, CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

import { useDeleteUser, useGetUserList } from "../api/userService";
import { TUserListFilter } from "../model/userListFilter";
import { TUserList } from "../model/userListModel";
import DeleteUserRenderer from "./deleteUserRenderer";
import ConfirmDialog from "../../../shared/view/confirmationDialog";
import { TDeleteUserModel } from "../model/deleteUserModel";
import UserContext from "./../util/userContext";
import UserViewLink from "./UserView";
import { statusMapper } from "../util/userGrid";
import { stringSortCaseInsensitive } from "../../properties/util/gridUtils";

interface UserListProps {
  filter: TUserListFilter;
  accountIdentifier: string;
  handleEmailList: (userList: string[] | undefined) => void;
}

const UserList: React.FC<UserListProps> = (props) => {
  const {
    data: userList,
    isLoading: isUserListLoading,
    isSuccess,
  } = useGetUserList(props.filter);

  useEffect(() => {
    if (userList === undefined || !isSuccess) return;

    let emailList = userList.map(function (el) {
      return el.email;
    });
    props.handleEmailList(emailList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList, isSuccess]);

  return isUserListLoading ? (
    <Box m="auto">
      <CircularProgress />
    </Box>
  ) : (
    <React.Fragment>
      <UserListMain
        userList={userList}
        accountIdentifier={props.accountIdentifier}
      />
    </React.Fragment>
  );
};

interface UserListMainProps {
  userList?: TUserList;
  accountIdentifier: string;
}

const UserListMain: React.FC<UserListMainProps> = (props) => {
  const {
    userFilterModel,
    userColumnState,
    updateUserFilterModel,
    updateUserColumnState,
  } = useContext(UserContext);

  const rows = props.userList;

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [deleteUserId, setDeleteUserId] = useState<string>();
  const [deleteUserDescription, setDeleteUserDescription] = useState<string>();

  const queryClient = useQueryClient();

  const columnDefs = React.useMemo(() => {
    const defs: ColDef[] = [
      {
        headerName: "Email",
        field: "email",
        cellRenderer: "linkComponent",
        comparator: stringSortCaseInsensitive,
        filter: "agTextColumnFilter",
        // comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        //   const valueALower = valueA.toLowerCase();
        //   const valueBLower = valueB.toLowerCase();
        //   if (valueALower === valueBLower) return 0;
        //   return valueALower > valueBLower ? 1 : -1;
        // },
      },
      {
        headerName: "Full Name",
        field: "fullName",
        comparator: stringSortCaseInsensitive,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Status",
        field: "status",
        comparator: stringSortCaseInsensitive,
        cellRenderer: (params: any) => {
          return (
            <div>
              <span
                style={{
                  padding: "3px 10px",
                  background: "lightgrey",
                  borderRadius: "5px",
                }}
              >
                {statusMapper(params.data.status)}
              </span>
            </div>
          );
        },
      },
      {
        headerName: "Actions",
        field: "id",
        cellRenderer: "deleteUserRenderer",
        cellRendererParams: {
          accountId: props.accountIdentifier,
          clicked: (id: string, desc: string) => {
            handleDeleteUserClicked(id, desc);
          },
        },
        sortable: false,
        filter: false,
      },
    ];

    return defs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteUserResponse = useDeleteUser();

  const handleDeleteUserClicked = (userId: string, userDescription: string) => {
    setDeleteUserId(userId);
    setDeleteUserDescription(userDescription);
    setConfirmOpen(true);
  };

  const deleteUser = () => {
    const model: TDeleteUserModel = {
      accountIdentifier: props.accountIdentifier,
      userId: deleteUserId || "",
    };

    deleteUserResponse.mutate(model, {
      onSettled: (data) => {
        queryClient.invalidateQueries([
          "getUserList",
          props.accountIdentifier,
          "",
        ]);
      },
    });
  };

  const onGridReady = (event: GridReadyEvent) => {
    if (rows === undefined || rows.length < 1) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }
  };

  const onSaveGridColumnState = (columnApi: ColumnApi) => {
    const columnState = columnApi.getColumnState();
    updateUserColumnState(columnState || []);
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    // Auto sizing columns to fit the whole screen
    event.api.sizeColumnsToFit();

    if (Object.keys(userFilterModel).length !== 0)
      event.api.setFilterModel(userFilterModel);
    if (Object.keys(userColumnState).length !== 0)
      event.columnApi.applyColumnState({ state: userColumnState });
  };

  const onFilterChanged = (event: FilterChangedEvent) => {
    const filterModel = event.api.getFilterModel();
    updateUserFilterModel(filterModel);
  };

  const onSortChanged = (event: SortChangedEvent) => {
    const columnState = event.columnApi.getColumnState();
    updateUserColumnState(columnState || []);
  };

  return (
    <div id="user-main-content" className="grid-container">
      <div
        id="userGrid"
        className="ag-theme-alpine ag-theme-alpine-container-override"
      >
        <ConfirmDialog
          title="Delete User?"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={deleteUser}
        >
          Are you sure you want to delete
          <br />'{deleteUserDescription}'?
        </ConfirmDialog>
        <AgGridReact
          onGridReady={onGridReady}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          onColumnVisible={(event) => onSaveGridColumnState(event.columnApi)}
          onColumnPinned={(event) => onSaveGridColumnState(event.columnApi)}
          onColumnResized={(event) => onSaveGridColumnState(event.columnApi)}
          onColumnMoved={(event) => onSaveGridColumnState(event.columnApi)}
          onColumnRowGroupChanged={(event) =>
            onSaveGridColumnState(event.columnApi)
          }
          onColumnValueChanged={(event) =>
            onSaveGridColumnState(event.columnApi)
          }
          onColumnPivotChanged={(event) =>
            onSaveGridColumnState(event.columnApi)
          }
          onFirstDataRendered={onFirstDataRendered}
          rowData={rows}
          columnDefs={columnDefs}
          components={{
            deleteUserRenderer: DeleteUserRenderer,
            linkComponent: UserViewLink,
          }}
          sortingOrder={["desc", "asc"]}
          defaultColDef={{
            filter: true,
            resizable: true,
            sortable: true,
            filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
          }}
        />
      </div>
    </div>
  );
};

export default UserList;
