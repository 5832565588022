import React from "react";
import { Box, Link } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { GridReadyEvent, GridApi, ColumnApi, ColDef } from "ag-grid-community";
import "ag-grid-enterprise";

import { IExpirationScheduleRsf } from "../model/portfolioLeasingActivity";
import { usePortfolioInfo } from "../../util/portfolioInfoContext";
import { getPortfolioExpirationScheduleColumns } from "../utils/portfolioExpirationSchedule";

interface PortfolioExpirationScheduleProps {
  tableContent: IExpirationScheduleRsf[];
  gridApi?: GridApi;
  gridColumnApi?: ColumnApi;
  onGridRender: (gridApi: GridApi, gridColumnApi: ColumnApi) => void;
  columnNames: string[];
  totals: IExpirationScheduleRsf[];
  leasingActivityDate: Date;
}

const PortfolioExpirationScheduleGrid: React.FC<
  PortfolioExpirationScheduleProps
> = (props) => {
  const { tableContent, totals, columnNames, onGridRender } = props;
  const portfolioInfo = usePortfolioInfo();
  const year = props.leasingActivityDate.getFullYear();
  const month = props.leasingActivityDate.getMonth() + 1;

  const columnDefs: ColDef[] =
    getPortfolioExpirationScheduleColumns(columnNames);

  const onGridReady = (event: GridReadyEvent) => {
    onGridRender(event.api, event.columnApi);

    if (tableContent === undefined || tableContent.length === 0) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }

    props.gridApi?.sizeColumnsToFit();
  };

  const LinkComponent = (props: any) => {
    const propertyId = props.data?.propertyId;
    const isTotals = String(props.value)
      .toUpperCase()
      .trim()
      .localeCompare("TOTALS");
    return (
      <React.Fragment>
        {isTotals === 0 ? (
          props.value
        ) : (
          <Link
            target="_blank"
            href={`/propertyView/activity?propertyId=${propertyId}&accountIdentifier=${portfolioInfo.accountIdentifier}&year=${year}&month=${month}`}
          >
            {props.value}
          </Link>
        )}
      </React.Fragment>
    );
  };

  return (
    <Box
      className="ag-theme-alpine ag-theme-alpine-container-override"
      sx={{
        height: 302,
        width: "100%",
        "& .ag-root-wrapper.ag-layout-normal": {
          borderRadius: "0 0 6px 6px",
        },
        "& .ag-root .ag-row-hover": {
          background: "inherit",
          cursor: "text",
        },
        "& .ag-ltr .ag-has-focus .ag-row-focus .ag-cell-focus:not(.ag-cell-range-selected)":
          {
            borderColor: "transparent",
            outline: "none",
          },
        "& .ag-root .ag-floating-bottom": {
          borderTop: "1px solid var(--neutral-grey-150)",
          overflow: "hidden !important",
        },
        "& .ag-root .ag-floating-bottom .ag-row": {
          borderBottom: 0,
        },
        "& .ag-root .ag-floating-bottom .ag-cell": {
          fontWeight: "bold",
          textTransform: "uppercase",
        },
        "& .ag-root .ag-floating-bottom .ag-cell.ag-right-aligned-cell": {
          fontWeight: "normal",
        },
        "& .ag-root .ag-floating-bottom .ag-cell.alignRight": {
          fontWeight: "normal",
        },
        "& .ag-root-wrapper .ag-status-bar": {
          border: 0,
        },
        "& .alignRight": {
          textAlign: "right",
        },
        "& .alignRight .ag-cell-wrapper": {
          flexDirection: "row-reverse",
        },
      }}
    >
      <AgGridReact
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        rowData={tableContent}
        autoGroupColumnDef={{ minWidth: 100 }}
        sortingOrder={["desc", "asc"]}
        pinnedBottomRowData={totals}
        components={{
          linkComponent: LinkComponent,
        }}
        excelStyles={[
          {
            id: "alignRight",
            alignment: {
              horizontal: "Right",
            },
          },
          {
            id: "currencyFormat",
            numberFormat: { format: "\u0024 #,##0.00" },
          },
          {
            id: "decimalPlaces",
            numberFormat: { format: "#,##0.00" },
          },
        ]}
      />
    </Box>
  );
};

export default PortfolioExpirationScheduleGrid;
