export const messageErrorMessage = "Maximum length is 250";

export const validateMessage = (message: string) => {
    //parse out markup and id before calculating length
    let strippedMsg = message
      .replace(/~~{[^~~{/]*~~}/g, "")
      .replace(/~~\(/g, "")
      .replace(/~~\)/g, "");
    // console.log("stripped mesage length: " + strippedMsg.length);
    return strippedMsg.length <= 250;
  };
