import { Property } from "../../assetmanagent/property/model/property";
import { numberWithCommas } from "../../properties/util/gridUtils";
import {
  IFrequencySelectList,
} from "../model/alertsModel";

export const getDisplayProperties = (
  properties: Property[],
  propertyIdList: string[]
) => {
  const propertyListWithoutAllOption = propertyIdList.filter(
    (propertyId) => propertyId !== "all"
  );
  let name = "[property]";
  if (propertyListWithoutAllOption.length > 0) {
    const property = properties.find(
      (property) => property.id === propertyListWithoutAllOption[0]
    );
    if (property) name = property.title;
  }
  if (propertyListWithoutAllOption.length > 1) name = name + "(...)";

  return name;
};

export const getDisplayAmount = (amount: number) => {
  const dollarAmount = numberWithCommas(amount);
  if (dollarAmount !== undefined && !isNaN(amount)) return `${dollarAmount}`;
  else return "[amount]";
};

export const getDisplayFrequency = (
  frequencySelectList: IFrequencySelectList[],
  frequencyId: number
) => {
  // if the selected option's key is -1, then its "select option"
  let frequencyName: string | undefined = "[frequency]";
  if (frequencyId === -1) return frequencyName;

  frequencyName = frequencySelectList.find(
    (frequencyItem) => frequencyItem.key === frequencyId
  )?.value;
  if (frequencyName) return frequencyName.toLowerCase();
  else return frequencyName;
};
