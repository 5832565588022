import React from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  ColDef,
  ColumnState,
  FirstDataRenderedEvent,
  GridReadyEvent,
} from "ag-grid-community";

import {
  dateFilterParams,
  dateValueFormatter,
  dateValueGetter,
  stringSortCaseInsensitive,
} from "../../../properties/util/gridUtils";
import GLAccountTreeActionsRenderer from "./GLAccountTreeActionsRenderer";
import {
  IGlAccountTree,
  IGLTreeAccountingSystemSelectList,
  IGLTreeTypeSelectList,
} from "../../model/glTreeModel";

type Props = {
  rows: IGlAccountTree[] | undefined;
  accountId: string;
  glAccountTreeTypes: IGLTreeTypeSelectList[];
  glAccountingSystems: IGLTreeAccountingSystemSelectList[];
  handleClose: (shouldRefresh: boolean) => void;
  onUploaded?: (msg: any) => void;
  onError?: (msg: any) => void;
  onErrorDownload?: () => void;
};

const GLAccountTreeGrid: React.FC<Props> = (props) => {
  const {
    rows,
    accountId,
    glAccountTreeTypes,
    glAccountingSystems,
    handleClose,
    onUploaded,
    onError,
    onErrorDownload,
  } = props;

  const columnDefs = React.useMemo(() => {
    const defs: ColDef[] = [
      {
        headerName: "Title",
        field: "glAccountTreeName",
        comparator: stringSortCaseInsensitive,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "System",
        comparator: stringSortCaseInsensitive,
        field: "accountingSystemTitle",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "SystemId",
        field: "accountingSystemId",
        hide: true,
      },
      {
        headerName: "TreeTypeId",
        field: "glAccountTreeTypeId",
        hide: true,
      },
      {
        headerName: "Created",
        field: "createdAt",
        cellClass: ["textAlignRight", "dateFormat"],
        sortable: true,
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        valueGetter: (params) => dateValueGetter(params.data.createdAt),
        valueFormatter: (params) => dateValueFormatter(params.data.createdAt),
        minWidth: 50,
      },
      {
        headerName: "Updated",
        field: "lastModifiedAt",
        cellClass: ["textAlignRight", "dateFormat"],
        sortable: true,
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        valueGetter: (params) => dateValueGetter(params.data.lastModifiedAt),
        valueFormatter: (params) =>
          dateValueFormatter(params.data.lastModifiedAt),
        minWidth: 50,
      },
      {
        headerName: "Actions",
        field: "id",
        cellRenderer: "actionsRenderer",
        cellRendererParams: {
          accountId: accountId,
          glAccountTreeTypes: glAccountTreeTypes,
          glAccountingSystems: glAccountingSystems,
          handleClose: handleClose,
          onUploaded: onUploaded,
          onError: onError,
          onErrorDownload: onErrorDownload,
        },
        sortable: false,
        filter: false,
      },
    ];
    return defs;
  }, [
    accountId,
    glAccountTreeTypes,
    glAccountingSystems,
    handleClose,
    onUploaded,
    onError,
    onErrorDownload,
  ]);

  const onGridReady = (event: GridReadyEvent) => {
    if (rows === undefined || rows.length < 1) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    // Auto sizing columns to fit the whole screen
    event.api.sizeColumnsToFit();

    var defaultSortModel: ColumnState[] = [
      { colId: "accountingSystemTitle", sort: "asc", sortIndex: 0 },
      { colId: "glAccountTreeName", sort: "asc", sortIndex: 1 },
    ];
    event.columnApi.applyColumnState({ state: defaultSortModel });
  };

  return (
    <div id="glaccounttree-main-content" className="grid-container">
      <div
        id="glaccounttreeGrid"
        className="ag-theme-alpine ag-theme-alpine-container-override"
      >
        <AgGridReact
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          rowData={rows}
          columnDefs={columnDefs}
          components={{
            actionsRenderer: GLAccountTreeActionsRenderer,
          }}
          sortingOrder={["desc", "asc"]}
          defaultColDef={{
            filter: true,
            resizable: true,
            sortable: true,
            filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
          }}
        />
      </div>
    </div>
  );
};

export default GLAccountTreeGrid;
