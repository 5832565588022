import React from "react";
import { Box, Checkbox } from "@mui/material";
import { IHeaderParams } from "ag-grid-community";

import { NegateActualBudgetContext } from "./NegateActualBudgetContext";

const NegateBudgetCustomHeader: React.FC<IHeaderParams> = (props) => {
  const {
    allNegateBudgetChecked,
    updateAllNegateBudgetChecked,
    updateLastColumnChecked,
    updateProcessAllBudgetChecked,
  } = React.useContext(NegateActualBudgetContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateLastColumnChecked(props.column?.getColId());
    updateProcessAllBudgetChecked(true);
    updateAllNegateBudgetChecked(event.target.checked);
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
        <Checkbox
          name="budgetheader"
          checked={allNegateBudgetChecked.checked}
          onChange={handleChange}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          className="grid-checkbox"
        />
      </Box>
      <Box>{props.displayName.toUpperCase()}</Box>
    </Box>
  );
};

export default NegateBudgetCustomHeader;
