import React, {
  useCallback,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";
import {
  Grid,
  Box,
  Button,
  CircularProgress,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DatePicker from "react-datepicker";
import { UilExport } from "@iconscout/react-unicons";
import { UilCalender } from "@iconscout/react-unicons";
import "react-datepicker/dist/react-datepicker.css";

import Financials from "./FinancialsGrid";
import { usePortfolioInfo } from "../../util/portfolioInfoContext";
import PortfoliosStateContext from "../../context/PortfoliosStateContext";
import { useGetPortfolioFinancialsData } from "../api/portfolioFinancialsService";
import FinancialsDropdown from "../../../properties/financials/views/FinancialsDropdown";
import {
  lastDayOfQuarter,
  lastDayOfMonth,
  lastDayOfYear,
  startOfQuarter,
  startOfMonth,
  startOfYear,
  addYears,
} from "date-fns";
import { GridApi, ColumnApi } from "ag-grid-community";
import { useQueryClient } from "@tanstack/react-query";
import {
  GridConfigIds,
  PageConfigIds,
} from "../../../../constants/GridPageConfigurationIds";
import { useGetGridConfig } from "../../../../shared/api/pageConfigService";
import SaveGridStateOptions, {
  GRID_CONFIG_DEFAULT_VIEW,
} from "../../../../shared/view/SaveGridStateOptions";

export interface FinancialsFilter {
  startYear: number;
  startMonth: number;
  endYear: number;
  endMonth: number;
  groupingMode: number;
  budgetView: boolean;
}

const PortfolioFinancials: React.FC = () => {
  const portfolioInfo = usePortfolioInfo();
  const {
    financialsStartDate,
    updateFinancialsStartDate,
    financialsEndDate,
    updateFinancialsEndDate,
    financialsGroupingMode,
    updateFinancialsGroupingMode,
    financialsBudgetView,
    updateFinancialsBudgetView,
  } = useContext(PortfoliosStateContext);

  const [financialsFilter, setFinancialsFilter] = useState<FinancialsFilter>({
    groupingMode: financialsGroupingMode,
    startYear: financialsStartDate.getFullYear(),
    startMonth: financialsStartDate.getMonth() + 1,
    endYear: financialsEndDate.getFullYear(),
    endMonth: financialsEndDate.getMonth() + 1,
    budgetView: financialsBudgetView,
  });

  const financialsQuery = useGetPortfolioFinancialsData({
    portfolioId: portfolioInfo.portfolioId,
    accountIdentifier: portfolioInfo.accountIdentifier,
    monthYearFilter: financialsFilter,
  });

  const startDateUpdated = useRef(false);
  const endDateUpdated = useRef(false);
  const filterUpdated = useRef(false);

  const { isFetching, data: financialsData } = financialsQuery;

  const getFormat = () => {
    switch (financialsGroupingMode) {
      case 0:
        return "MMM yyyy";
      case 1:
        return "QQQ yyyy";
      case 2:
        return "yyyy";
    }
  };

  const getRangeDate = useCallback((date: Date, groupingMode: number) => {
    switch (groupingMode) {
      case 0:
        return { start: startOfMonth(date), end: lastDayOfMonth(date) };
      case 1:
        return { start: startOfQuarter(date), end: lastDayOfQuarter(date) };
      case 2:
        return { start: startOfYear(date), end: lastDayOfYear(date) };
      default:
        throw new Error("Invalid grouping mode");
    }
  }, []);

  const getDates = useCallback(
    (date: Date, groupingMode: number) => {
      let dateRange = getRangeDate(date, groupingMode);
      let start = dateRange.start,
        end = dateRange.end;

      let minDate = getRangeDate(
          new Date(financialsData.minDate),
          groupingMode
        ),
        maxDate = getRangeDate(new Date(financialsData.maxDate), groupingMode);
      if (dateRange.start < minDate.start) start = minDate.start;
      if (dateRange.start > maxDate.end) start = minDate.end;
      if (dateRange.end > maxDate.end) end = maxDate.end;

      return { start, end };
    },
    [financialsData, getRangeDate]
  );

  const getMaxDate = (minDate: Date, maxDate?: Date) => {
    if (maxDate === undefined) return minDate;
    return Date.parse(maxDate.toString()) > Date.parse(new Date().toString())
      ? new Date()
      : new Date(maxDate);
  };

  const getMinDate = (minDate?: Date) => {
    if (minDate === undefined) return new Date();
    const oneYearBeforeMax = addYears(new Date(financialsEndDate), -1);
    return Date.parse(minDate.toString()) >
      Date.parse(new Date(oneYearBeforeMax).toString())
      ? new Date(minDate)
      : new Date(oneYearBeforeMax);
  };

  const updateStartDate = (minDate: Date, maxDate: Date) => {
    if (startDateUpdated.current === false) {
      const sDate = getMinDate(new Date(minDate));
      updateFinancialsStartDate(sDate);
      setFinancialsFilter({
        ...financialsFilter,
        startYear: sDate.getFullYear(),
        startMonth: sDate.getMonth() + 1,
      });
    }
    startDateUpdated.current = true;
  };

  const updateEndDate = (minDate: Date, maxDate: Date) => {
    if (endDateUpdated.current === false) {
      const eDate = getMaxDate(new Date(minDate), new Date(maxDate));
      updateFinancialsEndDate(eDate);
      setFinancialsFilter({
        ...financialsFilter,
        endYear: eDate.getFullYear(),
        endMonth: eDate.getMonth() + 1,
      });
    }
    endDateUpdated.current = true;
  };

  const updateFinancialsFilter = (minDate: Date, maxDate: Date) => {
    if (filterUpdated.current === false) {
      const eDate = getMaxDate(new Date(minDate), new Date(maxDate));
      const sDate = getMinDate(new Date(minDate));
      setFinancialsFilter({
        ...financialsFilter,
        startYear: sDate.getFullYear(),
        startMonth: sDate.getMonth() + 1,
        endYear: eDate.getFullYear(),
        endMonth: eDate.getMonth() + 1,
      });
    }
    filterUpdated.current = true;
  };

  useEffect(() => {
    if (!financialsQuery.isSuccess || financialsQuery.data === undefined)
      return;
    updateStartDate(financialsQuery.data.minDate, financialsQuery.data.maxDate);
    updateEndDate(financialsQuery.data.minDate, financialsQuery.data.maxDate);
    updateFinancialsFilter(
      financialsQuery.data.minDate,
      financialsQuery.data.maxDate
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialsQuery]);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | undefined>(
    undefined
  );

  /**
   * exports the data that is rendered in the AG grid.
   */
  const onExport = () => {
    const params = {
      columnWidth: 100,
      sheetName: "Financials data",
      fileName: "Portfolios - Financials",
      exportMode: undefined,
      suppressTextAsCDATA: false,
      rowHeight: undefined,
      headerRowHeight: undefined,
    };
    gridApi?.exportDataAsExcel(params);
  };

  // Grid Config state and methods

  const [gridView, setGridView] = useState(GRID_CONFIG_DEFAULT_VIEW);

  const gridId = financialsBudgetView
    ? GridConfigIds.PORTFOLIO_BUDGET_GRID
    : GridConfigIds.PORTFOLIO_FINANCIALS_GRID;
  const pageId = financialsBudgetView
    ? PageConfigIds.PORTFOLIO_BUDGET_GRID
    : PageConfigIds.PORTFOLIO_FINANCIALS_GRID;

  const accountId = portfolioInfo.accountIdentifier;

  const queryClient = useQueryClient();
  const gridConfigsQuery = useGetGridConfig({
    pageId: pageId,
    gridIdentifier: gridId,
    accountId: accountId,
  });

  /**
   * Sets the context that is retrieved from the backend whenever the gridView is changed.
   * gridView is either changed when data first loads (only if a default value exists) or when the user uses the dropdown
   */
  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1 ||
      gridApi === undefined ||
      gridColumnApi === undefined
    )
      return;

    if (gridView === GRID_CONFIG_DEFAULT_VIEW) {
      gridApi.setFilterModel({});
      gridColumnApi.resetColumnState();
      return;
    }

    const selectedGridConfig = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.id === Number(gridView)
    );

    if (selectedGridConfig === undefined) return;

    gridApi.setFilterModel(JSON.parse(selectedGridConfig.columnFilterState));
    gridColumnApi.applyColumnState({
      state: JSON.parse(selectedGridConfig.columnState),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridView, gridApi, gridColumnApi]);

  const saveAsView = useRef("");

  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1
    ) {
      if (gridView !== GRID_CONFIG_DEFAULT_VIEW)
        setGridView(GRID_CONFIG_DEFAULT_VIEW);
      return;
    }

    const isSaveAsRender = saveAsView.current.trim().length > 0;

    if (isSaveAsRender) {
      const savedAsView = gridConfigsQuery.data.gridConfigurations.find(
        (gridConfig) =>
          gridConfig.name.toLowerCase().trim() ===
          saveAsView.current.toLowerCase().trim()
      );
      if (savedAsView) setGridView(savedAsView.id.toString());
      saveAsView.current = "";
      return;
    }

    const defaultView = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.isDefault
    );
    if (defaultView && gridView !== defaultView.id.toString())
      setGridView(defaultView.id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridConfigsQuery.isSuccess, gridConfigsQuery.data]);

  return (
    <React.Fragment>
      {isFetching ? (
        <Box m="auto">
          <CircularProgress />
        </Box>
      ) : (
        <React.Fragment>
          <div
            id="portfolio-financials"
            className="tab-pane fade show active"
            role="tabpanel"
            aria-labelledby="portfolio-tab-financials"
          >
            <Grid container className="property-action-bar">
              <Grid item>
                <Grid container sx={{ width: "auto" }}>
                  <Grid item>
                    <Typography
                      variant="body3"
                      component="label"
                      className={"input-label"}
                    >{`Group by`}</Typography>
                    <FinancialsDropdown
                      label="Group By"
                      dropdownOptions={[
                        { id: "0", name: "Month" },
                        { id: "1", name: "Quarter" },
                        { id: "2", name: "Year" },
                      ]}
                      dropdownValue={financialsGroupingMode.toString()}
                      optionSelected={(newValue: string) => {
                        let numberValue = parseInt(newValue);
                        let startDate = getDates(
                          new Date(
                            financialsFilter.startYear,
                            financialsFilter.startMonth - 1,
                            1
                          ),
                          numberValue
                        );
                        let endDate = getDates(
                          new Date(
                            financialsFilter.endYear,
                            financialsFilter.endMonth - 1,
                            1
                          ),
                          numberValue
                        );
                        updateFinancialsGroupingMode(numberValue);
                        updateFinancialsStartDate(startDate.start);
                        updateFinancialsEndDate(endDate.end);
                        setFinancialsFilter({
                          ...financialsFilter,
                          groupingMode: numberValue,
                          startYear: startDate.start.getFullYear(),
                          startMonth: startDate.start.getMonth() + 1,
                          endMonth: endDate.end.getMonth() + 1,
                          endYear: endDate.end.getFullYear(),
                        });
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body3"
                      component="label"
                      className={"input-label"}
                    >{`Select date range`}</Typography>
                    <Box className={"datepicker-container daterange-container"}>
                      <DatePicker
                        selected={financialsStartDate}
                        onChange={(date: Date) => {
                          updateFinancialsStartDate(date);
                          setFinancialsFilter({
                            ...financialsFilter,
                            startYear: date.getFullYear(),
                            startMonth: date.getMonth() + 1,
                          });
                        }}
                        dateFormat={getFormat()}
                        //showFullMonthYearPicker
                        //showTwoColumnMonthYearPicker
                        showMonthYearPicker={financialsGroupingMode === 0}
                        showQuarterYearPicker={financialsGroupingMode === 1}
                        showYearPicker={financialsGroupingMode === 2}
                        minDate={
                          getDates(
                            new Date(financialsData.minDate),
                            financialsGroupingMode
                          ).start
                        }
                        maxDate={
                          getDates(
                            new Date(financialsData.maxDate),
                            financialsGroupingMode
                          ).end
                        }
                        //popperPlacement="auto"
                        popperModifiers={[
                          {
                            name: "preventOverflow",
                            options: {
                              altAxis: true,
                            },
                          },
                          {
                            name: "offset",
                            options: {
                              offset: [-13, 0],
                            },
                          },
                        ]}
                        className={"right-text"}
                      />
                      <Box className={"icon-datepicker icon-datepicker-dash"}>
                        &nbsp;-&nbsp;
                      </Box>
                      <DatePicker
                        selected={financialsEndDate}
                        onChange={(date: Date) => {
                          let endDate = getDates(
                            date,
                            financialsGroupingMode
                          ).end;
                          updateFinancialsEndDate(endDate);
                          setFinancialsFilter({
                            ...financialsFilter,
                            endYear: endDate.getFullYear(),
                            endMonth: endDate.getMonth() + 1,
                          });
                        }}
                        dateFormat={getFormat()}
                        //showFullMonthYearPicker
                        //showTwoColumnMonthYearPicker
                        showMonthYearPicker={financialsGroupingMode === 0}
                        showQuarterYearPicker={financialsGroupingMode === 1}
                        showYearPicker={financialsGroupingMode === 2}
                        minDate={new Date(financialsData.minDate)}
                        maxDate={
                          getDates(
                            new Date(financialsData.maxDate),
                            financialsGroupingMode
                          ).end
                        }
                        //popperPlacement="auto"
                        popperModifiers={[
                          {
                            name: "preventOverflow",
                            options: {
                              altAxis: true,
                            },
                          },
                          {
                            name: "offset",
                            options: {
                              offset: [-13, 0],
                            },
                          },
                        ]}
                      />
                      <Box className={"icon-datepicker"}>
                        <UilCalender />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container sx={{ width: "auto" }}>
                  <Grid item>
                    <SaveGridStateOptions
                      isSuccess={gridConfigsQuery.isSuccess}
                      gridView={gridView}
                      handleGridView={(newVal: string) => {
                        setGridView(newVal);
                      }}
                      gridConfigData={gridConfigsQuery.data}
                      gridApi={gridApi}
                      gridColumnApi={gridColumnApi}
                      gridId={gridId}
                      pageId={pageId}
                      accountId={accountId}
                      invalidateGridQuery={() =>
                        queryClient.invalidateQueries([
                          "getGridConfig",
                          pageId + gridId + accountId,
                        ])
                      }
                      updateSaveAsViewName={(name: string) => {
                        saveAsView.current = name;
                      }}
                      updateGridView={() =>
                        setGridView(GRID_CONFIG_DEFAULT_VIEW)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      className={"btn-primary"}
                      sx={{ marginTop: "21px" }}
                      onClick={onExport}
                    >
                      <UilExport /> Export
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="property-action-bar property-action-bar-sub">
              <Grid item>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          marginTop: "21px",
                          marginLeft: "-6px",
                          "& svg": {
                            fontSize: "1em",
                          },
                        }}
                        control={
                          <Checkbox
                            checked={financialsBudgetView}
                            sx={{ padding: "0", fontSize: "24px" }}
                            onChange={(event) => {
                              updateFinancialsBudgetView(
                                event.target.checked
                              );
                              setFinancialsFilter({
                                ...financialsFilter,
                                budgetView: event.target.checked,
                              });
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="body3"
                            component="label"
                            style={{ paddingLeft: "4px" }}
                          >
                            {"Budget View"}
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div id="portfolio-financials-tab-content" className="tab-content">
              <div className="standard-content">
                {/* Portfolio Grids */}
                <div
                  className="table-standard-toggle-container"
                  style={{ width: "100%" }}
                >
                  <Financials
                    financialsQuery={financialsQuery}
                    gridApi={gridApi}
                    gridColumnApi={gridColumnApi}
                    onGridRender={(
                      gridApi: GridApi,
                      gridColumnApi: ColumnApi
                    ) => {
                      setGridApi(gridApi);
                      setGridColumnApi(gridColumnApi);
                    }}
                    budgetView={financialsBudgetView}
                  />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PortfolioFinancials;
