import React, { useContext } from "react";
import { FormControl, Select, MenuItem, Theme, Box, SelectChangeEvent } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { TAccount } from "../../properties/property/model/account";
import UserContext from "../util/userContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 250,
      marginBottom: 16,
    },
  })
);

interface GroupSearchProps {
  setFilter: (value: string) => void;
}

const GroupSearch: React.FC<GroupSearchProps> = ({ setFilter }) => {
  const classes = useStyles();
  const { userAccountDDValue, updateUserAccountDDValue, latestAccountOptions } =
    useContext(UserContext);

  React.useEffect(() => {
    // if we did not retrieve account options, we return
    if (userAccountDDValue.length !== 0) return;

    const defaultAccId = latestAccountOptions[0]?.identifier || "";
    const selectedAccount =
      userAccountDDValue.length === 0 ? defaultAccId : userAccountDDValue;
    updateUserAccountDDValue(defaultAccId);
    setFilter(selectedAccount);
  }, [latestAccountOptions, userAccountDDValue, setFilter, updateUserAccountDDValue]);

  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value;

    updateUserAccountDDValue(newValue);
    setFilter(newValue);
  };

  const SelectIcon = (props: any) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  return (
    <Box ml={2}>
      {latestAccountOptions.length > 1 && (
        <FormControl
          variant="outlined"
          size="small"
          className={classes.formControl}
        >
          <Select
            value={userAccountDDValue}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            IconComponent={SelectIcon}
          >
            {latestAccountOptions.map((dropdownOption: TAccount) => (
              <MenuItem
                value={dropdownOption.identifier}
                key={dropdownOption.identifier}
              >
                {dropdownOption.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export default GroupSearch;
