import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  DialogActions,
  Button,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useGetDealContacts } from "../api/leasingActivityService";
import { Link } from "react-router-dom";
import { DealContactsFilter } from "../model/dealContactsFilter";
import { UilMultiply } from "@iconscout/react-unicons";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { formatPhoneNumber } from "../../util/formatPhone";

interface DealContactsProps {
  handlePopupClose: () => void;
  filter: DealContactsFilter;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leasingActivityModalDialog: {
      "& .MuiDialog-paper": {
        padding: 0,
      },
      "& .MuiDialog-paper .MuiDialogTitle-root": {
        padding: "16px 24px 8px 24px",
      },
      "& .MuiDialog-paper .MuiDialogActions-root": {
        padding: "8px 24px 16px 24px",
      },
      "& .MuiDialog-paper .MuiTypography-body1": {
        padding: 0,
        fontSize: 15,
        fontWeight: "normal",
        color: "var(--primary-dark-blue)",
      },
      "& .MuiDialog-paper .MuiDialogContent-root": {
        padding: "8px 0",
      },
      "& .MuiTable-root": {
        borderTop: "1px solid var(--neutral-grey-150)",
      },
      "& .MuiTableCell-root": {
        padding: "12px 20px",
        border: "1px solid var(--neutral-grey-150)",
        borderTop: 0,
        borderLeft: 0,
        verticalAlign: "top",
        overflowWrap: "break-word",
        breakWord: "break-all",
      },
      "& .MuiTableCell-head": {
        whiteSpace: "nowrap",
      },
      "& .MuiTableCell-root:last-child": {
        borderRight: 0,
      },
    },
  })
);

const DealContacts: React.FunctionComponent<DealContactsProps> = (props) => {
  const classes = useStyles();
  const dealContacts = useGetDealContacts(props.filter);

  return (
    <Dialog
      open={true}
      aria-labelledby="deal-contacts"
      fullWidth
      maxWidth="md"
      className={classes.leasingActivityModalDialog}
    >
      <DialogTitle id="deal-contacts-modal">
        <Typography component="span" variant="h2" color="primary.dark">
          Contacts
        </Typography>
        <IconButton
          aria-label="close"
          disabled={dealContacts.isLoading}
          onClick={() => props.handlePopupClose()}
          className="icon-dialog-close"
          size="large"
        >
          <UilMultiply />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {dealContacts.isFetching || !dealContacts.data ? (
          <Box m="auto">
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ width: "100%" }}>
            <TableContainer>
              <Table aria-label="contacts" style={{ overflow: "hidden" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        Type
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        Phone
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        Email
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dealContacts.data.map((contact, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {contact.dealContactType}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {(contact.firstName || "") +
                            " " +
                            (contact.lastName || "")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {formatPhoneNumber(contact.phone || "")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {contact.email && (
                            <Link
                              to="#"
                              onClick={(e) => {
                                window.location.replace(
                                  "mailto:" + contact.email
                                );
                                e.preventDefault();
                              }}
                            >
                              {contact.email}
                            </Link>
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={dealContacts.isLoading}
          onClick={() => props.handlePopupClose()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DealContacts;
