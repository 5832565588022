import {
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  ClickAwayListener,
} from "@mui/material";
import headerStyles from "../headerStyles";
import { Link } from "react-router-dom";

interface DocumentsMenuProps {
  setLeftMenuDocumentsCollapseClose: () => void;
}

const DocumentsMenu: React.FunctionComponent<DocumentsMenuProps> = (props) => {
  const classes = headerStyles();
  return (
    <ClickAwayListener
      onClickAway={() => {
        props.setLeftMenuDocumentsCollapseClose();
      }}
    >
      <List
        component="div"
        aria-labelledby="leftMenuDocumentsCollapseContainer"
        disablePadding
        className={classes.leftMenuList}
        onClick={() => props.setLeftMenuDocumentsCollapseClose()}
        subheader={
          <ListSubheader
            component="div"
            id="leftMenuDocumentsCollapseContainer"
          ></ListSubheader>
        }
      >
        <ListItem
          button
          component={Link}
          to="/"
          className={classes.listItemText}
        >
          <ListItemText primary="Certificate of Insurance" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/"
          className={classes.listItemText}
        >
          <ListItemText primary="Contracts" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/"
          className={classes.listItemText}
        >
          <ListItemText primary="Leases" />
        </ListItem>
      </List>
    </ClickAwayListener>
  );
};

export default DocumentsMenu;
