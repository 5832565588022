import React, { useEffect } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import {
  ColumnApi,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  RowDataChangedEvent,
  StatusPanelDef,
} from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { getStatusPanels } from "../../../properties/leasing activity/util/gridTable";
import { IPortfolioRentRoll } from "../model/getPortfolioRR";
import { portfolioRRLeaseColumns } from "../util/portfolioRRLeaseUtil";

interface PortfolioRRLeaseGridProps {
  rowData: IPortfolioRentRoll[];
  gridApi?: GridApi;
  gridColumnApi?: ColumnApi;
  onGridRender: (gridApi?: GridApi, gridColumnApi?: ColumnApi) => void;
  updateAssetSummary: (summary: number[]) => void;
  updateFilteredAssetSummary: (summary: number[]) => void;
}

const PortfolioRRLeaseGrid: React.FC<PortfolioRRLeaseGridProps> = (props) => {
  const { rowData, onGridRender } = props;

  const columnDefs = portfolioRRLeaseColumns;

  const statusBar: { statusPanels: StatusPanelDef[] } = getStatusPanels();

  const onGridReady = (event: GridReadyEvent) => {
    onGridRender(event.api, event.columnApi);

    if (rowData === undefined || rowData.length === 0) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }
  };

  useEffect(() => {
    return () => {
      onGridRender(undefined, undefined);
    };
  }, [onGridRender]);

  /**
   * Computes Asset Summary or Filtered Asset Summary
   * @param isFiltered Determines whether we are trying to compute filtered asset summary or overall asset summary
   * @param event event object (could be FirstDataRenderedEvent or FilterChangedEvent, etc.)
   */
  const calculateTotals = (
    isFiltered: boolean,
    event: FirstDataRenderedEvent | FilterChangedEvent | RowDataChangedEvent
  ) => {
    let result: number[];
    let rentableArea = 0;
    let occupied = 0;
    let leased = 0;
    let monthlyRent = 0;
    let tenantList: string[] = [];
    let occupancy = 0;
    let leasedPercentage = 0;
    let monthlyRentPerSF = 0;

    // Filtered Asset Summary values
    if (isFiltered) {
      event.api.forEachNodeAfterFilter((node) => {
        if (node.data !== undefined) {
          rentableArea += node.data.isFutureLease ? 0 : node.data.rsf;
          if (
            node.data.leaseTitle !== undefined &&
            node.data.leaseTitle.trim().length > 0 &&
            !node.data.isFutureLease
          )
            occupied += node.data.isFutureLease ? 0 : node.data.rsf;

          if (
            node.data.leaseTitle !== undefined &&
            node.data.leaseTitle.trim().length > 0
          )
            leased += node.data.isFutureLease ? 0 : node.data.rsf;

          if (
            node.data.tenantId !== undefined &&
            !tenantList.includes(node.data.tenantId)
          )
            tenantList.push(node.data.tenantId);

          monthlyRent += node.data.monthlyRent || 0;
        }
      });
      occupancy = rentableArea > 0 ? occupied / rentableArea : 0;
      leasedPercentage = rentableArea > 0 ? leased / rentableArea : 0;
      monthlyRentPerSF += occupied > 0 ? monthlyRent / occupied : 0;
    }
    //Asset Summary values
    else {
      rowData.forEach((ele) => {
        if (ele !== undefined) {
          rentableArea += ele.isFutureLease ? 0 : (ele.rsf || 0);
          if (
            ele.leaseTitle !== undefined &&
            ele.leaseTitle.trim().length > 0 &&
            !ele.isFutureLease
          )
            occupied += ele.isFutureLease ? 0 : (ele.rsf || 0);

          if (ele.leaseTitle !== undefined && ele.leaseTitle.trim().length > 0)
            leased += ele.isFutureLease ? 0 : (ele.rsf || 0);

          if (
            ele.tenantId !== undefined &&
            !tenantList.includes(ele.tenantId)
          ) {
            tenantList.push(ele.tenantId);
          }
          monthlyRent += ele.monthlyRent || 0;
        }
      });
      occupancy = rentableArea > 0 ? occupied / rentableArea : 0;
      leasedPercentage = rentableArea > 0 ? leased / rentableArea : 0;
      monthlyRentPerSF += occupied > 0 ? monthlyRent / occupied : 0;
    }

    result = [
      rentableArea,
      tenantList.length,
      occupancy,
      leasedPercentage,
      Math.round(monthlyRentPerSF),
    ];
    return result;
  };

  /**
   * To resize all columns to fit to screen
   * @param event
   */
  const autoSizeAll = (event: FirstDataRenderedEvent) => {
    setTimeout(() => event.columnApi?.autoSizeAllColumns(), 500);// TODO remove setTimeout when Ag-grid fixes autoSizeColumns on React 18 
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    autoSizeAll(event);
  };

  const onRowDataChanged = (event: RowDataChangedEvent) => {
    props.updateAssetSummary(calculateTotals(false, event));
    props.updateFilteredAssetSummary(calculateTotals(true, event));
  };

  const onFilterChanged = (event: FilterChangedEvent) => {
    props.updateFilteredAssetSummary(calculateTotals(true, event));
  };

  return (
    <div
      id="myGrid"
      className="ag-theme-alpine ag-theme-alpine-container-override"
      style={{ width: "100%" }}
    >
      <AgGridReact
        excelStyles={[
          {
            id: "alignRight",
            alignment: {
              horizontal: "Right",
            },
          },
          {
            id: "currencyFormat",
            numberFormat: { format: "\u0024 #,##0.00" },
          },
          {
            id: "numberFormat",
            numberFormat: { format: "#,##0.00" },
          },
        ]}
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        rowData={rowData}
        onRowDataChanged={onRowDataChanged}
        onFilterChanged={onFilterChanged}
        onFirstDataRendered={onFirstDataRendered}
        statusBar={statusBar}
        enableCharts={true}
        enableRangeSelection={true}
        sortingOrder={["desc", "asc"]}
        defaultColDef={{
          minWidth: 50,
          resizable: true,
          sortable: true,
          filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
        }}
      />
    </div>
  );
};

export default PortfolioRRLeaseGrid;
