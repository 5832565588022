import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import MaterialInputMask from "../../properties/loans/views/MaterialInputMask";
import SimpleAlert from "../../UI/view/SimpleAlert";
import { useNewCreateUser } from "../api/createUserApi";
import { ICreateUser } from "../model/createUserModel";
import logo from "./logo.png";

const PREFIX = "CreateUserForm";

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  divInput: `${PREFIX}-divInput`,
  divName: `${PREFIX}-divName`,
  formControl: `${PREFIX}-formControl`,
  title: `${PREFIX}-title`,
  logo: `${PREFIX}-logo`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    padding: theme.spacing(10, 0),
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.container}`]: {
    maxWidth: "30%",
    minWidth: 400,
    padding: 32,
    background: "white",
    border: "solid 1px var(--neutral-grey-150)",
    borderRadius: 6,
    boxShadow: "0 32px 48px 0 rgb(37 60 82 / 4%)",
  },

  [`& .${classes.divInput}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.divName}`]: {
    margin: theme.spacing(1),
    flex: 1,
  },

  [`& .${classes.formControl}`]: {
    minWidth: 120,
    width: "100%",
  },

  [`& .${classes.title}`]: {
    fontSize: 24,
    fontWeight: 600,
    color: "var(--primary-dark-blue)",
    margin: theme.spacing(1),
  },

  [`& .${classes.logo}`]: {
    marginBottom: theme.spacing(2),
    width: "70%",
  },
}));

interface CreateUserFormProps {
  userEmail: string;
  token: string;
}

const CreateUserForm: React.FC<CreateUserFormProps> = (props) => {
  const { token, userEmail } = props;

  const navigate = useNavigate();
  const createUserMutation = useNewCreateUser();

  const [error, setError] = React.useState<string | null>(null);

  const methods = useForm<ICreateUser>({
    mode: "onTouched",
    reValidateMode: "onChange",
    shouldUnregister: false,
    defaultValues: {
      token,
      email: userEmail,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = methods;

  const password = React.useRef({});
  password.current = watch("password", "");

  const phoneNumber: string = watch("phoneNumber");

  const onSubmit = handleSubmit((model: ICreateUser) => {
    createUserMutation.mutate(model, {
      onSuccess: (data) => {
        navigate("/login");
      },
      onError: (message) => {
        const msg =
          (message as string) ||
          "Something went wrong, please try again later.";
        setError(msg);
      },
    });
  });

  return (
    <Root>
      <FormProvider {...methods}>
        {error !== null && (
          <SimpleAlert
            severityType="error"
            onClose={() => setError(null)}
            message={error}
          />
        )}
        <Box className={classes.root}>
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <img
                src={logo}
                id="loginLogo"
                alt=""
                loading="lazy"
                className={classes.logo}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.title}>Sign Up</Typography>
            </Grid>
            {/* Email Address */}
            <Grid item xs={12} className={classes.divInput}>
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  id="user-email"
                  label="Email"
                  variant="outlined"
                  name={"email"}
                  defaultValue={userEmail}
                  required
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid container>
              {/* First Name */}
              <Grid item xs={6} className={classes.divName}>
                <FormControl className={classes.formControl}>
                  <TextField
                    size="small"
                    id="first-name"
                    label="First name"
                    variant="outlined"
                    {...register("firstName", {
                      required: "First name is required.",
                    })}
                    placeholder="First name"
                    error={Boolean(errors?.firstName)}
                    helperText={
                      Boolean(errors?.firstName)
                        ? errors?.firstName?.message
                        : null
                    }
                    required
                  />
                </FormControl>
              </Grid>
              {/* Last Name */}
              <Grid item xs={6} className={classes.divName}>
                <FormControl className={classes.formControl}>
                  <TextField
                    size="small"
                    id="last-name"
                    label="Last name"
                    variant="outlined"
                    {...register("lastName", {
                      required: "Last name is required.",
                    })}
                    placeholder="Last name"
                    error={Boolean(errors?.lastName)}
                    helperText={
                      Boolean(errors?.lastName)
                        ? errors?.lastName?.message
                        : null
                    }
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
            {/* Office Number */}
            <Grid item xs={12} className={classes.divInput}>
              <FormControl className={classes.formControl}>
                <Controller
                  control={control}
                  name={"phoneNumber"}
                  // rules={{
                  //   validate: {
                  //     format: (value) =>
                  //       isUserValidNumber(value) || "Incorrect Office number",
                  //   },
                  // }}
                  render={({ field, fieldState }) => (
                    <MaterialInputMask
                      addStyle={false}
                      mask="(999) 999-9999"
                      hasValue={Boolean(phoneNumber)}
                      value={field.value || ""}
                      onChange={field.onChange}
                      TextFieldProps={{
                        size: "small",
                        id: "phone-number",
                        variant: "outlined",
                        label: "Office number",
                        name: field.name,
                        inputRef: field.ref,
                        // error: Boolean(fieldState.invalid),
                        // helperText: Boolean(errors?.phoneNumber)
                        //   ? errors?.phoneNumber?.message
                        //   : null,
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            {/* Role */}
            <Grid item xs={12} className={classes.divInput}>
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  id="role"
                  label="Role"
                  variant="outlined"
                  placeholder="Insert your role"
                />
              </FormControl>
            </Grid>
            {/* Company */}
            <Grid item xs={12} className={classes.divInput}>
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  id="company"
                  label="Company"
                  variant="outlined"
                  placeholder="Insert your company"
                />
              </FormControl>
            </Grid>
            {/* Password */}
            <Grid item xs={12} className={classes.divInput}>
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  id="user-password"
                  label="Password"
                  variant="outlined"
                  {...register("password", {
                    required:
                      "Your password must be at least 8 characters: 1 uppercase, 1 lowercase, 1 number and 1 symbol.",
                    minLength: {
                      value: 8,
                      message:
                        "Your password must be at least 8 characters: 1 uppercase, 1 lowercase, 1 number and 1 symbol.",
                    },
                    pattern: {
                      value:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                      message:
                        "Your password must be at least 8 characters: 1 uppercase, 1 lowercase, 1 number and 1 symbol.",
                    },
                  })}
                  placeholder="Insert password"
                  type="password"
                  required
                  error={Boolean(errors?.password)}
                  helperText={
                    Boolean(errors?.password) ? errors?.password?.message : null
                  }
                />
              </FormControl>
            </Grid>
            {/* Confirm Password */}
            <Grid item xs={12} className={classes.divInput}>
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  id="user-confirm-password"
                  label="Confirm Password"
                  variant="outlined"
                  {...register("confirmPassword", {
                    required: "Confirm Password is required.",
                    validate: (value) =>
                      value === password.current ||
                      "The passwords do not match",
                  })}
                  placeholder="Confirm password"
                  type="password"
                  required
                  error={Boolean(errors?.confirmPassword)}
                  helperText={
                    Boolean(errors?.confirmPassword)
                      ? errors?.confirmPassword?.message
                      : null
                  }
                />
              </FormControl>
            </Grid>
            {/* Save button */}
            <Grid item xs={12} className={classes.divInput}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={onSubmit}
                >
                  Create User
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </Root>
  );
};

export default CreateUserForm;
