import React, { useEffect } from "react";

import PortfolioContext from "../../portfolio/util/portfolioContext";
import { useGetAccountRoles } from "../../usermanagement/api/userService";
import { EAccountPermission } from "../../usermanagement/model/editUserModel";

interface AccountRolesProps {
  userId: string;
}

export interface IAccountPermission {
  [EAccountPermission.ADMINISTRATION]: boolean;
  [EAccountPermission.ADMINISTER_USERS]: boolean;
  [EAccountPermission.PROPERTY_EDIT]: boolean;
  [EAccountPermission.CONFIGURE_WIDGETS]: boolean;
  [EAccountPermission.CONFIGURE_PORTFOLIOS]: boolean;
  [EAccountPermission.CONFIGURE_LOANS]: boolean;
  [EAccountPermission.DOCUMENTS_UPLOAD_AND_DELETE]: boolean;
}

const AccountRoles: React.FC<AccountRolesProps> = (props) => {
  const { updateAdminAccounts } = React.useContext(PortfolioContext);
  const { data: accountRoles, isSuccess } = useGetAccountRoles();

  useEffect(() => {
    if (accountRoles === undefined || isSuccess === false) return;

    const adminAccounts: string[] = [];
    const accountsMap = new Map<string, IAccountPermission>();
    accountRoles.accountUserRoles.forEach((accountUserRole) => {
      const accountId = accountUserRole.accountIdentifier;
      let pushAccId = false;
      const permissionsAvailable: IAccountPermission = {
        [EAccountPermission.ADMINISTRATION]: false,
        [EAccountPermission.ADMINISTER_USERS]: false,
        [EAccountPermission.PROPERTY_EDIT]: false,
        [EAccountPermission.CONFIGURE_WIDGETS]: false,
        [EAccountPermission.CONFIGURE_PORTFOLIOS]: false,
        [EAccountPermission.CONFIGURE_LOANS]: false,
        [EAccountPermission.DOCUMENTS_UPLOAD_AND_DELETE]: false,
      };
      for (const role of accountUserRole.roles) {
        const shouldPushAccId =
          role.name === EAccountPermission.ADMINISTRATION ||
          role.name === EAccountPermission.ADMINISTER_USERS ||
          role.name === EAccountPermission.CONFIGURE_WIDGETS;
        if (shouldPushAccId) pushAccId = true;
        permissionsAvailable[role.name as EAccountPermission] = true;
      }
      if (pushAccId) adminAccounts.push(accountId);
      accountsMap.set(accountId, permissionsAvailable);
    });

    updateAdminAccounts(adminAccounts, accountsMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountRoles, isSuccess]);

  return <React.Fragment />;
};

export default AccountRoles;
