import React from "react";
import { DashboardProps } from "../../models/dashboardProps";
import {
  getDateTickLabelsFormatter,
  formatNumber,
} from "../../utils/widgetUtils";
import DashboardItem from "./dashboardItem";

const VacancyDistributionAreaWidget: React.FunctionComponent<DashboardProps> = (
  props
) => {
  const chartOptions = {
    autoSize: true,
    data: props.data?.info,
    series: [
      {
        type: "column",
        xKey: "period",
        yKey: "sixMonths",
        yName: "0-6 months",
        stacked: true,
        tooltip: {
          renderer: (params: any) => {
            return {
              title: params.xValue,
              content: formatNumber(params.yValue, 0),
            };
          },
        },
      },
      {
        type: "column",
        xKey: "period",
        yKey: "oneYear",
        yName: "7-12 months",
        stacked: true,
        tooltip: {
          renderer: (params: any) => {
            return {
              title: params.xValue,
              content: formatNumber(params.yValue, 0),
            };
          },
        },
      },
      {
        type: "column",
        xKey: "period",
        yKey: "twoYears",
        yName: "1-2 years",
        stacked: true,
        tooltip: {
          renderer: (params: any) => {
            return {
              title: params.xValue,
              content: formatNumber(params.yValue, 0),
            };
          },
        },
      },
      {
        type: "column",
        xKey: "period",
        yKey: "twoYearsPlus",
        yName: "2+ years",
        stacked: true,
        tooltip: {
          renderer: (params: any) => {
            return {
              title: params.xValue,
              content: formatNumber(params.yValue, 0),
            };
          },
        },
      },
    ],
    legend: {
      reverseOrder: true,
    },
    axes: [
      {
        type: "category",
        position: "bottom",
        label: {
          rotation: 320,
          formatter: getDateTickLabelsFormatter(props.data?.info),
        },
      },
      {
        type: "number",
        position: "left",
        tick: {
          count: 5,
        },
      },
    ],
  };

  return (
    <DashboardItem
      {...props.data}
      options={chartOptions}
      title={props.data?.title || "Vacancy Distribution (Area)"}
      tooltip="Vacant RSF based on move out (otherwise lease end) date during specified time blocks with future start dates considered"
      highlightHeader={props.highlightHeader}
    />
  );
};

export default VacancyDistributionAreaWidget;
