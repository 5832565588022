import { useContext, createContext } from "react";

export interface PropertyInfo {
  id: string;
  title: string;
  accountIdentifier: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  imageUrl: string;
}

export const PropertyInfoContext = createContext<PropertyInfo>({
  id: "",
  title: "",
  accountIdentifier: "",
  street: "",
  city: "",
  state: "",
  zip: "",
  imageUrl: ""
});
export const usePropertyInfo = () => useContext(PropertyInfoContext);
