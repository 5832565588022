import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

interface Props {
  label: string;
  ml?: number;
  mr?: number;
  mt?: number;
  mb?: number;
}

/*
const styles = {
  padding: "4px",
  borderRadius: "6px",
  backgroundColor: "#DFE7F1",
  color: "#51647D",
  alignItems: "center",
  fontWeight: 600,
  fontSize: "small",
};
*/

const AccordionChip: React.FC<Props> = (props) => {
  const ml = props.ml ?? 0;
  const mr = props.mr ?? 0;
  const mt = props.mt ?? 0;
  const mb = props.mb ?? 0;

  return (
    <Box ml={ml} mr={mr} mt={mt} mb={mb}
      //style={{ ...styles }}
      className="overview-chip"
    >
      <Typography variant="body3" color="primary.dark" component="span">
        {props.label}
      </Typography>
    </Box>
  );
};

export default AccordionChip;
