/**
 * if the current date falls within range, do nothing, otherwise return minDate or maxDate based on how close current date is.
 * @param date current date
 * @param minDate min date retrieved from backend
 * @param maxDate max date retrieved from backend
 */
export const computeDateWithinRange = (
  date: Date,
  minDate: Date,
  maxDate: Date
): Date | undefined => {
  const dateYear = date.getFullYear();
  const maxYear = maxDate.getFullYear();
  const minYear = minDate.getFullYear();

  if (dateYear < maxYear && dateYear > minYear) return undefined;
  else if (dateYear === maxYear) {
    const dateMonth = date.getMonth();
    const maxMonth = maxDate.getMonth();
    if (dateMonth > maxMonth) return maxDate;
    return undefined;
  } else if (dateYear === minYear) {
    const dateMonth = date.getMonth();
    const minMonth = minDate.getMonth();
    if (dateMonth < minMonth) return minDate;
    return undefined;
  } else if (dateYear > maxYear) return maxDate;
  else if (dateYear < minYear) return minDate;
};

export const assetSummaryColumnNames = [
  "Rentable Area",
  "Number of Tenants",
  "Occupancy (Area)",
  "Leased (%)",
  "Monthly Rent/SF",
];
