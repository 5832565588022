import axios, { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import { AccountIdentifierHeaderName, useAuthorizedApiAxios } from "../../../../shared/api/authorizedAxios";
import { PortfolioLoansData, PortfolioLoansFilter } from "../models/portfolioLoans";

export const useGetPortfolioLoans = (loanProps: PortfolioLoansFilter) => {
    const authorizedApiAxios = useAuthorizedApiAxios();
    const uniqueId =
      loanProps.portfolioId +
      loanProps.accountIdentifier +
      loanProps.monthYearFilter.month +
      loanProps.monthYearFilter.year;
    return useQuery<PortfolioLoansData | undefined>(
      ["portfolioLoans" + uniqueId, loanProps],
      () => getPortfolioLoans(loanProps, authorizedApiAxios)
    );
  };
  
  const getPortfolioLoans = async (
    loanProps: PortfolioLoansFilter,
    authorizedApiAxios: () => AxiosInstance
  ) => {
    const source = axios.CancelToken.source();
  
    const queryParamsObj = {
      portfolioId: loanProps.portfolioId,
      year: loanProps.monthYearFilter.year,
      month: loanProps.monthYearFilter.month,
    };
    const queryParams = queryString.stringify(queryParamsObj);
    const qry = queryParams;
    const url = `/AssetService/v1/Portfolios/${loanProps.portfolioId}/Loans`;
    // const url = `/AssetService/v1/Loans/Portfolio?${qry}`;

    const requiredFilters = ["portfolioId", "month", "year"];
    const reqfilters = queryString.parse(queryParams);
    const containsRequiredFilters = requiredFilters.every((s) => {
      if (s in reqfilters) {
        return (
          reqfilters[s] != null && (reqfilters[s] + "").toString().length > 0
        );
      } else return false;
    });
    if (loanProps.portfolioId === null || !containsRequiredFilters) return;

    let promise = authorizedApiAxios().get<PortfolioLoansData>(url, {
      cancelToken: source.token,
      headers: {
        [AccountIdentifierHeaderName]: loanProps.accountIdentifier,
      },
    });
  
    (promise as any).cancel = () => source.cancel();
    return await promise.then((response) => response.data);
  };