import axios, { AxiosInstance } from "axios";
import configuration from "../../configuration";
import { useCallback } from "react";
import { useOidcAccessToken } from '@axa-fr/react-oidc';

export const useAuthorizedApiAxios = () => {
  const{ accessToken } = useOidcAccessToken();
  const callBack = useCallback(() => {
    return createInstance(accessToken, configuration?.apiBaseUrl || "");
  }, [accessToken]);

  return callBack;
};

export const AccountIdentifierHeaderName = "X-Account-Identifier";

function createInstance(token: string, url: string): AxiosInstance {
  const instance = axios.create({ baseURL: url });
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  instance.interceptors.response.use(
    (response) => {
      //response comes wrapped on a envelope, just return the actual result
      if (response.data.result) {
        response.data = response.data.result;
      }

      return response;
    },
    (error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else if (axios.isCancel(error)) {
        console.warn("Warning", "Request canceled");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", error.message);
      }

      if (error.config) console.log(error.config);

      //Error was returner from API
      if (error.errorCode) {
        return Promise.reject({
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
        });
      }

        //Error returned from response in API
      if (error.response.data.errorCode)
      {
        return Promise.reject({
          errorCode: error.response.data.errorCode,
          errorMessage: error.response.data.errorMessage,
        })
      }
      return Promise.reject(
        "There was an error while executing the request, please try again"
      );
    }
  );

  return instance;
}
