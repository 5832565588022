import { ColDef, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";

import {
  numberFormatter,
  negativeNumCellStyle,
  currencyFormatter,
  percentFormatterWithPlaces,
  stringSortCaseInsensitive,
} from "../../../properties/util/gridUtils";

export const getPortfoliosColumns = (
  hasPortfolioAccess: boolean,
  deleteFunc: Function,
  refreshGridFunc: Function
) => {
  const initialColumns = getInitialPortfoliosColumns();

  if (!hasPortfolioAccess) return initialColumns;

  initialColumns.unshift({
    headerName: "Actions",
    filter: false,
    field: "id",
    cellRenderer: "deletePortfolioRenderer",
    cellRendererParams: {
      clicked: (portfolioId: string, portfolioName: string) => {
        deleteFunc(portfolioId, portfolioName);
      },
      refreshData: () => {
        refreshGridFunc();
      },
    },
    width: 180,
  });

  return initialColumns;
};

export const getInitialPortfoliosColumns = () => {
  const columns: ColDef[] = [
    {
      headerName: "Portfolio",
      field: "name",
      rowGroup: true,
      hide: true,
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: true,
      cellRenderer: "linkComponent",
    },
    {
      headerName: "Property",
      field: "propertyName",
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data === undefined) return "";
        return params.value ?? "-";
      },
    },
    {
      headerName: "Rentable Area",
      field: "rentableArea",
      filter: "agNumberColumnFilter",
      sortable: true,
      type: "numericColumn",
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data === undefined) return "";
        return numberFormatter(params.data.rentableArea, 0);
      },
    },
    {
      headerName: "Occupancy (Area)",
      field: "leasedPercent",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "lessThan",
          "greaterThan",
          "inRange",
          "blank",
          "notBlank",
        ],
        defaultOption: "lessThan",
      },
      sortable: true,
      type: "numericColumn",
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data === undefined) return "";
        if(!params.value) return "-";
        return percentFormatterWithPlaces(params.value / 100, 1, "%");
      },
      valueGetter: (params: ValueGetterParams) => {
        return (params.data?.leasedPercent * 100) || undefined;
      },
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Monthly Rent Per Area",
      field: "monthlyRentPerArea",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "lessThan",
          "greaterThan",
          "inRange",
          "blank",
          "notBlank",
        ],
        defaultOption: "lessThan",
      },
      sortable: true,
      type: "numericColumn",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data === undefined) return "";
        return currencyFormatter(params.data.monthlyRentPerArea, "$");
      },
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Revenue",
      field: "revenue",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "lessThan",
          "greaterThan",
          "inRange",
          "blank",
          "notBlank",
        ],
        defaultOption: "lessThan",
      },
      sortable: true,
      type: "numericColumn",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data === undefined) return "";
        return currencyFormatter(params.data.revenue, "$");
      },
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "Expenses",
      field: "expenses",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "lessThan",
          "greaterThan",
          "inRange",
          "blank",
          "notBlank",
        ],
        defaultOption: "lessThan",
      },
      sortable: true,
      type: "numericColumn",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data === undefined) return "";
        return currencyFormatter(params.data.expenses, "$");
      },
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "NOI",
      field: "noi",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "lessThan",
          "greaterThan",
          "inRange",
          "blank",
          "notBlank",
        ],
        defaultOption: "lessThan",
      },
      sortable: true,
      type: "numericColumn",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data === undefined) return "";
        return currencyFormatter(params.data.noi, "$");
      },
      cellStyle: negativeNumCellStyle,
    },
    {
      headerName: "NCF",
      field: "cashflow",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "lessThan",
          "greaterThan",
          "inRange",
          "blank",
          "notBlank",
        ],
        defaultOption: "lessThan",
      },
      sortable: true,
      type: "numericColumn",
      cellClass: ["alignRight", "currencyFormat"],
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data === undefined) return "";
        return currencyFormatter(params.data.cashflow, "$");
      },
      cellStyle: negativeNumCellStyle,
    },
  ];

  return columns;
};
