import DatePicker from "react-datepicker";
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Tooltip,
  Button,
} from "@mui/material";
import {
  Fragment,
  forwardRef,
  useEffect,
  useMemo,
  useState,
  memo,
  useContext,
} from "react";
import { usePropertyInfo } from "../../util/propertyInfoContext";
import { useGetLeasingActivity } from "../api/leasingActivityService";
import { UilAngleDown } from "@iconscout/react-unicons";
import { UilCalender } from "@iconscout/react-unicons";
import { UilAngleUp } from "@iconscout/react-unicons";
import MaterialLink from "@mui/material/Link";
import {
  LeasingPipeline,
  LeasingActivityInfo,
  LeasingActivityDeal,
} from "../model/leasingActivity";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatDateFNS } from "../../property details/util/formatDateUtil";
import { Link, useLocation } from "react-router-dom";
import { UilComment } from "@iconscout/react-unicons";
import { UilFile } from "@iconscout/react-unicons";
import { UilUser } from "@iconscout/react-unicons";
import { UilVectorSquare } from "@iconscout/react-unicons";
import DealProposals from "./DealProposals";
import { DealProposalsFilter } from "../model/dealProposalsFilter";
import { DealSpacesFilter } from "../model/dealSpacesFilter";
import DealSpaces from "./DealSpaces";
import { DealContactsFilter } from "../model/dealContactsFilter";
import DealContacts from "./DealContacts";
import { DealCommentsFilter } from "../model/dealCommentsFilter";
import DealComments from "./DealComments";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { formatNumber } from "../../util/formatNumber";
import { formatPhoneNumber } from "../../util/formatPhone";
import { formatDuration } from "date-fns";
import { UilExport } from "@iconscout/react-unicons";
import fileSaver from "file-saver";
import { LoadingButton } from "@mui/lab";
import { useIsMutating } from "@tanstack/react-query";
import { useExportReport } from "../../../reporting/api/propertyService";
import LockIcon from "@mui/icons-material/LockOutlined";
import RentRollContext from "../../rent roll/context/RentRollContext";
import queryString from "query-string";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableLeasingPipelineCell: {
      "&:last-child": {
        backgroundColor: "var(--neutral-grey-50)",
      },
      "&:nth-child(7)": {
        backgroundColor: "var(--neutral-grey-50)",
      },
    },
  })
);

const LeasingActivity: React.FunctionComponent = () => {
  const propertyInfo = usePropertyInfo();
  const { propertyLeasingActivityDate, updatePropertyLeasingActivityDate } =
    useContext(RentRollContext);

  const location = useLocation();

  const year = propertyLeasingActivityDate?.getFullYear();
  const month =
    propertyLeasingActivityDate !== undefined
      ? propertyLeasingActivityDate?.getMonth() + 1
      : undefined;

  const leasingActivity = useGetLeasingActivity(
    propertyInfo.id,
    propertyInfo.accountIdentifier,
    year,
    month
  );
  const [isAllExpanded, setIsAllExpanded] = useState<boolean>(false);
  const initialExpandStatus = useMemo(
    () => [true, false, false, false, false, false],
    []
  );
  const [expandStatus, setExpandStatus] =
    useState<boolean[]>(initialExpandStatus);
  const [leasingGroupsCount, setLeasingGroupsCount] = useState<number>(0);

  const onExpandStatusChanged = (index: number, expanded: boolean) => {
    expandStatus[index] = expanded;
    setExpandStatus([...expandStatus]);
    if (expandStatus.every((e) => !e)) {
      setIsAllExpanded(false);
    } else if (expandStatus.every((e) => e)) {
      setIsAllExpanded(true);
    }
  };

  useEffect(() => {
    if (
      expandStatus.length > initialExpandStatus.length ||
      leasingActivity.isFetching ||
      !leasingActivity.data
    )
      return;

    let expandedElements = [leasingActivity.data.hasVTSData ? true : false];
    let leasingGroups = leasingActivity.data.leasingPipeline.groups;
    setLeasingGroupsCount(leasingGroups.length);

    expandedElements = expandedElements.concat(
      leasingGroups.map((b, index) => false) ?? []
    );
    expandedElements = expandedElements.concat([
      false,
      false,
      false,
      false,
      false,
    ]);
    setExpandStatus(expandedElements);
  }, [leasingActivity, expandStatus, initialExpandStatus]);

  useEffect(() => {
    const parsedQueryParams = queryString.parse(location.search);
    const monthString = parsedQueryParams.month;
    const yearString = parsedQueryParams.year;

    if (!propertyLeasingActivityDate) {
      if (
        yearString !== undefined &&
        yearString !== null &&
        monthString !== undefined &&
        monthString !== null
      ) {
        updatePropertyLeasingActivityDate(
          new Date(Number(yearString), Number(monthString) - 1)
        );
      } else {
        updatePropertyLeasingActivityDate(new Date());
      }
    }
  }, [
    location.search,
    propertyLeasingActivityDate,
    updatePropertyLeasingActivityDate,
  ]);

  return leasingActivity.isFetching ||
    !leasingActivity.data ||
    !propertyLeasingActivityDate ? (
    <Box m="auto">
      <CircularProgress />
    </Box>
  ) : (
    <div
      id="property-leasingactivity"
      className="tab-pane fade show active"
      role="tabpanel"
      aria-labelledby="property-tab-leasingactivity"
    >
      <LeasingPipeLineHeaderView
        date={propertyLeasingActivityDate}
        setDate={updatePropertyLeasingActivityDate}
        expandStatus={expandStatus}
        setExpandStatus={setExpandStatus}
        isAllExpanded={isAllExpanded}
        setIsAllExpanded={setIsAllExpanded}
      />
      <div id="property-ar-content" className="tab-content">
        <div id="property-leasing-widgets">
          <Accordion
            expanded={expandStatus[0]}
            onChange={(event, expanded) => {
              onExpandStatusChanged(0, expanded);
            }}
            className={"accordion accordion-container-leasing"}
          >
            <AccordionSummary
              expandIcon={
                <Box className="icon-expand">
                  <UilAngleDown />
                </Box>
              }
              aria-controls="panel1d-content"
              id="panel1d-header"
              className="accordion-summary accordion-summary-leasing-activity"
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h2" color="primary.dark">
                    Leasing Pipeline
                  </Typography>
                </Grid>
                <Grid item>
                  <Box className="leasing-chip leasing-chip-top">
                    <Typography
                      variant="body3"
                      color="primary.dark"
                      component="span"
                    >
                      {leasingActivity.data.hasVTSData
                        ? leasingActivity.data.leasingPipeline.totalDeals
                        : 0}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails
              className={"accordion-details accordion-details-leasing-activity"}
            >
              {!leasingActivity.data.hasVTSData && (
                <Grid
                  container
                  flexDirection="column"
                  alignItems="center"
                  p={2}
                  className="table-leasing-pipeline"
                >
                  <Grid item display="flex" alignItems="center" mb={2}>
                    <LockIcon
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "var(--neutral-grey-600)",
                        marginRight: "5px",
                      }}
                    />
                    <Typography variant="h2" color="var(--primary-dark-blue);">
                      This feature is not available without VTS integration.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      variant="body3"
                      color="var(--neutral-grey-600)"
                      component="span"
                      fontSize="15px"
                    >
                      Already use VTS? Please reach out to your WorkSpace
                      customer contact or
                    </Typography>
                    <Typography
                      variant="body3"
                      color="var(--neutral-grey-600)"
                      component="span"
                      fontSize="15px"
                    >
                      contact our support team at{" "}
                      <a href="mailto:support@workspace.cc" target="_top">
                        support@workspace.cc
                      </a>{" "}
                      to get connected.
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <MemoLeasingPipelineView
                leasingPipeline={leasingActivity.data.leasingPipeline}
                expandStatus={expandStatus}
                onExpandStatusChanged={onExpandStatusChanged}
                year={year}
                month={month}
                vts={leasingActivity.data.hasVTSData}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={"accordion accordion-container-leasing"}
            expanded={expandStatus[leasingGroupsCount + 1]}
            onChange={(event, expanded) => {
              onExpandStatusChanged(leasingGroupsCount + 1, expanded);
            }}
          >
            <AccordionSummary
              expandIcon={
                <Box className="icon-expand">
                  <UilAngleDown />
                </Box>
              }
              aria-controls="panel1e-content"
              id="panel1e-header"
              className="accordion-summary accordion-summary-leasing-activity"
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h2" color="primary.dark">
                    Leasing Activity
                  </Typography>
                </Grid>
                <Grid item>
                  <Box className="leasing-chip leasing-chip-top">
                    <Typography
                      variant="body3"
                      color="primary.dark"
                      component="span"
                    >
                      {leasingActivity.data.leasingActivityInfo.totalRecords}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails
              className={"accordion-details accordion-details-leasing-activity"}
            >
              <LeasingActivityView
                leasingActivityInfo={leasingActivity.data.leasingActivityInfo}
                expandStatus={expandStatus}
                onExpandStatusChanged={onExpandStatusChanged}
                leasingGroupsCount={leasingGroupsCount}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

interface LeasingPipeLineHeaderProps {
  date: Date;
  setDate: (date: Date) => void;
  expandStatus: boolean[];
  setExpandStatus: (status: boolean[]) => void;
  isAllExpanded: boolean;
  setIsAllExpanded: (allExpanded: boolean) => void;
}

const LeasingPipeLineHeaderView: React.FunctionComponent<
  LeasingPipeLineHeaderProps
> = (props) => {
  const {
    date,
    setDate,
    expandStatus,
    setExpandStatus,
    isAllExpanded,
    setIsAllExpanded,
  } = props;
  const propertyInfo = usePropertyInfo();

  type Props = {
    onClick?: () => void;
    value?: string;
  };
  type RefType = number;
  const CustomDatePickerInput = forwardRef<RefType, Props>(
    ({ onClick, value }, ref) => (
      <Box className={"react-datepicker-input"} ref={ref} onClick={onClick}>
        {value}
        <Box className={"react-datepicker-icon"}>
          <UilCalender />
        </Box>
      </Box>
    )
  );

  const exportReportResponse = useExportReport();

  const onExport = async () => {
    const fileName = propertyInfo.title + " Leasing Activity.xlsx";
    const result = await exportReportResponse.mutateAsync({
      accountIdentifier: propertyInfo.accountIdentifier,
      properties: [propertyInfo.id],
      includeLeasingActivityReport: true,
      leasingActivityReportYear: date.getFullYear(),
      leasingActivityReportMonth: date.getMonth() + 1,
    });

    fileSaver.saveAs(result.data, fileName);
  };

  const isMutating = useIsMutating();

  return (
    <Grid container className="property-action-bar">
      <Grid item>
        <Grid container sx={{ width: "auto" }}>
          <Grid item>
            <Box className={"datepicker-outer-container"}>
              <Typography
                variant="body3"
                component="label"
                className={"input-label"}
              >{`Select date:`}</Typography>
              <Box className={"datepicker-container"}>
                <DatePicker
                  selected={date}
                  maxDate={new Date()}
                  onChange={(date: Date) => {
                    setDate(date);
                  }}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  popperModifiers={[
                    {
                      name: "preventOverflow",
                      options: {
                        altAxis: true,
                      },
                    },
                    {
                      name: "offset",
                      options: {
                        offset: [-13, 0],
                      },
                    },
                  ]}
                  customInput={<CustomDatePickerInput />}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container sx={{ width: "auto" }}>
          <Grid item>
            <LoadingButton
              loading={isMutating > 0}
              variant="contained"
              size="small"
              className={"btn-primary"}
              sx={{ marginTop: "21px" }}
              onClick={async () => await onExport()}
            >
              <UilExport /> Export
            </LoadingButton>
            <div style={{ marginTop: "10px", textAlign: "right" }}>
              <MaterialLink
                component="button"
                className="btn-link"
                onClick={() => {
                  const allExpanded = !isAllExpanded;
                  setIsAllExpanded(allExpanded);
                  setExpandStatus([...expandStatus.map((m) => allExpanded)]);
                }}
                sx={{ display: "inline" }}
              >
                {isAllExpanded ? "Collapse all" : "Expand all"}
                {isAllExpanded ? (
                  <UilAngleUp style={{ verticalAlign: "middle" }} />
                ) : (
                  <UilAngleDown style={{ verticalAlign: "middle" }} />
                )}
              </MaterialLink>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface LeasingPipelineViewProps {
  leasingPipeline: LeasingPipeline;
  expandStatus: boolean[];
  onExpandStatusChanged: (index: number, expanded: boolean) => void;
  year: number | undefined;
  month: number | undefined;
  vts: boolean;
}

const LeasingPipelineView: React.FunctionComponent<LeasingPipelineViewProps> = (
  props
) => {
  const classes = useStyles();

  return (
    <Fragment>
      <TableContainer>
        <Table
          aria-label="Leasing Pipeline"
          className={"table-leasing-pipeline"}
          style={{ tableLayout: "fixed", marginBottom: "15px" }}
        >
          <TableHead>
            {props.leasingPipeline.groups.map((group, index) => (
              <TableRow key={index}></TableRow>
            ))}
          </TableHead>
          <TableBody>
            <TableRow>
              {props.leasingPipeline.groups.map((group, index) => (
                <TableCell
                  key={index}
                  className={classes.tableLeasingPipelineCell}
                >
                  <Typography
                    variant="subtitle1"
                    color={
                      props.vts ? "primary.dark" : "var(--neutral-grey-300)"
                    }
                  >
                    {group.title}
                  </Typography>
                  <Box className="leasing-chip" sx={{ marginTop: "10px" }}>
                    <Typography
                      variant="body3"
                      color={
                        props.vts ? "primary.dark" : "var(--neutral-grey-300)"
                      }
                      component="span"
                    >
                      {props.vts ? group.count : 0}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {props.vts &&
        props.leasingPipeline.groups.map((group, index) => (
          <Accordion
            key={index}
            disabled={group.count === 0}
            expanded={props.expandStatus[index + 1] || false}
            onChange={(event, expanded) => {
              props.onExpandStatusChanged(index + 1, expanded);
            }}
            className={
              "accordion accordion-container-leasing accordion-container-leasing-sub accordion-container-leasing-sub-pipeline"
            }
          >
            <AccordionSummary
              expandIcon={<UilAngleDown />}
              aria-controls="panel1a-content"
              id="panel2a-header"
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h2" color="primary.dark">
                    {group.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box className="leasing-chip leasing-chip-top">
                    <Typography
                      variant="body3"
                      color="primary.dark"
                      component="span"
                    >
                      {group.count}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <Box>
                <TableContainer>
                  <Table
                    className={"table-leasing-pipeline-sub"}
                    aria-label="Leasing Pipeline: Inquiries"
                  >
                    <TableBody>
                      {props.expandStatus[index + 1] &&
                        group.deals.map((deal, index) => (
                          <LeasingPipelineDealView
                            key={index}
                            deal={deal}
                            year={props.year}
                            month={props.month}
                          />
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
    </Fragment>
  );
};

const MemoLeasingPipelineView = memo(LeasingPipelineView);

interface LeasingPipelineDealProps {
  deal: LeasingActivityDeal;
  year: number | undefined;
  month: number | undefined;
}

const LeasingPipelineDealView: React.FunctionComponent<
  LeasingPipelineDealProps
> = (props) => {
  const defaultMissingText = "";
  const propertyInfo = usePropertyInfo();

  const [dealProposalsFilter, setDealProposalsFilter] =
    useState<DealProposalsFilter>({
      propertyId: propertyInfo.id,
      accountIdentifier: propertyInfo.accountIdentifier,
      year: props.year,
      month: props.month,
    });
  const [dealSpacesFilter, setDealSpacesFilter] = useState<DealSpacesFilter>({
    propertyId: propertyInfo.id,
    accountIdentifier: propertyInfo.accountIdentifier,
  });
  const [dealContactsFilter, setDealContactsFilter] =
    useState<DealContactsFilter>({
      propertyId: propertyInfo.id,
      accountIdentifier: propertyInfo.accountIdentifier,
    });
  const [dealCommentsFilter, setDealCommentsFilter] =
    useState<DealCommentsFilter>({
      propertyId: propertyInfo.id,
      accountIdentifier: propertyInfo.accountIdentifier,
    });

  const { deal } = props;

  return (
    <Fragment>
      <TableRow>
        <TableCell component="th" scope="row" colSpan={5}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h2" color="primary.dark">
                {deal.tenantName}
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip
                title="Comments"
                placement="top"
                className="icon-leasing-pipeline"
              >
                <Button
                  onClick={() => {
                    setDealCommentsFilter({
                      ...dealCommentsFilter,
                      dealId: deal.dealId,
                    });
                  }}
                >
                  <UilComment />
                  <Typography variant="body1" color="primary.main">
                    {deal.totalComments}
                  </Typography>
                </Button>
              </Tooltip>
              <Tooltip
                title="Terms"
                placement="top"
                className="icon-leasing-pipeline"
              >
                <Button
                  onClick={() => {
                    setDealProposalsFilter({
                      ...dealProposalsFilter,
                      dealId: deal.dealId,
                    });
                  }}
                >
                  <UilFile />
                  <Typography variant="body1" color="primary.main">
                    {deal.totalDealProposals}
                  </Typography>
                </Button>
              </Tooltip>
              <Tooltip
                title="Contacts"
                placement="top"
                className="icon-leasing-pipeline"
              >
                <Button
                  onClick={() => {
                    setDealContactsFilter({
                      ...dealContactsFilter,
                      dealId: deal.dealId,
                    });
                  }}
                >
                  <UilUser />
                  <Typography variant="body1" color="primary.main">
                    {deal.totalDealContacts}
                  </Typography>
                </Button>
              </Tooltip>
              <Tooltip
                title="Spaces"
                placement="top"
                className="icon-leasing-pipeline"
              >
                <Button
                  onClick={() => {
                    setDealSpacesFilter({
                      ...dealSpacesFilter,
                      dealId: deal.dealId,
                    });
                  }}
                >
                  <UilVectorSquare />
                  <Typography variant="body1" color="primary.main">
                    {deal.totalSpaces}
                  </Typography>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row" sx={{ width: "10%" }}>
          <Typography variant="subtitle1" color="neutral700.light">
            Date
          </Typography>
          <Typography variant="body1" color="primary.dark">
            {deal.updatedDate
              ? formatDateFNS(deal.updatedDate, "MM/dd/yyyy")
              : defaultMissingText}
          </Typography>
          <Typography variant="body1" color="primary.dark">
            {deal.dealType} Lease
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "25%" }}>
          <Typography variant="subtitle1" color="neutral700.light">
            Requirement Details
          </Typography>
          <Typography variant="body1" color="primary.dark">
            {deal.tenantRsfFrom !== 0 && deal.tenantRsfTo
              ? deal.tenantRsfFrom.toString() +
                "-" +
                deal.tenantRsfTo.toString()
              : "-"}
          </Typography>
          <Typography variant="body1" color="primary.dark">
            Type: {deal.propertyType}
          </Typography>
          <Typography variant="body1" color="primary.dark">
            Target Commecement:{" "}
            {deal.commencementDate
              ? formatDateFNS(deal.commencementDate, "MM/dd/yyyy")
              : ""}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "15%" }}>
          <Typography variant="subtitle1" color="neutral700.light">
            Broker
          </Typography>
          <Typography variant="body1" color="primary.dark">
            {(deal.tenantBrokerFirstName || "") +
              " " +
              (deal.tenantBrokerLastName || "")}
          </Typography>
          <Typography variant="body1" color="primary.dark">
            {deal.tenantBrokerCompany}
          </Typography>
          <Typography variant="body1" color="primary.dark">
            {formatPhoneNumber(deal.tenantBrokerPhone || "")}
          </Typography>
          <Typography variant="body1" color="primary.dark">
            {deal.tenantBrokerEmail && (
              <Link
                to="#"
                onClick={(e) => {
                  window.location.replace("mailto:" + deal.tenantBrokerEmail);
                  e.preventDefault();
                }}
              >
                {deal.tenantBrokerEmail}
              </Link>
            )}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "10%" }}>
          <Typography
            variant="subtitle1"
            color="neutral700.light"
            align="right"
          >
            Total Area
          </Typography>
          <Typography variant="body1" color="primary.dark" align="right">
            {deal.spaceRsf ? formatNumber(deal.spaceRsf) : ""} SF
          </Typography>
        </TableCell>
        <TableCell sx={{ paddingLeft: "5%", width: "35%" }}>
          <Typography variant="subtitle1" color="neutral700.light">
            Property Building
          </Typography>
          <Typography variant="body1" color="primary.dark">
            {deal.buildingTitle}
          </Typography>
        </TableCell>
      </TableRow>

      {dealProposalsFilter.dealId && (
        <DealProposals
          filter={dealProposalsFilter}
          handlePopupClose={() => {
            setDealProposalsFilter({
              ...dealProposalsFilter,
              dealId: undefined,
            });
          }}
        />
      )}
      {dealSpacesFilter.dealId && (
        <DealSpaces
          filter={dealSpacesFilter}
          handlePopupClose={() => {
            setDealSpacesFilter({
              ...dealSpacesFilter,
              dealId: undefined,
            });
          }}
        />
      )}
      {dealContactsFilter.dealId && (
        <DealContacts
          filter={dealContactsFilter}
          handlePopupClose={() => {
            setDealContactsFilter({
              ...dealContactsFilter,
              dealId: undefined,
            });
          }}
        />
      )}
      {dealCommentsFilter.dealId && (
        <DealComments
          filter={dealCommentsFilter}
          handlePopupClose={() => {
            setDealCommentsFilter({
              ...dealCommentsFilter,
              dealId: undefined,
            });
          }}
        />
      )}
    </Fragment>
  );
};

interface LeasingActivityViewProps {
  leasingActivityInfo: LeasingActivityInfo;
  expandStatus: boolean[];
  onExpandStatusChanged: (index: number, expanded: boolean) => void;
  leasingGroupsCount: number;
}

const LeasingActivityView: React.FunctionComponent<LeasingActivityViewProps> = (
  props
) => {
  return (
    <Fragment>
      <Accordion
        expanded={props.expandStatus[props.leasingGroupsCount + 2]}
        disabled={props.leasingActivityInfo.newLeases.itemCount === 0}
        onChange={(event, expanded) => {
          props.onExpandStatusChanged(props.leasingGroupsCount + 2, expanded);
        }}
        className={
          "accordion accordion-container-leasing accordion-container-leasing-sub"
        }
      >
        <AccordionSummary
          expandIcon={
            <Box className="icon-expand">
              <UilAngleDown />
            </Box>
          }
          aria-controls="panel1g-content"
          id="panel1g-header"
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography variant="h2" color="primary.dark">
                    New Leases
                  </Typography>
                </Grid>
                <Grid item ml={1}>
                  <Box className="leasing-chip leasing-chip-top">
                    <Typography
                      variant="body3"
                      color="primary.dark"
                      component="span"
                    >
                      {formatNumber(
                        props.leasingActivityInfo.newLeases.totalRsf
                      )}{" "}
                      RSF
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box className="leasing-chip leasing-chip-top">
                <Typography
                  variant="body3"
                  color="primary.dark"
                  component="span"
                >
                  {props.leasingActivityInfo.newLeases.itemCount}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          className={"accordion-details accordion-details-leasing-pipeline"}
          hidden={props.leasingActivityInfo.newLeases.itemCount === 0}
        >
          <Box>
            <TableContainer className="table-leasing-activity">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={"20%"}>Tenant</TableCell>
                    <TableCell width={"20%"}>Building</TableCell>
                    <TableCell width={"11%"} align="right">
                      Space
                    </TableCell>
                    <TableCell width={"11%"} align="right">
                      RSF
                    </TableCell>
                    <TableCell width={"11%"} align="right">
                      Executed
                    </TableCell>
                    <TableCell width={"11%"} align="right">
                      Start
                    </TableCell>
                    <TableCell width={"13%"} align="right">
                      Term
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.leasingActivityInfo.newLeases.items.map(
                    (lease, index) => (
                      <TableRow key={index}>
                        <TableCell>{lease.tenantName}</TableCell>
                        <TableCell>{lease.buildingTitle}</TableCell>
                        <TableCell align="right">{lease.spaceTitle}</TableCell>
                        <TableCell align="right">
                          {lease.rsf !== undefined
                            ? formatNumber(lease.rsf)
                            : "-"}
                        </TableCell>
                        <TableCell align="right">
                          {lease.executedDate
                            ? formatDateFNS(lease.executedDate, "MM/dd/yyyy")
                            : "-"}
                        </TableCell>
                        <TableCell align="right">
                          {lease.startDate
                            ? formatDateFNS(lease.startDate, "MM/dd/yyyy")
                            : "-"}
                        </TableCell>
                        <TableCell align="right">
                          {lease.term
                            ? formatDuration({
                                years:
                                  lease.term > 0
                                    ? Math.floor(lease.term / 12)
                                    : 0,
                                months: lease.term % 12,
                              })
                            : "-"}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={props.expandStatus[props.leasingGroupsCount + 3]}
        disabled={props.leasingActivityInfo.renewalLeases.itemCount === 0}
        onChange={(event, expanded) => {
          props.onExpandStatusChanged(props.leasingGroupsCount + 3, expanded);
        }}
        className={
          "accordion accordion-container-leasing accordion-container-leasing-sub"
        }
      >
        <AccordionSummary
          expandIcon={
            <Box className="icon-expand">
              <UilAngleDown />
            </Box>
          }
          aria-controls="panel2g-content"
          id="panel2g-header"
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography variant="h2" color="primary.dark">
                    Renewal Leases
                  </Typography>
                </Grid>
                <Grid item ml={1}>
                  <Box className="leasing-chip leasing-chip-top">
                    <Typography
                      variant="body3"
                      color="primary.dark"
                      component="span"
                    >
                      {formatNumber(
                        props.leasingActivityInfo.renewalLeases.totalRsf
                      )}{" "}
                      RSF
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box className="leasing-chip leasing-chip-top">
                <Typography
                  variant="body3"
                  color="primary.dark"
                  component="span"
                >
                  {props.leasingActivityInfo.renewalLeases.itemCount}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          className={"accordion-details accordion-details-leasing-pipeline"}
          hidden={props.leasingActivityInfo.renewalLeases.itemCount === 0}
        >
          <Box>
            <TableContainer className="table-leasing-activity">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={"20%"}>Tenant</TableCell>
                    <TableCell width={"20%"}>Building</TableCell>
                    <TableCell width={"11%"} align="right">
                      Space
                    </TableCell>
                    <TableCell width={"11%"} align="right">
                      RSF
                    </TableCell>
                    <TableCell width={"11%"} align="right">
                      Executed
                    </TableCell>
                    <TableCell width={"11%"} align="right">
                      Start
                    </TableCell>
                    <TableCell width={"13%"} align="right">
                      Term
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.leasingActivityInfo.renewalLeases.items.map(
                    (lease, index) => (
                      <TableRow key={index}>
                        <TableCell>{lease.tenantName}</TableCell>
                        <TableCell>{lease.buildingTitle}</TableCell>
                        <TableCell align="right">{lease.spaceTitle}</TableCell>
                        <TableCell align="right">
                          {lease.rsf !== undefined
                            ? formatNumber(lease.rsf)
                            : "-"}
                        </TableCell>
                        <TableCell align="right">
                          {lease.executedDate
                            ? formatDateFNS(lease.executedDate, "MM/dd/yyyy")
                            : "-"}
                        </TableCell>
                        <TableCell align="right">
                          {lease.startDate
                            ? formatDateFNS(lease.startDate, "MM/dd/yyyy")
                            : "-"}
                        </TableCell>
                        <TableCell align="right">
                          {lease.term
                            ? formatDuration({
                                years:
                                  lease.term > 0
                                    ? Math.floor(lease.term / 12)
                                    : 0,
                                months: lease.term % 12,
                              })
                            : "-"}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={props.expandStatus[props.leasingGroupsCount + 4]}
        disabled={props.leasingActivityInfo.tenantMoveOuts.itemCount === 0}
        onChange={(event, expanded) => {
          props.onExpandStatusChanged(props.leasingGroupsCount + 4, expanded);
        }}
        className={
          "accordion accordion-container-leasing accordion-container-leasing-sub"
        }
      >
        <AccordionSummary
          expandIcon={
            <Box className="icon-expand">
              <UilAngleDown />
            </Box>
          }
          aria-controls="panel3g-content"
          id="panel3g-header"
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography variant="h2" color="primary.dark">
                    Move Outs
                  </Typography>
                </Grid>
                <Grid item ml={1}>
                  <Box className="leasing-chip leasing-chip-top">
                    <Typography
                      variant="body3"
                      color="primary.dark"
                      component="span"
                    >
                      {formatNumber(
                        props.leasingActivityInfo.tenantMoveOuts.totalRsf
                      )}{" "}
                      RSF
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box className="leasing-chip leasing-chip-top">
                <Typography
                  variant="body3"
                  color="primary.dark"
                  component="span"
                >
                  {props.leasingActivityInfo.tenantMoveOuts.itemCount}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          className={"accordion-details accordion-details-leasing-pipeline"}
          hidden={props.leasingActivityInfo.tenantMoveOuts.itemCount === 0}
        >
          <Box>
            <TableContainer className="table-leasing-activity">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={"20%"}>Tenant</TableCell>
                    <TableCell width={"20%"}>Building</TableCell>
                    <TableCell width={"12%"} align="right">
                      Space
                    </TableCell>
                    <TableCell width={"12%"} align="right">
                      RSF
                    </TableCell>
                    <TableCell width={"12%"} align="right">
                      Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.leasingActivityInfo.tenantMoveOuts.items.map(
                    (moveOut, index) => (
                      <TableRow key={index}>
                        <TableCell>{moveOut.tenantName}</TableCell>
                        <TableCell>{moveOut.buildingTitle}</TableCell>
                        <TableCell align="right">
                          {moveOut.spaceTitle}
                        </TableCell>
                        <TableCell align="right">
                          {moveOut.rsf !== undefined
                            ? formatNumber(moveOut.rsf)
                            : "-"}
                        </TableCell>
                        <TableCell align="right">
                          {moveOut.moveOutDate
                            ? formatDateFNS(moveOut.moveOutDate, "MM/dd/yyyy")
                            : "-"}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={props.expandStatus[props.leasingGroupsCount + 5]}
        disabled={props.leasingActivityInfo.expirationSchedule.itemCount === 0}
        onChange={(event, expanded) => {
          props.onExpandStatusChanged(props.leasingGroupsCount + 5, expanded);
        }}
        className={
          "accordion accordion-container-leasing accordion-container-leasing-sub"
        }
      >
        <AccordionSummary
          expandIcon={
            <Box className="icon-expand">
              <UilAngleDown />
            </Box>
          }
          aria-controls="panel4g-content"
          id="panel4g-header"
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography variant="h2" color="primary.dark">
                    Lease Expiration Schedule
                  </Typography>
                </Grid>
                <Grid item ml={1}>
                  <Box className="leasing-chip leasing-chip-top">
                    <Typography
                      variant="body3"
                      color="primary.dark"
                      component="span"
                    >
                      {formatNumber(
                        props.leasingActivityInfo.expirationSchedule.totalRsf
                      )}{" "}
                      RSF
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box className="leasing-chip leasing-chip-top">
                <Typography
                  variant="body3"
                  color="primary.dark"
                  component="span"
                >
                  {props.leasingActivityInfo.expirationSchedule.itemCount}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          className={"accordion-details accordion-details-leasing-pipeline"}
          hidden={props.leasingActivityInfo.expirationSchedule.itemCount === 0}
        >
          <Box>
            <TableContainer className="table-leasing-activity">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={"20%"}>Tenant</TableCell>
                    <TableCell width={"20%"}>Building</TableCell>
                    <TableCell width={"12%"} align="right">
                      Space
                    </TableCell>
                    <TableCell width={"12%"} align="right">
                      RSF
                    </TableCell>
                    <TableCell width={"12%"} align="right">
                      LXD
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.leasingActivityInfo.expirationSchedule.items.map(
                    (expiration, index) => (
                      <TableRow key={index}>
                        <TableCell>{expiration.tenantName}</TableCell>
                        <TableCell>{expiration.buildingTitle}</TableCell>
                        <TableCell align="right">
                          {expiration.spaceTitle}
                        </TableCell>
                        <TableCell align="right">
                          {expiration.rsf !== undefined
                            ? formatNumber(expiration.rsf)
                            : "-"}
                        </TableCell>
                        <TableCell align="right">
                          {expiration.leaseExpirationDate
                            ? formatDateFNS(
                                expiration.leaseExpirationDate,
                                "MM/dd/yyyy"
                              )
                            : "-"}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
};
export default LeasingActivity;
