import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useIsFetching } from "@tanstack/react-query";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  Typography,
  Button,
  Dialog,
  OutlinedInput,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { UilCalender } from "@iconscout/react-unicons";
import { UilMultiply } from "@iconscout/react-unicons";

import {
  IPOSelectList,
  IWorkspaceStatusService,
} from "../model/propertyOverviewModel";
import { useSaveWorkspaceStatus } from "../api/propertyOverviewService";

interface Props {
  handlePopupClose: (shouldRefreshData?: boolean) => void;
  showDialog: boolean;
  workspaceStatus: IWorkspaceStatusService;
  propertyStatusSelectList: IPOSelectList[];
}

const errorColor = "#f43027";

const WorkspaceEdit: React.FC<Props> = (props) => {
  const { workspaceStatus, propertyStatusSelectList } = props;
  const isFetching = useIsFetching();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IWorkspaceStatusService>({
    defaultValues: {
      status: workspaceStatus.status || "",
      activationDate:
        workspaceStatus.activationDate === undefined
          ? null
          : workspaceStatus.activationDate,
      deactivatedDate:
        workspaceStatus.deactivatedDate === undefined
          ? null
          : workspaceStatus.deactivatedDate,
    },
  });

  const patchWorkspaceStatus = useSaveWorkspaceStatus();

  const onSubmit = handleSubmit((model: IWorkspaceStatusService) => {
    model.accountId = workspaceStatus.accountId;
    model.propertyId = workspaceStatus.propertyId;
    if (model.status === "") model.status = undefined;
    patchWorkspaceStatus.mutate(model, {
      onSuccess: () => {
        props.handlePopupClose(true);
      },
    });
  });

  const [isActivationDate, setIsActivationDate] = React.useState(null);
  const [isDeactivatedDate, setIsDeactivatedDate] = React.useState(null);
  const invalidDateString = "Please enter a valid date";
  
  const onError = (error: any, item: string) => {
    if (item === "activationDate") setIsActivationDate(error);
    else if (item === "deactivatedDate") setIsDeactivatedDate(error);
  };

  const isDisable = () => {
    if (
      patchWorkspaceStatus.isLoading ||
      isFetching > 0 ||
      isActivationDate !== null ||
      isDeactivatedDate !== null
    ) return true;
    else return false;
  };

  const SelectIcon = (props: Props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  return (
    <Dialog
      open={props.showDialog}
      aria-labelledby="asset-overview-edit"
      aria-describedby="asset-overview-edit-description"
      fullWidth
    >
      <DialogTitle id="asset-overview-edit">
        <Typography component="span" variant="h2" color="primary.dark">
          Edit Workspace Status
        </Typography>
        <IconButton
          aria-label="close"
          disabled={patchWorkspaceStatus.isLoading}
          onClick={() => props.handlePopupClose(false)}
          className="icon-dialog-close"
          size="large"
        >
          <UilMultiply />
        </IconButton>
        <Typography component="div" variant="h3" color="neutral700.light">
          Please select or enter values for the fields and click the save button to update Workspace status information.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <React.Fragment>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={1}>
              {/* Status */}
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name={"status"}
                  render={({ field, fieldState }) => (
                    <FormControl className={"form-control"}>
                      <Typography variant="body3" component="label">
                        Status
                      </Typography>
                      <Select
                        labelId="status"
                        displayEmpty
                        input={<OutlinedInput />}
                        className={"form-control-field"}
                        IconComponent={SelectIcon}
                        {...field}
                      >
                        {propertyStatusSelectList.map((propertyStatus) => (
                          <MenuItem
                            key={propertyStatus.key}
                            value={propertyStatus.key}
                          >
                            {propertyStatus.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {Boolean(errors.status) && (
                  <FormHelperText style={{ color: errorColor }}>
                    {errors.status?.message}
                  </FormHelperText>
                )}
              </Grid>
              {/* Activation Date */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography
                    variant="body3"
                    component="label"
                  >{`Activation Date`}</Typography>
                  <Controller
                    name="activationDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        inputRef={field.ref}
                        views={["year", "month", "day"]}
                        onChange={field.onChange}
                        value={field.value}
                        components={{
                          OpenPickerIcon: UilCalender,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // error={Boolean(errors?.activationDate)}
                            helperText={
                              isActivationDate !== null
                                ? invalidDateString
                                : null
                            }
                            className={
                              "form-control-field form-control-datepicker"
                            }
                          />
                        )}
                        onError={(error)=>onError(error, "activationDate")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              {/* Deactivated Date */}
              <Grid item xs={6}>
                <FormControl className={"form-control"}>
                  <Typography
                    variant="body3"
                    component="label"
                  >{`Deactivated Date`}</Typography>
                  <Controller
                    name="deactivatedDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        inputRef={field.ref}
                        views={["year", "month", "day"]}
                        onChange={field.onChange}
                        value={field.value}
                        components={{
                          OpenPickerIcon: UilCalender,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // error={Boolean(errors?.deactivatedDate)}
                            helperText={
                              isDeactivatedDate !== null
                                ? invalidDateString
                                : null
                            }
                            className={
                              "form-control-field form-control-datepicker"
                            }
                          />
                        )}
                        onError={(error)=>onError(error, "deactivatedDate")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </React.Fragment>
        {patchWorkspaceStatus.isLoading && (
          <Box m="auto">
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          disabled={patchWorkspaceStatus.isLoading}
          onClick={() => props.handlePopupClose(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={isDisable()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkspaceEdit;
