enum UserStatus {
  INACTIVE = "Inactive",
  ACTIVE = "Active",
  REQUEST_SENT = "Invitation Sent",
}


export const statusMapper = (status: number) => {
  let statusText: UserStatus;

  if (status === 0) statusText = UserStatus.INACTIVE;
  else if (status === 1) statusText = UserStatus.ACTIVE;
  else statusText = UserStatus.REQUEST_SENT;

  return statusText;
};
