import React, { useState } from "react";
import { ColumnState } from "ag-grid-community";

import { PortfoliosContextProps } from "./portfoliosContextProps";
import { FilterModel } from "../util/portfolioContextModels";
import {
  getFirstDateOfQuarter,
  getLastDateOfQuarter,
} from "../../../shared/util/dateHelper";

/**
 * Context for keeping track of the Portfolios state
 */
const PortfoliosStateContext = React.createContext<PortfoliosContextProps>({
  // Portfolios - Overview
  portfolioOverviewDate: new Date(),
  updatePortfolioOverviewDate: () => {},

  // Portfolios - Financials
  financialsStartDate: new Date(),
  financialsEndDate: new Date(),
  financialsGroupingMode: 0,
  financialsBudgetView: false,
  updateFinancialsStartDate: () => {},
  updateFinancialsEndDate: () => {},
  updateFinancialsGroupingMode: () => {},
  updateFinancialsBudgetView: () => {},

  // Portfolios - Budget
  budgetStartDate: new Date(),
  budgetEndDate: new Date(),
  budgetGroupingMode: 0,
  updateBudgetStartDate: () => {},
  updateBudgetEndDate: () => {},
  updateBudgetGroupingMode: () => {},

  // Portfolio - Rent Roll
  rentRollDate: new Date(),
  updateRentRollDate: () => {},

  // Portfolios - Leasing Activity
  laStartDate: new Date(),
  updateLAStartDate: () => {},

  // Portfolios - Properties - state
  propertiesStartDate: getFirstDateOfQuarter(new Date()),
  propertiesEndDate: getLastDateOfQuarter(new Date()),
  updatePropertiesStartDate: () => {},
  updatePropertiesEndDate: () => {},

  // Portfolio  Properties - Grid
  portfolioPropertiesFilterModel: {},
  portfolioPropertiesColumnState: [],
  updatePortfolioPropertiesFilterModel: () => {},
  updatePortfolioPropertiesColumnState: () => {},

  // Portfolio - A/R state
  arDate: new Date(),
  updateArDate: () => {},

  // Portfolio - Loan state
  loansDate: new Date(),
  updateLoansDate: () => {},

    // Portfolio - Snapshot state
  snapshotDate: new Date(),
  updateSnapshotDate: () => {},
});

const getPreviousMonthDate = () => {
  const date = new Date();
  date.setDate(1);
  date.setMonth(date.getMonth() - 1);

  return date;
};

interface PortfoliosStateProviderProps {
  children: React.ReactNode;
}

export const PortfoliosStateProvider: React.FunctionComponent<
  PortfoliosStateProviderProps
> = (props) => {
  // STATE

  // Portfolios - Overview - state
  const [portfolioOverviewDate, setPortfolioOverviewDate] = useState(
    getPreviousMonthDate()
  );

  // Portfolios - Financials - state
  const [financialsStartDate, setFinancialsStartDate] = useState(new Date());
  const [financialsEndDate, setFinancialsEndDate] = useState(new Date());
  const [financialsGroupingMode, setFinancialsGroupingMode] =
    useState<number>(0);
  const [financialsBudgetView, setFinancialsBudgetView] =
    useState<boolean>(false);

  // Portfolios - Budget - state
  const [budgetStartDate, setBudgetStartDate] = useState(new Date());
  const [budgetEndDate, setBudgetEndDate] = useState(new Date());
  const [budgetGroupingMode, setBudgetGroupingMode] = useState<number>(0);

  // Portfolios - Rent Roll - State
  const [rentRollDate, setRentRollDate] = useState(new Date());

  // Portfolios - Leasing Activity - state
  const [laStartDate, setLAStartDate] = useState(new Date());

  // Portfolios - Properties - state
  const [propertiesStartDate, setPropertiesStartDate] = useState(
    getFirstDateOfQuarter(new Date())
  );
  const [propertiesEndDate, setPropertiesEndDate] = useState(
    getLastDateOfQuarter(new Date())
  );

  // Portfolio  Properties - Grid
  const [portfolioPropertiesColumnState, setPortfolioPropertiesColumnState] =
    useState<ColumnState[]>([]);
  const [portfolioPropertiesFilterModel, setPortfolioPropertiesFilterModel] =
    useState<FilterModel>({});

  // Portfolio - A/R state
  const [arDate, setArDate] = useState(new Date());

  // Portfolio - Loan state
  const [loansDate, setloansDate] = useState(new Date());
  const [snapshotDate, setSnapshotDate] = useState(new Date());

  // FUNCTIONS

  // Portfolios - Overview - state
  const updatePortfolioOverviewDate = (date: Date) => {
    setPortfolioOverviewDate(date);
  };

  // Portfolios - Financials - state
  const updateFinancialsStartDate = (date: Date) => {
    setFinancialsStartDate(date);
  };

  const updateFinancialsEndDate = (date: Date) => {
    setFinancialsEndDate(date);
  };

  const updateFinancialsGroupingMode = (mode: number) => {
    setFinancialsGroupingMode(mode);
  };

  const updateFinancialsBudgetView = (budgetView: boolean) => {
    setFinancialsBudgetView(budgetView);
  };

  // Portfolios - Budget - state
  const updateBudgetStartDate = (date: Date) => {
    setBudgetStartDate(date);
  };

  const updateBudgetEndDate = (date: Date) => {
    setBudgetEndDate(date);
  };

  const updateBudgetGroupingMode = (mode: number) => {
    setBudgetGroupingMode(mode);
  };

  // Portfolios - Rent Roll - state
  const updateRentRollDate = (newDate: Date) => {
    setRentRollDate(newDate);
  };

  // Portfolios - Leasing Activity - state
  const updateLAStartDate = (date: Date) => {
    setLAStartDate(date);
  };

  // portfolios - Properties - state
  const updatePropertiesStartDate = (date: Date) => {
    setPropertiesStartDate(date);
  };

  const updatePropertiesEndDate = (date: Date) => {
    setPropertiesEndDate(date);
  };

  // Portfolio Properties - Grid
  const updatePortfolioPropertiesFilterModel = (filterModel: FilterModel) => {
    setPortfolioPropertiesFilterModel(filterModel);
  };

  const updatePortfolioPropertiesColumnState = (columnState: ColumnState[]) => {
    setPortfolioPropertiesColumnState(columnState);
  };

  // Portfolio - A/R state
  const updateArDate = (date: Date) => {
    setArDate(date);
  };

  // Portfolio - Loan state
  const updateLoansDate = (date: Date) => {
    setloansDate(date);
  };

    // Portfolio - Snapshot state
    const updateSnapshotDate = (date: Date) => {
      setSnapshotDate(date);
    };

  return (
    <PortfoliosStateContext.Provider
      value={{
        // portfolios - overview
        portfolioOverviewDate,
        updatePortfolioOverviewDate,

        // portfolios - financials
        financialsStartDate,
        financialsEndDate,
        financialsGroupingMode,
        financialsBudgetView,

        // portfolios - budget
        budgetStartDate,
        budgetEndDate,
        budgetGroupingMode,

        // portfolios - rent roll
        rentRollDate,
        updateRentRollDate,

        // portfolios - leasing activity
        laStartDate,

        // portfolios - properties
        propertiesStartDate,
        propertiesEndDate,

        // portfolios - budget
        updateBudgetStartDate,
        updateBudgetEndDate,
        updateBudgetGroupingMode,

        // portfolios - financials
        updateFinancialsStartDate,
        updateFinancialsEndDate,
        updateFinancialsGroupingMode,
        updateFinancialsBudgetView,

        // portfolios - leasing activity
        updateLAStartDate,

        // portfolios - properties
        updatePropertiesStartDate,
        updatePropertiesEndDate,

        // Portfolio Properties - Grid
        portfolioPropertiesFilterModel,
        portfolioPropertiesColumnState,
        updatePortfolioPropertiesFilterModel,
        updatePortfolioPropertiesColumnState,

        // portfolios - A/R local
        arDate,
        updateArDate,

        // portfolios - Loan local
        loansDate,
        updateLoansDate,

        //portfolios - Snapshot
        snapshotDate,
        updateSnapshotDate,
      }}
    >
      {props.children}
    </PortfoliosStateContext.Provider>
  );
};

export default PortfoliosStateContext;
