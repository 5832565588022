import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  Theme,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import { useGetAccounts } from "../../properties/property/api/accountService";
import { TAccount } from "../../properties/property/model/account";
import SimpleAlert from "../../UI/view/SimpleAlert";
import { useGetAccountRoles } from "../api/userService";
import { TUserListFilter } from "../model/userListFilter";
import UserContext from "../util/userContext";
import { useUserInfo } from "../util/userInfoContext";
import { EAccountPermission } from "../model/editUserModel";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
      marginBottom: 16,
    },
  })
);

interface UserListFilterProps {
  setFilter: (accountIdentifier: string, filter: string) => void;
  handlePopupOpen: (shouldBeOpened: boolean) => void;
}

const UserListSearch: React.FC<UserListFilterProps> = (props) => {
  const {
    userAccountDDValue,
    updateUserAccountDDValue,
    latestAccountOptions,
    updateLatestAccountOptions,
    // Alert message
    alertMessage,
    updateAlertMessage,
  } = useContext(UserContext);
  const classes = useStyles();
  const userInfo = useUserInfo();

  // account roles - to determine which accounts the current user has admin access to
  const { data: accountRoles, isSuccess: accountRolesRetrieved } =
    useGetAccountRoles();

  // retrieving account options
  const accountOptionsQuery = useGetAccounts();
  const { data: accountOptions, isSuccess } = accountOptionsQuery;
  const [userFilter, setUserFilter] = useState<TUserListFilter>({
    accountIdentifier: userInfo.accountIdentifier,
    value: "",
  });

  useEffect(() => {
    // if we did not retrieve account options, we return
    if (
      !isSuccess ||
      !accountRolesRetrieved ||
      accountRoles === undefined ||
      accountOptions === undefined ||
      userAccountDDValue.length !== 0
    )
      return;

    const updatedAccountOptions: TAccount[] = [];
    const traversedIds: string[] = [];
    for (let i = 0; i < accountRoles.accountUserRoles.length; i++) {
      const accId = accountRoles.accountUserRoles[i].accountIdentifier;
      if (traversedIds.includes(accId)) continue;

      accountRoles.accountUserRoles[i].roles.forEach((role) => {
        const roleName = role.name as EAccountPermission;
        if (
          roleName === EAccountPermission.ADMINISTRATION ||
          roleName === EAccountPermission.ADMINISTER_USERS
        ) {
          accountOptions.forEach((accountOption) => {
            if (accountOption.identifier === accId)
              updatedAccountOptions.push({ ...accountOption });
          });
        }
      });
      traversedIds.push(accId);
    }

    updatedAccountOptions.sort((a, b) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

    const firstAccountId = updatedAccountOptions[0]?.identifier || "";
    const selectedAccount =
      userAccountDDValue.trim().length === 0
        ? firstAccountId
        : userAccountDDValue;
    updateUserAccountDDValue(selectedAccount);
    props.setFilter(selectedAccount, "");

    if (updatedAccountOptions.length > 0)
      updateLatestAccountOptions(updatedAccountOptions);
  }, [
    accountOptions,
    accountRoles,
    accountRolesRetrieved,
    isSuccess,
    props,
    updateLatestAccountOptions,
    updateUserAccountDDValue,
    userAccountDDValue,
  ]);

  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value;

    updateUserAccountDDValue(newValue);
    setUserFilter({
      ...userFilter,
      accountIdentifier: newValue,
    });
    props.setFilter(newValue, "");
  };

  const SelectIcon = (props: any) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  return (
    <React.Fragment>
      {alertMessage.message.trim().length > 0 && (
        <SimpleAlert
          severityType={alertMessage.severityType}
          message={alertMessage.message}
          onClose={() =>
            updateAlertMessage({
              severityType: "success",
              message: "",
            })
          }
          alertStyles={{ width: "100%" }}
          styles={{ position: "inherit", width: "100%"}}
        />
      )}
      <Grid container className="property-action-bar">
        <Grid item>
          <Grid container sx={{ width: "auto", }}>
            <Grid item>
              {accountRolesRetrieved &&
                isSuccess &&
                latestAccountOptions.length > 1 && (
                  <FormControl
                    variant="outlined"
                    size="small"
                    //className={classes.formControl}
                  >
                    <Select
                      value={userAccountDDValue}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className={"input-field"}
                      IconComponent={SelectIcon}
                    >
                      {latestAccountOptions.map((dropdownOption: TAccount) => (
                        <MenuItem
                          value={dropdownOption.identifier}
                          key={dropdownOption.identifier}
                        >
                          <Typography variant="body1">
                            {dropdownOption.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container sx={{ width: "auto", }}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<AddCircleOutlineRoundedIcon />}
                onClick={() => {
                  props.handlePopupOpen(true);
                }}
              >
                Add new user
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default UserListSearch;
