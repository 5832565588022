import React from "react";
import { DashboardProps } from "../../models/dashboardProps";
import {
  getDateTickLabelsFormatter,
  formatNumber,
} from "../../utils/widgetUtils";
import DashboardItem from "./dashboardItem";

const VacantAreaWidget: React.FunctionComponent<DashboardProps> = (props) => {
  const chartOptions = {
    autoSize: true,
    data: props.data?.info,
    series: [
      {
        type: "area",
        xKey: "period",
        yKey: "actual",
        yName: "Actual",
        tooltip: {
          renderer: (params: any) => {
            return {
              title: params.xValue,
              content: formatNumber(params.yValue, 0),
            };
          },
        },
      },
    ],
    legend: {
      color: "Orange",
    },
    axes: [
      {
        type: "category",
        position: "bottom",
        label: {
          rotation: 320,
          formatter: getDateTickLabelsFormatter(props.data?.info),
        },
      },
      {
        type: "number",
        position: "left",
        tick: {
          count: 5,
        },
      },
    ],
  };

  return (
    <DashboardItem
      {...props.data}
      options={chartOptions}
      title={props.data?.title || "Vacant (Area)"}
      tooltip="All tenant spaces not currently occupied represented in RSF (excludes future leases)"
      highlightHeader={props.highlightHeader}
    />
  );
};

export default VacantAreaWidget;
