import { LeaseExpirationTerm } from "../model/stackingPlan";

export function getHoverBorderColor(
  leaseExpirationTermString: string,
  isFutureLease: boolean
) {
  const leaseExpirationTermEnum =
    LeaseExpirationTerm[
      leaseExpirationTermString as keyof typeof LeaseExpirationTerm
    ];
  if (isFutureLease) {
    return "#fbba00";
  }
  switch (leaseExpirationTermEnum) {
    case LeaseExpirationTerm.Current:
      return "#ff4c57";
    case LeaseExpirationTerm.Year1:
      return "#f19576";
    case LeaseExpirationTerm.Year2:
      return "rgba(37, 165, 103, 1.0)";
    case LeaseExpirationTerm.Year3:
      return "rgba(37, 165, 103, 1.0)";
    case LeaseExpirationTerm.Year4Plus:
      return "#b6846d";
    case LeaseExpirationTerm.MonthToMonth:
      return "rgba(168, 111, 245, 1.0)";
    case LeaseExpirationTerm.Vacant:
      return "rgba(99, 126, 151, 1.0)";
  }
}
