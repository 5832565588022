import { StatusPanelDef } from "ag-grid-community";

export const getStatusBar = () => {
    const statusBar: { statusPanels: StatusPanelDef[] } = {
        statusPanels: [
            {
                statusPanel: 'agAggregationComponent',
                statusPanelParams: {
                    aggFuncs: ['count', 'sum', 'min', 'max', 'avg']
                },
            },
        ],
    };

    return statusBar;
};