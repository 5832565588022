import {
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  ClickAwayListener,
} from "@mui/material";
import headerStyles from "../headerStyles";
import { Link } from "react-router-dom";

interface ReportingMenuProps {
  setLeftMenuReportingCollapseClose: () => void;
}

const ReportingMenu: React.FunctionComponent<ReportingMenuProps> = (props) => {
  const classes = headerStyles();
  return (
    <ClickAwayListener
      onClickAway={() => {
        props.setLeftMenuReportingCollapseClose();
      }}
    >
      <List
        component="div"
        aria-labelledby="leftMenuReportingCollapseContainer"
        disablePadding
        className={classes.leftMenuList}
        onClick={() => props.setLeftMenuReportingCollapseClose()}
        subheader={
          <ListSubheader
            component="div"
            id="leftMenuReportingCollapseContainer"
          ></ListSubheader>
        }
      >
        <ListItem button component={Link} to="/">
          <ListItemText primary="Accounting" />
        </ListItem>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Assets" />
        </ListItem>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Properties" />
        </ListItem>
      </List>
    </ClickAwayListener>
  );
};

export default ReportingMenu;
