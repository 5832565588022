export interface IPropertyOverviewType {
  propertyOverview: IPropertyOverview;
  propertyStatusSelectList: IPOSelectList[];
  assetClassSelectList: IPOSelectList[];
  assetTypeSelectList: IPOSelectList[];
  accountingSystemsSelectList: IPOSelectList[];
  investorRelationsSystemsSelectList: IPOSelectList[];
  leasingSystemsSelectList: IPOSelectList[];
  propertyManagementSystemsSelectList: IPOSelectList[];
  valuationSystemsSelectList: IPOSelectList[];
  tenantExperienceSystemsSelectList: IPOSelectList[];
  usStatesList: IPOStateList[];
  valuations: IValuation[];
  capitalPartners: ICapitalPartner[];
}

export interface IPOStateList {
  key: string;
  value: string;
}

export interface IPOSelectList {
  key: number;
  value: string;
}

export interface ICapitalPartner {
  id?: string;
  capitalPartnerName: string;
  capitalPartnerOwnership: number;
  isOwner: boolean;
}

export type TStatus = {
  active: "Active";
  inactive: "Inactive";
};

export interface IAssetOverview {
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  assetOverviewNotes?: string;
  yearBuilt?: Date | null;
  numBuildings?: number;
  numFloors?: number;
  assetClassId?: number;
  assetTypeId?: number;
  imageUrl?: string;
}

export interface IAssetOverviewImage {
  tags?: Tag[];
  documentType?: string;
  file?: any;
  label?: string;
}

export type Tag = {
  type: string;
  value: string;
};

export const assetOverviewFields = [
  "assetTypeId",
  "assetClassId",
  "yearBuilt",
  "numBuildings",
  "numFloors",
  "assetOverviewNotes",
];

export const assetOverviewAddressFields = ["street", "city", "state", "zip"];

export interface IWorkspaceStatus {
  status?: TStatus;
  activationDate?: Date;
  deactivatedDate?: Date;
}

export const workspaceStatusFields = [
  "status",
  "activationDate",
  "deactivatedDate",
];

export interface IPropertyDetails {
  lastRemeasureDate?: Date | null;
  deckToDeckHeight?: number;
  floorToGridHeight?: number;
  roofType?: string;
  roofRepairs?: string;
  roofReplacementDate?: Date | null;
  elevatorPermitDate?: Date | null;
  isSprinklered?: boolean;
  year5SprinklerInspectionDate?: Date | null;
  year3SprinklerInspectionDate?: Date | null;
  hasPressurizedStairwells?: boolean;
  floorType?: string;
  buildingStandardPaint?: string;
  buildingStandardCarpet?: string;
  commonAreaPaint?: string;
  commonAreaCarpet?: string;
  parkingType?: string;
  parkingStallCount?: number;
  parkingRatio?: number;
  parkingRates?: string;
  providerTelecom?: string;
  providerFiber?: string;
  utilityWaterCompany?: string;
  utilityElectricCompany?: string;
  utilityGasCompany?: string;
  buildingHours?: string;
  hvacHours?: string;
  hvacOvertimeRate?: string;
  lastRenovation?: string;
  lastRenovationDate?: Date | null;
}

export const propertyDetailsFields = [
  "lastRemeasureDate",
  "deckToDeckHeight",
  "floorToGridHeight",
  "roofType",
  "roofRepairs",
  "roofReplacementDate",
  "elevatorPermitDate",
  "isSprinklered",
  "year5SprinklerInspectionDate",
  "year3SprinklerInspectionDate",
  "hasPressurizedStairwells",
  "floorType",
  "buildingStandardPaint",
  "buildingStandardCarpet",
  "commonAreaPaint",
  "commonAreaCarpet",
  "parkingType",
  "parkingStallCount",
  "parkingRatio",
  "parkingRates",
  "providerTelecom",
  "providerFiber",
  "utilityWaterCompany",
  "utilityElectricCompany",
  "utilityGasCompany",
  "buildingHours",
  "hvacHours",
  "hvacOvertimeRate",
  "lastRenovation",
  "lastRenovationDate",
];

export interface IPersonnel {
  assetManagementCompany?: string;
  assetManagerName?: string;
  assetManagerContactEmail?: string;
  propertyManagementCompany?: string;
  propertyManagerName?: string;
  propertyManagerContactEmail?: string;
  leasingManagementCompany?: string;
  leasingManagerName?: string;
  leasingManagerContactEmail?: string;
  engineeringHVACManagementCompany?: string;
  engineeringHVACManagerName?: string;
  engineeringHVACManagerContactEmail?: string;
}

export const personnelFields = [
  "assetManagementCompany",
  "assetManagerName",
  "assetManagerContactEmail",
  "propertyManagementCompany",
  "propertyManagerName",
  "propertyManagerContactEmail",
  "leasingManagementCompany",
  "leasingManagerName",
  "leasingManagerContactEmail",
  "engineeringHVACManagementCompany",
  "engineeringHVACManagerName",
  "engineeringHVACManagerContactEmail",
];

export interface ISoftwareAndSystems {
  accountingSystemId?: string;
  accountingSystemDescription?: string;
  propertyManagementSystemId?: string;
  propertyManagementSystemDescription?: string;
  leasingSystemId?: string;
  leasingSystemDescription?: string;
  valuationSystemId?: string;
  valuationSystemDescription?: string;
  investorRelationsSystemId?: string;
  investorRelationsSystemDescription?: string;
  tenantExperienceSystemId?: string;
  tenantExperienceSystemDescription?: string;
  loanManagementSystem?: string;
  buildingAccessSecuritySystem?: string;
  fireLifeSafetySystem?: string;
  complianceSystem?: string;
  otherSystem?: string;
}

export const softwareAndSystemsFields = [
  "accountingSystemDescription",
  "propertyManagementSystemDescription",
  "leasingSystemDescription",
  "valuationSystemDescription",
  "investorRelationsSystemDescription",
  "tenantExperienceSystemDescription",
  "loanManagementSystem",
  "buildingAccessSecuritySystem",
  "fireLifeSafetySystem",
  "complianceSystem",
  "otherSystem",
];

export interface IWorkspaceStatusService {
  status?: string;
  activationDate?: Date | null;
  deactivatedDate?: Date | null;
  accountId: string;
  propertyId: string;
}

export interface IAssetOverviewService extends IAssetOverview {
  accountId: string;
  propertyId: string;
}

export interface IPropertyDetailsService extends IPropertyDetails {
  accountId: string;
  propertyId: string;
}

export interface IPersonnelService extends IPersonnel {
  accountId: string;
  propertyId: string;
}

export interface ISoftwareAndSystemsService extends ISoftwareAndSystems {
  accountId: string;
  propertyId: string;
}

export interface IInvestmentDetails {
  acquisitionDate?: Date | null;
  dispositionDate?: Date | null;
  investedEquity?: number;
  acquisitionPrice?: number;
  leverageAtAcquisition?: number;
  investmentDetailNotes?: string;
  valuationAtClose?: number;
  valuationAtCloseDate?: Date | null;
}

export interface InvestmentDetailsService extends IInvestmentDetails {
  accountId: string;
  propertyId: string;
  valuations: IValuation[];
  capitalPartners: ICapitalPartner[];
}

export const investmentDetailsFields = [
  "acquisitionDate",
  "dispositionDate",
  "investedEquity",
  "acquisitionPrice",
  "leverageAtAcquisition",
  "investmentDetailNotes",
  "valuationAtClose",
  "valuationAtCloseDate",
];

export interface IPropertyOverview
  extends IAssetOverview,
    IWorkspaceStatus,
    IPropertyDetails,
    IPersonnel,
    ISoftwareAndSystems,
    IInvestmentDetails {
  id: string;
  assetClassDescription?: string;
  assetTypeDescription?: string;
  ownershiptPct: number;
}

export interface IValuation {
  id?: string;
  valuation?: number;
  valuationDate: Date | null;
  lastModifiedAt?: Date;
  lastModifiedBy?: string;
  lastModifiedUserFullName?: string;
  createdBy?: string;
}
