import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  TextField,
  DialogActions,
  Button,
  Typography,
  Box,
  Switch,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { useForm } from "react-hook-form";

import { GridContextOptions } from "./SaveGridStateOptions";
import {
  requiredErrorMessage,
  textErrorMessage,
} from "../../components/properties/property details/util/errorTexts";
import DialogTitleCustom from "../../components/UI/DialogTitleCustom";

export const viewNameExistsErrorMessage =
  "The view name already exists for this page.";

const CustomizedDialog = styled(Dialog)`
  & .MuiDialog-paper {
    min-width: 35%;
  }
`;
const CustomizedFormControl = styled(FormControl)`
  margin: theme.spacing(1);
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  border-radius: 4;
`;

interface Props {
  handlePopupClose: () => void;
  onSubmit(
    name: string,
    isDefault: boolean,
    saveType?: GridContextOptions
  ): Promise<void>;
  gridNames: string[];
}

interface GridConfigCreateModel {
  name: string;
}

const CreateGridConfigModal: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<GridConfigCreateModel>();

  const [switchChecked, setSwitchChecked] = useState(false);

  const onSubmit = handleSubmit((model: GridConfigCreateModel) => {
    props.onSubmit(
      model.name.trim(),
      switchChecked,
      GridContextOptions.SAVE_AS
    );
  });

  return (
    <CustomizedDialog
      open={true}
      aria-labelledby="grid-config-create-title"
      aria-describedby="grid-config-create-description"
    >
      <form onSubmit={onSubmit}>
        <DialogTitleCustom title="Save View" onClose={props.handlePopupClose} />
        <DialogContent>
          <DialogContentText
            id="grid-config-create-description"
            style={{ paddingTop: 0 }}
          >
            <Typography component="div" variant="h3" color="neutral700.light">
              Give a name to your customized view.
            </Typography>
          </DialogContentText>
          <CustomizedFormControl>
            <Typography variant="body3"
              component="label"
              className={"input-label"}>
              {`Name*`}
            </Typography>
            <TextField
              type="text"
              placeholder="E.g: Date priority"
              fullWidth
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ""}
              {...register("name", {
                required: requiredErrorMessage,
                validate: {
                  nameAlreadyExists: (name) => {
                    if (props.gridNames.includes(name.trim().toLowerCase()))
                      return viewNameExistsErrorMessage;
                    return true;
                  },
                },
                maxLength: {
                  value: 50,
                  message: textErrorMessage,
                },
              })}
            />
          </CustomizedFormControl>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center">
            <Switch
              checked={switchChecked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSwitchChecked(event.target.checked);
              }}
              inputProps={{ "aria-label": "controlled" }}
              className={"input-switch"}
            />
            <Typography component="div" variant="subtitle1" color="primary.dark" sx={{ marginLeft: "8px", }}>
              Set as default view
            </Typography>
          </Box>
          <Box>
            <Button
              onClick={props.handlePopupClose}
              variant="outlined"
              size="small"
              className={"btn-secondary"}
            >
              Cancel
            </Button>
            <Button
              type={"submit"}
              variant="contained"
              sx={{ marginLeft: "8px" }}
              autoFocus
              size="small"
              className={"btn-tertiary"}
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </form>
    </CustomizedDialog>
  );
};

export default CreateGridConfigModal;
