import { ICellRendererParams } from "ag-grid-community";
import { Link } from "react-router-dom";

const PropertyListImageRenderer: React.FunctionComponent<
  ICellRendererParams
> = (props: any) => {
  return (
    <Link 
      to={
        "/propertyView/propertyViewDetails?propertyId=" +
        props.data.id +
        "&accountIdentifier=" +
        props.data.accountIdentifier
      }
      className="property-list-name"
    >
      <img src={props.data.imageUrl} className="property-list-img" alt=""/>
      <span>
        <span> {props.data.propertyName}</span>
        {props.data.street}
      </span>
    </Link>
  );
};

export default PropertyListImageRenderer;
