import React from "react";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";

const LeaseEndRenderer: React.FunctionComponent = (props: any) => {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  return (
    <span>
      <span>{cellValue}</span>&nbsp;
      {props.data.leaseExpiring && (
        <Tooltip title="Lease is expiring within 3 months">
          <ErrorIcon style={{ color: "#FF9800" }} />
        </Tooltip>
      )}
    </span>
  );
};

export default LeaseEndRenderer;
