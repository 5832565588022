import React, { useState } from "react";
import {
  Grid,
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormControl,
  ListItemText,
  Checkbox,
  CircularProgress,
  Box
} from "@mui/material";

import ReportByPropertiesGrid from "./ReportByPropertiesGrid"
import { GridApi, ColumnApi, RowNode } from "ag-grid-community";
import { useExportReport } from "../../api/propertyService";
import { MonthYearFilter } from "../../model/propertyOverviewFilter";
import { useGetAccounts } from "../../../properties/property/api/accountService";
import { propertyModel } from "../../model/propertiesModel";
import { getMonthByIndex, getGroupByEnum } from "../../util/formatDate";
import LoadingButton from '@mui/lab/LoadingButton';
import SimpleAlert from "../../../UI/view/SimpleAlert";
import { GridRowList } from "../../util/constant";
import fileSaver from "file-saver";
import { useGetPropertyList } from "../../../properties/property/api/propertyService";

const ReportByProperties: React.FC = () => {

  const date = new Date();
  const [monthFilter] = useState<MonthYearFilter>({
    startYear: date.getFullYear(),
    endYear: date.getFullYear(),
    startMonth: date.getMonth() + 1,
    endMonth: date.getMonth() + 1,
  });

  const { data: propertyList, isSuccess, isLoading } = useGetPropertyList(monthFilter);
  const [isLoadingExcel, setIsLoadingExcel] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const accounts = useGetAccounts();
  const accountIdentifier = accounts.data ? accounts.data[0].identifier : "";
  const exportReportResponse = useExportReport();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const nowYear = date.getFullYear();
  const nowMonth = date.getMonth() + 1;
  const [data, setData] = useState<propertyModel>({
    accountIdentifier: accountIdentifier,
    properties: [],
    includeArReport: false,
    arReportYear:	nowYear,
    arReportMonth: nowMonth,
    includeLoansReport:	false,
    loansReportYear:	nowYear,
    loansReportMonth:	nowMonth,
    includeRentRollSuiteReport:	false,
    rentRollSuiteReportYear:	nowYear,
    rentRollSuiteReportMonth:	nowMonth,
    includeRentRollLeaseReport:	false,
    rentRollLeaseReportYear:	nowYear,
    rentRollLeaseReportMonth:	nowMonth,
    includePropertyOverviewReport:	false,
    includeStackingPlanReport: false,
    stackingPlanReportYear: nowYear,
    stackingPlanReportMonth: nowMonth,
    includeLeasingActivityReport: false,
    leasingActivityReportYear: nowYear,
    leasingActivityReportMonth: nowMonth,
    includeFinancialsIncomeStatementReport: false,
    financialsIncomeStatementReportIncludeBudget:	false,
    financialsIncomeStatementReportStartYear: nowYear,
    financialsIncomeStatementReportStartMonth: nowMonth,
    financialsIncomeStatementReportEndYear: nowYear,
    financialsIncomeStatementReportEndMonth: nowMonth,
    financialsIncomeStatementReportGroupByMode: 0,
    includeFinancialsBalanceSheetReport: false,
    financialsBalanceSheetReportStartYear: nowYear,
    financialsBalanceSheetReportStartMonth: nowMonth,
    financialsBalanceSheetReportEndYear: nowYear,
    financialsBalanceSheetReportEndMonth: nowMonth,
    financialsBalanceSheetReportGroupByMode: 0,
    includePropertySnapshotReport: false,
    propertySnapshotReportYear: nowYear,
    propertySnapshotReportMonth: nowMonth       
  });
  
  const changedApi = async (newData: GridApi) => {
    setError(null);
    let includeArReport = false;
    let arReportYear = nowYear;
    let arReportMonth = nowMonth;
    let includeLoansReport = false;
    let loansReportYear =	nowYear;
    let loansReportMonth =	nowMonth;
    let includeRentRollSuiteReport = false;
    let rentRollSuiteReportYear =	nowYear;
    let rentRollSuiteReportMonth =	nowMonth;
    let includeRentRollLeaseReport = false;
    let rentRollLeaseReportYear =	nowYear;
    let rentRollLeaseReportMonth =	nowMonth;
    let includePropertyOverviewReport =	false;
    let includeStackingPlanReport = false;
    let stackingPlanReportYear = nowYear;
    let stackingPlanReportMonth = nowMonth;
    let includeLeasingActivityReport = false;
    let leasingActivityReportYear = nowYear;
    let leasingActivityReportMonth = nowMonth;
    let includeFinancialsIncomeStatementReport= false;
    let financialsIncomeStatementReportIncludeBudget=	false;
    let financialsIncomeStatementReportStartYear= nowYear;
    let financialsIncomeStatementReportStartMonth= nowMonth;
    let financialsIncomeStatementReportEndYear= nowYear;
    let financialsIncomeStatementReportEndMonth= nowMonth;
    let financialsIncomeStatementReportGroupByMode= 0;
    let includeFinancialsBalanceSheetReport= false;
    let financialsBalanceSheetReportStartYear= nowYear;
    let financialsBalanceSheetReportStartMonth= nowMonth;
    let financialsBalanceSheetReportEndYear= nowYear;
    let financialsBalanceSheetReportEndMonth= nowMonth;
    let financialsBalanceSheetReportGroupByMode= 0;
    let includePropertySnapshotReport = false;
    let propertySnapshotReportYear = nowYear;
    let propertySnapshotReportMonth = nowMonth;    

    await newData.forEachNode(async (rowNode: RowNode) => {
      const ym = rowNode.data.dateRange.split(" ");
      const mm = getMonthByIndex(ym[0]);
      const dates = new Date(ym[1], mm);
      const year = dates.getFullYear();
      const month = dates.getMonth() + 1;
      let yearfrom = 0;
      let monthfrom = 0;
      let yearto = 0;
      let monthto = 0;
      if (rowNode.data.report === GridRowList.FINANCIALS_BALANCE_SHEET ||rowNode.data.report === GridRowList.FINANCIALS_INCOME_STATEMENT)
      {
        const ymrange = rowNode.data.dateRange.split(" - ");
        const ymfrom = ymrange[0].split(" ");
        const ymto = ymrange[1].split(" ");
        const mmfrom = getMonthByIndex(ymfrom[0]);
        const datesfrom = new Date(ymfrom[1], mmfrom);
        yearfrom = datesfrom.getFullYear();
        monthfrom = datesfrom.getMonth() + 1;
        const mmto = getMonthByIndex(ymto[0]);
        const datesto = new Date(ymto[1], mmto);
        yearto = datesto.getFullYear();
        monthto = datesto.getMonth() + 1;
      }            
      switch (rowNode.data.report) {
        case GridRowList.ACCOUNTS_RECEIVABLE:
          arReportYear = year;
          arReportMonth = month;
          if (rowNode.isSelected()) includeArReport = true;
          break;
        case GridRowList.RENT_ROLL_LEASE_VIEW:
          rentRollLeaseReportYear =	year;
          rentRollLeaseReportMonth = month;
          if (rowNode.isSelected()) includeRentRollLeaseReport = true;
          break;
        case GridRowList.RENT_ROLL_SUITE_VIEW:
          rentRollSuiteReportYear =	year;
          rentRollSuiteReportMonth = month;
          if (rowNode.isSelected()) includeRentRollSuiteReport = true;
          break;
        case GridRowList.LEASING_ACTIVITY:
          leasingActivityReportYear = year;
          leasingActivityReportMonth = month;
          if (rowNode.isSelected()) includeLeasingActivityReport = true;
          break;
        case GridRowList.STACKING_PLAN:
          stackingPlanReportYear = year;
          stackingPlanReportMonth = month;
          if (rowNode.isSelected()) includeStackingPlanReport = true;
          break;
        case GridRowList.FINANCIALS_BALANCE_SHEET:
          financialsBalanceSheetReportStartYear = yearfrom;
          financialsBalanceSheetReportStartMonth = monthfrom;
          financialsBalanceSheetReportEndYear = yearto;
          financialsBalanceSheetReportEndMonth = monthto;
          financialsBalanceSheetReportGroupByMode = getGroupByEnum(rowNode.data.groupBy);
          if (rowNode.isSelected()) includeFinancialsBalanceSheetReport = true;
          break;
        case GridRowList.FINANCIALS_INCOME_STATEMENT:
          financialsIncomeStatementReportStartYear = yearfrom;
          financialsIncomeStatementReportStartMonth = monthfrom;
          financialsIncomeStatementReportEndYear = yearto;
          financialsIncomeStatementReportEndMonth = monthto;
          financialsIncomeStatementReportGroupByMode = getGroupByEnum(rowNode.data.groupBy);
          financialsIncomeStatementReportIncludeBudget = (rowNode.data.includeBudget === "Yes") ? true : false;
          if (rowNode.isSelected()) includeFinancialsIncomeStatementReport = true;
          break;
        case GridRowList.LOANS:
          loansReportYear =	year;
          loansReportMonth = month;
          if (rowNode.isSelected()) includeLoansReport = true;
          break;
        case GridRowList.PROPERTY_OVERVIEW:
          if (rowNode.isSelected()) includePropertyOverviewReport = true;
          break;
        case GridRowList.PROPERTY_SNAPSHOT:
          propertySnapshotReportYear = year;
          propertySnapshotReportMonth = month;
          if (rowNode.isSelected()) includePropertySnapshotReport = true;
          break;
      }
    });
    setData({
      ...data,
      accountIdentifier: accountIdentifier,
      includeArReport: includeArReport,
      arReportYear: arReportYear,
      arReportMonth: arReportMonth,
      includeRentRollSuiteReport: includeRentRollSuiteReport,
      rentRollSuiteReportYear:	rentRollSuiteReportYear,
      rentRollSuiteReportMonth:	rentRollSuiteReportMonth,
      includeRentRollLeaseReport: includeRentRollLeaseReport,
      rentRollLeaseReportYear:	rentRollLeaseReportYear,
      rentRollLeaseReportMonth:	rentRollLeaseReportMonth,
      includeLoansReport: includeLoansReport,
      loansReportYear:	loansReportYear,
      loansReportMonth: loansReportMonth,
      includePropertyOverviewReport: includePropertyOverviewReport,
      includeStackingPlanReport: includeStackingPlanReport,
      stackingPlanReportYear: stackingPlanReportYear,
      stackingPlanReportMonth: stackingPlanReportMonth,
      includeLeasingActivityReport: includeLeasingActivityReport,
      leasingActivityReportYear: leasingActivityReportYear,
      leasingActivityReportMonth: leasingActivityReportMonth,
      includeFinancialsIncomeStatementReport: includeFinancialsIncomeStatementReport,
      financialsIncomeStatementReportIncludeBudget:	financialsIncomeStatementReportIncludeBudget,
      financialsIncomeStatementReportStartYear: financialsIncomeStatementReportStartYear,
      financialsIncomeStatementReportStartMonth: financialsIncomeStatementReportStartMonth,
      financialsIncomeStatementReportEndYear: financialsIncomeStatementReportEndYear,
      financialsIncomeStatementReportEndMonth: financialsIncomeStatementReportEndMonth,
      financialsIncomeStatementReportGroupByMode: financialsIncomeStatementReportGroupByMode,
      includeFinancialsBalanceSheetReport: includeFinancialsBalanceSheetReport,
      financialsBalanceSheetReportStartYear: financialsBalanceSheetReportStartYear,
      financialsBalanceSheetReportStartMonth: financialsBalanceSheetReportStartMonth,
      financialsBalanceSheetReportEndYear: financialsBalanceSheetReportEndYear,
      financialsBalanceSheetReportEndMonth: financialsBalanceSheetReportEndMonth,
      financialsBalanceSheetReportGroupByMode: financialsBalanceSheetReportGroupByMode,    
      includePropertySnapshotReport: includePropertySnapshotReport,
      propertySnapshotReportYear: propertySnapshotReportYear,
      propertySnapshotReportMonth: propertySnapshotReportMonth,     
    })
  }

  const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
  const allPropertiesSelected =
    propertyList &&
    propertyList?.propertyAndAssetList?.length > 0 &&
    selectedProperties.length === propertyList?.propertyAndAssetList?.length;

  const changeProperties = (e: SelectChangeEvent<string[]>) => {
    setError(null);
    let value: any = e.target.value === "" ? null : e.target.value;
    let allSelected = (value as string[]).indexOf("all");
    if (allSelected !== -1)
      value = allPropertiesSelected
        ? []
        : propertyList?.propertyAndAssetList?.map((p: any) => p.id);
    setData({
      ...data,
      accountIdentifier: accountIdentifier,
      properties: value
    })
    setSelectedProperties(value);
  }

  const onExport = async () => {
    setIsLoadingExcel(true);
    setError(null);
    await exportReportResponse.mutateAsync(data, {
      onSuccess: (data) => {
        const date = new Date();
        const nowYear = date.getFullYear();
        const nowMonth = date.getMonth() + 1;
        const nowDay = date.getDate();
        const fileName = "Reports-" + nowYear + "-" + (nowMonth > 9 ? nowMonth : "0" + nowMonth) + "-" + (nowDay > 9 ? nowDay : "0" + nowDay) + ".xlsx";
        fileSaver.saveAs(data.data, fileName);
        setIsLoadingExcel(false);
      },
      onError: (message: any) => {
        const msg =
          (message as string) ||
          "Something went wrong, please try again later.";
        setIsLoadingExcel(false);
        setError(msg);
      }
    });
  };

  const isDisabled = () => {
    if ((data.properties && data.properties?.length === 0) ||
        (!data.includeArReport &&
        !data.includeLoansReport &&
        !data.includePropertyOverviewReport &&
        !data.includeRentRollLeaseReport &&
        !data.includeRentRollSuiteReport &&
        !data.includeStackingPlanReport &&
        !data.includeFinancialsBalanceSheetReport &&
        !data.includeFinancialsIncomeStatementReport &&
        !data.includeLeasingActivityReport &&
        !data.includePropertySnapshotReport))
      return true;
    else return false;
  };

  return (
    <React.Fragment>
      {isLoading && (
        <Box textAlign="center" width="100%">
          <CircularProgress />
        </Box>
      )}
      {isSuccess && propertyList !== undefined && (
        <div
          id="property-details"
          className="portfolios-pane"
          style={{width: "100%"}}
          role="tabpanel"
          aria-labelledby="report-tab-property"
        >
          {error !== null && (
            <SimpleAlert
              severityType="error"
              onClose={() => setError(null)}
              message={error}
              alertStyles={{ width: "100%" }}
              styles={{ position: "inherit", width: "100%" }}
            />
          )}
          <Grid container className="property-action-bar">
            <Grid item xs={6}>
              <Grid container sx={{ width: "auto" }}>
                <Grid item>
                  <FormControl variant="outlined" size="small">
                    <Typography
                      variant="body3"
                      component="label"
                      className={"input-label"}
                      sx={{ marginTop: "0 !important" }}
                    >
                      Properties
                    </Typography>
                    <Select
                      MenuProps={{
                        //anchorOrigin: { vertical: "bottom", horizontal: "left" },
                        autoFocus: false,
                      }}
                      labelId="property-label"
                      multiple
                      //label="propertyList"
                      id="properties"
                      name="properties"
                      value={selectedProperties || []}
                      renderValue={(selected: any) => {
                        if (!propertyList) return selected;
                        return selected
                          .map((s: any) => {
                            let property = propertyList?.propertyAndAssetList?.find(
                              (p: any) => p.id === s
                            );
                            return property?.propertyName;
                          })
                          .join(", ");
                      }}
                      onChange={changeProperties}
                      className={"input-field"}
                    >
                      <MenuItem
                        value="all"
                        classes={{
                          root: "",
                        }}
                      >
                        <Checkbox
                          checked={allPropertiesSelected}
                          indeterminate={
                            propertyList &&
                            selectedProperties.length > 0 &&
                            selectedProperties.length < propertyList?.propertyAndAssetList?.length
                          }
                        />

                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {propertyList?.propertyAndAssetList?.map((property: any) => (
                        <MenuItem key={property.id} value={property.id}>
                          <Checkbox
                            checked={
                              selectedProperties.indexOf(property.id) > -1
                            }
                          />
                          <ListItemText primary={property.propertyName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography
                    variant="body2"
                    component="label"
                    className={"input-label"}
                    sx={{ marginTop: "8px !important" }}
                  >
                    Please select one or more properties and one of the reports below.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div id="property-details-content" className="tab-content">
            <ReportByPropertiesGrid
              gridApi={gridApi}
              gridColumnApi={gridColumnApi}
              updateGridApi={(newGridApi: GridApi) => {
                setGridApi(newGridApi);
                changedApi(newGridApi);
              }}
              updateColumnApi={(newColApi: ColumnApi) =>
                setGridColumnApi(newColApi)
              }
            />
          </div>
          <Grid container style={{justifyContent: "end", }}>
            <LoadingButton
              size="small"
              onClick={onExport}
              loading={isLoadingExcel}
              variant="contained"
              className={"btn-primary"}
              sx={{ margin: "21px" }}
              disabled={isDisabled()}
            >
            Generate Report
            </LoadingButton>
          </Grid>
        </div>
      )}
    </React.Fragment>
  );
};

export default ReportByProperties;
