import React, { Fragment, forwardRef } from "react";
import { Box, Typography, } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UilCalender } from '@iconscout/react-unicons';

import { TMonthYearFilter } from "../model/rentRollListFilter";
import { getMinDate, getMaxDate } from "../util/rentRollCalendar";

interface Props {
  currentDate: Date;
  setFilter: (filter?: TMonthYearFilter) => void;
  minDate: Date;
  maxDate: Date;
  value?: string;
  onClick?: () => void;
}

const PropertyViewRentRollSearch: React.FC<Props> = (props: Props) => {
  const { currentDate, setFilter, minDate, maxDate } = props;
  type Props = {
    onClick?: () => void,
    value?: string
  }
  type RefType=number
  const CustomDatePickerInput = forwardRef<RefType, Props>(({ onClick, value }, ref) => (
    <Box
      className={"react-datepicker-input"}
      ref={ref}
      onClick={onClick}
    >
      {value}
      <Box className={"react-datepicker-icon"}>
        <UilCalender />
      </Box>
    </Box>
  ));
  
  return (
    <Fragment>
      <Box className={"datepicker-outer-container"}>
        <Typography variant="body3" component="label"
          className={"input-label"}
        >{`Show tenants for month:`}</Typography>
        <Box className={"datepicker-container"}>
          <DatePicker
            selected={currentDate}
            onChange={(date: Date) => {
              setFilter({
                filterMonth: date.getMonth() + 1,
                filterYear: date.getFullYear(),
              });
            }}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            //showFullMonthYearPicker
            //showTwoColumnMonthYearPicker
            minDate={getMinDate(minDate)}
            maxDate={getMaxDate(maxDate)}
            //popperPlacement="auto"
            popperModifiers={[
              {
                name: "preventOverflow",
                options: {
                  altAxis: true,
                },
              },
              {
                name: "offset",
                options: {
                  offset: [-13, 0],
                },
              },
            ]}
            customInput={<CustomDatePickerInput {...props} />}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default PropertyViewRentRollSearch;
