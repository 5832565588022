import React, { useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  GridReadyEvent,
  GridApi,
  ColumnApi,
  ColDef,
  StatusPanelDef,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  RowNode,
} from "ag-grid-community";
import "ag-grid-enterprise";
import { UseQueryResult } from "@tanstack/react-query";
import { Box, CircularProgress } from "@mui/material";

import Generations from "./propertyViewRentRollGenerations";
import LeaseEndRenderer from "./propertyViewRentRollLeaseEndRenderer";
import { TRentRollAssetSummary } from "../model/rentRollAssetSummary";
import { TMonthYearFilter } from "../model/rentRollListFilter";
import { TRentRollList } from "../model/rentRollListModel";
import {
  currencyFormatterNoAbbrev,
  currencyFormatterWithPlaces,
  dateFilterParams,
  dateValueFormatter,
  dateValueGetter,
  dateValueGetterNA,
  formatSqFt,
  negativeNumCellStyle,
  numberFormatter,
  numberGetter,
  percentFormatter,
  stringSortCaseInsensitive,
} from "../../util/gridUtils";
import DocumentRenderer from "./propertyViewRentRollDocumentRenderer";
import "../../css/properties.css";
import { getTerm, getTermValue } from "../../util/formatTerm";

interface RentRollListProps {
  expandTenant: string;
  rRollList?: UseQueryResult<TRentRollList>;
  monthYearFilter?: TMonthYearFilter;
  setAssetSummary: (assetSummary: TRentRollAssetSummary) => void;
  setAssetSummaryFiltered: (
    assetSummaryFiltered: TRentRollAssetSummary
  ) => void;
  onGridRender: (gridApi?: GridApi, gridColumnApi?: ColumnApi) => void;
  gridApi?: GridApi;
  gridColumnApi?: ColumnApi;
  orderByColumn?: string;
}

const PropertyViewRentRollListByTenant: React.FC<RentRollListProps> = (
  props
) => {
  const hasFilterMonth =
    props.monthYearFilter != null &&
    props.monthYearFilter["filterMonth"] &&
    props.monthYearFilter["filterMonth"] != null;
  const hasFilterYear =
    props.monthYearFilter != null &&
    props.monthYearFilter["filterYear"] &&
    props.monthYearFilter["filterYear"] != null;

  if (props.rRollList && hasFilterMonth && hasFilterYear) {
    return props.rRollList?.isLoading ? (
      <Box m="auto">
        <CircularProgress />
      </Box>
    ) : (
      <React.Fragment>
        <RentRollListMain
          expandTenant={
            props.expandTenant === "" ? undefined : props.expandTenant
          }
          rRollList={props.rRollList!}
          setAssetSummary={props.setAssetSummary}
          setAssetSummaryFiltered={props.setAssetSummaryFiltered}
          onGridRender={props.onGridRender}
          gridApi={props.gridApi}
          gridColumnApi={props.gridColumnApi}
          orderByColumn={props.orderByColumn}

        />
      </React.Fragment>
    );
  } else return <React.Fragment />;
};

interface RentRollListMainProps {
  expandTenant?: string;
  rRollList: UseQueryResult<TRentRollList>;
  setAssetSummary: (assetSummary: TRentRollAssetSummary) => void;
  setAssetSummaryFiltered: (
    assetSummaryFiltered: TRentRollAssetSummary
  ) => void;
  onGridRender: (gridApi?: GridApi, gridColumnApi?: ColumnApi) => void;
  gridApi?: GridApi;
  gridColumnApi?: ColumnApi;
  orderByColumn?: string;
}

export enum DocumentRendererType {
  SUITE_DOCUMENT = "suiteDocument",
  LEASE_DOCUMENT = "leaseDocument",
  OTHER_DOCUMENT = "otherDocument",
}

const RentRollListMain: React.FC<RentRollListMainProps> = (props) => {
  const rows = props.rRollList.data?.rentrolls?.groupedresult;
  const { onGridRender, gridApi, gridColumnApi } = props;

  useEffect(() => {
    return () => {
      onGridRender(undefined, undefined);
    };
  }, [onGridRender]);

  const [statusBar] = React.useState<{ statusPanels: StatusPanelDef[] }>({
    statusPanels: [
      {
        statusPanel: "agAggregationComponent",
        statusPanelParams: {
          aggFuncs: ["count", "sum", "min", "max", "avg"],
        },
      },
    ],
  });

  const autoSizeAll = () => {
    setTimeout(() => {
      gridColumnApi?.autoSizeAllColumns();

      //Initially set Suite column to a max size if it exceeds the max size
      const allState = gridColumnApi?.getColumnState();
      if (allState !== undefined && allState[0] !== undefined) {
        const suiteWidth = allState[0].width;
        columnDefs.forEach(function (colDef, index) {
          if (colDef.headerName === "Suite") {
            if (suiteWidth !== undefined) {
              if (suiteWidth > 350) {
                colDef.width = 350;
              }
            }
          }
        });
        gridApi?.setColumnDefs(columnDefs);
      }
    }, 500); // TODO remove setTimeout when Ag-grid fixes autoSizeColumns on React 18
  };

  const tenantValueFormatter = (value: string | undefined) => {
    if (value === undefined) return "*VACANT*";
    return value;
  };

  const [columnDefs] = React.useState<ColDef[]>([
    {
      headerName: "Suite",
      field: "spaceCode",
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: "agTextColumnFilter",
      cellRenderer: "agGroupCellRenderer",
      tooltipField: "spaceCode",
      minWidth: 50,
      suppressSizeToFit: true,
    },
    {
      headerName: "Lease ID",
      field: "leaseCode",
      cellRenderer: DocumentRendererType.LEASE_DOCUMENT,
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: "agTextColumnFilter",
      minWidth: 50,
    },
    {
      headerName: "Tenant",
      rowGroup: false,
      field: "tenantTitle",
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: "agTextColumnFilter",
      minWidth: 50,
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
          {
            displayKey: "emptyTenant",
            displayName: "Empty",
            predicate: (filterValue: any, cellValue: any) => {
              return cellValue === undefined;
            },
            numberOfInputs: 0,
          },
        ],
      },
      valueFormatter: (params: any) =>
        tenantValueFormatter(params.data.tenantTitle),
    },
    {
      headerName: "Building",
      field: "buildingTitle",
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: "agTextColumnFilter",
      minWidth: 50,
    },
    {
      headerName: "Rentable Area",
      field: "rsf",
      cellClass: ["decimalPlaces", "textAlignRight"],
      sortable: true,
      filter: "agNumberColumnFilter",
      type: "numericColumn",
      valueFormatter: (params) => numberFormatter(params.data.rsf, 0),
      valueGetter: (params) => numberGetter(params.data.rsf),
      cellStyle: negativeNumCellStyle,
      minWidth: 50,
    },
    {
      headerName: "Lease Start",
      field: "leaseFrom",
      cellClass: ["textAlignRight", "dateFormat"],
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params) => dateValueGetter(params.data.leaseFrom),
      valueFormatter: (params) => dateValueFormatter(params.data.leaseFrom),
      minWidth: 50,
    },
    {
      headerName: "Lease End",
      field: "leaseTo",
      cellClass: ["textAlignRight", "dateFormat"],
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params) => dateValueGetterNA(params.data.leaseTo),
      valueFormatter: (params) => dateValueFormatter(params.data.leaseTo),
      cellRenderer: "leaseEndRenderer",
      minWidth: 150,
    },
    {
      headerName: "Term (mo)",
      field: "leaseTerm",
      cellClass: ["decimalPlaces", "textAlignRight"],
      sortable: true,
      filter: "agNumberColumnFilter",
      type: "numericColumn",
      valueFormatter: (params) =>
        getTerm(params.data.leaseTo, params.data.leaseTerm),
      valueGetter: (params) =>
        getTermValue(params.data.leaseTo, params.data.leaseTerm),
      cellStyle: negativeNumCellStyle,
      minWidth: 50,
    },
    {
      headerName: "Move-In",
      field: "moveInDate",
      cellClass: ["textAlignRight", "dateFormat"],
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params) => dateValueGetter(params.data.moveInDate),
      valueFormatter: (params) => dateValueFormatter(params.data.moveInDate),
      minWidth: 50,
    },
    {
      headerName: "Move-Out",
      field: "moveOutDate",
      cellClass: ["textAlignRight", "dateFormat"],
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params) => dateValueGetter(params.data.moveOutDate),
      valueFormatter: (params) => dateValueFormatter(params.data.moveOutDate),
      minWidth: 50,
    },

    {
      headerName: "Security Deposit",
      field: "securityDeposit",
      cellClass: ["textAlignRight", "currencyFormat"],
      sortable: true,
      filter: "agNumberColumnFilter",
      type: "numericColumn",
      valueFormatter: (params) =>
        currencyFormatterNoAbbrev(params.data.securityDeposit, "$"),
      valueGetter: (params) => numberGetter(params.data.securityDeposit),
      cellStyle: negativeNumCellStyle,
      minWidth: 50,
    },
    {
      headerName: "Current Monthly Rent",
      field: "monthlyRent",
      cellClass: ["textAlignRight", "currencyFormat"],
      sortable: true,
      filter: "agNumberColumnFilter",
      type: "numericColumn",
      valueFormatter: (params) =>
        currencyFormatterNoAbbrev(params.data.monthlyRent, "$"),
      valueGetter: (params) => numberGetter(params.data.monthlyRent),
      cellStyle: negativeNumCellStyle,
      minWidth: 50,
    },
    {
      headerName: "Current Rent/SF",
      field: "rentPerSF",
      cellClass: ["textAlignRight", "currencyFormat"],
      sortable: true,
      filter: "agNumberColumnFilter",
      type: "numericColumn",
      valueFormatter: (params) =>
        currencyFormatterWithPlaces(params.data.rentPerSF, 2, "$"),
      valueGetter: (params) => numberGetter(params.data.rentPerSF),
      cellStyle: negativeNumCellStyle,
      minWidth: 50,
    },
  ]);

  const aggFuncs = {
    // this overrides the grids built-in sum function
    list: (values: any) => {
      let listOfItems = "";

      values.forEach((value: any) => (listOfItems += value + ", "));
      listOfItems = listOfItems.replace(/,\s*$/, "");

      return listOfItems;
    },
    distinctcount: (values: any) => {
      const testCnt: string[] = [];
      values.forEach((value: any) => {
        testCnt.push(value);
      });
      let test = new Set(testCnt);
      return test.size;
    },
  };

  function calculateTotals(isFiltered: boolean): TRentRollAssetSummary {
    // By Suite
    let result: TRentRollAssetSummary;
    let rentableArea: number = 0;
    let occupied: number = 0; //rsf if lease
    let leased: number = 0; //rsf if leased currently or in future
    let monthlyRent: number = 0;
    let tenantList: string[] = [];
    let suiteList: string[] = [];
    let suitePercentage: string = "";
    let rentableAreaPercentage: string = "";
    let numTenantsPercentage: string = "";
    let monthlyRentPercentage: string = "";
    let occupancy: number = 0;
    let leasedPercentage: number = 0;
    let monthlyRentPerSF: number = 0;
    const totNumTenantsOtherCount =
      props.rRollList.data?.rentrolls.totalNumTenantsOtherCount ?? 0;

    if (isFiltered) {
      gridApi?.forEachNodeAfterFilter((node) => {
        if (typeof node.data !== "undefined") {
          rentableArea += node.data.isFutureLease ? 0 : node.data.rsf;
          node.data.spaceIdList.forEach(function (spaceId: string) {
            if (!suiteList.includes(spaceId)) {
              suiteList.push(spaceId);
            }
          });

          if (
            node.data.leaseTitle !== undefined &&
            node.data.leaseTitle.length > 0 &&
            !node.data.isFutureLease
          )
            occupied += node.data.isFutureLease ? 0 : node.data.rsf;

          if (
            node.data.leaseTitle !== undefined &&
            node.data.leaseTitle.length > 0
          )
            leased += node.data.isFutureLease ? 0 : node.data.rsf;

          if (
            node.data.tenantId !== undefined &&
            !tenantList.includes(node.data.tenantId) &&
            !node.data.isFutureLease
          ) {
            tenantList.push(node.data.tenantId);
          }

          monthlyRent +=
            typeof node.data.monthlyRent !== "undefined" &&
            node.data.monthlyRent !== null
              ? node.data.monthlyRent
              : 0;
        }
      });
      occupancy = rentableArea > 0 ? occupied / rentableArea : 0;
      leasedPercentage = rentableArea > 0 ? leased / rentableArea : 0;
      monthlyRentPerSF = occupied > 0 ? Math.round(monthlyRent / occupied) : 0;

      //Totals for Unfiltered:
      let rentableAreaTotal: number = 0;
      let occupiedTotal: number = 0; //rsf if lease
      let monthlyRentTotal: number = 0;
      let tenantListTotal: string[] = [];
      let suiteListTotal: string[] = [];
      let monthlyRentPerSFTotal: number = 0;
      props.rRollList.data?.rentrolls.result.forEach((ele) => {
        if (typeof ele !== "undefined") {
          rentableAreaTotal += ele.isFutureLease ? 0 : ele.rsf || 0;
          if (!suiteListTotal.includes(ele.spaceId)) {
            suiteListTotal.push(ele.spaceId);
          }
          if (
            ele.leaseTitle !== undefined &&
            ele.leaseTitle.length > 0 &&
            !ele.isFutureLease
          )
            occupiedTotal += ele.isFutureLease ? 0 : ele.rsf || 0;

          if (
            ele.tenantId !== undefined &&
            !tenantListTotal.includes(ele.tenantId) &&
            !ele.isFutureLease
          ) {
            tenantListTotal.push(ele.tenantId);
          }
          monthlyRentTotal +=
            typeof ele.monthlyRent !== "undefined" && ele.monthlyRent !== null
              ? ele.monthlyRent
              : 0;
        }
      });
      monthlyRentPerSFTotal =
        occupiedTotal > 0 ? Math.round(monthlyRentTotal / occupiedTotal) : 0;

      //Filtered Percentages
      suitePercentage =
        suiteListTotal.length > 0
          ? " " +
            Math.round((100 * suiteList.length) / suiteListTotal.length) + //Math.round((100 * suiteList.length) / suiteListTotal.length) +
            "%"
          : "";
      rentableAreaPercentage =
        rentableAreaTotal > 0
          ? " " + Math.round((100 * rentableArea) / rentableAreaTotal) + "%"
          : "";
      numTenantsPercentage =
        tenantListTotal.length > 0
          ? " " +
            Math.round((100 * tenantList.length) / tenantListTotal.length) +
            "%"
          : "";
      monthlyRentPercentage =
        monthlyRentPerSFTotal > 0
          ? " " +
            Math.round((100 * monthlyRentPerSF) / monthlyRentPerSFTotal) +
            "%"
          : "";
    } else {
      //Asset Summary Totals
      props.rRollList.data?.rentrolls.result.forEach((ele) => {
        if (typeof ele !== "undefined") {
          rentableArea += ele.isFutureLease ? 0 : ele.rsf || 0;
          if (!suiteList.includes(ele.spaceId)) {
            suiteList.push(ele.spaceId);
          }
          if (
            ele.leaseTitle !== undefined &&
            ele.leaseTitle.length > 0 &&
            !ele.isFutureLease
          )
            occupied += ele.isFutureLease ? 0 : ele.rsf || 0;

          if (ele.leaseTitle !== undefined && ele.leaseTitle.length > 0)
            leased += ele.isFutureLease ? 0 : ele.rsf || 0;

          if (
            ele.tenantId !== undefined &&
            !tenantList.includes(ele.tenantId) &&
            !ele.isFutureLease
          ) {
            tenantList.push(ele.tenantId);
          }
          monthlyRent +=
            typeof ele.monthlyRent !== "undefined" && ele.monthlyRent !== null
              ? ele.monthlyRent
              : 0;
        }
      });
      occupancy = rentableArea > 0 ? occupied / rentableArea : 0;
      leasedPercentage = rentableArea > 0 ? leased / rentableArea : 0;
      monthlyRentPerSF = occupied > 0 ? Math.round(monthlyRent / occupied) : 0;
    }

    result = {
      rentableAreaDisplay: formatSqFt(rentableArea) + " SF",
      numberofSuitesDisplay: numberFormatter(suiteList.length, 0) + "",
      //      numberofSuitesDisplay: numberFormatter(suiteCnt, 0) + "",
      numberOfTenantsDisplay: numberFormatter(tenantList.length, 0) + "",
      occupancyDisplay: percentFormatter(occupancy, "%"),
      leasedPercentDisplay: percentFormatter(leasedPercentage, "%"),
      monthlyRentPerSFDisplay: currencyFormatterNoAbbrev(monthlyRentPerSF, "$"),
      numberofSuitesPercent: suitePercentage,
      rentableAreaPercent: rentableAreaPercentage,
      numberOfTenantsPercent: numTenantsPercentage,
      monthlyRentPerSFPercent: monthlyRentPercentage,
      totalNumTenantsOtherCount: numberFormatter(totNumTenantsOtherCount, 0),
    };
    return result;
  }

  const onGridReady = (event: GridReadyEvent) => {
    onGridRender(event.api, event.columnApi);
    if (typeof rows === "undefined" || rows.length === 0) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }
  };

  const onFilterChanged = (event: FilterChangedEvent) =>
    props.setAssetSummaryFiltered(calculateTotals(true));

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    if (props.expandTenant) {
      const filterComponent = event.api.getFilterInstance("tenantTitle");

      if (props.expandTenant.toLocaleLowerCase() === "vacant") {
        filterComponent?.setModel({
          type: "emptyTenant",
        });
      } else {
        filterComponent?.setModel({
          type: "equals",
          filter: props.expandTenant,
        });
      }
      event.api.onFilterChanged();

      event.api.forEachNode((node: RowNode) => {
        if (
          node.data.tenantTitle !== undefined &&
          node.data.tenantTitle === props.expandTenant
        )
          event.api.getDisplayedRowAtIndex(node.childIndex)?.setExpanded(true);
      });
    }

    if (props.orderByColumn) {
      event.columnApi.applyColumnState({
        state: [{ colId: props.orderByColumn, sort: "desc" }],
        defaultState: { sort: null },
      });
      event.api.redrawRows();
    }

    autoSizeAll();

    props.setAssetSummary(calculateTotals(false));
    props.setAssetSummaryFiltered(calculateTotals(true));
  };

  return (
    <div
      id="property-rentroll-content"
      className="example-wrapper"
      style={{ width: "100%" }}
    >
      <div
        id="myGrid"
        className="ag-theme-alpine ag-theme-alpine-container-override"
        style={{ width: "100%" }}
      >
        <AgGridReact
          masterDetail={true}
          getRowId={function (params) {
            let uniqueId = "";
            params.data.spaceIdList?.forEach((spaceId: any) => {
              uniqueId += spaceId;
            });
            uniqueId += params.data.id + params.data.buildingId;
            return uniqueId;
          }}
          detailCellRenderer={"generationsChargesRenderer"}
          components={{
            generationsChargesRenderer: Generations,
            leaseEndRenderer: LeaseEndRenderer,
            [DocumentRendererType.LEASE_DOCUMENT]: DocumentRenderer,
          }}
          suppressAggFuncInHeader
          aggFuncs={aggFuncs}
          onGridReady={onGridReady}
          statusBar={statusBar}
          enableRangeSelection={true}
          groupDisplayType={"multipleColumns"}
          onFilterChanged={onFilterChanged}
          onFirstDataRendered={onFirstDataRendered}
          rowData={rows}
          autoGroupColumnDef={{ minWidth: 100 }}
          columnDefs={columnDefs}
          sortingOrder={["desc", "asc"]}
          defaultColDef={{
            minWidth: 100,
            filter: true,
            resizable: true,
            filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
          }}
          excelStyles={[
            {
              id: "textAlignRight",
              alignment: {
                horizontal: "Right",
              },
            },
            {
              id: "dateFormat",
              dataType: "DateTime",
              numberFormat: { format: "mm/dd/yyyy;@" },
            },
            {
              id: "currencyFormat",
              numberFormat: { format: "\u0024 #,##0.00" },
            },
            {
              id: "decimalPlaces",
              numberFormat: { format: "#,##0.00" },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default PropertyViewRentRollListByTenant;
