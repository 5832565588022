import React, { useState } from "react";
import { ColumnState } from "ag-grid-community";

import {
  FilterModel,
  IUAAlertMessage,
  UserContextProps,
} from "./userContextModels";
import { TAccount } from "../../properties/property/model/account";

/**
 * Context for keeping track of the usermanagement state
 */
const UserContext = React.createContext<UserContextProps>({
  // USERMANAGEMENT

  // Alert message
  alertMessage: {
    severityType: "success",
    message: "",
  },
  updateAlertMessage: () => {},

  // User - Grid
  userFilterModel: {},
  userColumnState: [],
  updateUserFilterModel: () => {},
  updateUserColumnState: () => {},

  // User - Local State
  userAccountDDValue: "",
  updateUserAccountDDValue: () => {},

  // User and Groups: Dropdown options
  latestAccountOptions: [],
  updateLatestAccountOptions: () => {},

  // Groups - Local state
});

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider: React.FunctionComponent<UserProviderProps> = (props) => {
  // STATE

  // Alert Message
  const [alertMessage, setAlertMessage] = useState<IUAAlertMessage>({
    severityType: "success",
    message: "",
  });

  // User - Grid
  const [userColumnState, setUserColumnState] = useState<ColumnState[]>([]);
  const [userFilterModel, setUserFilterModel] = useState<FilterModel>({});

  // User - Local State
  const [userAccountDDValue, setUserAccountDDValue] = useState<string>("");

  // User and Groups: Dropdown options

  const [latestAccountOptions, setLatestAccountOptions] = useState<TAccount[]>(
    []
  );

  // FUNCTIONS

  // Alert Message

  const updateAlertMessage = (newAlertMessage: IUAAlertMessage) => {
    setAlertMessage(newAlertMessage);
  };

  // Groups - Local state

  // User - Grid
  const updateUserFilterModel = (filterModel: FilterModel) => {
    setUserFilterModel(filterModel);
  };

  const updateUserColumnState = (columnState: ColumnState[]) => {
    setUserColumnState(columnState);
  };

  // User - Local State
  const updateUserAccountDDValue = (newValue: string) => {
    setUserAccountDDValue(newValue);
  };

  // User and Groups: Dropdown options

  const updateLatestAccountOptions = (options: TAccount[]) => {
    setLatestAccountOptions(options);
  };

  // Groups - Local state
  return (
    <UserContext.Provider
      value={{
        // Alert Message
        alertMessage,
        updateAlertMessage,

        // User - Grid
        userFilterModel,
        userColumnState,
        updateUserColumnState,
        updateUserFilterModel,

        // User - Local State
        userAccountDDValue,
        updateUserAccountDDValue,

        // User and Groups: Dropdown options
        latestAccountOptions,
        updateLatestAccountOptions,

        // Groups - local state
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
