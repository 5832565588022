import { numberGetterForExcel, numberWithCommas, currencyGetterForExcel, dateValueFormatter, numberFormatter } from "../../util/gridUtils";
import {
  RentRollExcel,
  GenerationsExcel,
  ChargeScheduleExcel,
} from "../model/rentRollExcel";
import {
  ChargeSchedule,
  Generation,
  TRentRollListGroupedModel,
} from "../model/rentRollListModel";

export const formatRentRolls = (rentRolls: TRentRollListGroupedModel[]) => {
  const rentRollExcel: RentRollExcel[] = [];
  const generations: GenerationsExcel[] = [];
  const chargeSchedules: ChargeScheduleExcel[] = [];
  rentRolls.forEach((rentRoll: TRentRollListGroupedModel) => {
    rentRollExcel.push({
      id: rentRoll.id,
      spaceCode: rentRoll.spaceCode.join(", "),
      leaseTitle: rentRoll.leaseTitle,
      leaseCode: rentRoll.leaseCode,
      tenantTitle: rentRoll.tenantTitle,
      buildingTitle: rentRoll.buildingTitle.join(", "),
      rsf: numberWithCommas(rentRoll.rsf) || "-1",
      leaseFrom: dateFormatter(rentRoll.leaseFrom),
      leaseTo: dateFormatter(rentRoll.leaseTo),
      leaseTerm: termFormatter(rentRoll.leaseTo, rentRoll.leaseTerm),
      // leaseTerm: numberGetterForExcel(0, rentRoll.leaseTerm),
      moveInDate: dateFormatter(rentRoll.moveInDate),
      moveOutDate: dateFormatter(rentRoll.moveOutDate),
      securityDeposit: currencyGetterForExcel(2, rentRoll.securityDeposit), 
      monthlyRent: currencyGetterForExcel(2, rentRoll.monthlyRent), 
      rentPerSF: currencyGetterForExcel(2, rentRoll.rentPerSF)
    });
    rentRoll.generations.forEach((generation: Generation) => {
      generations.push({
        leaseId: rentRoll.leaseTitle,
        leaseCode: rentRoll.leaseCode,
        iterationNum: generation.iterationNum,
        executedDate: dateFormatter(generation.executedDate.toString()),
        startDate: dateFormatter(generation.startDate.toString()),
        endDate: dateFormatter(generation.endDate.toString()),
        rentedArea: numberWithCommas(generation.rentedArea) || "-1",
      });
    });
    rentRoll.chargeSchedules.forEach((chargeSchedule: ChargeSchedule) => {
      chargeSchedules.push({
        leaseId: rentRoll.leaseTitle,
        leaseCode: rentRoll.leaseCode,
        iterationNum: chargeSchedule.iterationNum,
        effectiveDate: dateFormatter(chargeSchedule.effectiveDate),
        endDate: dateFormatter(chargeSchedule.endDate),
        chargeType: chargeSchedule.chargeType,
        monthlyAmount: currencyGetterForExcel(2, chargeSchedule.monthlyAmount) //`$ ${ numberGetterForExcel(2, chargeSchedule.monthlyAmount) || 0 }`,
      });
    });
  });
  return { rentRollExcel, generations, chargeSchedules };
};

const dateFormatter = (dateinput: string) => {
  const NA_DATE = "09/09/9999";
  const NA_DATE2 = "12/31/9999";

  if (typeof dateinput === "undefined") return "";
  if (dateinput.length === 0) return "";
  const newDate =
    ("0" + (new Date(dateinput).getUTCMonth() + 1)).slice(-2) +
    "/" +
    ("0" + new Date(dateinput).getUTCDate()).slice(-2) +
    "/" +
    new Date(dateinput).getUTCFullYear();
  if (newDate === NA_DATE) return "N/A";
  if (newDate === NA_DATE2) return "N/A";
  return newDate;
};

const termFormatter = (leaseTo: any, leaseTerm: number | undefined) => {
  if (dateValueFormatter(leaseTo) === "N/A") {
    return "N/A";
  } else {
    return numberGetterForExcel(0, leaseTerm);
  }
};