import React from "react";
import { Theme, Fade } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Alert } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "calc(100% - 72px)",
      position: "absolute",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  })
);

export type AlertSeverityType = "success" | "info" | "warning" | "error";

interface ISimpleAlertProps {
  severityType: AlertSeverityType;
  message: string;
  onClose: () => void;
  styles?: Object;
  alertStyles?: Object;
}

const SimpleAlert: React.FC<ISimpleAlertProps> = (props) => {
  const [fadeIn, setFadeIn] = React.useState(true);
  const classes = useStyles();
  return (
    <div className={classes.root} style={props.styles ?? {}}>
      <Fade in={fadeIn}>
        <Alert
          severity={props.severityType}
          onClose={() => {
            setFadeIn(false);
            setTimeout(() => {
              props.onClose();
            }, 300);
          }}
          style={props.alertStyles ?? {}}
        >
          {props.message}
        </Alert>
      </Fade>
    </div>
  );
};

export default SimpleAlert;
