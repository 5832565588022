import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useIsFetching } from "@tanstack/react-query";

import { getDenominationOptions } from "../utils/widgetUtils";
import { useGetAccounts } from "../../../properties/property/api/accountService";
import { DashboardWidgetSettings } from "../models/dashboardWidgetSettings";
import { useGetPropertyList } from "../../property/api/propertyService";
import { PropertyTypeEnum } from "../../../../shared/model/propertyTypeEnum";
import { useGetLedgerBookList } from "../api/ledgerBookService";
import {
  DashboardTypeEnum,
  WidgetTypeEnum,
  WidgetCategoryEnum,
  DashboardWidgetDenominationEnum,
} from "../models/dashboardEnums";
import useStyles from "./dashboardStyles";

interface Props {
  dashboardType: DashboardTypeEnum;
  isInEditWidgetMode: boolean;
  widgetCategory: WidgetCategoryEnum;
  widgetType: WidgetTypeEnum;
  widgetSettings?: DashboardWidgetSettings;
  groupIdentifier: string;
  closeDrawer: () => void;
  updateSettings: (settings: DashboardWidgetSettings) => void;
}

const DashboardEditWidgetSettings: React.FC<Props> = (props) => {
  const accounts = useGetAccounts();
  const [widgetSettings, setWidgetSettings] = useState({
    ...props.widgetSettings,
  });

  const properties = useGetPropertyList(widgetSettings.accountIdentifier, {
    propertyType:
      widgetSettings.denomination ===
      DashboardWidgetDenominationEnum[DashboardWidgetDenominationEnum.PerUnit]
        ? PropertyTypeEnum.ResidentialMultifamily
        : undefined,
  });
  const ledgerBookList = useGetLedgerBookList(widgetSettings.accountIdentifier);
  const isFetching = useIsFetching();

  function updateSettings(e: SelectChangeEvent) {
    if (e.target.name) {
      let settings = { ...widgetSettings };
      if (e.target.name === "propertyId" || e.target.name === "denomination") {
        settings.propertyId = undefined;
      } else if (e.target.name === "accountIdentifier") {
        settings.bookId = undefined;
      }
      let value = e.target.value === "" ? null : e.target.value;
      setWidgetSettings({
        ...settings,
        [e.target.name]: value,
      });
    }
  }

  useEffect(() => {
    if (!accounts.data || widgetSettings.groupIdentifier) return;

    if (accounts.data?.length === 1)
      widgetSettings.accountIdentifier = accounts.data[0].identifier;

    if (props.widgetCategory === WidgetCategoryEnum.Financials)
      widgetSettings.denomination =
        DashboardWidgetDenominationEnum[DashboardWidgetDenominationEnum.Gross];

    widgetSettings.groupIdentifier = props.groupIdentifier;
    setWidgetSettings({ ...widgetSettings });
  }, [widgetSettings, accounts, props]);

  const denominationOptions = getDenominationOptions();

  const classes = useStyles();

  const SelectIcon = (props: Props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 0 0"
      fill="none"
    >
      <path
        d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z"
        fill="#021A3C"
      />
    </svg>
  );

  return (
    <Drawer
      anchor={"right"}
      open={props.isInEditWidgetMode}
      onClose={() => alert("test")}
      variant={"persistent"}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {isFetching > 0 ? (
        <Box m="auto">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12} className={classes.spaceItem}>
            <h4>Edit Widget</h4>
            {/* <Grid container> */}

            <FormControl className={classes.formControl}>
              <InputLabel id="company-label">Company</InputLabel>
              <Select
                labelId="company-label"
                id="accounts"
                name="accountIdentifier"
                value={widgetSettings.accountIdentifier || ""}
                onChange={updateSettings}
                IconComponent={SelectIcon}
              >
                <MenuItem key="select" value="">
                  <em>None</em>
                </MenuItem>
                {accounts.data?.map((account) => (
                  <MenuItem key={account.identifier} value={account.identifier}>
                    {account.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {props.widgetCategory === WidgetCategoryEnum.Financials && (
              <React.Fragment>
                <FormControl className={classes.formControl}>
                  <InputLabel id="denomination-label">Denomination</InputLabel>
                  <Select
                    labelId="denomination-label"
                    id="denomination"
                    name="denomination"
                    value={widgetSettings.denomination || ""}
                    onChange={updateSettings}
                    IconComponent={SelectIcon}
                  >
                    <MenuItem key="select" value="">
                      <em>None</em>
                    </MenuItem>
                    {denominationOptions.map((denominationOption) => (
                      <MenuItem
                        key={denominationOption.value}
                        value={denominationOption.value}
                      >
                        {denominationOption.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl className={classes.formControl}>
                  <InputLabel id="gl-book-label">GL Book</InputLabel>
                  <Select
                    labelId="gl-book-label"
                    id="bookId"
                    name="bookId"
                    value={widgetSettings.bookId || ""}
                    onChange={updateSettings}
                    IconComponent={SelectIcon}
                  >
                    <MenuItem key="select" value="">
                      <em>None</em>
                    </MenuItem>
                    {ledgerBookList.data?.map((ledgerBook) => (
                      <MenuItem key={ledgerBook.id} value={ledgerBook.id}>
                        {ledgerBook.bookName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </React.Fragment>
            )}

            <FormControl className={classes.formControl}>
              <InputLabel id="property-label">Property</InputLabel>
              <Select
                labelId="property-label"
                id="properties"
                name="propertyId"
                value={widgetSettings.propertyId || ""}
                onChange={updateSettings}
                IconComponent={SelectIcon}
              >
                <MenuItem key="select" value="">
                  <em>None</em>
                </MenuItem>
                {properties.data?.map((property) => (
                  <MenuItem key={property.id} value={property.id}>
                    {property.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Grid item xs={12}>
              <Grid container direction="row-reverse">
                <Box mr={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      props.updateSettings(widgetSettings);
                      props.closeDrawer();
                    }}
                  >
                    Save
                  </Button>
                </Box>
                <Box mr={1}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={props.closeDrawer}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Drawer>
  );
};

export default DashboardEditWidgetSettings;
