import { createContext } from "react";
import { INegateActualBudgetContextContext } from "../../model/negateActualBudgetModel";

export const NegateActualBudgetContext =
  createContext<INegateActualBudgetContextContext>({
    negateActualBudgetRow: [],
    updatenegateActualBudgetRow: () => {},
    updateMultiplenegateActualBudgetRow: () => {},
    allNegateActualChecked: {
      checked: false,
      touched: false,
    },
    allNegateBudgetChecked: {
      checked: false,
      touched: false,
    },
    updateAllNegateActualChecked: () => {},
    updateAllNegateBudgetChecked: () => {},
    lastColumnChecked: "",
    updateLastColumnChecked: () => {},
    processAllActualChecked: false,
    updateProcessAllActualChecked: () => {},
    processAllBudgetChecked: false,
    updateProcessAllBudgetChecked: () => {},
  });
