import React, { useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  GridReadyEvent,
  GridApi,
  ColumnApi,
  ColDef,
  StatusPanelDef,
  ExcelStyle,
  FirstDataRenderedEvent,
  FilterChangedEvent,
} from "ag-grid-community";
import "ag-grid-enterprise";
import { UseQueryResult } from "@tanstack/react-query";
import { Box, CircularProgress } from "@mui/material";

import LeaseEndRenderer from "./propertyViewRentRollLeaseEndRenderer";
import { TRentRollAssetSummary } from "../model/rentRollAssetSummary";
import { TMonthYearFilter } from "../model/rentRollListFilter";
import { TRentRollOtherList } from "../model/rentRollOtherListModel";
import {
  currencyFormatterNoAbbrev,
  dateFilterParams,
  dateValueFormatter,
  dateValueGetter,
  dateValueGetterNA,
  negativeNumCellStyle,
  numberFormatter,
  numberGetter,
  stringSortCaseInsensitive,
} from "../../util/gridUtils";
import DocumentRenderer from "./propertyViewRentRollDocumentRenderer";
import { DocumentRendererType } from "./propertyViewRentRollListByTenant";
import "../../css/properties.css";
import { getTerm, getTermValue } from "../../util/formatTerm";

interface RentRollListProps {
  rRollList?: UseQueryResult<TRentRollOtherList>;
  monthYearFilter?: TMonthYearFilter;
  setAssetSummaryOther: (assetSummary: TRentRollAssetSummary) => void;
  setAssetSummaryFiltered: (
    assetSummaryFiltered: TRentRollAssetSummary
  ) => void;
  onGridRender: (gridApi?: GridApi, gridColumnApi?: ColumnApi) => void;
  gridApi: GridApi | undefined;
  gridColumnApi: ColumnApi | undefined;
}

const PropertyViewRentRollOtherList: React.FC<RentRollListProps> = (props) => {
  const hasFilterMonth =
    props.monthYearFilter != null &&
    props.monthYearFilter["filterMonth"] &&
    props.monthYearFilter["filterMonth"] != null;
  const hasFilterYear =
    props.monthYearFilter != null &&
    props.monthYearFilter["filterYear"] &&
    props.monthYearFilter["filterYear"] != null;

  if (props.rRollList && hasFilterMonth && hasFilterYear) {
    return props.rRollList?.isLoading || props.rRollList?.isFetching ? (
      <Box m="auto">
        <CircularProgress />
      </Box>
    ) : (
      <React.Fragment>
        <RentRollListMain
          rRollList={props.rRollList!}
          setAssetSummaryOther={props.setAssetSummaryOther}
          setAssetSummaryFiltered={props.setAssetSummaryFiltered}
          onGridRender={props.onGridRender}
          gridApi={props.gridApi}
          gridColumnApi={props.gridColumnApi}
        />
      </React.Fragment>
    );
  } else return <React.Fragment />;
};

interface RentRollListMainProps {
  rRollList: UseQueryResult<TRentRollOtherList>;
  setAssetSummaryOther: (assetSummary: TRentRollAssetSummary) => void;
  setAssetSummaryFiltered: (
    assetSummaryFiltered: TRentRollAssetSummary
  ) => void;
  onGridRender: (gridApi?: GridApi, gridColumnApi?: ColumnApi) => void;
  gridApi: GridApi | undefined;
  gridColumnApi: ColumnApi | undefined;
}

const RentRollListMain: React.FC<RentRollListMainProps> = (props) => {
  const rows = props.rRollList.data?.rentrolls?.result;
  const { onGridRender, gridApi, gridColumnApi } = props;

  useEffect(() => {
    return () => {
      onGridRender(undefined, undefined);
    };
  }, [onGridRender]);

  const autoSizeAll = () => {
    setTimeout(() => gridColumnApi?.autoSizeAllColumns(), 500); // TODO remove setTimeout when Ag-grid fixes autoSizeColumns on React 18
  };

  const [statusBar] = React.useState<{ statusPanels: StatusPanelDef[] }>({
    statusPanels: [
      {
        statusPanel: "agAggregationComponent",
        statusPanelParams: {
          aggFuncs: ["count", "sum", "min", "max", "avg"],
        },
      },
    ],
  });

  const numberSort = (num1: number, num2: number) => {
    return num1 - num2;
  };

  const [columnDefs] = React.useState<ColDef[]>([
    {
      headerName: "Lease ID",
      field: "leaseCode",
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: "agTextColumnFilter",
      cellRenderer: DocumentRendererType.OTHER_DOCUMENT,
      minWidth: 50,
    },
    {
      headerName: "Tenant",
      field: "tenantTitle",
      sortable: true,
      comparator: stringSortCaseInsensitive,
      filter: "agTextColumnFilter",
      minWidth: 50,
    },
    {
      headerName: "Lease Start",
      field: "leaseFrom",
      cellClass: "dateFormat",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params) => dateValueGetter(params.data.leaseFrom),
      valueFormatter: (params) => dateValueFormatter(params.data.leaseFrom),
      minWidth: 50,
    },
    {
      headerName: "Lease End",
      field: "leaseTo",
      cellClass: "dateFormat",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params) => dateValueGetterNA(params.data.leaseTo),
      valueFormatter: (params) => dateValueFormatter(params.data.leaseTo),
      cellRenderer: "leaseEndRenderer",
      minWidth: 150,
    },
    {
      headerName: "Term (mo)",
      field: "leaseTerm",
      sortable: true,
      filter: "agNumberColumnFilter",
      type: "numericColumn",
      valueFormatter: (params) =>
        getTerm(params.data.leaseTo, params.data.leaseTerm),
      valueGetter: (params) =>
        getTermValue(params.data.leaseTo, params.data.leaseTerm),
      comparator: numberSort, //valueFormatter: leaseTermValueFormatter,
      minWidth: 50,
    },
    {
      headerName: "Move-In",
      field: "moveInDate",
      cellClass: "dateFormat",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params) => dateValueGetter(params.data.moveInDate),
      valueFormatter: (params) => dateValueFormatter(params.data.moveInDate),
      minWidth: 50,
    },
    {
      headerName: "Move-Out",
      field: "moveOutDate",
      cellClass: "dateFormat",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params) => dateValueGetter(params.data.moveOutDate),
      valueFormatter: (params) => dateValueFormatter(params.data.moveOutDate),
      minWidth: 50,
    },
    {
      headerName: "Security Deposit",
      field: "securityDeposit",
      cellClass: ["centralize", "currencyFormat"],
      sortable: true,
      filter: "agNumberColumnFilter",
      type: "numericColumn",
      valueFormatter: (params) =>
        currencyFormatterNoAbbrev(params.data.securityDeposit, "$"),
      valueGetter: (params) => numberGetter(params.data.securityDeposit),
      cellStyle: negativeNumCellStyle,
      minWidth: 50,
    },
    {
      headerName: "Current Monthly Rent",
      field: "monthlyRent",
      cellClass: ["centralize", "currencyFormat"],
      sortable: true,
      filter: "agNumberColumnFilter",
      type: "numericColumn",
      valueFormatter: (params) =>
        currencyFormatterNoAbbrev(params.data.monthlyRent, "$"),
      valueGetter: (params) => numberGetter(params.data.monthlyRent),
      cellStyle: negativeNumCellStyle,
      minWidth: 50,
    },
  ]);

  const aggFuncs = {
    // this overrides the grids built-in sum function
    list: (values: any) => {
      let listOfItems = "";

      values.forEach((value: any) => (listOfItems += value + ", "));
      listOfItems = listOfItems.replace(/,\s*$/, "");

      return listOfItems;
    },
    distinctcount: (values: any) => {
      const testCnt: string[] = [];
      values.forEach((value: any) => {
        testCnt.push(value);
      });
      let test = new Set(testCnt);
      return test.size;
    },
  };

  function calculateTotals(isFiltered: boolean): TRentRollAssetSummary {
    // By Suite
    let result: TRentRollAssetSummary;
    let tenantList: string[] = [];
    let numTenantsPercentage: string = "";

    if (isFiltered) {
      gridApi?.forEachNodeAfterFilter((node) => {
        if (typeof node.data !== "undefined") {
          if (!tenantList.includes(node.data.tenantId)) {
            tenantList.push(node.data.tenantId);
          }
        }
      });

      //Totals for Unfiltered:
      let tenantListTotal: string[] = [];
      props.rRollList.data?.rentrolls.result.forEach((ele) => {
        if (typeof ele !== "undefined") {
          if (!tenantListTotal.includes(ele.tenantId)) {
            tenantListTotal.push(ele.tenantId);
          }
        }
      });

      //Filtered Percentages
      numTenantsPercentage =
        tenantListTotal.length > 0
          ? " " +
            Math.round((100 * tenantList.length) / tenantListTotal.length) +
            "%"
          : "";
    } else {
      //Asset Summary Totals
      props.rRollList.data?.rentrolls.result.forEach((ele) => {
        if (typeof ele !== "undefined") {
          if (!tenantList.includes(ele.tenantId)) {
            tenantList.push(ele.tenantId);
          }
        }
      });
    }

    result = {
      rentableAreaDisplay: "N/A",
      numberofSuitesDisplay: "N/A",
      numberOfTenantsDisplay: numberFormatter(tenantList.length, 0) + "",
      occupancyDisplay: "N/A",
      leasedPercentDisplay: "N/A",
      monthlyRentPerSFDisplay: "N/A",
      numberofSuitesPercent: "",
      rentableAreaPercent: "",
      numberOfTenantsPercent: numTenantsPercentage,
      monthlyRentPerSFPercent: "",
      totalNumTenantsOtherCount: numberFormatter(tenantList.length, 0) + "",
    };
    return result;
  }

  const onGridReady = (event: GridReadyEvent) => {
    onGridRender(event.api, event.columnApi);
    if (rows === undefined || rows.length === 0) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }
  };

  const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
    autoSizeAll();
    props.setAssetSummaryOther(calculateTotals(false));
    props.setAssetSummaryFiltered(calculateTotals(true));
  };

  const onFilterChanged = (params: FilterChangedEvent) => {
    props.setAssetSummaryFiltered(calculateTotals(true));
  };

  const excelStyles: ExcelStyle[] = [
    {
      id: "centralize",
      alignment: {
        horizontal: "Right",
      },
    },
    {
      id: "dateFormat",
      dataType: "DateTime",
      numberFormat: { format: "mm/dd/yyyy;@" },
    },
    {
      id: "currencyFormat",
      numberFormat: { format: "\u0024 #,##0.00" },
    },
  ];

  return (
    <div
      id="property-rentroll-content"
      className="example-wrapper"
      style={{ width: "100%" }}
    >
      <div
        id="myGrid"
        className="ag-theme-alpine ag-theme-alpine-container-override"
        style={{ width: "100%" }}
      >
        <AgGridReact
          components={{
            leaseEndRenderer: LeaseEndRenderer,
            [DocumentRendererType.OTHER_DOCUMENT]: DocumentRenderer,
          }}
          excelStyles={excelStyles}
          onGridReady={onGridReady}
          columnDefs={columnDefs}
          rowData={rows}
          onFilterChanged={onFilterChanged}
          onFirstDataRendered={onFirstDataRendered}
          suppressAggFuncInHeader
          aggFuncs={aggFuncs}
          statusBar={statusBar}
          enableRangeSelection={true}
          autoGroupColumnDef={{ minWidth: 100 }}
          sortingOrder={["desc", "asc"]}
          defaultColDef={{
            minWidth: 100,
            resizable: true,
            filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
          }}
        />
      </div>
    </div>
  );
};

export default PropertyViewRentRollOtherList;
