import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import { OidcSecure, useOidc } from "@axa-fr/react-oidc";
import { Routes, Route, Navigate } from "react-router-dom";

import MainDashboard from "../../assetmanagent/dashboard/views/mainDashboard";
import { DrawerStateContext } from "../../../shared/util/drawerStateContext";
import PortfolioContext from "../../portfolio/util/portfolioContext";
import AccountRoles from "./AccountRoles";
import Portfolios from "../../portfolio/portfolios/views/portfolios";
import PortfolioView from "../../portfolio/portfolios/views/portfolioView";
import Properties from "../../properties/property/views/properties";
import PropertyView from "../../properties/property/views/propertyView";
import Users from "../../usermanagement/views/usermanagement";
import Login from "./login";
import CreateUser from "../../createuser/views/CreateUser";
import Info from "../../info/views/info";
import Database from "../../info/views/database";
import PropertyAR from "../../properties/AR/views/propertyAr";
import Buildings from "../../properties/building/views/buildings";
import PropertyViewFinancials from "../../properties/financials/views/PropertyViewFinancials";
import { LoansProvider } from "../../properties/loans/context/LoansContext";
import PropertyViewLoans from "../../properties/loans/views/PropertyViewLoans";
import PropertyViewDetailsNew from "../../properties/property details/views/propertyViewDetailsNew";
//import PropertyViewSnapshot from "../../properties/snapshot/views/propertyViewSnapshot";
import PropertyViewRentRoll from "../../properties/rent roll/views/propertyViewRentRoll";
import PropertyViewSpaces from "../../properties/space/views/propertyViewSpaces";
import PropertyViewStackingPlan from "../../properties/stacking plan/views/propertyViewStackingPlan";
import PortfolioAr from "../../portfolio/AR/views/PortfolioAr";
import PortfolioFinancials from "../../portfolio/financials/views/PortfolioFinancials";
import PortfolioLeasingActivity from "../../portfolio/leasingActivity/views/PortfolioLeasingActivity";
import PortfolioLoans from "../../portfolio/loans/views/PortfolioLoans";
import PortfolioRentRoll from "../../portfolio/rentRoll/views/PortfolioRentRoll";
import Header from "./header";
import "./main.css";
import Documents from "../../documents/view/Documents";
import Reporting from "../../reporting/reporting/views/reporting";
import ReportByProperty from "../../reporting/property/views/ReportByProperty";
import ReportByProperties from "../../reporting/properties/views/ReportByProperties";
import LeasingActivity from "../../properties/leasing activity/views/LeasingActivity";

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = (props) => {
  const { isAuthenticated } = useOidc();
  const { userId, isSuperAdmin } = useContext(PortfolioContext);

  const [drawerState, setDrawerState] = useState(true);

  return (
    <DrawerStateContext.Provider
      value={{ drawerOpen: drawerState, setDrawerOpened: setDrawerState }}
    >
      <React.Fragment>
        {userId !== "" && <AccountRoles userId={userId} />}
        <Header userId={userId} />
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Routes>
            <Route
              path="/portfolios"
              element={
                <OidcSecure>
                  <Portfolios />
                </OidcSecure>
              }
            />
            <Route
              path="/portfolioView"
              element={
                <OidcSecure>
                  <PortfolioView />
                </OidcSecure>
              }
            >
              <Route path={`activity`} element={<PortfolioLeasingActivity />} />
              <Route path={`ar`} element={<PortfolioAr />} />
              <Route path={`loans`} element={<PortfolioLoans />} />
              <Route path={`financials`} element={<PortfolioFinancials />} />
              <Route path={`rentroll`} element={<PortfolioRentRoll />} />
            </Route>
            <Route
              path="/propertyList"
              element={
                <OidcSecure>
                  <Properties />
                </OidcSecure>
              }
            />
            <Route
              path="/reportList"
              element={
                <OidcSecure>
                  <Reporting />
                </OidcSecure>
              }
            >
              <Route path={`property`} element={<ReportByProperty />} />
              <Route path={`properties`} element={<ReportByProperties />} />
            </Route>
            <Route
              path="/documentsList"
              element={
                <OidcSecure>
                  <Documents />
                </OidcSecure>
              }
            />
            <Route
              path="/propertyView"
              element={
                <OidcSecure>
                  <PropertyView />
                </OidcSecure>
              }
            >
              <Route
                path={`propertyViewDetails`}
                element={<PropertyViewDetailsNew />}
              />
              {/*<Route
                path={`propertyViewSnapshot`}
                element={<PropertyViewSnapshot />}
              />*/}
              <Route
                path={`propertyViewRentRoll`}
                element={<PropertyViewRentRoll />}
              />
              <Route
                path={`propertyViewStackingPlan`}
                element={<PropertyViewStackingPlan />}
              />
              <Route path={`activity`} element={<LeasingActivity />} />
              <Route path={`ar`} element={<PropertyAR />} />
              <Route path={`financials`} element={<PropertyViewFinancials />} />
              <Route
                path={`loans`}
                element={
                  <LoansProvider>
                    <PropertyViewLoans />
                  </LoansProvider>
                }
              />
              <Route path={`buildings`} element={<Buildings />} />
              <Route
                path={`propertyViewSpaces`}
                element={<PropertyViewSpaces />}
              />
            </Route>
            <Route
              path="/AMDashboard"
              element={
                <OidcSecure>
                  <MainDashboard />
                </OidcSecure>
              }
            />
            {!isAuthenticated && <Route path="/createUser" element={<CreateUser />} />}
            <Route
              path="/access-transfer"
              element={
                <OidcSecure> 
                  <Login />
                </OidcSecure>
              }
            />
            <Route path="/login" element={<Login />} />
            {isSuperAdmin && (
              <Route
                path="/info"
                element={
                  <OidcSecure>
                    <Info />
                  </OidcSecure>
                }
              />
            )}
            {isSuperAdmin && (
              <Route
                path="/database"
                element={
                  <OidcSecure>
                    <Database />
                  </OidcSecure>
                }
              />
            )}
            <Route
              path="/usermanagement"
              element={
                <OidcSecure>
                  <Users />
                </OidcSecure>
              }
            />
            <Route path="/" element={<Navigate to="/AMDashboard" />} />
          </Routes>
          {props.children}
        </Box>
      </React.Fragment>
      <div
        id="exportTmpContainer"
        style={{
          width: 1200,
          display: "none",
        }}
      ></div>
    </DrawerStateContext.Provider>
  );
};

export default Layout;
