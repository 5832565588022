import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  DialogActions,
  Button,
  DialogTitle,
  Typography,
  IconButton,
} from "@mui/material";
import { formatDateFNS } from "../../property details/util/formatDateUtil";
import { useGetDealComments } from "../api/leasingActivityService";
import { DealCommentsFilter } from "../model/dealCommentsFilter";
import { UilMultiply } from "@iconscout/react-unicons";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

interface DealCommentsProps {
  handlePopupClose: () => void;
  filter: DealCommentsFilter;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leasingActivityModalDialog: {
      "& .MuiDialog-paper": {
        padding: 0,
      },
      "& .MuiDialog-paper .MuiDialogTitle-root": {
        padding: "16px 24px 8px 24px",
      },
      "& .MuiDialog-paper .MuiDialogActions-root": {
        padding: "8px 24px 16px 24px",
      },
      "& .MuiDialog-paper .MuiTypography-body1": {
        padding: 0,
        fontSize: 15,
        fontWeight: "normal",
        color: "var(--primary-dark-blue)",
      },
      "& .MuiDialog-paper .MuiDialogContent-root": {
        padding: "8px 0",
      },
      "& .MuiTable-root": {
        borderTop: "1px solid var(--neutral-grey-150)",
      },
      "& .MuiTableCell-root": {
        padding: "12px 20px",
        border: "1px solid var(--neutral-grey-150)",
        borderTop: 0,
        borderLeft: 0,
        verticalAlign: "top",
        overflowWrap: "break-word",
        breakWord: "break-all",
      },
      "& .MuiTableCell-head": {
        whiteSpace: "nowrap",
      },
      "& .MuiTableCell-root:first-child": {
        width: "15%",
      },
      "& .MuiTableCell-root:last-child": {
        width: "60%",
        borderRight: 0,
      },
    },
  })
);

const DealComments: React.FunctionComponent<DealCommentsProps> = (props) => {
  const classes = useStyles();
  const dealComments = useGetDealComments(props.filter);

  return (
    <Dialog open={true} aria-labelledby="deal-comments" fullWidth maxWidth="md" className={classes.leasingActivityModalDialog}>
      <DialogTitle id="deal-comments-modal">
        <Typography component="span" variant="h2" color="primary.dark">
          Comments
        </Typography>
        <IconButton
          aria-label="close"
          disabled={dealComments.isLoading}
          onClick={() => props.handlePopupClose()}
          className="icon-dialog-close"
          size="large"
        >
          <UilMultiply />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {dealComments.isFetching || !dealComments.data ? (
          <Box m="auto">
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ width: "100%" }}>
            <TableContainer>
              <Table
                aria-label="comments"
                style={{ overflow: "hidden" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        Date &amp; Time
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        From
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4" color="neutral700.light">
                        Comments
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dealComments.data.map((comment, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {comment.dateTimeStamp
                            ? formatDateFNS(comment.dateTimeStamp,  "MM/dd/yyyy hh:mm:ss aa")
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {comment.commentFrom}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="primary.dark">
                          {comment.comment}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={dealComments.isLoading}
          onClick={() => props.handlePopupClose()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DealComments;
