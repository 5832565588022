import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Box,
  FormControl,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
// import { Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useForm } from "react-hook-form";
import { useAddARNote } from "../api/ARService";
import { usePropertyInfo } from "../../util/propertyInfoContext";
import DialogTitleCustom from "../../../UI/DialogTitleCustom";
import { TAddARNoteModel } from "../model/addARNoteModel";
import SimpleAlert from "../../../UI/view/SimpleAlert";
import { MentionsInput, Mention } from "react-mentions";
import defaultMentionsInputStyle from "../../util/defaultMentionsInputStyle";
import defaultMentionsStyle from "../../util/defaultMentionsStyle";
import { useGetUserListByProperty } from "../../../usermanagement/api/userService";
import { TUserListByPropertyFilter } from "../../../usermanagement/model/userListByPropertyFilter";
import {
  messageErrorMessage,
  validateMessage,
} from "../../util/messageValidationHelper";

const CustomizedMentionsInput = styled(MentionsInput)`
  position: static !important;
  margin: 0 auto;
  padding: 4px !important;
  width: auto !important;
  border: 0;
  overflow-y: visible !important;
  & .form-control-field__control {
    position: relative;
    padding: 0;
    min-height: 100px;
    background-color: var(--neutral-grey-50);
    border: 1px solid transparent;
    border-radius: 6px;
  }
  & .form-control-field__highlighter {
    padding: 12px !important;
    height: 109px;
    overflow: auto !important;
    & strong {
      background-color: rgba(6, 108, 250, 0.12) !important;
      border-radius: 6px;
      color: transparent !important;
    }
  }
  & .form-control-field__highlighter__substring {
    box-sizing: border-box !important;
  }
  & textarea {
    padding: 12px !important;
    height: 109px !important;
    overflow-y: auto !important;
  }
  &:hover textarea {
    box-shadow: 0 0 0 3px rgb(6 108 250 / 16%);
  }
  & textarea:focus-visible {
    outline: solid 1px var(--primary-blue) !important;
  }
  & .form-control-field__suggestions {
    top: 24px !important;
    padding: 12px;
    max-height: 190px;
    overflow-y: auto;
    border: 1px solid var(--neutral-grey-200);
    border-radius: 6px;
  }
  & .form-control-field__suggestions__list {
    font-size: 14px !important;
  }
  & .form-control-field__suggestions__item {
    padding: 8px;
    color: var(--primary-dark-blue);
    border: 0 !important;
  }
  & .form-control-field__suggestions__item:hover {
    background-color: rgba(6, 108, 250, 0.12) !important;
    border-radius: 6px;
    color: var(--primary-blue);
  }
  & .form-control-field__suggestions__item--focused {
    background-color: rgba(6, 108, 250, 0.12) !important;
    border-radius: 6px;
    color: var(--primary-blue);
  }
`;

interface Users {
  id: string;
  display: string;
}

const AddArNoteChildModal: React.FC<any> = (props: any) => {
  const { title, open, setOpen, tenantId, handleEditPopupClose } = props;
  const [disable, setDisable] = useState(true);
  const [pageError, setPageError] = React.useState<string | null>(null);
  const handleClose = () => {
    setOpen(false);
  };

  const {
    handleSubmit,
    register,
    control,
    setError,
    formState: { errors },
  } = useForm<TAddARNoteModel>();

  const addArNoteResponse = useAddARNote();
  const propertyInfo = usePropertyInfo();

  const userByPropertyFilter: TUserListByPropertyFilter = {
    accountIdentifier: propertyInfo.accountIdentifier,
    propertyId: propertyInfo.id,
  };

  const { data: userList } = useGetUserListByProperty(userByPropertyFilter);

  const fetchUsers = (query: any, callback: (arg0: Users[]) => void) => {
    if (userList === undefined) {
      console.log("userList undefined");
      return;
    }
    if (query === undefined) {
      console.log("query undefined");
      query = "";
    }
    let userModifiedList = userList
      // .filter((user) => user.status === 1)
      .map((e) => {
        return {
          id: e.userId,
          display: "@" + (e.fullName !== undefined ? e.fullName : ""),
        };
      });
    if (userModifiedList !== null) {
      userModifiedList = userModifiedList.sort((objA, objB) =>
        objA.display.toLowerCase() > objB.display.toLowerCase() ? 1 : -1
      );
      const filteredUsers = userModifiedList.filter(
        (user) =>
          user.display.length > 1 &&
          user.display
            .substring(1)
            .split(" ")
            .some((name) => name.toLowerCase().startsWith(query.toLowerCase()))
      );
      callback(filteredUsers);
    }
  };

  // Add scrolling to mentions suggestion list
  useEffect(() => {
    document.body.addEventListener(
      "mousemove",
      (event) => {
        let div: HTMLDivElement | null = document.querySelector(
          "div.form-control-field__suggestions"
        );
        if (div !== null) div!.style.pointerEvents = "auto";
        // console.log("mousemove");
      },
      false
    );

    document.body.addEventListener(
      "keydown",
      (event) => {
        if (event.code === "ArrowDown" || event.code === "ArrowUp") {
          let selectedIdx = -1;
          let suggestionsDiv = document.getElementsByClassName(
            "form-control-field__suggestions"
          );
          let div: HTMLDivElement | null = document.querySelector(
            "div.form-control-field__suggestions"
          );
          if (div !== null) div!.style.pointerEvents = "none";

          let list = document.getElementsByClassName(
            "form-control-field__suggestions__list"
          );
          // var numItems = list[0].getElementsByTagName("li").length;
          // console.log("numItems: " + numItems.toString());

          let items = list[0].getElementsByTagName("li");

          for (var i = 0; i < items.length; i++) {
            if (items[i].ariaSelected === "true") {
              selectedIdx = i;
              break;
              // console.log("selected: " + i.toString());
            }
          }

          if (suggestionsDiv[0].scrollHeight > suggestionsDiv[0].clientHeight) {
            if (selectedIdx < items.length - 1) {
              suggestionsDiv[0].scrollTo(
                0,
                selectedIdx * (suggestionsDiv[0].scrollHeight / items.length)
              );
            } else {
              suggestionsDiv[0].scrollTop = suggestionsDiv[0].scrollHeight;
            }
          }
          // document.body.style.pointerEvents = "auto";
        }
      },
      false
    );
  }, []);

  const [note, setNote] = useState("");
  const onMentionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisable(event.target.value === "");
    console.log("event.target.value: " + event.target.value);

    if (event.target.value !== undefined) {
      console.log("note size: " + event.target.value.length);
      if (validateMessage(event.target.value)) {
        //clear errors
        if (Boolean(errors) && Boolean(errors.message)) delete errors.message;
        setDisable(false);
      } else {
        setError("message", {
          type: "manual",
          message: messageErrorMessage,
        });
        setDisable(true);
      }
    }

    setNote(event.target.value);
  };

  const onSubmit = handleSubmit((model: TAddARNoteModel) => {
    model.propertyId = propertyInfo.id;
    model.accountIdentifier = propertyInfo.accountIdentifier;
    model.tenantId = tenantId;
    model.message = note;

    addArNoteResponse.mutate(model, {
      onSuccess: () => {
        handleEditPopupClose(true);
        setOpen(false);
      },
      onError: (message: any) => {
        const msg =
          (message as string) ||
          "Something went wrong, please try again later.";
        setPageError(msg);
        handleEditPopupClose(false);
        setOpen(false);
      },
    });
  });

  const messageRef = useRef<typeof MentionsInput>(null);
  const { ref, ...rest } = register("message");

  return (
    <React.Fragment>
      {pageError !== null && (
        <SimpleAlert
          severityType="error"
          onClose={() => setPageError(null)}
          message={pageError}
        />
      )}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          "& div[role=dialog]": {
            overflowY: "visible !important",
            maxWidth: "500px",
            width: "80%",
          },
        }}
      >
        <DialogTitleCustom title={title} onClose={handleClose} />
        <DialogContent
          sx={{
            overflowY: "visible !important",
          }}
        >
          <React.Fragment>
            <form onSubmit={onSubmit}>
              <Box>
                <FormControl className={"form-control"}>
                  {/* 
                  <TextField
                    rows={4}
                    className={
                      "form-control-field form-control-textarea form-control-textarea-notes"
                    }
                    type="text"
                    multiline
                    error={!!errors.message}
                    placeholder="Write your notes here..."
                    helperText={errors.message ? errors.message.message : ""}
                    {...register("message", {
                      required: "Note is required",
                      maxLength: {
                        value: 250,
                        message: "Maximum length is 250",
                      },
                    })}
                    onChange={handleChange}
                  />*/}

                  <CustomizedMentionsInput
                    {...rest}
                    value={note}
                    onChange={onMentionChange}
                    type="text"
                    multiline
                    control={control}
                    // rules={registerOptions.maxLength}
                    name={"message"}
                    // ref={register}
                    ref={(e: any) => {
                      ref(e);
                      messageRef.current = e; // can still assign to ref
                    }}
                    // ref={register("message", {
                    //   // required: "Required",
                    //   // maxLength: {
                    //   //   value: 250,
                    //   //   message: "Maximum length is 250",
                    //   // },
                    // })}
                    // {...register("message")}
                    // error={!!errors.message}
                    // {...register("message", {
                    //   required: "Note is required",
                    //   maxLength: {
                    //     value: 250,
                    //     message: "Maximum length is 250",
                    //   },
                    // })}
                    // error={!!errors.message}
                    // error={true}
                    placeholder="Write your notes here..."
                    a11ySuggestionsListLabel={"Suggested mentions"}
                    allowSpaceInQuery={true}
                    allowSuggestionsAboveCursor={true}
                    style={defaultMentionsInputStyle}
                    className={
                      "form-control-field form-control-textarea form-control-textarea-notes"
                    }
                  >
                    <Mention
                      trigger="@"
                      // data={users}
                      data={fetchUsers}
                      appendSpaceOnAdd={true}
                      style={defaultMentionsStyle}
                      // markup="@[__display__](__id__)"
                      markup="~~{__id__~~}~~(__display__~~)"
                    />
                  </CustomizedMentionsInput>

                  {/* <Controller
                    control={control}
                    name={"message"}
                    defaultValue={note}
                    rules={{
                      validate: {
                        format: (value) => {
                          console.log("note length: " + note.length);
                          return note !== undefined && !validateMessage(note)
                            ? messageErrorMessage
                            : true;
                        },
                      },
                    }}
                    render={({ field }) => (
                      <CustomizedMentionsInput
                        // inputRef={field.ref}
                        inputRef={inputEl}
                        value={note}
                        type="text"
                        // multiline
                        name={"message"}
                        onChange={onMentionChange}
                        control={control}
                        className={
                          "form-control-field form-control-textarea form-control-textarea-notes"
                        }
                        placeholder={"Write your notes here..."}
                        a11ySuggestionsListLabel="Suggested mentions"
                        allowSpaceInQuery={true}
                        allowSuggestionsAboveCursor={true}
                        style={defaultMentionsInputStyle}
                      >
                        <Mention
                          trigger="@"
                          // data={users}
                          data={fetchUsers}
                          appendSpaceOnAdd={true}
                          style={defaultMentionsStyle}
                          // markup="@[__display__](__id__)"
                          markup="~~{__id__~~}~~(__display__~~)"
                        />
                      </CustomizedMentionsInput>
                    )}
                  /> */}

                  {Boolean(errors.message) && (
                    <FormHelperText style={{ color: "red" }}>
                      {errors.message?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </form>

            {addArNoteResponse.isLoading && (
              <Box m="auto">
                <CircularProgress />
              </Box>
            )}
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            size="small"
            autoFocus
            disabled={addArNoteResponse.isLoading}
          >
            Cancel
          </Button>
          <Button
            type={"submit"}
            onClick={onSubmit}
            color="primary"
            variant="contained"
            size="small"
            disabled={disable || addArNoteResponse.isLoading}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default AddArNoteChildModal;
