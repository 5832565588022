import React, { Fragment, useState, useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";

import BuildingList from "./buildingList";
import BuildingInsert from "./buildingInsert";
import { Button } from "@mui/material";

const Buildings: React.FunctionComponent = () => {
  const [showInsertPopup, setShowInsertPopup] = useState(false);
  const queryClient = useQueryClient();

  const handlePopupClose = useCallback(
    (shouldRefreshData: boolean) => {
      setShowInsertPopup(false);
      shouldRefreshData && queryClient.invalidateQueries(["getBuildingList"]);
    },
    [queryClient]
  );

  return (
    <Fragment>
      <div
        id="property-buildings"
        className="tab-pane fade active show"
        role="tabpanel"
        aria-labelledby="property-tab-buildings"
      >
        <div
          id="property-buildings-action-bar"
          className="d-flex flex-row align-items-top bg-light border-bottom py-2"
        >
          <div className="btn-group pr-1 p1-3">
            <Button
              variant="contained"
              size="small"
              color="primary"
              className="btn btn-primary btn-sm"
              onClick={() => setShowInsertPopup(true)}
            >
              + Add Building
            </Button>
          </div>
        </div>

        <BuildingList />
        {showInsertPopup && (
          <BuildingInsert
            handlePopupClose={handlePopupClose}
            showInsertPopup={showInsertPopup}
          />
        )}
      </div>
    </Fragment>
  );
};
export default Buildings;
