import { ICapitalPartner, IValuation } from "../model/propertyOverviewModel";
import { ChipTheme } from "../views/InfoChip";
import { TChipTheme } from "../views/InvestmentDetailsEdit";

export const prefixCapitalPartId = "new-capital-partner";
export const prefixValuationId = "new-valuation";

export const computeChipText = (
  chipTheme: ChipTheme,
  sumOfPercents: number
) => {
  if (chipTheme === "warning") return `${sumOfPercents}% of 100%`;
  else if (chipTheme === "danger") return `${sumOfPercents}% of 100`;
  else return `100% completed`;
};

/**
 * Computes the error text for capital partners when a user enters percentages
 * @param chipTheme undefined | success | warning | danger
 * @returns returns an empty string in the case of undefined or success (indicating there's no error). Otherwise returns appropriate message.
 */
export const computeCapitalPartnerErrorText = (chipTheme: TChipTheme) => {
  let warningText = "";
  if (chipTheme === "warning")
    warningText =
      "Please change your percentage or sum of all percentages to complete 100%";
  else if (chipTheme === "danger")
    warningText = "Ownership or sum of ownership percentages can't exceed 100%";

  return warningText;
};

/**
 * react-hook-form generates a random id when we append a new field to FieldArray. This method checks whether an id was generated by RHF or not.
 * @param id id of the valuation
 * @param valuations All the valuations returned by the backend
 * @returns true if the id was generated by the backend; false if it was generated by react hook form
 */
export const isValidValuationId = (id?: string, valuations?: IValuation[]) => {
  if (valuations === undefined) return false;

  const valuation = valuations.find((val) => val.id === id);
  if (valuation === undefined) return false;
  else return true;
};

/**
 * Filters based on whether a capital partner is empty or not.
 * Then removes the dummy ids created when a new field was added.
 * @param capitalPartners
 */
export const trimCapitalPartners = (
  capitalPartners: ICapitalPartner[],
  isYourFirmId?: string
) => {
  return capitalPartners
    .filter((capitalPartner) => {
      if (isNaN(capitalPartner.capitalPartnerOwnership)) return false;
      return !(capitalPartner.capitalPartnerOwnership < 1);
    })
    .map((capitalPartner) => {
      const updatedCapitalPartner = {
        ...capitalPartner,
        isOwner: isYourFirmId === capitalPartner.id,
      };
      if (capitalPartner.id?.includes(prefixCapitalPartId)) {
        return {
          ...updatedCapitalPartner,
          id: undefined,
        };
      } else return { ...updatedCapitalPartner };
    });
};

/**
 * Filters based on whether a valuation is empty or not.
 * Then removes the dummy ids created when a new field was added.
 * @param valuations
 */
export const trimValuations = (valuations: IValuation[]) => {
  return valuations
    .filter((valuation) => {
      if (Number.isNaN(valuation.valuation) || (valuation.valuation || 0) < 1) return false;
      if (valuation.valuationDate === null) return false;
      return true;
    })
    .map((valuation) => {
      if (valuation.id?.includes(prefixValuationId)) {
        return {
          ...valuation,
          id: undefined,
        };
      } else return { ...valuation };
    });
};
