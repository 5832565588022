import React, { useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  GridReadyEvent,
  GridApi,
  ColumnApi,
  FirstDataRenderedEvent,
} from "ag-grid-community";
import "ag-grid-enterprise";
import "../../css/properties.css";

import {
  currencyFormatterNoAbbrev,
  negativeAndPositiveNumCellStyle,
  negativeNumCellStyle,
  stringSortCaseInsensitive,
} from "../../util/gridUtils";
import { getStatusBar } from "../util/financialsGrid";
import { FinancialsFilter } from "./PropertyViewFinancials";
import { useGetFinancialData } from "../api/financialService";
import { Box, CircularProgress } from "@mui/material";
import { UseQueryResult } from "@tanstack/react-query";
import { GridConfig } from "../../../../shared/model/gridConfig";
// import { GRID_CONFIG_DEFAULT_VIEW } from "../../../../shared/view/SaveGridStateOptions";
import BudgetNoteRenderer from "./BudgetNoteRenderer";

interface Props {
  propertyId: string;
  accountIdentifier: string;
  financialsFilter: FinancialsFilter;
  financialsTableShouldRender: boolean;
  gridApi: GridApi | undefined;
  gridColumnApi: ColumnApi | undefined;
  gridConfigsQuery: UseQueryResult<GridConfig, unknown>;
  gridView: string;
  updateMinMaxDate: (minDate: Date, maxDate: Date) => void;
  updateFinancialsEndDate: (minDate: Date, maxDate: Date) => void;
  updateFinancialsFilter: (minDate: Date, maxDate: Date) => void;
  updateGridApi: (gridApi: GridApi) => void;
  updateColumnApi: (columnApi: ColumnApi) => void;
}

const FinancialsGrid: React.FC<Props> = (props) => {
  const GRID_CONFIG_DEFAULT_VIEW = "Standard";
  const { gridConfigsQuery, gridApi, gridColumnApi, gridView } = props;

  const financialsQuery = useGetFinancialData({
    propertyId: props.propertyId,
    accountIdentifier: props.accountIdentifier,
    financialsFilter: props.financialsFilter,
  });

  const { isSuccess, data: financialsData } = financialsQuery;

  useEffect(() => {
    if (!isSuccess || financialsData === undefined) return;

    props.updateMinMaxDate(
      financialsQuery.data.minDate,
      financialsQuery.data.maxDate
    );
    props.updateFinancialsEndDate(
      financialsQuery.data.minDate,
      financialsQuery.data.maxDate
    );
    props.updateFinancialsFilter(
      financialsQuery.data.minDate,
      financialsQuery.data.maxDate
    );
    if (gridApi !== undefined && gridApi !== null) {
      gridApi?.expandAll();
      gridApi.redrawRows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialsData, gridApi]);

  const columns = financialsData?.columnDefs;
  const rowData = financialsData?.financialData;

  const setNumberColor= (param:any)=>{
    if(param.colDef.type?.includes('money'))
    {
      if(param.value?.startsWith!==undefined && param.value.startsWith('('))
      {
        return "negativeNumber";
      }else{
        return "positiveNumber";
      }
    }
    return null;
  }
  let newColumns: any[] = [];
  if (columns !== undefined) {
    columns.forEach((column: any) => {
      column.cellClass = setNumberColor;
    });
    columns.forEach((column: any) => {
      column['headerClass'] = 'header';
    });
    columns.forEach((column: any) => {
      if ("type" in column) {
        if (column.type === "group") newColumns.push({ ...column });
        else if (column.type === "negate") {
        } else {
          newColumns.push({ ...column });
        }
      } else {
        const newCol = { ...column };
        if (column.field === "AccountNumber")
          newColumns.push({
            ...newCol,
            headerName: "Account",
            comparator: stringSortCaseInsensitive,
          });
        else if ("children" in newCol && Array.isArray(newCol.children)) {
          newCol.children.forEach((child: any) => {
            if ("type" in child && Array.isArray(child.type))
              if (child.type.includes("money"))
                child.cellClass = "currencyFormat";
          });
          newColumns.push({ ...newCol });
        } else {
          newColumns.push({ ...column });
        }
      }
    });
  }
  const statusBar = getStatusBar();

  //Add col size and filter to accountnumber
  if (newColumns != null) {
    newColumns.forEach((item) => {
      if (item.field === "AccountNumber") {
        item["minWidth"] = "150";
        item["filter"] = "agTextColumnFilter";
      }
    });
  }

  const autoSizeAll = () => {
    setTimeout(() => {
      if (gridColumnApi !== undefined && gridColumnApi !== null)
        gridColumnApi?.autoSizeAllColumns();
    }, 500); // TODO remove setTimeout when Ag-grid fixes autoSizeColumns on React 18
  };

  const onGridReady = (event: GridReadyEvent) => {
    props.updateGridApi(event.api);
    props.updateColumnApi(event.columnApi);
    if (typeof rowData === "undefined" || rowData.length === 0) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    autoSizeAll();
  };

  const formatCurrency = (params: { value: number | undefined }) => {
    return currencyFormatterNoAbbrev(params.value, "$");
  };

  const getIndentClass = (params:any) => {
    let indent = 0;
    let node = params.node;
    let level = node.level + 1;
    let isGroup = node.group;
    let classId = isGroup?`indent-group-${level}`:`indent-${level}`;
    return classId;
  };
  const aggFuncs = {
    sum: (params: any) => {
      let s = 0;
      if (params.values && params.values.length > 0) {
        let colId = params.column.colId;
        let isVariance = colId.toUpperCase().endsWith("VARIANCE")
          ? true
          : false;
        params.rowNode.allLeafChildren.forEach((v: any) => {
          let propagateV = 1;
          if (params.rowNode.field) {
            let nextLevel = parseInt(params.rowNode.field.slice(-1)) + 1;
            for (let pi = nextLevel; pi < 10; pi++) {
              if ("Propagate" + pi in v.data) {
                propagateV *= v.data["Propagate" + pi];
              } else {
                break;
              }
            }
          }
          s += v.data[colId] * (isVariance ? 1 : v.data["Negate"]) * propagateV;
        });
        try {
          let negateV = 1;
          if (
            params.rowNode.allChildrenCount ==
            params.rowNode.allLeafChildren.length
          ) {
            let currentLevel = parseInt(params.rowNode.field.slice(-1));
            let alc = params.rowNode.allLeafChildren[0]["data"];
            negateV = alc["Negate" + currentLevel] * (isVariance ? -1 : 1);
          } else {
            let nextLevel = parseInt(params.rowNode.field.slice(-1)) + 1;
            for (let alcObj of params.rowNode.allLeafChildren) {
              let alc = alcObj["data"];
              if ("Negate" + nextLevel in alc) {
                if (alc["Negate" + nextLevel] * (isVariance ? -1 : 1) == -1) {
                  negateV = isVariance ? 1 : -1;
                  break;
                }
              } else if ("Negate" in alc) {
                if (alc["Negate"] * (isVariance ? -1 : 1) == -1) {
                  negateV = isVariance ? 1 : -1;
                  break;
                }
              }
            }
          }

          if(!isVariance) s *= negateV;
        } catch (err) {}
      }
      return s;
    },
  };

  /**
   * Sets the context that is retrieved from the backend whenever the gridView is changed.
   * gridView is either changed when data first loads (only if a default value exists) or when the user uses the dropdown
   */
  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1 ||
      gridApi === undefined ||
      gridColumnApi === undefined
    )
      return;

    if (gridView === GRID_CONFIG_DEFAULT_VIEW) {
      gridApi.setFilterModel({});
      if (gridColumnApi !== undefined && gridColumnApi !== null) {
        gridColumnApi.resetColumnState();
      }
      return;
    }

    const selectedGridConfig = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.id === Number(gridView)
    );

    if (selectedGridConfig === undefined) return;

    gridApi.setFilterModel(JSON.parse(selectedGridConfig.columnFilterState));
    if (gridColumnApi !== undefined && gridColumnApi !== null) {
      gridColumnApi.applyColumnState({
        state: JSON.parse(selectedGridConfig.columnState),
      });
      autoSizeAll();
      if (gridApi !== undefined && gridApi !== null) {
        gridApi?.expandAll();
        gridApi.redrawRows();
      }
    }

    // }, [gridView, gridApi, gridColumnApi, newColumns]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridView, gridApi, gridColumnApi]);

  if (financialsQuery.isLoading)
    return (
      <Box m="auto">
        <CircularProgress />
      </Box>
    );

  return (
    <React.Fragment>
      {props.financialsTableShouldRender && (
        <div className="standard-content">
          <div
            className="table-standard-toggle-container"
            style={{ width: "100%" }}
          >
            <div
              id="property-financials-content"
              className="example-wrapper"
              style={{ width: "100%" }}
            >
              <div
                id="myGrid"
                className="ag-theme-alpine ag-theme-alpine-container-override"
                style={{ width: "100%" }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  onFirstDataRendered={onFirstDataRendered}
                  suppressAggFuncInHeader={true}
                  enableCharts={true}
                  statusBar={statusBar}
                  enableRangeSelection={true}
                  groupIncludeFooter={true}
                  aggFuncs={aggFuncs}
                  excelStyles={[
                    {
                      id: "currencyFormat",
                      numberFormat: { format: "\u0024 #,##0.00" },
                    },
                    {id: 'head1', font: {fontName: 'arial', bold: true, size:14}, alignment: {vertical: 'Center',},},
                    {id: 'head2', font: {fontName: 'arial', bold: true,}, alignment: {vertical: 'Center',},},
                    {id: 'header', font: {fontName: 'arial', bold: true,}, alignment: {vertical: 'Center',},},
                    {id: 'indent-1', font: {fontName: 'arial'}, alignment: {indent: 4, vertical: 'Center',},dataType: 'String',},
                    {id: 'indent-2', font: {fontName: 'arial'}, alignment: {indent: 8, vertical: 'Center',},dataType: 'String',},
                    {id: 'indent-3', font: {fontName: 'arial'}, alignment: {indent: 12, vertical: 'Center',},dataType: 'String',},
                    {id: 'indent-4', font: {fontName: 'arial'}, alignment: {indent: 16, vertical: 'Center',},dataType: 'String',},
                    {id: 'indent-5', font: {fontName: 'arial'}, alignment: {indent: 20, vertical: 'Center',},dataType: 'String',},
                    {id: 'indent-6', font: {fontName: 'arial'}, alignment: {indent: 24, vertical: 'Center',},dataType: 'String',},
                    {id: 'indent-7', font: {fontName: 'arial'}, alignment: {indent: 28, vertical: 'Center',},dataType: 'String',},
                    {id: 'indent-group-1', font: {fontName: 'arial', bold: true,},alignment: {indent: 4, vertical: 'Center',}, dataType: 'String',},
                    {id: 'indent-group-2', font: {fontName: 'arial', bold: true,},alignment: {indent: 8, vertical: 'Center',}, dataType: 'String',},
                    {id: 'indent-group-3', font: {fontName: 'arial', bold: true,},alignment: {indent: 12, vertical: 'Center',}, dataType: 'String',},
                    {id: 'indent-group-4', font: {fontName: 'arial', bold: true,},alignment: {indent: 16, vertical: 'Center',}, dataType: 'String',},
                    {id: 'indent-group-5', font: {fontName: 'arial', bold: true,},alignment: {indent: 20, vertical: 'Center',}, dataType: 'String',},
                    {id: 'indent-group-6', font: {fontName: 'arial', bold: true,},alignment: {indent: 24, vertical: 'Center',}, dataType: 'String',},
                    {id: 'indent-group-7', font: {fontName: 'arial', bold: true,},alignment: {indent: 28, vertical: 'Center',}, dataType: 'String',},
                    {id: 'negativeNumber', font: {color: '#ff0000'},alignment: {horizontal: 'Right'},},
                    {id: 'positiveNumber', alignment: {horizontal: 'Right'},}
                  ]}
                  components={{
                    budgetNoteRenderer: BudgetNoteRenderer,
                  }}
                  sortingOrder={["desc", "asc"]}
                  defaultColDef={{
                    initialWidth: 100,
                    sortable: true,
                    resizable: true,
                    filterParams: {
                      buttons: ["apply", "reset"],
                      closeOnApply: true,
                    },
                  }}
                  columnTypes={{
                    money: {
                      width: 130,
                      type: "numericColumn",
                      valueFormatter: formatCurrency,
                      aggFunc: "sum",
                      cellStyle: negativeNumCellStyle,
                      filter: "agNumberColumnFilter",
                      filterParams: {
                        filterOptions: [
                          "lessThan",
                          "greaterThan",
                          "inRange",
                          "blank",
                          "notBlank",
                        ],
                      },
                    },
                    group: {
                      width: 130,
                      rowGroup: true,
                      hide: true,
                    },
                    textType: {
                      filter: "agTextColumnFilter",
                      cellRenderer: "budgetNoteRenderer",
                    },
                  }}
                  autoGroupColumnDef={{
                    headerName: "Description ",
                    field: "Description",
                    minWidth: 390,
                    cellRendererParams: {
                      suppressCount: true,
                    },
                    comparator: stringSortCaseInsensitive,
                    cellClass: getIndentClass,
                    flex: 1,
                  }}
                  
                >
                  {newColumns.map((column, index) => {
                    return column.hasOwnProperty("children") ? (
                      <AgGridColumn
                        headerName={column.headerName}
                        marryChildren={true}
                        key={index}
                        type={"rightAligned"}
                      >
                        {column?.children.map(
                          (childcol: any, index: number) => (
                            <AgGridColumn
                              {...childcol}
                              key={index}
                              headerTooltip={childcol.headerName}
                              {...(childcol.headerName === "VARIANCE" && {
                                cellStyle: negativeAndPositiveNumCellStyle,
                              })}
                            />
                          )
                        )}
                      </AgGridColumn>
                    ) : (
                      <AgGridColumn {...column} key={column.field} />
                    );
                  })}
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default FinancialsGrid;
