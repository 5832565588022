import { useQuery } from "@tanstack/react-query";
import {
  AccountIdentifierHeaderName,
  useAuthorizedApiAxios,
} from "../../../../shared/api/authorizedAxios";
import { LeasingActivity } from "../model/leasingActivity";
import { DealProposal } from "../model/dealProposal";
import { DealProposalsFilter } from "../model/dealProposalsFilter";
import { DealSpacesFilter } from "../model/dealSpacesFilter";
import { DealSpace } from "../model/dealSpace";
import { DealContactsFilter } from "../model/dealContactsFilter";
import { DealContact } from "../model/dealContact";
import { DealCommentsFilter } from "../model/dealCommentsFilter";
import { DealComment } from "../model/dealComment";

export const useGetLeasingActivity = (
  propertyId: string,
  accountIdentifier: string,
  year: number | undefined,
  month: number | undefined
) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["leasingActivity", propertyId, accountIdentifier, year, month],
    ({ signal }) => {
      const url = `/AssetService/v1/Properties/${propertyId}/LeasingActivity?year=${year}&month=${month}`;
      return authorizedApiAxios().get<LeasingActivity>(url, {
        signal,
        headers: {
          [AccountIdentifierHeaderName]: accountIdentifier,
        },
      });
    },
    {
      enabled: month !== undefined && year !== undefined,
      select: (response) => response!.data,
    }
  );
};

export const useGetDealProposals = (filter: DealProposalsFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["dealProposals", filter],
    ({ signal }) => {
      const url = `/AssetService/v1/Properties/${filter.propertyId}/Deals/${filter.dealId}/Proposals?year=${filter.year}&month=${filter.month}`;
      return authorizedApiAxios().get<DealProposal[]>(url, {
        signal,
        headers: {
          [AccountIdentifierHeaderName]: filter?.accountIdentifier,
        },
      });
    },
    {
      enabled: filter.month !== undefined && filter.year !== undefined,
      select: (response) => response!.data,
    }
  );
};

export const useGetDealSpaces = (filter: DealSpacesFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["dealSpaces", filter],
    ({ signal }) => {
      const url = `/AssetService/v1/Properties/${filter.propertyId}/Deals/${filter.dealId}/Spaces`;
      return authorizedApiAxios().get<DealSpace[]>(url, {
        signal,
        headers: {
          [AccountIdentifierHeaderName]: filter?.accountIdentifier,
        },
      });
    },
    {
      select: (response) => response!.data,
    }
  );
};

export const useGetDealContacts = (filter: DealContactsFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["dealContacts", filter],
    ({ signal }) => {
      const url = `/AssetService/v1/Properties/${filter.propertyId}/Deals/${filter.dealId}/Contacts`;
      return authorizedApiAxios().get<DealContact[]>(url, {
        signal,
        headers: {
          [AccountIdentifierHeaderName]: filter?.accountIdentifier,
        },
      });
    },
    {
      select: (response) => response!.data,
    }
  );
};

export const useGetDealComments = (filter: DealCommentsFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["dealComments", filter],
    ({ signal }) => {
      const url = `/AssetService/v1/Properties/${filter.propertyId}/Deals/${filter.dealId}/Comments`;
      return authorizedApiAxios().get<DealComment[]>(url, {
        signal,
        headers: {
          [AccountIdentifierHeaderName]: filter?.accountIdentifier,
        },
      });
    },
    {
      select: (response) => response!.data,
    }
  );
};
