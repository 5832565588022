import axios, { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import {
  AccountIdentifierHeaderName,
  useAuthorizedApiAxios,
} from "../../../../shared/api/authorizedAxios";
import { PortfolioARData, PortfolioARFilter } from "../models/portfolioARModel";

export const useGetPortfolioArData = (filter: PortfolioARFilter) => {
  const authorizedApiAxios = useAuthorizedApiAxios();
  const uniqueId =
    filter.portfolioId +
    filter.accountIdentifier +
    filter.monthYearFilter.month +
    filter.monthYearFilter.year;
  return useQuery<PortfolioARData | undefined>(
    ["portfolioAr", uniqueId],
    () => getPortfolioArData(filter, authorizedApiAxios),
    {
      select: (response) => {
        return response;
      },
    }
  );
};

const getPortfolioArData = async (
  filter: PortfolioARFilter,
  authorizedApiAxios: () => AxiosInstance
) => {
  const source = axios.CancelToken.source();

  const queryParamsObj = {
    portfolioId: filter.portfolioId,
    year: filter.monthYearFilter.year,
    month: filter.monthYearFilter.month,
  };
  const queryParams = queryString.stringify(queryParamsObj);
  const qry = queryParams;
  const url = `/AssetService/v1/AR/Portfolio?${qry}`;

  const requiredFilters = ["portfolioId", "month", "year"];
  const reqfilters = queryString.parse(queryParams);
  const containsRequiredFilters = requiredFilters.every((s) => {
    if (s in reqfilters) {
      return (
        reqfilters[s] != null && (reqfilters[s] + "").toString().length > 0
      );
    } else return false;
  });
  if (filter.portfolioId === null || !containsRequiredFilters) return;
  let promise = authorizedApiAxios().get<PortfolioARData>(url, {
    cancelToken: source.token,
    headers: {
      [AccountIdentifierHeaderName]: filter?.accountIdentifier,
    },
  });

  (promise as any).cancel = () => source.cancel();
  return await promise.then((response) => response.data);
};
