import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  ColDef,
  FilterChangedEvent,
  GridApi,
  GridReadyEvent,
  RowDataChangedEvent,
} from "ag-grid-community";

import { IWidgetConfigRow } from "../model/widgetConfigModel";
import WidgetConfigCustomHeader from "./WidgetConfigCustomHeader";
import WidgetConfigAccessRenderer from "./WidgetConfigAccessRenderer";
import { WidgetConfigContext } from "./WidgetConfigContext";
import { capitalizeFirstLetter } from "../util/capitalizeFirstLetter";
import { stringSortCaseInsensitive } from "../../properties/util/gridUtils";

type Props = {
  rowData: IWidgetConfigRow[];
  updateRenderedRows: (renderedRows: number[]) => void;
};

const WidgetConfigGrid: React.FC<Props> = (props) => {
  const { rowData } = props;
  const { allWidgetsChecked, updateMultipleWidgetsConfig } =
    React.useContext(WidgetConfigContext);

  const [gridApi, setGridApi] = useState<GridApi>();

  const columnDefs = React.useMemo(() => {
    const defs: ColDef[] = [
      {
        headerName: "Widget Name",
        field: "widgetName",
        comparator: stringSortCaseInsensitive,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Widget Category",
        field: "widgetCategory",
        comparator: stringSortCaseInsensitive,
        filter: "agTextColumnFilter",
        valueFormatter: (params) => {
          const widgetCategoryName = params.data.widgetCategory;

          if (widgetCategoryName === undefined) return "-";

          return capitalizeFirstLetter(widgetCategoryName);
        },
      },
      {
        field: "isAvailable",
        headerComponent: "customAccessHeader",
        cellRenderer: "widgetConfigAccessRenderer",
        sortable: false,
        filter: false,
      },
    ];
    return defs;
  }, []);

  React.useEffect(() => {
    if (!allWidgetsChecked.touched) return;

    // Whenever the user clicks on check/uncheck all (on the "is Available" column header)
    const newWidgetsConfig: IWidgetConfigRow[] = [];
    gridApi?.forEachNodeAfterFilter((node) => {
      newWidgetsConfig.push({
        id: node.data.id,
        widgetName: node.data.widgetName,
        widgetCategory: node.data.widgetCategory,
        isAvailable: allWidgetsChecked.checked,
      });
    });

    updateMultipleWidgetsConfig(newWidgetsConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allWidgetsChecked.checked, allWidgetsChecked.touched]);

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);

    // Updating the list of rows that have been rendered onto the screen
    const newRenderedRows: number[] = [];
    event.api.forEachNodeAfterFilter((node) => {
      newRenderedRows.push(node.data.id);
    });
    props.updateRenderedRows(newRenderedRows);
  };

  const onFilterChanged = (event: FilterChangedEvent) => {
    // Updating the list of rows that have been rendered onto the screen
    const newRenderedRows: number[] = [];
    event.api.forEachNodeAfterFilter((node) => {
      newRenderedRows.push(node.data.id);
    });
    props.updateRenderedRows(newRenderedRows);
  };

  const rowDataChangedHandler = (event: RowDataChangedEvent) => {
    event.columnApi.resetColumnState();
    event.api.setFilterModel(null);
  };

  return (
    <div id="widgetconfig-main-content" className="grid-container">
      <div
        id="widgetconfigGrid"
        className="ag-theme-alpine ag-theme-alpine-container-override"
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          suppressMenuHide={true}
          components={{
            customAccessHeader: WidgetConfigCustomHeader,
            widgetConfigAccessRenderer: WidgetConfigAccessRenderer,
          }}
          defaultColDef={{
            editable: false,
            sortable: true,
            filter: true,
            flex: 1,
            minWidth: 100,
            resizable: true,
            filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
          }}
          onGridReady={onGridReady}
          onFilterChanged={onFilterChanged}
          onRowDataChanged={rowDataChangedHandler}
        />
      </div>
    </div>
  );
};

export default WidgetConfigGrid;
