import axios, { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import {
  AccountIdentifierHeaderName,
  useAuthorizedApiAxios,
} from "../../../../shared/api/authorizedAxios";
import { IPortfolioLeasingActivityFilter } from "../model/portfolioLeasingActivityFilter";
import { IPortfolioLeasingActivity } from "../model/portfolioLeasingActivity";

export const useGetPortfolioLeasingActivity = (
  filter: IPortfolioLeasingActivityFilter
) => {
  const authorizedApiAxios = useAuthorizedApiAxios();
  const uniqueId =
    filter.portfolioId +
    filter.month +
    filter.year;
  return useQuery<IPortfolioLeasingActivity | undefined>(
    ["leasingActivity" + uniqueId, filter],
    () => getLeasingActivity(filter, authorizedApiAxios)
  );
};

const getLeasingActivity = async (
  filter: IPortfolioLeasingActivityFilter,
  authorizedApiAxios: () => AxiosInstance
) => {
  const source = axios.CancelToken.source();

  const queryParamsObj = {
    portfolioId: filter.portfolioId,
    year: filter.year,
    month: filter.month,
  };

  const queryParams = queryString.stringify(queryParamsObj);
  const qry = queryParams;
  const url = `/AssetService/v1/LeasingActivity?${qry}`;

  const requiredFilters = [
    "portfolioId",
    "year",
    "month",
  ];
  const reqfilters = queryString.parse(queryParams);
  const containsRequiredFilters = requiredFilters.every((s) => {
    if (s in reqfilters) {
      return (
        reqfilters[s] != null && (reqfilters[s] + "").toString().length > 0
      );
    } else return false;
  });
  if (filter.portfolioId === null || !containsRequiredFilters) return;

  let promise = authorizedApiAxios().get<IPortfolioLeasingActivity>(url, {
    cancelToken: source.token,
    headers: {
      [AccountIdentifierHeaderName]: filter?.accountIdentifier,
    },
  });

  (promise as any).cancel = () => source.cancel();
  return await promise.then((response) => response.data);
};
