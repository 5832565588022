import React, { useState } from "react";
import { ContextProps } from "./contextModels";
import { TRentRollListFilter } from "../model/rentRollListFilter";
import { usePropertyInfo } from "../../util/propertyInfoContext";
import { addYears } from "date-fns";
import { IUAAlertMessage } from "../../../usermanagement/util/userContextModels";

/**
 * Context for keeping track of the Rent roll state
 */
const RentRollContext = React.createContext<ContextProps>({
  // PROPERTY DETAILS
  propertyDetailsDate: new Date(),
  updatePropertyDetailsDate: () => {},

  // RENT ROLL
  rentRollFilter: {
    propertyId: "",
    accountIdentifier: "",
    monthYearFilter: {},
  },
  updateRentRollFilter: () => {},

  // LEASING ACTIVITY
  // Leasing activity - state
  propertyLeasingActivityDate: new Date(new Date().getFullYear(), 0, 1),
  updatePropertyLeasingActivityDate: () => {},

  // Stacking Plan
  // Stacking Plan - state 
  propertyStackingPlanDate: new Date(),
  updatePropertyStackingPlanDate: () => {},

  // Snapshot
  // Snapshot - state
  propertySnapshotDate: new Date(),
  updatePropertySnapshotDate: () => {},

  // AR
  arDate: new Date(),
  updateArDate: () => {},

  // Loans
  loanDate: new Date(),
  updateLoanDate: () => {},

  // Financials - Local State
  financialsAccountingType: "",
  financialsBusinessUnitsDDValue: "",
  financialsGLTreeValue: "",
  financialsStartDate: new Date(),
  financialsEndDate: new Date(),
  financialsGroupingMode: 0,
  financialsBudgetView: false,
  updateFinancialsAccountingType: () => {},
  updateFinancialsBusinessUnitsDDValue: () => {},
  updateFinancialsGLTreeValue: () => {},
  updateFinancialsStartDate: () => {},
  updateFinancialsEndDate: () => {},
  updateFinancialsGroupingMode: () => {},
  updateFinancialsBudgetView: () => {},

  // Rent roll - docs - success/error messages
  alertDetails: {
    severityType: "success",
    message: "",
  },
  updateAlertDetails: () => {},
});

interface RentRollProviderProps {
  children: React.ReactNode;
}

export const RentRollProvider: React.FunctionComponent<RentRollProviderProps> = (props) => {
  // PROPERTY DETAILS

  // Propert details local state
  const [propertyDetailsDate, setpropertyDetailsDate] = useState(new Date());

  // RENT ROLL
  // Rent roll filter
  const propertyInfo = usePropertyInfo();
  const [rentRollFilter, setRentRollFilter] = useState<TRentRollListFilter>({
    propertyId: propertyInfo.id,
    accountIdentifier: propertyInfo.accountIdentifier,
    monthYearFilter: {
      filterMonth: new Date().getMonth() + 1,
      filterYear: new Date().getFullYear(),
    },
  });

  // LEASING ACTIVITY
  const [propertyLeasingActivityDate, setPropertyLeasingActivityDate] = useState<Date | undefined>();

  // Stacking Plan
  const [propertyStackingPlanDate, setPropertyStackingPlanDate] = useState<Date | undefined>();

  // Snapshot
  const [propertySnapshotDate, setPropertySnapshotDate] = useState(new Date());

  // AR
  const [arDate, setArDate] = useState(new Date());

  // Loans
  const [loanDate, setLoanDate] = useState(new Date());

  // Financials - Local State
  const [financialsAccountingType, setFinancialsAccountingType] =
    useState<string>("");
  const [financialsBusinessUnitsDDValue, setFinancialsBusinessUnitsDDValue] =
    useState<string>("");
  const [financialsGLTreeValue, setFinancialsGLTreeValue] =
    useState<string>("");
  const [financialsStartDate, setFinancialsStartDate] = useState<Date>(
    addYears(new Date(), -1)
  );
  const [financialsEndDate, setFinancialsEndDate] = useState<Date>(new Date());
  const [financialsGroupingMode, setFinancialsGroupingMode] =
    useState<number>(0);
  const [financialsBudgetView, setFinancialsBudgetView] =
    useState<boolean>(false);

  const [alertDetails, setAlertDetails] = useState<IUAAlertMessage>({
    severityType: "success",
    message: "",
  });

  // PROPERTY DETAILS

  // Property Details local state

  const updatePropertyDetailsDate = (date: Date) => {
    setpropertyDetailsDate(date);
  };

  // RENT ROLL
  // Rent roll - state

  const updateRentRollFilter = (filter: TRentRollListFilter) => {
    setRentRollFilter(filter);
  };

  // LEASING ACTIVITY
  const updatePropertyLeasingActivityDate = (date: Date) => {
    setPropertyLeasingActivityDate(date);
  };

  // Stacking Plan
  const updatePropertyStackingPlanDate = (date: Date) => {
    setPropertyStackingPlanDate(date);
  };

  // Snapshot
  const updatePropertySnapshotDate = (date: Date) => {
    setPropertySnapshotDate(date);
  };

  // AR
  const updateArDate = (date: Date) => {
    setArDate(date);
  };

  // Loans
  const updateLoanDate = (date: Date) => {
    setLoanDate(date);
  };

  // Financials - Local State

  const updateFinancialsAccountingType = (newValue: string) => {
    setFinancialsAccountingType(newValue);
  };

  const updateFinancialsBusinessUnitsDDValue = (newValue: string) => {
    setFinancialsBusinessUnitsDDValue(newValue);
  };

  const updateFinancialsGLTreeValue = (newValue: string) => {
    setFinancialsGLTreeValue(newValue);
  };

  const updateFinancialsStartDate = (date: Date) => {
    setFinancialsStartDate(date);
  };

  const updateFinancialsEndDate = (date: Date) => {
    setFinancialsEndDate(date);
  };

  const updateFinancialsGroupingMode = (mode: number) => {
    setFinancialsGroupingMode(mode);
  };

  const updateFinancialsBudgetView = (budgetView: boolean) => {
    setFinancialsBudgetView(budgetView);
  };

  const updateAlertDetails = (newAlertDetails: IUAAlertMessage) => {
    setAlertDetails({
      ...newAlertDetails,
    });
  };

  return (
    <RentRollContext.Provider
      value={{
        // PROPERTY DETAILS
        propertyDetailsDate,
        updatePropertyDetailsDate,

        // RENT ROLL
        rentRollFilter,
        updateRentRollFilter,

        // LEASING ACTIVITY
        propertyLeasingActivityDate,
        updatePropertyLeasingActivityDate,

        // Stacking Plan
        propertyStackingPlanDate,
        updatePropertyStackingPlanDate,

        // Snapshot
        propertySnapshotDate,
        updatePropertySnapshotDate,

        // AR
        arDate,
        updateArDate,

        // Loans
        loanDate,
        updateLoanDate,

        // Financials - Local State
        financialsAccountingType,
        financialsBusinessUnitsDDValue,
        financialsGLTreeValue,
        financialsStartDate,
        financialsEndDate,
        financialsGroupingMode,
        financialsBudgetView,
        updateFinancialsAccountingType,
        updateFinancialsBusinessUnitsDDValue,
        updateFinancialsGLTreeValue,
        updateFinancialsStartDate,
        updateFinancialsEndDate,
        updateFinancialsGroupingMode,
        updateFinancialsBudgetView,

        // Rent roll - docs - success/error messages
        alertDetails,
        updateAlertDetails,
      }}
    >
      {props.children}
    </RentRollContext.Provider>
  );
};

export default RentRollContext;
