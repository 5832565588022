import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { UilExport } from "@iconscout/react-unicons";
import { UilCalender } from "@iconscout/react-unicons";
import DatePicker from "react-datepicker";
import { GridApi, ColumnApi, ExcelExportParams } from "ag-grid-community";

import { useGetAccounts } from "../../../properties/property/api/accountService";
import { TAccount } from "../../../properties/property/model/account";
import { TPortfolioListFilter } from "../model/portfolioListFilter";
import { usePortfolioInfo } from "../../util/portfolioInfoContext";
import PortfolioContext from "../../util/portfolioContext";
import {
  GridConfigIds,
  PageConfigIds,
} from "../../../../constants/GridPageConfigurationIds";
import { useQueryClient } from "@tanstack/react-query";
import { useGetGridConfig } from "../../../../shared/api/pageConfigService";
import SaveGridStateOptions, {
  GRID_CONFIG_DEFAULT_VIEW,
} from "../../../../shared/view/SaveGridStateOptions";
import { hasSpecificPermission } from "../../util/accountPermissions";
import { EAccountPermission } from "../../../usermanagement/model/editUserModel";

interface Props {
  gridApi?: GridApi;
  gridColumnApi?: ColumnApi;
  accountId: string;
  setFilter: (
    accountIdentifier: string,
    startYear: number,
    startMonth: number,
    endYear: number,
    endMonth: number
  ) => void;
  handlePopupOpen: () => void;
}

const PortfolioListSearch: React.FC<Props> = (props) => {
  const { gridApi, gridColumnApi } = props;

  const portfolioInfo = usePortfolioInfo();

  const {
    accountPermissions,
    portfolioAccountDDValue,
    updatePortfolioAccountDDValue,
    portfoliosStartDate,
    updatePortfoliosStartDate,
    portfoliosEndDate,
    updatePortfoliosEndDate,
  } = useContext(PortfolioContext);

  // Retrieving account options
  const accountOptionsQuery = useGetAccounts();
  const { data: accountOptions, isSuccess } = accountOptionsQuery;

  const hasPortfolioAccess = React.useMemo(
    () =>
      hasSpecificPermission(
        accountPermissions,
        portfolioInfo.accountIdentifier,
        EAccountPermission.CONFIGURE_PORTFOLIOS
      ),
    [accountPermissions, portfolioInfo.accountIdentifier]
  );

  const [portfolioFilter, setPortfolioFilter] = useState<TPortfolioListFilter>({
    accountIdentifier: portfolioInfo.accountIdentifier,
    startYear: portfoliosStartDate.getFullYear(),
    startMonth: portfoliosStartDate.getMonth() + 1,
    endYear: portfoliosEndDate.getFullYear(),
    endMonth: portfoliosEndDate.getMonth() + 1,
  });

  /**
   * Executed whenever accountOptions change. Used to set the first option
   */
  useEffect(() => {
    if (!isSuccess || accountOptions === undefined || accountOptions.length < 1)
      return;

    const selectedAccount =
      portfolioAccountDDValue.trim().length === 0
        ? accountOptions[0].identifier
        : portfolioAccountDDValue;

    updatePortfolioAccountDDValue(selectedAccount);
    // props.setFilter(selectedAccount, "");
    props.setFilter(
      selectedAccount,
      portfolioFilter.startMonth,
      portfolioFilter.startYear,
      portfolioFilter.endMonth,
      portfolioFilter.endYear
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountOptions, isSuccess]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;

    // setPortAccountDDValue(newValue);
    updatePortfolioAccountDDValue(newValue);
    setPortfolioFilter({
      ...portfolioFilter,
      accountIdentifier: newValue,
    });
    // props.setFilter(newValue, "");
    props.setFilter(
      newValue,
      portfolioFilter.startMonth,
      portfolioFilter.startYear,
      portfolioFilter.endMonth,
      portfolioFilter.endYear
    );
  };

  /**
   * exports the data that is rendered in the AG grid.
   */
  const onExport = () => {
    const params: ExcelExportParams = {
      sheetName: "Portfolios - data",
      fileName: "Portfolios",
      exportMode: undefined,
      suppressTextAsCDATA: false,
      rowHeight: undefined,
      headerRowHeight: undefined,
      columnKeys: getColumnKeys(),
    };
    props.gridApi?.exportDataAsExcel(params);
  };

  // Export params
  const getColumnKeys = () => {
    return [
      "name",
      "propertyName",
      "rentableArea",
      "units",
      "occupancy",
      "monthlyRentPerArea",
      "revenue",
      "expenses",
      "noi",
      "debtSVC",
      "cashflow",
    ];
  };

  // Grid Config state and methods

  const [gridView, setGridView] = useState(GRID_CONFIG_DEFAULT_VIEW);

  const gridId = GridConfigIds.PORTFOLIOS_LIST_GRID;
  const pageId = PageConfigIds.PORTFOLIOS_LIST_GRID;

  const accountId = props.accountId;

  const queryClient = useQueryClient();
  const gridConfigsQuery = useGetGridConfig({
    pageId: pageId,
    gridIdentifier: gridId,
    accountId: accountId,
  });

  /**
   * Sets the context that is retrieved from the backend whenever the gridView is changed.
   * gridView is either changed when data first loads (only if a default value exists) or when the user uses the dropdown
   */
  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1 ||
      gridApi === undefined ||
      gridColumnApi === undefined
    )
      return;

    if (gridView === GRID_CONFIG_DEFAULT_VIEW) {
      gridApi.setFilterModel({});
      gridColumnApi.resetColumnState();
      return;
    }

    const selectedGridConfig = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.id === Number(gridView)
    );

    if (selectedGridConfig === undefined) return;

    gridApi.setFilterModel(JSON.parse(selectedGridConfig.columnFilterState));
    gridColumnApi.applyColumnState({
      state: JSON.parse(selectedGridConfig.columnState),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridView, gridApi, gridColumnApi]);

  const saveAsView = React.useRef("");

  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1
    ) {
      if (gridView !== GRID_CONFIG_DEFAULT_VIEW)
        setGridView(GRID_CONFIG_DEFAULT_VIEW);
      return;
    }

    const isSaveAsRender = saveAsView.current.trim().length > 0;

    if (isSaveAsRender) {
      const savedAsView = gridConfigsQuery.data.gridConfigurations.find(
        (gridConfig) =>
          gridConfig.name.toLowerCase().trim() ===
          saveAsView.current.toLowerCase().trim()
      );
      if (savedAsView) setGridView(savedAsView.id.toString());
      saveAsView.current = "";
      return;
    }

    const defaultView = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.isDefault
    );
    if (
      defaultView &&
      gridView !== defaultView.id.toString() &&
      gridView === GRID_CONFIG_DEFAULT_VIEW
    )
      setGridView(defaultView.id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridConfigsQuery.isSuccess, gridConfigsQuery.data]);

  const SelectIcon = (props: Props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  return (
    <React.Fragment>
      <Grid
        container
        className={"property-action-bar property-action-bar-list"}
      >
        <Grid item>
          <Grid
            container
            sx={{ width: "auto" }}
          >
            <Grid item>
              <Typography
                variant="body3"
                component="label"
                className={"input-label"}
              >{`Select date range`}</Typography>
              <Box className={"datepicker-container daterange-container"}>
                <DatePicker
                  selected={portfoliosStartDate}
                  onChange={(date: Date) => {
                    updatePortfoliosStartDate(date);
                    setPortfolioFilter({
                      ...portfolioFilter,
                      startYear: date.getFullYear(),
                      startMonth: date.getMonth() + 1,
                    });
                    props.setFilter(
                      portfolioAccountDDValue,
                      date.getMonth() + 1,
                      date.getFullYear(),
                      portfolioFilter.endMonth,
                      portfolioFilter.endYear
                    );
                  }}
                  dateFormat="MMM yyyy"
                  showMonthYearPicker
                  maxDate={portfoliosEndDate}
                  popperModifiers={[
                    {
                      name: "preventOverflow",
                      options: {
                        altAxis: true,
                      },
                    },
                    {
                      name: "offset",
                      options: {
                        offset: [-13, 0],
                      },
                    },
                  ]}
                  className={"right-text"}
                />
                <Box className={"icon-datepicker icon-datepicker-dash"}>
                  &nbsp;-&nbsp;
                </Box>
                <DatePicker
                  selected={portfoliosEndDate}
                  onChange={(date: Date) => {
                    updatePortfoliosEndDate(date);
                    setPortfolioFilter({
                      ...portfolioFilter,
                      endYear: date.getFullYear(),
                      endMonth: date.getMonth() + 1,
                    });
                    props.setFilter(
                      portfolioAccountDDValue,
                      portfolioFilter.startMonth,
                      portfolioFilter.startYear,
                      date.getMonth() + 1,
                      date.getFullYear()
                    );
                  }}
                  dateFormat="MMM yyyy"
                  showMonthYearPicker
                  minDate={portfoliosStartDate}
                  popperModifiers={[
                    {
                      name: "preventOverflow",
                      options: {
                        altAxis: true,
                      },
                    },
                    {
                      name: "offset",
                      options: {
                        offset: [-13, 0],
                      },
                    },
                  ]}
                />
                <Box className={"icon-datepicker"}>
                  <UilCalender />
                </Box>
              </Box>
            </Grid>
            <Grid item>
              {!!accountOptionsQuery &&
                isSuccess &&
                accountOptions !== undefined &&
                accountOptions.length > 1 && (
                  <FormControl variant="outlined" size="small">
                    <Select
                      value={portfolioAccountDDValue}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      IconComponent={SelectIcon}
                    >
                      {accountOptions.map((dropdownOption: TAccount) => (
                        <MenuItem
                          value={dropdownOption.identifier}
                          key={dropdownOption.identifier}
                        >
                          {dropdownOption.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            sx={{ width: "auto" }}
          >
            <Grid item>
              {hasPortfolioAccess && (
                <Button
                  variant="contained"
                  size="small"
                  className={"btn-primary"}
                  sx={{ marginTop: "21px" }}
                  onClick={props.handlePopupOpen}
                >
                  {"+ Create Portfolio"}
                </Button>
              )}
            </Grid>
            <Grid item>
              <SaveGridStateOptions
                isSuccess={gridConfigsQuery.isSuccess}
                gridView={gridView}
                handleGridView={(newVal: string) => {
                  setGridView(newVal);
                }}
                gridConfigData={gridConfigsQuery.data}
                gridApi={gridApi}
                gridColumnApi={gridColumnApi}
                gridId={gridId}
                pageId={pageId}
                accountId={accountId}
                invalidateGridQuery={() =>
                  queryClient.invalidateQueries([
                    "getGridConfig",
                    pageId + gridId + accountId,
                  ])
                }
                updateSaveAsViewName={(name: string) => {
                  saveAsView.current = name;
                }}
                updateGridView={() => setGridView(GRID_CONFIG_DEFAULT_VIEW)}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                className={"btn-primary"}
                sx={{ marginTop: "21px" }}
                onClick={onExport}
              >
                <UilExport /> Export
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PortfolioListSearch;
