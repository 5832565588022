import { format } from "date-fns";

type DateTimeType = {
  year: "numeric" | "2-digit" | undefined;
  month: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
};

export enum EDateFormat {
  MONTH_YEAR = "Month Year",
  MM_DD_YYYY = "MM DD YYYY",
}

export const formatDatePO = (date: Date, dateFormat: EDateFormat) => {
  const options: DateTimeType = { year: "numeric", month: "long" };
  const newDate = new Date(date);

  if (dateFormat === EDateFormat.MONTH_YEAR)
    return newDate.toLocaleDateString("en-US", options);
  else return newDate.toLocaleDateString("en-US");
};

export const formatDateFNS = (date: string, dateFormat: string) => {
  const newDate = new Date(date);
  return format(newDate, dateFormat);
};
