import { IUpdateUser, TEditUserModel } from "../model/editUserModel";

/**
 * Removes a couple of fields to match the expected data on the PUT: user request
 * @param user
 */
export const parseEditUser = (user: TEditUserModel) => {
  const newUser: IUpdateUser = {
    // email: user.email,
    // phoneNumber: user.phoneNumber,
    // status: user.status,
    roles: user.roles,
  };

  return newUser;
};
