/**
 * checks if the given item has a valid zip code
 * @param value the zip code
 */
export const isValidZipCode = (value?: string) => {
  if (value?.trim().length === 0) return true;
  return value ? /^\d{5}(?:[-\s]\d{4})?$/.test(value) : false;
};

/**
 * Checks for a valid phone number
 * @param value
 */
export const isValidPhoneNumber = (value?: string) => {
  if (value === "(___) ___-____" || !value ) return true;
  return value ? /^\(\d{3}\) \d{3}-\d{4}/.test(value) : false;
};

/**
 * Checks for a valid email address
 * @param value
 */
export const isValidEmail = (value?: string) => {
  if (value?.trim().length === 0) return true;
  return value ? /\S+@\S+\.\S+/.test(value) : false;
};

/**
 * Checks for a valid website
 * @param value
 */
export const isValidWebsite = (value?: string) => {
  if (value?.trim().length === 0) return true;
  var websiteRegex = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/i;
  return value ? websiteRegex.test(value) : false;
};

/**
 * Formats a number from (999) 999-9999 to 9999999999
 * @param phoneNumber 
 */
export const formatPhoneNumber = (phoneNumber?: string) => {
  return phoneNumber !== undefined ? phoneNumber.split(/\s+/).join('').replace(/\(*\)*-*/g, '') : phoneNumber;
};
