export enum PageConfigIds {
  PROPERTIES = 1,
  PROPERTIES_RENT_ROLL_GRIDS = 2,
  PROPERTIES_LEASING_ACTIVITY_GRIDS = 3,
  PROPERTY_AR_GRID = 4,
  PROPERTY_LOANS_GRID = 5,
  PROPERTY_FINANCIALS_GRID = 6,
  PROPERTY_BUDGET_GRID = 7,
  PORTFOLIO_FINANCIALS_GRID = 8,
  PORTFOLIO_BUDGET_GRID = 9,
  PORTFOLIO_RENT_ROLL_GRID = 10,
  PORTFOLIO_LEASING_ACTIVITY_GRID = 11,
  PORTFOLIO_AR_GRID = 12,
  PORTFOLIO_LOANS_GRID = 13,
  PORTFOLIOS_LIST_GRID = 14,
  DOCUMENTS = 15
}

export enum GridConfigIds {
  PROPERTY_LIST_GRID = "PropertyListGrid",
  PROPERTY_RR_SUITES = "PropertyRentRollSuites",
  PROPERTY_RR_LEASES = "PropertyRentRollLeases",
  PROPERTY_RR_OTHER_LEASES = "PropertyRentRollOtherLeases",
  PROPERTY_LA_NEW = "PropertyLeasingActivityNew",
  PROPERTY_LA_RENEWAL = "PropertyLeasingActivityRenewal",
  PROPERTY_LA_VACATED = "PropertyLeasingActivityVacated",
  PROPERTY_AR_GRID = "PropertyARGrid",
  PROPERTY_LOANS_GRID = "PropertyLoansGrid",
  PROPERTY_FINANCIALS_GRID = "PropertyFinancialsGrid",
  PROPERTY_BUDGET_GRID = "PropertyBudgetGrid",
  PORTFOLIO_RR_LEASES_GRID = "PortfolioRentRollLeases",
  PORTFOLIO_RR_OTHER_LEASES_GRID = "PortfolioRentRollOtherLeases",
  PORTFOLIO_LA_OVERVIEW_GRID = "PortfolioLeasingActivityOverview",
  PORTFOLIO_LA_EXPIRATION_GRID = "PortfolioLeasingActivitySchedule",
  PORTFOLIO_FINANCIALS_GRID = "PortfolioFinancialsGrid",
  PORTFOLIO_BUDGET_GRID = "PortfolioBudgetGrid",
  PORTFOLIO_AR_GRID = "PortfolioARGrid",
  PORTFOLIO_LOANS_GRID = "PortfolioLoansGrid",
  PORTFOLIOS_LIST_GRID = "PortfolioListGrid",
  DOCUMENTS_GRID = "DocumentsGrid"
}
