import React, { useState, useEffect } from "react";

import { PortfolioContextProps } from "./portfolioContextModels";
import { IAccountPermission } from "../../layout/views/AccountRoles";
import subYears from "date-fns/subYears";
import { useOidcUser } from "@axa-fr/react-oidc";

/**
 * Context for keeping track of the Portfoli state
 */
const PortfolioContext = React.createContext<PortfolioContextProps>({
  // Admin
  userId: "",
  updateUserId: () => {},

  adminAccounts: [],
  accountPermissions: new Map(),
  updateAdminAccounts: () => {},
  isSuperAdmin: false,
  updateIsSuperAdmin: () => {},
  hasAdminPageAccess: false,
  hasCurrentAdminAccess: false,

  // PORTFOLIO

  //Portolio - Date filter
  portfoliosStartDate: subYears(new Date(), 1),
  portfoliosEndDate: new Date(),
  updatePortfoliosStartDate: () => {},
  updatePortfoliosEndDate: () => {},

  // Portfolio - Local State
  portfolioAccountDDValue: "",
  updatePortfolioAccountDDValue: () => {},

  // PROPERTIES

  //Property - Date filter
  propertiesStartDate: subYears(new Date(), 1),
  propertiesEndDate: new Date(),
  updatePropertiesStartDate: () => {},
  updatePropertiesEndDate: () => {},
});

interface PortfolioProviderProps {
  children: React.ReactNode;
}

export const PortfolioProvider: React.FC<PortfolioProviderProps> = (props) => {
  // Admin

  const{ oidcUser } = useOidcUser(); 
  const [userId, setUserId] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const updateUserId = (newUserId: string) => {
    setUserId(newUserId);
  };

  const updateIsSuperAdmin = (isSuperAdmin: boolean) => {
    setIsSuperAdmin(isSuperAdmin);
  };

  useEffect(() => {
    if (oidcUser === null) return;

    setUserId(oidcUser.sub);
    if (oidcUser.role === "admin") setIsSuperAdmin(true);
  }, [oidcUser]);

  const [hasAdminPageAccess, setHasAdminPageAccess] = useState(false);
  const [hasCurrentAdminAccess, setHasCurrentAdminAccess] = useState(false);

  const [adminAccounts, setAdminAccounts] = useState<string[]>([]);
  const [accountPermissions, setAccountPermissions] = useState(
    new Map<string, IAccountPermission>()
  );

  const updateAdminAccounts = (
    adminAccounts: string[],
    accountsMap: Map<string, IAccountPermission>
  ) => {
    setAdminAccounts(adminAccounts);
    setAccountPermissions(new Map(accountsMap));
  };

  useEffect(() => {
    setHasAdminPageAccess(adminAccounts.length > 0);
    setHasCurrentAdminAccess(
      isHasCurrentAdminAccess(adminAccounts, portfolioAccountDDValue)
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAccounts]);

  // Portfolio - Local State
  const [portfolioAccountDDValue, setPortfolioAccountDDValue] =
    useState<string>("");

  useEffect(() => {
    setHasCurrentAdminAccess(
      isHasCurrentAdminAccess(adminAccounts, portfolioAccountDDValue)
    );
  }, [adminAccounts, portfolioAccountDDValue]);

  const isHasCurrentAdminAccess = (
    adminAccs: string[],
    portfolioAccDDVal: string
  ) => {
    return adminAccs.includes(portfolioAccDDVal);
  };

  const updatePortfolioAccountDDValue = (newValue: string) => {
    setPortfolioAccountDDValue(newValue);
  };

  // Portfolio - date filter
  const [portfoliosStartDate, setPortfoliosStartDate] = useState<Date>(
    subYears(new Date(), 1)
  );
  const [portfoliosEndDate, setPortfoliosEndDate] = useState<Date>(new Date());

  const updatePortfoliosStartDate = (date: Date) => {
    setPortfoliosStartDate(date);
  };

  const updatePortfoliosEndDate = (date: Date) => {
    setPortfoliosEndDate(date);
  };

  // PROPERTIES

  // Properties - date filter
  const [propertiesStartDate, setPropertiesStartDate] = useState<Date>(
    subYears(new Date(), 1)
  );
  const [propertiesEndDate, setPropertiesEndDate] = useState<Date>(new Date());

  const updatePropertiesStartDate = (date: Date) => {
    setPropertiesStartDate(date);
  };

  const updatePropertiesEndDate = (date: Date) => {
    setPropertiesEndDate(date);
  };

  return (
    <PortfolioContext.Provider
      value={{
        // Admin
        userId,
        updateUserId,

        adminAccounts,
        accountPermissions,
        updateAdminAccounts,
        isSuperAdmin,
        updateIsSuperAdmin,
        hasAdminPageAccess,
        hasCurrentAdminAccess,
        // updateHasAdminAccess,

        // PORTFOLIOS

        // Portfolio - Local State
        portfolioAccountDDValue,
        updatePortfolioAccountDDValue,

        portfoliosStartDate,
        portfoliosEndDate,
        updatePortfoliosStartDate,
        updatePortfoliosEndDate,

        // PROPERTIES

        // Portfolio - Local State
        propertiesStartDate,
        propertiesEndDate,
        updatePropertiesStartDate,
        updatePropertiesEndDate,
      }}
    >
      {props.children}
    </PortfolioContext.Provider>
  );
};

export default PortfolioContext;
