/**
 * Formats a number with added commas
 * @param value
 */
export const unformatNumber = (value?: string) => {
  if (value) return value.toString().replace(/,/g, "");
  else return value;
};

/**
 * Computes the difference in two dates in the form of years. Takes months into consideration.
 * @param originationDate
 * @param maturityDate
 */
export const computeTerms = (originationDate: Date, maturityDate: Date) => {
  const origination = new Date(originationDate);
  const maturity = new Date(maturityDate);
  const originationMonth = origination.getMonth();
  const originationYear = origination.getFullYear();

  const maturityMonth = maturity.getMonth();
  const maturityYear = maturity.getFullYear();
  const terms = maturityYear - originationYear;
  if (maturityMonth > originationMonth || maturityMonth === originationMonth)
    return terms;
  return terms - 1;
};

/**
 * Limits a number up to two decimal places.
 * @param commaRemovedNum number in a string format with no commas in it.
 * @param decimalPlaces number of decimal places to set
 * @returns returns a formatted string or undefined
 */
export const limitToDecimalPlaces = (
  commaRemovedNum?: string,
  decimalPlaces: number = 2
) => {
  let parsedNum = commaRemovedNum;
  if (commaRemovedNum !== undefined) {
    const indexOfPeriod = commaRemovedNum.indexOf(".");
    if (indexOfPeriod && indexOfPeriod > -1) {
      const numOfDecimals = commaRemovedNum.slice(indexOfPeriod + 1).length;
      if (numOfDecimals > decimalPlaces) {
        parsedNum = parseFloat(commaRemovedNum).toFixed(decimalPlaces);
      }
    }
  }

  return parsedNum;
};

export const isValidDate = (d: Date) => {
  return d instanceof Date && !isNaN(d.getTime());
};

export const areDatesEqual = (dateA: Date, dateB: Date) => {
  if (
    dateA.getFullYear() === dateB.getFullYear() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getDate() === dateB.getDate()
  )
    return true;
  else return false;
};

export const originationMaturityErrorText =
  "Maturity date must be later than Origination date.";

export const dateBehind = (value: Date | null, secondValue: Date | null) => {
  if (
    value === null ||
    value.toString().trim() === "" ||
    secondValue === null ||
    secondValue.toString().trim() === ""
  )
    return true;
  const originationDate = new Date(value);
  const maturityDate = new Date(secondValue);
  if (isValidDate(originationDate) && isValidDate(maturityDate)) {
    if (
      originationDate.valueOf() > maturityDate.valueOf() ||
      areDatesEqual(originationDate, maturityDate)
    )
      return originationMaturityErrorText;
    else return true;
  } else return true;
};
