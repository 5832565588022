export const numberErrorMessage = "Invalid number entry.";
export const dateErrorMessage = "Invalid date entry.";
export const textErrorMessage = "Invalid entry.";
export const requiredErrorMessage = "This field is mandatory.";
export const notesErrorMessage = "Maximum 500 characters";

export const maxNumberField = 9999999999999;
export const maxTextField = 250;
export const maxNotesField = 500;

export const maxCapitalPartners = 4;
