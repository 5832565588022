import { useGetInfo } from "../api/infoService";
import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Box,
  Typography,
  Link,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import UserOptions from "../../layout/views/userOptions";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Info: React.FunctionComponent = () => {
  const portfolioInfo = useGetInfo();
  const classes = useStyles();

  return (
    <Box className="mainSettingsContainer mainSettingsContainer-info">
      <Box className={"appHeader"} sx={{ backgroundColor: "neutralSecondary.main", }}>
        <Box>
          <Typography variant="h1" sx={{ color: "primary.dark" }}>
            Information
          </Typography>
        </Box>
        {/* User Options */}
        <UserOptions />
      </Box>
      <Box className="info-panel-container" >
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="info table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography variant="subtitle1" color="primary.dark">Major Version</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1" color="primary.dark">Minor Version</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1" color="primary.dark">Revision</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1" color="primary.dark">Built at</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1" color="primary.dark">Commit Hash</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <Typography variant="body1" color="primary.dark">
                    {portfolioInfo.data?.majorVersion}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" color="primary.dark">
                    {portfolioInfo.data?.minorVersion}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" color="primary.dark">
                    <Link
                      rel="noopener"
                      target="_blank"
                      href={
                        "https://dev.azure.com/goworkspace/Genesis/_build/results?buildId=" +
                        portfolioInfo.data?.revision
                      }
                      color="inherit"
                    >
                      {portfolioInfo.data?.revision}
                    </Link>
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" color="primary.dark">
                    {portfolioInfo.data?.builtAt}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" color="primary.dark">
                    <Link
                      rel="noopener"
                      target="_blank"
                      href={
                        "https://dev.azure.com/goworkspace/Genesis/_git/Genesis/commit/" +
                        portfolioInfo.data?.commitHash
                      }
                      color="inherit"
                    >
                      {portfolioInfo.data?.commitHash}
                    </Link>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Info;
