import React from "react";
import { Box, CircularProgress, Typography, Theme, Grid, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

import { useIsTokenValid } from "../api/createUserApi";
import CreateUserForm from "./CreateUserForm";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import logo from "./logo.png";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(10, 0),
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    container: {
      maxWidth: "30%",
      minWidth: 400,
      padding: 32,
      background: "white",
      border: "solid 1px var(--neutral-grey-150)",
      borderRadius: 6,
      boxShadow: "0 32px 48px 0 rgb(37 60 82 / 4%)"
    },
    logo: {
      marginBottom: theme.spacing(3),
      width: "70%"
    },
    subheading: {
      padding: "24px 0 12",
      fontSize: 15,
      fontWeight: 500,
      letterSpacing: "normal",
      lineHeight: 1.6,
      color: "var(--neutral-grey-600)",
      marginTop: theme.spacing(1),
    },
    support: {
      color: "var(--primary-blue)",
    },
    warning: {
      forntSize: 24,
      color: "var(--primary-blue)",
    },
    title: {
      fontSize: 24,
      fontWeight: 600,
      color: "var(--primary-dark-blue)",
      marginLeft: theme.spacing(0.5),
    },
    button: {
      marginTop: theme.spacing(4),
    },
  })
);

const CreateUser: React.FC = () => {
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const parsedQueryParams = queryString.parse(location.search);
  const userEmail = parsedQueryParams.userName?.toString();
  const token = parsedQueryParams.token?.toString();
  const isTokenValidQuery = useIsTokenValid(token || "", userEmail || "");

  const onSubmit = (() => {
    navigate("/login");
  });
  return (
    <React.Fragment>
      {isTokenValidQuery.isLoading && (
        <Box m="auto" textAlign="center" style={{ width: "100%" }}>
          <CircularProgress />
        </Box>
      )}
      {(isTokenValidQuery.isError || isTokenValidQuery.data === false) && (
        <Box style={{ width: "100%" }} m="auto" className={classes.root}>
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <img src={logo} id="loginLogo" alt="" loading="lazy" className={classes.logo} />
            </Grid>
            <Grid container>
              <Grid item className={classes.warning}>
                <WarningAmberRoundedIcon />
              </Grid>
              <Grid item className={classes.title}>
                <Typography className={classes.title}>Link Expired</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subheading}>
                The link you selected is invalid or has already been used. If you
                believe this has occurred in error, please get in touch with your
                System Administrator.
                {/* <span className={classes.support}>support@workspace.cc.</span> */}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.button}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={onSubmit}
                >
                  Back to Login
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      {isTokenValidQuery.isSuccess && isTokenValidQuery.data && (
        <CreateUserForm
          userEmail={userEmail || ""}
          token={
            parsedQueryParams.token?.toString() !== undefined
              ? parsedQueryParams.token.toString().replaceAll(" ", "+")
              : ""
          }
        />
      )}
    </React.Fragment>
  );
};

export default CreateUser;
