import React from "react";
import { Box, Button, CircularProgress, Dialog, IconButton, Typography } from "@mui/material";
import { DialogActions, DialogContent, DialogTitle } from "./downloaDocumentsModal";
import { styled } from '@mui/material/styles';
import OptionsTable from "./optionsTable";
import { usePropertyInfo } from "../../../util/propertyInfoContext";
import { useGetLeaseOptionList } from "../../api/leaseOptionsService";
import { UilMultiply } from "@iconscout/react-unicons";

const CustomizedDialog = styled(Dialog)`
  & .MuiDialog-paper {
    min-width: 70%;
  }
`;
const CustomizedDialogContent = styled(DialogContent)`
  margin: 0 -34px;
`;

interface OptionsDialogProps {
  leaseGenId: string;
  handlePopupClose: (shouldRefreshData: boolean) => void;
  showOptionsPopup: boolean;
}
const OptionsModal: React.FC<OptionsDialogProps> = (
  props: OptionsDialogProps
) => {
  const propertyInfo = usePropertyInfo();
  const leaseOptionList = useGetLeaseOptionList({
    LeaseGenId: props.leaseGenId,
    accountIdentifier: propertyInfo.accountIdentifier,
  });

  const handleClose = () => {
    props.handlePopupClose(false);
  };

  return (
    <div>
      <CustomizedDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.showOptionsPopup}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography component="span" variant="h2" color="primary.dark">
            Options
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="icon-dialog-close"
            size="large"
          >
            <UilMultiply />
          </IconButton>
        </DialogTitle>
        <CustomizedDialogContent>
          {leaseOptionList.isSuccess && leaseOptionList.data?.length > 0 ? (
            <OptionsTable options={leaseOptionList.data!} />
          ) : leaseOptionList.isSuccess &&
            leaseOptionList.data?.length === 0 ? (
            <Typography>No options available.</Typography>
          ) : leaseOptionList.isLoading ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {leaseOptionList.isError && (
                <Typography>
                  An error occured while retrieving the documents
                </Typography>
              )}
            </>
          )}
        </CustomizedDialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            className={"btn-tertiary"}
            onClick={handleClose}
          >
            Back to Rent Roll
          </Button>
        </DialogActions>
      </CustomizedDialog>
    </div>
  );
};

export default OptionsModal;
