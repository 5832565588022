import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import DatePicker from "react-datepicker";
import { UilCalender } from "@iconscout/react-unicons";
import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Button,
  Link
} from "@mui/material";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import { useGetDashboardData } from "../api/dashboardService";
import {
  DashboardGeneralDataFilter,
  InnerDashboardGeneralDataFilter,
} from "../models/dashboardGeneralDataFilter";
import DashboardBase from "./dashboardBase";
import { DashboardTypeEnum, WidgetTypeEnum } from "../models/dashboardEnums";
import AreaOccupancyWidget from "./widgets/areaOccupancyWidget";
import UnitOccupancyWidget from "./widgets/unitOccupancyWidget";
import VacantAreaWidget from "./widgets/vacantAreaWidget";
import VacantUnitWidget from "./widgets/vacantUnitWidget";
import RenewedLeasesAreaWidget from "./widgets/renewedLeasesAreaWidget";
import RenewedLeasesUnitWidget from "./widgets/renewedLeasesUnitWidget";
import VacancyDistributionAreaWidget from "./widgets/vacancyDistributionAreaWidget";
import VacancyDistributionUnitWidget from "./widgets/vacancyDistributionUnitWidget";
import VacatedLeasesAreaWidget from "./widgets/vacatedLeasesAreaWidget";
import VacatedLeasesUnitWidget from "./widgets/vacatedLeasesUnitWidget";
import NewLeaseAreaWidget from "./widgets/newLeaseAreaWidget";
import NewLeaseUnit from "./widgets/newLeaseUnitWidget";
import AverageDaysVacantArea from "./widgets/averageDaysVacantArea";
import AverageDaysVacantUnit from "./widgets/averageDaysVacantUnit";
import NetOperatingIncomeWidget from "./widgets/netOperatingIncomeWidget";
import OperatingExpensesWidget from "./widgets/operatingExpensesWidget";
import CapitalExpensesWidget from "./widgets/capitalExpensesWidget";
import TenantImprovementsWidget from "./widgets/tenantImprovementsWidget";
import NetCashFlowWidget from "./widgets/netCashFlowWidget";
import InPlaceContractRentAreaWidget from "./widgets/inPlaceContractRentAreaWidget";
import InPlaceContractRentUnitWidget from "./widgets/inPlaceContractRentUnitWidget";
import WeightAverageLeaseExpiry from "./widgets/weightAverageLeaseExpiry";
import WeightAverageLeaseExpiryInMonths from "./widgets/weightAverageLeaseExpiryInMonths";
import DebtServiceWidget from "./widgets/debtServiceWidget";
import LeaseCommissionsWidget from "./widgets/leaseCommissionsWidget";
import ValuationWidget from "./widgets/valuationWidget";
import RevenueWidget from "./widgets/revenueWidget";
import NetAbsorptionAreaWidget from "./widgets/netAbsorptionAreaWidget";
import NetAbsorptionUnitWidget from "./widgets/netAbsorptionUnitWidget";
import LeasedPercentageWidget from "./widgets/leasedPercentageWidget";
import CapitalizationRateWidget from "./widgets/capitalizationRateWidget";
import NetAbsorptionRateAreaWidget from "./widgets/netAbsorptionRateAreaWidget";
import NetAbsorptionRateYoYAreaWidget from "./widgets/netAbsorptionRateYoYAreaWidget";
import { useGetAccounts } from "../../../properties/property/api/accountService";
import { useGetPropertyList } from "../../property/api/propertyService";
import { getDenominationOptions } from "../utils/widgetUtils";
import { useGetLedgerBookList } from "../api/ledgerBookService";
import useStyles from "./dashboardStyles";
import { DateRangeEnum } from "../models/dateRangeEnum";
import { useGetPortfolioList } from "../../../portfolio/portfolios/api/portfolioService";
import UserOptions from "../../../layout/views/userOptions";
import LockedIcon from '@mui/icons-material/LockOutlined';
import UnLockedIcon from '@mui/icons-material/LockOpenOutlined';
import StarIcon from '@mui/icons-material/StarBorderOutlined';
import StarFilledIcon from '@mui/icons-material/Star';

interface UserSelectedDashboard {
  id: number,
  accountIdentifier: string,
  name: string,
  isDefault: boolean,
  isPublic: boolean,
  denomination: string,
  dateRange: number,
  properties?: string[];
  portfolios?: string[];
  startDate?: Date,
  endDate?: Date,
  bookId?: string;
}

const MainDashboard: React.FC = () => {
  const [filter, setFilter] = useState<DashboardGeneralDataFilter>();
  const [selectedDashboard, setSelectedDashboard] = useState<UserSelectedDashboard>({
    id: 0,
    accountIdentifier: "",
    name: "",
    isDefault: false,
    isPublic: false,
    denomination: "Gross",
    dateRange: DateRangeEnum.LastTwoYears,
  });

  const dashboardData = useGetDashboardData(
    selectedDashboard.id,
    selectedDashboard.accountIdentifier,
    filter
  );

  const [defaultBookId, setDefaultBookId] = useState<string>();
  const classes = useStyles();

  useEffect(() => {
    document.title = "Workspace | Dashboard";
  }, []);

  const getControl = useCallback(
    (widgetId: WidgetTypeEnum, groupIdentifier: string) => {
      let data = dashboardData.data?.widgetInfos.find(
        (d) => d.groupIdentifier === groupIdentifier
      );
      const highlightHeader =
        filter?.denomination?.toLowerCase() ===
        data?.widgetDenomination.toLowerCase();

      switch (widgetId) {
        case WidgetTypeEnum.AreaOccupancy:
          return (
            <AreaOccupancyWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.UnitOccupancy:
          return (
            <UnitOccupancyWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.VacantArea:
          return (
            <VacantAreaWidget data={data} highlightHeader={highlightHeader} />
          );
        case WidgetTypeEnum.VacantUnit:
          return (
            <VacantUnitWidget data={data} highlightHeader={highlightHeader} />
          );
        case WidgetTypeEnum.RenewedLeasesArea:
          return (
            <RenewedLeasesAreaWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.RenewedLeasesUnit:
          return (
            <RenewedLeasesUnitWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.VacancyDistributionArea:
          return (
            <VacancyDistributionAreaWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.VacancyDistributionUnit:
          return (
            <VacancyDistributionUnitWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.VacatedLeasesArea:
          return (
            <VacatedLeasesAreaWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.VacatedLeasesUnit:
          return (
            <VacatedLeasesUnitWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.NewLeaseArea:
          return (
            <NewLeaseAreaWidget data={data} highlightHeader={highlightHeader} />
          );
        case WidgetTypeEnum.NewLeaseUnit:
          return <NewLeaseUnit data={data} highlightHeader={highlightHeader} />;
        case WidgetTypeEnum.AverageTimeVacantArea:
          return (
            <AverageDaysVacantArea
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.AverageTimeVacantUnit:
          return (
            <AverageDaysVacantUnit
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.NetOperatingIncome:
          return (
            <NetOperatingIncomeWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.Revenue:
          return (
            <RevenueWidget data={data} highlightHeader={highlightHeader} />
          );
        case WidgetTypeEnum.OperatingExpenses:
          return (
            <OperatingExpensesWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.CapitalExpenses:
          return (
            <CapitalExpensesWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.TenantImprovements:
          return (
            <TenantImprovementsWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.NetCashFlow:
          return (
            <NetCashFlowWidget data={data} highlightHeader={highlightHeader} />
          );
        case WidgetTypeEnum.InPlaceContractRentArea:
          return (
            <InPlaceContractRentAreaWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.InPlaceContractRentUnit:
          return (
            <InPlaceContractRentUnitWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.WeightAverageLeaseExpiry:
          return (
            <WeightAverageLeaseExpiry
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.WeightAverageLeaseExpiryInMonths:
          return (
            <WeightAverageLeaseExpiryInMonths
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.DebtService:
          return (
            <DebtServiceWidget data={data} highlightHeader={highlightHeader} />
          );
        case WidgetTypeEnum.LeaseCommissions:
          return (
            <LeaseCommissionsWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.Valuation:
          return (
            <ValuationWidget data={data} highlightHeader={highlightHeader} />
          );
        case WidgetTypeEnum.CapitalizationRate:
          return (
            <CapitalizationRateWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.NetAbsorptionArea:
          return (
            <NetAbsorptionAreaWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.NetAbsorptionUnit:
          return (
            <NetAbsorptionUnitWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
        case WidgetTypeEnum.LeasedPercentage:
          return (
            <LeasedPercentageWidget
              data={data}
              highlightHeader={highlightHeader}
            />
          );
          case WidgetTypeEnum.NetAbsorptionRateArea:
            return (
              <NetAbsorptionRateAreaWidget
                data={data}
                highlightHeader={highlightHeader}
              />
            );
          case WidgetTypeEnum.NetAbsorptionRateYoYArea:
            return (
              <NetAbsorptionRateYoYAreaWidget
                data={data}
                highlightHeader={highlightHeader}
              />
            );
        default:
          return <React.Fragment />;
      }
    },
    [dashboardData.data?.widgetInfos, filter?.denomination]
  );

  const modifySelectedDashboard = useCallback(
    (id: number, accountIdentifier: string, name: string, isDefault: boolean, isPublic: boolean, denomination: string, dateRange: number, startDate: Date, endDate: Date, bookId: string, properties: string[], portfolios: string[]) => {
      let copyProperties = properties.slice();
      let copyDashboardProperties = selectedDashboard.properties?.slice();
      let copyPortfolios = portfolios.slice();
      let copyDashboardPortfolios = selectedDashboard.portfolios?.slice();
      
      if (
        selectedDashboard.id !== id ||
        selectedDashboard.accountIdentifier !== accountIdentifier ||
        selectedDashboard.name !== name ||
        selectedDashboard.isDefault !== isDefault ||
        selectedDashboard.isPublic !== isPublic ||
        selectedDashboard.denomination !== denomination ||
        selectedDashboard.dateRange !== dateRange ||
        selectedDashboard.startDate?.getTime() !== startDate.getTime() ||
        selectedDashboard.endDate?.getTime() !== endDate.getTime() ||
        selectedDashboard.bookId !== bookId ||
        JSON.stringify(copyDashboardProperties?.sort()) !== JSON.stringify(copyProperties?.sort()) ||
        JSON.stringify(copyDashboardPortfolios?.sort()) !== JSON.stringify(copyPortfolios?.sort()) 
      )
      setSelectedDashboard({ id, accountIdentifier, name, isDefault, isPublic, denomination, dateRange, startDate, endDate, bookId, properties, portfolios});       
    },
    [selectedDashboard]
  );

  const [locked, setLocked] = useState(false)
  const clickLocked = () => {
    setLocked(!locked)
  };

  const clickDefault = () => {
    console.log("clicked default button")
  };

  return (
    <Box className="mainContainer">
      <Box className={"appHeader"}>
        <Box>
          <Typography sx={{ color: "var(--neutral-grey-600)", fontSize: "16px", fontWeight: "500", marginBottom: "7.5px" }}>
            Dashboard
          </Typography>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="h1" sx={{ color: "var(--primary-dark-blue)", fontSize: "20px" }}>
                {selectedDashboard.name && selectedDashboard.name.length > 75
                  ? selectedDashboard.name?.slice(0, 75).concat("...")
                  : selectedDashboard.name}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={selectedDashboard.isPublic ? <UnLockedIcon /> : <LockedIcon />}
                onClick={clickLocked}
                className={classes.greyButton}
                >
                { selectedDashboard.isPublic ? "Public" : "Private" }
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={selectedDashboard.isDefault ? <StarFilledIcon /> : <StarIcon />}
                onClick={clickDefault}
                className={classes.greyButton}
                >
                Default
              </Button>
            </Grid>
          </Grid>
        </Box>
        {/* User Options */}
        <UserOptions />
      </Box>
      <DashboardBase
        dashboardType={DashboardTypeEnum.Main}
        getControl={getControl}
        hasDataLoaded={
          dashboardData.data?.widgetInfos !== null && !dashboardData.isLoading
        }
        currentFilters={filter}
        defaultBookId={defaultBookId}
        modifySelectedDashboard={modifySelectedDashboard}
        filter={<MainDashboardFilter setFilter={setFilter} dashboardItems={selectedDashboard} setDefaultBookId={setDefaultBookId} defaultBookId={defaultBookId} />}
        accountId={filter?.accountIdentifier}
      />
    </Box>
  );
};

interface MainDashboardProps {
  setFilter: (filter: DashboardGeneralDataFilter) => void;
  setDefaultBookId: (defaultBookId: string) => void;
  defaultBookId: string | undefined;
  dashboardItems: UserSelectedDashboard;
}
const MainDashboardFilter: React.FC<MainDashboardProps> = React.memo(
  (props) => {
    const accounts = useGetAccounts();
    const denominationOptions = getDenominationOptions();

    const [settings, setSettings] = useState<InnerDashboardGeneralDataFilter>({
      startDate: new Date(),
      endDate: new Date(),
      properties: [],
      portfolios: [],
      dateRange: DateRangeEnum.CustomerRange,
      denomination: "Gross",
    });

    const properties = useGetPropertyList(settings.accountIdentifier, {
      propertyType: undefined,
    });

    const portfolios = useGetPortfolioList(settings.accountIdentifier);

    const queryParams = useLocation().search;
    const parsedQueryParams = queryString.parse(queryParams);

    // Updating setFilter from props every time the settings change.
    useEffect(() => {
      if (!settings) return;
      
      setSettings((oldSettings) => {
        return {
          ...oldSettings,
          denomination: props.dashboardItems.denomination,
          dateRange: props.dashboardItems.dateRange,
          startDate: props.dashboardItems.startDate,
          endDate: props.dashboardItems.endDate,
          bookId: props.dashboardItems.bookId,
          properties: props.dashboardItems.properties  || [],
          portfolios: props.dashboardItems.portfolios  || []
        };
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dashboardItems]);

    // If the query param "portfolioId" exists, then we use the portfolioId and set our portfolio dropdown to that portfolio Id
    useEffect(() => {
      if (
        !portfolios.isSuccess ||
        portfolios.data === undefined ||
        parsedQueryParams.portfolioId === undefined
      )
        return;

      const queryPortfolioId = parsedQueryParams.portfolioId as string;
      let portfoliosHasId = false;
      for (const portfolio of portfolios.data) {
        if (portfolio.id === queryPortfolioId) {
          portfoliosHasId = true;
          break;
        }
      }

      if (portfoliosHasId && settings.portfolios?.length === 0) {
        setSettings((prevSettings) => {
          if (prevSettings.portfolios?.length > 0) return prevSettings;

          const newPortfolios: string[] = [queryPortfolioId];
          return {
            ...prevSettings,
            portfolios: newPortfolios,
          };
        });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolios.data, portfolios.isSuccess]);

    const ledgerBookList = useGetLedgerBookList(settings.accountIdentifier);

    const allPropertiesSelected =
      properties.data &&
      properties.data.length > 0 &&
      settings.properties.length === properties.data.length;

    const allPortfoliosSelected =
      portfolios.data &&
      portfolios.data.length > 0 &&
      settings.portfolios.length === portfolios.data.length;

    // Updating setFilter from props every time the settings change.
    useEffect(() => {
      if (!settings) return;

      let filter = {
        ...settings,
        startYear: settings.startDate?.getFullYear(),
        startMonth: (settings.startDate?.getMonth() || 0) + 1,
        endYear: settings.endDate?.getFullYear(),
        endMonth: (settings.endDate?.getMonth() || 0) + 1,
      };
      props.setFilter({ ...filter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings, props.setFilter]);

    // Setting book id to the first book id from the retrieved ledger book list items
    useEffect(() => {
      if (ledgerBookList.data === undefined || !ledgerBookList.isSuccess)
        return;

      if (settings.bookId !== undefined && settings.bookId.trim().length > 0)
        return;

      if (
        Array.isArray(ledgerBookList.data) &&
        ledgerBookList.data.length > 0
      ) {
        setSettings((prevSettings) => {
          return {
            ...prevSettings,
            bookId: ledgerBookList.data[0].id,
          };
        });
        props.setDefaultBookId(ledgerBookList.data[0].id);
      }
    }, [ledgerBookList, ledgerBookList.isSuccess, settings.bookId, props]);

    // Setting account id to the first account id from the retrieved accounts
    useEffect(() => {
      if (accounts.data === undefined || !accounts.isSuccess) return;

      if (
        settings.accountIdentifier !== undefined &&
        settings.accountIdentifier.trim().length > 0
      )
        return;

      if (Array.isArray(accounts.data) && accounts.data.length > 0) {
        setSettings((prevSettings) => {
          return {
            ...prevSettings,
            accountIdentifier: accounts.data[0].identifier,
          };
        });
      }
    }, [accounts, accounts.isSuccess, settings.accountIdentifier]);

    const classes = useStyles();

    /**
     * Single function that is used to update the settings - could be account identifier, properties, portfolios, or any other dropdown
     * @param e change event
     */
    function updateSettings(e: SelectChangeEvent<string[] | string>) {
      if (e.target.name) {
        let newSettings = { ...settings };
        if (e.target.name === "accountIdentifier") {
          newSettings.bookId = undefined;
          newSettings.properties = [];
          newSettings.portfolios = [];
        }
        let value: any = e.target.value === "" ? null : e.target.value;
        if (e.target.name === "properties") {
          let allSelected = (value as string[]).indexOf("all");
          if (allSelected !== -1)
            value = allPropertiesSelected
              ? []
              : properties.data?.map((p) => p.id);
        }
        if (e.target.name === "portfolios") {
          let allSelected = (value as string[]).indexOf("all");
          if (allSelected !== -1)
            value = allPortfoliosSelected
              ? []
              : portfolios.data?.map((p) => p.id);
        }

        setSettings({
          ...newSettings,
          [e.target.name]: value,
        });
      }
    }

    const resetFiters = () => {
      setSettings({
        startDate: new Date(),
        endDate: new Date(),
        properties: [],
        portfolios: [],
        dateRange: DateRangeEnum.LastTwoYears,
        denomination: "Gross",
        bookId: props.defaultBookId
      });
    }
    const SelectIcon = (props: any) => (
      <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
        <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
      </svg>
    );

    return (
      <React.Fragment>
        <Box>
          <Grid container spacing={1}>
            {/* Property Dropdown */}
            {settings.accountIdentifier &&
              properties.data &&
              portfolios.data &&
              ledgerBookList.data && (
              <React.Fragment>
                <Grid item>
                  <FormControl
                    variant="outlined"
                    size="small"
                    className={"form-control form-control-dashboard"}
                  >
                    <Typography
                      variant="body3"
                      component="label"
                      className={"input-label"}
                    >{`Date Range`}</Typography>
                    {/*<InputLabel id="date-range-label">Date Range</InputLabel>*/}
                    <Select
                      labelId="date-range-label"
                      id="dateRange"
                      name="dateRange"
                      //label="Date Range"
                      value={settings.dateRange.toString()}
                      onChange={updateSettings}
                      className={"input-field"}
                      IconComponent={SelectIcon}
                    >
                      <MenuItem
                        key={DateRangeEnum.LastOneMonth}
                        value={DateRangeEnum.LastOneMonth}
                      >
                        Last Month
                      </MenuItem>
                      <MenuItem
                        key={DateRangeEnum.LastTwoMonths}
                        value={DateRangeEnum.LastTwoMonths}
                      >
                        Last Two Months
                      </MenuItem>
                      <MenuItem
                        key={DateRangeEnum.LastThreeMonths}
                        value={DateRangeEnum.LastThreeMonths}
                      >
                        Last Three Months
                      </MenuItem>
                      <MenuItem
                        key={DateRangeEnum.LastSixMonths}
                        value={DateRangeEnum.LastSixMonths}
                      >
                        Last Six Months
                      </MenuItem>
                      <MenuItem
                        key={DateRangeEnum.YearToDate}
                        value={DateRangeEnum.YearToDate}
                      >
                        Year To Date
                      </MenuItem>
                      <MenuItem
                        key={DateRangeEnum.LastOneYear}
                        value={DateRangeEnum.LastOneYear}
                      >
                        Last Year
                      </MenuItem>
                      <MenuItem
                        key={DateRangeEnum.LastTwoYears}
                        value={DateRangeEnum.LastTwoYears}
                      >
                        Last Two Years
                      </MenuItem>
                      <MenuItem
                        key={DateRangeEnum.CustomerRange}
                        value={DateRangeEnum.CustomerRange}
                      >
                        Custom Range
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {settings.dateRange === DateRangeEnum.CustomerRange && (
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      size="small"
                      className={"form-control form-control-dashboard"}
                    >
                      <Typography
                        variant="body3"
                        component="label"
                        className={"input-label"}
                      >{`Select date range`}</Typography>
                      {/* 
                      <label className={"label-datepicker"}>
                        Enter Date Range
                      </label>*/}
                      <Box className={"datepicker-container daterange-container"}>
                        <DatePicker
                          selected={settings.startDate}
                          startDate={settings.startDate}
                          endDate={settings.endDate}
                          maxDate={new Date()}
                          onChange={(date: Date) =>
                            setSettings({
                              ...settings,
                              startDate: date,
                            })
                          }
                          dateFormat="MMM yyyy"
                          showMonthYearPicker
                          showFullMonthYearPicker
                          showTwoColumnMonthYearPicker
                          selectsStart
                          //className={classes.datePicker}
                          popperPlacement="auto"
                          popperModifiers={[
                            {
                              name: "preventOverflow",
                              options: {
                                altAxis: true,
                              },
                            },
                            {
                              name: "offset",
                              options: {
                                offset: [-13, 0],
                              },
                            },
                          ]}
                          className={"right-text"}
                        />
                        <Box className={"icon-datepicker icon-datepicker-dash"}>
                          &nbsp;-&nbsp;
                        </Box>
                        <DatePicker
                          selected={settings.endDate}
                          startDate={settings.startDate}
                          endDate={settings.endDate}
                          maxDate={new Date()}
                          onChange={(date: Date) =>
                            setSettings({
                              ...settings,
                              endDate: date,
                            })
                          }
                          dateFormat="MMM yyyy"
                          showMonthYearPicker
                          showFullMonthYearPicker
                          showTwoColumnMonthYearPicker
                          selectsEnd
                          //className={classes.datePicker}
                          popperPlacement="auto"
                          popperModifiers={[
                            {
                              name: "preventOverflow",
                              options: {
                                altAxis: true,
                              },
                            },
                            {
                              name: "offset",
                              options: {
                                offset: [-13, 0],
                              },
                            },
                          ]}
                        />
                        <Box className={"icon-datepicker"}>
                          <UilCalender />
                        </Box>
                      </Box>
                    </FormControl>
                  </Grid>
                )}
                <Grid item>
                  <FormControl
                    variant="outlined"
                    size="small"
                    className={"form-control form-control-dashboard"}
                  >
                    {/*<InputLabel id="property-label">Property</InputLabel>*/}
                    <Typography
                      variant="body3"
                      component="label"
                      className={"input-label"}
                    >{`Property`}</Typography>
                    <Select
                      MenuProps={{
                        //anchorOrigin: { vertical: "bottom", horizontal: "left" },
                        autoFocus: false,
                      }}
                      labelId="property-label"
                      multiple
                      //label="Property"
                      id="properties"
                      name="properties"
                      value={settings.properties || []}
                      renderValue={(selected: any) => {
                        if (!properties.data) return selected;
                        return selected
                          .map((s: any) => {
                            let property = properties.data.find(
                              (p) => p.id === s
                            );
                            return property?.title;
                          })
                          .join(", ");
                      }}
                      onChange={updateSettings}
                      className={"input-field"}
                      IconComponent={SelectIcon}
                    >
                      <MenuItem
                        value="all"
                        classes={{
                          root: allPropertiesSelected ? classes.selectedAll : "",
                        }}
                      >
                        <Checkbox
                          checked={allPropertiesSelected}
                          indeterminate={
                            properties.data &&
                            settings.properties.length > 0 &&
                            settings.properties.length < properties.data?.length
                          }
                        />

                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {properties.data?.map((property) => (
                        <MenuItem key={property.id} value={property.id}>
                          <Checkbox
                            checked={
                              settings.properties.indexOf(property.id) > -1
                            }
                          />
                          <ListItemText primary={property.title} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl
                    variant="outlined"
                    size="small"
                    className={"form-control form-control-dashboard"}
                  >
                    <Typography
                      variant="body3"
                      component="label"
                      className={"input-label"}
                    >{`Portfolio`}</Typography>
                    {/*<InputLabel id="portfolio-label">Portfolio</InputLabel>*/}
                    <Select
                      labelId="portfolio-label"
                      multiple
                      //label="Portfolios"
                      id="portfolios"
                      name="portfolios"
                      value={settings.portfolios || []}
                      renderValue={(selected: any) => {
                        if (!portfolios.data) return selected;
                        return selected
                          .map((s: any) => {
                            let portfolio = portfolios.data.find(
                              (p) => p.id === s
                            );
                            return portfolio?.name;
                          })
                          .join(", ");
                      }}
                      onChange={updateSettings}
                      className={"input-field"}
                      IconComponent={SelectIcon}
                    >
                      <MenuItem
                        value="all"
                        classes={{
                          root: allPortfoliosSelected ? classes.selectedAll : "",
                        }}
                      >
                        <Checkbox
                          checked={allPortfoliosSelected}
                          indeterminate={
                            portfolios.data &&
                            settings.portfolios.length > 0 &&
                            settings.portfolios.length < portfolios.data?.length
                          }
                        />

                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {portfolios.data?.map((portfolio) => (
                        <MenuItem key={portfolio.id} value={portfolio.id}>
                          <Checkbox
                            checked={
                              settings.portfolios.indexOf(portfolio.id) > -1
                            }
                          />
                          <ListItemText primary={portfolio.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </React.Fragment>
            )}
            </Grid>
            <Grid container spacing={1}>
            {/* Denomination Dropdown */}
            <Grid item>
              <FormControl
                variant="outlined"
                size="small"
                className={"form-control form-control-dashboard"}
                //className={classes.formControl}
              >
                {/*<InputLabel id="denomination-label">Denomination</InputLabel>*/}
                <Typography
                  variant="body3"
                  component="label"
                  className={"input-label"}
                >{`Denomination`}</Typography>
                <Select
                  labelId="denomination-label"
                  id="denomination"
                  //label="Denomination"
                  name="denomination"
                  value={settings.denomination || ""}
                  onChange={updateSettings}
                  className={"input-field"}
                  IconComponent={SelectIcon}
                >
                  {denominationOptions.map((denominationOption) => (
                    <MenuItem
                      key={denominationOption.value}
                      value={denominationOption.value}
                    >
                      {denominationOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Company Dropdown */}
            {accounts !== undefined &&
              accounts.data !== undefined &&
              accounts.data?.length > 1 && (
                <Grid item>
                  <FormControl
                    variant="outlined"
                    size="small"
                    className={"form-control form-control-dashboard"}
                  >
                    <Typography
                      variant="body3"
                      component="label"
                      className={"input-label"}
                    >{`Company`}</Typography>
                    {/*<InputLabel id="company-label">Company</InputLabel>*/}
                    <Select
                      labelId="company-label"
                      id="accounts"
                      name="accountIdentifier"
                      //label="Company"
                      value={settings.accountIdentifier || ""}
                      onChange={updateSettings}
                      className={"input-field"}
                      IconComponent={SelectIcon}
                    >
                      {accounts.data?.map((account) => (
                        <MenuItem
                          key={account.identifier}
                          value={account.identifier}
                        >
                          {account.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            {/* Go Block */}
            
            {settings.accountIdentifier &&
              properties.data &&
              portfolios.data &&
              ledgerBookList.data && (
              <Grid item>
                <FormControl
                  variant="outlined"
                  size="small"
                  className={"form-control form-control-dashboard"}
                >
                  <Typography
                    variant="body3"
                    component="label"
                    className={"input-label"}
                  >{`GL Book`}</Typography>
                  {/*<InputLabel id="gl-book-label">GL Book</InputLabel>*/}
                  <Select
                    labelId="gl-book-label"
                    id="bookId"
                    name="bookId"
                    //label="GL Book"
                    value={settings.bookId || ""}
                    onChange={updateSettings}
                    className={"input-field"}
                    IconComponent={SelectIcon}
                  >
                    {ledgerBookList.data?.map((ledgerBook) => (
                      <MenuItem key={ledgerBook.id} value={ledgerBook.id}>
                        {ledgerBook.bookName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {/* Reset all filters */}
            <Grid item sx={{display: "flex", alignItems: "center"}}>
              <FormControl
                variant="outlined"
                size="small"
                className={"form-control form-control-dashboard"}
              >
                <Typography
                  variant="body3"
                  component="label"
                  sx={{height: "20px"}}
                ></Typography>
                <Link component="button" variant="body2" underline="always" className={classes.linkButton} onClick={resetFiters}>
                  Reset all filters
                </Link>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    );
  }
);

export default MainDashboard;
