import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Tooltip,
  Button,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { UilExport } from "@iconscout/react-unicons";
import "react-datepicker/dist/react-datepicker.css";

import { usePropertyInfo } from "../../util/propertyInfoContext";
import { useGetBusinessUnitsOptions } from "../api/businessUnits";
import { useGetGLTreeOptions } from "../api/glTree";
import { useGetAccountTypesOptions } from "../api/accountTypes";
import FinancialsDropdown from "./FinancialsDropdown";
import {
  transformAccountOptions,
  transformBusinessOptions,
  transformGLTreeOptions,
} from "../util/financialsDropdown";
import FinancialsGrid from "./FinancialsGrid";
import RentRollContext from "../../rent roll/context/RentRollContext";
import { UilCalender } from "@iconscout/react-unicons";
import {
  lastDayOfQuarter,
  lastDayOfMonth,
  lastDayOfYear,
  startOfQuarter,
  startOfMonth,
  startOfYear,
  addYears,
} from "date-fns";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SaveGridStateOptions from "../../../../shared/view/SaveGridStateOptions"; // GRID_CONFIG_DEFAULT_VIEW,
import { useQueryClient } from "@tanstack/react-query";
import {
  GridConfigIds,
  PageConfigIds,
} from "../../../../constants/GridPageConfigurationIds";
import { useGetGridConfig } from "../../../../shared/api/pageConfigService";
import { GridApi, ColumnApi } from "ag-grid-community";

export interface FinancialsFilter {
  startYear: number;
  startMonth: number;
  endYear: number;
  endMonth: number;
  businessUnitId: string;
  groupingMode: number;
  glTreeId: string;
  accountTypeId: string;
  budgetView: boolean;
}
let propertyInfo:any=null;

const PropertyViewFinancials: React.FC = () => {
  const GRID_CONFIG_DEFAULT_VIEW = "Standard";
  propertyInfo = usePropertyInfo();
  const {
    financialsAccountingType,
    updateFinancialsAccountingType,
    financialsBusinessUnitsDDValue,
    updateFinancialsBusinessUnitsDDValue,
    financialsGLTreeValue,
    updateFinancialsGLTreeValue,
    financialsStartDate,
    financialsEndDate,
    financialsGroupingMode,
    updateFinancialsStartDate,
    updateFinancialsEndDate,
    updateFinancialsGroupingMode,
    financialsBudgetView,
    updateFinancialsBudgetView,
  } = React.useContext(RentRollContext);

  const currentDate = new Date();
  const [minMaxDate, setMinMaxDate] = useState({
    minDate: currentDate,
    maxDate: currentDate,
  });
  const [enableBudgetView, setEnableBudgetView] = useState(false);

  const minMaxUpdated = React.useRef(false);
  const endDateUpdated = React.useRef(false);
  const filterUpdated = React.useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isIncomeStatement, setIsIncomeStatement] = useState(false);
  const [queryParamDate, setQueryParamDate] = useState<Date | null>();

  useEffect(() => {
    if (
      searchParams.has("isincomestatement") ||
      searchParams.has("month") ||
      searchParams.has("year")
    ) {
      if (searchParams.has("month")) {
        let monthString = searchParams.get("month");
        let yearString = searchParams.get("year");
        if (yearString !== null && monthString !== null) {
          setQueryParamDate(
            new Date(Number(yearString), Number(monthString) - 1)
          );
        }
      }

      if (searchParams.has("isincomestatement")) {
        setIsIncomeStatement(
          searchParams.get("isincomestatement") === "1" ? true : false
        );
      }

      //remove from the url as it is not needed
      const newParams: { [key: string]: string } = {};
      searchParams.forEach((value: string, key: string) => {
        if (
          key.toLowerCase() === "propertyid" ||
          key.toLowerCase() === "accountidentifier"
        )
          newParams[key] = value;
      });
      setSearchParams(newParams);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setSearchParams]);

  const businessUnitOptionsQuery = useGetBusinessUnitsOptions({
    accountIdentifier: propertyInfo.accountIdentifier,
    propertyId: propertyInfo.id,
  });
  const glTreeOptionsQuery = useGetGLTreeOptions({
    accountIdentifier: propertyInfo.accountIdentifier,
    propertyId: propertyInfo.id,
  });
  const accountTypeOptionsQuery = useGetAccountTypesOptions(
    propertyInfo.accountIdentifier
  );

  const businessUnitOptions = businessUnitOptionsQuery.data;
  const glTreeOptions = glTreeOptionsQuery.data;
  const accountTypeOptions = accountTypeOptionsQuery.data;

  const reportTypeToEnableBudgetView = "income statement";

  const [financialsFilter, setFinancialsFilter] = useState<FinancialsFilter>({
    startYear: financialsStartDate.getFullYear(),
    startMonth: financialsStartDate.getMonth() + 1,
    endYear: financialsEndDate.getFullYear(),
    groupingMode: financialsGroupingMode,
    endMonth: financialsEndDate.getMonth() + 1,
    businessUnitId: financialsBusinessUnitsDDValue,
    glTreeId: financialsGLTreeValue,
    accountTypeId: financialsAccountingType,
    budgetView: financialsBudgetView,
  });

  useEffect(() => {
    if (
      businessUnitOptions === undefined ||
      businessUnitOptions.length === 0 ||
      financialsBusinessUnitsDDValue.length !== 0
    )
      return;
    setFinancialsFilter((prevFilter) => {
      return {
        ...prevFilter,
        businessUnitId: "",
      };
    });
    updateFinancialsBusinessUnitsDDValue("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitOptions]);

  useEffect(() => {
    if (
      glTreeOptions === undefined ||
      glTreeOptions.length === 0 ||
      financialsGLTreeValue.length !== 0
    )
      return;
    setFinancialsFilter((prevFilter) => {
      return {
        ...prevFilter,
        glTreeId: glTreeOptions[0].id,
      };
    });

    let optionIndex = 0;
    if (isIncomeStatement) {
      let incomeStmtIndex = glTreeOptions.findIndex((opt) =>
        opt.name.toLowerCase().includes(reportTypeToEnableBudgetView)
      );
      optionIndex = incomeStmtIndex;
    }
    updateFinancialsGLTreeValue(glTreeOptions[optionIndex].id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [glTreeOptions, isIncomeStatement]);

  useEffect(() => {
    if (
      glTreeOptions === undefined ||
      glTreeOptions.length === 0 ||
      !financialsGLTreeValue
    )
      return;

    let option = glTreeOptions.find((g) => g.id === financialsGLTreeValue);
    if (option?.typeName?.toLowerCase().includes(reportTypeToEnableBudgetView)) {
      setEnableBudgetView(true);
    } else {
      setEnableBudgetView(false);
    }
    if (isIncomeStatement) {
      updateFinancialsBudgetView(true);
      setFinancialsFilter({
        ...financialsFilter,
        budgetView: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialsGLTreeValue, glTreeOptions, isIncomeStatement]);

  useEffect(() => {
    if (
      accountTypeOptions === undefined ||
      accountTypeOptions.length === 0 ||
      financialsAccountingType.length !== 0
    )
      return;

    const defaultAccountOptionObj = accountTypeOptions.find(
      (option) => option.name.toLowerCase() === "accrual"
    );
    let accountTypeId: string;
    if (defaultAccountOptionObj === undefined)
      accountTypeId = accountTypeOptions[0].id.toString();
    else accountTypeId = defaultAccountOptionObj.id.toString();

    setFinancialsFilter((prevFilter) => {
      return {
        ...prevFilter,
        accountTypeId: accountTypeId,
      };
    });
    updateFinancialsAccountingType(accountTypeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountTypeOptions]);

  const getFormat = () => {
    switch (financialsGroupingMode) {
      case 0:
        return "MMM yyyy";
      case 1:
        return "QQQ yyyy";
      case 2:
        return "yyyy";
    }
  };

  const getRangeDate = useCallback((date: Date, groupingMode: number) => {
    switch (groupingMode) {
      case 0:
        return { start: startOfMonth(date), end: lastDayOfMonth(date) };
      case 1:
        return { start: startOfQuarter(date), end: lastDayOfQuarter(date) };
      case 2:
        return { start: startOfYear(date), end: lastDayOfYear(date) };
      default:
        throw new Error("Invalid grouping mode");
    }
  }, []);

  const getDates = useCallback(
    (date: Date, groupingMode: number) => {
      let dateRange = getRangeDate(date, groupingMode);
      let start = dateRange.start,
        end = dateRange.end;

      let minDate = getRangeDate(new Date(minMaxDate.minDate), groupingMode),
        maxDate = getRangeDate(new Date(minMaxDate.maxDate), groupingMode);
      if (dateRange.start < minDate.start) start = minDate.start;
      if (dateRange.start > maxDate.end) start = minDate.end;
      if (dateRange.end > maxDate.end) end = maxDate.end;

      return { start, end };
    },
    [minMaxDate, getRangeDate]
  );

  const getMaxDate = (minDate: Date, maxDate?: Date) => {
    if (maxDate === undefined) return minDate;
    let resultDate =
      Date.parse(maxDate.toString()) > Date.parse(new Date().toString())
        ? new Date()
        : new Date(maxDate);
    if (queryParamDate !== undefined && queryParamDate !== null) {
      resultDate =
        Date.parse(resultDate.toString()) <
        Date.parse(queryParamDate.toString())
          ? resultDate
          : queryParamDate;
    }
    return resultDate;
  };

  const getMinDate = (minDate?: Date, endDate?: Date) => {
    if (minDate === undefined) return new Date();
    const oneYearBeforeMax = addYears(
      endDate === undefined ? new Date() : endDate,
      -1
    );
    let resultDate =
      Date.parse(minDate.toString()) >
      Date.parse(new Date(oneYearBeforeMax).toString())
        ? new Date(minDate)
        : new Date(oneYearBeforeMax);

    if (
      queryParamDate !== undefined &&
      queryParamDate !== null &&
      queryParamDate === endDate
    ) {
      //Use 1st day of the end date year for YTD
      let firstDayOfYear = new Date(queryParamDate.getFullYear(), 0);
      resultDate =
        Date.parse(minDate.toString()) > Date.parse(firstDayOfYear.toString())
          ? new Date(minDate)
          : firstDayOfYear;
    }
    return resultDate;
  };

  // Grid Config state and methods
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();

  const [gridView, setGridView] = useState(GRID_CONFIG_DEFAULT_VIEW);

  const gridId = financialsBudgetView
    ? GridConfigIds.PROPERTY_BUDGET_GRID
    : GridConfigIds.PROPERTY_FINANCIALS_GRID;
  const pageId = financialsBudgetView
    ? PageConfigIds.PROPERTY_BUDGET_GRID
    : PageConfigIds.PROPERTY_FINANCIALS_GRID;

  const accountId = propertyInfo.accountIdentifier;

  const queryClient = useQueryClient();
  const gridConfigsQuery = useGetGridConfig({
    pageId: pageId,
    gridIdentifier: gridId,
    accountId: accountId,
  });

  /**
   * Sets the context that is retrieved from the backend whenever the gridView is changed.
   * gridView is either changed when data first loads (only if a default value exists) or when the user uses the dropdown
   */
  React.useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1 ||
      gridApi === undefined ||
      gridColumnApi === undefined
    )
      return;

    if (gridView === GRID_CONFIG_DEFAULT_VIEW) {
      gridApi.setFilterModel({});
      gridColumnApi.resetColumnState();
      return;
    }

    const selectedGridConfig = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.id === Number(gridView)
    );

    if (selectedGridConfig === undefined) return;

    gridApi.setFilterModel(JSON.parse(selectedGridConfig.columnFilterState));
    gridColumnApi.applyColumnState({
      state: JSON.parse(selectedGridConfig.columnState),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridView]);

  const saveAsView = React.useRef("");

  /**
   * exports the data that is rendered in the AG grid.
   */

  const rowGroupCallback = (params:any) => {
    return params.node.key;
  };
  const columnWidthCallback = (params:any) => {
    if(params.column.colId=='ag-Grid-AutoColumn')
    {
      return 400;
    }
    return 120;
  };
  const rowHeightCallback = (params:any) => {
    let rowHight=18;
    switch(params?.rowIndex) { 
      case 1: { 
        rowHight=72;
         break; 
      } 
      case 4: { 
        rowHight=72;
         break; 
      } 
      default: { 
        rowHight=29;
         break; 
      } 
    } 
    return rowHight;
  };

  const onExport = () => {
    const cols=gridColumnApi?.getAllDisplayedColumns().map(v=>v.getColId());
    
    let index = cols?.indexOf('AccountNumber');
    if (index !== -1) {
      cols?.splice(index!, 1); // remove the item
      cols?.unshift('AccountNumber'); // add the item to the beginning
    }
    const params = {
      author: "WorkSpace LLC",
      columnWidth: columnWidthCallback,
      sheetName: "Financial data",
      fileName: "Financial",
      exportMode: undefined,
      suppressTextAsCDATA: false,
      rowHeight: rowHeightCallback,
      headerRowHeight: undefined,
      columnKeys: cols,
      processRowGroupCallback: rowGroupCallback,
      processCellCallback: cellCallback,
      prependContent: getSheetSummaryRows(),
    };
    gridApi?.exportDataAsExcel(params);
  };
const formatCurrency = (num:any, scale=2)=>{
  let isNegative = num<0;
  let absValue = Math.abs(num);
  
  const nf = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: scale,
  });
  let returnString = nf.format(absValue);

  return isNegative ? '(' + returnString + ')' : returnString;
}
const cellCallback= (params:any) => {
  const column = params.column;
  const colId=column.colId;
  let value = params.value
  if(value?.toFixed){
    if(!['AccountNumber','ag-Grid-AutoColumn'].includes(colId)){
      value=formatCurrency(value);
    }
  }
  return value === undefined ? '' : value;
}
const getSheetSummaryRows:any = () =>{ 
  let data=[
      [
          {
            'styleId': 'head1',
            'data': {
              value: 'Financial Statement Export',
              type: 'String',
            },
          },
      ],
      [
        {
          'styleId': 'head2',
          'data': {
            value: `Property: ${propertyInfo.title}`,
            type: 'String',
          },
        },
      ],
      [
        {
          'styleId': 'head2',
          'data': {
            value: `Business Units: ${financialsFilter.businessUnitId==''?'All Business Units':businessUnitOptions?.find(v=>v.id==financialsFilter.businessUnitId)?.name}`,
            type: 'String',
          },
        },
      ],
    ];
    return data;
  };

  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1
    ) {
      if (gridView !== GRID_CONFIG_DEFAULT_VIEW)
        setGridView(GRID_CONFIG_DEFAULT_VIEW);
      return;
    }

    const isSaveAsRender = saveAsView.current.trim().length > 0;

    if (isSaveAsRender) {
      const savedAsView = gridConfigsQuery.data.gridConfigurations.find(
        (gridConfig) =>
          gridConfig.name.toLowerCase().trim() ===
          saveAsView.current.toLowerCase().trim()
      );
      if (savedAsView) setGridView(savedAsView.id.toString());
      saveAsView.current = "";
      return;
    }

    const defaultView = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.isDefault
    );
    if (
      defaultView &&
      gridView !== defaultView.id.toString() &&
      gridView === GRID_CONFIG_DEFAULT_VIEW
    )
      setGridView(defaultView.id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridConfigsQuery.isSuccess, gridConfigsQuery.data]);

  useEffect(() => {
    if (financialsBudgetView) setGridView(GRID_CONFIG_DEFAULT_VIEW);
  }, [financialsBudgetView]);

  const UnCheckedIcon = () => (
    <div
      style={{
        width: "24px",
        height: "24px",
        margin: "0 8px 0 0",
        borderRadius: "6px",
        boxShadow: "0 2px 2px 0 rgba(46, 57, 72, 0.06)",
        border: "solid 1px var(--neutral-grey-200)",
        backgroundColor: !enableBudgetView
          ? "var(--neutral-grey-100)"
          : "var(--neutral-white)",
      }}
    />
  );

  const CheckedIcon = () => (
    <svg
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#a)">
        <rect x="2" width="24" height="24" rx="6" fill="#066CFA" />
      </g>
      <path
        d="M20.71 7.21a.999.999 0 0 0-1.42 0l-7.45 7.46-3.13-3.14A1.02 1.02 0 1 0 7.29 13l3.84 3.84a1 1 0 0 0 1.42 0l8.16-8.16a.999.999 0 0 0 0-1.47z"
        fill="#fff"
      />
      <defs>
        <filter
          id="a"
          x="0"
          y="0"
          width="32"
          height="24"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.180392 0 0 0 0 0.223529 0 0 0 0 0.282353 0 0 0 0.06 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_811:11217"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_811:11217"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );

  return (
    <React.Fragment>
      {financialsAccountingType === "" || financialsGLTreeValue === "" ? (
        <Box m="auto">
          <CircularProgress />
        </Box>
      ) : (
        <React.Fragment>
          <div
            id="property-financials"
            className="tab-pane fade show active tab-pane-no-summary"
            role="tabpanel"
            aria-labelledby="property-tab-financials"
          >
            <Grid container className="property-action-bar">
              <Grid item>
                <Grid container sx={{ width: "auto" }}>
                  <Grid item>
                    <Typography
                      variant="body3"
                      component="label"
                      className={"input-label"}
                    >{`Select date range`}</Typography>
                    <Box className={"datepicker-container daterange-container"}>
                      <DatePicker
                        selected={financialsStartDate}
                        onChange={(date: Date) => {
                          updateFinancialsStartDate(date);
                          setFinancialsFilter({
                            ...financialsFilter,
                            startYear: date.getFullYear(),
                            startMonth: date.getMonth() + 1,
                          });
                        }}
                        dateFormat={getFormat()}
                        //showFullMonthYearPicker
                        //showTwoColumnMonthYearPicker
                        showMonthYearPicker={financialsGroupingMode === 0}
                        showQuarterYearPicker={financialsGroupingMode === 1}
                        showYearPicker={financialsGroupingMode === 2}
                        minDate={
                          getDates(
                            new Date(minMaxDate.minDate),
                            financialsGroupingMode
                          ).start
                        }
                        maxDate={
                          getDates(
                            new Date(minMaxDate.maxDate),
                            financialsGroupingMode
                          ).end
                        }
                        //className={classes.datePicker}
                        //popperPlacement="auto"
                        popperModifiers={[
                          {
                            name: "preventOverflow",
                            options: {
                              altAxis: true,
                            },
                          },
                          {
                            name: "offset",
                            options: {
                              offset: [-13, 0],
                            },
                          },
                        ]}
                        className={"right-text"}
                      />
                      <Box className={"icon-datepicker icon-datepicker-dash"}>
                        &nbsp;-&nbsp;
                      </Box>
                      <DatePicker
                        selected={financialsEndDate}
                        onChange={(date: Date) => {
                          let endDate = getDates(
                            date,
                            financialsGroupingMode
                          ).end;
                          updateFinancialsEndDate(endDate);
                          setFinancialsFilter({
                            ...financialsFilter,
                            endYear: endDate.getFullYear(),
                            endMonth: endDate.getMonth() + 1,
                          });
                        }}
                        dateFormat={getFormat()}
                        //showFullMonthYearPicker
                        //showTwoColumnMonthYearPicker
                        showMonthYearPicker={financialsGroupingMode === 0}
                        showQuarterYearPicker={financialsGroupingMode === 1}
                        showYearPicker={financialsGroupingMode === 2}
                        minDate={financialsStartDate}
                        maxDate={
                          getDates(
                            new Date(minMaxDate.maxDate),
                            financialsGroupingMode
                          ).end
                        }
                        //className={classes.datePicker}
                        //popperPlacement="auto"
                        popperModifiers={[
                          {
                            name: "preventOverflow",
                            options: {
                              altAxis: true,
                            },
                          },
                          {
                            name: "offset",
                            options: {
                              offset: [-13, 0],
                            },
                          },
                        ]}
                      />
                      <Box className={"icon-datepicker"}>
                        <UilCalender />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Box display="flex">
                  <SaveGridStateOptions
                    isSuccess={gridConfigsQuery.isSuccess}
                    gridView={gridView}
                    handleGridView={(newVal: string) => {
                      setGridView(newVal);
                    }}
                    gridConfigData={gridConfigsQuery.data}
                    gridApi={gridApi}
                    gridColumnApi={gridColumnApi}
                    gridId={gridId}
                    pageId={pageId}
                    accountId={accountId}
                    invalidateGridQuery={() =>
                      queryClient.invalidateQueries([
                        "getGridConfig",
                        pageId + gridId + accountId,
                      ])
                    }
                    updateSaveAsViewName={(name: string) => {
                      saveAsView.current = name;
                    }}
                    updateGridView={() => setGridView(GRID_CONFIG_DEFAULT_VIEW)}
                  />
                  <Button
                    variant="contained"
                    size="small"
                    className={"btn-primary"}
                    sx={{ marginTop: "21px", marginLeft: "8px" }}
                    onClick={onExport}
                  >
                    <UilExport /> Export
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              className="property-action-bar property-action-bar-sub"
            >
              <Grid item>
                <Grid container sx={{ width: "auto" }}>
                  <Grid item>
                    <Typography
                      variant="body3"
                      component="label"
                      className={"input-label"}
                    >{`Report Type`}</Typography>
                    {!!glTreeOptions && (
                      <FinancialsDropdown
                        label="Reports"
                        dropdownOptions={transformGLTreeOptions(glTreeOptions)}
                        dropdownValue={financialsGLTreeValue}
                        optionSelected={(newValue: string) => {
                          const option = glTreeOptions.find(
                            (g) => g.id === newValue
                          );
                          const newBudgetView = option?.typeName
                            ?.toLowerCase()
                            .includes(reportTypeToEnableBudgetView)
                            ? financialsBudgetView
                            : false;
                          updateFinancialsGLTreeValue(newValue);
                          updateFinancialsBudgetView(newBudgetView);
                          setFinancialsFilter({
                            ...financialsFilter,
                            budgetView: newBudgetView,
                            glTreeId: newValue,
                          });
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body3"
                      component="label"
                      className={"input-label"}
                    >{`Business Unit`}</Typography>
                    {!!businessUnitOptions && (
                      <FinancialsDropdown
                        label="Business Units"
                        dropdownOptions={transformBusinessOptions(
                          businessUnitOptions
                        )}
                        dropdownValue={financialsBusinessUnitsDDValue}
                        optionSelected={(newValue: string) => {
                          updateFinancialsBusinessUnitsDDValue(newValue);
                          setFinancialsFilter({
                            ...financialsFilter,
                            businessUnitId: newValue,
                          });
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body3"
                      component="label"
                      className={"input-label"}
                    >{`Group by`}</Typography>
                    <FinancialsDropdown
                      label="Group By"
                      dropdownOptions={[
                        { id: "0", name: "Month" },
                        { id: "1", name: "Quarter" },
                        { id: "2", name: "Year" },
                      ]}
                      dropdownValue={financialsGroupingMode.toString()}
                      optionSelected={(newValue: string) => {
                        let numberValue = parseInt(newValue);
                        let startDate = getDates(
                          new Date(
                            financialsFilter.startYear,
                            financialsFilter.startMonth - 1,
                            1
                          ),
                          numberValue
                        );
                        let endDate = getDates(
                          new Date(
                            financialsFilter.endYear,
                            financialsFilter.endMonth - 1,
                            1
                          ),
                          numberValue
                        );
                        updateFinancialsGroupingMode(numberValue);
                        updateFinancialsStartDate(startDate.start);
                        updateFinancialsEndDate(endDate.end);
                        setFinancialsFilter({
                          ...financialsFilter,
                          groupingMode: numberValue,
                          startYear: startDate.start.getFullYear(),
                          startMonth: startDate.start.getMonth() + 1,
                          endMonth: endDate.end.getMonth() + 1,
                          endYear: endDate.end.getFullYear(),
                        });
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body3"
                      component="label"
                      className={"input-label"}
                    >{`Financial Basis`}</Typography>
                    {!!accountTypeOptions && (
                      <FinancialsDropdown
                        label="Financial Basis"
                        dropdownOptions={transformAccountOptions(
                          accountTypeOptions
                        )}
                        dropdownValue={financialsAccountingType}
                        optionSelected={(newValue: string) => {
                          updateFinancialsAccountingType(newValue);
                          setFinancialsFilter({
                            ...financialsFilter,
                            accountTypeId: newValue,
                          });
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          margin: "30px 0 0 0",
                          "& svg": {
                            fontSize: "24px",
                          },
                        }}
                        control={
                          <Checkbox
                            icon={<UnCheckedIcon />}
                            checkedIcon={<CheckedIcon />}
                            checked={financialsBudgetView}
                            disabled={!enableBudgetView}
                            sx={{ padding: "0" }}
                            onChange={(event) => {
                              updateFinancialsBudgetView(event.target.checked);
                              setFinancialsFilter({
                                ...financialsFilter,
                                budgetView: event.target.checked,
                              });
                            }}
                          />
                        }
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              "& svg": {
                                width: "20px",
                                height: "20px",
                                color: "var(--primary-dark-blue)",
                              },
                            }}
                          >
                            <Typography
                              variant="body3"
                              component="label"
                              sx={{
                                paddingLeft: "3px",
                                fontSize: "15px",
                                fontWeight: 500,
                                color: !enableBudgetView
                                  ? "var(--neutral-grey-400)"
                                  : "var(--primary-dark-blue)",
                              }}
                            >
                              {"Budget View"}
                            </Typography>
                            <Tooltip
                              title="Only available when income statement is selected."
                              onClick={(e) => e.preventDefault()}
                              sx={{ paddingLeft: "4px" }}
                              placement="top"
                            >
                              <InfoOutlinedIcon />
                            </Tooltip>
                          </Box>
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div id="property-financials-content" className="tab-content">
              <FinancialsGrid
                propertyId={propertyInfo.id}
                accountIdentifier={propertyInfo.accountIdentifier}
                gridConfigsQuery={gridConfigsQuery}
                gridView={gridView}
                gridApi={gridApi}
                gridColumnApi={gridColumnApi}
                updateGridApi={(newGridApi: GridApi) => setGridApi(newGridApi)}
                updateColumnApi={(newColApi: ColumnApi) =>
                  setGridColumnApi(newColApi)
                }
                financialsFilter={financialsFilter}
                financialsTableShouldRender={
                  financialsFilter.accountTypeId !== "" &&
                  financialsFilter.glTreeId !== ""
                }
                updateMinMaxDate={(minDate: Date, maxDate: Date) => {
                  if (minMaxUpdated.current === false)
                    setMinMaxDate({
                      minDate: minDate,
                      maxDate: getMaxDate(new Date(minDate), new Date(maxDate)),
                    });
                  minMaxUpdated.current = true;
                }}
                updateFinancialsEndDate={(minDate: Date, maxDate: Date) => {
                  if (endDateUpdated.current === false) {
                    const eDate = getMaxDate(
                      new Date(minDate),
                      new Date(maxDate)
                    );
                    updateFinancialsEndDate(eDate);
                    const sDate = getMinDate(new Date(minDate), eDate);
                    updateFinancialsStartDate(sDate);
                    setFinancialsFilter({
                      ...financialsFilter,
                      endYear: eDate.getFullYear(),
                      endMonth: eDate.getMonth() + 1,
                      startYear: sDate.getFullYear(),
                      startMonth: sDate.getMonth(),
                    });
                  }
                  endDateUpdated.current = true;
                }}
                updateFinancialsFilter={(minDate: Date, maxDate: Date) => {
                  if (filterUpdated.current === false) {
                    const eDate = getMaxDate(
                      new Date(minDate),
                      new Date(maxDate)
                    );
                    const sDate = getMinDate(new Date(minDate), eDate);
                    setFinancialsFilter({
                      ...financialsFilter,
                      startYear: sDate.getFullYear(),
                      startMonth: sDate.getMonth() + 1,
                      endYear: eDate.getFullYear(),
                      endMonth: eDate.getMonth() + 1,
                    });
                  }
                  filterUpdated.current = true;
                }}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PropertyViewFinancials;