import React, { Fragment } from "react";
import configuration from "./configuration";
import {
  OidcProvider
} from "@axa-fr/react-oidc";
import { BrowserRouter as Router } from "react-router-dom";
import {
  Box,
  Typography,
} from "@mui/material";

import Layout from "./components/layout/views/layout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PortfolioProvider } from "./components/portfolio/util/portfolioContext";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

const CustomAuthenticatingComponent: React.FunctionComponent = () => {
  return (
    <div className="App">
      <Box className={"appHeader"}>
        <Box>
          <Typography variant="h1" sx={{ color: "primary.dark", }}>Please wait</Typography>
        </Box>
      </Box>
    </div>
  );
};

const SessionLostComponent: React.FunctionComponent = () => {
  window.location.href = "/AMDashboard";
  return <Fragment/>
};

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <OidcProvider
          configuration={configuration?.config}
          callbackErrorComponent={CustomAuthenticatingComponent}
          sessionLostComponent={SessionLostComponent}
          authenticatingComponent={CustomAuthenticatingComponent}
          callbackSuccessComponent={CustomAuthenticatingComponent}
          loadingComponent={CustomAuthenticatingComponent}
        >
          <PortfolioProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Layout />
            </LocalizationProvider>
          </PortfolioProvider>
        </OidcProvider>
      </QueryClientProvider>
    </Router>
  );
}

export default App;
