import { ColDef } from "ag-grid-community";
import {
  currencyFormatterNoAbbrev,
  dateFilterParams,
  dateValueFormatter,
  dateValueGetter,
} from "../../util/gridUtils";

export const getOpenTableColumns = () => {
  const columnDefs: ColDef[] = [
    {
      headerName: "Code",
      field: "code",
      sortable: true,
      // filter: true,
      filter: "agTextColumnFilter",
      minWidth: 50,
      maxWidth: 250,
    },
    {
      headerName: "Charge Type",
      sortable: true,
      valueGetter: (params) =>
        formatChargeType(
          params.data.chargeTypeCode,
          params.data.chargeTypeName
        ),
      valueFormatter: (params) =>
        formatChargeType(
          params.data.chargeTypeCode,
          params.data.chargeTypeName
        ),
      filter: "agTextColumnFilter",
      minWidth: 50,
      maxWidth: 250,
    },
    {
      headerName: "Date",
      field: "arDate",
      cellClass: "dateFormat",
      sortable: true,
      valueGetter: (params: any) => dateValueGetter(params.data.arDate),
      valueFormatter: (params) => dateValueFormatter(params.data.arDate),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 50,
      maxWidth: 200,
    },
    {
      headerName: "Description",
      field: "description",
      sortable: true,
      filter: "agTextColumnFilter",
      minWidth: 50,
      maxWidth: 250,
    },
    {
      headerName: "0 - 30 Days",
      field: "thirtyDays",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params: any) =>
        currencyFormatterNoAbbrev(params.data.thirtyDays, "$"),
      minWidth: 50,
    },
    {
      headerName: "31 - 60 Days",
      field: "sixtyDays",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      minWidth: 50,
      valueFormatter: (params: any) =>
        currencyFormatterNoAbbrev(params.data.sixtyDays, "$"),
    },
    {
      headerName: "61 - 90 Days",
      field: "ninetyDays",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      minWidth: 50,
      valueFormatter: (params: any) =>
        currencyFormatterNoAbbrev(params.data.ninetyDays, "$"),
    },
    {
      headerName: "90+ Days",
      field: "ninetyPlusDays",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      minWidth: 50,
      valueFormatter: (params: any) =>
        currencyFormatterNoAbbrev(params.data.ninetyPlusDays, "$"),
    },
    {
      headerName: "Total Balance",
      field: "totalBalance",
      sortable: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      minWidth: 50,
      valueFormatter: (params: any) =>
        currencyFormatterNoAbbrev(params.data.totalBalance, "$"),
    },
    { field: "", filter: false, sortable: false, minWidth: 50 },
  ];
  return columnDefs;
};

export const formatChargeType = (
  chargeTypeCode: string,
  chargeTypeName: string
) => {
  return chargeTypeCode !== undefined
    ? `(${chargeTypeCode}) ${chargeTypeName}`
    : "";
};
