import { useContext, useState, useMemo } from "react";
import PortfolioContext from "../../../portfolio/util/portfolioContext";
import { hasSpecificPermission } from "../../../portfolio/util/accountPermissions";
import { usePropertyInfo } from "../../util/propertyInfoContext";
import { DocumentUploadSourceEnum } from "../../document/model/documentUploadSource";
import UploadDocumentsModal from "../../rent roll/views/property rent roll generations/uploadDocumentsModal";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { UilUpload } from "@iconscout/react-unicons";
import {
  useDeleteDocument,
  useGetDocumentList,
} from "../../document/api/documentService";
import ConfirmDialog from "../../../../shared/view/confirmationDialog";
import { AgGridReact } from "ag-grid-react";
import { TDeleteDocumentModel } from "../../document/model/deleteDocumentModel";
import { GridReadyEvent, ColDef } from "ag-grid-community";
import DocumentActionsRenderer from "../../../documents/DocumentActionsRenderer";
import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { IUAAlertMessage } from "../../../usermanagement/util/userContextModels";
import SimpleAlert from "../../../UI/view/SimpleAlert";
import { format, parseISO } from "date-fns";
import { formatFileSize } from "../../../documents/util/format";
import { EAccountPermission } from "../../../usermanagement/model/editUserModel";
import { stringSortCaseInsensitive } from "../../util/gridUtils";

export const documentUploadSuccessMessage =
  "Your document was successfully uploaded.";
export const documentUploadErrorMessage =
  "Could not upload the document. Please try again later.";

const PropertyViewOverviewDocuments: React.FC = () => {
  const propertyInfo = usePropertyInfo();

  const { accountPermissions } = useContext(PortfolioContext);
  const [openUpload, setOpenUpload] = useState(false);
  const [deleteDocument, setDeleteDocument] = useState({
    documentId: "",
    description: "",
  });
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const hasDeleteUploadAccess = useMemo(
    () =>
      hasSpecificPermission(
        accountPermissions,
        propertyInfo.accountIdentifier,
        EAccountPermission.DOCUMENTS_UPLOAD_AND_DELETE
      ),
    [accountPermissions, propertyInfo.accountIdentifier]
  );

  const documents = useGetDocumentList({
    accountIdentifier: propertyInfo.accountIdentifier,
    propertyId: propertyInfo.id,
    documentSource: DocumentUploadSourceEnum.PropertyOverview,
  });
  const deleteDocumentAction = useDeleteDocument();
  const queryClient = useQueryClient();

  const defaultAlertMessage: IUAAlertMessage = {
    severityType: "success",
    message: "",
  };
  const [alertMessage, setAlertMessage] =
    useState<IUAAlertMessage>(defaultAlertMessage);

  const handleDeleteDocumentClicked = (
    documentId: string,
    description: string
  ) => {
    setDeleteDocument({ documentId, description });
    setConfirmOpen(true);
  };

  const columnDefs = React.useMemo(() => {
    const defs: ColDef[] = [
      {
        headerName: "Title",
        field: "displayName",
        comparator: stringSortCaseInsensitive,
        lockPosition: true,
      },
      {
        headerName: "Group",
        field: "labels",
        comparator: stringSortCaseInsensitive,
        lockPosition: true,
      },
      {
        headerName: "Type",
        field: "documentType",
        comparator: stringSortCaseInsensitive,
        lockPosition: true,
      },
      {
        headerName: "Upload Date",
        field: "createdAt",
        lockPosition: true,
        valueFormatter: (params) => {
          let parsedDate = parseISO(params.data.createdAt);
          return format(parsedDate, "MM/dd/yyyy");
        },
      },
      {
        headerName: "Uploaded By",
        field: "userFullName",
        comparator: stringSortCaseInsensitive,
        lockPosition: true,
        flex: 1,
      },
      {
        headerName: "Size",
        field: "fileSize",
        lockPosition: true,
        valueFormatter: (params) => {
          let parsedSize = Number(params.data.fileSize);
          return formatFileSize(parsedSize);
        },
      },
      {
        headerName: "",
        field: "id",
        cellRenderer: "documentActionsRenderer",
        lockPosition: true,
        cellRendererParams: {
          accountIdentifier: propertyInfo.accountIdentifier,
          hasDeleteDocAccess: hasDeleteUploadAccess,
          clicked: (id: string, desc: string) => {
            handleDeleteDocumentClicked(id, desc);
          },
        },
        sortable: false,
        filter: false,
        flex: 0.3,
        minWidth: 120,
        maxWidth: 120,
      },
    ];

    return defs;
  }, [propertyInfo.accountIdentifier, hasDeleteUploadAccess]);

  const onGridReady = (event: GridReadyEvent) => {
    if (documents.data === undefined || documents.data.length === 0) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }
  };

  const invalidateListCache = () => {
    queryClient.invalidateQueries([
      "getDocumentList",
      {
        accountIdentifier: propertyInfo.accountIdentifier,
        propertyId: propertyInfo.id,
      },
    ]);
  };

  const onUploaded = () => {
    invalidateListCache();
    setAlertMessage({
      severityType: "success",
      message: documentUploadSuccessMessage,
    });
  };

  const deleteDocumentEvent = () => {
    const model: TDeleteDocumentModel = {
      accountIdentifier: propertyInfo.accountIdentifier,
      documentId: deleteDocument.documentId,
    };

    deleteDocumentAction.mutate(model, {
      onSuccess: () => {
        invalidateListCache();
        setAlertMessage({
          severityType: "success",
          message: "Document was successfully deleted.",
        });
      },
      onError: () => {
        setAlertMessage({
          severityType: "error",
          message: "Could not delete the document. Please try again later.",
        });
      },
    });
  };

  return documents.isFetching ? (
    <Box textAlign="center">
      <CircularProgress />
    </Box>
  ) : (
    <Box className="mainContainer">
      {openUpload && (
        <UploadDocumentsModal
          handleClose={() => setOpenUpload(false)}
          open={openUpload}
          uploadSource={DocumentUploadSourceEnum.PropertyOverview}
          onUploaded={onUploaded}
          onError={() => {
            setAlertMessage({
              severityType: "error",
              message: documentUploadErrorMessage,
            });
          }}
        />
      )}
      {alertMessage.message !== "" && (
        <SimpleAlert
          severityType={alertMessage.severityType}
          message={alertMessage.message}
          onClose={() => setAlertMessage(defaultAlertMessage)}
          alertStyles={{ width: "100%" }}
          styles={{ position: "inherit", width: "100%" }}
        />
      )}
      {hasDeleteUploadAccess && (
        <Grid
          container
          className="property-action-bar"
          sx={{ backgroundColor: "var(--neutral-white) !important" }}
        >
          <Grid item></Grid>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              className={"btn-primary"}
              onClick={() => setOpenUpload(true)}
            >
              <UilUpload /> Upload
            </Button>
          </Grid>
        </Grid>
      )}
      <div
        id="document-main-content"
        className="example-wrapper"
        style={{ width: "100%" }}
      >
        <div
          id="documentGrid"
          className="ag-theme-alpine ag-grid-document"
          style={{ width: "100%", flex: "1 1" }}
        >
          <div>
            <ConfirmDialog
              title="Delete Document?"
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={deleteDocumentEvent}
            >
              Are you sure you want to delete '{deleteDocument.description}
              '?
            </ConfirmDialog>
          </div>
          <div style={{ height: "260px" }}>
            <AgGridReact
              onGridReady={onGridReady}
              rowData={documents.data}
              columnDefs={columnDefs}
              components={{
                documentActionsRenderer: DocumentActionsRenderer,
              }}
              sortingOrder={["desc", "asc"]}
              defaultColDef={{
                filter: true,
                resizable: true,
                sortable: true,
                filterParams: {
                  buttons: ["apply", "reset"],
                  closeOnApply: true,
                },
              }}
            />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default PropertyViewOverviewDocuments;
