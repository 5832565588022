import React, { Fragment, useCallback, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { usePropertyInfo } from "../../util/propertyInfoContext";
import TBuildingsSearchFilter from "../../building/model/buildingListSearchFilter";
import { TSpaceListFilter } from "../model/spaceListFilter";
import PropertyViewSpaceList from "./propertyViewSpaceList";
import PropertyViewSpacesSearch from "./propertyViewSpacesSearch";
import { useGetLeaseList, useGetSpaceList } from "../api/spaceService";
import { useGetBuildingList } from "../../building/api/buildingService";
import SpaceInsert from "./propertyViewSpaceInsert";
import TLeasesByBuildingFilter from "../model/leaseListByPropertyFilter";

const PropertyViewSpaces: React.FunctionComponent = (props) => {
  const queryClient = useQueryClient();
  const propertyInfo = usePropertyInfo();

  const [showInsertPopup, setShowInsertPopup] = useState(false);
  const [filter, setFilter] = useState<TSpaceListFilter>({
    propertyId: propertyInfo.id,
    value: "",
    accountIdentifier: propertyInfo.accountIdentifier,
  });
  const [buildingFilter] = useState<TBuildingsSearchFilter>({
    PropertyId: propertyInfo.id,
    accountIdentifier: propertyInfo.accountIdentifier,
  });
  const [leaseFilter] = useState<TLeasesByBuildingFilter>({
    PropertyId: propertyInfo.id,
    accountIdentifier: propertyInfo.accountIdentifier,
  });

  const handlePopupOpen = useCallback(() => {
    setShowInsertPopup(true);
  }, []);

  const handlePopupClose = useCallback(
    (shouldRefreshData: boolean) => {
      setShowInsertPopup(false);
      shouldRefreshData && queryClient.invalidateQueries(["getSpaceList"]);
    },
    [queryClient]
  );

  const setSpaceListSearchFilter = useCallback(
    (value: string) => {
      setFilter({
        propertyId: propertyInfo.id,
        value: value,
        accountIdentifier: propertyInfo.accountIdentifier,
      });
    },
    [propertyInfo]
  );

  const spaceList = useGetSpaceList(filter);
  const buildings = useGetBuildingList(buildingFilter);
  const leases = useGetLeaseList(leaseFilter);
  return (
    <Fragment>
      <div
        id="property-spaces"
        className="tab-pane fade show active"
        role="tabpanel"
        aria-labelledby="property-tab-spaces"
      >
        <PropertyViewSpacesSearch
          setFilter={setSpaceListSearchFilter}
          handlePopupOpen={handlePopupOpen}
          buildings={buildings}
        />

        <PropertyViewSpaceList spaceList={spaceList} />

        {showInsertPopup && (
          <SpaceInsert
            leases={leases}
            buildings={buildings}
            handlePopupClose={handlePopupClose}
            showInsertPopup={showInsertPopup}
          />
        )}
      </div>
    </Fragment>
  );
};
export default PropertyViewSpaces;
