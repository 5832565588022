import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuthorizedApiAxios } from "../../../shared/api/authorizedAxios";
import { DatabaseMigration, MigrationDatabase } from "../model/databaseMigration";
import { DatabaseStatus } from "../model/databaseStatus";

export const useGetDatabaseStatus = (service: string) => {
    const authorizedApiAxios = useAuthorizedApiAxios();
    return useQuery(
      ["databaseStatus", service],
      () => {
        const source = axios.CancelToken.source();
        const url = `/${service}/v1/Database`;
  
        let promise = authorizedApiAxios().get<DatabaseStatus>(url, {
          cancelToken: source.token,
        });
  
        (promise as any).cancel = () => source.cancel();
        return promise;
      },
      {
        select: (response) => {
          return response!.data;
        },
      }
    );
  };

  export const useMigrateDatabase = () => {
    const authorizedApiAxios = useAuthorizedApiAxios();
  
    return useMutation((item: MigrationDatabase) => {
      const source = axios.CancelToken.source();
  
      const url = `/${item.service}/v1/Database`;
      let promise = authorizedApiAxios().post<DatabaseMigration>(url, item, {
        cancelToken: source.token,
      });
  
      (promise as any).cancel = () => source.cancel();
      return promise;
    });
  };