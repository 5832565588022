import { IAccountPermission } from "../../layout/views/AccountRoles";
import { EAccountPermission } from "../../usermanagement/model/editUserModel";

export const hasSpecificPermission = (
  accountPermissions: Map<string, IAccountPermission>,
  accId: string,
  type: EAccountPermission
) => {
  let hasPermissions = false;
  for (const [permissionAccId, permissions] of accountPermissions.entries()) {
    if (permissionAccId === accId) {
      if (permissions[EAccountPermission.ADMINISTRATION] || permissions[type])
        hasPermissions = true;
    }
  }

  return hasPermissions;
};
