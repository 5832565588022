import {
  AccountIdentifierHeaderName,
  useAuthorizedApiAxios,
} from "../../../../shared/api/authorizedAxios";
import { StackingPlan } from "../model/stackingPlan";
import { useQuery } from "@tanstack/react-query";

export const useGetStackingPlan = (
  propertyId: string,
  accountIdentifier: string,
  year: number | undefined,
  month: number | undefined
) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["getStackingPlan", year, month, accountIdentifier],
    ({ signal }) => {
      const url: string = `/AssetService/v1/Properties/${propertyId}/StackingPlan?year=${year}&month=${month}`;

      return authorizedApiAxios().get<StackingPlan>(url, {
        signal,
        headers: {
          [AccountIdentifierHeaderName]: accountIdentifier,
        },
      });
    },
    {
      select: (response) => response!.data,
      enabled: month !== undefined && year !== undefined,
    }
  );
};
