import React, {
  forwardRef,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { UilExport } from "@iconscout/react-unicons";
import { GridApi, ColumnApi, ExcelExportParams } from "ag-grid-community";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";

import {
  IPortfolioRentRoll,
  IPortfolioRentRollOther,
} from "../model/getPortfolioRR";
import { EPortfolioRRView } from "../model/portfolioRREnums";
import PortfolioRRLeaseGrid from "./PortfolioRRLeaseGrid";
import PortfolioRROtherGrid from "./PortfolioRROtherGrid";
import {
  GridConfigIds,
  PageConfigIds,
} from "../../../../constants/GridPageConfigurationIds";
import { usePortfolioInfo } from "../../util/portfolioInfoContext";
import { useGetGridConfig } from "../../../../shared/api/pageConfigService";
import {
  GridConfig,
  GridConfiguration,
} from "../../../../shared/model/gridConfig";
import SaveGridStateOptions, {
  GRID_CONFIG_DEFAULT_VIEW,
} from "../../../../shared/view/SaveGridStateOptions";
import DatePicker from "react-datepicker";
import { UilCalender } from "@iconscout/react-unicons";
import {
  getMinDate,
  getMaxDate,
} from "../../../properties/rent roll/util/rentRollCalendar";
import PerspectiveDropdown from "../../../properties/financials/views/FinancialsDropdown";

interface PortfolioRRGridsProps {
  date: Date;
  updateDate: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  leaseRentRoll: IPortfolioRentRoll[];
  otherRentRoll: IPortfolioRentRollOther[];
  updateAssetSummary: (summary: number[]) => void;
  updateFilteredAssetSummary: (summary: number[]) => void;
  value?: string;
  onClick?: () => void;
}

const PortfolioRRGrids: React.FC<PortfolioRRGridsProps> = (props) => {
  const portfolioInfo = usePortfolioInfo();

  const [tab, setTab] = useState<EPortfolioRRView>(EPortfolioRRView.LEASE_VIEW);

  const isLeaseView = tab === EPortfolioRRView.LEASE_VIEW;

  const [leaseGridApi, setLeaseGridApi] = useState<GridApi | undefined>(
    undefined
  );
  const [leaseGridColApi, setLeaseGridColApi] = useState<ColumnApi | undefined>(
    undefined
  );
  const [otherLeasesGridApi, setOtherLeasesGridApi] = useState<
    GridApi | undefined
  >(undefined);
  const [otherLeasesGridColApi, setOtherLeasesGridColApi] = useState<
    ColumnApi | undefined
  >(undefined);

  const onLeasesExport = () => {
    if (leaseGridApi === undefined) return;

    const params: ExcelExportParams = {
      sheetName: "Lease view",
      exportMode: undefined,
      suppressTextAsCDATA: false,
      rowHeight: undefined,
      headerRowHeight: undefined,
      fileName: "Portfolios - Rent Roll - Lease View",
    };
    leaseGridApi.exportDataAsExcel(params);
  };

  const onOtherLeasesExport = () => {
    if (otherLeasesGridApi === undefined) return;

    const params: ExcelExportParams = {
      sheetName: "Other Leases",
      exportMode: undefined,
      suppressTextAsCDATA: false,
      rowHeight: undefined,
      headerRowHeight: undefined,
      fileName: "Portfolios - Rent Roll - Other Leases",
    };
    otherLeasesGridApi.exportDataAsExcel(params);
  };

  // Grid Config state and methods

  const [gridView, setGridView] = useState(GRID_CONFIG_DEFAULT_VIEW);

  const pageId = PageConfigIds.PORTFOLIO_RENT_ROLL_GRID;
  const gridRrLeasesId = GridConfigIds.PORTFOLIO_RR_LEASES_GRID;
  const gridRrOtherLeasesId = GridConfigIds.PORTFOLIO_RR_OTHER_LEASES_GRID;

  const accountId = portfolioInfo.accountIdentifier;

  const queryClient = useQueryClient();

  const leasesGridConfigsQuery = useGetGridConfig({
    pageId: pageId,
    gridIdentifier: gridRrLeasesId,
    accountId: accountId,
  });

  const otherLeasesGridConfigsQuery = useGetGridConfig({
    pageId: pageId,
    gridIdentifier: gridRrOtherLeasesId,
    accountId: accountId,
  });

  const updateGridView = (newView: EPortfolioRRView) => {
    if (newView === tab) return;
    setTab(newView);

    let defaultView: GridConfiguration | undefined;
    if (newView === EPortfolioRRView.LEASE_VIEW) {
      defaultView = leasesGridConfigsQuery.data?.gridConfigurations.find(
        (gridConfig) => gridConfig.isDefault
      );
      setOtherLeasesGridApi(undefined);
      setOtherLeasesGridColApi(undefined);
    } else {
      defaultView = otherLeasesGridConfigsQuery.data?.gridConfigurations.find(
        (gridConfig) => gridConfig.isDefault
      );
      setLeaseGridApi(undefined);
      setLeaseGridColApi(undefined);
    }
    if (!defaultView || gridView === defaultView?.id.toString()) return;

    setGridView(defaultView.id.toString());
  };

  let gridConfigsQuery: UseQueryResult<GridConfig, unknown>;
  let gridApi: GridApi | undefined;
  let gridColumnApi: ColumnApi | undefined;
  let gridId: GridConfigIds;

  switch (tab) {
    case EPortfolioRRView.LEASE_VIEW:
      gridConfigsQuery = leasesGridConfigsQuery;
      gridApi = leaseGridApi;
      gridColumnApi = leaseGridColApi;
      gridId = gridRrLeasesId;
      break;
    case EPortfolioRRView.OTHER_LEASES:
      gridConfigsQuery = otherLeasesGridConfigsQuery;
      gridApi = otherLeasesGridApi;
      gridColumnApi = otherLeasesGridColApi;
      gridId = gridRrOtherLeasesId;
      break;
    default:
      gridConfigsQuery = leasesGridConfigsQuery;
      gridApi = leaseGridApi;
      gridColumnApi = leaseGridColApi;
      gridId = gridRrLeasesId;
      break;
  }

  /**
   * Sets the context that is retrieved from the backend whenever the gridView is changed.
   * gridView is either changed when data first loads (only if a default value exists) or when the user uses the dropdown
   */
  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1 ||
      gridApi === undefined ||
      gridColumnApi === undefined
    )
      return;

    if (gridView === GRID_CONFIG_DEFAULT_VIEW) {
      gridApi.setFilterModel({});
      gridColumnApi.resetColumnState();
      return;
    }

    const selectedGridConfig = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.id === Number(gridView)
    );
    if (selectedGridConfig === undefined) return;

    gridApi.setFilterModel(JSON.parse(selectedGridConfig.columnFilterState));
    gridColumnApi.applyColumnState({
      state: JSON.parse(selectedGridConfig.columnState),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gridView,
    leaseGridApi,
    leaseGridColApi,
    otherLeasesGridApi,
    otherLeasesGridColApi,
  ]);

  const saveAsView = useRef({
    name: "",
    type: EPortfolioRRView.LEASE_VIEW,
  });

  useEffect(() => {
    if (
      !leasesGridConfigsQuery.isSuccess ||
      !leasesGridConfigsQuery.data ||
      leasesGridConfigsQuery.data.gridConfigurations.length < 1 ||
      tab !== EPortfolioRRView.LEASE_VIEW
    ) {
      if (gridView !== GRID_CONFIG_DEFAULT_VIEW)
        setGridView(GRID_CONFIG_DEFAULT_VIEW);
      return;
    }

    const isSaveAsRender =
      saveAsView.current.name.trim().length > 0 &&
      saveAsView.current.type === EPortfolioRRView.LEASE_VIEW;

    if (isSaveAsRender) {
      const savedAsView = leasesGridConfigsQuery.data.gridConfigurations.find(
        (gridConfig) =>
          gridConfig.name.toLowerCase().trim() ===
          saveAsView.current.name.toLowerCase().trim()
      );
      if (savedAsView) setGridView(savedAsView.id.toString());
      saveAsView.current = { name: "", type: EPortfolioRRView.LEASE_VIEW };
      return;
    }

    const defaultView = leasesGridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.isDefault
    );
    if (
      defaultView &&
      gridView !== defaultView.id.toString() &&
      gridView === GRID_CONFIG_DEFAULT_VIEW
    )
      setGridView(defaultView.id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leasesGridConfigsQuery.isSuccess, leasesGridConfigsQuery.data]);

  useEffect(() => {
    if (
      !otherLeasesGridConfigsQuery.isSuccess ||
      !otherLeasesGridConfigsQuery.data ||
      otherLeasesGridConfigsQuery.data.gridConfigurations.length < 1 ||
      tab !== EPortfolioRRView.OTHER_LEASES
    ) {
      if (gridView !== GRID_CONFIG_DEFAULT_VIEW)
        setGridView(GRID_CONFIG_DEFAULT_VIEW);
      return;
    }

    const isSaveAsRender =
      saveAsView.current.name.trim().length > 0 &&
      saveAsView.current.type === EPortfolioRRView.OTHER_LEASES;

    if (isSaveAsRender) {
      const savedAsView =
        otherLeasesGridConfigsQuery.data.gridConfigurations.find(
          (gridConfig) =>
            gridConfig.name.toLowerCase().trim() ===
            saveAsView.current.name.toLowerCase().trim()
        );
      if (savedAsView) setGridView(savedAsView.id.toString());
      saveAsView.current = { name: "", type: EPortfolioRRView.OTHER_LEASES };
      return;
    }

    const defaultView =
      otherLeasesGridConfigsQuery.data.gridConfigurations.find(
        (gridConfig) => gridConfig.isDefault
      );
    if (
      defaultView &&
      gridView !== defaultView.id.toString() &&
      gridView === GRID_CONFIG_DEFAULT_VIEW
    )
      setGridView(defaultView.id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherLeasesGridConfigsQuery.isSuccess, otherLeasesGridConfigsQuery.data]);

  useEffect(() => {
    if (
      !leasesGridConfigsQuery.isSuccess ||
      !leasesGridConfigsQuery.data ||
      leasesGridConfigsQuery.data.gridConfigurations.length < 1
    )
      return;

    const defaultView = leasesGridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.isDefault
    );
    if (
      defaultView &&
      gridView !== defaultView.id.toString() &&
      gridView === GRID_CONFIG_DEFAULT_VIEW
    )
      setGridView(defaultView.id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leasesGridConfigsQuery.isSuccess, leasesGridConfigsQuery.data]);

  const leaseOnGridReady = useCallback(
    (gridApi?: GridApi, gridColumnApi?: ColumnApi) => {
      setLeaseGridApi(gridApi);
      setLeaseGridColApi(gridColumnApi);
    },
    []
  );

  const otherLeasesOnGridReady = useCallback(
    (gridApi?: GridApi, gridColumnApi?: ColumnApi) => {
      setOtherLeasesGridApi(gridApi);
      setOtherLeasesGridColApi(gridColumnApi);
    },
    []
  );

  type PortfolioRRGridsProps = {
    onClick?: () => void;
    value?: string;
  };
  type RefType = number;
  const CustomDatePickerInput = forwardRef<RefType, PortfolioRRGridsProps>(
    ({ onClick, value }, ref) => (
      <Box className={"react-datepicker-input"} ref={ref} onClick={onClick}>
        {value}
        <Box className={"react-datepicker-icon"}>
          <UilCalender />
        </Box>
      </Box>
    )
  );

  const [perspective, setPerspective] = useState(0);

  return (
    <React.Fragment>
      <Grid container className="property-action-bar">
        <Grid item>
          <Grid container sx={{ width: "auto" }}>
            <Grid item>
              <Box className={"datepicker-outer-container"}>
                <Typography
                  variant="body3"
                  component="label"
                  className={"input-label"}
                >{`Show tenants for month:`}</Typography>
                <Box className={"datepicker-container"}>
                  {props.minDate !== undefined && props.maxDate !== undefined && (
                    <DatePicker
                      selected={props.date}
                      onChange={(date: Date) => props.updateDate(date)}
                      dateFormat="MMMM yyyy"
                      showMonthYearPicker
                      //showFullMonthYearPicker
                      //showTwoColumnMonthYearPicker
                      minDate={getMinDate(props.minDate)}
                      maxDate={getMaxDate(props.maxDate)}
                      //popperPlacement="auto"
                      popperModifiers={[
                        {
                          name: "preventOverflow",
                          options: {
                            altAxis: true,
                          },
                        },
                        {
                          name: "offset",
                          options: {
                            offset: [-13, 0],
                          },
                        },
                      ]}
                      customInput={<CustomDatePickerInput {...props} />}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Typography
                variant="body3"
                component="label"
                className={"input-label"}
              >{`Perspective`}</Typography>
              <PerspectiveDropdown
                label="Group By"
                dropdownOptions={[
                  { id: "0", name: EPortfolioRRView.LEASE_VIEW },
                  { id: "1", name: EPortfolioRRView.OTHER_LEASES },
                ]}
                dropdownValue={perspective.toString()}
                optionSelected={(newValue: string) => {
                  if (parseInt(newValue) === 0)
                    updateGridView(EPortfolioRRView.LEASE_VIEW);
                  else updateGridView(EPortfolioRRView.OTHER_LEASES);
                  setPerspective(parseInt(newValue));
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container sx={{ width: "auto" }}>
            <Grid item>
              <SaveGridStateOptions
                isSuccess={gridConfigsQuery.isSuccess}
                gridView={gridView}
                handleGridView={(newVal: string) => setGridView(newVal)}
                gridConfigData={gridConfigsQuery.data}
                gridApi={gridApi}
                gridColumnApi={gridColumnApi}
                gridId={gridId}
                pageId={pageId}
                accountId={accountId}
                invalidateGridQuery={() =>
                  queryClient.invalidateQueries([
                    "getGridConfig",
                    pageId + gridId + accountId,
                  ])
                }
                updateSaveAsViewName={(name: string) => {
                  saveAsView.current = { name: name, type: tab };
                }}
                updateGridView={() => setGridView(GRID_CONFIG_DEFAULT_VIEW)}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                className={"btn-primary"}
                sx={{ marginTop: "21px" }}
                onClick={() => {
                  if (isLeaseView) onLeasesExport();
                  if (!isLeaseView) onOtherLeasesExport();
                }}
              >
                <UilExport /> Export
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div id="portfolio-rentroll-content" className="tab-content">
        <div className="standard-content">
          {/* Portfolio Grids */}
          <div
            className="table-standard-toggle-container"
            style={{ width: "100%" }}
          >
            {isLeaseView ? (
              <PortfolioRRLeaseGrid
                rowData={props.leaseRentRoll}
                gridApi={leaseGridApi}
                gridColumnApi={leaseGridColApi}
                onGridRender={leaseOnGridReady}
                updateAssetSummary={props.updateAssetSummary}
                updateFilteredAssetSummary={props.updateFilteredAssetSummary}
              />
            ) : (
              <PortfolioRROtherGrid
                rowData={props.otherRentRoll}
                gridApi={otherLeasesGridApi}
                gridColumnApi={otherLeasesGridColApi}
                onGridRender={otherLeasesOnGridReady}
                updateAssetSummary={props.updateAssetSummary}
                updateFilteredAssetSummary={props.updateFilteredAssetSummary}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PortfolioRRGrids;
