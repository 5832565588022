import React, {
  useState,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import {
  Box,
} from "@mui/material";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ICellRendererParams } from "ag-grid-community";
import { formatMonth, getMonthByIndex } from "../util/formatDate";
import { GridRowList } from "../util/constant";

export const CustomEditorComponent = forwardRef((props: ICellRendererParams, ref) => {
  const [editing, setEditing] = useState(true);

  const [settings, setSettings] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const getDate = (date: string) => {
    if (date !== null && date !== undefined) {
      const dates = date.split(' - ');
      const s = formatMonth(dates[0].split(" ")[0])+ " " + dates[0].split(" ")[1];
      const e = formatMonth(dates[1].split(" ")[0]) + " " + dates[1].split(" ")[1];
      return {start: s, end: e}
    } else {
      return null;
    }
  }
  useEffect(() => {
    if (!editing) {
      props.api.stopEditing();
    }
    if (props.value !== "" && props.value !== null) {
      if (props.data.report === GridRowList.ACCOUNTS_RECEIVABLE ||
        props.data.report === GridRowList.RENT_ROLL_LEASE_VIEW ||
        props.data.report === GridRowList.RENT_ROLL_SUITE_VIEW ||
        props.data.report === GridRowList.LEASING_ACTIVITY ||
        props.data.report === GridRowList.STACKING_PLAN ||
        props.data.report === GridRowList.LOANS ||
        props.data.report === GridRowList.PROPERTY_SNAPSHOT)
      {
        const dates = props.value?.split(" ");
        if (dates && dates.length > 1) {
          const s = new Date(dates[1], getMonthByIndex(dates[0]));
          setSettings({
            ...settings,
            startDate: s,
          })
        }
      } else if (props.data.report === GridRowList.FINANCIALS_BALANCE_SHEET || props.data.report === GridRowList.FINANCIALS_INCOME_STATEMENT) {
        const dates = props.value?.split(" - ");
        if (dates && dates.length > 1) {
          const first = dates[0].split(" ");
          const second = dates[1].split(" ");
          const s = new Date(first[1], getMonthByIndex(first[0]));
          const e = new Date(second[1], getMonthByIndex(second[0]));
          setSettings({
            ...settings,
            startDate: s,
            endDate: e,
          })
        }
      } else {}
    }
  }, [editing, props]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (props.data.report === GridRowList.ACCOUNTS_RECEIVABLE ||
        props.data.report === GridRowList.RENT_ROLL_LEASE_VIEW ||
        props.data.report === GridRowList.RENT_ROLL_SUITE_VIEW ||
        props.data.report === GridRowList.LEASING_ACTIVITY ||
        props.data.report === GridRowList.STACKING_PLAN ||
        props.data.report === GridRowList.LOANS ||
        props.data.report === GridRowList.PROPERTY_SNAPSHOT)
        {
          const date: string = settings.startDate?.getMonth() + " " + settings.startDate?.getFullYear();
          const dates = date.split(' ');
          const s = formatMonth(dates[0]) + " " + dates[1];
          if (s !== null) return s;
          else return "";
        } else if (props.data.report === GridRowList.FINANCIALS_BALANCE_SHEET || props.data.report === GridRowList.FINANCIALS_INCOME_STATEMENT) {
          const date: string = settings.startDate?.getMonth() + " " + settings.startDate?.getFullYear() + " - " + settings.endDate?.getMonth() + " " + settings.endDate?.getFullYear();
          const data = getDate(date);
          if (data !== null) return data.start + " - " + data.end;
          else return "";
        }
      }
    };
  });

  return (
    <>
      {
        (props.data.report === GridRowList.ACCOUNTS_RECEIVABLE ||
        props.data.report === GridRowList.RENT_ROLL_LEASE_VIEW ||
        props.data.report === GridRowList.RENT_ROLL_SUITE_VIEW ||
        props.data.report === GridRowList.LEASING_ACTIVITY ||
        props.data.report === GridRowList.STACKING_PLAN ||
        props.data.report === GridRowList.LOANS || 
        props.data.report === GridRowList.PROPERTY_SNAPSHOT) &&
        (
          <Box className={"datepicker-container daterange-container"}>
            <DatePicker
              selected={settings.startDate}
              maxDate={new Date()}
              onChange={(date: Date) => {
                setSettings({
                  ...settings,
                  startDate: date,
                })
                setEditing(false)
              }}
              dateFormat="MMM yyyy"
              showMonthYearPicker
              // showFullMonthYearPicker
              showTwoColumnMonthYearPicker={true}
              popperPlacement={
                // (props.rowIndex === 0 || props.rowIndex === 1) ? "right-start" :
                (props.rowIndex === 2 || props.rowIndex === 3 || props.rowIndex === 4) ? "auto" : "bottom-start"}
                popperModifiers={[
                  {
                    name: "preventOverflow",
                    options: {
                      altAxis: true,
                    },
                  },
                  {
                    name: "offset",
                    options: {
                      offset: [-13, 0],
                    },
                  },
                ]}
            />
          </Box>
        )
      }
      {
        (props.data.report === GridRowList.FINANCIALS_BALANCE_SHEET || props.data.report === GridRowList.FINANCIALS_INCOME_STATEMENT) && (
          <Box className={"datepicker-container daterange-container"}>
            <DatePicker
              selected={settings.startDate}
              maxDate={new Date()}
              onChange={(date: Date) => {
                setSettings({
                  ...settings,
                  startDate: date,
                })
                setEditing(false)
              }}
              dateFormat="MMM yyyy"
              showMonthYearPicker
              // showFullMonthYearPicker
              showTwoColumnMonthYearPicker
              selectsEnd
              popperPlacement={
                (props.data.report === GridRowList.FINANCIALS_BALANCE_SHEET) ? "right" : "bottom-start"}
                popperModifiers={[
                  {
                    name: "preventOverflow",
                    options: {
                      altAxis: true,
                    },
                  },
                  {
                    name: "offset",
                    options: {
                      offset: [-13, 0],
                    },
                  },
                ]}
                className={"right-text"}
            />
            <Box className={"icon-datepicker icon-datepicker-dash"}>
              &nbsp;-&nbsp;
            </Box>
            <DatePicker
              selected={settings.endDate}
              maxDate={new Date()}
              minDate={settings.startDate}
              onChange={(date: Date) => {
                setSettings({
                  ...settings,
                  endDate: date,
                })
                setEditing(false)
              }}
              dateFormat="MMM yyyy"
              showMonthYearPicker
              // showFullMonthYearPicker
              showTwoColumnMonthYearPicker
              popperPlacement={
                (props.data.report === GridRowList.FINANCIALS_BALANCE_SHEET) ? "right" : "bottom-start"}
                popperModifiers={[
                  {
                    name: "preventOverflow",
                    options: {
                      altAxis: true,
                    },
                  },
                  {
                    name: "offset",
                    options: {
                      offset: [-13, 0],
                    },
                  },
                ]}
            />
          </Box>
        )
      }
    </>
  );
});

export default CustomEditorComponent;
