import React, { useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

import {
  useGetUsersPropertyPermissions,
  useUpdateUsersPropertyPermissions,
} from "../../api/userAssignment";
import UserContext from "../../util/userContext";
import GroupSearch from "../GroupSearch";
import {
  IPutUserPropertyAssignment,
  IUserPropertyAssignment,
} from "./models/userPropertyAssignment";
import UserPropAssignGrid from "./UserPropAssignGrid";
import SimpleAlert, { AlertSeverityType } from "../../../UI/view/SimpleAlert";
import {
  AllUsersChecked,
  RenderedRow,
} from "./models/userPropertyAssignmentContext";
import { UPAContext } from "./UserPropertyAssignmentContext";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

interface Props {
  setFilter: (accountIdentifier: string, value: string) => void;
}

const UserPropertyAssignment: React.FC<Props> = (props) => {
  const { userAccountDDValue } = React.useContext(UserContext);

  const [mutationStatus, setMutationStatus] = useState<
    "loading" | "success" | "error" | "idle"
  >("idle");

  const queryClient = useQueryClient();
  const usersPropertyPerms = useGetUsersPropertyPermissions(userAccountDDValue);
  const updateUsersPropertyPerms =
    useUpdateUsersPropertyPermissions(userAccountDDValue);

  React.useEffect(() => {
    const status = updateUsersPropertyPerms.status;
    if (status === "idle" || status === mutationStatus) return;

    if (status === "loading") setMutationStatus("loading");
    else
      setTimeout(() => {
        setMutationStatus(status);
      }, 1000);
  }, [updateUsersPropertyPerms.status, mutationStatus]);

  const [allUsersChecked, setAllUsersChecked] = useState<AllUsersChecked>({
    checked: false,
    touched: false,
  });

  const [renderedRows, setRenderedRows] = useState<RenderedRow[]>([]);

  const updateRenderedRows = (newRenderedRows: RenderedRow[]) => {
    setRenderedRows(newRenderedRows);
  };

  const updateAllUsersChecked = (checked: boolean) => {
    setAllUsersChecked({
      checked: checked,
      touched: true,
    });
  };

  const setGroupFilter = React.useCallback((accountIdentifier: string) => {
    props.setFilter(accountIdentifier, "");
  }, [props]);

  const {
    data: userProperties,
    isSuccess: userPropertiesLoaded,
    isLoading,
  } = usersPropertyPerms;

  const [editedUserProperties, setEditedUserProperties] = useState<
    IUserPropertyAssignment[]
  >([]);

  const updateUserProperties = (userProperty: IUserPropertyAssignment) => {
    setEditedUserProperties((prevUserProps) => {
      let updatedEditedUsers: IUserPropertyAssignment[] = [];

      // creating a new copy
      prevUserProps.forEach((userProp) => {
        updatedEditedUsers.push({ ...userProp });
      });
      const prevUserPropIndex = updatedEditedUsers.findIndex(
        (prevUserProperty) =>
          prevUserProperty.propertyId === userProperty.propertyId &&
          prevUserProperty.userId === userProperty.userId
      );
      // If the userProperty already exists. In this case, delete it from the existing list.
      if (prevUserPropIndex > -1) {
        updatedEditedUsers[prevUserPropIndex].grantAccess =
          userProperty.grantAccess;
      }
      // If the userProperty doesn't exist. In this case, add it to the existing list.
      else updatedEditedUsers.push(userProperty);

      return updatedEditedUsers;
    });
  };

  const updateMultipleUserProperties = (
    userProperties: IUserPropertyAssignment[]
  ) => {
    setEditedUserProperties(userProperties);
  };

  const onSaveChanges = () => {
    if (userProperties === undefined) return;

    const finalUpdatedUserProps: IUserPropertyAssignment[] = [];
    editedUserProperties.forEach((editedUserProp) => {
      const untouchedUserProp = userProperties.find(
        (userProp) =>
          userProp.userId === editedUserProp.userId &&
          userProp.propertyId === editedUserProp.propertyId
      );

      if (untouchedUserProp?.grantAccess !== editedUserProp.grantAccess)
        finalUpdatedUserProps.push({ ...editedUserProp });
    });
    const postUserProps: IPutUserPropertyAssignment[] = [];
    finalUpdatedUserProps.forEach((updatedUserProp) => {
      const filteredUserIndex = renderedRows.findIndex(
        (renderedRow) =>
          renderedRow.propertyId === updatedUserProp.propertyId &&
          renderedRow.userId === updatedUserProp.userId
      );
      if (filteredUserIndex > -1)
        postUserProps.push({
          userId: updatedUserProp.userId,
          propertyId: updatedUserProp.propertyId,
          grantAccess: updatedUserProp.grantAccess,
        });
    });

    updateUsersPropertyPerms.mutate(postUserProps, {
      onSuccess: (successObj) => {
        queryClient.invalidateQueries([
          "usersPropertyPermissions",
          userAccountDDValue,
        ]);
      },
    });
  };

  const renderAlert = mutationStatus !== "idle";
  let alertMessage = "";
  let alertType: AlertSeverityType = "info";

  if (mutationStatus === "loading") {
    alertMessage = "Updating Property Assignments.";
    alertType = "info";
  } else if (mutationStatus === "success") {
    alertMessage = "Successfully updated Property Assignments.";
    alertType = "success";
  } else if (mutationStatus === "error") {
    alertMessage = "Something went wrong. Please reload and try again.";
    alertType = "error";
  }

  const propertiesToBeUpdated = () => {
    if (editedUserProperties.length === 0 || updateUsersPropertyPerms.isLoading)
      return true;

    if (userProperties === undefined) return true;

    for (const editedUserProperty of editedUserProperties) {
      const actualUserProperty = userProperties.find(
        (oldUserProperty) =>
          oldUserProperty.userId === editedUserProperty.userId &&
          oldUserProperty.propertyId === editedUserProperty.propertyId
      );

      if (actualUserProperty === undefined) continue;

      if (actualUserProperty.grantAccess !== editedUserProperty.grantAccess)
        return false;
    }
    return true;
  };

  return (
    <UPAContext.Provider
      value={{
        userProperties: editedUserProperties,
        updateUserProperties: updateUserProperties,
        updateMultipleUserProperties: updateMultipleUserProperties,
        allUsersChecked: allUsersChecked,
        updateAllUsersChecked: updateAllUsersChecked,
      }}
    >
      {/* Alert to show loading, error and success messages */}
      {renderAlert && (
        <SimpleAlert
          severityType={alertType}
          message={alertMessage}
          onClose={() => {
            setMutationStatus("idle");
          }}
          alertStyles={{ width: "100%" }}
          styles={{ position: "inherit", width: "100%" }}
        />
      )}
      <Grid container className="property-action-bar">
        <Grid item>
          <Grid container sx={{ width: "auto", }}>
            <Grid item>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container sx={{ width: "auto", }}>
            <Grid item>
              {/* Account Dropdown */}
              <GroupSearch setFilter={setGroupFilter} />
              {/* Save Assignments buttons */}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveOutlinedIcon />}
                size="small"
                disabled={propertiesToBeUpdated()}
                onClick={onSaveChanges}
              >
                Save Assignments
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Loader */}
      {isLoading && (
        <Box m="auto">
          <CircularProgress />
        </Box>
      )}
      {/* Grid */}
      {userPropertiesLoaded && (
        <UserPropAssignGrid
          rowData={userProperties ?? []}
          updateRenderedRows={updateRenderedRows}
        />
      )}
    </UPAContext.Provider>
  );
};

export default UserPropertyAssignment;
