import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  GridReadyEvent,
  ColumnApi,
  ColDef,
  GridApi,
  ExcelExportParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useGetAccounts } from "../../properties/property/api/accountService";
import ConfirmDialog from "../../../shared/view/confirmationDialog";
import {
  useGetDocumentList,
  useDeleteDocument,
} from "../../properties/document/api/documentService";
import { Box, Button, Grid, CircularProgress, Typography } from "@mui/material";
import { TDeleteDocumentModel } from "../../properties/document/model/deleteDocumentModel";
import { useQueryClient } from "@tanstack/react-query";
import UserOptions from "../../layout/views/userOptions";
import SimpleAlert from "../../UI/view/SimpleAlert";
import { IUAAlertMessage } from "../../usermanagement/util/userContextModels";
import { hasSpecificPermission } from "../../portfolio/util/accountPermissions";
import PortfolioContext from "../../portfolio/util/portfolioContext";
import { useGetGridConfig } from "../../../shared/api/pageConfigService";
import {
  GridConfigIds,
  PageConfigIds,
} from "../../../constants/GridPageConfigurationIds";
import SaveGridStateOptions, {
  GRID_CONFIG_DEFAULT_VIEW,
} from "../../../shared/view/SaveGridStateOptions";
import { UilExport } from "@iconscout/react-unicons";
import DocumentActionsRenderer from "../DocumentActionsRenderer";
// import { formatFileSize } from "../util/format";
import { EAccountPermission } from "../../usermanagement/model/editUserModel";
import {
  dateFilterParams,
  dateValueFormatter,
  dateValueGetter,
  stringSortCaseInsensitive,
} from "../../properties/util/gridUtils";
import { numberFormatterNoAbbrev } from "../../properties/util/gridUtils";

const Documents: React.FC = () => {
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [deleteDocument, setDeleteDocument] = useState({
    documentId: "",
    description: "",
  });
  const defaultAlertMessage: IUAAlertMessage = {
    severityType: "success",
    message: "",
  };
  const [alertMessage, setAlertMessage] =
    useState<IUAAlertMessage>(defaultAlertMessage);
  const accounts = useGetAccounts();
  const accountIdentifier = accounts.data ? accounts.data[0].identifier : "";
  const documents = useGetDocumentList({
    accountIdentifier,
  });
  const deleteDocumentAction = useDeleteDocument();

  const pageId = PageConfigIds.DOCUMENTS;
  const gridId = GridConfigIds.DOCUMENTS_GRID;
  const gridConfigsQuery = useGetGridConfig({
    pageId: pageId,
    gridIdentifier: gridId,
    accountId: accountIdentifier,
  });
  const [gridView, setGridView] = useState(GRID_CONFIG_DEFAULT_VIEW);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | undefined>(
    undefined
  );
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const saveAsView = React.useRef("");

  /**
   * Sets the context that is retrieved from the backend whenever the gridView is changed.
   * gridView is either changed when data first loads (only if a default value exists) or when the user uses the dropdown
   */
  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1 ||
      gridApi === undefined ||
      gridColumnApi === undefined
    )
      return;

    if (gridView === GRID_CONFIG_DEFAULT_VIEW) {
      gridApi.setFilterModel({});
      gridColumnApi.resetColumnState();
      gridApi.redrawRows();
      return;
    }

    const selectedGridConfig = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.id === Number(gridView)
    );

    if (selectedGridConfig === undefined) return;

    gridApi.setFilterModel(JSON.parse(selectedGridConfig.columnFilterState));
    gridColumnApi.applyColumnState({
      state: JSON.parse(selectedGridConfig.columnState),
    });
    gridApi.redrawRows();
  }, [gridView, gridApi, gridColumnApi, gridConfigsQuery]);

  const queryClient = useQueryClient();

  const { accountPermissions } = useContext(PortfolioContext);
  const hasDeleteDocAccess = useMemo(
    () =>
      hasSpecificPermission(
        accountPermissions,
        accountIdentifier,
        EAccountPermission.DOCUMENTS_UPLOAD_AND_DELETE
      ),
    [accountPermissions, accountIdentifier]
  );

  const columnDefs = React.useMemo(() => {
    const defs: ColDef[] = [
      {
        headerName: "Actions",
        field: "id",
        cellRenderer: "documentActionsRenderer",
        cellRendererParams: {
          accountIdentifier: accountIdentifier,
          hasDeleteDocAccess: hasDeleteDocAccess,
          clicked: (id: string, desc: string) => {
            handleDeleteDocumentClicked(id, desc);
          },
        },
        sortable: false,
        filter: false,
      },
      {
        headerName: "Property Name",
        field: "propertyTitle",
        comparator: stringSortCaseInsensitive,
      },
      {
        headerName: "Document Title",
        field: "displayName",
        filter: "agTextColumnFilter",
        comparator: stringSortCaseInsensitive,
      },
      {
        headerName: "Group",
        field: "labels",
        filter: "agTextColumnFilter",
        comparator: stringSortCaseInsensitive,
      },
      {
        headerName: "Type",
        field: "documentType",
        comparator: stringSortCaseInsensitive,
      },
      {
        headerName: "Uploaded To",
        field: "documentSource",
        comparator: stringSortCaseInsensitive,
      },
      {
        headerName: "Upload Date",
        field: "createdAt",
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        cellClass: ["textAlignRight", "dateFormat"],
        valueGetter: (params) => dateValueGetter(params.data.createdAt),
        valueFormatter: (params) => dateValueFormatter(params.data.createdAt),
      },
      {
        headerName: "Uploaded By",
        field: "userFullName",
      },
      {
        headerName: "Size (Bytes)",
        filter: "agNumberColumnFilter",
        field: "fileSize",
        type: ["numericColumn", "rightAligned"],
        valueFormatter: (params: any) =>
          numberFormatterNoAbbrev(params.data.fileSize, 0),
      },
    ];

    return defs;
  }, [accountIdentifier, hasDeleteDocAccess]);

  const handleDeleteDocumentClicked = (
    documentId: string,
    description: string
  ) => {
    setDeleteDocument({ documentId, description });
    setConfirmOpen(true);
  };

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
    setGridColumnApi(event.columnApi);
    if (documents.data === undefined || documents.data.length === 0) {
      event.api.hideOverlay();
      event.api.showNoRowsOverlay();
    }
  };

  const deleteDocumentEvent = () => {
    const model: TDeleteDocumentModel = {
      accountIdentifier: accountIdentifier,
      documentId: deleteDocument.documentId,
    };

    deleteDocumentAction.mutate(model, {
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          "getDocumentList",
          { accountIdentifier },
        ]);
        setAlertMessage({
          severityType: "success",
          message: "Document was successfully deleted.",
        });
      },
      onError: () => {
        setAlertMessage({
          severityType: "error",
          message: "Could not delete the document. Please try again later.",
        });
      },
    });
  };

  const onExport = () => {
    let columns = gridColumnApi
      ?.getAllColumns()
      ?.filter((c) => c.getUserProvidedColDef()?.field !== "id");
    const params: ExcelExportParams = {
      sheetName: "Documents - data",
      fileName: "Documents",
      exportMode: undefined,
      suppressTextAsCDATA: false,
      rowHeight: undefined,
      headerRowHeight: undefined,
      columnKeys: columns,
    };
    gridApi?.exportDataAsExcel(params);
  };

  /**
   * If we have only one view and its not set to default, then it is set to default.
   * Otherwise, the newly created grid is set as the current grid.
   */
  useEffect(() => {
    if (
      !gridConfigsQuery.isSuccess ||
      !gridConfigsQuery.data ||
      gridConfigsQuery.data.gridConfigurations.length < 1
    ) {
      if (gridView !== GRID_CONFIG_DEFAULT_VIEW)
        setGridView(GRID_CONFIG_DEFAULT_VIEW);
      return;
    }

    // Setting the newly saved grid config as the currently selected view.
    const isSaveAsRender = saveAsView.current.trim().length > 0;

    if (isSaveAsRender) {
      const savedAsView = gridConfigsQuery.data.gridConfigurations.find(
        (gridConfig) =>
          gridConfig.name.toLowerCase().trim() ===
          saveAsView.current.toLowerCase().trim()
      );
      if (savedAsView) setGridView(savedAsView.id.toString());
      saveAsView.current = "";
      return;
    }

    const defaultView = gridConfigsQuery.data.gridConfigurations.find(
      (gridConfig) => gridConfig.isDefault
    );
    if (
      defaultView &&
      gridView !== defaultView.id.toString() &&
      gridView === GRID_CONFIG_DEFAULT_VIEW
    )
      setGridView(defaultView.id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridConfigsQuery.isSuccess, gridConfigsQuery.data]);

  return (
    <Box className="mainContainer">
      <Box className={"appHeader"}>
        <Box>
          <Typography variant="h1" sx={{ color: "primary.dark" }}>
            Documents
          </Typography>
        </Box>
        {/* User Options */}
        <UserOptions />
      </Box>
      {alertMessage.message !== "" && (
        <SimpleAlert
          severityType={alertMessage.severityType}
          message={alertMessage.message}
          onClose={() => setAlertMessage(defaultAlertMessage)}
        />
      )}
      {documents.isFetching ? (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <Fragment>
          <div
            id="document-main-content"
            className="example-wrapper"
            style={{ width: "100%" }}
          >
            <Grid
              container
              className={"property-action-bar property-action-bar-list"}
            >
              <Grid item></Grid>
              <Grid item>
                <Grid container sx={{ width: "auto" }}>
                  <Grid item>
                    <SaveGridStateOptions
                      isSuccess={gridConfigsQuery.isSuccess}
                      gridView={gridView}
                      handleGridView={(newVal: string) => {
                        setGridView(newVal);
                      }}
                      gridConfigData={gridConfigsQuery.data}
                      gridApi={gridApi}
                      gridColumnApi={gridColumnApi}
                      gridId={gridId}
                      pageId={pageId}
                      accountId={accountIdentifier}
                      invalidateGridQuery={() =>
                        queryClient.invalidateQueries([
                          "getGridConfig",
                          pageId + gridId + accountIdentifier,
                        ])
                      }
                      updateSaveAsViewName={(name: string) => {
                        saveAsView.current = name;
                      }}
                      updateGridView={() =>
                        setGridView(GRID_CONFIG_DEFAULT_VIEW)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={onExport}
                      className={"btn-primary"}
                      sx={{ marginTop: "21px" }}
                    >
                      <UilExport /> Export
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div
              id="documents-main-content"
              className="ag-theme-alpine"
              style={{ height: "100%", width: "100%" }}
            >
              <div
                id="documentsGrid"
                className="ag-theme-alpine ag-theme-alpine-container-override"
                style={{ height: "100%", width: "100%" }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={documents.data}
                  columnDefs={columnDefs}
                  components={{
                    documentActionsRenderer: DocumentActionsRenderer,
                  }}
                  sortingOrder={["desc", "asc"]}
                  defaultColDef={{
                    filter: true,
                    resizable: true,
                    sortable: true,
                    filterParams: {
                      buttons: ["apply", "reset"],
                      closeOnApply: true,
                    },
                  }}
                  overlayNoRowsTemplate="There are no documents. To view documents here, please upload documents to the appropriate property pages."
                />
              </div>
            </div>
          </div>
          <ConfirmDialog
            title="Delete Document?"
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={deleteDocumentEvent}
          >
            Are you sure you want to delete '{deleteDocument.description}'?
          </ConfirmDialog>
        </Fragment>
      )}
    </Box>
  );
};

export default Documents;
