import axios from "axios";
import {
  useAuthorizedApiAxios,
  AccountIdentifierHeaderName,
} from "../../../../shared/api/authorizedAxios";
import { useQuery } from "@tanstack/react-query";

export interface AccountTypesOption {
  id: number;
  name: string;
}

export const useGetAccountTypesOptions = (accountIdentifier: string) => {
  const authorizedApiAxios = useAuthorizedApiAxios();

  return useQuery(
    ["accountTypes"],
    () => {
      const source = axios.CancelToken.source();
      const url: string = `/assetService/v1/lookups/financialaccounttypes`;

      const promise = authorizedApiAxios().get<AccountTypesOption[]>(url, {
        cancelToken: source.token,
        headers: {
          [AccountIdentifierHeaderName]: accountIdentifier,
        },
      });

      (promise as any).cancel = () => source.cancel();
      return promise;
    },
    {
      enabled: accountIdentifier.trim().length !== 0,
      select: (response) => {
        return response.data.sort((a, b) => {
          let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      },
    }
  );
};
