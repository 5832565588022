import React, { useCallback, useState } from "react";
import { Badge, Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { UilNotes } from '@iconscout/react-unicons'
import { ICellRendererParams } from "ag-grid-community";
import ViewNotesModal from "./ViewNotesModal";

const ViewNotesRenderer: React.FunctionComponent<ICellRendererParams> = (
  props: any
) => {
  const [openNote, setOpenNote] = useState(false);
  const [currHasNotes, setCurrHasNotes] = useState(props.data.hasNotes);
  const openNotes= () => {
   setOpenNote(true);
  };

  const updateNoteIcon= useCallback(
    (hasNotes: boolean) => {
      setCurrHasNotes(hasNotes);
    },
    []
  );

  return (
    <React.Fragment>
      {openNote && (
        <ViewNotesModal
          setCurrHasNotes={updateNoteIcon}
          isNoteOpen = {openNote}
          handlePopupClose={() => setOpenNote(false)}
          tenantId={props.data.tenantId}
        />
      )}
      {
        <Box display="flex" flexDirection="row">
          {/* Has Notes */}
          {currHasNotes && (
            <Tooltip title="View Notes" onClick={openNotes}>
                <Box className="icon-grid">
                  <Badge color="secondary" overlap="circular" badgeContent=" " variant="dot" sx={{ verticalAlign: "inherit", }}>                       
                    <UilNotes />
                  </Badge>
                </Box>
            </Tooltip>
          )}
          {/* Does not have Notes */}
          {!currHasNotes && (
            <Tooltip title="Add Notes" onClick={openNotes}>           
              <Box className="icon-grid">                    
                <UilNotes />   
              </Box>
            </Tooltip>            
          )}
        </Box>
      }
    </React.Fragment>
  );
};

export default ViewNotesRenderer;