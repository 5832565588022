import React, { ChangeEvent, useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import {
  Dialog,
  Box,
  FormControl,
  IconButton,
  Select,
  MenuItem,
  Typography,
  Button,
  CircularProgress,
  TextField,
  // InputLabel,
  OutlinedInput,
  // DialogTitle,
  // DialogContent,
  // DialogActions,
  SelectChangeEvent,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FilePond, registerPlugin, FileStatus } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { UilFile } from "@iconscout/react-unicons";
import { UilMultiply } from "@iconscout/react-unicons";

import {
  IGLTreeAccountingSystemSelectList,
  IGLTreeTypeSelectList,
} from "../../model/glTreeModel";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../../../properties/rent roll/views/property rent roll generations/downloaDocumentsModal";

// import { useUploadGLDocument } from "../../../properties/document/api/documentService";
import { TCreateGLTreeModel } from "../../model/createGLTreeModel";
import ConfirmDialog from "../../../../shared/view/confirmationDialog";
import { useUploadGLDocument } from "../../api/glTreeService";
import { useForm, Controller } from "react-hook-form";
import {
  requiredErrorMessage,
  // textErrorMessage,
} from "../../../properties/property details/util/errorTexts";

// const createAlertText =
//   "Your alert has been successfully created. You can see it in Notifications Center.";
// const editAlertText = "Successfully updated alerts.";

// const createAlertFailText =
//   "Your alert could not been saved. Please try again later.";

// const editAlertFailText =
//   "Your alert could not been edited. Please try again later.";

// const SELECT_ALL_OPTION = "all";

const CustomizedDialog = styled(Dialog)`
  width: 99.7%;
  & .MuiDialog-paper {
    width: 500px;
    max-width: 90%;
  }
  & .filepond--drop-label {
    padding: 25px;
    height: 120px;
    background-color: var(--neutral-grey-50);
    border: 1px dashed var(--neutral-grey-200);
    border-radius: 6px;
    cursor: pointer;
    & label {
      cursor: pointer;
    }
    & .MuiTypography-h3 {
      font-family: lato;
      font-weight: 500;
      color: var(--neutral-grey-600);
    }
    & .MuiTypography-h3 + .MuiTypography-h3 {
      color: var(--primary-blue);
      text-decoration: underline;
    }
    & .MuiTypography-body3 {
      font-family: lato;
      font-size: 14px;
      font-weight: 400;
      color: var(--neutral-grey-600);
    }
  }
  & .filepond--drip {
    background-color: var(--neutral-white);
  }
  & .filepond--credits {
    display: none;
  }
  & .brs-files {
    color: blue;
    text-decoration: underline;
    font-size: 12px;
  }
  & .filepond--list {
    top: 7px !important;
    left: 0 !important;
    right: 0 !important;
    & .filepond--item {
      margin: 8px;
      & .filepond--file-wrapper {
        // background-color: var(--neutral-white);
        border-radius: 6px;
        & .filepond--file-info {
          font-family: lato;
          font-size: 15px;
          font-weight: 500;
          color: var(--primary-dark-blue);
          & .filepond--file-info-main {
            font-size: 1em;
          }
        }
        & .filepond--file-action-button {
          padding: 6px;
          width: 32px;
          height: 32px;
          background: transparent;
          cursor: pointer;
          &:hover {
            box-shadow: 0;
            background-color: var(--neutral-grey-150);
            & svg path {
              fill: var(--semantic-red);
            }
          }
        }
        & svg path {
          fill: var(--primary-dark-blue);
        }
        & .filepond--image-preview-overlay-idle {
          mix-blend-mode: screen;
          color: rgba(255, 255, 255, 0.85);
        }
      }
    }
  }
`;

type FileDelete = {
  id: string;
  name: string;
};

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

interface Props {
  open: boolean;
  handleClose: (shouldRefreshData: boolean) => void;
  accountId: string;
  treeId?: string;
  editMode: boolean;
  glAccountTreeTypes: IGLTreeTypeSelectList[];
  glAccountingSystems: IGLTreeAccountingSystemSelectList[];
  initialTitle: string;
  initialAccountingSystem: string;
  initialTreeType: string;
  onUploaded?: (msg: any) => void;
  onError?: (msg: any) => void;
}

const CreateGLAccountTreeDialog: React.FC<Props> = (props) => {
  const {
    open,
    handleClose,
    accountId,
    treeId,
    editMode,
    glAccountTreeTypes,
    glAccountingSystems,
    initialTitle,
    initialAccountingSystem,
    initialTreeType,
    onUploaded,
    onError,
  } = props;

  const [glTreeType, setGLTreeType] = useState<string>(
    initialTreeType !== "" ? initialTreeType : "-1"
  );
  const [glAccountingSystem, setGLAccountingSystem] = useState<string>(
    initialAccountingSystem !== "" ? initialAccountingSystem : "-1"
  );
  const [titleHasValue, setTitleHasValue] = useState<boolean>(
    (editMode ? initialTitle : "").length !== 0
  );
  const [accountingSystemHasValue, setAccountingSystemHasValue] =
    useState<boolean>(glAccountingSystem !== "-1");

  const [glTypeHasValue, setGLTypeHasValue] = useState<boolean>(
    glTreeType !== "-1"
  );
  const [disabled, setDisabled] = useState<boolean>(editMode ? false : true);

  const defaultValues = {
    title: editMode ? initialTitle : "",
    accountingSystem: glAccountingSystem,
    accountTreeType: glTreeType,
  };
  const { ...methods } = useForm<TCreateGLTreeModel>({ defaultValues });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    // setError,
  } = methods;

  // const [label, setLabel] = useState<string>();
  // const [title, setTitle] = useState<string>(initialTitle);
  const [files, setFiles] = useState<any[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [fileToBeDeleted, setFileToBeDeleted] = useState<
    FileDelete | undefined
  >(undefined);

  // const propertyInfo = usePropertyInfo();
  const uploadDocumentResponse = useUploadGLDocument();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name.toUpperCase() === "TITLE") {
      setTitleHasValue(event.target.value.length !== 0);
    }
    if (
      event.target.value.length !== 0 &&
      accountingSystemHasValue &&
      glTypeHasValue
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  const handleTreeTypeChange = (value: string) => {
    setGLTypeHasValue(value !== "-1");
    if (titleHasValue && accountingSystemHasValue && value !== "-1") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  const handleAccountingSystemChange = (value: string) => {
    setAccountingSystemHasValue(value !== "-1");
    if (titleHasValue && value !== "-1" && glTypeHasValue) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    if (
      glAccountTreeTypes === undefined ||
      glAccountTreeTypes.length === 0 ||
      glTreeType === undefined ||
      glTreeType.length !== 0
    )
      return;
    setGLTreeType(glAccountTreeTypes[0].key);
    setGLTypeHasValue(glTreeType !== "-1");
    if (titleHasValue && accountingSystemHasValue && glTypeHasValue) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    glAccountTreeTypes,
    glTreeType,
    glAccountingSystem,
    glTypeHasValue,
    titleHasValue,
    accountingSystemHasValue,
  ]);

  useEffect(() => {
    if (
      glAccountingSystems === undefined ||
      glAccountingSystems.length === 0 ||
      glAccountingSystem === undefined ||
      glAccountingSystem.length !== 0
    )
      return;
    setGLAccountingSystem(glAccountingSystems[0].key);
    setAccountingSystemHasValue(glAccountingSystem !== "-1");
    if (titleHasValue && accountingSystemHasValue && glTypeHasValue) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    glAccountingSystems,
    glAccountingSystem,
    accountingSystemHasValue,
    titleHasValue,
    glTypeHasValue,
  ]);

  const onSubmit = handleSubmit((model: TCreateGLTreeModel) => {
    for (const file of files) {
      model.accountIdentifier = accountId;
      model.treeId = treeId;
      // model.accountTreeType = `${glTreeType}`;
      // model.accountingSystem = `${glAccountingSystem}`;
      model.file = file;
      // model.title = title;
    }
    uploadDocumentResponse.mutate(model, {
      onSuccess: (data) => {
        setFiles([]);
        handleClose(true);
        console.log("azure file url: " + data.data);
        if (onUploaded) onUploaded(data.data);
      },

      onError: (msg: any) => {
        handleClose(false);
        if (onError) onError(msg.errorMessage);
      },
    });
  });

  // const handleUpload = async () => {
  //   for (const file of files) {
  //     const model: TCreateGLTreeModel = {
  //       accountIdentifier: accountId,
  //       accountTreeType: `${glTreeType}`,
  //       accountingSystem: `${glAccountingSystem}`,
  //       file: file,
  //       title: title,
  //     };

  //     await uploadDocumentResponse.mutateAsync(model, { onError: onError });
  //   }

  //   setFiles([]);
  //   handleClose(true);
  //   if (onUploaded) onUploaded();
  // };

  function filepondCustomLabel() {
    return renderToString(
      <Box className="upload-document-container">
        <Box>
          <UilFile />
        </Box>
        <Box>
          <Typography variant="h3" component="span">
            Drag and drop here or&nbsp;
          </Typography>
          <Typography variant="h3" component="span">
            browse files
          </Typography>
        </Box>
        <Typography variant="body3" mt={2}>
          Maximum file size of 25MB
        </Typography>
      </Box>
    );
  }

  const SelectIcon = (props: any) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 0 0"
      fill="none"
    >
      <path
        d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z"
        fill="#021A3C"
      />
    </svg>
  );

  return (
    <Box>
      {openDeleteModal && fileToBeDeleted && (
        <ConfirmDialog
          title="Delete Document?"
          open={openDeleteModal}
          setOpen={(open: boolean) => setOpenDeleteModal(open)}
          onConfirm={() => {
            if (fileToBeDeleted !== undefined) {
              setFiles((prevFiles) => {
                return prevFiles.filter(
                  (file) => file.id !== fileToBeDeleted.id
                );
              });
              setFileToBeDeleted(undefined);
            }
          }}
        >
          Are you sure you want to delete '{fileToBeDeleted.name}'?
        </ConfirmDialog>
      )}

      <CustomizedDialog aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title">
          <Typography component="span" variant="h2" color="primary.dark">
            {editMode === true ? "Update GL Tree" : "Add GL Tree"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setFiles([]);
              handleClose(false);
            }}
            className="icon-dialog-close"
            size="large"
          >
            <UilMultiply />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {glAccountTreeTypes.length === 0 ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              {
                <Box>
                  <FormControl className={"form-control"}>
                    <Typography
                      variant="body3"
                      component="label"
                      className="input-label"
                    >
                      {"Title*"}
                    </Typography>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      className={"form-control-field"}
                      inputProps={{
                        maxLength: 50,
                      }}
                      {...register("title", {
                        required: requiredErrorMessage,
                        maxLength: {
                          value: 50,
                          message: "Maximum 50 characters.",
                        },
                      })}
                      error={Boolean(errors?.title)}
                      onChange={handleChange}
                      helperText={
                        Boolean(errors?.title) ? errors?.title?.message : null
                      }
                    />
                    {Boolean(errors.title) && (
                      <FormHelperText style={{ color: "red" }}>
                        {errors.title?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              }
              <Box>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={"form-control"}
                  error={Boolean(errors.accountingSystem)}
                >
                  <Typography
                    variant="body3"
                    component="label"
                  >{`Accounting System*`}</Typography>
                  {/* accountingSystem */}
                  <Controller
                    control={control}
                    name={"accountingSystem"}
                    rules={{
                      validate: {
                        required: (v) =>
                          v === "-1" ? "Accounting System is required" : true,
                      },
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="accountingSystem"
                        onChange={(e: SelectChangeEvent<string>) => {
                          const newVal = e.target.value;
                          handleAccountingSystemChange(newVal);
                          field.onChange(newVal);
                        }}
                        // label="Accounting System"
                        // displayEmpty
                        // disabled={editMode}
                        className={"form-control-field"}
                        input={<OutlinedInput />}
                        IconComponent={SelectIcon}
                      >
                        <MenuItem value={"-1"}>
                          Select Accounting System
                        </MenuItem>
                        {glAccountingSystems.map((acctingSysOpt) => (
                          <MenuItem
                            key={acctingSysOpt.key}
                            value={acctingSysOpt.key}
                          >
                            {acctingSysOpt.value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {Boolean(errors.accountingSystem) && (
                    <FormHelperText>
                      {errors.accountingSystem?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={"form-control"}
                  error={Boolean(errors.accountTreeType)}
                >
                  <Typography
                    variant="body3"
                    component="label"
                  >{`Tree Type*`}</Typography>
                  {/* accountTreeType */}
                  <Controller
                    control={control}
                    name={"accountTreeType"}
                    rules={{
                      validate: {
                        required: (v) =>
                          v === "-1" ? "Tree Type is required" : true,
                      },
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        {...field}
                        // inputRef= {field.ref}
                        // value={field.value}
                        onChange={(e: SelectChangeEvent<string>) => {
                          const newVal = e.target.value;
                          handleTreeTypeChange(newVal);
                          field.onChange(newVal);
                        }}
                        labelId="accountTreeType"
                        // displayEmpty
                        className={"form-control-field"}
                        input={<OutlinedInput />}
                        IconComponent={SelectIcon}
                      >
                        <MenuItem value={"-1"}>Select Tree Type</MenuItem>
                        {glAccountTreeTypes.map((typeOption) => (
                          <MenuItem key={typeOption.key} value={typeOption.key}>
                            {typeOption.value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {Boolean(errors.accountTreeType) && (
                    <FormHelperText>
                      {errors.accountTreeType?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box mt={2}>
                <Typography
                  variant="body3"
                  component="label"
                  className="input-label"
                >
                  Attach Document*
                </Typography>
              </Box>
              <Box>
                <FilePond
                  files={files}
                  allowReorder={true}
                  allowMultiple={false}
                  onupdatefiles={(files) => {
                    let validFiles = files.filter(
                      (f) => f.status !== FileStatus.LOAD_ERROR
                    );
                    setFiles(validFiles);
                  }}
                  allowFileTypeValidation={true}
                  acceptedFileTypes={[
                    // "image/jpg",
                    // "image/jpeg",
                    // "image/png",
                    // "application/pdf",
                    // "application/msword",
                    // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    // "application/vnd.ms-excel",
                    // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    // "text/plain",
                    // "application/xml",
                    "text/csv",
                  ]}
                  fileValidateTypeLabelExpectedTypesMap={{
                    // "image/jpg": ".jpg",
                    // "image/jpeg": ".jpeg",
                    // "image/png": ".png",
                    // "application/pdf": ".pdf",
                    // "application/msword": ".doc",
                    // "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    //   ".docx",
                    // "application/vnd.ms-excel": ".xls",
                    // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    // ".xlsx",
                    // "text/plain": ".txt",
                    // "application/xml": ".xml",
                    "text/csv": ".csv",
                  }}
                  labelFileTypeNotAllowed={"File must be a csv file."}
                  fileValidateTypeLabelExpectedTypes={
                    "Supported types: {allTypes}"
                  }
                  allowFileSizeValidation={true}
                  maxFileSize={"25MB"}
                  maxFiles={1}
                  maxTotalFileSize={"25MB"}
                  labelIdle={filepondCustomLabel()}
                  styleButtonRemoveItemPosition="right"
                  beforeRemoveFile={(file) => {
                    setOpenDeleteModal(true);
                    setFileToBeDeleted({
                      id: file.id,
                      name: file.filename,
                    });
                    return false;
                  }}
                  iconRemove='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#0a1a27" d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"/></svg>'
                  iconRetry='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#0a1a27" d="M21,11a1,1,0,0,0-1,1,8.05,8.05,0,1,1-2.22-5.5h-2.4a1,1,0,0,0,0,2h4.53a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4.77A10,10,0,1,0,22,12,1,1,0,0,0,21,11Z"/></svg>'
                />
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setFiles([]);
              handleClose(false);
            }}
            disabled={uploadDocumentResponse.isLoading}
            size="small"
            className={"btn-secondary"}
          >
            {editMode ? "Close" : "Cancel"}
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            disabled={
              !files.length || uploadDocumentResponse.isLoading || disabled
            }
            size="small"
            className={"btn-tertiary"}
          >
            {editMode ? "Save" : "Create"}
          </Button>
        </DialogActions>
      </CustomizedDialog>
    </Box>
  );
};

export default CreateGLAccountTreeDialog;
