import {
  //withStyles
  Theme,
} from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const drawerWidth = 240;

const headerStyles = makeStyles((theme: Theme) =>
  createStyles({
    //const headerStyles = makeStyles(theme, () => ({
    root: {
      display: "flex",
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    collapseRoot: {
      width: "100%",
      maxWidth: drawerWidth,
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      left: 57,
      backgroundColor: "white",
      color: "black",
      width: `calc(100% - 57px)`,
      transition: theme.transitions.create(["width", "margin", "left"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarInner: {
      alignItems: "center",
      justifyContent: "space-between",
    },
    appBarNavBrand: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
      overflow: "hidden",
    },
    appBarNavBrandClientLogoContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      //width: "100%",
    },
    appBarNavBrandClientLogoIcon: {
      margin: "0 5px",
      padding: 0,
      width: 46,
      fontSize: "1rem",
      fontWeight: 900,
      textAlign: "center",
    },
    appBarNavBrandClientLogo: {
      fontSize: "1rem",
      /*
      [theme.breakpoints.up("sm")]: {
        maxWidth: "100%",
        minWidth: "15%",
      },
      */
    },
    appBarCourtesyNav: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& svg": {
        fill: "black",
      },
    },
    appBarShift: {
      left: 0,
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin", "left"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      /*
      marginLeft: -4,
      [theme.breakpoints.up("sm")]: {
        marginLeft: -4,
      },
      marginRight: 8,
      */
    },
    noBgColor: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    listItemText: {
      margin: "4px 12px",
      padding: "8px 11px",
      width: "calc(100% - 24px)",
      minHeight: 40,
      borderRadius: 6,
      lineHeight: 1,
      "& svg": {
        fill: theme.palette.primary.dark,
      },
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.neutralSecondary.main,
        "& svg": {
          fill: theme.palette.primary.main,
        },
      },
      "&.Mui-selected:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
      },
      "&.Mui-selected": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
        "& svg": {
          fill: theme.palette.primary.main,
        },
      },
    },
    handleDrawerOpenIcon: {
      /*
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",*/
      position: "fixed",
      top: 48,
      left: 72,
      //zIndex: 9999,
      padding: 0,
      width: 24,
      height: 24,
      backgroundColor: theme.palette.neutralSecondary.main,
      boxShadow: "0 2px 2px 0 rgba(46, 57, 72, 0.06)",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.neutral100.dark,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      "& svg": {
        fill: theme.palette.primary.dark,
      },
      "&:hover": {
        backgroundColor: theme.palette.neutralSecondary.main,
        "& svg": {
          fill: theme.palette.primary.main,
        },
      },
    },
    handleDrawerCloseIcon: {
      /*
      display: "none",
      alignItems: "center",
      justifyContent: "flex-start",*/
      position: "fixed",
      top: 48,
      left: 226,
      //zIndex: 9999,
      padding: 0,
      width: 24,
      height: 24,
      backgroundColor: theme.palette.neutralSecondary.main,
      boxShadow: "0 2px 2px 0 rgba(46, 57, 72, 0.06)",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.neutral100.dark,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      "& svg": {
        fill: theme.palette.primary.dark,
      },
      "&:hover": {
        backgroundColor: theme.palette.neutralSecondary.main,
        "& svg": {
          fill: theme.palette.primary.main,
        },
      },
    },
    listIcon: {
      minWidth: "auto",
    },
    /*
    listIconAccount: {
      minWidth: 40,
    },
    */
    listIconSub: {
      width: 24,
      marginRight: 11,
      minWidth: "auto",
    },
    /*
    userProfileAvatar: {
      maxHeight: 24,
      maxWidth: 24,
    },
    userProfileName: {
      //display: "none",
      //[theme.breakpoints.up("sm")]: {
      //  display: "block",
      //},
      "& span": {
        whiteSpace: "nowrap",
      },
    },
    */
    notificationCollapseContainer: {
      position: "absolute",
      top: 56,
      left: "auto",
      right: 0,
      [theme.breakpoints.up("sm")]: {
        top: 64,
        left: 0,
        right: "auto",
      },
      backgroundColor: "white",
      color: "black",
      "& div": {
        color: "black",
      },
      "& svg": {
        fill: "black",
      },
    },
    /*
    accountCollapseContainer: {
      position: "fixed",
      bottom: 0,
      //[theme.breakpoints.up("sm")]: {
        //left: drawerWidth,
      //},
      backgroundColor: "white",
      color: "black",
      "& div": {
        color: "black",
      },
      "& svg": {
        fill: "black",
      },
    },
    accountCollapseContainerOpen: {
      left: 0,
      bottom: 52,
      width: drawerWidth,
      [theme.breakpoints.up("sm")]: {
        left: drawerWidth,
        bottom: "2rem",
        width: "auto",
      },
    },
    accountCollapseContainerClose: {
      left: 57,
    },
    */
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerContainer: {
      overflowX: "hidden",
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerPaperColor: {
      background: theme.palette.neutralSecondary.main,
      color: theme.palette.primary.dark,
      "& nav": {
        background: theme.palette.neutralSecondary.main,
      },
      "& svg": {
        fill: theme.palette.primary.dark,
      },
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      /*
      padding: theme.spacing(0, 1),
      */
      padding: "0 8px 20px 8px",
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    /*
    toolbarToggleDrawerOpen: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      position: "absolute",
      top: 48,
      left: 226,
      zIndex: 10000,
      "& button": {
        padding: 0,
        width: 24,
        height: 24,
        backgroundColor: theme.palette.neutralSecondary.main,
        boxShadow: "0 2px 2px 0 rgba(46, 57, 72, 0.06)",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.neutral100.dark,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        "& svg": {
          fill: theme.palette.primary.dark,
        },
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
          "& svg": {
            fill: theme.palette.primary.main,
          },
        }
      }
    },
    toolbarToggleDrawerClose: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      position: "absolute",
      top: 48,
      left: 54,
      zIndex: 10000,
      "& button": {
        padding: 0,
        width: 24,
        height: 24,
        backgroundColor: theme.palette.neutralSecondary.main,
        boxShadow: "0 2px 2px 0 rgba(46, 57, 72, 0.06)",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.neutral100.dark,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        "& svg": {
          fill: theme.palette.primary.dark,
        },
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
          "& svg": {
            fill: theme.palette.primary.main,
          },
        },
      },
    },
    */
    leftMenuList: {
      width: `calc(100% - ${drawerWidth}px)`,
      "& > div": {
        paddingLeft: 12,
      },
    },
    leftMenuCollapseContainerDrawerOpen: {
      display: "none",
      zIndex: theme.zIndex.drawer + 2,
      backgroundColor: "#303f9f",
      color: "white",
    },
    leftMenuCollapseContainerDrawerClose: {
      display: "block",
      position: "absolute",
      top: 56,
      left: 57,
      [theme.breakpoints.up("sm")]: {
        top: 64,
      },
      zIndex: theme.zIndex.drawer + 2,
      backgroundColor: "#303f9f",
      color: "white",
    },
    leftMenuCollapseContainerInlineDrawerOpen: {
      paddingLeft: 40,
      zIndex: theme.zIndex.drawer + 2,
      backgroundColor: "#303f9f",
      color: "white",
    },
    leftMenuCollapseContainerInlineDrawerClose: {
      display: "none",
      zIndex: theme.zIndex.drawer + 2,
      backgroundColor: "#303f9f",
      color: "white",
    },
    /*
    isActive: {
      backgroundColor: "#000",
    },
    */
    searchAppBar: {
      display: "none",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        display: "initial",
      },
      color: "#666",
      "& > div": {
        marginTop: 8,
        marginLeft: 24,
        width: "calc(100% - 24px)",
        "& > div.MuiAutocomplete-inputRoot": {
          padding: 0,
          color: "#666",
          width: "50%",
          backgroundColor: "#f3f3f3",
          "& fieldset": {
            borderColor: "white",
            width: "100%",
          },
          "& svg": {
            fill: "#666",
          },
        },
      },
    },
    searchLeftMenu: {
      display: "flex",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
      color: "white",
      "& > div.MuiListItemIcon-root": {
        display: "inline-block",
        paddingTop: 8,
        paddingBottom: 8,
      },
      "& > div.MuiAutocomplete-root": {
        padding: 0,
        color: "white",
        width: "90%",
        "& > div.MuiFormControl-marginNormal": {
          marginTop: 0,
          marginBottom: 0,
          "& .MuiAutocomplete-inputRoot": {
            padding: 0,
            color: "white",
            "& .MuiAutocomplete-tag": {
              maxWidth: "calc(50% - 6px)",
            },
          },
          "& .MuiAutocomplete-input:first-child": {
            paddingRight: 30,
            borderColor: "white",
            color: "white",
          },
          "& .MuiInputLabel-formControl": {
            top: -8,
            color: "white",
          },
          "& .MuiInputLabel-formControl.MuiInputLabel-shrink": {
            top: 0,
          },
        },
        "& fieldset": {
          borderColor: "white",
          width: "100%",
        },
        "& svg": {
          fill: "white",
        },
      },
    },
    searchLeftMenuInput: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    searchLeftMenuInputOpen: {
      display: "inline-block",
    },
    searchLeftMenuInputClose: {
      display: "none",
      width: "90%",
    },
    leftMenuSearchCollapse: {
      display: "inline-block",
      margin: 12,
      padding: 0,
      color: "white",
      width: "90%",
      "& > div.MuiFormControl-marginNormal": {
        marginTop: 0,
        marginBottom: 0,
        "& .MuiAutocomplete-inputRoot": {
          padding: 0,
          color: "white",
        },
        "& .MuiAutocomplete-input:first-child": {
          paddingRight: 30,
          borderColor: "white",
          color: "white",
        },
        "& .MuiInputLabel-formControl": {
          top: -8,
          color: "white",
        },
        "& .MuiInputLabel-formControl.MuiInputLabel-shrink": {
          top: 0,
        },
      },
      "& fieldset": {
        borderColor: "white",
        width: "100%",
      },
      "& svg": {
        fill: "white",
      },
    },
  })
);

export default headerStyles;
