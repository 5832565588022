import { ICellRendererParams } from "ag-grid-community";
import { Fragment } from "react";
import {
  Box,
  IconButton,
} from "@mui/material";
import { UseDownloadDocuments } from "../properties/document/api/documentService";
import { UilImport } from '@iconscout/react-unicons';
import { UilTrashAlt } from '@iconscout/react-unicons';
import { TDownloadDocumentModel } from "../properties/document/model/downloadDocumentModel";

const DocumentActionsRenderer: React.FunctionComponent<ICellRendererParams> = (
  props: any
) => {
  const downloadDocuments = UseDownloadDocuments();

  const btnClickedHandler = () => {
    const documentName = props.data?.displayName;
    props.clicked(props.value, documentName);
  };

  const btnDownloadHandler = async () => {
    const model: TDownloadDocumentModel = {
      accountIdentifier: props.accountIdentifier,
      documentId: props.data?.id,
    };
    const documentToDownload = await downloadDocuments.mutateAsync(model);

    if (documentToDownload !== "") window.open(documentToDownload);
  };

  return (
    <Fragment>
      <Box>
        <Box className="icon-document-grid-action">
          <IconButton
            aria-label="download"
            size="small"
            onClick={btnDownloadHandler}
          >
            <UilImport />
          </IconButton>
        </Box>
        {props.hasDeleteDocAccess && (
          <Box className="icon-document-grid-action">
            <IconButton
              aria-label="delete"
              size="small"
              onClick={btnClickedHandler}
            >
              <UilTrashAlt />
            </IconButton>
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

export default DocumentActionsRenderer;
