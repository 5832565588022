import React from "react";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  OutlinedInput,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { LoansForm } from "../model/LoansForm";
import {
  isValidEmail,
  isValidPhoneNumber,
  isValidWebsite,
  isValidZipCode,
} from "../util/lenderServicerForm";
import MaterialInputMask from "./MaterialInputMask";
import LoansContext from "../context/LoansContext";
import { UsState } from "../model/LoansModel";
import {
  requiredErrorMessage,
  textErrorMessage,
  numberErrorMessage,
  notesErrorMessage,
  maxNotesField,
} from "../../property details/util/errorTexts";

interface Props {
  disableSave: (value: boolean) => void;
}

const LendersServicerForm: React.FC<Props> = (props) => {
  const { disableSave } = props;
  const { usStatesList } = React.useContext(LoansContext);

  const methods = useFormContext<LoansForm>();
  const {
    formState: { errors },
    control,
    watch,
    register,
  } = methods;

  const watchLenderPhone: string = watch("lenderPhone");
  const watchServicerPhone: string = watch("servicerPhone");

  const { isValid } = methods.formState;

  React.useEffect(() => {
    disableSave(!isValid);
  }, [isValid, disableSave]);

  const SelectIcon = (props: Props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0 0" fill="none">
      <path d="M10.707 12.293a1 1 0 0 1-1.414 0L5.707 8.707C5.077 8.077 5.523 7 6.414 7h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z" fill="#021A3C"/>
    </svg>
  );

  return (
    <React.Fragment>
      <Typography variant="h4">Lender Information</Typography>
      {/* Lender */}
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">
              Lender*
            </Typography>
            <TextField
              size="small"
              id="lender-title"
              //label="Lender*"
              variant="outlined"
              {...register("lenderTitle", {
                required: requiredErrorMessage,
              })}
              error={Boolean(errors?.lenderTitle)}
              helperText={
                Boolean(errors?.lenderTitle)
                  ? errors?.lenderTitle?.message
                  : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* Lender name & Lender email */}
      <Grid container spacing={1}>
        {/* Lender Name */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">
              Contact Name
            </Typography>
            <TextField
              size="small"
              id="lender-contact-name"
              //label="Contact Name"
              variant="outlined"
              {...register("lenderContactName")}
              error={Boolean(errors?.lenderContactName)}
              helperText={
                Boolean(errors?.lenderContactName)
                  ? errors?.lenderContactName?.message
                  : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
        {/* Lender Email */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">
              Email
            </Typography>
            <TextField
              size="small"
              id="lender-email"
              //label="Email"
              variant="outlined"
              {...register("lenderEmail", {
                validate: {
                  format: (value) => isValidEmail(value) || textErrorMessage,
                },
              })}
              error={Boolean(errors?.lenderEmail)}
              helperText={
                Boolean(errors?.lenderEmail)
                  ? errors?.lenderEmail?.message
                  : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* Lender Address & Loan City */}
      <Grid container spacing={1}>
        {/* Lender Address */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">
              Address
            </Typography>
            <TextField
              size="small"
              id="lender-address"
              //label="Address"
              variant="outlined"
              {...register("lenderAddress")}
              error={Boolean(errors?.lenderAddress)}
              helperText={
                Boolean(errors?.lenderAddress)
                  ? errors?.lenderAddress?.message
                  : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
        {/* Lender City */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">
              City
            </Typography>
            <TextField
              size="small"
              id="lender-city"
              //label="City"
              variant="outlined"
              {...register("lenderCity")}
              error={Boolean(errors?.lenderCity)}
              helperText={
                Boolean(errors?.lenderCity) ? errors?.lenderCity?.message : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* Lender state, zip & phone */}
      <Grid container spacing={1}>
        {/* Lender state */}
        <Grid item xs={3}>
          <FormControl
            size="small"
            variant="outlined"
            className={"form-control"}
          >
            <Typography variant="body3" component="label">
              State
            </Typography>
            <Controller
              control={control}
              name={"lenderState"}
              render={({ field }) => (
                <Select
                  labelId="lender-state"
                  //label="State"
                  displayEmpty
                  input={
                    <OutlinedInput
                    //notched
                    //label="State"
                    />
                  }
                  {...field}
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                >
                  {usStatesList.map((usStateOption: UsState, index: number) => (
                    <MenuItem key={`${index}`} value={usStateOption.key}>
                      {usStateOption.value}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        {/* Lender zip */}
        <Grid item xs={3}>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">
              Zip
            </Typography>
            <TextField
              size="small"
              id="lender-zip"
              variant="outlined"
              //label="Zip"
              {...register("lenderZip", {
                validate: {
                  format: (value) =>
                    isValidZipCode(value) || numberErrorMessage,
                },
              })}
              error={Boolean(errors?.lenderZip)}
              helperText={
                Boolean(errors?.lenderZip) ? errors?.lenderZip?.message : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
        {/* Lender phone */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">
              Phone
            </Typography>
            <Controller
              control={control}
              name={"lenderPhone"}
              rules={{
                validate: {
                  format: (value) =>
                    isValidPhoneNumber(value) || numberErrorMessage,
                },
              }}
              render={({ field, fieldState }) => (
                <MaterialInputMask
                  addStyle={true}
                  mask="(999) 999-9999"
                  hasValue={Boolean(watchLenderPhone)}
                  value={field.value || ""}
                  onChange={field.onChange}
                  TextFieldProps={{
                    size: "small",
                    id: "lender-phone",
                    variant: "outlined",
                    //label: "Phone",
                    name: field.name,
                    inputRef: field.ref,
                    error: Boolean(fieldState.invalid),
                    helperText: Boolean(errors?.lenderPhone)
                      ? errors?.lenderPhone?.message
                      : null,
                    className: "form-control-field",
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* Lender Website */}
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">
              Website
            </Typography>
            <TextField
              size="small"
              id="lender-website"
              //label="Website"
              variant="outlined"
              {...register("lenderWebsite", {
                validate: {
                  format: (value) => isValidWebsite(value) || textErrorMessage,
                },
              })}
              error={Boolean(errors?.lenderWebsite)}
              helperText={
                Boolean(errors?.lenderWebsite)
                  ? errors?.lenderWebsite?.message
                  : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* Servicer Information */}
      <Typography variant="h4" sx={{ marginTop: "2em" }}>
        Servicer Information
      </Typography>
      {/* Servicer */}
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">
              Servicer*
            </Typography>
            <TextField
              size="small"
              id="servicer-title"
              //label="Servicer*"
              variant="outlined"
              {...register("servicerTitle", {
                required: requiredErrorMessage,
              })}
              error={Boolean(errors?.servicerTitle)}
              helperText={
                Boolean(errors?.servicerTitle)
                  ? errors?.servicerTitle?.message
                  : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* Servicer Address & Servicer City */}
      <Grid container spacing={1}>
        {/* Servicer Address */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">
              Address
            </Typography>
            <TextField
              size="small"
              id="servicer-address"
              //label="Address"
              variant="outlined"
              {...register("servicerAddress")}
              error={Boolean(errors?.servicerAddress)}
              helperText={
                Boolean(errors?.servicerAddress)
                  ? errors?.servicerAddress?.message
                  : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
        {/* Servicer City */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">
              City
            </Typography>
            <TextField
              size="small"
              id="servicer-city"
              //label="City"
              variant="outlined"
              {...register("servicerCity")}
              error={Boolean(errors?.servicerCity)}
              helperText={
                Boolean(errors?.servicerCity)
                  ? errors?.servicerCity?.message
                  : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* Servicer state, zip & phone */}
      <Grid container spacing={1}>
        {/* Servicer state */}
        <Grid item xs={3}>
          <FormControl
            size="small"
            variant="outlined"
            className={"form-control"}
          >
            <Typography variant="body3" component="label">
              State
            </Typography>
            <Controller
              control={control}
              name={"servicerState"}
              render={({ field, fieldState }) => (
                <Select
                  labelId="servicer-state"
                  //label="State"
                  displayEmpty
                  input={
                    <OutlinedInput
                    //notched
                    //label="State"
                    />
                  }
                  {...field}
                  className={"form-control-field"}
                  IconComponent={SelectIcon}
                >
                  {usStatesList.map((usStateOption: UsState, index: number) => (
                    <MenuItem key={`${index}`} value={usStateOption.key}>
                      {usStateOption.value}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        {/* Servicer zip */}
        <Grid item xs={3}>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">
              Zip
            </Typography>
            <TextField
              size="small"
              id="servicer-zip"
              variant="outlined"
              //label="Zip"
              {...register("servicerZip", {
                validate: {
                  format: (value) =>
                    isValidZipCode(value) || numberErrorMessage,
                },
              })}
              error={Boolean(errors?.servicerZip)}
              helperText={
                Boolean(errors?.servicerZip)
                  ? errors?.servicerZip?.message
                  : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
        {/* Servicer phone */}
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">
              Phone
            </Typography>
            <Controller
              control={control}
              name={"servicerPhone"}
              rules={{
                validate: {
                  format: (value) =>
                    isValidPhoneNumber(value) || numberErrorMessage,
                },
              }}
              render={({ field, fieldState }) => (
                <MaterialInputMask
                  addStyle={true}
                  mask="(999) 999-9999"
                  hasValue={Boolean(watchServicerPhone)}
                  value={field.value || ""}
                  onChange={field.onChange}
                  TextFieldProps={{
                    size: "small",
                    id: "servicer-phone",
                    variant: "outlined",
                    //label: "Phone",
                    name: field.name,
                    inputRef: field.ref,
                    error: Boolean(fieldState.invalid),
                    helperText: Boolean(errors?.servicerPhone)
                      ? errors?.servicerPhone?.message
                      : null,
                    className: "form-control-field",
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* Servicer Website */}
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">
              Website
            </Typography>
            <TextField
              size="small"
              id="servicer-website"
              //label="Website"
              variant="outlined"
              {...register("servicerWebsite", {
                validate: {
                  format: (value) => isValidWebsite(value) || textErrorMessage,
                },
              })}
              error={Boolean(errors?.servicerWebsite)}
              helperText={
                Boolean(errors?.servicerWebsite)
                  ? errors?.servicerWebsite?.message
                  : null
              }
              className={"form-control-field"}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* Lender Notes */}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl className={"form-control"}>
            <Typography variant="body3" component="label">
              Lender Notes
            </Typography>
            <TextField
              size="small"
              variant="outlined"
              multiline
              rows={4}
              error={Boolean(errors?.lenderNotes)}
              helperText={
                Boolean(errors?.lenderNotes)
                  ? errors?.lenderNotes?.message
                  : null
              }
              className={"form-control-field form-control-textarea"}
              {...register("lenderNotes", {
                validate: {
                  maxCharacters: (value) => {
                    if (!value) return true;
                    if (value?.trim().length > maxNotesField)
                      return notesErrorMessage;
                  },
                },
              })}
            />
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LendersServicerForm;
