interface IDropdownOption {
  value: string;
  name: string;
}

export const getDenominationOptions = () => {
  const options: IDropdownOption[] = [];
  options.push({
    value: "Gross",
    name: "Gross",
  });
  options.push({
    value: "PerArea",
    name: "Per Area",
  });
  options.push({
    value: "PerUnit",
    name: "Per Unit",
  });

  return options;
};

export const formatAsPercentage = (value: any, decimalPoints: number = 0) => {
  if (isNaN(value)) return "";

  return (value * 100).toFixed(decimalPoints) + "%";
};

export const formatAsCurrency = (params: any, decimalPoints: number) => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: decimalPoints,
  });

  return formatter.format(params || 0);
};

export const formatNumber = (params: any, decimalPoints: number) => {
  let formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: decimalPoints,
  });

  return formatter.format(params || 0);
};

export const formatAsMetricUnit = (value: number) => {
  var input = value;

  var exp,
    suffixes = ["k", "M", "G", "T", "P", "E"];

  if (Number.isNaN(input)) {
    return input;
  }

  if ((input >= 0 && input < 1000) || (input < 0 && input > -1000)) {
    return input;
  }

  var isNegative = input < 0;
  if (isNegative) {
    input = input * -1;
  }

  exp = Math.floor(Math.log(input) / Math.log(1000));

  return (
    ((isNegative ? -1 : 1) * (input / Math.pow(1000, exp))).toFixed(0) +
    suffixes[exp - 1]
  );
};

export const getDateTickLabelsFormatter = (data: any[]) => {
  let dataClosure: any[] = data;

  return function (params: any) {
    if (dataClosure.length > 24) {
      if (params.index % 3 !== 0) {
        return "";
      }
    } else if (dataClosure.length > 12) {
      if (params.index % 2 !== 0) {
        return "";
      }
    }
    return params.value;
  };
};
