export function getFirstDayOfMonth(year: number, month: number) {
    return new Date(year, month-1, 1);
  }

export function getLastDayOfMonth(year: number, month: number) {
    return new Date(year, month, 0);
}

export function getFirstDateOfQuarter(inputdate: Date) {
    const quarter = Math.floor((inputdate.getMonth() / 3));
    const startDateQuarter = new Date(inputdate.getFullYear(), quarter * 3, 1);
    return startDateQuarter;
}

export function getLastDateOfQuarter(inputdate: Date) {
    const quarter = Math.floor((inputdate.getMonth() / 3));
    const startDateQuarter = new Date(inputdate.getFullYear(), quarter * 3, 1);
    const lastDateQuarter = new Date(startDateQuarter.getFullYear(), startDateQuarter.getMonth() + 3, 0);
    return lastDateQuarter;
}