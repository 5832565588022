import React, {
  useState,
  useMemo,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Box, Link, Tab, Tabs, Typography } from "@mui/material";

import UserPropertyAssignment from "./userPropertyAssignment/UserPropertyAssignment";
import { UserProvider } from "../util/userContext";
import { TUserListFilter } from "../model/userListFilter";
import UserList from "./userList";
import UserInsert from "./userInsert";
import UserListSearch from "./userListSearch";
import { UserInfo, UserInfoContext } from "../util/userInfoContext";
import PortfolioContext from "../../portfolio/util/portfolioContext";
import WidgetConfiguration from "./WidgetConfiguration";
import UserOptions from "../../layout/views/userOptions";
import { EAccountPermission } from "../model/editUserModel";
import Alerts from "./alerts/Alerts";
import SimpleAlert, { AlertSeverityType } from "../../UI/view/SimpleAlert";
import GLAccountTrees from "./glAccountTrees/GlAccountTrees";
import NegateActualBudget from "./negateActualBudget/NegateActualBudget";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  marginLeft: number;
  marginTop: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, marginLeft, marginTop, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box ml={marginLeft} mt={marginTop}>
          {children}
        </Box>
      )}
    </div>
  );
};

export const a11yProps = (index: number) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

const hiddenTabStyles = {
  display: "none",
};

type UserPermissions = {
  showUsers: boolean;
  showWidgetConfig: boolean;
};

const UserManagement: React.FC = () => {
  const { hasAdminPageAccess, accountPermissions, isSuperAdmin } =
    useContext(PortfolioContext);

  const usersPermissions: UserPermissions = useMemo(() => {
    let showUsers = false;
    let showWidgetConfig = false;

    for (let value of accountPermissions.values()) {
      if (value[EAccountPermission.ADMINISTRATION]) {
        showUsers = true;
        showWidgetConfig = true;
        break;
      } else {
        if (value[EAccountPermission.ADMINISTER_USERS]) showUsers = true;
        if (value[EAccountPermission.CONFIGURE_WIDGETS])
          showWidgetConfig = true;
        break;
      }
    }

    return { showUsers, showWidgetConfig };
  }, [accountPermissions]);

  const [tabChange, setTabChange] = useState(() => {
    if (usersPermissions.showUsers && hasAdminPageAccess) return 0;
    if (usersPermissions.showWidgetConfig && hasAdminPageAccess) return 2;
    else return 3;
  });

  useEffect(() => {
    setTabChange((prevTabChange) => {
      const { showUsers, showWidgetConfig } = usersPermissions;
      if (showUsers && showWidgetConfig) {
        return 0;
      } else return prevTabChange;
    });
  }, [usersPermissions]);

  const [filter, setFilter] = useState<TUserListFilter>({
    accountIdentifier: "",
    value: "",
  });
  const [userInfo, setUserInfo] = useState<UserInfo>({
    userId: "",
    accountIdentifier: "",
    email: "",
  });
  const [emailList, setEmailList] = useState<string[] | undefined>();

  const updateAlertDetails = (
    alertMessage: string,
    alertSeverity: AlertSeverityType
  ) => {
    setUserInfo((prevInfo) => {
      return {
        ...prevInfo,
        alertMessage: alertMessage,
        alertSeverity: alertSeverity,
      };
    });
  };

  const [showInsertPopup, setShowInsertPopup] = useState(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    document.title = "Workspace | User Management";
  }, []);

  const handlePopupOpen = useCallback(() => {
    setShowInsertPopup(true);
  }, []);

  const handlePopupClose = useCallback(
    (shouldRefreshData: boolean, accountId?: string) => {
      setShowInsertPopup(false);
      if (shouldRefreshData && accountId !== undefined) {
        queryClient.invalidateQueries(["getUserList", accountId, ""]);
      }
    },
    [queryClient]
  );

  const setUserListSearchFilter = useCallback(
    (acctIdentifier: string, val: string) => {
      setFilter({ accountIdentifier: acctIdentifier, value: val });
      setUserInfo({
        ...userInfo,
        accountIdentifier: acctIdentifier,
      });
    },
    [userInfo]
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setUserInfo({
      ...userInfo,
      alertMessage: undefined,
      alertSeverity: undefined,
    });
    console.log("change tabs");
    setTabChange(newValue);
  };

  const handleEmailList = (userList: string[] | undefined) => {
    setEmailList(userList);
  };

  return (
    <Box className="mainSettingsContainer">
      {userInfo.alertMessage && userInfo.alertSeverity && (
        <SimpleAlert
          severityType={userInfo.alertSeverity}
          message={userInfo.alertMessage}
          onClose={() => {
            setUserInfo((prevUserInfo) => {
              return {
                ...prevUserInfo,
                alertMessage: undefined,
                alertSeverity: undefined,
              };
            });
          }}
          alertStyles={{ width: "100%" }}
          styles={{ position: "inherit", width: "100%" }}
        />
      )}
      <UserInfoContext.Provider value={{ ...userInfo, updateAlertDetails }}>
        <UserProvider>
          <Box
            className={"appHeader"}
            sx={{ backgroundColor: "neutralSecondary.main" }}
          >
            <Box>
              <Typography variant="h1" sx={{ color: "primary.dark" }}>
                User Management
              </Typography>
            </Box>
            {/* User Options */}
            <UserOptions />
          </Box>
          {/*<AppBar position="static" color="default">*/}
          <Tabs
            value={tabChange}
            onChange={handleTabChange}
            indicatorColor="primary"
            //textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            //className={classes.tabsContainer}
            sx={{ width: "100%", backgroundColor: "neutralSecondary.main" }}
          >
            <Tab
              label="Users"
              className={"mainTopTab"}
              component={Link}
              {...a11yProps(0)}
              style={
                usersPermissions.showUsers && hasAdminPageAccess
                  ? {}
                  : { ...hiddenTabStyles }
              }
            />
            <Tab
              label="Property Assignment"
              className={"mainTopTab"}
              component={Link}
              {...a11yProps(1)}
              style={
                usersPermissions.showUsers && hasAdminPageAccess
                  ? {}
                  : { ...hiddenTabStyles }
              }
            />
            <Tab
              label="Widget Configuration"
              className={"mainTopTab"}
              component={Link}
              {...a11yProps(2)}
              style={
                usersPermissions.showWidgetConfig && hasAdminPageAccess
                  ? {}
                  : { ...hiddenTabStyles }
              }
            />
            <Tab
              label="Alerts"
              className="mainTopTab"
              component={Link}
              {...a11yProps(3)}
            />
            <Tab
              label="GL Account Trees"
              className="mainTopTab"
              component={Link}
              {...a11yProps(4)}
              style={isSuperAdmin ? {} : { ...hiddenTabStyles }}
            />
            <Tab
              label="Negate Actual/Budget"
              className="mainTopTab"
              component={Link}
              {...a11yProps(5)}
              style={isSuperAdmin ? {} : { ...hiddenTabStyles }}
            />
          </Tabs>
          {/*</AppBar>*/}
          <div className="tab-panel-container">
            {usersPermissions.showUsers && hasAdminPageAccess && (
              <React.Fragment>
                <TabPanel
                  value={tabChange}
                  index={0}
                  marginTop={0}
                  marginLeft={0}
                >
                  <UserListSearch
                    setFilter={setUserListSearchFilter}
                    handlePopupOpen={handlePopupOpen}
                  />
                  <UserList
                    filter={filter}
                    accountIdentifier={filter.accountIdentifier}
                    handleEmailList={handleEmailList}
                  />
                  {showInsertPopup && (
                    <UserInsert
                      handlePopupClose={handlePopupClose}
                      showInsertPopup={showInsertPopup}
                      accountIdentifier={filter.accountIdentifier}
                      emailList={emailList}
                    />
                  )}
                </TabPanel>
                <TabPanel
                  value={tabChange}
                  index={1}
                  marginTop={0}
                  marginLeft={0}
                >
                  <UserPropertyAssignment setFilter={setUserListSearchFilter} />
                </TabPanel>
              </React.Fragment>
            )}
            {usersPermissions.showWidgetConfig && hasAdminPageAccess && (
              <TabPanel
                value={tabChange}
                index={2}
                marginTop={0}
                marginLeft={0}
              >
                <WidgetConfiguration />
              </TabPanel>
            )}
            <TabPanel value={tabChange} index={3} marginTop={0} marginLeft={0}>
              <Alerts />
            </TabPanel>
            <TabPanel value={tabChange} index={4} marginTop={0} marginLeft={0}>
              <GLAccountTrees />
            </TabPanel>
            <TabPanel value={tabChange} index={5} marginTop={0} marginLeft={0}>
              <NegateActualBudget />
            </TabPanel>
          </div>
        </UserProvider>
      </UserInfoContext.Provider>
    </Box>
  );
};
export default UserManagement;
